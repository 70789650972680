import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Box,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import SopuseAccordion from "components/reusable/accordion/SopouseAccordion";
import { Button } from "components/reusable/button/Button";
import { IconStyledButton } from "components/reusable/button/Button.style";
import { ButtonIcon } from "components/reusable/button/IconButton";
import FlexComp from "components/reusable/flex/Flex";
import {
  DateInput,
  Input,
  InputWithButton,
  InputWithCheckBox,
} from "components/reusable/input/Inputs";
import { MuiPhone } from "components/reusable/PhoneNumber";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { pcFieldsBottom, pcFieldsTop } from "data/client";
import { stateOptions } from "data/clientData/businessFormation";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import {
  BasicInfoContainer,
  BasicInfoInputWrapper,
  FillingStatusCheckBoxes,
  FillingStatusWrapper,
  FreeLookWrapper,
  PCButtonWrappers,
} from "./PersonalCollection.style";
import { prepSheetSchema } from "./Schema";

const dependentOptions = {
  firstName: "",
  middleName: "",
  lastName: "",
  relation: "",
  ssn: "",
  dob: "",
  dependentBirthCertificateDocFileName: null,
  dependentBirthCertificateDoc: null,
};

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  ssn: "",
  phone: "",
  alternatePhone: "",
  city: "",
  street: "",
  state: "",
  zipCode: "",
  alternateAddress: "",
  alternateWorkAddressCheck: false,
  alternateHomeAddressCheck: false,
  alternateSpouseAddressCheck: false,
  alternateOtherAddressCheck: false,
  email: "",
  driverLicense: "",
  dlDocFileName: null,
  dlDoc: null,
  occupation: "",
  taxYear: "",
  statusSingle: false,
  statusMarriedFillingJointly: false,
  statusMarriedFillingSeparately: false,
  statusMarriedFillingHeadOfHousehold: false,
  freeLookRequestCheck: "",
  // freeLookRequestedNoCheck: ""
  isIrsIpPinYesCheck: true,
  isIrsIpPinNoCheck: false,
  license_state: "",
  IRSPin: "",
  spouse: {
    ssn: "",
    dob: "",
    firstName: "",
    lastName: "",
    middleName: "",
    spouseDlDoc: null,
    spouseDlDocFileName: null,
  },
  dependent: [],
};

const fillStatus = [
  { label: "Single", key: "statusSingle" },
  { label: "Married Filling Jointly", key: "statusMarriedFillingJointly" },
  {
    label: "Married Filling Separately",
    key: "statusMarriedFillingSeparately",
  },
  { label: "Head of Household", key: "statusMarriedFillingHeadOfHousehold" },
];

const addressOptions = [
  { label: "Work", key: "alternateWorkAddressCheck" },
  { label: "Home", key: "alternateHomeAddressCheck" },
  { label: "Spouse", key: "alternateSpouseAddressCheck" },
  { label: "Other", key: "alternateOtherAddressCheck" },
];

const irsOptions = [
  { 
    label: "Yes", 
    key: "isIrsIpPinYesCheck",
    onChange: (e, setFieldValue) => {
      if (e.target.checked) {
        setFieldValue('isIrsIpPinYesCheck', true);
        setFieldValue('isIrsIpPinNoCheck', false);
      }
    }
  },
  { 
    label: "No", 
    key: "isIrsIpPinNoCheck",
    onChange: (e, setFieldValue) => {
      if (e.target.checked) {
        setFieldValue('isIrsIpPinNoCheck', true);
        setFieldValue('isIrsIpPinYesCheck', false);
        setFieldValue('IRSPin', ''); // Clear the PIN when "No" is selected
      }
    }
  }
];

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const BasicInformation = ({
  check = false,
  setCheck,
  setCurrentTab,
  rowData: businessData,
}) => {
  const [edit, setEdit] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const [dependent, setDependent] = useState([]);
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [sopuse, setSopuse] = useState([]);
  const [dlDoc, setDlDoc] = useState(null);
  const [ssCard, setSsCard] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [openIndex1, setOpenIndex1] = useState(null);
  const { setOpen } = useContext(PageLoaderContext);
  const perosnalMainId = localStorage.getItem("personalCollectionId");
  const [newEntryIndices, setNewEntryIndices] = useState([]);
  const [spouseDlFile, setSpouseDlFile] = useState();
  const newSpouse = useMemo(
    () => ({
      title: `Spouse `,
      fields: [
        {
          label: "First Name",
          placeholder: "Enter spouse First Name",
          id: "firstName",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Middle Name",
          placeholder: "Enter spouse Middle Name",
          id: "middleName",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Last Name",
          placeholder: "Enter spouse Last Name",
          id: "lastName",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "SSN",
          placeholder: "Type SSN",
          id: "ssn",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Date of Birth",
          placeholder: "03-26-2025",
          id: "dob",
          size: "size",
          width: "100%",
          value: "",
        },
        {
          label: "Driver License Upload *",
          id: "spouseDlDoc",
          size: "small",
          width: "100%",
        },
      ],
    }),
    []
  );
  const addSopuse = () => {
    if (sopuse.length === 0) {
      setSopuse([newSpouse]);
    } else {
      console.log("Only one spouse can be added.");
    }
  };

  const newDependent = useMemo(
    () => ({
      title: `Dependent `,
      fields: [
        {
          label: "First Name",
          placeholder: "Enter first name",
          id: "firstName",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Middle Name",
          placeholder: "Enter middle name",
          id: "middleName",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Last Name",
          placeholder: "Enter last name",
          id: "lastName",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Relation",
          placeholder: "Enter relation",
          id: "relation",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "SSN",
          placeholder: "Type SSN",
          id: "ssn",
          size: "size",
          width: "23.5%",
          value: "",
        },
        {
          label: "Date of Birth",
          placeholder: "",
          id: "dob",
          size: "size",
          width: "100%",
          value: "",
        },
        {
          label: "SS Card/Birth Certificate Upload *",
          id: "dependentBirthCertificateDoc",
          size: "small",
          width: "100%",
        },
      ],
    }),
    [dependent.length]
  );

  const addDependent = () => {
    setFieldValue("dependent", [...values.dependent, dependentOptions]);
    setDependent((dependents) => [...dependents, newDependent]);
  };
  const [freeLookYearArr, setFreeLookYearArr] = useState([
    { year: "", yearDocs: [] },
  ]);
  const [deleteFreeLookYearArrDocs, setDeleteFreeLookYearArrDocs] = useState(
    []
  );
  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: prepSheetSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(edit, "editttt");

      const value = values.spouse.spouseDlDoc?.name || "";
      const updatedDependents = values.dependent.map((dependent) => {
        return {
          ...dependent,
          dependentBirthCertificateDocFileName:
            dependent.dependentBirthCertificateDoc?.name,
        };
      });

      const newValues = {
        ...values,
        freeLookYearArr,
        spouse: {
          ...values.spouse,
          spouseDlDocFileName: value,
        },
        dependent: updatedDependents,
      };

      console.log(newValues);

      try {
        if (!clientId) {
          errorToast("Client Id Not Found.");
          return;
        }

        function createFormData(payload) {
          const formData = new FormData();

          formData.append("clientId", clientId.toString());
          formData.append("firstName", payload.firstName);
          formData.append("middleName", payload.middleName);
          formData.append("lastName", payload.lastName);
          formData.append("dob", new Date(payload.dob).toUTCString());
          formData.append("ssn", payload.ssn);
          formData.append("phone", payload.phone);
          formData.append("city", payload.city);
          formData.append("street", payload.street);
          formData.append("state", payload.state);
          formData.append("zipCode", payload.zipCode);
          formData.append("alternateAddress", payload.alternateAddress);
          formData.append(
            "alternateWorkAddressCheck",
            payload.alternateWorkAddressCheck
          );
          formData.append(
            "alternateHomeAddressCheck",
            payload.alternateHomeAddressCheck
          );
          formData.append(
            "alternateSpouseAddressCheck",
            payload.alternateSpouseAddressCheck
          );
          formData.append(
            "alternateOtherAddressCheck",
            payload.alternateOtherAddressCheck
          );
          formData.append("email", payload.email);
          formData.append("driverLicense", payload.driverLicense);
          formData.append("dlDocFileName", payload.dlDocFileName);

          if (payload.dlDoc) {
            formData.append("dlDoc", payload.dlDoc);
          }

          formData.append("occupation", payload.occupation);
          formData.append(
            "taxYear",
            payload.taxYear ? dayjs(payload.taxYear).format("YYYY") : "2014"
          );
          formData.append("statusSingle", payload.statusSingle);
          formData.append(
            "statusMarriedFillingJointly",
            payload.statusMarriedFillingJointly
          );
          formData.append(
            "statusMarriedFillingSeparately",
            payload.statusMarriedFillingSeparately
          );
          formData.append(
            "statusMarriedFillingHeadOfHousehold",
            payload.statusMarriedFillingHeadOfHousehold
          );

          formData.append(
            "freeLookRequestCheck",
            values.freeLookRequestCheck ? "true" : "false"
          );

          if (values.freeLookRequestCheck) {
            const entriesToSubmit = edit
              ? freeLookYearArr.filter((_, index) =>
                  newEntryIndices.includes(index)
                )
              : freeLookYearArr;

            entriesToSubmit.forEach((item, newIndex) => {
              if (item.year && item.yearDocs && item.yearDocs.length > 0) {
                formData.append(
                  `freeLookYearArr[${newIndex}][year]`,
                  item.year
                );

                item.yearDocs.forEach((doc, docIndex) => {
                  formData.append(
                    `freeLookYearArr[${newIndex}][yearDocs][${docIndex}][doc]`,
                    doc.doc
                  );
                  formData.append(
                    `freeLookYearArr[${newIndex}][yearDocs][${docIndex}][fileName]`,
                    doc.fileName ||
                      doc?.docFileName ||
                      doc?.yearDocs?.[0]?.docFileName
                  );
                });
              }
            });
          }

          if (edit && deleteFreeLookYearArrDocs.length) {
            formData.append(
              "deleteFreeLookYearArrDocs",
              JSON.stringify(deleteFreeLookYearArrDocs)
            );
          }

          if (edit) {
            setNewEntryIndices([]);
          }

          formData.append("isIrsIpPinYesCheck", payload.isIrsIpPinYesCheck);
          formData.append("isIrsIpPinNoCheck", payload.isIrsIpPinNoCheck);
          formData.append("license_state", payload.license_state);
          formData.append("IRSPin", payload.IRSPin);
          formData.append("alternatePhone", payload.alternatePhone);

          if (payload.spouse && !spouseDlFile) {
            formData.append("spouse[ssn]", payload.spouse.ssn);
            formData.append(
              "spouse[dob]",
              new Date(payload.spouse.dob).toUTCString()
            );
            formData.append("spouse[firstName]", payload.spouse.firstName);
            formData.append("spouse[lastName]", payload.spouse.lastName);
            formData.append("spouse[middleName]", payload.spouse.middleName);
            formData.append(
              "spouse[spouseDlDocFileName]",
              payload.spouse.spouseDlDocFileName
            );
            if (payload.spouse.spouseDlDoc) {
              formData.append(
                "spouse[spouseDlDoc]",
                payload.spouse.spouseDlDoc
              );
            }
          }
          if (edit && spouseDlFile) {
            console.log(
              edit,
              values?.spouse?.spouseDlDocFileName,
              "values?.spouse?.spouseDlDocFileName"
            );
            formData.append("deleteSpouseDlDoc", spouseDlFile);
          }

          if (payload.dependent && payload.dependent.length > 0) {
            payload.dependent.forEach((dep, index) => {
              formData.append(`dependent[${index}][firstName]`, dep.firstName);
              formData.append(
                `dependent[${index}][middleName]`,
                dep.middleName
              );
              formData.append(`dependent[${index}][lastName]`, dep.lastName);
              formData.append(`dependent[${index}][relation]`, dep.relation);
              formData.append(`dependent[${index}][ssn]`, dep.ssn);
              formData.append(
                `dependent[${index}][dob]`,
                new Date(dep.dob).toUTCString()
              );
              formData.append(
                `dependent[${index}][dependentBirthCertificateDocFileName]`,
                dep.dependentBirthCertificateDocFileName
              );

              if (dep.dependentBirthCertificateDoc) {
                formData.append(
                  `dependent[${index}][dependentBirthCertificateDoc]`,
                  dep.dependentBirthCertificateDoc
                );
              }
            });
          }

          return formData;
        }

        const formData = createFormData(newValues);
        const response = await apiReq(
          `${
            edit
              ? API_ROUTES.updatePrepSheet + values?._id
              : API_ROUTES.addPreSheet
          }`,
          "POST",
          formData,
          API_BASE_URL,
          false
        );
        console.log("new values", response);
        const { data: { data = {} } = {} } = response;
        if (!edit) {
          localStorage.setItem(
            "personalCollectionId",
            data?.personalCollectionId
          );
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, retirement: false })
          );
        }
        infoToast(
          edit
            ? "Basic Info updated successfully"
            : "Basic Info saved successfully"
        );
        setTimeout(() => {
          setCurrentTab(1);
        }, 1500);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  const deleteSopuse = async (index) => {
    setSpouseDlFile(values?.spouse?.spouseDlDoc);
    const updatedSopuse = [...sopuse];
    updatedSopuse.splice(index, 1);
    setFieldValue("spouse", {
      ssn: "",
      dob: "",
      firstName: "",
      lastName: "",
      middleName: "",
    });
    setSopuse(updatedSopuse);

    infoToast("Spouse Removed Successfully");
  };
  console.log(edit, spouseDlFile, "values?.spouse?.spouseDlDocFileName");
  const fetchdata = async () => {
    try {
      console.log(perosnalMainId, "Buseinssss");

      setOpen(true);
      const id = (businessData?._id || perosnalMainId).replace(/"/g, "");
      const { data: { data = {} } = {} } = await apiReq(
        API_ROUTES.getPerosnalCollection + id,
        "POST",
        { clientId }
      );

      if (data.prepSheetId) {
        setFieldValue("dependent", []);
        setDependent([]);

        if (
          data.prepSheetId.spouse &&
          data.prepSheetId.spouse.firstName !== ""
        ) {
          addSopuse();
        }

        if (data.prepSheetId.dependent) {
          const validDependents = data.prepSheetId.dependent.filter(
            (dependent) => dependent.firstName !== ""
          );
          validDependents.forEach((dependent) => addDependent());
        }

        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, retirement: false })
        );
        setFreeLookYearArr(data.prepSheetId.freeLookYearArr || []);

        const ssn = data.prepSheetId.ssn
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
        const {
          spouse: { dlDoc, dlDocFileName, ...spouseRest },
          ...data1
        } = data.prepSheetId;

        data1.spouse = spouseRest;
        console.log(data, "spouse");
        setValues({
          ...data1,
          ssn,
          dlDoc: data1?.dlDoc,
          dependentBirthCertificateDoc: data1?.dependentBirthCertificateDoc,
          license_state: data1?.license_state,
          spouseDlDoc: data1?.spouseDlDoc,
        });

        setEdit(true);
      }
    } catch (error) {
      errorToast(
        error?.message ||
          "Error while fetching business info. please try later."
      );
    } finally {
      setOpen(false);
    }
  };
  useEffect(() => {
   
    if (businessData?._id || perosnalMainId) fetchdata();
    else {
      !check && setCheck(true);
      localStorage.setItem(
        "tabs",
        JSON.stringify({
          retirement: true,
          healthCare: true,
          adjustments: true,
          itemize_deduction: true,
          upload_docs: true,
        })
      );
    }
  }, [businessData?._id]);

  const deleteDependent = async (index) => {
    const dependentToDelete = values.dependent[index];
    console.log(dependentToDelete, "deleteDependent")
  
    if (dependentToDelete?._id) {
      try {
        setOpen(true); 
  
        const payload = {
          dependentId: dependentToDelete?._id,
          preSheetId: values._id, 
        };
  
        const response = await apiReq(
          API_ROUTES.deleteDepentInfo,
          "POST",
          payload,
          API_BASE_URL,
          false
        );
          console.log(response, "sadsadsadsad")
        if (response?.data?.status == "200") {
          fetchdata()
        } else {
          errorToast("Failed to remove dependent from backend.");
          return;
        }
      } catch (error) {
        errorToast(
          error?.message || "Error while deleting dependent. Please try again."
        );
        return;
      } finally {
        setOpen(false);
      }
    }
  
  // **Remove the dependent from Formik state**
  const updatedFormikDependents = [...values.dependent];
  updatedFormikDependents.splice(index, 1); // Remove the dependent at the specified index

  // **Remove the dependent from UI state**
  const updatedUIDependents = [...dependent];
  updatedUIDependents.splice(index, 1); // Ensure UI also updates

  // **Sync both states**
  setFieldValue("dependent", updatedFormikDependents);
  setDependent(updatedUIDependents);

  infoToast("Dependent removed successfully.");
  };
  console.log("dependent", dependent);
  useEffect(() => {
    if (sopuse.length === 1) {
      setOpenIndex(0);
    }
    if (dependent.length === 1) {
      setOpenIndex1(0);
    }
  }, [sopuse, dependent]);

  const resetCheckboxes = (arrOfCheckbox = []) => {
    for (let val of arrOfCheckbox) {
      setFieldValue(val.key, false);
    }
  };

  const fileChange1 = (file) => {
    setFieldValue("dlDoc", file);
    setDlDoc(file);
  };

  const handleRemoveDocument = (yearIndex, docIndex) => {
    setFreeLookYearArr((prev) =>
      prev.map((item, index) =>
        index === yearIndex
          ? {
              ...item,
              yearDocs: item.yearDocs.filter((_, i) => i !== docIndex),
            }
          : item
      )
    );

    if (!newEntryIndices.includes(yearIndex)) {
      setDeleteFreeLookYearArrDocs((prev) => [
        ...prev,
        freeLookYearArr[yearIndex].yearDocs[docIndex].doc,
      ]);
    }
  };

  const addMoreYear = () => {
    setFreeLookYearArr((prev) => [...prev, { year: "", yearDocs: [] }]);
    setNewEntryIndices((prev) => [...prev, freeLookYearArr.length]);
  };
  const handleYearChange = (index, date) => {
    setFreeLookYearArr((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, year: dayjs(date).format("YYYY") } : item
      )
    );
  };
  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files);
    setFreeLookYearArr((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              yearDocs: [
                ...item.yearDocs,
                ...files.map((file) => ({ doc: file, fileName: file.name })),
              ],
            }
          : item
      )
    );
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize="18px" fontWeight="500" color="#0B2558">
            Basic Information
          </HeadingFive>
          <BasicInfoInputWrapper>
            <Stack direction="column" sx={{ width: "32%" }}>
              <DateInput
                label="Tax Year *"
                width="100%"
                openTo="year"
                restrictToLast10Years="true"
                defaultToTenthYear="true"
                disableFuture="true"
                views={["year"]}
                onChange={(date) => setFieldValue("taxYear", date)}
                value={values?.taxYear ? values.taxYear : "2014"}
              />
              <FormHelperText error>
                {touched.taxYear && errors.taxYear}
              </FormHelperText>  
            </Stack>
            {pcFieldsTop.map(
              (
                { label, id, defaultValue, size, width, placeholder },
                index
              ) => (
                <Stack key={index} direction="column" sx={{ width: "32% " }}>
                  <Input
                    label={label}
                    id={id}
                    defaultValue={defaultValue}
                    size={size}
                    width="100%"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[id]}
                  />
                  <FormHelperText error>
                    {touched?.[id] && errors[id]}
                  </FormHelperText>
                </Stack>
              )
            )}
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="SSN *"
                id="ssn"
                size="size"
                placeholder="XXX-XX-XXXX"
                onChange={(e) => {
                  const formattedSSN = e.target.value
                    .replace(/\D/g, "")
                    .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
                  if (formattedSSN.length <= 11)
                    setFieldValue("ssn", formattedSSN);
                }}
                onBlur={handleBlur}
                value={values.ssn}
              />
              <FormHelperText error>
                {touched?.ssn && errors?.ssn}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <DateInput
                label="Date of Birth *"
                width="100%"
                id="dob"
                onChange={(date) => setFieldValue("dob", date)}
                value={values.dob}
              />
              <FormHelperText error>
                {touched?.dob && errors?.dob}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <MuiPhone
                value={values.phone}
                handleChange={(value) => setFieldValue("phone", value)}
              />
              <FormHelperText error>
                {touched?.phone && errors?.phone}
              </FormHelperText>
            </Stack>
            <Stack sx={{ width: "66%", position: "relative" }}>
              <InputWithCheckBox
                label="Alternate Phone Number"
                defaultValue=""
                size="small"
                width="99%"
                checkboxWidth="360px"
                placeholder="Enter"
                values={values}
                value={values.alternatePhone}
                checkboxData={addressOptions}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                resetCheckboxes={resetCheckboxes}
                onChange={(e) =>
                  setFieldValue("alternatePhone", e.target.value)
                }
              />
              <FormHelperText error>
                {touched?.alternatePhone && errors?.alternatePhone}
              </FormHelperText>
            </Stack>
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Address
            </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "40%" }}>
                <Input
                  label="Street *"
                  id="street"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={handleChange}
                  value={values.street}
                />
                <FormHelperText error>
                  {touched?.street && errors?.street}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "40%" }}>
                <Input
                  label="City *"
                  id="city"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={handleChange}
                  value={values.city}
                />
                <FormHelperText error>
                  {touched?.city && errors?.city}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "40%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values.state}
                  onChange={(_, newVal) =>
                    setFieldValue("state", newVal?.value || "")
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="State *" />
                  )}
                />
                <FormHelperText error>
                  {touched?.state && errors?.state}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "40%" }}>
                <Input
                  label="Zip Code *"
                  id="zipCode"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="XXXXX"
                  onChange={(e) => {
                    let zipCodeValue = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue =
                        zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    setFieldValue(`zipCode`, zipCodeValue);
                  }}
                  value={values.zipCode}
                />
                <FormHelperText error>
                  {touched?.zipCode && errors?.zipCode}
                </FormHelperText>
              </Stack>
            </FlexComp>
            {pcFieldsBottom.map(
              ({ label, id, size, width, placeholder }, index) => (
                <Stack key={index} sx={{ width }}>
                  <Input
                    label={label}
                    id={id}
                    size={size}
                    width="100%"
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={values[id]}
                  />
                  <FormHelperText error>
                    {touched?.[id] && errors[id]}
                  </FormHelperText>
                </Stack>
              )
            )}
            <Stack direction="column" sx={{ width: "40%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={stateOptions}
                value={values.license_state}
                onChange={(_, newVal) =>
                  setFieldValue("license_state", newVal?.value || "")
                }
                renderInput={(params) => (
                  <TextField {...params} label="Driver License State *" />
                )}
              />
              <FormHelperText error>
                {touched?.license_state && errors?.license_state}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "40%" }}>
              <InputWithButton
                label="Driver License Upload *"
                id="dlDoc"
                defaultValue=""
                size="small"
                placeholder=""
                disabled
                onFileSelect={fileChange1}
                value={dlDoc?.name ? dlDoc?.name : values.dlDocFileName}
                button={
                  <div>
                    <input
                      type="file"
                      id="dlDoc"
                      onChange={(event) => {
                        setFieldValue("dlDoc", event.currentTarget.files[0]);
                        setDlDoc(event.currentTarget.files[0]);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="dlDoc">
                      <ButtonIcon
                        icon={<CloudUploadIcon />}
                        text="Upload DL Document"
                        fontSize="14px"
                      />
                    </label>
                  </div>
                }
              />
              {console.log(dlDoc?.name, "presheet ")}
              <FormHelperText error>
                {touched?.dlDoc && errors?.dlDoc}
              </FormHelperText>
              {/* <p style={{ color: "#0b2558", fontSize: "14px" }}>
                The file size should not exceed 4.5MB
              </p> */}
            </Stack>
            <Stack direction="column" sx={{ width: "40%" }}>
              <Input
                label="Occupation "
                id="occupation"
                defaultValue=""
                size="medium"
                width="100%"
                placeholder="Enter"
                required
                onChange={handleChange}
                value={values.occupation}
              />
              <FormHelperText error>
                {touched?.occupation && errors?.occupation}
              </FormHelperText>
            </Stack>
            <FillingStatusWrapper style={{ width: "auto" }}>
              <Span color="#1B1B1B" fontSize="16px" fontWeight="500">
                Filling Status{" "}
              </Span>
              <FillingStatusCheckBoxes>
                {fillStatus.map(({ label, key }, index) => (
                  <TextCheckbox
                    text={label}
                    gridGap="1px"
                    key={index}
                    isChecked={values[key]}
                    onChange={(e) => {
                      resetCheckboxes(fillStatus);
                      setFieldValue(key, e.target.checked);
                    }}
                  />
                ))}
              </FillingStatusCheckBoxes>
            </FillingStatusWrapper>
            <FlexComp
              direction="column"
              justifyContent="space-between"
              alignItems="space-between"
              gridGap="5px"
            >
              <Stack direction="column" sx={{ width: "40%" }}>
                <InputWithCheckBox
                  label="IRS IP PIN"
                  defaultValue=""
                  size="small"
                  width="100%"
                  checkboxWidth="155px"
                  placeholder="Enter"
                  values={values}
                  value={values.IRSPin}
                  checkboxData={irsOptions.map(option => ({
                    ...option,
                    onChange: (e) => option.onChange(e, setFieldValue)
                  }))}
                  disabled={values.isIrsIpPinNoCheck}
                  resetCheckboxes={resetCheckboxes}
                  setFieldValue={setFieldValue}
                  onChange={(e) => {
                    let val = e.target.value.replace(/\D/g, "").slice(0, 9);
                    setFieldValue("IRSPin", val);
                  }}
                />
                <FormHelperText error>
                  {values.isIrsIpPinYesCheck && touched?.IRSPin && errors?.IRSPin}
                </FormHelperText>
              </Stack>
              <FreeLookWrapper>
                <Span color="#1B1B1B" fontSize="16px" fontWeight="500">
                  Free Look Requested (Years)
                </Span>
                <FillingStatusCheckBoxes>
                  <TextCheckbox
                    text="Yes"
                    isChecked={values.freeLookRequestCheck === true}
                    onChange={(e) => {
                      setFieldValue(
                        "freeLookRequestCheck",
                        e.target.checked ? true : null
                      );
                    }}
                  />
                  <TextCheckbox
                    text="No"
                    isChecked={values.freeLookRequestCheck === false}
                    onChange={(e) => {
                      setFieldValue(
                        "freeLookRequestCheck",
                        e.target.checked ? false : null
                      );
                    }}
                  />
                </FillingStatusCheckBoxes>
              </FreeLookWrapper>
              {values.freeLookRequestCheck &&
                freeLookYearArr.map((item, index) => (
                  <FreeLookWrapper key={index} alignItems="start">
                    {console.log(item.year, "freeLookRequestYear")}
                    <Stack
                      direction="column"
                      justifyContent="start"
                      alignItems="start"
                      sx={{ width: "50%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <DateInput
                          label="Select Year"
                          width="100%"
                          openTo="year"
                          restrictToLast10Years="true"
                          defaultToTenthYear="true"
                          disableFuture="true"
                          views={["year"]}
                          onChange={(date) => handleYearChange(index, date)}
                          value={item.year ? dayjs(item.year) : dayjs()}
                        />
                        <FormHelperText error>
                          {touched?.freeLookYearArr?.[index]?.year &&
                            errors?.freeLookYearArr?.[index]?.year}
                        </FormHelperText>
                      </div>

                      {item.yearDocs.length > 0 && (
                        <Stack
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="15px"
                          style={{ marginTop: "10px" }}
                        >
                          {console.log(item, "cjeclGas")}
                          {item.yearDocs.map((file, fileIndex) => (
                            <Box
                              key={fileIndex}
                              sx={{
                                fontSize: "12px",
                                margin: "4px 0",
                                background: "#62a0b8",
                                color: "#fff",
                                display: "inline-flex",
                                alignItems: "center",
                                padding: "0 15px",
                                height: "30px",
                                borderRadius: "8px",
                              }}
                            >
                              {file.docFileName || file.fileName}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  handleRemoveDocument(index, fileIndex)
                                }
                                style={{
                                  marginLeft: "8px",
                                  color: "#000",
                                  fontSize: "5px",
                                }}
                              >
                                <CloseIcon />
                              </a>
                            </Box>
                          ))}
                        </Stack>
                      )}
                    </Stack>

                    <Stack direction="row" gap={5}>
                      <ButtonIcon
                        width="100%"
                        icon={<CloudUploadIcon />}
                        text="Upload Free Look Documents"
                        fontSize="12px"
                        clickHandler={() =>
                          document
                            .getElementById(`freelookDocs-${index}`)
                            .click()
                        }
                      />
                      <input
                        type="file"
                        id={`freelookDocs-${index}`}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, index)}
                        multiple
                      />
                    </Stack>
                  </FreeLookWrapper>
                ))}

              {values.freeLookRequestCheck && (
                <IconStyledButton
                  fontSize="14px"
                  onClick={addMoreYear}
                  type="button"
                  width={"auto"}
                  style={{ marginTop: "10px", display: "inline-flex" }}
                >
                  <LuPlus />
                  Add More Year
                </IconStyledButton>
              )}
              {!values.statusSingle &&
                sopuse.map((sopuse, index) => (
                  <FlexComp
                    key={index}
                    direction="column"
                    justifyContent="flex-start"
                    gridGap="5px"
                  >
                    <SopuseAccordion
                      item={sopuse}
                      index={index}
                      fieldToSet="spouse"
                      openIndex={openIndex}
                      values={values?.spouse || {}}
                      setOpenIndex={setOpenIndex}
                      setFieldValue={setFieldValue}
                      deleteExpense={deleteSopuse}
                    />
                  </FlexComp>
                ))}
              {dependent.map((dept, index1) => (
                <FlexComp
                  key={index1}
                  direction="column"
                  justifyContent="flex-start"
                  gridGap="5px"
                >
                  <SopuseAccordion
                    item={dept}
                    key={index1}
                    index={index1}
                    fieldToSet="dependent"
                    openIndex={openIndex1}
                    setOpenIndex={setOpenIndex1}
                    setFieldValue={setFieldValue}
                    values={values?.dependent?.[index1] || {}}
                    deleteExpense={deleteDependent}
                  />
                </FlexComp>
              ))}
              <FlexComp
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <PCButtonWrappers style={{ width: "auto" }}>
                  {!values.statusSingle && !sopuse.length && (
                    <ButtonIcon
                      icon={<AddIcon />}
                      text="Add Spouse"
                      fontSize="14px"
                      clickHandler={addSopuse}
                    />
                  )}
                  <ButtonIcon
                    icon={<AddIcon />}
                    text="Add dependent"
                    fontSize="14px"
                    clickHandler={addDependent}
                  />
                </PCButtonWrappers>
                <Button
                  type="submit"
                  text={
                    isSubmitting ? (
                      <SyncLoader color={"#FFFFFF"} size={8} />
                    ) : (
                      "Next"
                    )
                  }
                  width="15%"
                  fontSize="18px"
                  backgroundColor="#0B2558"
                />
              </FlexComp>
            </FlexComp>
          </BasicInfoInputWrapper>
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default BasicInformation;
