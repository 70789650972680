import { useEffect, useState } from "react";
import styled from "styled-components";
import FlexComp from "components/reusable/flex/Flex";
import { Input } from "components/reusable/input/Inputs";
import SecDropdownComp from "components/reusable/dropdownComp/SecDropdown";
import { Button } from "components/reusable/button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { FormHelperText, Stack } from "@mui/material";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, storage, db } from "services/firebaseSetup";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";

const UserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state?.user; // Get the user data from the router state

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(user?.role || null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      address: user?.address || "",
      phone: user?.phone || "",
      role: user?.role || "",
      password: "", // keep password empty initially
      fireBaseUid: user?.fireBaseUid || "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      address: Yup.string().required("Address is required"),
      phone: Yup.string().required("Phone number is required"),
      role: Yup.string().required("Role is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);
        if (user) {
          // Update user
          const formData = new FormData();
          formData.append("userId", user._id);
          formData.append("firstName", values.firstName);
          formData.append("lastName", values.lastName);
          formData.append("email", values.email);
          formData.append("address", values.address);
          formData.append("phone", values.phone);
          formData.append("role", values.role);
          formData.append("password", values.password);
          await apiReq(
            API_ROUTES.userManagementUpdate,
            "POST",
            formData,
            API_BASE_URL,
            false
          );
        } else {
          // Create new user logic here...
          const fullName = `${values.firstName}_${values.lastName}`;
          const res = await createUserWithEmailAndPassword(
            auth,
            values.email,
            values.password
          );
          const storageRef = ref(storage, fullName);
          const uploadTask = uploadBytesResumable(storageRef);
          uploadTask.on(
            "state_changed",
            (error) => {
              // setErr(err)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  await updateProfile(res.user, {
                    displayName: fullName,
                  });
                }
              );
            }
          );
          console.log("Firebase UID:", res.user.uid);
          await setDoc(doc(db, "users", res.user.uid), {
            uid: res.user.uid,
            displayName: fullName,
            email: values.email,
            role: values.role,
          });
          await setDoc(doc(db, "usersChats", res.user.uid), {});
          values.fireBaseUid = res.user.uid;
          await apiReq(API_ROUTES.registerAdminSide, "POST", values);
        }
        navigate("/user-management");
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const options = [
    { value: "Staff", label: "Staff" },
    { value: "Admin", label: "Admin" },
  ];

  return (
    <>
      <RentalPropertyContainer>
        <h2 style={{ paddingLeft: "5px" }}>Add New User</h2>
        <RentalPropertyWrapper>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Stack direction="column" sx={{ width: "49%" }}>
              <Input
                label="First Name"
                id="outlined-size-small"
                size="size"
                placeholder="Enter"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              <FormHelperText error>{formik.errors?.firstName}</FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Input
                label="Last Name"
                id="outlined-size-small"
                defaultValue=""
                size="size"
                height={50}
                placeholder="Enter"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              <FormHelperText error>{formik.errors?.lastName}</FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Input
                label="Email"
                id="outlined-size-small"
                defaultValue=""
                size="size"
                height={50}
                placeholder="Enter"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <FormHelperText error>{formik.errors?.email}</FormHelperText>
            </Stack>
          </FlexComp>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Address"
                id="outlined-size-small"
                defaultValue=""
                size="size"
                placeholder="Enter"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
              <FormHelperText error>{formik.errors?.address}</FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Contact"
                id="outlined-size-small"
                defaultValue=""
                size="size"
                height={50}
                placeholder="+1 | Enter"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <FormHelperText error>{formik.errors?.phone}</FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <SecDropdownComp
                label="Role"
                id="location-select"
                placeholder="select"
                defaultValue="Role"
                focused
                size="small"
                name="role"
                options={options}
                value={formik.values.role}
                selectedMonth={selectedOption}
                handleChange={(event) =>
                  formik.setFieldValue("role", event.target.value)
                }
              />
              <FormHelperText error>{formik.errors.role}</FormHelperText>
            </Stack>
          </FlexComp>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Password"
                id="outlined-size-small"
                defaultValue=""
                size="size"
                placeholder="Default to Password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <FormHelperText error>{formik.errors?.password}</FormHelperText>
            </Stack>
          </FlexComp>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Button
              text="Save"
              backgroundColor="rgba(11, 37, 88, 1)"
              color="white"
              width="112px"
              type="submit"
              onClick={formik.submitForm}
            />
            <Button
              text="Cancel"
              type="button"
              onClick={() => navigate("/user-management")}
              backgroundColor="rgba(239, 243, 253, 1)"
              color="rgba(174, 182, 207, 1)"
              width="112px"
            />
          </FlexComp>
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default UserForm;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
