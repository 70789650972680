import * as Yup from 'yup';

export const RentalSchema = Yup.object().shape({
  address: Yup.object().shape({
    street: Yup.string().required('Street required'),
    city: Yup.string().required('City required'),
    state: Yup.string().required('State required'),
    zipCode: Yup.string().required('Zip Code required'),
    datePlacedService: Yup.string().required('Date Placed in Service required'),
    rentCharge: Yup.string().required('Rent Charge required'),
    purchasePrice: Yup.string().required('Purchase Price required'),
    fairMarketValue: Yup.string().required('Fair Market Value required'),
    fairRentalDays: Yup.string().required('Fair Rental Days required'),
    propertyType: Yup.string().required('Property Type required'),
  }),

  income: Yup.object().shape({
    rentalIncome: Yup.string().required('Rental Income required'),
    deposit: Yup.string().required('Deposit required'),
    lateFees: Yup.string().required('Late Fees required'),
  }),
  expenses: Yup.object().shape({
    managementFees: Yup.string().required('Management Fees required'),
    advertisingForTenants: Yup.string().required('Advertising for Tenants required'),
    associationDues: Yup.string().required('Association Dues required'),
    cleaningAndMaintenance: Yup.string().required('Cleaning and Maintenance required'),
    repairsAndMaintenance: Yup.string().required('Repairs and Maintenance required'),
    insurance: Yup.string().required('Insurance required'),
    propertyTax: Yup.string().required('Property Tax required'),
    utilities: Yup.string().required('Utilities required'),
    waterCharges: Yup.string().required('Water Charges required'),
    miscFees: Yup.string().required('Misc Fees required'),
  }),
  additionalNotes: Yup.string(),
});
