import { useContext, useEffect, useState } from "react";
import {
  BusinessUseHomeContainer,
  BusinessUseHomeInputWrapper,
} from "./BusinessCollection.style";
import { Input, InputWithAdornment } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import AdditionalNotes from "../../modals/AdditionalNotes";
import ValidateTaxModal from "../../modals/ValidateTaxModal";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { useFormik } from "formik";
import {
  businessUseHomeBottomFields,
  businessUseHomeTopFields,
} from "data/clientData/generalBusiness";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const BusinessUseHome = ({ rowData }) => {
  const clientId = localStorage.getItem("clientId");
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const generalBusinessMainId = localStorage.getItem("generalBusinessMainId");
  const generalId = JSON.parse(localStorage.getItem("generalBusinessID"));
  const editForm = localStorage.getItem("editForm");
  const { setOpen } = useContext(PageLoaderContext);
  const [generalBusinessId] = useState(
    editForm === "true" ? generalId : generalBusinessMainId
  );
  const [, setLoading] = useState(false);
  const [additionalNotes, setadditionalnotes] = useState();
  const [Data, setData] = useState();
  const navigate = useNavigate();

  console.log(generalId, "generalId");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      totalAreaOfHome: Data?.totalAreaOfHome || "",
      businessUseArea: Data?.businessUseArea || "",
      associationDues: Data?.associationDues || "",
      repairsAndMaintenance: Data?.repairsAndMaintenance || "",
      mortgageInterest: Data?.mortgageInterest || "",
      utilities: Data?.utilities || "",
      realEstateTaxes: Data?.realEstateTaxes || "",
      telephone: Data?.telephone || "",
      rent: Data?.rent || "",
      security: Data?.security || "",
      insurance: Data?.insurance || "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Prepare data to be uploaded
        // const uploadData = { ...values, clientId, generalBusinessMainId};
        let uploadData;
        if (editForm === "true" && Data?._id) {
          uploadData = {
            ...values,
            clientId,
            businessUseOfHomeId: Data?._id,
          };
        } else {
          uploadData = {
            ...values,
            clientId,
            generalBusinessMainId: generalBusinessMainId
              ? generalBusinessMainId
              : rowData?._id,
          };
        }
        const { data: { data = {} } = {} } = await apiReq(
          !Data?._id
            ? API_ROUTES.addBusinessUseHome
            : API_ROUTES.updateBusinessUseHome,
          "POST",
          uploadData
        );
        localStorage.setItem("businessUseOfHomeId", data?._id);

        infoToast(
          Data?._id
            ? "Business home info updated succesfully"
            : "Business home info saved succesfully"
        );
        setTimeout(() => {
          setIsAdditionalModal(true);
        }, 1500);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  // console.log(
  //   rowData?._id,
  //   generalBusinessMainId,
  //   Data?._id,
  //   "adsdasdasdasdasasd"
  // );

  console.log(Data, "business use of home");
  const handleFormSubmit = async () => {
    try {
      // Prepare data to be uploaded
      setLoading(true);
      const uploadData = { additionalNotes, generalBusinessId };

      await apiReq(API_ROUTES.updateGeneralBusiness, "POST", uploadData);
      infoToast("Info saved successfully");
      localStorage.setItem("editForm", false);
      setTimeout(() => {
        navigate("/review");
      }, 1500);

      // localStorage.removeItem('generalBusinessMainId');
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getGeneralBusinessByID +
            (rowData?._id || generalBusinessMainId),
          "POST",
          { clientId: clientId }
        );
        if (data) {
          setData(data?.businessUseOfHomeId);
          setadditionalnotes(data?.additionalNotes);
        }
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };
    if (rowData?._id || generalBusinessMainId) fetchdata();
  }, [rowData?._id]);

  return (
    <>
      <BusinessUseHomeContainer>
        <HeadingFive color="#000" fontSize="18px" fontWeight="500">
          Business use of Home
        </HeadingFive>
        <BusinessUseHomeInputWrapper>
          {businessUseHomeTopFields.map(
            ({ label, name, placeHolder, width }, index) => (
              <Input
                label={label}
                name={name}
                value={formik.values[name]}
                onChange={formik.handleChange}
                placeholder={placeHolder}
                width={width}
                key={index}
              />
            )
          )}
          <InputWithAdornment
            label="Association Dues"
            name="associationDues"
            value={formik.values.associationDues}
            onChange={(e) => {
              let val = e.target.value.replace(/\D/g, "").slice(0, 9);
              formik.setFieldValue("associationDues", val);
            }}
            placeholder="Enter"
            width="49%"
          />
          {businessUseHomeBottomFields.map(
            ({ label, name, placeHolder, width }, index) => (
              <Input
                label={label}
                name={name}
                value={formik.values[name]}
                onChange={(e) => {
                  let val = e.target.value.replace(/\D/g, "").slice(0, 10);
                  formik.setFieldValue([name], val);
                }}
                placeholder={placeHolder}
                width={width}
                key={index}
              />
            )
          )}
        </BusinessUseHomeInputWrapper>
        <Button
          text={
            formik.isSubmitting ? (
              <SyncLoader color={"#FFFFFF"} size={8} />
            ) : (
              "Submit"
            )
          }
          width="10%"
          fontSize="20px"
          padding="10px 40px"
          backgroundColor="#0B2558"
          onClick={formik.handleSubmit}
          alignSelf="flex-end"
        />
      </BusinessUseHomeContainer>
      <AdditionalNotes
        isModalOpen={isAdditionalModal}
        closeModal={() => setIsAdditionalModal(false)}
        openSecondModal={() => setIsValidateTaxModal(true)}
        additionalNotes={additionalNotes}
        setadditionalnotes={setadditionalnotes}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(false)}
        paragraph="Are you sure you want to validate this Business Info Form?"
        handleYesClick={handleFormSubmit}
      />
    </>
  );
};

export default BusinessUseHome;
