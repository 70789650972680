import { useContext, useEffect, useState } from "react";
import {
  VehicleInfoCheckBoxWrapper,
  VehicleInfoCheckBoxes,
  VehicleInfoContainer,
  VehicleInfoInputWrapper,
  VehicleInfoMidSec,
} from "./BusinessCollection.style";
import { Input, InputWithAdornment } from "components/reusable/input/Inputs";
import {
  vehicleInfoBottomFields,
  vehicleInfoMidFields,
  vehicleInfoTopFields,
} from "data/clientData/generalBusiness";
import { Button } from "components/reusable/button/Button";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { useFormik } from "formik";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const VehicleInfo = ({ setCurrentTab, rowData }) => {
  const [Data, setData] = useState();
  const clientId = localStorage.getItem("clientId");
  const { setOpen } = useContext(PageLoaderContext);
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const generalBusinessMainId = localStorage.getItem("generalBusinessMainId");
  const editForm = localStorage.getItem("editForm");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      vehicleYearMakeModel: Data?.vehicleYearMakeModel || "",
      purchasePrice: Data?.purchasePrice || "",
      loanInterest: Data?.loanInterest || "",
      advertising: Data?.advertising || "",
      leasePayments: Data?.leasePayments || "",
      licenseAndRegistration: Data?.licenseAndRegistration || "",
      insurance: Data?.insurance || "",
      repairsAndTires: Data?.repairsAndTires || "",
      gasolineLubeOil: Data?.gasolineLubeOil || "",
      miscellaneous: Data?.miscellaneous || "",
      parkingFees: Data?.parkingFees || "",
      interestExp: Data?.interestExp || "",
      personalMileage: Data?.personalMileage || "",
      businessMileage: Data?.businessMileage || "",
      commutingMileage: Data?.commutingMileage || "",
      vehicleUsedCheck: Data?.vehicleUsedCheck || false,
      vehicleAvailableForOffDutyCheck:
        Data?.vehicleAvailableForOffDutyCheck || false,
      vehicleAvailableForPersonalUserCheck:
        Data?.vehicleAvailableForPersonalUserCheck || false,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Prepare data to be uploaded
        // const uploadData = { ...values, clientId, generalBusinessMainId };
        let uploadData;
        if (editForm === "true" && Data?._id) {
          uploadData = {
            ...values,
            clientId,
            vehicleInfoId: Data?._id,
          };
        } else {
          uploadData = {
            ...values,
            clientId,
            generalBusinessMainId: generalBusinessMainId ? generalBusinessMainId : rowData?._id,
          };
        }
        const { data: { data = {} } = {} } = await apiReq(
          !Data?._id ? API_ROUTES.addVehicleInfo : API_ROUTES.updateVehicleInfo,
          "POST",
          uploadData
        );
        localStorage.setItem("vehicleInfoId", data?._id);
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, business_use: false })
        );
        infoToast(
          Data?._id
            ? "Vehicle info updated succesfully"
            : "Vehicle info saved succesfully"
        );
        setTimeout(() => {
          setCurrentTab(4);
        }, 1500);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  // console.log(rowData?._id, generalBusinessMainId, "adsdasdasdasdasasd")

  useEffect(() => {
    const fetchdata = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getGeneralBusinessByID +
            (rowData?._id || generalBusinessMainId),
          "POST",
          { clientId: clientId }
        );

        setData(data?.vehicleInfoId);
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, business_use: false })
        );
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };
    if (rowData?._id || generalBusinessMainId) fetchdata();
  }, [rowData?._id]);

  return (
    <>
      <VehicleInfoContainer>
        <HeadingFive color="#000" fontSize="18px" fontWeight="500">
          Vehicle Information
        </HeadingFive>
        <VehicleInfoInputWrapper>
          <Input
            label="Vehicle Year Make Model "
            id="vehicleYearMakeModel"
            value={formik.values.vehicleYearMakeModel}
            onChange={formik.handleChange}
            placeholder="Enter"
            size="size"
            width="32%"
          />
          {vehicleInfoTopFields.map(
            ({ label, id, size, width, placeHolder, name }, index) => (
              <InputWithAdornment
                key={index}
                label={label}
                id={id}
                name={name}
                value={formik.values[name]}
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                  formik.setFieldValue([name], val);
                }}
                placeholder={placeHolder}
                size={size}
                width={width}
              />
            )
          )}
          <VehicleInfoMidSec smWidth="45%" width="32%">
            <VehicleInfoCheckBoxWrapper>
              <VehicleInfoCheckBoxes width="100%" gridGap="0px">
                <Span color="#1B1B1B" fontSize="16px" fontWeight="500">
                  Vehicle is used by more than 5% own
                </Span>
                <VehicleInfoCheckBoxes gridGap="10px">
                  <TextCheckbox
                    text="Yes"
                    gridGap="1px"
                    isChecked={formik.values.vehicleUsedCheck === true}
                    onChange={() =>
                      formik.setFieldValue(
                        "vehicleUsedCheck",
                        !formik.values.vehicleUsedCheck
                      )
                    }
                  />
                  <TextCheckbox
                    text="No"
                    gridGap="1px"
                    isChecked={formik.values.vehicleUsedCheck === false}
                    onChange={() =>
                      formik.setFieldValue(
                        "vehicleUsedCheck",
                        !formik.values.vehicleUsedCheck
                      )
                    }
                  />
                </VehicleInfoCheckBoxes>
              </VehicleInfoCheckBoxes>

              <VehicleInfoCheckBoxes width="100%" gridGap="0px">
                <Span color="#1B1B1B" fontSize="16px" fontWeight="500">
                  Vehicle available for off-duty personal use
                </Span>
                <VehicleInfoCheckBoxes gridGap="10px">
                  <TextCheckbox
                    text="Yes"
                    gridGap="1px"
                    isChecked={
                      formik.values.vehicleAvailableForOffDutyCheck === true
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "vehicleAvailableForOffDutyCheck",
                        !formik.values.vehicleAvailableForOffDutyCheck
                      )
                    }
                  />
                  <TextCheckbox
                    text="No"
                    gridGap="1px"
                    isChecked={
                      formik.values.vehicleAvailableForOffDutyCheck === false
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "vehicleAvailableForOffDutyCheck",
                        !formik.values.vehicleAvailableForOffDutyCheck
                      )
                    }
                  />
                </VehicleInfoCheckBoxes>
              </VehicleInfoCheckBoxes>

              <VehicleInfoCheckBoxes width="100%" gridGap="0px">
                <Span
                  color="#1B1B1B"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="start"
                >
                  Any other vehicle available for personal use
                </Span>
                <VehicleInfoCheckBoxes gridGap="10px">
                  <TextCheckbox
                    text="Yes"
                    gridGap="1px"
                    isChecked={
                      formik.values.vehicleAvailableForPersonalUserCheck ===
                      true
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "vehicleAvailableForPersonalUserCheck",
                        !formik.values.vehicleAvailableForPersonalUserCheck
                      )
                    }
                  />
                  <TextCheckbox
                    text="No"
                    gridGap="1px"
                    isChecked={
                      formik.values.vehicleAvailableForPersonalUserCheck ===
                      false
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "vehicleAvailableForPersonalUserCheck",
                        !formik.values.vehicleAvailableForPersonalUserCheck
                      )
                    }
                  />
                </VehicleInfoCheckBoxes>
              </VehicleInfoCheckBoxes>
            </VehicleInfoCheckBoxWrapper>
          </VehicleInfoMidSec>
          <VehicleInfoMidSec smWidth="54%" width="66%">
            {vehicleInfoMidFields.map(
              ({ label, id, size, width, placeHolder, name }, index) => (
                <InputWithAdornment
                  key={index}
                  label={label}
                  id={id}
                  name={name}
                  width="60%"
                  value={formik.values[name]}
                  onChange={(e) => {
                    const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                    formik.setFieldValue([name], val);
                  }}
                  placeholder={placeHolder}
                  size={size}
                  // width={width}
                />
              )
            )}
          </VehicleInfoMidSec>
          {vehicleInfoBottomFields.map(
            ({ label, id, size, width, placeHolder, name }, index) => (
              <Input
                key={index}
                label={label}
                id={id}
                name={name}
                value={formik.values[name]}
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                  formik.setFieldValue([name], val);
                }}
                placeholder={placeHolder}
                size={size}
                width={width}
              />
            )
          )}
        </VehicleInfoInputWrapper>
        <Button
          text={
            formik.isSubmitting ? (
              <SyncLoader color={"#FFFFFF"} size={8} />
            ) : (
              "Next"
            )
          }
          disabled={formik.isSubmitting}
          width="10%"
          fontSize="20px"
          padding="10px 40px"
          backgroundColor="#0B2558"
          onClick={formik.handleSubmit}
          alignSelf="flex-end"
        />
      </VehicleInfoContainer>
    </>
  );
};

export default VehicleInfo;
