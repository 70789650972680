// In UploadTaxDocs component
import { useEffect, useState } from "react";
import styled from "styled-components";
import { uploadTaxDocTabData } from "data/clientData/uploadTaxDocs";
import PersonalDocs from "components/client/uploadTaxDocs/PersonalDocs";
import GeneralBusiness from "components/client/uploadTaxDocs/GeneralBusiness";
import RentalProperty from "components/client/uploadTaxDocs/RentalProperty";
import TruckDriver from "components/client/uploadTaxDocs/TruckDriver";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import UploadDocMenuTab from "components/reusable/tabs/UploadDocMenuTab";

const UploadTaxDocs = () => {
  const tabComponents = [
    PersonalDocs,
    GeneralBusiness,
    RentalProperty,
    TruckDriver,
  ];
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const clientId = localStorage.getItem("clientId");
    if (!clientId) {
      console.error("Client ID not found in local storage");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await apiReq(`${API_ROUTES.uploadTaxDocs}`, "POST", {
          clientId: clientId,
        });
        
    
        setApiData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    

    fetchData();
  }, []);

 

  return (
    <UploadTaxDocsContainer>
      <HeadingFive fontSize="24px" fontWeight="500">
        Uploaded Tax Docs
      </HeadingFive>
      <UploadTaxDocsWrapper>
      <UploadDocMenuTab
        tabData={uploadTaxDocTabData}
        tabComponents={tabComponents}
        apiData={apiData} 
      />

      </UploadTaxDocsWrapper>
    </UploadTaxDocsContainer>
  );
};

export default UploadTaxDocs;
const UploadTaxDocsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const UploadTaxDocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
