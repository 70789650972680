import React from "react";
import Modal from "@mui/material/Modal";
import { ValidateTaxBtnWrapper, ValidateTaxContainer } from "./Modals.style";
import { Span } from "../reusable/typography/Typography.style";
import { Button } from "../reusable/button/Button";
import Warning from "../../assets/modal/Warning.png";

const ValidateTaxModal = (props) => {
  return (
    <Modal
      open={props?.isModalOpen}
      onClose={props?.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ValidateTaxContainer>
        <div>
          <img src={Warning} alt="Warning" />
        </div>
        <Span color="#000000" fontSize="20px" fontWeight="500">
          {props?.paragraph}
        </Span>
        <ValidateTaxBtnWrapper>
          <Button
            text="No"
            width="150px"
            fontSize="20px"
            backgroundColor="#EFF3FD"
            padding="10px"
            color="#AEB6CF"
            onClick={props?.closeModal}
          />
          <Button
            text="Yes"
            width="150px"
            type="submit"
            fontSize="20px"
            backgroundColor="#0B2558"
            padding="10px"
            color="#FFFFFF"
            onClick={() => {
              props?.closeModal();
              props?.handleYesClick?.(); // Call the API request function
            }}
            // onClick={() => {
              // props?.closeModal();
              // props?.setGeneralBusinessModal &&
              //   props?.setGeneralBusinessModal(!props?.generalBusinessModal);
            // }}
          />
        </ValidateTaxBtnWrapper>
      </ValidateTaxContainer>
    </Modal>
  );
};

export default ValidateTaxModal;
