import styled from "styled-components";

export const LoginMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  width: 100%;
  p {
    color: #ffffff;
  }
`;

export const ForgetPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  width: 100%;
`;
export const StaffForgetPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  width: 100%;
`;
