import Auth from "../../../assets/auth/Auth.png";
import { AuthPicWrapper, SignupContainer } from "../signup/Signup.style";
import StaffForgetPass from "./StaffForgetPass";

const StaffForgetPassword = () => {
  return (
    <SignupContainer>
      <StaffForgetPass />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default StaffForgetPassword;
