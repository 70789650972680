import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import FlexComp from "components/reusable/flex/Flex";
import { Span } from "components/reusable/typography/Typography.style";
import dayjs from "dayjs";
import { AddBusinessCloseIcon, AddNewBusinessContainer } from "./Modals.style";
import { GrayBox } from "./UploadAndLoss.style";
import { GrayHeading } from "./review/ReviewConfirmation/ReviewAndConfirmation.style";

const ClientInformationModal = ({
  setOpenInfoModal,
  openInfoModal,
  modalData,
  modalType,
}) => {
  console.log(modalType, "modalData");
  return (
    <>
      <Modal
        open={openInfoModal}
        onClose={() => setOpenInfoModal(!openInfoModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddNewBusinessContainer style={{ width: "600px" }}>
          <AddBusinessCloseIcon
            style={{ width: "100%", left: "0px", padding: "0 20px" }}
          >
            <FlexComp alignItems="center" justifyContent="space-between">
              <Span color="#000000" fontSize="20px" fontWeight="500">
                Client View
              </Span>
              <CloseIcon
                onClick={() => setOpenInfoModal(!openInfoModal)}
                sx={{ cursor: "pointer" }}
              />
            </FlexComp>
          </AddBusinessCloseIcon>

          <GrayBox>
            <GrayHeading style={{ width: "100%" }}>
              <Span fontSize="16px" fontWeight="500" color="#fff">
                {modalType == "clientInformation"
                  ? "Client Information"
                  : "User Information"}
              </Span>
            </GrayHeading>
            {modalType == "clientInformation" && (
              <>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        First Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.firstName}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Last Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.lastName}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Email
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.email}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Phone Number
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.phone}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Client Type
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.clientId?.isBusinessProfile
                          ? "Business"
                          : "Personal"}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Payment Status
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.paymentStatus ? "Paid" : "Unpaid"}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    {/* <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Tax Representative
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.taxRepresentative}
                      </Span>
                    </div> */}
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Created At
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {dayjs(modalData?.createdAt).format("MMMM D, YYYY")}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Social Security Number
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.presheetDetails?.ssn}
                      </Span>
                    </div>
                  </FlexComp>
                </div>

                {/* <div
                  style={{
                    width: "100%",
                    padding: "10px 0",
                    paddingRight: "70px",
                  }}
                >
                  <FlexComp>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Created At
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {dayjs(modalData?.createdAt).format("MMMM D, YYYY")}
                      </Span>
                    </div>
                  </FlexComp>
                </div> */}
              </>
            )}
            {modalType == "userManagement" && (
              <>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        First Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.firstName}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Last Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.lastName}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Email
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.email}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Phone Number
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.phone}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Active Status
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.activeStatus}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Address
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.address}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
              </>
            )}
            {console.log(modalData, "modalData")}
            {modalType == "staffInfo" && (
              <>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        First Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.fname}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Last Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.lname}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Business Name
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.businessName}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Social Security Number
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.ssname}
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.email}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #00000033",
                    width: "100%",
                    padding: "10px 0",
                  }}
                >
                  <FlexComp>
                    <div style={{ width: "60%" }}>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Form Progress
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.otherdetails?.formProgress}
                      </Span>
                    </div>
                    <div>
                      <Span
                        color="#1B1B1B"
                        fontSize="16px"
                        fontWeight="500"
                        style={{ display: "block" }}
                      >
                        Payment Status
                      </Span>
                      <Span
                        color="#000000"
                        fontSize="16px"
                        fontWeight="400"
                        style={{ display: "block" }}
                      >
                        {modalData?.otherdetails?.paymentStatus
                          ? "Paid"
                          : "Unpaid"}
                      </Span>
                    </div>
                  </FlexComp>
                </div>
              </>
            )}

            {console.log(modalData, "modalData")}
          </GrayBox>
        </AddNewBusinessContainer>
      </Modal>
    </>
  );
};

export default ClientInformationModal;
