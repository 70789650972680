import {useContext, useEffect, useState} from "react";
import { Input } from "components/reusable/input/Inputs";
import { LaborFieldsTop, TaxFieldsTop } from "data/client";
import { Button } from "components/reusable/button/Button";
import FlexComp from "components/reusable/flex/Flex";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { useFormik } from "formik";
import { laboxTaxSchema } from "./Schema";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { FormHelperText, Stack } from "@mui/material";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const initialValues = {
  labor: {
    subContractor: "",
    lumpers: ""
  },
  taxes: {
    adValorem: "",
    fuelTax: "",
    heavyUseTax: "",
    licenseTax: "",
    otherTax: "",
    permits: "",
    registration: ""
  }
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const LaborTaxes = ({ setCurrentTab }) => {
  const clientId = localStorage.getItem("clientId");
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [tabsData, setTabsData] = useState({})

  const {setOpen} = useContext(PageLoaderContext);

  const {values, errors, touched, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: laboxTaxSchema,
    onSubmit: async(values, {setSubmitting}) => {
      try {
        if (!clientId) {
          errorToast('Client Id Not Found.');
          return;
        } else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
        const {_id: truckDriverId, truckDriverLaborTaxesId} = selectedDriverInfo;
        await apiReq(`${(truckDriverLaborTaxesId?._id || tabsData?._id) ? API_ROUTES.updateLaborTax : API_ROUTES.addLaborTax}`, "POST", {clientId, truckDriverMainId: truckDriverMainId || truckDriverId || "",truckDriverLaborTaxesId: truckDriverLaborTaxesId?._id || tabsData?._id, ...values});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, insurance: false}));
        infoToast(truckDriverLaborTaxesId?._id? "Labor taxes updated succesfully": "Labor taxes saved succesfully");
        setTimeout(() => {
          setCurrentTab(4);
        }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const fetchLaborTax = async() => {
      try {
        setOpen(true);
        const {truckDriverLaborTaxesId} = selectedDriverInfo;
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, insurance: false}));
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getLaborTax, "POST", {truckDriverLaborTaxesId: truckDriverLaborTaxesId._id});
        truckDriverLaborTaxesId && setValues(truckDriverLaborTaxesId);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setOpen(false);
      }
    }
    selectedDriverInfo?.truckDriverLaborTaxesId && fetchLaborTax();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverLaborTaxesId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverLaborTaxesId?._id && localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, insurance: false}));
        truckDriverLaborTaxesId?._id && setValues(truckDriverLaborTaxesId);
        setTabsData(truckDriverLaborTaxesId)
      }
      fetchTruckDriverDetails();
    }
  },[]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize="18px" fontWeight="600">
            Labor
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {LaborFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{width}}>
                  <Input
                    id={id}
                    size={size}
                    label={label}
                    value={values.labor[key]}
                    placeholder={placeholder}
                    onChange={e => {
                      let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                      setFieldValue(`labor.${key}`, val)
                    }}
                  />
                  <FormHelperText error>{touched?.labor?.[key] && errors?.labor?.[key]}</FormHelperText>
                </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          <HeadingFive fontSize="18px" fontWeight="600">
            Taxes
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {TaxFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{width}}>
                  <Input
                      label={label}
                      id={id}
                      size={size}
                      value={values.taxes[key]}
                      placeholder={placeholder}
                      onChange={e => {
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(`taxes.${key}`, val)
                      }}
                  />
                  <FormHelperText error>{touched?.taxes?.[key] && errors?.taxes?.[key]}</FormHelperText>
                </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          <Button
            text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"}
            type="submit"
            width="15%"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="flex-end"
          />
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default LaborTaxes;
