import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  // ButtonInText,
  CheckboxComp,
  CheckboxSec,
  CustomDatePicker,
  InputCheckboxBtnWrapper,
  InputCheckboxWrapper,
} from "./Inputs.style";
import { Span } from "../typography/Typography.style";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../../../index.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import { ButtonIcon } from "../button/IconButton";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
export const Input = ({
  label,
  placeHolder,
  defaultValue,
  id,
  size,
  width,
  height,
  value,
  valueSetter,
  required,
  name,
  mb,
  ...otherProps
}) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      placeholder={placeHolder}
      id={id}
      size={size}
      value={value}
      defaultValue={defaultValue}
      style={{ width: width, height: height, marginBottom: mb }} // Apply height here
      onChange={(e) => valueSetter && valueSetter(e.target.value)}
      required={required}
      InputLabelProps={{ shrink: true }}
      name={name}
      {...otherProps}
    />
  );
};

export const InputArea = ({
  label,
  placeHolder,
  defaultValue,
  id,
  size,
  width,
  height, // Make sure height is passed to the Input component
  value,
  valueSetter,
  ...otherProps
}) => {
  return (
    <TextareaAutosize
      variant="outlined"
      label={label}
      placeholder={placeHolder}
      id={id}
      size={size}
      value={value}
      defaultValue={defaultValue}
      style={{ width: width, height: height }} // Apply height here
      onChange={(e) => valueSetter && valueSetter(e.target.value)}
      required
      InputLabelProps={{ shrink: true }}
      {...otherProps}
    />
  );
};
export const DateInput = ({
  label,
  placeholder,
  defaultValue,
  id,
  size,
  width,
  value,
  required,
  onChange,
  disableFuture = false, // Set default value to false
  error,
  restrictToLast10Years,
  defaultToTenthYear,
  openTo = "day",
  views = ["year", "month", "day"],
  ...otherProps
}) => {
  const minDate = restrictToLast10Years
    ? dayjs().subtract(10, "year").startOf("year")
    : undefined;
  // const maxDate = dayjs();
  const defaultYearValue = dayjs().subtract(10, "year"); // Calculate the 10th year from the current year

  // Function to handle date change
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(dayjs(date));
    }
  };

  return (
    <CustomDatePicker>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          id={id}
          views={views}
          className="custom-date-picker"
          label={label}
          disableFuture={disableFuture} // Use the default value or passed value
          openTo={openTo}
          // maxDate={maxDate}
          minDate={minDate}
          sx={{ width: width }}
          onChange={handleDateChange}
          slotProps={{
            field: {
              readOnly: true,
            },
          }}
          format={openTo === "year" ? "YYYY" : "MM-DD-YYYY"}
          value={
            value
              ? dayjs(value)
              : defaultToTenthYear
              ? defaultYearValue
              : dayjs()
          } // Set default year value if defaultToTenthYear is true
          {...otherProps}
        />
      </LocalizationProvider>
    </CustomDatePicker>
  );
};
const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ["us"].includes(iso2);
});
export const InputWithCheckBox = ({
  label,
  placeholder,
  defaultValue,
  id,
  size,
  width,
  values,
  checkboxWidth,
  value,
  checkboxData,
  setFieldValue,
  checkboxValSetter,
  resetCheckboxes,
  padding,
  handleChange,
  ...otherProps
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries,
      onChange: (data) => {
        console.log(data, "sdadadsad");
        // handleChange(data);
      },
    });
  return (
    <InputCheckboxWrapper width={width}>
      {label == "Alternate Phone Number" ? (
        <TextField
          variant="outlined"
          label={label}
          color="primary"
          placeholder="Alternate Phone Number"
          value={inputValue}
          onChange={handlePhoneValueChange}
          type="tel"
          inputRef={inputRef}
          sx={{ width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ marginRight: "2px", marginLeft: "-8px" }}
              >
                <FlagImage iso2={country.iso2} style={{ marginRight: "8px" }} />
              </InputAdornment>
            ),
          }}
          {...otherProps}
        />
      ) : (
        <TextField
          label={label}
          placeholder={placeholder}
          id={id}
          size="medium"
          value={value}
          defaultValue={defaultValue}
          sx={{ width: "100%" }}
          focused
          {...otherProps}
        />
      )}
      <CheckboxComp width={checkboxWidth}>
        <CheckboxSec>
          {checkboxData.map(({ label, key }, index) => (
            <React.Fragment key={index}>
              <Checkbox
                checked={values[key]}
                onChange={(e) => {
                  resetCheckboxes(checkboxData);
                  setFieldValue(key, e.target.checked);
                }}
              />
              <Span color="#1B1B1B" fontSize="14px" fontWeight="400">
                {label}
              </Span>
            </React.Fragment>
          ))}
        </CheckboxSec>
      </CheckboxComp>
    </InputCheckboxWrapper>
  );
};
const ButtonInText = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  padding: ${({ selectedFileName }) => (selectedFileName ? "10px" : "0")};
  border: ${({ selectedFileName }) =>
    selectedFileName ? "1px solid rgba(2, 173, 238, 1)" : "none"};
  border-radius: ${({ selectedFileName }) => (selectedFileName ? "10px" : "0")};
`;
export const InputWithButton = ({
  label,
  placeholder,
  defaultValue,
  id,
  size,
  width,
  value,
  button,
  onFileSelect,
  onChange,
  addHyphens = false,
  disabled = false,
  ...otherProps
}) => {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file ? file.name : null);
    if (onFileSelect && typeof onFileSelect === "function") {
      onFileSelect(file);
    }
  };
  const handleChange = (e) => {
    let updatedValue = e.target.value;
    if (addHyphens) {
      updatedValue = updatedValue.replace(/-/g, "").trim();
      updatedValue = updatedValue.replace(/(.{3})/g, "$1-");
      updatedValue = updatedValue.replace(/-$/, "");
    }

    if (onChange && typeof onChange === "function") {
      onChange(updatedValue);
    }
  };
  return (
    <InputCheckboxWrapper width={width}>
      <TextField
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        id="outlined-basic"
        size="medium"
        value={value}
        defaultValue={defaultValue}
        sx={{ width: "100%" }}
        variant="outlined"
        focused
        {...otherProps}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <ButtonInText
        selectedFileName={selectedFileName}
        onClick={handleButtonClick}
      >
        {selectedFileName ? "File Selected" : button}
      </ButtonInText>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </InputCheckboxWrapper>
  );
};
export const InputWithAdornment = ({
  label,
  placeHolder,
  defaultValue,
  id,
  size,
  width,
  value, // Add the value prop here
  required,
  error,
  onChange,
  name,
}) => {
  return (
    <FormControl
      sx={{ width: width }}
      focused
      required={required}
      error={error}
    >
      <InputLabel htmlFor={`outlined-adornment-${id}`}>{label}</InputLabel>
      <OutlinedInput
        id={`outlined-adornment-${id}`}
        startAdornment={
          label !== "License & Registration" && (
            <InputAdornment position="start">
              {label == "Loan Interest"
                ? "%"
                : label == "Yr/Make/Model"
                ? ""
                : "$"}
            </InputAdornment>
          )
        }
        label={label}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        size={size}
        value={value} // Use the value prop directly
        onChange={onChange}
        name={name}
      />
    </FormControl>
  );
};

export const PasswordInput = ({
  label,
  placeHolder,
  defaultValue,
  id,
  size,
  width,
  value,
  required,
  valueSetter,
  otherProps,
  onChange,
  name,
  type = "password",
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" focused required={required}>
      {/* <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel> */}
      <TextField
        variant="outlined"
        label={label}
        placeholder={placeHolder}
        id={id}
        type={showPassword ? "text" : "password"}
        size={size}
        value={value}
        defaultValue={defaultValue}
        style={{ width: width }} // Apply width here
        onChange={onChange}
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        {...otherProps}
      />
    </FormControl>
  );
};
export const InputCheckboxBtn = ({
  width,
  label,
  id,
  size,
  placeHolder,
  val,
  value,
  setCheckBox,
  setFieldValue,
  onChange,
  required,
  onFileSelect,
  error,
  button,
  startAdornment,
  leftPo,
  rightPo,
  bottomPo,
  remove,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file ? file.name : null);
    if (onFileSelect && typeof onFileSelect === "function") {
      onFileSelect(file);
    }
  };

  return (
    <InputCheckboxBtnWrapper width={width}>
      <Input
        id={id}
        label={label}
        value={value}
        size={size}
        multiline
        type="number"
        startAdornment={
          startAdornment && (
            <InputAdornment position="start"> "$"</InputAdornment>
          )
        }
        placeholder={placeHolder}
        required={required}
        error={error}
        onChange={onChange}
        disabled={val === "No"}
        minRows={val === "Yes" ? 3 : 0}
        sx={{ width: "100%", display: remove }}
      />
      <CheckboxComp width="auto">
        {" "}
        <CheckboxSec>
          <React.Fragment>
            <Checkbox
              checked={val === "Yes" || false}
              onChange={(e) => {
                setFieldValue("educationExpenseCheck", true);
                setCheckBox((prev) => (prev === "Yes" ? false : "Yes"));
              }}
            />
            <Span color="#1B1B1B" fontSize="14px" fontWeight="400">
              Yes
            </Span>
            <Checkbox
              checked={val === "No" || false}
              onChange={(e) => {
                setFieldValue("educationExpenseCheck", false);
                setCheckBox((prev) => (prev === "No" ? false : "No"));
              }}
            />
            <Span color="#1B1B1B" fontSize="14px" fontWeight="400">
              No
            </Span>
          </React.Fragment>
        </CheckboxSec>
      </CheckboxComp>
      {val === "Yes" && (
        <>
          <CustomButton
            selectedFileName={selectedFileName}
            onClick={handleButtonClick}
            leftPo={leftPo}
            rightPo={rightPo}
            bottomPo={bottomPo}
          >
            {selectedFileName ? selectedFileName : button}
          </CustomButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </>
      )}
    </InputCheckboxBtnWrapper>
  );
};

const CustomButton = styled.div`
  position: absolute;
  bottom: ${({ bottomPo }) => (bottomPo ? bottomPo : "5px")};
  right: ${({ rightPo }) => (rightPo ? rightPo : "5px")};
  left: ${({ leftPo }) => (leftPo ? leftPo : "auto")};
  padding: ${({ selectedFileName }) => (selectedFileName ? "10px" : "0")};
  border: ${({ selectedFileName }) =>
    selectedFileName ? "1px solid rgba(2, 173, 238, 1)" : "none"};
  border-radius: ${({ selectedFileName }) => (selectedFileName ? "10px" : "0")};
`;
