import {
  AuthPicWrapper,
  SignupContainer,
} from "components/auth/signup/Signup.style";
import Auth from "assets/auth/Auth.png";
import SigninComp from "components/auth/signin/SigninComp";
import { styled, keyframes } from "@mui/material/styles";
import { IconButton, Tooltip, Paper } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

// Ripple animation keyframes
const rippleAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
`;

// Wrapper for positioning the tutorial button
const TutorialWrapper = styled("div")({
  position: "absolute",
  top: "20px",
  right: "20px",
  zIndex: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

// Ripple effect styling
const RippleEffect = styled("div")({
  position: "absolute",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  backgroundColor: "rgb(255, 255, 255)",
  animation: `${rippleAnimation} 1.5s infinite ease-out`,
  top: "0%",
  left: "0%",
  transform: "translate(-50%, -50%)",
  zIndex: -1,
});

// Button wrapper to keep the ripple behind the button
const TutorialButtonWrapper = styled("div")({
  position: "relative",
  display: "inline-flex",
});

// Styled tutorial button
const TutorialButton = styled(IconButton)({
  backgroundColor: "#0b2558",
  color: "#fff",
  width: "50px",
  height: "50px",
  "&:hover": {
    backgroundColor: "#0b2558",
  },
});

// Tooltip container
const TutorialTooltip = styled(Paper)({
  padding: "12px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
  marginTop: "8px",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});

// Title for tutorial popup
const TutorialTitle = styled("h3")({
  fontSize: "14px",
  fontWeight: 600,
  color: "#0b2558",
  marginBottom: "4px",
});

// Link for tutorial video
const TutorialLink = styled("a")({
  textDecoration: "none",
  color: "#0b2558",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  fontSize: "14px",
  fontWeight: "500",
  "&:hover": {
    color: "#0b2558",
    textDecoration: "underline",
  },
});

// Tutorial button component
const TutorialComponent = () => (
  <TutorialWrapper>
    <Tooltip title="Watch Tutorial" placement="left">
      <TutorialButtonWrapper>
        <RippleEffect />
        <TutorialButton>
          <PlayCircleIcon fontSize="large" />
        </TutorialButton>
      </TutorialButtonWrapper>
    </Tooltip>

    <TutorialTooltip>
      <TutorialTitle>Please Review Client Tutorial</TutorialTitle>
      <TutorialLink
        href="https://drive.google.com/file/d/1jCTpDueGa5RXF_AutW4V-62IEAWQ4mBm/view"
        target="_blank"
        rel="noopener noreferrer"
      >
        Watch Tutorial Video
        <PlayCircleIcon fontSize="small" />
      </TutorialLink>
    </TutorialTooltip>
  </TutorialWrapper>
);

// Main login component
const Signin = () => {
  return (
    <SignupContainer>
      <SigninComp />
      <AuthPicWrapper style={{ position: "relative" }}>
        {/* Tutorial Button with Ripple Effect */}
        <TutorialComponent />
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default Signin;
