import Grid from "@mui/material/Grid";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import { Button } from "components/reusable/button/Button";
import MessageContext from "components/reusable/chatCards/MessageContext";
import { ChatBox } from "components/reusable/chatLayout/index";
import CustomTable from "components/reusable/customTable/CustomTable";
import SecDropdownComp from "components/reusable/dropdownComp/SecDropdown";
import StaffHeader from "components/reusable/header/StaffHeader";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { v4 as uuid } from "uuid";
import { db } from "../../../FirebaseSetup";

const errorToast = (message = "") => toast.error(message);

const chatOptions = [
  {
    title: "Staff",
    value: "staff",
  },
  {
    title: "Customer Support Requests",
    value: "customerSupport",
  },
];

const ChatComp = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("0");
  const [activeTab, setActiveTab] = useState("staff"); // Set initial state to "staff"
  const [assignto, setassignto] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tab_loader, setTabLoader] = useState(true);
  const [enableRowSelection, setEnableRowSelection] = useState(false); // State to manage row selection

  const [selectedOption, setSelectedOption] = useState("All Messages");
  const [user, setUser] = useState("");
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);

  const [assignChange, setAssignChange] = useState({});
  const assigntotask = Array.isArray(assignto)
    ? assignto.map((client) => ({
        label: client.name,
        value: client,
      }))
    : [];

  const handleAssignChange = async (event, params) => {
    const staffUid = event.target.value.fireBaseUid;
    const clientUid = params.row.uid;
    const clientMessage = params.row.query;

    await initiateChatBetweenUsers(clientUid, staffUid, clientMessage);

    setAssignChange((prev) => ({
      ...prev,
      [params.row.id]: event.target.value,
    }));
    fetchUsers();
  };

  const initiateChatBetweenUsers = async (
    clientUid,
    staffUid,
    clientMessage,
    params
  ) => {
    const combinedId =
      clientUid > staffUid ? clientUid + staffUid : staffUid + clientUid;

    try {
      const chatDocRef = doc(db, "chats", combinedId);
      const chatDoc = await getDoc(chatDocRef);

      if (!chatDoc.exists()) {
        // Create chat document if it doesn't exist
        await setDoc(chatDocRef, { messages: [] });
      } else {
      }

      // Ensure usersChats document for clientUid exists
      const clientChatsDocRef = doc(db, "usersChats", clientUid);
      const clientChatsDoc = await getDoc(clientChatsDocRef);

      if (!clientChatsDoc.exists()) {
        await setDoc(clientChatsDocRef, {});
      }

      // Ensure usersChats document for staffUid exists
      const staffChatsDocRef = doc(db, "usersChats", staffUid);
      const staffChatsDoc = await getDoc(staffChatsDocRef);

      if (!staffChatsDoc.exists()) {
        await setDoc(staffChatsDocRef, {});
      }

      // Add the initial message to the chat
      const messageData = {
        id: uuid(),
        senderId: clientUid,
        text: clientMessage,
        date: Timestamp.now(),
      };

      await updateDoc(chatDocRef, {
        messages: arrayUnion(messageData),
      });

      // Update client and staff usersChats
      await updateDoc(clientChatsDocRef, {
        [`${combinedId}.userInfo`]: {
          uid: staffUid,
          displayName: "Staff Member",
        },
        [`${combinedId}.lastMessage`]: {
          text: clientMessage,
        },
        [`${combinedId}.date`]: serverTimestamp(),
      });

      await updateDoc(staffChatsDocRef, {
        [`${combinedId}.userInfo`]: {
          uid: clientUid,
          displayName: "Client",
        },
        [`${combinedId}.lastMessage`]: {
          text: clientMessage,
        },
        [`${combinedId}.date`]: serverTimestamp(),
      });

      // Update chatRequests to reflect staff assignment
      await updateDoc(doc(db, "chatRequests", params.row.id), {
        assignedStaff: staffUid,
        status: "assigned",
      });
    } catch (error) {
      console.error("Error initiating chat:", error);
    }
  };

  const currentUser = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    if (currentUser && currentUser.user.fireBaseUid) {
      const getChats = () => {
        const unsub = onSnapshot(
          doc(db, "usersChats", currentUser.user.fireBaseUid),
          (doc) => {
            setChats(doc.data());
          }
        );

        return () => {
          unsub();
        };
      };

      getChats();
    }
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveTab(buttonName);
  };

  const getChatRequests = async () => {
    try {
      setTabLoader(true);
      const response = await apiReq(`${API_ROUTES.getChatRequests}`, "POST", {
        chatStatus: "requested",
      });
      const responseData = response.data.status;

      // Create a new array to store unique emails
      const uniqueEmails = [];
      const uniqueData = responseData.filter((item) => {
        if (!uniqueEmails.includes(item.clientId.email)) {
          uniqueEmails.push(item.clientId.email);
          return true;
        }
        return false;
      });

      const updatedData = uniqueData.map((item, index) => {
        return {
          id: index + 1,
          srno1: index + 1,
          userName: item.clientId.firstName + " " + item.clientId.lastName,
          email: item.clientId.email,
          chatStatus: item.chatStatus,
          query: item.chatBody,
          cd: item.createdAt,
          uid: item.clientFireBaseUid,
        };
      });
      setRows(updatedData);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };

  const AssignChatToStaff = async () => {
    try {
      const response = await apiReq(API_ROUTES.getChatStaffs, "POST");
      const responseData = await response.data;

      // Filter the staff to only include those with activeStatus as Active
      const activeStaff = responseData.status.filter(
        (staff) => staff.activeStatus === "Active"
      );

      const updatedData = activeStaff.map((item, index) => {
        return {
          id: item?._id,
          name: item?.firstName + " " + item?.lastName,
          email: item?.email,
          fireBaseUid: item?.fireBaseUid,
        };
      });
      setassignto(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const individualColumns = [
    {
      field: "srno1",
      headerName: "Sr#",
      width: 20,
      editable: false,
    },
    {
      field: "userName",
      headerName: "Client Name",
      width: 180,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 220,
      editable: false,
    },
    {
      field: "query",
      headerName: "Client Query",
      width: 400,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "left",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "chatStatus",
      headerName: "Chat Status",
      width: 180,
      editable: false,
    },

    {
      headerName: "Staff Select",
      width: 300,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div className="assign-staff" style={{ width: "100%" }}>
          <SecDropdownComp
            label="Assign Chat To *"
            id="location-select"
            placeholder="select"
            size="small"
            width="100%"
            value={assignChange[params.row.id] || ""}
            options={assigntotask}
            selectedMonth={selectedOption}
            handleChange={(event) => handleAssignChange(event, params)}
          />
        </div>
      ),
    },
  ];

  const handleSearch = async (searchValue) => {
    setSearchQuery(searchValue); // Store search query

    if (!searchValue?.trim()) {
      fetchUsers(); // Reset to all users if search is empty
      return;
    }

    try {
      const searchLower = searchValue.toLowerCase();

      // First get all users based on role
      const q = query(
        collection(db, "users"),
        where("role", "==", activeTab === "staff" ? "Staff" : "")
      );

      const querySnapshot = await getDocs(q);
      const allUsers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Then filter locally by displayName
      const filteredUsers = allUsers.filter(
        (user) =>
          user.displayName?.toLowerCase().includes(searchLower) &&
          user.email !== currentUser.user.email
      );

      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error searching users:", error);
      // On error, reset to all users
      fetchUsers();
    }
  };
  // const hanldeSearchStaff = (searchTerm) => {
  //   if (typeof searchTerm !== "string") {
  //     return;
  //   }

  //   const trimmedSearchTerm = searchTerm.trim();

  //   if (trimmedSearchTerm === "") {
  //     fetchUsers();
  //     return;
  //   }

  //   const lowerCaseSearchTerm = trimmedSearchTerm.toLowerCase();

  //   const filteredUsers = users.filter((user) =>
  //     user.displayName.toLowerCase().includes(lowerCaseSearchTerm)
  //   );
  //   setUsers(filteredUsers);
  // };

  const handleFirstSelect = async (user) => {
    if (user) {
      const currentUser = JSON.parse(localStorage.getItem("userData"));
      const email = currentUser?.user?.email;
      const fullName = email.substring(0, 2);

      const combinedId =
        currentUser.user.fireBaseUid > user.uid
          ? currentUser.user.fireBaseUid + user.uid
          : user.uid + currentUser.user.fireBaseUid;

      try {
        const chatDoc = await getDoc(doc(db, "chats", combinedId));

        if (!chatDoc.exists()) {
          await setDoc(doc(db, "chats", combinedId), { messages: [] });

          await updateDoc(doc(db, "usersChats", currentUser.user.fireBaseUid), {
            [combinedId + ".userInfo"]: {
              uid: user.uid,
              displayName: user.displayName,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });

          await updateDoc(doc(db, "usersChats", user.uid), {
            [combinedId + ".userInfo"]: {
              uid: currentUser.user.fireBaseUid,
              displayName: fullName,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });
        }

        setUser(null);
        handleSelect(user);
      } catch (err) {
        console.error("Error starting chat:", err);
      }
    }
  };

  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          chatId:
            currentUser.user.fireBaseUid > action.payload.uid
              ? currentUser.user.fireBaseUid + action.payload.uid
              : action.payload.uid + currentUser.user.fireBaseUid,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);
  const handleSelect = (writerObj) => {
    dispatch({ type: "CHANGE_USER", payload: writerObj });
    getChatList();
  };

  const getChatList = () => {
    const unSub = onSnapshot(doc(db, "chats", state.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });
  };

  const fetchUsers = async () => {
    try {
      let querySnapshot;

      switch (activeTab) {
        case "staff":
          querySnapshot = await getDocs(
            query(collection(db, "users"), where("role", "==", "Staff"))
          );
          break;
        default:
          querySnapshot = await getDocs(collection(db, "users"));
      }

      const fetchedUsers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    getChatRequests();
    AssignChatToStaff();
  }, [activeTab]);

  return (
    <>
      <RentalPropertyContainer>
        <RentalPropertyWrapper>
          <Grid container spacing={2}>
            <Grid item xs={5} lg={3}>
              <SideBoxStyle>
                {activeTab === "staff" && (
                  <StaffHeader
                    text=""
                    btntext=""
                    bgColor="white"
                    showButton={false}
                    showFirstSelect={false}
                    clickHandler={() => {}}
                    showSecondSelect={false}
                    placeholder="Select Status"
                    searchUsers={handleSearch} // Pass the search function
                  />
                )}
                <StyledPara />

                <ToggleButtonDiv>
                  {chatOptions.map((val, index) => (
                    <Button
                      key={index}
                      text={val.title}
                      fontSize="14px"
                      width="250px"
                      height="40px"
                      smHeight="auto"
                      padding="0px"
                      margin="5px"
                      borderRadius="10px"
                      backgroundColor={
                        activeTab === val.value ? "#00ADEF" : "white"
                      }
                      color={activeTab === val.value ? "white" : "black"}
                      onClick={() => handleButtonClick(val.value)}
                    />
                  ))}
                </ToggleButtonDiv>
                <Line border="0.0999999999999999px solid rgba(0, 0, 0, 0.4)" />
                <StyledPara />
                <div style={{ overflowY: "scroll", height: "65vh" }}>
                  {activeTab === "staff" ? (
                    <MessageContext
                      chats={chats}
                      currentUser={currentUser.user.email}
                      handleUser={handleFirstSelect}
                      users={users}
                      activeTab={activeTab}
                      searchQuery={searchQuery} // Pass search query
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </SideBoxStyle>
            </Grid>
            <Grid item xs={7} lg={9}>
              {activeTab === "staff" ? (
                <ChatBox
                  data={state}
                  currentUser={currentUser.user.fireBaseUid}
                  userName={currentUser.user.firstName}
                />
              ) : (
                <CustomTable
                  rows={rows}
                  loading={tab_loader}
                  enableRowSelection={enableRowSelection}
                  columns={individualColumns}
                />
              )}
            </Grid>
          </Grid>
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default ChatComp;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  height: 100%;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

const SideBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  grid-gap: 5px;
  background: #eff3fd;
  width: 100%;
  padding: 15px;
  min-height: 82%;
`;

const SideBoxButtonStyle = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  height: 100px;
  margin-top: 10px;
  justify-content: space-between;
  &.active {
    background-color: rgba(0, 173, 239, 1);
    color: white;
  }
`;

const SideBoxTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const HeadingTwo = styled.h2`
  font-weight: 400;
  font-size: 25px;
  padding: 10px;
`;

export const ToggleButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background: white;
  align-item: center;
  border: 1px solid #00adef;
  margin-bottom: 12px;
`;

export const StyledPara = styled.p`
  margin-top: 12px;
`;
