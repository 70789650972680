import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Input, InputWithAdornment } from "../input/Inputs";
import styled from "styled-components";
import { Stack } from "@mui/system";
import { FormHelperText } from "@mui/material";

const AccordionComp = ({
  item,
  index,
  openIndex,
  setOpenIndex,
  values,
  setFieldValue,
  errors,
}) => {
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };

  return (
    <div>
      <Accordion
        sx={{ backgroundColor: "#EBEFF9" }}
        expanded={isOpen}
        onChange={toggleAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          {item.title}
        </AccordionSummary>
        <AccordionDetails>
          <InputWrapper>
            {item?.fields?.map(
              ({ label, id, key, size, width, placeholder }, index) => (
                <Stack sx={{ width: "49%" }}>
                  {/* <Input
                    key={index}
                    label={label}
                    id={id}
                    size={size}
                    width="100%"
                    value={values?.[item.id]?.[key] || ""}
                    onChange={(e) => {
                      let val =
                        key === "model"
                          ? e.target.value
                          : e.target.value.slice(0, 9);
                      setFieldValue(`${item.id}.${key}`, val);
                    }}
                    placeholder={placeholder}
                  /> */}
                  <InputWithAdornment
                     key={index}
                     label={label}
                     id={id}
                     size={size}
                     width="100%"
                     value={values?.[item.id]?.[key] || ""}
                     onChange={(e) => {
                       let val =
                         key === "model"
                           ? e.target.value
                           : e.target.value.slice(0, 9);
                       setFieldValue(`${item.id}.${key}`, val);
                     }}
                     placeholder={placeholder}
                  />
                  <FormHelperText error>
                    {errors?.[item.id]?.[key]}
                  </FormHelperText>
                </Stack>
              )
            )}
          </InputWrapper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionComp;

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 15px;
  width: 100%;
  flex-wrap: wrap;
`;
