import { useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { PageLoaderContext } from 'context/PageLoaderProvider';
import { FadeLoader } from 'react-spinners';

export const PageLoader = () => {
  const {open} = useContext(PageLoaderContext);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={open}
        // onClick={handleToggle}
      >
        <FadeLoader color="#00ADEF" size={50} />
      </Backdrop>
    </div>
  );
}