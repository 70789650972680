import axios from "axios";
import { API_BASE_URL } from "../utils/apiRoutes.constant";

axios.interceptors.request.use((value) => {
  value.headers = {
    token: "",
  };
  return value;
});

export const apiReq = async (
  path,
  method = "GET",
  body = {},
  baseurl = API_BASE_URL,
  isJsonType = true
) => {
  try {
    if (!path) {
      throw new Error("Missing parameters");
    }
    const url = baseurl + path;

    if (method === "GET") {
      return await axios
        .get(url, {
          params: body,
        })
        .then((res) => res)
        .catch((err) => Promise.reject(err));
    } else if (method === "POST") {
      return await axios
        .post(url, body, {
          headers: {
            "Content-Type": isJsonType
              ? "application/json"
              : "multipart/form-data",
          },
        })
        .then((res) => res)
        .catch((err) => Promise.reject(err));
    } else if (method === "PATCH") {
      return await axios
        .patch(url, body)
        .then((res) => res)
        .catch((err) => Promise.reject(err));
    } else {
      throw new Error("Invalid method: " + method);
    }
  } catch (err) {
    // console.log(err.response.data.message, "err");
    let errorMessage = "Failed to do something exceptional";
    if (err instanceof Error) {
      errorMessage = err.message;
    }
    throw new Error(err.response.data.message);
  }
};
