export const settingFields = [
  {
    label: "First Name",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
    name:"firstName"
  },
  {
    label: "Last Name",
    placeHolder: "Enter Last Name",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
    name:"lastName"
  },
  {
    label: "Email",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
    name:"email"
  },
  {
    label: "Contact",
    placeHolder: "+1| Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
    name:"phone"
  },
  // {
  //   label: "Address",
  //   placeHolder: "Enter",
  //   defaultValue: "",
  //   id: "outlined-size-small",
  //   size: "size",
  //   width: "32%",
  //   value: "",
  //   name:"address"
  // },
];
