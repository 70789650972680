import Logo from "assets/auth/Logo.png";
import LoginVerModal from "components/modals/LoginVerModal";
import { Button } from "components/reusable/button/Button";
import { Span } from "components/reusable/typography/Typography.style";
import { ProfileContext } from "context/ProfileContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { db } from "services/firebaseSetup";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as Yup from "yup";
import {
  ForgetPasswordWrapper,
  LoginMessageWrapper,
} from "../signin/Signin.style";
import {
  FieldWrapper,
  FormLogo,
  FormSec,
  FormWrapper,
  StyledInput,
} from "../signup/Signup.style";

const errorToast = (message = "") => toast.error(message);

const AdminLogin = () => {
  const navigate = useNavigate();
  const [, setLoading] = useState(false);
  const { setadminstaffProfileData } = useContext(ProfileContext);
  const [showLoginVerificationModal, setShowLoginVerificationModal] =
    useState(false);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("email is required"),
    password: Yup.string().required("Password is required"),
  });
  const [userEmail, setUserEmail] = useState();
  const [otp, setOtp] = useState("000000");
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const email = values.email;
      const usersQuery = query(
        collection(db, "users"),
        where("email", "==", email)
      );
      const usersSnapshot = await getDocs(usersQuery);

      if (!usersSnapshot.empty) {
        await Promise.all(
          usersSnapshot.docs.map(async (doc) => {
            const userData = doc.data();
            const userRole = userData.role;
            console.log(userData, "user role");
            if (userRole === "Admin") {
              setLoading(true);
              const obj = {
                ...values,
                role: "Admin",
              };
              const response = await apiReq(API_ROUTES.userLogin, "POST", obj);
              setUserEmail(response.data.data.email);
              setShowLoginVerificationModal(true);

              // localStorage.setItem(
              //   "userData",
              //   JSON.stringify(response.data?.data)
              // );
              // setadminstaffProfileData(response?.data?.data);
              // localStorage.setItem("auth", true);
              // localStorage.setItem("clientId", response.data.data.user._id);
              // navigate("/admin-dashboard");
            } else {
              errorToast("User not found");
            }
          })
        );
      }
    } catch (error) {
      errorToast(
        error?.message || "Error while login. please try again later."
      );
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleOTPVerification = async (otp) => {
    try {
      console.log("Attempting API request...");
      const payload = {
        email: userEmail,
        otp: otp,
        role: "Admin",
      };
      const response = await apiReq(API_ROUTES.loginOTPVerify, "POST", payload);

      if (response.status === 200) {
        setShowLoginVerificationModal(false);
        localStorage.setItem("userData", JSON.stringify(response.data?.data));
        setadminstaffProfileData(response?.data?.data);
        localStorage.setItem("auth", true);
        localStorage.setItem("clientId", response.data.data.user._id);
        navigate("/admin-dashboard");
      }
    } catch (error) {
      errorToast("Error during OTP verification. Please try again later.");
    }
  };
  //     else if (userRole === 'Staff') {
  //       setLoading(true);
  //       const obj = {
  //         ...values,
  //         role: "Staff",
  //       };
  //       const response = await axios.post("https://remedy-tax-db.vercel.app/auth/user/login", obj);
  //       localStorage.setItem("userData", JSON.stringify(response.data?.data));
  //       setadminstaffProfileData(response?.data?.data);
  //       localStorage.setItem("auth", true);
  //       localStorage.setItem("clientId", response.data.data.user._id);
  //       navigate("/staffDashboard");
  //     } else {
  //       errorToast("Invalid role");
  //     }
  //   }));
  // }
  const onClose = () => {
    setShowLoginVerificationModal(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <FormWrapper>
            <FormSec gridGap="30px">
              <FormLogo>
                <img src={Logo} alt="Logo" />
              </FormLogo>
              <LoginMessageWrapper>
                <h5>Admin Portal</h5>
                <p>Enter your credentials to login your account.</p>
              </LoginMessageWrapper>
              <FieldWrapper>
                <p>Email Address</p>
                <Field
                  className="emailComp"
                  type="email"
                  name="email"
                  component={StyledInput}
                  placeholder="Email"
                  onChange={(e) =>
                    formikProps.setFieldValue("email", e.target.value)
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message "
                />
              </FieldWrapper>
              <ForgetPasswordWrapper>
                <FieldWrapper>
                  <p>Password</p>
                  <Field
                    className="emailComp"
                    type={showPass ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    component={StyledInput}
                    onChange={(e) =>
                      formikProps.setFieldValue("password", e.target.value)
                    }
                  />
                  <h5 onClick={() => setShowPass(!showPass)}>
                    {showPass ? "HIDE" : "SHOW"}
                  </h5>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message "
                  />
                </FieldWrapper>
                <Span
                  color="#fff"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="end"
                  cursor="pointer"
                >
                  <p onClick={() => navigate("/admin-forget")}>
                    {" "}
                    Forgot Password
                  </p>
                </Span>
              </ForgetPasswordWrapper>
              <Button
                text={
                  formikProps.isSubmitting ? (
                    <SyncLoader color={"#FFFFFF"} size={8} />
                  ) : (
                    "Log in"
                  )
                }
                type="submit"
                width="100%"
                fontSize="18px"
              />
            </FormSec>
          </FormWrapper>
        )}
      </Formik>
      <LoginVerModal
        otp={otp}
        setOtp={setOtp}
        open={showLoginVerificationModal}
        onVerify={handleOTPVerification}
        email={userEmail}
        onClose={onClose}
      />
    </>
  );
};

export default AdminLogin;
