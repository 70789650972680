import React, { useContext, useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormHelperText } from "@mui/material";
import { Stack } from "@mui/system";
import { Input } from "components/layout/navbar/Navbar.style";
import { Button } from "components/reusable/button/Button";
import { ButtonIcon } from "components/reusable/button/IconButton";
import FlexComp from "components/reusable/flex/Flex";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { useFormik } from "formik";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import {
  RetirementContainer,
  RetirementWrapper,
} from "./PersonalCollection.style";

const initialValues = {
  tspWithdrawal: false,
  receiveSocialSecurityBenefits: false,
  cryptoSales: false,
  withdrawalDoc: "",
  withdrawalDocFileName: "",
  socialSecurityBenefitDocFileName: "",
  salesDocFileName: "",
  socialSecurityBenefitDoc: "",
  salesDoc: "",
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const Retirement = ({ setCurrentTab, rowData: businessData }) => {
  const withdrawalDocRef = useRef(null);
  const socialSecurityBenefitDocRef = useRef(null);
  const salesDocRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [sales, setSales] = useState("No");
  const [fileName, setFileName] = useState({
    withdrawalDoc: "",
    socialSecurityBenefitDoc: "",
    salesDoc: "",
  });
  const [benefits, setBenefits] = useState("No");
  const clientId = localStorage.getItem("clientId");
  const [withdrawal, setWithDrawal] = useState("No");
  const { setOpen } = useContext(PageLoaderContext);
  const personalCollectionId = localStorage.getItem("personalCollectionId");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [retirementId, setRetirementId] = useState(null);

  const handleUploadButtonClick = (e, type) => {
    e.preventDefault();
    if (type === "withdrawalDoc") withdrawalDocRef.current.click();
    if (type === "socialSecurityBenefitDoc")
      socialSecurityBenefitDocRef.current.click();
    if (type === "salesDoc") salesDocRef.current.click();
  };

  const resetFieldsForNo = (fieldName, docField, docFileName) => {
    setFieldValue(fieldName, false);
    setFieldValue(docField, "");
    setFieldValue(docFileName, "");
    setFileName((prev) => ({
      ...prev,
      [docField]: "",
    }));
  };

  const {
    values,
    errors,
    touched,
    setValues,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (!clientId) {
          errorToast("clientId not found!");
          return;
        }
        const id = personalCollectionId.replace(/"/g, "");
        const formData = new FormData();
        formData.append("clientId", clientId.toString());
        formData.append("personalCollectionId", id);

        // Add retirementId for update requests
        if (edit && retirementId) {
          formData.append("retirementId", retirementId);
        }

        for (const [key, value] of Object.entries(values)) {
          if (key.includes("Doc")) {
            if (
              (key === "withdrawalDoc" && values.tspWithdrawal) ||
              (key === "socialSecurityBenefitDoc" &&
                values.receiveSocialSecurityBenefits) ||
              (key === "salesDoc" && values.cryptoSales)
            ) {
              formData.append(key, value);
            }
          } else {
            formData.append(key, value.toString());
          }
        }

        await apiReq(
          `${edit ? API_ROUTES.updateRetirement : API_ROUTES.addRetirement}`,
          "POST",
          formData,
          API_BASE_URL,
          false
        );

        if (!edit) {
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, healthCare: false })
          );
        }

        infoToast(
          edit
            ? "Retirement info updated successfully"
            : "Retirement info saved successfully"
        );
        setTimeout(() => {
          setCurrentTab(2);
        }, 2000);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchRetirement = async () => {
      try {
        setOpen(true);
        const id = (businessData?._id || personalCollectionId).replace(
          /"/g,
          ""
        );
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getPerosnalCollection + id,
          "POST",
          { clientId }
        );

        if (Object.keys(data?.retirementId || {}).length > 0) {
          const retirementData = data?.retirementId;
          setEdit(true);
          setRetirementId(retirementData?._id); // Store the retirementId
          setWithDrawal(retirementData?.tspWithdrawal ? "Yes" : "No");
          setBenefits(
            retirementData?.receiveSocialSecurityBenefits ? "Yes" : "No"
          );
          setSales(retirementData?.cryptoSales ? "Yes" : "No");

          setValues({
            tspWithdrawal: retirementData?.tspWithdrawal || false,
            receiveSocialSecurityBenefits:
              retirementData?.receiveSocialSecurityBenefits || false,
            cryptoSales: retirementData?.cryptoSales || false,
            withdrawalDoc: retirementData?.withdrawalDoc || "",
            withdrawalDocFileName: retirementData?.withdrawalDocFileName || "",
            socialSecurityBenefitDoc:
              retirementData?.socialSecurityBenefitDoc || "",
            socialSecurityBenefitDocFileName:
              retirementData?.socialSecurityBenefitDocFileName || "",
            salesDoc: retirementData?.salesDoc || "",
            salesDocFileName: retirementData?.salesDocFileName || "",
          });

          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, healthCare: false })
          );
        }
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };
    fetchRetirement();
  }, []);

  const handleOptionChange = (option, value) => {
    switch (option) {
      case "withdrawal":
        setWithDrawal(value);
        if (value === "No") {
          resetFieldsForNo(
            "tspWithdrawal",
            "withdrawalDoc",
            "withdrawalDocFileName"
          );
        } else {
          setFieldValue("tspWithdrawal", true);
        }
        break;
      case "benefits":
        setBenefits(value);
        if (value === "No") {
          resetFieldsForNo(
            "receiveSocialSecurityBenefits",
            "socialSecurityBenefitDoc",
            "socialSecurityBenefitDocFileName"
          );
        } else {
          setFieldValue("receiveSocialSecurityBenefits", true);
        }
        break;
      case "sales":
        setSales(value);
        if (value === "No") {
          resetFieldsForNo("cryptoSales", "salesDoc", "salesDocFileName");
        } else {
          setFieldValue("cryptoSales", true);
        }
        break;
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <RetirementContainer>
        <HeadingFive fontSize="18px" fontWeight="500" color="1b1b1b">
          Retirements
        </HeadingFive>
        <RetirementWrapper>
          <FlexComp direction="row" gridGap="50px">
            <Span
              color="#1B1B1B"
              fontSize="16px"
              fontWeight="500"
              textAlign="start"
            >
              <p style={{ width: "260px" }}>401K/TSP Withdrawal</p>
            </Span>
            <div style={{ width: "260px" }}>
              <TextCheckbox
                text="Yes (1099R required)"
                gridGap="3px"
                isChecked={withdrawal === "Yes"}
                onChange={() =>
                  handleOptionChange(
                    "withdrawal",
                    withdrawal === "Yes" ? "No" : "Yes"
                  )
                }
              />
            </div>
            <TextCheckbox
              text="No"
              gridGap="3px"
              isChecked={withdrawal === "No"}
              onChange={() =>
                handleOptionChange(
                  "withdrawal",
                  withdrawal === "No" ? "Yes" : "No"
                )
              }
            />
            {withdrawal === "Yes" && (
              <Stack direction="row" gap={5}>
                <ButtonIcon
                  width="100%"
                  icon={<CloudUploadIcon />}
                  text={
                    values?.withdrawalDoc
                      ? fileName?.withdrawalDoc || values?.withdrawalDocFileName
                      : "Upload Required Documents"
                  }
                  fontSize="12px"
                  clickHandler={(e) =>
                    handleUploadButtonClick(e, "withdrawalDoc")
                  }
                />
                <Input
                  type="file"
                  id="withdrawalDoc"
                  ref={withdrawalDocRef}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setFieldValue(
                      "withdrawalDoc",
                      event?.currentTarget?.files[0]
                    );
                    setFileName({
                      ...fileName,
                      withdrawalDoc: event?.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                <FormHelperText error>
                  {touched?.withdrawalDoc && errors?.withdrawalDoc}
                </FormHelperText>
              </Stack>
            )}
          </FlexComp>

          <FlexComp direction="row" gridGap="50px" marginTop="10px">
            <Span
              color="#1B1B1B"
              fontSize="16px"
              fontWeight="500"
              textAlign="start"
            >
              <p style={{ width: "260px" }}>Receive Social Security Benefits</p>
            </Span>
            <div style={{ width: "260px" }}>
              <TextCheckbox
                text="Yes (1099SSA required)"
                gridGap="3px"
                isChecked={benefits === "Yes"}
                onChange={() =>
                  handleOptionChange(
                    "benefits",
                    benefits === "Yes" ? "No" : "Yes"
                  )
                }
              />
            </div>
            <TextCheckbox
              text="No"
              gridGap="3px"
              isChecked={benefits === "No"}
              onChange={() =>
                handleOptionChange("benefits", benefits === "No" ? "Yes" : "No")
              }
            />
            {benefits === "Yes" && (
              <Stack direction="row" gap={5}>
                <ButtonIcon
                  width="100%"
                  icon={<CloudUploadIcon />}
                  text={
                    values?.socialSecurityBenefitDoc
                      ? fileName?.socialSecurityBenefitDoc ||
                        values?.socialSecurityBenefitDocFileName
                      : "Upload Required Documents"
                  }
                  fontSize="12px"
                  clickHandler={(e) =>
                    handleUploadButtonClick(e, "socialSecurityBenefitDoc")
                  }
                />
                <Input
                  type="file"
                  id="socialSecurityBenefitDoc"
                  ref={socialSecurityBenefitDocRef}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setFieldValue(
                      "socialSecurityBenefitDoc",
                      event?.currentTarget?.files[0]
                    );
                    setFileName({
                      ...fileName,
                      socialSecurityBenefitDoc:
                        event?.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                <FormHelperText error>
                  {touched?.socialSecurityBenefitDoc &&
                    errors?.socialSecurityBenefitDoc}
                </FormHelperText>
              </Stack>
            )}
          </FlexComp>

          <FlexComp direction="row" gridGap="50px" marginTop="10px">
            <Span
              color="#1B1B1B"
              fontSize="16px"
              fontWeight="500"
              textAlign="start"
            >
              <p style={{ width: "260px" }}>Stocks/Crypto Sales</p>
            </Span>
            <div style={{ width: "260px" }}>
              <TextCheckbox
                text="Yes (1099B required)"
                gridGap="3px"
                isChecked={sales === "Yes"}
                onChange={() =>
                  handleOptionChange("sales", sales === "Yes" ? "No" : "Yes")
                }
              />
            </div>
            <TextCheckbox
              text="No"
              gridGap="3px"
              isChecked={sales === "No"}
              onChange={() =>
                handleOptionChange("sales", sales === "No" ? "Yes" : "No")
              }
            />
            {sales === "Yes" && (
              <Stack direction="row" gap={5}>
                <ButtonIcon
                  width="100%"
                  icon={<CloudUploadIcon />}
                  text={
                    values?.salesDoc
                      ? fileName?.salesDoc || values?.salesDocFileName
                      : "Upload Required Documents"
                  }
                  fontSize="12px"
                  clickHandler={(e) => handleUploadButtonClick(e, "salesDoc")}
                />
                <Input
                  type="file"
                  id="salesDoc"
                  ref={salesDocRef}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setFieldValue("salesDoc", event?.currentTarget.files[0]);
                    setFileName({
                      ...fileName,
                      salesDoc: event?.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                <FormHelperText error>
                  {touched?.salesDoc && errors?.salesDoc}
                </FormHelperText>
              </Stack>
            )}
          </FlexComp>
        </RetirementWrapper>
      </RetirementContainer>
      <FlexComp direction="row" justifyContent="flex-end">
        <Button
          text={
            isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"
          }
          type="submit"
          width="15%"
          fontSize="18px"
          backgroundColor="#0B2558"
        />
      </FlexComp>
    </form>
  );
};

export default Retirement;
