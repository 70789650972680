import { Avatar } from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import card from "assets/chat/BotPic.png";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const MessageContext = ({
  activeTab,
  users,
  handleUser,
  currentUser,
  chats,
}) => {
  const context = {
    name: "Rehan Khan",
    address: "3386 Central Avenue",
    timeAgo: "6 min ago",
    // message: '2 Rehan Khan 3386 Central Avenue 6 min ago 2',
    img: card,
  };

  const newArray = users.filter((item) => {
    return item.email != currentUser;
  });
  const filteredUsers =
    activeTab === "staff"
      ? newArray.filter((user) => user.role === "Staff")
      : activeTab === "my_clients" || activeTab === "all"
      ? newArray.filter((user) => user.role === "")
      : newArray.filter((user) => user.role === "Admin");

  console.log(filteredUsers, "newArray");
  console.log(activeTab, "activeTab");

  const getMessage = (uid) => {
    let lastMessage = null;

    for (const chatId in chats) {
      const chat = chats[chatId];
      if (chat.userInfo && chat.userInfo.uid === uid && chat.lastMessage) {
        lastMessage = chat?.lastMessage?.text; // Access the text property of lastMessage
        break; // Exit the loop once the lastMessage is found
      }
    }

    return lastMessage;
  };

  const getTime = (uid) => {
    let seconds = 0,
      minutes = 0,
      date = {};
    for (const chatId in chats) {
      const chat = chats[chatId];
      if (chat?.userInfo?.uid === uid) {
        date = chat.date;
        break; // Exit the loop once the lastMessage is found
      }
    }
    if (date?.nanoseconds) seconds = Math.floor(date.nanoseconds / 1e9);
    if (date?.seconds) minutes = Math.floor(date.seconds / 60);

    if (minutes > 0 && minutes <= 59) {
      return `${minutes} ago121`;
    } else if (minutes > 59) {
      const hours = Math.floor(minutes / 60);
      console.log({ minutes, hours });
      const remainingMinutes = minutes % 60;
      if (hours >= 1 && hours < 24) return `${hours}h ${remainingMinutes} ago`;
      else if (hours >= 24) {
        const days = Math.floor(hours / 24);
        return `${days} ago`;
      }
    } else return "";
  };

  console.log(chats, "chats");

  return (
    <>
      {filteredUsers
        .slice() // Create a copy of filteredUsers to avoid mutating the original array
        .sort((a, b) => b.date?.seconds - a.date?.seconds) // Sort by timestamp in descending order
        // .sort((a, b) => b.chats?.date?.seconds - a.chats?.date?.seconds)  // Sort by timestamp in descending order
        .map((chat) => (
          <div
            key={chat.id} // Use chat.id instead of chats.id
            style={{
              background: "white",
              width: "auto",
              height: "auto",
              borderRadius: "5px",
              border: "none",
              padding: "10px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleUser(chat)}
          >
            <div style={{ display: "flex" }}>
              <Avatar
                sx={{ margin: "5px", width: "60px", height: "60px" }}
                alt="User Avatar"
                src={chat.photoURL || context.img}
              />
              <div>
                <p style={{ fontSize: "18px" }}>{chat.displayName}</p>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      paddingLeft: "5px",
                      color: "rgba(134, 148, 151, 1)",
                    }}
                  >
                    {getMessage(chat?.uid)}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  fontSize: "14px",
                  paddingLeft: "5px",
                  color: "rgba(134, 148, 151, 1)",
                }}
              >
                {/* {getTime(chat?.uid)} */}
              </p>
            </div>
          </div>
        ))}
    </>
  );
};

export default MessageContext;
