import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react'

const Formation1099 = ({sheetData}) => {
  return (
    <>
      <Box sx={{width: "100%"}}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", color: "#000" }}
          >
            Tax Year:{" "}
            <span style={{ fontWeight: "400" }}>
              {dayjs(sheetData.taxYear).format("YYYY")}
            </span>
          </Typography>
        </Box>
        {sheetData.businessInfoArr.map((item, i) => {
          return (
            <>
              <Box
                sx={{
                  background: "#EFF3FD99",
                  padding: "20px",
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    background: "#0B2558",
                    height: "40px",
                    padding: "0 20px",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  Vendors{i == 0 ? "" : i + 1} Information  
                </Box>

                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        First Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.firstName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Middle Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.middleName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Last Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.lastName}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Entity Info
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.entityInfo}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Business Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.businessName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Mobile Number
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.mobileNumber}</Typography>
                    </div>
                  </Grid>
                
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                      <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        EIN
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.ssn}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.street}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        City
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.city}</Typography>
                    </div>
                  </Grid>
                 
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                     <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.state}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.zipCode}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        All Money Paid
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.amountPaid}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
    </>
  )
}

export default Formation1099