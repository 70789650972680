import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { Button } from "../reusable/button/Button";
import { HeadingFive } from "../reusable/typography/Typography.style";
import { EmailVerContainer1, ModalHeading, ModalText } from "./Modals.style";

const ResetVerifyModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      // onClose={onClose}
      // disableBackdropClick
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      <EmailVerContainer1>
        <ModalHeading>
          <HeadingFive fontSize="36px">Email Verified!</HeadingFive>
          <CloseIcon
            sx={{ color: "#000", fontSize: "40px", cursor: "pointer" }}
            onClick={onClose}
          />
        </ModalHeading>
        <ModalText>
          <p style={{ fontSize: "14px" }}>
            You have successfully verified the account.
          </p>
        </ModalText>

        <Button
          text="Go to Reset Screen"
          width="100%"
          fontSize="18px"
          onClick={() => navigate("/change-password")}
        />
      </EmailVerContainer1>
    </Modal>
  );
};

export default ResetVerifyModal;
