import icon1 from "assets/StaffDashboardIcons/icon1.png";
import icon2 from "assets/StaffDashboardIcons/icon2.png";
import icon3 from "assets/StaffDashboardIcons/icon3.png";
import icon4 from "assets/StaffDashboardIcons/icon4.png";
import DashboardCard from "../../client/cards/DashboardCard";
import {
  HeadingFive
} from "../../reusable/typography/Typography.style";
import {
  CardsContainer,
  HeaderTitle,
  SummaryWrapper,
  TitleSec,
} from "./Dashboard.style";
const IconImage = ({ src }) => <img src={src} alt="Icon" />;
const StaffSummaryComp = ({ showExportButton = true, userData, staff }) => {
  const dashboardCard2 = [
    {
      icon: <IconImage src={icon1} />,
      number: userData?.summary?.paidClients,
      key: "paidClients",
      title: "Paid Clients",
      value: "",
    },
    {
      icon: <IconImage src={icon2} />,
      number: userData?.summary?.unPaidClients,
      key: "unPaidClients",
      title: "Unpaid Clients",
      value: "",
    },
    {
      icon: <IconImage src={icon3} />,
      number: userData?.summary?.taxFillingCompleted,
      key: "taxFillingCompleted",
      title: "Tax Filling Completed",
      value: "",
    },
    {
      icon: <IconImage src={icon4} />,
      number: userData?.summary?.totalClients,
      key: "totalClients",
      title: "Total Clients",
      value: "",
    },
    {
      icon: <IconImage src={icon4} />,
      number: userData?.summary?.totalStaff,
      key: "totalStaff",
      title: "Total Staff",
      value: "",
    },
  ];
  const staffDashboard = [
    {
      icon: <IconImage src={icon1} />,
      number: userData?.summary?.myTasks,
      key: "myTasks",
      title: "My Task",
      value: "",
    },
    {
      icon: <IconImage src={icon2} />,
      number: userData?.summary?.completedTasks,
      key: "completedTasks",
      title: "Completed Task",
      value: "",
    },
    {
      icon: <IconImage src={icon3} />,
      number: userData?.summary?.pendingTasks,
      key: "pendingTasks",
      title: "Pending Task",
      value: "",
    },
    {
      icon: <IconImage src={icon4} />,
      number: userData?.summary?.rejectedTasks,
      key: "rejectedTasks",
      title: "Rejected Task",
      value: "",
    },
    {
      icon: <IconImage src={icon4} />,
      number: userData?.summary?.myClients,
      key: "myClients",
      title: "My Clients",
      value: "",
    },
  ];
  const array = staff ? staffDashboard : dashboardCard2;
  return (
    <SummaryWrapper>
      <HeaderTitle>
        <TitleSec>
          <HeadingFive fontSize="20px" fontWeight="600">
            Summary
          </HeadingFive>
        </TitleSec>
        {/* {showExportButton && (
          <ButtonIcon
            border="1px solid rgba(195, 211, 226, 1)"
            height="40px"
            fontSize="14px"
            bgColor="none"
            text="Export"
            color="black"
            icon={<IosShareIcon fontSize="small" />}
          />
        )} */}
      </HeaderTitle>

      <CardsContainer>
        {array.map(({ title, value, number, icon, key }, index) => (
          <DashboardCard
            key={index}
            icon={icon}
            title={title}
            number={number}
            titleFontSize={24}
            numberFontSize={16}
            valueFontSize={12}
            mediaWidth="45%"
            width="30%"
            value={userData && userData[key]} // Access value from userData using the key
          />
        ))}
      </CardsContainer>
    </SummaryWrapper>
  );
};

export default StaffSummaryComp;
