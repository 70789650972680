import TableComp from "../../reusable/tables/Table";
import { RequiredTaskContainer } from "../../client/dashboard/Dashboard.style";
import { HeadingFive } from "../../reusable/typography/Typography.style";
import { formatCategoryName } from "utils/Helpers";

const StaffQueueStatus = ({userData, admin}) => {
  const taskData = transformTaskData(userData && userData.queuesStatus);
  
  return (
    <RequiredTaskContainer>
      <HeadingFive fontSize="20px" fontWeight="600">
        Queue Status
      </HeadingFive>
      {taskData ? (
        <TableComp taskData={taskData} admin={admin}/>
      ) : (
        <p>No tasks available</p>
      )}
    </RequiredTaskContainer>
  );
};
const transformTaskData = (requiredTasks) => {
  if (!requiredTasks) return null;

  const transformedData = Object.keys(requiredTasks).map((key) => ({
    taskCategory: formatCategoryName(key), 
    taskCount: requiredTasks[key],
  }));
  return transformedData;
};

export default StaffQueueStatus;
