import { Box, Grid, Typography, Divider } from "@mui/material";
import dayjs from "dayjs";

const PersonalData = ({ clientData }) => {
  console.log(clientData, "clientData");
  const {
    prepSheetId: {
      firstName,
      middleName,
      lastName,
      ssn,
      dob,
      phone,
      city,
      street,
      state,
      zipCode,
      email,
      occupation,
      statusSingle,
      statusMarriedFillingJointly,
      statusMarriedFillingSeparately,
      statusMarriedFillingHeadOfHousehold,
      freeLookRequestCheck,
      freeLookYearArr,
      spouse,
      dlDoc,
      dlDocFileName,
      dependent = []
    },
    retirementId,
    healthCareId,
    adjustmentsId,
    itemizedDeductionId,
    uploadDocs,
  } = clientData;

  // Helper function to format date or return empty string
  const formatDate = (date) => {
    if (!date || date === "Invalid Date") return "";
    return dayjs(date).isValid() ? dayjs(date).format("MM-DD-YYYY") : "";
  };

  // Helper function to check if dependents array has any valid data
  const hasDependentData = () => {
    if (!Array.isArray(dependent) || dependent.length === 0) return false;
    return dependent.some(dep => 
      Object.values(dep).some(value => 
        value !== null && 
        value !== undefined && 
        value !== '' && 
        value !== 'Invalid Date'
      )
    );
  };

  // Helper function to check if spouse has any data
  const hasSpouseData = () => {
    if (!spouse) return false;
    return Object.values(spouse).some(value => 
      value !== null && 
      value !== undefined && 
      value !== '' && 
      value !== 'Invalid Date'
    );
  };

  return (
    <div>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          mb: 3,
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Personal Information
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              First Name
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {firstName}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Middle Name{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {middleName}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Last Name{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {lastName}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              SSN{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {ssn}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              DOB{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {dob}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Phone
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {phone}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              City
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {city}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Street
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {street}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              State
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {state}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              ZIP Code
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {zipCode}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Email
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {email}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Occupation
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {occupation}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              DL Doc
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              <a href={dlDoc} target="_blank" rel="noreferrer">
                {dlDocFileName}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Dependents Information Section */}
      {hasDependentData() && (
        <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
          <Box
            sx={{
              background: "#0B2558",
              height: "35px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              p: 1,
              mb: 2,
            }}
          >
            Dependents Information
          </Box>
          {dependent.map((dep, index) => (
            <Box key={index} sx={{ mb: index !== dependent.length - 1 ? 4 : 0 }}>
              {index > 0 && (
                <Divider sx={{ my: 3 }} />
              )}
              {console.log(dep, "dep")}
              <Typography 
                fontSize={"18px"} 
                fontWeight={"600"} 
                sx={{ mb: 2 }}
              >
                Dependent #{index + 1}
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={4}>
                  <Typography fontSize={"16px"} fontWeight={"600"}>
                    First Name
                  </Typography>
                  <Typography fontSize={"16px"} fontWeight={"400"}>
                    {dep?.firstName}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography fontSize={"16px"} fontWeight={"600"}>
                    Middle Name
                  </Typography>
                  <Typography fontSize={"16px"} fontWeight={"400"}>
                    {dep?.middleName}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography fontSize={"16px"} fontWeight={"600"}>
                    Last Name
                  </Typography>
                  <Typography fontSize={"16px"} fontWeight={"400"}>
                    {dep?.lastName}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography fontSize={"16px"} fontWeight={"600"}>
                    Relation
                  </Typography>
                  <Typography fontSize={"16px"} fontWeight={"400"}>
                    {dep?.relation}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography fontSize={"16px"} fontWeight={"600"}>
                    SSN
                  </Typography>
                  <Typography fontSize={"16px"} fontWeight={"400"}>
                    {dep?.ssn}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography fontSize={"16px"} fontWeight={"600"}>
                    DOB
                  </Typography>
                  <Typography fontSize={"16px"} fontWeight={"400"}>
                    {formatDate(dep?.dob)}
                  </Typography>
                </Grid>
                {dep?.dependentBirthCertificateDoc && (
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      SS Card/Birth Certificate Upload
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      <a
                        href={dep?.dependentBirthCertificateDoc}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {dep?.dependentBirthCertificateDocFileName}
                      </a>
                    </Typography>
                  </Grid>
                )}
                {dep?.dlDoc && (
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      DL Doc
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      <a href={dep?.dlDoc} target="_blank" rel="noopener noreferrer">
                        {dep?.dlDocFileName}
                      </a>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          ))}
        </Box>
      )}

      {/* Spouse Information Section - Only show if there's spouse data */}
      {hasSpouseData() && (
        <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
          <Box
            sx={{
              background: "#0B2558",
              height: "35px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              p: 1,
              mb: 2,
            }}
          >
            Spouse Information
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Spouse First Name
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"}>
                {spouse?.firstName}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Spouse Middle Name
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"}>
                {spouse?.middleName}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Spouse Last Name
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"}>
                {spouse?.lastName}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Spouse SSN
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"}>
                {spouse?.ssn}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Spouse DOB
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"}>
                {formatDate(spouse?.dob)}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Spouse DL Doc
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"}>
                <a
                  href={spouse?.spouseDlDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {spouse?.spouseDlDocFileName}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Free Look Request Section */}
      <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
        <Box
          sx={{
            background: "#0B2558",
            height: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            p: 1,
            mb: 2,
          }}
        >
          Free Look Request
        </Box>
        <Grid container spacing={2}>
          {freeLookYearArr?.map((yearData) => (
            <Grid item lg={4} key={yearData._id}>
              <Typography>{yearData.year}</Typography>
              {yearData.yearDocs?.map((doc) => (
                <a
                  key={doc._id}
                  href={doc.doc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {doc.docFileName}
                </a>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Health Care Section */}
      <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
        <Box
          sx={{
            background: "#0B2558",
            height: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            p: 1,
            mb: 2,
          }}
        >
          Retirements
        </Box>
        <Grid container spacing={2}>
          {retirementId.cryptoSales && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Crypto Sales Doc{" "}
              </Typography>
              <Typography>
                <a
                  href={retirementId?.salesDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {retirementId?.salesDocFileName}
                </a>
              </Typography>
            </Grid>
          )}
          {retirementId.receiveSocialSecurityBenefits && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Social Security Benefits Doc{" "}
              </Typography>
              <Typography>
                <a
                  href={retirementId?.socialSecurityBenefitDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {retirementId?.socialSecurityBenefitDocFileName}
                </a>
              </Typography>
            </Grid>
          )}
          {retirementId.tspWithdrawal && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Withdrawal Doc
              </Typography>
              <Typography>
                <a
                  href={retirementId?.withdrawalDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {retirementId?.withdrawalDocFileName}
                </a>
              </Typography>
            </Grid>
          )}
          {retirementId.healthCareGov && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Health Care Doc
              </Typography>
              <Typography>
                <a
                  href={retirementId?.socialSecurityBenefitDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {healthCareId?.socialSecurityBenefitDocFileName}
                </a>
              </Typography>
            </Grid>
          )}
          {retirementId.socialSecurityBenefitDoc && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                salesDoc{" "}
              </Typography>
              <Typography>
                <a
                  href={retirementId?.socialSecurityBenefitDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {retirementId?.socialSecurityBenefitDocFileName}
                </a>
              </Typography>
            </Grid>
          )}
          {/* <Grid item lg={6}>
              <Link href={healthCareId.healthCareDoc} target="_blank">
                {healthCareId.healthCareDocFilename}
              </Link>
            </Grid> */}
        </Grid>
      </Box>

      <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
        <Box
          sx={{
            background: "#0B2558",
            height: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            p: 1,
            mb: 2,
          }}
        >
          Health Care
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              HealthCare.gov{" "}
            </Typography>
            <Typography>{healthCareId.healthCareGov ? "Yes" : "No"}</Typography>
          </Grid>
          {healthCareId.healthCareGov && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Health Care Doc
              </Typography>
              <Typography>
                <a
                  href={healthCareId?.healthCareDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {healthCareId?.healthCareDocFilename}
                </a>
              </Typography>
            </Grid>
          )}
          {healthCareId.withdrawalDoc && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                salesDoc{" "}
              </Typography>
              <Typography>
                <a
                  href={healthCareId?.withdrawalDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {healthCareId?.withdrawalDocFileName}
                </a>
              </Typography>
            </Grid>
          )}

          {/* <Grid item lg={6}>
              <Link href={healthCareId.healthCareDoc} target="_blank">
                {healthCareId.healthCareDocFilename}
              </Link>
            </Grid> */}
        </Grid>
      </Box>

      {/* Adjustments Section */}
      <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
        <Box
          sx={{
            background: "#0B2558",
            height: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            p: 1,
            mb: 2,
          }}
        >
          Adjustments
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              IRA Contributions
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {adjustmentsId.iraContributions}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Teacher Expenses
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {adjustmentsId.teacherExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Student Loan Interest
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {adjustmentsId.studentLoanInterest}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Estimated Taxes
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {adjustmentsId.estimatedTaxes}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Education Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {adjustmentsId.educationExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              All Mondy Paid
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {adjustmentsId.alimoneyPaid}
            </Typography>
          </Grid>
          {adjustmentsId.healthCareGov && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                Health Care Doc
              </Typography>
              <Typography>
                <a
                  href={adjustmentsId?.studentLoanDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {healthCareId?.studentLoanDocFilename}
                </a>
              </Typography>
            </Grid>
          )}
          {adjustmentsId.studentLoanDoc && (
            <Grid item lg={6}>
              <Typography fontSize={"16px"} fontWeight={"600"}>
                salesDoc{" "}
              </Typography>
              <Typography>
                <a
                  href={adjustmentsId?.studentLoanDoc}
                  target="_blank"
                  rel="noreferrer"
                >
                  {adjustmentsId?.studentLoanDocFilename}
                </a>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Itemized Deduction Section */}
      <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
        <Box
          sx={{
            background: "#0B2558",
            height: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            p: 1,
            mb: 2,
          }}
        >
          Itemized Deduction
        </Box>
        <Box sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
          Gifts To Charity
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Cash
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.cash}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Non Cash
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.nonCash}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
          Medical
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Dental Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.dentalExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Medical Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.medicalIns}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Prescription Cost
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.prescriptionCost}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Medical Ins
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.medicalIns}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Dental Ins
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.medical?.dentalIns}
            </Typography>
          </Grid>
        </Grid>

        {/*   Military Expense */}
        <Box sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
          Military Expense
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Business
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.business}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Equipment
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.equipment}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.fees}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Grooming
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.grooming}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Hotel Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.hotelExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Meal And Entertainment
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.mealAndEntertainment}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Milleage Commuting
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.milleageCommuting}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Moving Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.movingExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Office Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.officeExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Personal
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.personal}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Supplies
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.supplies}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Travel Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.travelExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Uniforms Cleaners
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.uniformsCleaners}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Vehicle Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.vehicleExpense}
            </Typography>
          </Grid>
        </Grid>
        {/*   Military Expense */}
        <Box sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
          Miscellaneous Deduction
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Assoc Dues
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.AssocDues}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Alimony Paid
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.alimonyPaid}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Casualty Loss
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.casualtyLoss}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Energy Credit Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.energyCreditExpense}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Legal Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.militaryExpense?.legalFees}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Prior Year Tax Preparation Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {
                itemizedDeductionId?.militaryExpense
                  ?.priorYearTaxPreparationFees
              }
            </Typography>
          </Grid>
        </Grid>

        {/*  Taxes and Interest */}
        <Box sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
          Taxes and Interest
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Dental Expense
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.taxesAndInterest?.autoTruckTax}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Medical Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.taxesAndInterest?.boatMotorcycle}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Prescription Cost
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.taxesAndInterest?.interest}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Medical Ins
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.taxesAndInterest?.model}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Dental Ins
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.taxesAndInterest?.pmi}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Dental Ins
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {itemizedDeductionId?.taxesAndInterest?.realEstateTaxes}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {itemizedDeductionId?.dayCare?.length > 0 && (
        <>
          {itemizedDeductionId?.dayCare?.map((item, i) => {
            return (
              <Box
                sx={{
                  background: "#EFF3FD",
                  borderRadius: "10px",
                  p: 3,
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    background: "#0B2558",
                    height: "35px",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    p: 1,
                    mb: 2,
                  }}
                >
                  DayCare
                </Box>
                <Grid container spacing={2} key={i}>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Day Care Name
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Expenses
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.expense}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      taxId
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.taxId}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Street
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.street}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      City
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.city}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      State
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.state}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Zip Code
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item.zipCode}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </>
      )}
      {itemizedDeductionId?.dayCareDocs?.length > 0 && (
        <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
          <Box
            sx={{
              background: "#0B2558",
              height: "35px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              p: 1,
              mb: 2,
            }}
          >
            DayCare Docs
          </Box>
          <Grid container spacing={2}>
            {itemizedDeductionId?.dayCareDocs?.map((doc) => (
              <Grid item lg={4} key={doc._id}>
                <a href={doc.doc} target="_blank" rel="noreferrer">
                  {doc.fileName}
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Uploaded Documents Section */}
      <Box sx={{ background: "#EFF3FD", borderRadius: "10px", p: 3, mb: 3 }}>
        <Box
          sx={{
            background: "#0B2558",
            height: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            p: 1,
            mb: 2,
          }}
        >
          Uploaded Documents
        </Box>
        <Grid container spacing={2}>
          {uploadDocs?.w2Docs?.map((doc) => (
            <Grid item lg={4} key={doc._id}>
              <a href={doc.doc} target="_blank" rel="noreferrer">
                {doc.fileName}
              </a>
            </Grid>
          ))}
          {uploadDocs?.otherDocs?.map((doc) => (
            <Grid item lg={4} key={doc._id}>
              <a href={doc.doc} target="_blank" rel="noreferrer">
                {doc.fileName}
              </a>
            </Grid>
          ))}
          {uploadDocs?.docs1099?.map((doc) => (
            <Grid item lg={4} key={doc._id}>
              <a href={doc.doc} target="_blank" rel="noreferrer">
                {doc.fileName}
              </a>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default PersonalData;
