import React from "react";
import styled from "styled-components";
import { HeadingFive } from "../typography/Typography.style";

const TitleComponent = ({ text, showButton, btntext, clickHandler }) => {
  return (
    <TitleDesignMainDiv>
      <TitleDesignDiv>
        <TitleDesign />
        <HeadingFive fontSize="20px" fontWeight="600">
          {text || "title"}
        </HeadingFive>
      </TitleDesignDiv>
      {showButton && <TitleButton onClick={clickHandler}>{btntext}</TitleButton>}
    </TitleDesignMainDiv>
  );
};

export const TitleDesign = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: rgba(0, 173, 239, 1);
  margin-right: 10px;
`;

export const TitleDesignDiv = styled.div`
  display: flex;
`;

export const TitleDesignMainDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleButton = styled.button`
  width: auto;
  height: 40px;
  background: rgba(0, 173, 239, 1);
  border: none;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  padding:${({ padding }) => padding};
`;

export default TitleComponent;
