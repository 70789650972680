// BusinessInfoAccordian component
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { Input } from "components/reusable/input/Inputs";
import FlexComp from "components/reusable/flex/Flex";
import { ButtonIcon } from "components/reusable/button/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import {
  Autocomplete,
  Box,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { stateOptions } from "data/clientData/businessFormation";
import { MuiPhone } from "components/reusable/PhoneNumber";

const BusinessInfoAccordian = ({
  item,
  errors,
  index,
  isOpen,
  values,
  touched,
  onDelete,
  setFieldValue,
  toggleAccordion,
  memberList,
  handleDelete,
  openIndex  
}) => {
  const deleteAccordion = () => {
    onDelete(index);
  };
  

  return (
    <>
    
      <Box display={"flex"} alignItems={"center"} gap={"20px"}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#1B1B1B",
            margin: "20px 0",
          }}
        >
          Business Owner Information {index < 0 ? "" : index + 1}
        </Typography>
        {index > 0 &&<a
          href="javascript:void(0)"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(index)
          }}
          style={{width: "30px", height: "30px", backgroundColor: 'red', display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "6px"}}
        >
           <CloseIcon sx={{ color: "black" }} />
        </a>}
      </Box>
      <InputWrapper>
        {/* Display topBusinessInfoFields */}
        {item.fields.map(
          ({ label, id, name, size, key, width, placeholder }, fieldIndex) => (
            <Stack key={index} direction="column" sx={{ width }}>
              <Input
                label={label}
                id={id}
                name={name}
                size={size}
                width="100%"
                value={values[id]}
                onChange={(e) =>
                  setFieldValue(
                    `personBusinessInfo[${index}].${id}`,
                    e.target.value
                  )
                }
                placeholder={placeholder}
              />
              <FormHelperText error>
                {touched?.[id] && errors?.[id]}
              </FormHelperText>
            </Stack>
          )
        )}
        <Stack direction="column" sx={{ width: "32%" }}>
          <MuiPhone
            value={values.mobileNumber}
            handleChange={(value) =>
              setFieldValue(`personBusinessInfo[${index}].mobileNumber`, value)
            }
          />
          <FormHelperText error>
            {touched?.mobileNumber && errors?.mobileNumber}
          </FormHelperText>
        </Stack>
        {/* <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="EIN *"
              id="ssn"
              size="size"
              placeholder="XX-XXXXXXX"
              onChange={(e) => {
                const formattedSSN = e.target.value
                  .replace(/\D/g, "")
                  .replace(/(\d{2})(\d{7})/, "$1-$2");
                if (formattedSSN.length <= 10)
                  setFieldValue(
                    `personBusinessInfo[${index}].ssn`,
                    formattedSSN
                  );
              }}
              value={values.ssn}
            />
            <FormHelperText error>{touched?.ssn && errors?.ssn}</FormHelperText>
          </Stack> */}
        <FlexComp direction="row" gridGap="25px" width="100%">
          <HeadingFive fontSize="16px" fontWeight="500" color="black">
            Address
          </HeadingFive>
        </FlexComp>
        <FlexComp direction="row" gridGap="25px" width="100%">
          <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="Street"
              id="street"
              defaultValue=""
              size="size"
              width="100%"
              placeholder="Enter"
              required
              value={values.street}
              onChange={(e) =>
                setFieldValue(
                  `personBusinessInfo[${index}].street`,
                  e.target.value
                )
              }
            />
            <FormHelperText error>
              {touched?.street && errors?.street}
            </FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="City"
              id="city"
              defaultValue=""
              size="size"
              width="100%"
              placeholder="Enter"
              required
              value={values.city}
              onChange={(e) =>
                setFieldValue(
                  `personBusinessInfo[${index}].city`,
                  e.target.value
                )
              }
            />
            <FormHelperText error>
              {touched?.city && errors.city}
            </FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              sx={{ width: "100%" }}
              options={stateOptions}
              value={values.state}
              onChange={(_, newVal) =>
                setFieldValue(
                  `personBusinessInfo[${index}].state`,
                  newVal?.value
                )
              }
              renderInput={(params) => (
                <TextField {...params} label="State *" />
              )}
            />
            <FormHelperText error>
              {touched?.state && errors.state}
            </FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="Zip Code *"
              id="zipCode"
              defaultValue=""
              size="size"
              width="100%"
              placeholder="XXXXX-XXXX"
              value={values.zipCode}
              onChange={(e) => {
                let zipCodeValue = e.target.value
                  .replace(/\D/g, "")
                  .slice(0, 9);
                if (zipCodeValue.length > 5) {
                  zipCodeValue =
                    zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                }
                setFieldValue(
                  `personBusinessInfo[${index}].zipCode`,
                  zipCodeValue
                );
              }}
            />
            <FormHelperText error>
              {touched?.zipCode && errors.zipCode}
            </FormHelperText>
          </Stack>
        </FlexComp>
      </InputWrapper>
    </>
  );
};

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export default BusinessInfoAccordian;
