import React, { useState } from "react";
import Searchbar from "./Searchbar";
import NavRight from "./NavRight";
import { NavbarContainer } from "./Navbar.style";
import Notification from "../../modals/Notification";

const Navbar = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <NavbarContainer>
      <Searchbar />
      <NavRight setIsOpenModal={setIsOpenModal} />
      <Notification isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </NavbarContainer>
  );
};

export default Navbar;
