import {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { Input } from "../input/Inputs";
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, FormHelperText, Stack, TextField } from "@mui/material";

import { ButtonIcon } from "../button/IconButton";
import FlexComp from "../flex/Flex";
import { HeadingFive } from "../typography/Typography.style";
import { stateOptions } from "data/clientData/businessFormation";


const NonProfitAccordion = ({ 
  item,
  values,
  errors,
  touched,
  itemIndex, 
  openIndex, 
  setOpenIndex,
  setFieldValue, 
  deleteExpense,
}) => {

  const handleAccordionClick = () => {
    setOpenIndex(openIndex === itemIndex ? null : itemIndex);
  };

  return (
    <Accordion
      sx={{ backgroundColor: "#EBEFF9" }}
      expanded={openIndex === itemIndex}
      onChange={handleAccordionClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${itemIndex}-content`}
        id={`panel${itemIndex}-header`}
      >
        <AccordionTitle>
          {item.title}
          <ButtonIcon
            borderRadius="30px"
            width="auto"
            height="25px"
            fontSize="14px"
            bgColor="none"
            margin
            icon={<CloseIcon sx={{ color: "black"}}/>}
            clickHandler={(e) => {
              e.stopPropagation();
              deleteExpense(itemIndex); 
            }}
          />
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <InputWrapper>
          {/* Render fields */}
          {item?.fields?.map(({ label, id, size, width, placeholder }, indx) => (
            <Stack key={indx} direction="column" sx={{width}}>
            <Input
              
              label={label}
              id={id}
              size={size}
              value={values?.[id]}
              placeholder={placeholder}
              onChange={e => setFieldValue(`officersBoardTrustees[${itemIndex}].${id}`, e.target.value)}
            />
            <FormHelperText error>{touched?.[id] && errors?.[id]}</FormHelperText>
            </Stack>
          ))}
          {/* Address fields */}
          
         
        </InputWrapper>
        <HeadingFive fontSize="16px" fontWeight="500" color="black" paddingBottom='20px' paddingTop='20px'>
          Address
        </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "32%"}}>
              <Input
                label="Street *"
                id="street"
                size="size"
                placeholder="Enter"
                onChange={(e) => {
                  setFieldValue(`officersBoardTrustees[${itemIndex}].street`, e.target.value);
                }}
                // onBlur={handleBlur}
                value={values?.street}
                // error={touched.streetPOC && errors.streetPOC}
              />
            <FormHelperText error>{touched?.street && errors?.street}</FormHelperText>
              </Stack>
              <Stack direction="column" sx={{width: "32%"}}>
               <Input
                label="City *"
                id="city"
                size="size"
                placeholder="Enter"
                onChange={(e) => {
                  setFieldValue(`officersBoardTrustees[${itemIndex}].city`, e.target.value);
                }}
                value={values?.city}
              />
              <FormHelperText error>{touched?.city && errors?.city}</FormHelperText>
              </Stack>
               <Stack direction="column" sx={{ width: "30%" }}>
               <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values?.state}
                  onChange={(_, newVal) => {
                    setFieldValue(`officersBoardTrustees[${itemIndex}].state`, newVal?.value || '')}
                  }
                  renderInput={(params) => <TextField {...params} label="State"/>}
                />
              <FormHelperText error>{touched?.state && errors?.state}</FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "32%"}}>
              <Input
                label="Zip Code *"
                id="zipCode"
                size="size"
                placeholder="XXXXX-XXXX"
                onChange={(e) => {
                  let zipCodeValue = e.target.value.replace(/\D/g, '').slice(0, 9); 
                  if (zipCodeValue.length > 5) {
                    zipCodeValue = zipCodeValue.slice(0, 5) + '-' + zipCodeValue.slice(5);
                  }
                  setFieldValue(`officersBoardTrustees[${itemIndex}].zipCode`, zipCodeValue);
                }}
                value={values?.zipCode}
              />
              <FormHelperText error>{touched?.zipCode && errors?.zipCode}</FormHelperText>
              </Stack>
            </FlexComp>
      </AccordionDetails>
    </Accordion>
  );
};

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content:space-between;
 
`;

export default NonProfitAccordion;
