import React from "react";
import Modal from "@mui/material/Modal";
import {
  BulletList,
  BulletListItem,
  NotificationContainer,
} from "./Modals.style";
import CloseIcon from "@mui/icons-material/Close";
import FlexComp from "../reusable/flex/Flex";
import { Span } from "../reusable/typography/Typography.style";
import { Line } from "../client/rentalProperty/RentalProperty.style";

const Notification = ({ isOpenModal, setIsOpenModal }) => {
  return (
    <Modal
      open={isOpenModal}
      onClose={() => setIsOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <NotificationContainer>
        <FlexComp
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <FlexComp
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gridGap="5px"
          >
            <Span color="#000000" fontSize="16px" fontWeight="500">
              Notifications
            </Span>
            <CloseIcon
              sx={{ color: "#000", fontSize: "20px", cursor: "pointer" }}
              onClick={() => setIsOpenModal(false)}
            />
          </FlexComp>
          <BulletList>
            <BulletListItem>
              Your Tax return has been rejected by the admin.
            </BulletListItem>
            <Line border="1px dashed #B6BECD" />
            <BulletListItem color="#B6BECD">
              You have uploaded your personal information
            </BulletListItem>
          </BulletList>
        </FlexComp>
      </NotificationContainer>
    </Modal>
  );
};

export default Notification;
