import { IconButton, Stack } from "@mui/material";
import detail from "assets/client/detail.png";
import edit from "assets/client/edit.png";
import FormationPreviewModal from "components/modals/FormationPreviewModal";
import Signature from "components/modals/Signature";
import CustomTable from "components/reusable/customTable/CustomTable";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";

const errorToast = (message = "") => toast.error(message);
const infoToast = (message = "") => toast.info(message);
const BusinessFormationList = ({
  isTermModal,
  setIsTermModal,
  setHasEditableItems,
}) => {
  const [tab_loader, setTabLoader] = useState(true);
  const [businessData, setBusinessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [businessIds, setBusinessIds] = useState();
  // const [hasEditableItems, setHasEditableItems] = useState(false);
  const clientId = localStorage.getItem("clientId");

  const BGColumns = [
    {
      headerName: "Sr#",
      field: "srNo",
      width: 100,
      editable: false,
    },
    {
      field: "businessName",
      headerName: "Business Name",
      width: 250,
      editable: false,
    },

    {
      field: "fillingStatus",
      headerName: "Filling Status",
      width: 300,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Data Entery"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(244, 184, 27, 0.3)",
            color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <div>{dayjs(params.values).format("DD-MM-YYYY")}</div> // Assuming you have a formattedDate function
      ),
    },
    {
      headerName: "Action",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="space-around">
          {params.row.status !== "Submitted" && (
            <IconButton
              color="primary"
              onClick={() => {
                localStorage.setItem(
                  "businessFormationId",
                  JSON.stringify(params.row.otherDetails._id)
                );
                localStorage.setItem("formationEdit", true);
                navigate("/add-businessFormation");
              }}
            >
              <img src={edit} alt="edit" width={30} height={25} />
            </IconButton>
          )}
          <IconButton
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails);
              setIsPreviewModal(true);
            }}
          >
            <img src={detail} alt="detail" width={30} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      setTabLoader(true);
      const response = await apiReq(
        API_ROUTES.getBusinessFormationList,
        "POST",
        { clientId }
      );
      const responseData = await response.data;

      // Extracting all _id values and setting them
      const businessIds = responseData?.data?.map(
        (businessFormation) => businessFormation?._id
      );
      setBusinessIds(businessIds); // Save the array of ids

      // Mapping the data for display
      const updatedData = responseData?.data?.map((businessFormation, i) => ({
        id: businessFormation?._id,
        srNo: i + 1,
        businessName: businessFormation?.currentCorporationName,
        date: businessFormation?.createdAt,
        fillingStatus: businessFormation?.fillingStatus,
        status: businessFormation?.status,
        otherDetails: businessFormation,
      }));

      // Check if any items have status !== "Submitted"
      const hasEditable = updatedData.some(
        (item) => item.status !== "Submitted"
      );
      setHasEditableItems(hasEditable);
      setBusinessData(updatedData);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. Please try later."
      );
    } finally {
      setTabLoader(false);
    }
  };
  console.log(businessIds, "BusinessIds");
  const handleSaveSignature = async (signatureData) => {
    try {
      const clientId = localStorage.getItem("clientId");
      if (!clientId) {
        errorToast("clientId not found!");
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("clientId", clientId);

      formData.append("businessFormationIds", JSON.stringify(businessIds));
      formData.append("status", "Submitted");

      if (signatureData) {
        const { signatureFile, ...rest } = signatureData;
        formData.append("signatureData", JSON.stringify(rest));
        formData.append("signatureFile", signatureFile);
      }
      // Create API call
      await apiReq(
        API_ROUTES.SubmitBusinessFormationForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );

      // peronalCollectionId
      // generalBusinessId

      setTimeout(() => {
        infoToast("Form submitted successfully");
        setIsTermModal(false);
        fetchData();
      }, 2000);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    localStorage.removeItem("formationEdit");
    localStorage.removeItem("businessFormationId");
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <BusinessListWrapper>
        <CustomTable
          loading={tab_loader}
          rows={businessData}
          columns={BGColumns}
        />
      </BusinessListWrapper>
      <Signature
        loading={loading}
        isModalOpen={isTermModal}
        onSaveSignature={handleSaveSignature}
        closeModal={() => setIsTermModal(!isTermModal)}
      />
      <FormationPreviewModal
        isModalOpen={isPreviewModal}
        closeModal={() => setIsPreviewModal(!isPreviewModal)}
        modalData={modalData}
      />
    </>
  );
};

export default BusinessFormationList;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
