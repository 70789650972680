import styled from "styled-components";

export const ChatbotContainer = styled.div`
  width: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 520px;

  bottom: 25px;
  right: 30px;

  grid-gap: 2px;
`;
export const ChatbotHeader = styled.div`
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 195px;
  background-color: rgba(11, 37, 88, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* position: fixed; */
  font-size: 18px;
  font-weight: 400;
`;
export const ChatbotMessages = styled.div`
  height: 400px;
  overflow-y: auto;
  padding: 8px;
`;
export const Message = styled.div`
  margin: 8px 0;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
  background: rgba(246, 246, 246, 1);
  min-height: 47px;
  width: auto;
  textalign: left;
  display: flex;
  flex-direction: column;
`;

export const ChatbotInputForm = styled.div`
  margin: 8px 10px;
  padding: 5px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid rgba(112, 112, 112, 1);
  height: 63px;
  display: flex;
  outline: rgb(245, 245, 245);
`;

export const ChatButton = styled.button`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "rgba(0, 51, 142, 1)"};
  border: none;
  border-radius: 50%;
  margin-left: ${({ marginLeft }) => marginLeft || "10px"};
  width:40px;
  height:40px;
  margin-top: 5px;
`;
