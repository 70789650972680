import { useContext, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import Logo from "assets/auth/Logo.png";
import {
  AlreadyAcc,
  FieldWrapper,
  FormLogo,
  FormSec,
  FormWrapper,
  StyledInput,
} from "../signup/Signup.style";
import { Button } from "components/reusable/button/Button";
import { ForgetPasswordWrapper, LoginMessageWrapper } from "./Signin.style";
import { useNavigate, useLocation } from "react-router-dom";
import { Span } from "components/reusable/typography/Typography.style";
import { SyncLoader } from "react-spinners";
import { ProfileContext } from "context/ProfileContext";
import { apiReq } from "services/Requests";
import { toast } from "react-toastify";
import LoginVerModal from "components/modals/LoginVerModal";
import { API_ROUTES } from "utils/apiRoutes.constant";

const errorToast = (message = "") => toast.error(message);

const SigninComp = () => {
  const navigate = useNavigate();
  const Location = useLocation();
  const [showPass, setShowPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showLoginVerificationModal, setShowLoginVerificationModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { setProfileData } = useContext(ProfileContext);
  const [passwordCount, setPasswordCount] = useState(0);

  const [userEmail, setUserEmail] = useState();
  const [otp, setOtp] = useState("000000");

  const handleLogin = async (values, { setSubmitting }) => {
    setLoading(true);
    if (!formData.email || !formData.password) {
      alert("Please enter both email and password");
      return;
    }
    setIsLoading(true);

    try {
      const response = await apiReq("/auth/client/login", "POST", formData);

      const data = await response.data;
      const { client, token } = data.data;
      setUserEmail(response.data.data.email);
      setShowLoginVerificationModal(true);
    } catch (error) {
      setPasswordCount(passwordCount + 1);
      if (passwordCount == 4) {
        toast.error(
          "The count has been locked due to multiple login attempts please reset your password"
        );
      }
      if (
        error.message === "Request failed with status code 401" ||
        "Request failed with status code 400" ||
        "Request failed with status code 404"
      )
        toast.error("Username and/or password combination is incorrect");
      else
        errorToast(
          error.message || error?.response?.message,
          "Error while login. please try again later."
        );
      // Handle error gracefully, e.g., display an error message to the user
    } finally {
      setIsLoading(false); // Ensure isLoading is set back to false
    }
  };

  const handleOTPVerification = async (otp) => {
    try {
      console.log("Attempting API request...");
      const payload = {
        email: userEmail,
        otp: otp,
        role: "Client",
      };
      const response = await apiReq(API_ROUTES.loginOTPVerify, "POST", payload);
      const { user, token } = response.data.data;
      console.log(response, "adsdasdadsa");
      if (response.status === 200) {
        localStorage.setItem("clientId", user._id);
        localStorage.setItem("userName", user.userName);
        localStorage.setItem("initials", user.initials);
        localStorage.setItem("token", token);
        localStorage.setItem("clientData", JSON.stringify({ user: user }));
        setProfileData({ user: user });
        localStorage.setItem("userData", JSON.stringify({ user: user }));
        localStorage.setItem("auth", true);
        navigate("/dashboard");
      }
    } catch (error) {
      errorToast("Error during OTP verification. Please try again later.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log({ isLoading });

  const onClose = () => {
    setShowLoginVerificationModal(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleLogin}
      >
        {(formikProps) => (
          <FormWrapper>
            <FormSec gridGap="30px">
              <FormLogo>
                <img src={Logo} alt="Logo" />
              </FormLogo>
              <LoginMessageWrapper>
                <h5>Client Portal</h5>
                <p>Enter your credentials to login your account.</p>
              </LoginMessageWrapper>
              <FieldWrapper>
                <p>Email Address</p>
                <StyledInput
                  className="emailComp"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <ErrorMessage name="username" component="div" />
              </FieldWrapper>
              <ForgetPasswordWrapper>
                <FieldWrapper>
                  <p>Password</p>
                  <StyledInput
                    className="emailComp"
                    type={showPass ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <h5 onClick={() => setShowPass(!showPass)}>
                    {showPass ? "HIDE" : "SHOW"}
                  </h5>
                  <ErrorMessage name="password" component="div" />
                </FieldWrapper>
                <Span
                  color="#fff"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="end"
                  cursor="pointer"
                >
                  <p onClick={() => navigate("/forgot-password")}>
                    {" "}
                    Forgot Password
                  </p>
                </Span>
              </ForgetPasswordWrapper>
              <Button
                type="submit"
                text={
                  isLoading ? (
                    <SyncLoader color={"#FFFFFF"} size={8} />
                  ) : (
                    "Log in"
                  )
                }
                width="100%"
                fontSize="18px"
                disabled={isLoading} // Disable button when loading
              />
              <AlreadyAcc>
                {" "}
                {Location.pathname === "/" ? (
                  <>
                    <p>Don't have an account? </p>
                    <h5 onClick={() => navigate("/register")}>Sign up</h5>
                  </>
                ) : (
                  ""
                )}
              </AlreadyAcc>
            </FormSec>
          </FormWrapper>
        )}
      </Formik>
      <LoginVerModal
        otp={otp}
        setOtp={setOtp}
        open={showLoginVerificationModal}
        onVerify={handleOTPVerification}
        email={userEmail}
        onClose={onClose}
      />
    </>
  );
};

export default SigninComp;
