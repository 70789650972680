import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Bell from "assets/navbar/Bell.png";
import User from "assets/navbar/User.png";
import {
  BellBg,
  NavRightWrapper,
  UserInfoWrapper,
} from "./Navbar.style";
import { ProfileContext } from "context/ProfileContext";
import { Span } from "components/reusable/typography/Typography.style";

const NavRight2 = ({ setIsOpenModal, is_admin = false }) => {
  const { role, setRole,adminstaffprofileData } = useContext(ProfileContext);
  const navigate = useNavigate();
  const user=adminstaffprofileData?.user

  return (
    <NavRightWrapper>
      <BellBg onClick={() => setIsOpenModal?.(true)}>
        <img src={Bell} alt="Bell" />
      </BellBg>
      <UserInfoWrapper onClick={() => navigate(is_admin ? "/admin-profile" : "/staff-profile")}>
        <img src={user?.profileImage||User} alt="User" width={45} height={45} style={{ borderRadius: "10px"}}/>
        <Span fontSize="16px" fontWeight="500">
          {user?.firstName + " " + user?.lastName}
        </Span>
      </UserInfoWrapper>
    </NavRightWrapper>
  );
};

export default NavRight2;
