import { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import Logo from "assets/auth/Logo.png";
import {
  AlreadyAcc,
  FieldWrapper,
  FormLogo,
  FormSec,
  FormWrapper,
  StyledInput,
} from "../signup/Signup.style";
import { Button } from "components/reusable/button/Button";
import { LoginMessageWrapper } from "./Signin.style";
import { useNavigate } from "react-router-dom";
import EmailVerModal from "components/modals/EmailVerModal";
import ResetVerifyModal from "components/modals/ResetVerifyModal";
import { SyncLoader } from "react-spinners";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";

const errorToast = (message = "") => toast.error(message);

const ForgetPass = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    role: "Client",
  });
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading spinner

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onClose = () => {
    setShowEmailVerificationModal(false);
  };
  const onClose1 = () => {
    setShowVerificationModal(false);
  };

  const handleResetPassword = async (values, { setSubmitting }) => {
    try {
      if (!formData.email) {
        errorToast("Email field cannot be empty");
        return;
      }
      setIsLoading(true);
      const response = await apiReq(API_ROUTES.resetEmail, "POST", formData);
      if (response.status === 200) {
        // Store email in local storage
        localStorage.setItem("resetEmail", formData.email);
        setShowEmailVerificationModal(true);
      } else if (response.status === 404) {
        errorToast("User not found");
      } else {
        console.error("Reset password request failed:", response.statusText);
      }
    } catch (error) {
      errorToast("Error during reset password. please try again later.");
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const handleOTPVerification = async (otp) => {
    try {
      const response = await apiReq(API_ROUTES.resetPasswordOTP, "POST", {
        email: formData.email,
        otp: otp,
        role: "Client",
      });
      if (response.status === 200) {
        setShowEmailVerificationModal(true);
        setShowVerificationModal(true);
      } else {
        errorToast("OTP verification failed. please try again later.");
      }
    } catch (error) {
      errorToast("OTP verification failed. please try again later.");
    }
  };

  return (
    <>
      <EmailVerModal
        open={showEmailVerificationModal}
        onVerify={handleOTPVerification}
        email={formData.email}
        role={formData.role}
        onClose={onClose}
      />
      <ResetVerifyModal open={showVerificationModal} onClose={onClose1} />
      <Formik
        initialValues={{
          email: "",
          role: "Client",
        }}
        onSubmit={handleResetPassword}
      >
        {(formik) => (
          <FormWrapper>
            <FormSec gridGap="30px">
              <FormLogo>
                <img src={Logo} alt="Logo" />
              </FormLogo>
              <LoginMessageWrapper>
                <h5>Welcome Back!</h5>
                <p>Enter your credentials to login your account.</p>
              </LoginMessageWrapper>
              <FieldWrapper>
                <p>Email Address</p>
                <StyledInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  // onBlur={formik.handleBlur}
                />
                <ErrorMessage name="email" component="div" />
              </FieldWrapper>
              <Button
                text={
                  isLoading ? (
                    <SyncLoader color={"#FFFFFF"} size={8} />
                  ) : (
                    "Reset Password"
                  )
                }
                type="submit"
                width="100%"
                fontSize="18px"
                disabled={formik.isSubmitting}
              />
              <AlreadyAcc>
                <p>Dont have a account? </p>{" "}
                <h5 onClick={() => navigate("/register")}> Sign up</h5>
              </AlreadyAcc>
            </FormSec>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export default ForgetPass;
