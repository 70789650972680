import React, { useEffect, useState } from "react";

import { NavbarContainer } from "../../../layout/navbar/Navbar.style";
import Notification from "../../../modals/Notification";
import NavRight2 from "../../../layout/navbar/NavRight2";

const Navbar2 = ({ header }) => {
  // console.log(header);
  // Ensure that Navbar2 receives header prop
  // const [breadCrumbs, setBreadCrumbs] = useState(header);
  // useEffect(() => {
  //   if (header) {
  //     console.log("header", header);
  //   }
  //   setBreadCrumbs(header || sessionStorage.getItem("menuLink"));
  // }, []);

  return (
    <NavbarContainer>
      {/* <Searchbar /> */}
      <h3>{header}</h3> {/* Display the header prop */}
      <NavRight2 />
      <Notification />
    </NavbarContainer>
  );
};

export default Navbar2;
