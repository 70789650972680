import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

const UploadDocMenuTab = ({
  tabData,
  tabComponents,
  initial,
  fullName,
  apiData,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, tabValue) => {
    setCurrentTab(tabValue);
  };

  const filterDependents = (dependents) => {
    return dependents.filter(
      (dep) =>
        dep.dependentBirthCertificateDoc &&
        dep.dependentBirthCertificateDocFileName !== "undefined"
    );
  };

  const filterPersonalDocs = (docs) => {
    console.log(docs, "docsss");
    return {
      ...docs,
      prepSheetId: {
        ...docs.prepSheetId,
        dependent: filterDependents(docs.prepSheetId?.dependent || []),
        spouse:
          docs.prepSheetId?.spouse?.spouseDlDoc !== "null" &&
          docs.prepSheetId?.spouse?.spouseDlDocFileName !== "null"
            ? docs.prepSheetId?.spouse
            : undefined,
      },
      retirementId: docs.retirementId?.salesDoc ? docs.retirementId : undefined,
      healthCareId: docs.healthCareId?.healthCareDoc
        ? docs.healthCareId
        : undefined,
      adjustmentsId: docs.adjustmentsId?.studentLoanDoc
        ? docs.adjustmentsId
        : undefined,
        itemizedDeductionId: docs?.itemizedDeductionId?.dayCareDocs,
      uploadDocs: {...docs.uploadDocs},
    };
  };

  const filteredPersonalDocs = filterPersonalDocs(
    apiData?.data?.personalDocs || {}
  );

  console.log(filteredPersonalDocs, "filteredPersonalDocs")

  const CustomTabPanel = ({ value, index }) => {
    const Component = tabComponents[index];
    let componentProps = {};

    switch (index) {
      case 0:
        componentProps.data = filteredPersonalDocs;
        break;
      case 1:
        componentProps.data = apiData?.data?.generalBusiness;
        break;
      case 2:
        componentProps.data = apiData?.data?.rentalProperty;
        break;
      case 3:
        componentProps.data = apiData?.data?.truckDriver;
        break;
      default:
        break;
    }

    const shouldRender =
      componentProps.data && Object.keys(componentProps.data).length > 0;

    return (
      shouldRender &&
      value === index && (
        <div
          role="tabpanel"
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          <Component {...componentProps} />
        </div>
      )
    );
  };
  console.log(tabData, "tab data");
  return (
    <>
      <Tabs value={currentTab} onChange={handleChange}>
        {tabData.map((label, index) => (
          <Tab label={label} key={index} />
        ))}
      </Tabs>
      {tabData.map((label, index) => (
        <CustomTabPanel key={label} index={index} value={currentTab} />
      ))}
    </>
  );
};

export default UploadDocMenuTab;
