import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import TablePercentage from "../../client/dashboard/TablePercentage";

const TableComp = ({ taskData = [], admin }) => {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">{admin? "Name":"Task Category"}</TableCell>
            <TableCell align="left">{admin? "Popularity":"Completion"}</TableCell>
            <TableCell   align="left">{admin? "Sales":"Percent"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {taskData?.map((task, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="left">{task.taskCategory}</TableCell>
              <TableCell align="left">
                <LinearProgress
                  variant="determinate"
                  value={Number(task.taskCount)}
                />
              </TableCell>
              <TableCell align="left">
                <TablePercentage value={`${parseFloat(task.taskCount).toFixed(2)} %`} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComp;
