import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import detail from "assets/client/detail.png";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import AdminFormationPreviewModal from "components/modals/AdminFormationPreviewModal";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import AdminToggleButton from "components/reusable/button/AdminToggleButton";
import CustomTable from "components/reusable/customTable/CustomTable";
import StaffHeader from "components/reusable/header/StaffHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import btn from "../../../assets/reusable/Group 1000001601.png";
import unlock from "../../../assets/reusable/unlock.png";
import AssignTaskPopup from "./AssignTaskPopup";
import ClientInfoModal from "./ClientInfoModal";
import WarningModal from "./WarningModal";

const formattedDate = (value) => {
  const date = dayjs(value);
  return date.format("MM-DD-YYYY");
};

const options = [
  { label: "Select All", value: "Select All" },
  { label: "Data Validation", value: "Data Validation" },
  { label: "Data Entry", value: "Data Entry" },
  { label: "Staff Review", value: "Staff Review" },
  { label: "Accountant Review", value: "Accountant Review" },
  { label: "Tax Return Submitted", value: "Tax Return Submitted" },
  { label: "Tax Return Rejected", value: "Tax Return Rejected" },
  { label: "Tax Return Completed", value: "Tax Return Completed" },
];

const errorToast = (message = "") => toast.error(message);
const infoToast = (message = "") => toast.success(message);
const getStatusStyles = (status) => {
  let styles = {
    backgroundColor: "",
    color: "black",
    borderRadius: "10px",
    padding: "5px",
    textAlign: "center",
    // display: 'inline-block', // Ensure the div does not take full wid
    Width: "100%", // Ensure a consistent width for the status div
  };

  switch (status) {
    case "Completed":
      styles.backgroundColor = "#cff5d1";
      styles.color = "#1eff00";
      break;
    case "Sent":
      styles.backgroundColor = "#eff0ca";
      styles.color = "#888A01";
      break;
    case "Not Sent":
      styles.backgroundColor = "#ebc0c0";
      styles.color = "#D01717";
      break;
    default:
      styles.backgroundColor = "#ebc0c0";
      styles.color = "#D01717";
      break;
  }

  return styles;
};

const AdminQueue = () => {
  const navigate = useNavigate();
  const [tabLoader, setTabLoader] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [assignPopUp, setAssignPopUp] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false);
  const [activeButton, setActiveButton] = useState("Assigned Tasks");
  const [modalData, setModalData] = useState();
  const [search, setSearch] = useState();
  const userData = localStorage.getItem("userData");
  const jsonUserData = JSON.parse(userData);
  const [adminId, setAdminId] = useState(jsonUserData?.user?._id);
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [taskOriginalData, setTaskOriginalData] = useState([]);
  const [assignOriginalData, setAssignOriginalData] = useState([]);
  const [unassignOriginalData, setUnassignOriginalData] = useState([]);
  const [formationOriginalData, setFormationOriginalData] = useState([]);
  const [status, setStatus] = useState({ status: "Select All" });
  const [clientData, setClientData] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [openInfoModal, setOpenInfoModal] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");

  const [openWarningModal, setOpenWarningModal] = useState();
  const [openFormationModal, setOpenFormationModal] = useState();
  const [formationData, setFormationData] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isBusineeFormation, setIsBusineeFormation] = useState(false);
  const [rowData, setRowData] = useState(null);

  const userName = localStorage.getItem("userName");
  const [user, setUser] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, id, row) => {
    setAnchorEl(event.currentTarget); // Make sure this is set correctly
    setSelectedStatus(id);
    setRowData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleInfoModalClose = () => {
    setOpenInfoModal(false);
  };
  const handleFormationModalClose = () => {
    setOpenFormationModal(false);
  };
  console.log(anchorEl, "anchorEl");

  const assignColumns = [
    { field: "srno1", headerName: "Sr#.", width: 10, editable: false },
    { field: "cname", headerName: "Client Name", width: 180, editable: false },
    {
      field: "cd",
      headerName: "Start Date",
      width: 130,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "enddate",
      headerName: "End Date",
      width: 130,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "assignto",
      headerName: "Assigned To",
      width: 200,
      editable: false,
    },
    {
      field: "updateby",
      headerName: "Assigned By",
      width: 180,
      editable: false,
    },
    { field: "priority", headerName: "Priority", width: 120, editable: false },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.value === "Staff Review"
                ? "rgba(244, 184, 27, 0.3)"
                : "rgba(244, 184, 27, 0.3)",
            color:
              params.value === "Staff Review"
                ? "rgba(208, 150, 0, 1)"
                : "rgba(208, 150, 0, 1)",
            borderRadius: "15px",
            padding: "5px",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "updateon",
      headerName: "Created Date",
      width: 160,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      headerName: "Action",
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails);
              setOpenInfoModal(true);
            }}
          >
            <img src={detail} alt="detail" />
          </Button>
           {/* Assign button */}
           <Button
            variant="text"
            sx={{
              borderRadius: "20px",
              color: "white",
              minWidth: "30px",
              padding: "2.5px",
              marginLeft: "5px",
            }}
            onClick={async () => {
              const clientData = params?.row?.otherDetails;
              let updatedClientData = clientData;

              if (clientData.assignTo) {
                try {
                  const response = await apiReq(
                    `${API_ROUTES.assignToData}`,
                    "POST",
                    { staffId: clientData.assignTo }
                  );
                  const staffInfo = response.data;

                  updatedClientData = { ...clientData, staffInfo };
                } catch (error) {
                  console.error("Error fetching staff details:", error);
                }
              }

              setClientData(updatedClientData);
              setAssignPopUp(true);
            }}
          >
            <img src={btn} alt="detail" />
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails);
              setOpenWarningModal(true);
            }}
          >
            <img src={unlock} alt="detail" />
          </Button>
        </Box>
      ),
    },
  ];

  const unassignColumns = [
    { field: "srno1", headerName: "Sr#.", width: 200, editable: false },
    { field: "cname", headerName: "Client Name", width: 400, editable: false },

    {
      field: "status",
      headerName: "Status",
      width: 400,
      editable: false,
      renderCell: (params) => (
        <Button
          variant="text"
          sx={{
            background: "#F4B81B4D",
            color: "#D09600",
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </Button>
      ),
    },

    {
      headerName: "Action",
      width: 400,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Preview button */}
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              // For unassigned list, pass the entire data
              setModalData(params.row.otherDetails);
              setOpenInfoModal(true);
            }}
          >
            <img src={detail} alt="detail" />
          </Button>

          {/* Assign button */}
          <Button
            variant="text"
            sx={{
              borderRadius: "20px",
              color: "white",
              minWidth: "30px",
              padding: "2.5px",
              marginLeft: "5px",
            }}
            onClick={async () => {
              const clientData = params?.row?.otherDetails;
              let updatedClientData = clientData;

              if (clientData.assignTo) {
                try {
                  const response = await apiReq(
                    `${API_ROUTES.assignToData}`,
                    "POST",
                    { staffId: clientData.assignTo }
                  );
                  const staffInfo = response.data;

                  updatedClientData = { ...clientData, staffInfo };
                } catch (error) {
                  console.error("Error fetching staff details:", error);
                }
              }

              setClientData(updatedClientData);
              setAssignPopUp(true);
            }}
          >
            <img src={btn} alt="detail" />
          </Button>

          {/* Unlock button */}
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setOpenWarningModal(true);
              setModalData(params.row.otherDetails);
            }}
          >
            <img src={unlock} alt="detail" />
          </Button>
        </Box>
      ),
    },
  ];

  const formationColumns = [
    { field: "srno1", headerName: "Sr#.", width: 10, editable: false },
    { field: "formType", headerName: "Form Type", width: 250, editable: false },
    { field: "cname", headerName: "Client Name", width: 260, editable: false },

    {
      field: "assignTo",
      headerName: "Assign Staff",
      width: 250,
      editable: false,
      renderCell: (params) => (
        <div>
          {console.log(params.row.assignTo, "paransfor")}
          <Typography sx={{ color: params.row.assignTo == "N/A" ? "red" : "" }}>
            {params.row.assignTo}
          </Typography>
        </div>
      ),
    },
    // { field: "businessTitle", headerName: "Business Title", width: 400, editable: false },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
      editable: false,
    },
    {
      field: "state",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        const availableStatuses = ["Not Sent", "Sent", "Completed"];

        return (
          <Select
            renderValue={(selected) => selected}
            value={params.row.state || availableStatuses[0]}
            onChange={(e) => handleStatusChange(params.row, e.target.value)}
            style={{
              width: "80%",
              height: "35px",

              ...getStatusStyles(params.row.state),
            }}
          >
            {availableStatuses.map((option) => (
              <MenuItem
                key={option}
                value={option}
                selected={params.row.state === option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setFormationData(params.row.otherDetails);
            setIsBusineeFormation(true);
          }}
        >
          {console.log(params.row.otherDetails, "corrrrrrrr")}
          <img src={detail} alt="detail" />
        </Button>
      ),
    },
  ];

  const fetchAssignedData = async () => {
    try {
      setTabLoader(true);
      const adminId = localStorage.getItem("clientId");
      if (!adminId) {
        console.error("AdminId not found in local storage");
        return;
      }

      const response = await apiReq(
        `${API_ROUTES.AdminQueuesAssignByMe}`,
        "POST",
        { adminId }
      );
      const responseData = response.data.data;
      // console.log(responseData, "responseData");

      const updatedData = responseData.map((item, index) => {
        return {
          id: index + 1,
          srno1: index + 1,
          cname: item.clientId?.firstName + " " + item.clientId?.lastName,
          cd: item.createdAt,
          startDate: item?.startDateTime,
          enddate: item.endDateTime,
          assignto: item.assignTo?.firstName + " " + item.assignTo?.lastName,
          updateby: item.assignBy?.firstName + " " + item.assignBy?.lastName,
          updateon: item.createdAt,
          priority: item.priority,
          status: item.status == "Data Review" ? "Staff Review" : item.status,
          otherDetails: item,
        };
      });

      setTableData(updatedData);
      setAssignOriginalData(updatedData);
      setRows(updatedData);
      setCols(assignColumns);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };

  const fetchUnassignedData = async (page = 1) => {
    try {
      setTabLoader(true);
      const response = await apiReq(
        `${API_ROUTES.AdminQueuesUnassignForms}`,
        "POST",
        { adminId }
      );
      const responseData = await response.data;
      // console.log(responseData, "unaAssigensd");

      // Adjust the data path according to your data structure
      const filteredClientData = responseData?.data?.clients;

      if (!filteredClientData) {
        throw new Error("No data found");
      }

      const updatedData = filteredClientData.map((item, index) => {
        return {
          id: index + 1,
          srno1: index + 1,
          cname: item?.firstName + " " + item?.lastName,
          // cd: item?.startDateTime,
          // enddate: item?.endDateTime,
          // assignto: item?.assignTo?.firstName + " " + item?.assignTo?.lastName,
          // updateby: item?.prepSheetId?.ssn,
          // updateon: item?.createdAt,
          priority: item?.priority,
          status: "Unassigned",
          otherDetails: item,
        };
      });

      setTableData(updatedData);
      setUnassignOriginalData(updatedData);
      setRows(updatedData);
      setCols(unassignColumns);
      setTotalPages(responseData?.data?.totalPages || 1);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };

  const fetchFormationData = async (page = 1) => {
    try {
      setTabLoader(true);
      const response = await apiReq(
        `${API_ROUTES.AdminQueuesFormationForms}`,
        "POST",
        { adminId }
      );
      const responseData = await response.data;
      // console.log(responseData, "unaAssigensd");

      // Adjust the data path according to your data structure
      const filteredClientData = responseData?.data;

      console.log(responseData?.data, "filteredClientData");

      if (!filteredClientData) {
        throw new Error("No data found");
      }

      const updatedData = filteredClientData.map((item, index) => {
        return {
          id: index + 1,
          srno1: index + 1,
          formType: item?.informationType,
          cname: userName,
          assignTo: item?.assignedTo ? item.assignedTo : "N/A",

          cname: item?.clientId?.firstName + " " + item?.clientId?.lastName,
          state: item.formationState,
          createdDate: dayjs(item?.createdAt).format("MM-DD-YYYY"),
          otherDetails: item,
        };
      });

      setTableData(updatedData);
      setFormationOriginalData(updatedData);
      setRows(updatedData);
      setCols(formationColumns);
      setTotalPages(responseData?.data?.length || 1);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };
  const handleStatusChange = async (row, newStatus) => {
    try {
      const requestData = {
        formId: row.otherDetails._id,
        state: newStatus,
        formType:
          row.otherDetails?.type === "Worksheet"
            ? "1099WorkSheet"
            : row.otherDetails?.type,
      };

      const updateResponse = await apiReq(
        API_ROUTES.UpdateFormState,
        "POST",
        requestData
      );

      toast.success("Status updated successfully");
      fetchFormationData();
    } catch (error) {
      errorToast(error.message || "Failed to update status. Please try again.");
    }
  };
  console.log(userName, "userName");

  useEffect(() => {
    // if (activeButton === "All Tasks") fetchData();
    if (activeButton === "Assigned Tasks") fetchAssignedData();
    else if (activeButton === "Unassigned Tasks") fetchUnassignedData();
    else if (activeButton === "Formation Request") fetchFormationData();
  }, [activeButton]);
  const handleUnlockClients = async () => {
    try {
      // Prepare data to be uploaded
      setLoading(true);

      await apiReq(
        activeButton == "Assigned Task"
          ? API_ROUTES.unlockAssignedClients
          : API_ROUTES.unlockNotassignedClients,
        "POST"
      );
      infoToast("Info saved successfully");
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  console.log(activeButton, "activeButton");

  useEffect(() => {
    let filteredData = assignOriginalData;
    // console.log("aaaaaaaaaaaaaaaa", filteredData);
    if (status.status && status.status !== "Select All") {
      filteredData = filteredData.filter(
        (item) => item.status === status.status
      );
    }
    if (search)
      filteredData = filteredData.filter((item) =>
        item.cname.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filteredData);
  }, [status, search]);

  const onSelectChange = (selectedValues, key) => {
    setStatus({ ...status, [key]: selectedValues });
  };
  const searchStaff = async (searchValue) => {
    try {
      // console.log(searchValue);
      const filteredAssignData = assignOriginalData.filter((item) => {
        return item?.cname?.toLowerCase().includes(searchValue?.toLowerCase());
        // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
      });

      setRows(filteredAssignData);
      setTotalPages(Math.ceil(filteredAssignData.length / 10)); // Adjust page size if needed
    } catch (error) {}
  };
  const handleOnChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          onSelectChange={onSelectChange}
          options={options}
          showFirstSelect
          placeholder="Select Status"
          placeholder2="Select Task"
          onChange={handleOnChange}
          searchStaff={searchStaff}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />
        <AdminToggleButton
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          // btn1text="All Task"
          btn2text="Assigned Tasks"
          btn3text="Unassigned Tasks"
          btn4text="Formation Request"
        />
        <RentalPropertyWrapper className="queue-table">
          <CustomTable
            rows={tableData}
            loading={tabLoader}
            enableRowSelection={enableRowSelection}
            columns={cols}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />

      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
      <AssignTaskPopup
        clientData={clientData}
        setAssignPopUp={setAssignPopUp}
        assignPopUp={assignPopUp}
        fetchUnassignedData={fetchUnassignedData}
        fetchAssignedData={fetchAssignedData}
        activeButton={activeButton}
      />
      <ClientInfoModal
        modalData={modalData}
        handleInfoModalClose={handleInfoModalClose}
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
      />
      <WarningModal
        modalData={modalData}
        handleInfoModalClose={() => setOpenWarningModal(false)}
        openInfoModal={openWarningModal}
        setOpenInfoModal={setOpenWarningModal}
        activeButton={activeButton}
        fetchUnassignedData={fetchUnassignedData}
      />
      <AdminFormationPreviewModal
        isModalOpen={isBusineeFormation}
        closeModal={() => setIsBusineeFormation(!isBusineeFormation)}
        modalData={formationData}
      />
    </>
  );
};

export default AdminQueue;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
