import { useContext, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Logo from "assets/auth/Logo.png";
import Dashboard from "assets/sidebar/Dashboard.png";
import ActiveDashboard from "assets/sidebar/active/Dashboard.png";
import ReviewSub from "assets/sidebar/ReviewSub.png";
import Settings from "assets/sidebar/Settings.png";
import Submit from "assets/sidebar/Submit.png";
import TaxReturn from "assets/sidebar/TaxReturn.png";
import InfoCollection from "assets/sidebar/InfoCollection.png";
import Logout from "assets/sidebar/Logout.png";
import FormationService from "assets/sidebar/FormationService.png";
import UploadDocs from "assets/sidebar/UploadDocs.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarLogo } from "./Sidebar.style";
import { ProfileContext } from "context/ProfileContext";
import ActiveUploadTax from "assets/sidebar/active/UploadTaxDoc.png";
import ActiveSubmit from "assets/sidebar/active/Submit.png";
import ActiveReview from "assets/sidebar/active/Review.png";
import ActiveTaxReturn from "assets/sidebar/active/TaxReturn.png";
import ActiveSetting from "assets/sidebar/active/setting.png";
import ActiveFormationService from "assets/sidebar/active/users.png";
import ActiveInfoCollection from "assets/sidebar/active/InfoCollection.png";
import ActiveLogout from "assets/sidebar/active/logout.png";

const SidebarComp = () => {
  const { role } = useContext(ProfileContext);
  const params = useLocation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  //States to show the sidebar menu
  const [infoColMenu, setInfoCol] = useState(true);
  const [businessColMenu, setBusinessColMenu] = useState(true);
  const [formationColMenu, setFormationColMenu] = useState(true);
  const [settingMenu, setSettingMenu] = useState(true);
  //Handle the package prop of open of react-pro-sidebar
  const [openInfoCol, setOpenInfoCol] = useState(false);
  const [openBusinessCol, setOpenBusinessCol] = useState(false);
  const [openFormationCol, setOpenFormationCol] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  const handleMouseEnter = (menuItem) => {
    setActiveMenu(menuItem);
  };

  //Need to have one state to monitor that when is active sidebar item to handle the closing
  const handleMenuClick = (menuItem) => {
    if (menuItem === "info-collection") {
      //ShowMenu
      setInfoCol(true);
      setBusinessColMenu(true);
      setFormationColMenu(false);
      setSettingMenu(false);
      //OpenMenu Handling the react pro sidebar open prop
      setOpenFormationCol(false);
      setOpenSetting(false);
      if (openInfoCol) {
        setOpenInfoCol(false);
      } else {
        setOpenInfoCol(true);
      }
      if (openBusinessCol) {
        setOpenBusinessCol(true);
      } else {
        setOpenBusinessCol(false);
      }
    } else if (menuItem === "business-collection") {
      //ShowMenu
      setInfoCol(true);
      setBusinessColMenu(true);
      setFormationColMenu(false);
      setSettingMenu(false);
      //OpenMenu Handling the react pro sidebar open prop
      setOpenFormationCol(false);
      setOpenSetting(false);
      setOpenInfoCol(true);
      if (openBusinessCol) {
        setOpenBusinessCol(false);
      } else {
        setOpenBusinessCol(true);
      }
    } else if (menuItem === "formation-service") {
      //ShowMenu
      setInfoCol(false);
      setBusinessColMenu(false);
      setFormationColMenu(true);
      setSettingMenu(false);
      //OpenMenu Handling the react pro sidebar open prop
      setOpenInfoCol(false);
      setOpenBusinessCol(false);
      setOpenSetting(false);
      if (openFormationCol) {
        setOpenFormationCol(false);
      } else {
        setOpenFormationCol(true);
      }
    }

    if (menuItem === "settings") {
      //ShowMenu
      setInfoCol(false);
      setBusinessColMenu(false);
      setFormationColMenu(false);
      setSettingMenu(true);
      //OpenMenu Handling the react pro sidebar open prop
      setOpenInfoCol(false);
      setOpenBusinessCol(false);
      setOpenFormationCol(false);
      if (openSetting) {
        setOpenSetting(false);
      } else {
        setOpenSetting(true);
      }
    }
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  const isMenuItemActive = (menuItem) => {
    return activeMenu === menuItem || params?.pathname === `/${menuItem}`;
  };

  const getMenuItemStyle = (isActive, subMenu = true) => ({
    background: isActive ? "#fff" : "rgb(11, 37, 88)",
    color: isActive ? "rgb(11, 37, 88)" : "#fff",
    borderTopLeftRadius: isActive ? "25px" : "0px",
    borderBottomLeftRadius: isActive ? "25px" : "0px",
    fontSize: "12px",
    paddingLeft: subMenu ? "" : "16px",
    marginTop: "8px",
  });

  return (
    <Sidebar style={{textTransform: "capitalize"}} backgroundColor="#0b2558" width="100%">
      <SidebarLogo onClick={() => navigate("/dashboard")}>
        <img src={Logo} alt="RemeDy" />
      </SidebarLogo>
      <Menu>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("dashboard") ? ActiveDashboard : Dashboard}
              alt="Dashboard"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("dashboard"), false)}
          component={<Link to="/dashboard" />}
          onMouseEnter={() => handleMouseEnter("dashboard")}
          onMouseLeave={() => handleMouseLeave("dashboard")}
        >
          Dashboard
        </MenuItem>
        <SubMenu
          label="Information collection"
          icon={
            <img
              src={
                isMenuItemActive("info-collection")
                  ? ActiveInfoCollection
                  : InfoCollection
              }
              alt="InfoCollection"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("info-collection"), false)}
          onMouseEnter={() => handleMouseEnter("info-collection")}
          onMouseLeave={() => handleMouseLeave("info-collection")}
          onClick={() => handleMenuClick("info-collection")}
          open={openInfoCol}
        >
          {infoColMenu && (
            <>
              <MenuItem
                component={<Link to="/personal-list" />}
                style={getMenuItemStyle(
                  isMenuItemActive("personal-collection")
                )}
                onMouseEnter={() => handleMouseEnter("personal-collection")}
                onMouseLeave={() => handleMouseLeave("personal-collection")}
              >
                Personal Collection
              </MenuItem>

              <SubMenu
                label="Business collection"
                style={getMenuItemStyle(
                  isMenuItemActive("business-collection")
                )}
                onMouseEnter={() => handleMouseEnter("business-collection")}
                onMouseLeave={() => handleMouseLeave("business-collection")}
                onClick={() => handleMenuClick("business-collection")}
                open={openBusinessCol}
              >
                {businessColMenu && (
                  <>
                    <MenuItem
                      component={<Link to="/general-business" />}
                      style={getMenuItemStyle(
                        isMenuItemActive("general-business")
                      )}
                      onMouseEnter={() => handleMouseEnter("general-business")}
                      onMouseLeave={() => handleMouseLeave("general-business")}
                    >
                      General Business
                    </MenuItem>
                    <MenuItem
                      component={<Link to="/rental-property" />}
                      style={getMenuItemStyle(
                        isMenuItemActive("rental-property")
                      )}
                      onMouseEnter={() => handleMouseEnter("rental-property")}
                      onMouseLeave={() => handleMouseLeave("rental-property")}
                    >
                      Rental Property
                    </MenuItem>
                    <MenuItem
                      component={<Link to="/truck-driver" />}
                      style={getMenuItemStyle(isMenuItemActive("truck-driver"))}
                      onMouseEnter={() => handleMouseEnter("truck-driver")}
                      onMouseLeave={() => handleMouseLeave("truck-driver")}
                    >
                      Truck Driver
                    </MenuItem>
                    <MenuItem
                      component={<Link to="/worksheet" />}
                      style={getMenuItemStyle(isMenuItemActive("worksheet"))}
                      onMouseEnter={() => handleMouseEnter("worksheet")}
                      onMouseLeave={() => handleMouseLeave("worksheet")}
                    >
                      1099 Worksheet
                    </MenuItem>
                  </>
                )}
              </SubMenu>
            </>
          )}
        </SubMenu>
        <MenuItem
          icon={
            <img
              src={
                isMenuItemActive("upload-tax-docs")
                  ? ActiveUploadTax
                  : UploadDocs
              }
              alt="UploadDocs"
            />
          }
          component={<Link to="/upload-tax-docs" />}
          style={getMenuItemStyle(isMenuItemActive("upload-tax-docs"), false)}
          onMouseEnter={() => handleMouseEnter("upload-tax-docs")}
          onMouseLeave={() => handleMouseLeave("upload-tax-docs")}
        >
          Uploaded Tax Docs
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("review") ? ActiveReview : ReviewSub}
              alt="ReviewSub"
            />
          }
          component={<Link to="/review" />}
          style={getMenuItemStyle(isMenuItemActive("review"), false)}
          onMouseEnter={() => handleMouseEnter("review")}
          onMouseLeave={() => handleMouseLeave("review")}
        >
          Verify Tax Docs
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("submit-tax-docs") ? ActiveSubmit : Submit}
              alt="Submit"
            />
          }
          component={<Link to="/submit-tax-docs" />}
          style={getMenuItemStyle(isMenuItemActive("submit-tax-docs"), false)}
          onMouseEnter={() => handleMouseEnter("submit-tax-docs")}
          onMouseLeave={() => handleMouseLeave("submit-tax-docs")}
        >
          Downloaded tax docs
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("tax-return") ? ActiveTaxReturn : TaxReturn}
              alt="TaxReturn"
            />
          }
          component={<Link to="/tax-return" />}
          style={getMenuItemStyle(isMenuItemActive("tax-return"), false)}
          onMouseEnter={() => handleMouseEnter("tax-return")}
          onMouseLeave={() => handleMouseLeave("tax-return")}
        >
          Tax return
        </MenuItem>
        <SubMenu
          label="Formation services"
          icon={
            <img
              src={
                isMenuItemActive("formation-service")
                  ? ActiveFormationService
                  : FormationService
              }
              alt="FormationService"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("formation-service"), false)}
          onMouseEnter={() => {
            handleMouseEnter("formation-service");
          }}
          onMouseLeave={() => handleMouseLeave("formation-service")}
          onClick={() => handleMenuClick("formation-service")}
          open={openFormationCol}
        >
          {formationColMenu && (
            <>
              <MenuItem
                component={<Link to="/business-formation" />}
                style={getMenuItemStyle(isMenuItemActive("business-formation"))}
                onMouseEnter={() => handleMouseEnter("business-formation")}
                onMouseLeave={() => handleMouseLeave("business-formation")}
              >
                Business Formation
              </MenuItem>
              <MenuItem
                component={<Link to="/non-profit-formation" />}
                style={getMenuItemStyle(
                  isMenuItemActive("non-profit-formation")
                )}
                onMouseEnter={() => {
                  handleMouseEnter("non-profit-formation");
                }}
                onMouseLeave={() => handleMouseLeave("non-profit-formation")}
              >
                Non-Profit Formation
              </MenuItem>
            </>
          )}
        </SubMenu>
        <SubMenu
          label="Settings"
          icon={
            <img
              src={isMenuItemActive("settings") ? ActiveSetting : Settings}
              alt="Settings"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("settings"), false)}
          onMouseEnter={() => handleMouseEnter("settings")}
          onMouseLeave={() => handleMouseLeave("settings")}
          onClick={() => handleMenuClick("settings")}
          open={openSetting}
        >
          {settingMenu && (
            <>
              <MenuItem
                component={<Link to="/profile" />}
                style={getMenuItemStyle(isMenuItemActive("profile"))}
                onMouseEnter={() => handleMouseEnter("profile")}
                onMouseLeave={() => handleMouseLeave("profile")}
              >
                Profile
              </MenuItem>
              <MenuItem
                component={<Link to="/client-change-password" />}
                style={getMenuItemStyle(isMenuItemActive("change-password"))}
                onMouseEnter={() => handleMouseEnter("change-password")}
                onMouseLeave={() => handleMouseLeave("change-password")}
              >
                Change Password
              </MenuItem>
            </>
          )}
        </SubMenu>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("logout") ? ActiveLogout : Logout}
              alt="Logout"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("logout"))}
          onClick={() => {
            navigate("/");
            localStorage.clear();
            sessionStorage.removeItem("roleSelected");
            sessionStorage.removeItem("hasSeenBusinessProfileModal");
          }}
          onMouseEnter={() => handleMouseEnter("logout")}
          onMouseLeave={() => handleMouseLeave("logout")}
        >
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SidebarComp;
