import styled from "styled-components";
import MenuTabs from "components/reusable/tabs/MenuTabs";
import BasicInfo from "components/client/truckDriverModule/BasicInfo";
import Fees from "components/client/truckDriverModule/Fees";
import Maintenance from "components/client/truckDriverModule/Maintenance";
import LaborTaxes from "components/client/truckDriverModule/LaborTaxes";
import Insurance from "components/client/truckDriverModule/Insurance ";
import RoadExp from "components/client/truckDriverModule/RoadExp";
import OfficeExp from "components/client/truckDriverModule/OfficeExp";
import Rent from "components/client/truckDriverModule/Rent";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { useEffect } from "react";

const tabComponents = [
  BasicInfo,
  Fees,
  Maintenance,
  LaborTaxes,
  Insurance,
  RoadExp,
  OfficeExp,
  Rent,
];

const tabsData = [
  {label: "Basic Info", key: "basic_info"},
  {label: "Administrative Fees", key: "fees"},
  {label: "Maintenance", key: "maintenance"},
  {label: "Labor / Taxes", key: "labor"},
  {label: "Insurance / Interest", key: "insurance"},
  {label: "Road Expenses", key: "expenses"},
  {label: "Prof. Fees / Fuel / Office Exp", key: "office_exp"},
  {label: "Rent & Lease / Utilities", key: "utilities"},
];

const TruckDriver = () => {

  useEffect(() => {
    return () => {
      localStorage.removeItem("tabs");
      localStorage.removeItem("current_tab");
      localStorage.removeItem("selectedDriverInfo");
    }
  },[])

  return (
      <TruckDriverContainer>
        <HeadingFive fontSize="24px" fontWeight="500">
          Truck Driver{" "}
        </HeadingFive>
        <TruckDriverWrapper>
          <MenuTabs tabData={tabsData} tabComponents={tabComponents} />
        </TruckDriverWrapper>
      </TruckDriverContainer>
  );
};

export default TruckDriver;

const TruckDriverContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;

const TruckDriverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
