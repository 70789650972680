import { Box, Button } from "@mui/material";
import dayjs from "dayjs";
import WarningModal from "pages/admin/adminQueue/WarningModal";
import { useEffect, useState } from "react";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import unlock from "../../../assets/reusable/unlock.png";
import { Line } from "../../../components/client/rentalProperty/RentalProperty.style";
import ValidateTaxModal from "../../../components/modals/ValidateTaxModal";
import PersonalCollection from "../../../components/modals/review/ReviewConfirmation/PersonalCollection";
import CustomTable from "../../../components/reusable/customTable/CustomTable";
import StaffHeader from "../../../components/reusable/header/StaffHeader";
import ShowClientModal from "./ShowClientModal";

const StaffQueue = () => {
  const [showButton, setShowButton] = useState(false); // State to manage button visibility
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [originaldata, setoriginaldata] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [modalData, setModalData] = useState();
  const staffId = user?.user?._id;
  const [search, setsearch] = useState();
  const [enableRowSelection, setEnableRowSelection] = useState(false); // State to manage row selection
  let initialState = {
    status: "Select All",
    tag: "Select All",
  };
  const [status, setStatus] = useState(initialState);
  const formattedDate = (value) => {
    const date = dayjs(value);
    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  };
  const [openWarningModal, setOpenWarningModal] = useState();

  const fetchData = async () => {
    try {
      const response = await apiReq(
        API_ROUTES.getAllocatedTaskQueueStaff,
        "POST",
        { staffId: staffId }
      );

      const responseData = await response.data;

      const updatedData = responseData?.data?.map((item, index) => {
        return {
          id: index + 1,
          srno1: index + 1,
          cname: item?.clientId?.firstName + " " + item?.clientId?.lastName,
          totalForms: item?.tasks?.length,
          cd: item?.startDateTime,
          enddate: item?.endDateTime,
          assignby: item?.assignBy?.firstName + " " + item?.assignBy?.lastName,
          updateby: item?.prepSheetId?.ssn,
          updateon: item?.createdAt,
          priority: item?.priority,
          status: item?.status == "Data Review" ? "Staff Review" : item?.status,
          otherDetails: item?.tasks,
        };
      });
      console.log(responseData, "updated data");
      setTableData(updatedData);
      setoriginaldata(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("data staff", modalData);
  const handleYesClick = async () => {
    const filteredTasks = modalData.filter((task) => {
      if (task.status === "Data Review" || task.status === "Data Entry") {
        return task._id;
      }
      return false;
    });
    // const queueId = filteredTasks.map((task) => task._id);
    const queueId = modalData?.[0]?._id;

    const requestData = {
      queueId,
      status:
        filteredTasks[0]?.status === "Data Review"
          ? "Data Entry"
          : filteredTasks[0]?.status === "Data Entry"
          ? "Data Validation"
          : "Data Completed",
    };
    const response = await apiReq(
      API_ROUTES.queuesStatusUpdate,
      "POST",
      requestData
    );
    fetchData();
    console.log("response", response);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    let filteredData = originaldata;
    if (status.tag && status.tag !== "Select All") {
      filteredData = filteredData.filter((item) => item.status === status.tag);
    }
    if (search)
      filteredData = filteredData.filter((item) =>
        item.cname.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filteredData);
  }, [status.tag, search]);
  const onSelectChange = (selectedValues, key) => {
    setStatus({ ...status, [key]: selectedValues });
  };
  const handleonChange = (e) => {
    setsearch(e.target.value);
  };
  const individualColumns = [
    {
      field: "srno1",
      headerName: "Sr#",
      width: 80,
      editable: false,
    },

    {
      field: "cname",
      headerName: "Client Name",
      width: 200,
      editable: false,
    },
    {
      field: "totalForms",
      headerName: "Total Forms",
      width: 90,
      editable: false,
    },
    {
      field: "cd",
      headerName: "Start Date",
      width: 150,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "enddate",
      headerName: "End Date",
      width: 150,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "assignby",
      headerName: "Assigned By",
      width: 200,
      editable: false,
    },
    {
      field: "updateby",
      headerName: "Updated By",
      width: 150,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "updateon",
      headerName: "Updated On",
      width: 100,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 100,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 220,
      field: "status",
      headerName: "Status",
      width: 220,
      editable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Button
            onClick={() => {
              setModalData(params.row.otherDetails);
              setGeneralBusinessModal(true);
            }}
            style={{
              backgroundColor:
                params.value === "Data Completed"
                  ? "rgb(217, 245, 220)"
                  : "rgba(244, 184, 27, 0.3)",
              color:
                params.value === "Data Completed"
                  ? "rgb(34, 193, 50)"
                  : "rgba(208, 150, 0, 1)",
              borderRadius: "15px",
              padding: "5px",
            }}
          >
            {params.value}
          </Button>
        </Box>
      ),
    },
    // {
    //   // field: "comments",
    //   headerName: "Action",
    //   width: 100,
    //   editable: false,
    //   renderCell: (params) => (
    //     <div>
    //       <Button
    //         variant='text'
    //         color='primary'
    //         onClick={() => setGeneralBusinessModal(true)}
    //       >
    //         <img src={detail} alt='detail' />
    //       </Button>
    //     </div>
    //   ),
    // },
    {
      field: "Action",
      headerName: "Action",

      editable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails?.[0]);
              setOpenWarningModal(true);
            }}
          >
            <img src={unlock} alt="detail" />
          </Button>
        </Box>
      ),
    },
  ];

  const individualRows = [
    {
      id: 1,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Staff Review",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "heigh",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
    {
      id: 2,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Staff Review",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "low",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
  ];

  const options = [
    { label: "Select ALL", value: "Select All" },
    { label: "Staff Review", value: "Staff Review" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Completed", value: "Data Completed" },
  ];
  const options2 = [
    { label: "Select ALL", value: "Select All" },
    { label: "Staff Review", value: "Staff Review" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Completed", value: "Data Completed" },
  ];

  const searchStaff = async (searchValue) => {
    try {
      console.log(searchValue);
      const filteredAssignData = originaldata.filter((item) => {
        return item?.cname?.toLowerCase().includes(searchValue?.toLowerCase());
        // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
      });

      setTableData(filteredAssignData);
      // Adjust page size if needed
    } catch (error) {}
  };
  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton={showButton}
          btntext="Validate Selected"
          clickHandler={() => setIsValidateTaxModal(!isValidateTaxModal)}
          onSelectChange={onSelectChange}
          options={options}
          options2={options2}
          showFirstSelect={false}
          showSecondSelect
          placeholder="All Queues"
          placeholder2="Select Status"
          onChange={handleonChange}
          searchStaff={searchStaff}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />

        <h5 style={{ paddingLeft: "5px" }}>My Task</h5>
        <RentalPropertyWrapper>
          <CustomTable
            rows={tableData}
            enableRowSelection={enableRowSelection}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        handleYesClick={handleYesClick}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
      {/* <GeneralBusinessModal
        modalData={modalData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        handleYesClick={handleYesClick}
        
      /> */}
      <ShowClientModal
        modalData={modalData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        handleYesClick={handleYesClick}
        download="false"
      />
      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
      <WarningModal
        modalData={modalData}
        handleInfoModalClose={() => setOpenWarningModal(false)}
        openInfoModal={openWarningModal}
        setOpenInfoModal={setOpenWarningModal}
        activeButton={"Assigned Tasks"}
        fetchUnassignedData={fetchData}
      />
    </>
  );
};

export default StaffQueue;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
