import styled from "styled-components";

export const SidebarLogo = styled.div`
  text-align: start;
  padding: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  img {
    width: 70%;
  }
`;
