import React, { useState,useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {
  AdditionalButtonWrapper,
  AdditionalNotesBody,
  AdditionalNotesContainer,
  AdditionalNotesHeader,
} from "./Modals.style";
import { Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import { Span } from "components/reusable/typography/Typography.style";

const AdditionalNotes = ({ isModalOpen, closeModal, openSecondModal, additionalNotes, setadditionalnotes }) => {
  console.log(additionalNotes, "aaaaa")
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setNotes(additionalNotes); 
  }, [additionalNotes]);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    setadditionalnotes?.(e.target.value);
  };

  const handleSaveNotes = () => {
    closeModal();
    openSecondModal(notes);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <AdditionalNotesContainer>
        <AdditionalNotesHeader>
          <Span color="#FFFFFF" fontSize="16px" fontWeight="500">
            Additional Notes
          </Span>
          <CloseIcon
            sx={{ color: "#FFFFFF", cursor: "pointer" }}
            onClick={closeModal}
          />
        </AdditionalNotesHeader>
        <AdditionalNotesBody>
          <Input
            label="Additional Notes"
            defaultValue={notes} // Set default value to notes
            size="medium"
            multiline
            width="100%"
            placeholder="You can type here..."
            minRows="5"
            value={notes}
            onChange={handleNotesChange}
          />
          <AdditionalButtonWrapper>
            <Button
              text="Cancel"
              width="25%"
              fontSize="18px"
              backgroundColor="#EFF3FD"
              color="#AEB6CF"
              padding="10px 16px"
              borderRadius="10px"
              onClick={closeModal}
            />
            <Button
              text="Submit"
              width="25%"
              fontSize="18px"
              backgroundColor="#0B2558"
              color="#FFFFFF"
              padding="10px 16px"
              borderRadius="10px"
              onClick={handleSaveNotes}
            />
          </AdditionalButtonWrapper>
        </AdditionalNotesBody>
      </AdditionalNotesContainer>
    </Modal>
  );
};

export default AdditionalNotes;
