import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { FormHelperText, Stack } from "@mui/material";
import { MFieldsTop } from "data/client";
import FlexComp from "components/reusable/flex/Flex";
import { Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import ExpAccordion from "components/reusable/accordion/ExpAccordian";
import { apiReq } from "services/Requests";
import { maintenanceSchema } from "./Schema";
import { Toaster } from "components/reusable/toaster";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { PageLoaderContext } from "context/PageLoaderProvider";

const detail = {
  name: "",
  amount: ""
};
const initialValues = {
  parts: "",
  repairs: "",
  tires: "",
  truckWashes: "",
  otherMaintenance: "",
  otherExpenseArr: [],
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const Maintenance = ({ setCurrentTab }) => {
  const [expenses, setExpenses] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const clientId = localStorage.getItem("clientId");
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const {setOpen} = useContext(PageLoaderContext);
  const [tabsData, setTabsData] = useState({})

  const {values, errors, touched, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: maintenanceSchema,
    onSubmit: async(values, {setSubmitting}) => {
      try {
        if (!clientId) {
          errorToast('Client Id Or Truck driver Id Not Found.');
          return;
        } else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
        const {_id: truckDriverId, truckDriverMaintenanceId} = selectedDriverInfo;
        await apiReq(`${(truckDriverMaintenanceId?._id || tabsData?._id) ? API_ROUTES.updateMaintenance: API_ROUTES.addMaintenance}`, "POST", {clientId, truckDriverMainId: truckDriverMainId || truckDriverId || "", truckDriverMaintenanceId: truckDriverMaintenanceId?._id || tabsData?._id, ...values});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, labor: false}));
        infoToast(truckDriverMaintenanceId?._id ? "Maintenance info updated succesfully":"Maintenance info saved succesfully");
        setTimeout(() => {
          setCurrentTab(3);
        }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });

  const newExpense = useMemo(() => ({
    title: `Additional Expense ${expenses.length + 1} `,
    fields: [
      {
        label: "Expense Name",
        placeholder: "Enter Name",
        defaultValue: "",
        key: "name",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Amount",
        placeholder: "$",
        defaultValue: "",
        key: "amount",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ] // You can initialize with any default fields if needed
  }), [expenses]);

  const addExpense = () => {
    setFieldValue("otherExpenseArr", [...values.otherExpenseArr, detail]);
    const newExpenses = [...expenses, newExpense];
  setExpenses(newExpenses);
    setOpenIndex(newExpenses.length - 1);
  };

  useEffect(() => {
    const fetchMaintenance = async() => {
      try {
        setOpen(true);
        const {truckDriverMaintenanceId} = selectedDriverInfo;
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getMaintenance, "POST", {truckDriverMaintenanceId: truckDriverMaintenanceId._id});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, labor: false}));
        setExpenses(Array.from(data.otherExpenseArr).fill(newExpense));
        setValues(data);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      }  finally {
        setOpen(false);
      }
    }
    selectedDriverInfo?.truckDriverMaintenanceId && fetchMaintenance();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverMaintenanceId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverMaintenanceId?._id && localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, labor: false}));
        truckDriverMaintenanceId?._id && setValues(truckDriverMaintenanceId);
        truckDriverMaintenanceId?._id && setExpenses(Array.from(truckDriverMaintenanceId.otherExpenseArr).fill(newExpense));
        setTabsData(truckDriverMaintenanceId)
      }
      fetchTruckDriverDetails();
    }
  },[]);

  const deleteExpense = (index) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  
    // Update values.otherExpenseArr array
    const updatedOtherExpenseArr = [...values.otherExpenseArr];
    updatedOtherExpenseArr.splice(index, 1);
    setFieldValue("otherExpenseArr", updatedOtherExpenseArr);
  
    // Update openIndex if needed
    if (openIndex === index) {
      setOpenIndex(null);
    } else if (openIndex > index) {
      setOpenIndex(openIndex - 1);
    }
  };
  

  
  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize="18px" fontWeight="600">
            Maintenance
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {MFieldsTop.map(({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{width}}>
                  <Input
                    id={id}
                    size={size}
                    label={label}
                    value={values[key]}
                    placeholder={placeholder}
                    onChange={e => {
                      let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                      setFieldValue(key, val)
                    }}
                  />
                  <FormHelperText error>{touched?.[key] && errors[key]}</FormHelperText>
                </Stack>
              ))}
            </FlexComp>
          </BasicInfoInputWrapper>

          {/* Render existing expenses */}
          {expenses.map((expense, index) => (
            <FlexComp key={index} direction="column" justifyContent="flex-start" gridGap="5px">
              <ExpAccordion
                index={index}
                item={expense}
                openIndex={openIndex}
                setOpenIndex={setOpenIndex}
                setFieldValue={setFieldValue}
                values={values.otherExpenseArr[index]}
                deleteExpense={deleteExpense} // Pass the deleteExpense function
                Icon='RedTrash'
              />
            </FlexComp>
          ))}

          {/* Button to add new expense */}
          <Button
            text="+ Add Other Expense"
            type="button"
            width="auto"
            fontSize="18px"
            backgroundColor="#00ADEF"
            padding="10px 20px"
            onClick={addExpense}
          />

          {/* Next button */}
          <Button
            text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"}
            disabled={isSubmitting}
            type="submit"
            width="15%"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="flex-end"
          />
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default Maintenance;
