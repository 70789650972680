import { Autocomplete, FormHelperText, Stack, TextField } from "@mui/material";
import { Button } from "components/reusable/button/Button";
import FlexComp from "components/reusable/flex/Flex";
import { DateInput, Input } from "components/reusable/input/Inputs";
import { MuiPhone } from "components/reusable/PhoneNumber";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { TdFieldsTop, tdFieldsBottom } from "data/client";
import { stateOptions } from "data/clientData/businessFormation";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { basicInfoSchema } from "./Schema";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const BasicInfo = ({ check = false, setCheck, setCurrentTab }) => {
  const [tabsData, setTabsData] = useState({});

  const initialValues = {
    firstName: tabsData?.firstName || "",
    middleName: tabsData?.middleName || "",
    lastName: tabsData?.lastName || "",
    socialSecurity: tabsData?.socialSecurity || "",
    taxYear: tabsData?.taxYear || "",
    street: tabsData?.street || "",
    city: tabsData?.city || "",
    state: tabsData?.state || "",
    zipCode: tabsData?.zipCode || "",
    dob: tabsData?.dob || "",
    email: tabsData?.email || "",
    mobileNumber: tabsData?.mobileNumber || "",
    SingleCheck: tabsData?.SingleCheck || false,
    MarriedFillingJointlyCheck: tabsData?.MarriedFillingJointlyCheck || false,
    MarriedFillingSeparatelyCheck:
      tabsData?.MarriedFillingSeparatelyCheck || false,
    headOfHouseholdCheck: tabsData?.headOfHouseholdCheck || false,
    businessAddress: {
      street: tabsData?.businessAddress?.street || "",
      city: tabsData?.businessAddress?.city || "",
      state: tabsData?.businessAddress?.state || "",
      zipCode: tabsData?.businessAddress?.zipCode || "",
    },
  };

  const fillStatus = [
    { label: "Single", key: "SingleCheck" },
    { label: "Married Filling Jointly", key: "MarriedFillingJointlyCheck" },
    {
      label: "Married Filling Separately",
      key: "MarriedFillingSeparatelyCheck",
    },
    { label: "Head of Household", key: "headOfHouseholdCheck" },
  ];
  const clientId = localStorage.getItem("clientId");
  const mainId = localStorage.getItem("truckDriverMainId");
  const infoId = localStorage.getItem("newInfoId");
  const { setOpen } = useContext(PageLoaderContext);
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [fillingError, setfillingError] = useState("");
  const {
    values,
    touched,
    errors,
    isSubmitting,
    setValues,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: basicInfoSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // if (
      //   !(
      //     values.SingleCheck ||
      //     values.MarriedFillingJointlyCheck ||
      //     values.MarriedFillingSeparatelyCheck ||
      //     values.headOfHouseholdCheck
      //   )
      // ) {
      //   setfillingError("Please select a filling status.");
      //   return;
      // }
      try {
        if (!clientId) {
          errorToast("Client Id Not Found.");
          return;
        }
        const { _id: truckDriverId, truckDriverBasicInfoId } =
          selectedDriverInfo;
        const { data: { data: { truckDriverMainId } } = {} } = await apiReq(
          `${
            truckDriverBasicInfoId?._id || tabsData?._id
              ? API_ROUTES.updateBasicInfo
              : API_ROUTES.addBasicInfo
          }`,
          "POST",
          {
            clientId,
            truckDriverBasicInfoId:
              truckDriverBasicInfoId?._id || tabsData?._id,
            ...values,
          }
        );
        if (!truckDriverId && !mainId) {
          localStorage.setItem("truckDriverMainId", truckDriverMainId);

          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, fees: false })
          );
        }
        console.log(truckDriverId, "id1");
        infoToast(
          truckDriverBasicInfoId?._id
            ? "Basic Info updated succesfully"
            : "Basic Info saved succesfully"
        );
        setTimeout(() => {
          setCurrentTab(1);
          console.log(truckDriverBasicInfoId?._id, "id1");
        }, 1500);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  console.log(mainId, "ssssasasasa");
  useEffect(() => {
    const fetchBasicInfo = async () => {
      try {
        setOpen(true);
        const { truckDriverBasicInfoId } = selectedDriverInfo;
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getBasicInfo,
          "POST",
          { truckDriverBasicInfoId: truckDriverBasicInfoId._id }
        );
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, fees: false })
        );
        console.log("aaaasasasas");
        setValues(data);
        setTabsData(data);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };
    if (selectedDriverInfo?.truckDriverBasicInfoId) fetchBasicInfo();
    else if (mainId) {
      const fetchTruckDriverDetails = async () => {
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getTruckDriver + mainId,
          "POST"
        );
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, fees: false })
        );
        setTabsData(data?.truckDriverBasicInfoId);
      };
      fetchTruckDriverDetails();
    } else {
      !check && setCheck(true);
      localStorage.setItem(
        "tabs",
        JSON.stringify({
          fees: true,
          maintenance: true,
          labor: true,
          insurance: true,
          expenses: true,
          office_exp: true,
          utilities: true,
        })
      );
      const fetchCollection = async () => {
        setOpen(true);
        try {
          const { data: { data = {} } = {} } = await apiReq(
            `${API_ROUTES.getPerosnalCollection}`,
            "POST",
            { clientId }
          );
          if (data?.prepSheetId?._id) {
            const prepSheet = data.prepSheetId;
            setValues({
              ...initialValues,
              firstName: prepSheet.firstName,
              lastName: prepSheet.lastName,
              middleName: prepSheet.middleName,
              street: prepSheet.street,
              city: prepSheet.city,
              state: prepSheet.state,
              zipCode: prepSheet.zipCode,
              dob: prepSheet.dob,
              email: prepSheet.email,
              mobileNumber: prepSheet.phone,
            });
          }
        } catch (error) {
        } finally {
          setOpen(false);
        }
      };
      fetchCollection();
    }
  }, []);
  console.log(tabsData, "dataTabssss");

  const resetCheckboxes = () => {
    for (let val of fillStatus) {
      setFieldValue(val.key, false);
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize="18px" fontWeight="600">
            Basic Information
          </HeadingFive>

          <BasicInfoInputWrapper>
            <Stack direction="column" sx={{ width: "32%" }}>
              <DateInput
                label="Tax year"
                id="tax-year"
                name="taxYear"
                openTo="year"
                restrictToLast10Years="true"
                defaultToTenthYear="true"
                disableFuture="true"
                width="100%"
                placeholder="YYYYY"
                views={["year"]}
                value={dayjs(values.taxYear).format("YYYY") || ""}
                onChange={(date) => setFieldValue(`taxYear`, date)}
              />
              <FormHelperText error>
                {touched?.taxYear && errors?.taxYear}
              </FormHelperText>
            </Stack>

            {TdFieldsTop.map(
              ({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{ width }}>
                  {key === "socialSecurity" ? (
                    <Input
                      label={label}
                      id="ssn"
                      size="size"
                      placeholder="XX-XXXXXXX"
                      onChange={(e) => {
                        const formattedSSN = e.target.value
                          .replace(/\D/g, "")
                          .replace(/(\d{2})(\d{7})/, "$1-$2");
                        if (formattedSSN.length <= 10)
                          setFieldValue(key, formattedSSN);
                      }}
                      value={values[key]}
                    />
                  ) : (
                    <Input
                      key={index}
                      label={label}
                      id={id}
                      value={values[key]}
                      size={size}
                      placeholder={placeholder}
                      onChange={(e) => setFieldValue(key, e.target.value)}
                    />
                  )}
                  <FormHelperText error>
                    {touched?.[key] && errors[key]}
                  </FormHelperText>
                </Stack>
              )
            )}
          </BasicInfoInputWrapper>
          <BasicInfoInputWrapper>
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Address
            </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Street *"
                  id="outlined-size-small"
                  size="size"
                  placeholder="Enter"
                  value={values.street}
                  onChange={(e) => setFieldValue("street", e.target.value)}
                />
                <FormHelperText error>
                  {touched?.street && errors.street}
                </FormHelperText>
              </Stack>

              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="City *"
                  id="outlined-size-small"
                  size="size"
                  placeholder="Enter"
                  value={values.city}
                  onChange={(e) => setFieldValue("city", e.target.value)}
                />
                <FormHelperText error>
                  {touched?.city && errors.city}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "32%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values?.state}
                  onChange={(_, newVal) => {
                    setFieldValue(`state`, newVal?.value || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="State *" />
                  )}
                />
                <FormHelperText error>
                  {touched?.state && errors.state}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Zip Code *"
                  id="outlined-size-small"
                  size="size"
                  placeholder="XXXXX-XXXX"
                  value={values.zipCode}
                  onChange={(e) => {
                    let zipCodeValue = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue =
                        zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    setFieldValue(`zipCode`, zipCodeValue);
                  }}
                />
                <FormHelperText error>
                  {touched?.zipCode && errors.zipCode}
                </FormHelperText>
              </Stack>
            </FlexComp>

            <Stack direction="column" sx={{ width: "32%" }}>
              <DateInput
                label="Date of Birth *"
                name="dob"
                id="dob"
                width="100%"
                value={dayjs(values.dob).format("MM/DD/YYYY")}
                onChange={(date) => setFieldValue("dob", date)}
                disableFuture
              />
              <FormHelperText error>
                {touched?.dob && errors.dob}
              </FormHelperText>
            </Stack>

            {tdFieldsBottom.map(
              ({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{ width }}>
                  {key === "mobileNumber" ? (
                    <MuiPhone
                      value={values.mobileNumber}
                      handleChange={(value) =>
                        setFieldValue(`mobileNumber`, value)
                      }
                    />
                  ) : (
                    <Input
                      label={label}
                      id={id}
                      size={size}
                      value={values[key]}
                      placeholder={placeholder}
                      onChange={(e) => setFieldValue(key, e.target.value)}
                    />
                  )}
                  <FormHelperText error>
                    {touched?.[key] && errors[key]}
                  </FormHelperText>
                </Stack>
              )
            )}

            {/* <FillingStatusWrapper>
              <Span color="#1B1B1B" fontSize="16px" fontWeight="500">
                Filling Status{" "}
              </Span>
              <FillingStatusCheckBoxes>
                {fillStatus.map(({ label, key }, index) => (
                  <TextCheckbox
                    text={label}
                    gridGap="1px"
                    key={index}
                    isChecked={values[key]}
                    onChange={(e) => {
                      resetCheckboxes();
                      setFieldValue(key, e.target.checked);
                    }}
                  />
                ))}
              </FillingStatusCheckBoxes>
            </FillingStatusWrapper> */}
            {fillingError && (
              <FormHelperText
                style={{ width: "100%", textAlign: "center" }}
                error
              >
                {fillingError}
              </FormHelperText>
            )}
            <FlexComp direction="column" gridGap="10px">
              <HeadingFive fontSize="16px" fontWeight="500" color="black">
                Business Address
              </HeadingFive>
              <FlexComp direction="row" gridGap="25px" width="100%">
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="Street"
                    id="outlined-size-small"
                    size="size"
                    placeholder="Enter"
                    value={values.businessAddress.street}
                    onChange={(e) =>
                      setFieldValue("businessAddress.street", e.target.value)
                    }
                  />
                  <FormHelperText error>
                    {touched.businessAddress?.street &&
                      errors.businessAddress?.street}
                  </FormHelperText>
                </Stack>

                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="City"
                    id="outlined-size-small"
                    size="size"
                    placeholder="Enter"
                    value={values.businessAddress.city}
                    onChange={(e) =>
                      setFieldValue("businessAddress.city", e.target.value)
                    }
                  />
                  <FormHelperText error>
                    {touched.businessAddress?.city &&
                      errors.businessAddress?.city}
                  </FormHelperText>
                </Stack>
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={stateOptions}
                    value={values.businessAddress?.state}
                    onChange={(_, newVal) => {
                      setFieldValue(
                        `businessAddress.state`,
                        newVal?.value || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" />
                    )}
                  />
                  <FormHelperText error>
                    {touched.businessAddress?.state &&
                      errors.businessAddress?.state}
                  </FormHelperText>
                </Stack>
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="Zip Code"
                    id="outlined-size-small"
                    size="size"
                    placeholder="XXXXX-XXXX"
                    value={values.businessAddress.zipCode}
                    onChange={(e) => {
                      let zipCodeValue = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 9);
                      if (zipCodeValue.length > 5) {
                        zipCodeValue =
                          zipCodeValue.slice(0, 5) +
                          "-" +
                          zipCodeValue.slice(5);
                      }
                      setFieldValue(`businessAddress.zipCode`, zipCodeValue);
                    }}
                  />
                  <FormHelperText error>
                    {touched.businessAddress?.zipCode &&
                      errors.businessAddress?.zipCode}
                  </FormHelperText>
                </Stack>
              </FlexComp>
            </FlexComp>
          </BasicInfoInputWrapper>
          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"
            }
            type="submit"
            width="15%"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="flex-end"
          />
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default BasicInfo;
