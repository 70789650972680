import React from "react";
import { ToggleButtonDiv } from "./Button.style";
import { Button } from "./Button";

const AdminToggleButton = ({
  setActiveButton,
  activeButton,
  btn1text,
  btn2text,
  btn3text,
  btn4text,
}) => {
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <ToggleButtonDiv>
      {/* <Button
        text={btn1text || "All Tasks"}
        fontSize="14px"
        width="150px"
        height="40px"
        padding="0px"
        margin="5px"
        borderRadius="10px"
        backgroundColor={
          activeButton === "All Tasks"
            ? "rgba(0, 173, 239, 1)"
            : "rgba(239, 243, 253, 1)"
        }
        color={activeButton === "All Tasks" ? "white" : "black"}
        onClick={() => handleButtonClick("All Tasks")}
      /> */}
      <Button
        text={btn2text || "Assigned Tasks"}
        fontSize="14px"
        width="300px"
        height="40px"
        padding="0px"
        margin="5px"
        borderRadius="10px"
        backgroundColor={
          activeButton === "Assigned Tasks"
            ? "rgba(0, 173, 239, 1)"
            : "rgba(239, 243, 253, 1)"
        }
        color={activeButton === "Assigned Tasks" ? "white" : "black"}
        onClick={() => handleButtonClick("Assigned Tasks")}
      />
      <Button
        text={btn3text || "Unassigned Tasks"}
        fontSize="14px"
        width="350px"
        height="40px"
        padding="0px"
        margin="5px"
        borderRadius="10px"
        backgroundColor={
          activeButton === "Unassigned Tasks"
            ? "rgba(0, 173, 239, 1)"
            : "rgba(239, 243, 253, 1)"
        }
        color={activeButton === "Unassigned Tasks" ? "white" : "black"}
        onClick={() => handleButtonClick("Unassigned Tasks")}
      />
      <Button
        text={btn4text || "Formation Request"}
        fontSize="14px"
        width="350px"
        height="40px"
        padding="0px"
        margin="5px"
        borderRadius="10px"
        backgroundColor={
          activeButton === "Formation Request"
            ? "rgba(0, 173, 239, 1)"
            : "rgba(239, 243, 253, 1)"
        }
        color={activeButton === "Formation Request" ? "white" : "black"}
        onClick={() => handleButtonClick("Formation Request")}
      />
    </ToggleButtonDiv>
  );
};

export default AdminToggleButton;
