import { useContext, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import Logo from "assets/auth/Logo.png";
import * as Yup from "yup";
import {
  AlreadyAcc,
  FieldWrapper,
  FormLogo,
  FormSec,
  FormWrapper,
  StyledInput,
} from "../signup/Signup.style";
import { Button } from "components/reusable/button/Button";
import {
  ForgetPasswordWrapper,
  LoginMessageWrapper,
} from "../signin/Signin.style";
import { useNavigate } from "react-router-dom";
import { Span } from "components/reusable/typography/Typography.style";
import { SyncLoader } from "react-spinners";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "services/firebaseSetup";
import { ProfileContext } from "context/ProfileContext";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";
import LoginVerModal from "components/modals/LoginVerModal";

const errorToast = (message = "") => toast.error(message);

const ClientLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("email is required"),
    password: Yup.string().required("Password is required"),
  });
  const [showLoginVerificationModal, setShowLoginVerificationModal] =
    useState(false);
  const [showPass, setShowPass] = useState(false);
  const [, setLoading] = useState(false);
  const { setadminstaffProfileData } = useContext(ProfileContext);
  const [userEmail, setUserEmail] = useState();
  const [otp, setOtp] = useState("000000");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const email = values.email;
      const usersQuery = query(
        collection(db, "users"),
        where("email", "==", email)
      );
      await getDocs(usersQuery);

      setIsLoading(true);
      // if (userRole === 'Staff') {
      setLoading(true);
      const obj = {
        ...values,
        role: "Staff",
      };
      const response = await apiReq(API_ROUTES.userLogin, "POST", obj);
      setUserEmail(response.data.data.email);
      // console.log(response.data.data.email)
      // localStorage.setItem("userData", JSON.stringify(response.data?.data));
      // setadminstaffProfileData(response?.data?.data);
      // localStorage.setItem("auth", true);
      // localStorage.setItem("clientId", response.data.data.user._id);
      // navigate("/staffDashboard");
      setShowLoginVerificationModal(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("API error:", error);
      toast.error(error.message);
    }
    setSubmitting(false);
  };
  console.log(Formik?.values?.email, "Formik?.values?.email");
  const handleOTPVerification = async (otp) => {
    console.log("OTP:", otp);
    console.log("User Email:", userEmail);

    try {
      console.log("Attempting API request...");
      const payload = {
        email: userEmail,
        otp: otp,
        role: "Staff",
      };
      const response = await apiReq(API_ROUTES.loginOTPVerify, "POST", payload);

      console.log("API Response:", response);

      if (response.status === 200) {
        setShowLoginVerificationModal(false);
        localStorage.setItem("userData", JSON.stringify(response.data?.data));
        setadminstaffProfileData(response?.data?.data);

        localStorage.setItem("auth", true);
        localStorage.setItem("clientId", response.data.data.user._id);
        navigate("/staffDashboard");
      } else {
        console.log("Response status is not 200");
        errorToast("OTP verification failed");
      }
    } catch (error) {
      console.error("Full Error:", error);

      // Check for specific error details
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received");
      } else {
        // Something happened in setting up the request
        console.error("Error Message:", error.message);
      }

      errorToast("Error during OTP verification. Please try again later.");
    }
  };
  const onClose = () => {
    setShowLoginVerificationModal(false);
  };
  console.log(otp, "otppp");

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <FormWrapper>
            <FormSec gridGap="30px">
              <FormLogo>
                <img src={Logo} alt="Logo" />
              </FormLogo>
              <LoginMessageWrapper>
                <h5>Staff Portal</h5>
                <p>Enter your credentials to login your account.</p>
              </LoginMessageWrapper>
              <FieldWrapper>
                <p>Email Address</p>
                <Field
                  className="emailComp"
                  type="email"
                  name="email"
                  component={StyledInput}
                  placeholder="Email"
                  onChange={(e) =>
                    formikProps.setFieldValue("email", e.target.value)
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message "
                />
              </FieldWrapper>
              <ForgetPasswordWrapper>
                <FieldWrapper>
                  <p>Password</p>
                  <Field
                    className="emailComp"
                    type={showPass ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    component={StyledInput}
                    onChange={(e) =>
                      formikProps.setFieldValue("password", e.target.value)
                    }
                  />
                  <h5 onClick={() => setShowPass(!showPass)}>
                    {showPass ? "HIDE" : "SHOW"}
                  </h5>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message "
                  />
                </FieldWrapper>
                <Span
                  color="#fff"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="end"
                  cursor="pointer"
                >
                  <p onClick={() => navigate("/staff-forget")}>
                    {" "}
                    Forgot Password
                  </p>
                </Span>
              </ForgetPasswordWrapper>
              <Button
                type="submit"
                text={
                  isLoading ? (
                    <SyncLoader color={"#FFFFFF"} size={8} />
                  ) : (
                    "Log in"
                  )
                }
                width="100%"
                fontSize="18px"
                disabled={isLoading}
              />
            </FormSec>
          </FormWrapper>
        )}
      </Formik>
      <LoginVerModal
        otp={otp}
        setOtp={setOtp}
        open={showLoginVerificationModal}
        onVerify={handleOTPVerification}
        email={userEmail}
        onClose={onClose}
      />
    </>
  );
};

export default ClientLogin;
