import { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  GrayBox,
  UploadLossBody,
  UploadLossButtonWrapper,
  UploadLossContainer,
  UploadLossHeader,
} from "./UploadAndLoss.style";
import { Span } from "../reusable/typography/Typography.style";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonIcon } from "../reusable/button/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "../reusable/button/Button";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import generalBusinessCsv from "../../assets/csv/general_business_sample.csv";
import rentalPropertyCsv from "../../assets/csv/rental_property_sample.csv";
import truckDriverCsv from "../../assets/csv/truck_driver_sample.csv";
import csvIcon from "../../assets/csv-icon.svg";

const errorToast = (message = "") => toast.error(message);

const UploadAndLoss = ({
  modalHeading = "Upload Profit & Loss Document",
  buttonText = "Upload Education Document",
  isUploadModal,
  setIsUploadModal,
  payroll,
  contract,
  fileName,
  generalBusiness,
  fileData,
  setIsConfirmationModal,
}) => {
  // const [file, setFile] = useState(null);
  // const fileInputRef = useRef(null);
  // const { pathname } = useLocation();
  // const [clientId] = useState(localStorage.getItem("clientId"));

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  // };

  // console.log(fileName, "fileName");

  // const handleGrayBoxClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };
  // const Submit = async () => {
  //   try {
  //     if (!file) {
  //       errorToast("Please uplaod File.");
  //       return;
  //     } else if (file && file.type !== "text/csv") {
  //       errorToast("Please uplaod valid CSV File.");
  //       return;
  //     }

  //     // Prepare FormData
  //     const formData = new FormData();
  //     // Append clientId if available
  //     if (clientId) {
  //       formData.append("clientId", clientId);
  //     }
  //     const URL =
  //       pathname === "/general-business"
  //         ? API_ROUTES.uploadGeneralBusiness
  //         : pathname === "/rental-property"
  //         ? API_ROUTES.uploadRentalPropertyDoc
  //         : API_ROUTES.uploadTruckDriverDoc;
  //     formData.append("generalBusinessDoc", file);
  //     if (generalBusiness = "generaBusiness"){
  //       formData.append("payroll", payroll);
  //       formData.append("contract", contract);
  //       formData.append("payRollDoc", fileData?.payRollDoc);
  //       formData.append("contractDoc", fileData?.contractDoc);
  //     }
  //     await apiReq(URL, "POST", formData, API_BASE_URL, false);
  //   } catch (error) {
  //     errorToast(
  //       error?.message || error?.response?.message || "Error while parsing CSV"
  //     );
  //   } finally {
  //     setFile(null);
  //     setIsUploadModal(!isUploadModal);
  //   }
  // };

  return (
    <Modal
      open={isUploadModal}
      onClose={() => {
        // setFile(null);
        setIsUploadModal(!isUploadModal);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <UploadLossContainer>
        <UploadLossHeader>
          <Span color="#FFFFFF" fontSize="16px" fontWeight="500">
            {modalHeading}
          </Span>
          <CloseIcon
            sx={{ color: "#FFFFFF", cursor: "pointer" }}
            onClick={() => {
              // setFile(null);
              setIsUploadModal(!isUploadModal);
            }}
          />
        </UploadLossHeader>
        <UploadLossBody>
          {/* onClick={handleGrayBoxClick} */}
          <GrayBox>
            <Span fontSize="16px" fontWeight="400">
              You can choose file from folder or you can drag & drop file.
            </Span>
            {/* <ButtonIcon
              width="50%"
              icon={<CloudUploadIcon />}
              text={!file ? buttonText : file?.name}
              fontSize="14px"
            />
            <input
              type="file"
              accept=".xls,.csv"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            /> */}

            {generalBusiness == "generaBusiness" && (
              <a
                href={generalBusinessCsv}
                download={generalBusinessCsv.pdf}
                style={{ fontSize: "16px", color: "#000" }}
              >
                <img src={csvIcon} alt="" /> Download Sample Template
              </a>
            )}
            {generalBusiness == "rentalProperty" && (
              <a
                href={rentalPropertyCsv}
                download={rentalPropertyCsv.pdf}
                style={{ fontSize: "16px", color: "#000" }}
              >
                <img src={csvIcon} alt="" /> Download Sample Template
              </a>
            )}
            {generalBusiness == "truckDriver" && (
              <a
                href={truckDriverCsv}
                download={truckDriverCsv.pdf}
                style={{ fontSize: "16px", color: "#000" }}
              >
                <img src={csvIcon} alt="" /> Download Sample Template
              </a>
            )}
            <ButtonIcon
              width="50%"
              icon={<CloudUploadIcon />}
              text={"Upload Documents"}
              fontSize="14px"
              clickHandler={() => {
                setIsConfirmationModal(true);
                setIsUploadModal(false);
              }}
            />

            <Span fontSize="16px" fontWeight="400">
              Format: only CSV supported.
            </Span>
          </GrayBox>
          <UploadLossButtonWrapper>
            <Button
              text="Cancel"
              width="25%"
              fontSize="18px"
              backgroundColor="#EFF3FD"
              color="#AEB6CF"
              padding="10px 16px"
              borderRadius="10px"
              onClick={() => setIsUploadModal(!isUploadModal)}
            />
            <Button
              text="Submit"
              width="25%"
              fontSize="18px"
              backgroundColor="#0B2558"
              color="#FFFFFF"
              padding="10px 16px"
              borderRadius="10px"
              // onClick={Submit}
            />
          </UploadLossButtonWrapper>
        </UploadLossBody>
      </UploadLossContainer>
    </Modal>
  );
};

export default UploadAndLoss;
