import styled from "styled-components";

export const BusinessCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
`;

export const EntityInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  flex-wrap: wrap;
`;

export const BusinessInfoTextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 30px 20px;
  width: 100%;
`;

export const PayRollWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 35px;
`;

export const ContractWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 30px;
`;

export const ExpenseInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px 0px;
`;

export const ExpenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  align-items: flex-start;
`;

export const ExpenseBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FixedAssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  align-items: flex-start;
  min-height: 65vh;
`;

export const FixedAssetInputWrapper = styled.div`
  display: flex;
  grid-gap: 15px;
  align-items: center;
  width: 100%;
`;

export const VehicleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  align-items: flex-start;
`;

export const VehicleInfoInputWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px 5px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const VehicleInfoMidSec = styled.div`
  width: ${({ width }) => width};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px;
  @media (max-width: 1150px) {
    width: ${({ smWidth }) => smWidth};

  }
`;

export const VehicleInfoCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 38px;
`;

export const VehicleInfoCheckBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width};
  grid-gap: ${({ gridGap }) => gridGap};
`;

export const BusinessUseHomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  align-items: flex-start;
`;

export const BusinessUseHomeInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px 5px;
  width: 100%;
  flex-wrap: wrap;
`;
