import React from "react";
import { BarChart, Bar, Legend, Rectangle, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const TaskBarChart = ({data, barWidth,width, legendNames = [],staffMonthlyreport }) => {
  
  const daysOfWeek = Object?.keys(data?.weeklyCompletedQueue? data?.weeklyCompletedQueue:''); // Get the days of the week
  const weekData = daysOfWeek?.map(day => ({
    name: day.charAt(0).toUpperCase() + day.slice(1),
    Completed: data?.weeklyCompletedQueue[day],
    "Pending Task": data?.weeklyPendingQueue[day],
  }));

  const months = Object?.keys(data?.monthlyCompletedQueue? data?.monthlyCompletedQueue:''); // Get the days of the week
  const monthData = months?.map(month => ({
    name: month.slice(0, 3),
    Completed: data?.monthlyCompletedQueue[month],
    "Pending Task": data?.monthlyPendingQueue[month],
  }));
const chartData=staffMonthlyreport?monthData:weekData;
  return (
    <div>
      <style>
        {`
          .recharts-cartesian-axis-line {
            display: none;
          }
          .recharts-text {
            font-size: 10px;
          }
          .recharts-legend-item-text {
            font-size: 10px;
          }
          .recharts-legend-icon {
            width: 12px !important;
            height: 12px !important;
          }
        `}
      </style>
      <ResponsiveContainer height={250} width="100%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Legend />
          {legendNames.map((name, index) => (
            <Bar
              key={name}
              dataKey={name}
              fill={index % 2 === 0 ? "rgba(11, 37, 88, 1)" : "rgba(0, 173, 239, 1)"}
              activeBar={<Rectangle fill={index % 2 === 0 ? "gold" : "pink"} stroke={index % 2 === 0 ? "purple" : "blue"} />}
              barSize={barWidth}
              radius={[2, 2, 2, 2]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TaskBarChart;
