import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { Button } from "../reusable/button/Button";
import { HeadingFive } from "../reusable/typography/Typography.style";
import { EmailVerContainer, ModalHeading, ModalText } from "./Modals.style";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const LoginVerModal = ({ open, onClose, onVerify, email, otp, setOtp }) => {
  const [timer, setTimer] = useState(300); // 5 minutes timer in seconds
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    let interval;
    if (open && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [open, timer]);

  const handleVerify = () => {
    onVerify(otp);
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <EmailVerContainer>
        <ModalHeading>
          <HeadingFive fontSize="36px">Email Verification</HeadingFive>
          <CloseIcon
            sx={{ color: "#000", fontSize: "40px", cursor: "pointer" }}
            onClick={onClose}
          />
        </ModalHeading>
        <ModalText>
          <p style={{ fontSize: "16px" }}>
            An 6-digit code has been sent to{" "}
            <span style={{ color: "rgba(0, 173, 239, 1)" }}>{email}</span>
          </p>
          {/* <p>{email}</p> */}
          {/* <EditIcon style={{ color: "#00adef" }} /> */}
        </ModalText>
        <OTPInput
          containerStyle={{
            width: "100%",
          }}
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "20%",
            fontSize: "50px",
            borderRadius: "10px",
            border: "1px solid #00000033",
            margin: "10px",
            padding: "10px",
            color: "#40485C80",
          }}
        />
        <Button
          text="Verify and Continue"
          width="100%"
          fontSize="18px"
          onClick={handleVerify}
        />
      </EmailVerContainer>
    </Modal>
  );
};

export default LoginVerModal;
