import { IconButton, Stack } from "@mui/material";
import CustomTable from "components/reusable/customTable/CustomTable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import edit from "assets/client/edit.png";
import dayjs from "dayjs";
import detail from "assets/client/detail.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Signature from "components/modals/Signature";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import FormationPreviewModal from "components/modals/FormationPreviewModal";
import WorksheetModal from "components/modals/WorksheetModal";

const errorToast = (message = "") => toast.error(message);
const infoToast = (message = "") => toast.info(message);
const WorkSheetList = ({ isTermModal, setIsTermModal }) => {
  const [tab_loader, setTabLoader] = useState(true);
  const [businessData, setBusinessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [businessIds, setBusinessIds] = useState();

  const clientId = localStorage.getItem("clientId");

  const BGColumns = [
    {   
      headerName: "Sr#",
      field: "srNo",
      width: 100,
      editable: false,
    },
    {
      field: "taxYear",
      headerName: "Tax Year",
      width: 250,
      editable: false,
      renderCell: (params) => {
        
        <div>
            {dayjs(params?.row?.taxYear).format("YYYY")}
            </div> // Assuming you have a formattedDate function
      },
    },

    {
      field: "noVendors",
      headerName: "No. of Vendors",
      width: 300,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Data Entery"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(244, 184, 27, 0.3)",
            color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
          }}
        >
         {params.row.formProgress < 100 ? "Incomplete" : "Verification Needed"}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div>{dayjs(params.values).format("DD-MM-YYYY")}</div> // Assuming you have a formattedDate function
      ),
    },
    {
      headerName: "Action",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="space-around">
          {params.row.status !== "Submitted" && <IconButton
            color="primary"
            onClick={() => {
              localStorage.setItem(
                "worksheetId",
                JSON.stringify(params.row.otherDetails._id)
              );
              localStorage.setItem("worksheetEdit", true);
              navigate("/add-worksheet");
            }}
          >
            <img src={edit} alt="edit" width={30} height={25} />
          </IconButton>}
          <IconButton
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails);
              setIsPreviewModal(true);
            }}
          >
            <img src={detail} alt="detail" width={30} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      setTabLoader(true);
      const response = await apiReq(
        API_ROUTES.getWorkSheetList,
        "POST",
        { clientId }
      );
      const responseData = await response.data;

      // Extracting all _id values and setting them
      const businessIds = responseData?.data?.map(
        (businessFormation) => businessFormation?._id
      );
      setBusinessIds(businessIds); // Save the array of ids

      // Mapping the data for display
      const updatedData = responseData?.data?.map((businessFormation, i) => ({
        id: businessFormation?._id,
        srNo: i + 1,
        taxYear: dayjs(businessFormation?.taxYear).format("YYYY"),
        noVendors: businessFormation?.businessInfoArr?.length,
        status: businessFormation?.status,
        date: businessFormation?.createdAt,
        otherDetails: businessFormation,
      }));

      setBusinessData(updatedData);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. Please try later."
      );
    } finally {
      setTabLoader(false);
    }
  };
  console.log(businessIds, "BusinessIds");
  const handleSaveSignature = async (signatureData) => {
    try {
      const clientId = localStorage.getItem("clientId");
      if (!clientId) {
        errorToast("clientId not found!");
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("clientId", clientId);

      formData.append("worksheetIds", JSON.stringify(businessIds));
      formData.append("status", "Submitted");

      if (signatureData) {
        const { signatureFile, ...rest } = signatureData;
        formData.append("signatureData", JSON.stringify(rest));
        formData.append("signatureFile", signatureFile);
      }
      // Create API call
      await apiReq(
        API_ROUTES.SubmitWorksheetForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );

      // peronalCollectionId
      // generalBusinessId

      setTimeout(() => {
        infoToast("Form submitted successfully");
        setIsTermModal(false);
        fetchData();
      }, 2000);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    localStorage.removeItem("worksheetId");
    localStorage.removeItem("worksheetEdit");
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <BusinessListWrapper>
        <CustomTable
          loading={tab_loader}
          rows={businessData}
          columns={BGColumns}
        />
      </BusinessListWrapper>
      <Signature
        loading={loading}
        isModalOpen={isTermModal}
        onSaveSignature={handleSaveSignature}
        closeModal={() => setIsTermModal(!isTermModal)}
      />
        <WorksheetModal
        isModalOpen={isPreviewModal}
        closeModal={() => setIsPreviewModal(!isPreviewModal)}
        modalData={modalData}
      />
    </>
  );
};

export default WorkSheetList;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
