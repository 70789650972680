import React from "react";
import DashboardCard from "../../../components/client/cards/DashboardCard";
import { CardsContainer, SummaryWrapper, TitleSec } from "./Dashboard.style";
import { HeadingFive, Paragraph } from "../../reusable/typography/Typography.style";

const SummaryComp = ({ userData }) => {
  // Define an array of objects with titles and corresponding keys from userData
  const dashboardCard = [
    {
      title: "Tax Doc Uploaded",
      key: "taxDocs",
    },

    {
      title: "Review Needed",
      key: "confirmationNeeded",
    },
    {
      title: "Tax Info Submitted",
      key: "taxInfoSubmitted",
    },
 
    {
      title: "Tax Return Completed",
      key: "taxReturn",
    },
  ];

  return (
    <SummaryWrapper>
      <TitleSec>
        <HeadingFive fontSize="20px" fontWeight="600">
          Summary
        </HeadingFive>
        <Paragraph fontSize="16px" fontWeight="400" color="#7b91b0">
          Overall Summary
        </Paragraph>
      </TitleSec>
      <CardsContainer>
        {dashboardCard.map(({ title, key }, index) => (
          <DashboardCard
            title={title}
            value={userData && userData[key]} // Access value from userData using the key
            key={index}
          />
        ))}
      </CardsContainer>
    </SummaryWrapper>
  );
};

export default SummaryComp;
