import { useContext, useEffect, useState } from "react";
import {
  ExpenseBtnWrapper,
  ExpenseContainer,
  ExpenseInputWrapper,
} from "./BusinessCollection.style";
import { Input, InputWithAdornment } from "components/reusable/input/Inputs";
import { expenseFields } from "data/clientData/generalBusiness";
import { Button } from "components/reusable/button/Button";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import FlexComp from "components/reusable/flex/Flex";
import { ErrorMessage, Form, Formik } from "formik";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as Yup from "yup";
import { apiReq } from "services/Requests";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { ButtonIcon } from "components/reusable/button/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { PageLoaderContext } from "context/PageLoaderProvider";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const Expense = ({ setCurrentTab, rowData }) => {
  const [Data, setData] = useState();
  const [expenses, setExpenses] = useState([]);
  const { setOpen } = useContext(PageLoaderContext);
  const [openIndex, setOpenIndex] = useState(null);
  const editForm = localStorage.getItem("editForm");
  const clientId = localStorage.getItem("clientId");
  const [otherExpenses, setotherExpenses] = useState([]);
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const generalBusinessMainId = localStorage.getItem("generalBusinessMainId");
  console.log(generalBusinessMainId, "idd");

  const addExpense = () => {
    const newExpense = {
      title: `Additional Expense ${expenses.length + 1} `,
      fields: [
        {
          label: "Expense Name",
          placeholder: "Enter Name",
          defaultValue: "",
          id: "outlined-size-small",
          size: "size",
          width: "32%",
          value: "",
          name: "name",
        },
        {
          label: "Amount",
          placeholder: "$",
          defaultValue: "",
          id: "outlined-size-small",
          size: "size",
          width: "32%",
          value: "",
          name: "amount",
        },
      ], // You can initialize with any default fields if needed
    };
    // setExpenses([...expenses, newExpense]);
    // setotherExpenses(expenses)
    const newExpenses = [...expenses, newExpense];
    setExpenses(newExpenses);
    setotherExpenses(expenses);
    setOpenIndex(newExpenses.length - 1);
  };

  const deleteExpense = (index) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  };

  const initialValues = {
    grossReceipts: Data?.grossReceipts || "",
    accounting: Data?.accounting || "",
    officeExpenses: Data?.officeExpenses || "",
    vehicleExp: Data?.vehicleExp || "",
    advertising: Data?.advertising || "",
    outsideContractors: Data?.outsideContractors || "",
    shipper: Data?.shipper || "",
    bankCharges: Data?.bankCharges || "",
    postage: Data?.postage || "",
    subscriptions: Data?.subscriptions || "",
    cellPhone: Data?.cellPhone || "",
    printing: Data?.printing || "",
    equipmentRental: Data?.equipmentRental || "",
    insurance: Data?.insurance || "",
    rent: Data?.rent || "",
    interestExp: Data?.interestExp || "",
    internetCost: Data?.internetCost || "",
    repairsAndMaintenance: Data?.repairsAndMaintenance || "",
    commissionExpenses: Data?.commissionExpenses || "",
    legalAndProfessionalFees: Data?.legalAndProfessionalFees || "",
    supplies: Data?.supplies || "",
    travel: Data?.travel || "",
    licenseAndPermit: Data?.licenseAndPermit || "",
    telephone: Data?.telephone || "",
    other: Data?.other || "",
    miscellaneous: Data?.miscellaneous || "",
    training: Data?.training || "",
    mealsAndEntertainment: Data?.mealsAndEntertainment || "",
    otherExpense: Data
      ? Data.otherExpense.map((asset) => ({
          name: asset.name,
          amount: asset.amount,
        }))
      : [],
  };

  const validationSchema = Yup.object().shape({
    grossReceipts: Yup.string().required("Gross Receipts is required"),
    accounting: Yup.string(),
    officeExpenses: Yup.string(),
    vehicleExp: Yup.string(),
    advertising: Yup.string(),
    outsideContractors: Yup.string(),
    shipper: Yup.string(),
    bankCharges: Yup.string(),
    postage: Yup.string(),
    subscriptions: Yup.string(),
    cellPhone: Yup.string(),
    printing: Yup.string(),
    equipmentRental: Yup.string(),
    insurance: Yup.string(),
    rent: Yup.string(),
    interestExp: Yup.string(),
    internetCost: Yup.string(),
    repairsAndMaintenance: Yup.string(),
    commissionExpenses: Yup.string(),
    legalAndProfessionalFees: Yup.string(),
    supplies: Yup.string(),
    travel: Yup.string(),
    licenseAndPermit: Yup.string(),
    telephone: Yup.string(),
    other: Yup.string(),
    miscellaneous: Yup.string(),
    training: Yup.string(),
    mealsAndEntertainment: Yup.string(),
    otherExpense: Yup.array(),
  });
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      // Prepare data to be uploaded
      let uploadData;
      // const uploadData = {
      //   ...values,
      //   clientId,
      //   generalBusinessMainId,
      // };
      if (editForm === "true" && Data?._id) {
        console.log("first");
        uploadData = {
          ...values,
          clientId,
          expensesId: Data?._id,
        };
      } else {
        console.log("second");
        uploadData = {
          ...values,
          clientId,
          generalBusinessMainId: generalBusinessMainId
            ? generalBusinessMainId
            : rowData?._id,
        };
      }
      const { data: { data = {} } = {} } = await apiReq(
        !Data?._id ? API_ROUTES.addExpense : API_ROUTES.updateExpense,
        "POST",
        uploadData
      );
      localStorage.setItem("expensesId", data?._id);
      localStorage.setItem(
        "tabs",
        JSON.stringify({ ...menuTabs, fixed_assets: false })
      );
      infoToast(
        Data?._id
          ? "Expense info updated succesfully"
          : "Expense info saved succesfully"
      );
      setTimeout(() => {
        setCurrentTab(2);
      }, 1500);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };
  console.log(
    rowData?._id,
    generalBusinessMainId,
    Data?._id,
    "adsdasdasdasdasasd"
  );

  useEffect(() => {
    const fetchdata = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getGeneralBusinessByID +
            (rowData?._id || generalBusinessMainId),
          "POST",
          { clientId: clientId }
        );
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, fixed_assets: false })
        );
        setData(data?.expensesId);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
        // Handle error
      } finally {
        setOpen(false);
      }
    };
    (rowData?._id || generalBusinessMainId) && fetchdata();
  }, [clientId, rowData?._id]);

  return (
    <>
      <Formik
        enableReinitialize
        key={Data?.id || "defaultKey"}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <ExpenseContainer>
                <HeadingFive color="#000" fontSize="18px" fontWeight="500">
                  Income
                </HeadingFive>
                <Input
                  label="Gross Receipts or Sales *"
                  id="outlined-size-small"
                  defaultValue=""
                  size="size"
                  width="32%"
                  placeholder="Enter"
                  name="grossReceipts"
                  value={formik.values.grossReceipts}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessage
                  name="grossReceipts"
                  component="div"
                  className="error-message"
                />
                <h3>Expenses</h3>
                <ExpenseInputWrapper>
                  {expenseFields.map(
                    ({ label, id, width, placeHolder, name }, index) => (
                      <InputWithAdornment
                        label={label}
                        width={width}
                        id={id}
                        placeHolder={placeHolder}
                        key={index}
                        name={name}
                        value={formik.values[name]}
                        onChange={(e) => {
                          const val = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 9);
                          formik.setFieldValue([name], val);
                        }}
                      />
                    )
                  )}
                </ExpenseInputWrapper>
                {expenses.map((expense, index) => (
                  <FlexComp
                    key={index}
                    direction="column"
                    justifyContent="flex-start"
                    gridGap="5px"
                  >
                    <ExpAccordion
                      item={expense}
                      key={index}
                      index={index}
                      openIndex={openIndex}
                      setOpenIndex={setOpenIndex}
                      deleteExpense={deleteExpense} // Pass the deleteExpense function
                    />
                  </FlexComp>
                ))}
                <ExpenseBtnWrapper>
                  <Button
                    text="+ Add Other Expense"
                    width="auto"
                    fontSize="18px"
                    backgroundColor="#00ADEF"
                    onClick={addExpense}
                    type="button"
                  />
                  <Button
                    width="20%"
                    fontSize="20px"
                    padding="10px 40px"
                    backgroundColor="#0B2558"
                    text={
                      formik.isSubmitting ? (
                        <SyncLoader color={"#FFFFFF"} size={8} />
                      ) : (
                        "Next"
                      )
                    }
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  />
                </ExpenseBtnWrapper>
              </ExpenseContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Expense;

const ExpAccordion = ({
  item,
  index,
  openIndex,
  setOpenIndex,
  deleteExpense,
}) => {
  const handleAccordionClick = () => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const formik = useFormikContext();
  return (
    <Accordion
      sx={{ backgroundColor: "#EBEFF9" }}
      expanded={openIndex === index}
      onChange={handleAccordionClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <AccordionTitle>
          {item.title}
          <ButtonIcon
            borderRadius="30px"
            width="auto"
            height="25px"
            fontSize="14px"
            bgColor="none"
            margin
            icon={<CloseIcon sx={{ color: "black" }} />}
            clickHandler={(e) => {
              e.stopPropagation();
              deleteExpense(index); // Corrected function name to deleteExpense
            }}
          />
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <InputWrapper>
          {/* Render fields */}
          {item.fields.map((field, fieldIndex) => (
            <Input
              key={fieldIndex}
              label={field.label}
              id={field.id}
              defaultValue={field.defaultValue}
              size={field.size}
              width={field.width}
              placeholder={field.placeholder}
              name={`otherExpense[${index}].${field.name}`}
              value={formik.values[`otherExpense[${index}]..${field.name}`]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          ))}
          {/* Address fields */}
        </InputWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
