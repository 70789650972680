import { Button } from "@mui/material";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import GeneralBusinessModal from "components/modals/staffModals/GernalBusinessModal";
import CustomTable from "components/reusable/customTable/CustomTable";
import DateComponent from "components/reusable/datecomponent/DateComponent";
import StaffHeader from "components/reusable/header/StaffHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as XLSX from "xlsx";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YY");
  return formattedDate;
};

const AdminClientActivity = () => {
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [tab_loader, setTabLoader] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false);
  const [value, setValue] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [originaldata, setoriginalData] = useState();
  const [loading, setLoading] = useState(false);
  const handleSearchByName = (selectedValues) => {
    setSearchName(selectedValues.target.value);
    console.log(searchName, "searchName");
    if (originaldata) {
      const filteredData = originaldata?.filter((item) =>
        item.cname
          .toLowerCase()
          .includes(selectedValues.target.value.toLowerCase())
      );
      setValue(filteredData);
    }
  };

  const fetchTaxFiling = async () => {
    try {
      if (startDate && !endDate) {
        toast.error("Please Select End date");
        return;
      } else if (!startDate && endDate) {
        toast.error("Please Select Start date");
        return;
      }

      const isStartDateAfterEndDate = startDate > endDate;
      if (isStartDateAfterEndDate) {
        toast.error("End date cannot be before the start date");
        return;
      }
      setLoading(true);
      setTabLoader(true);
      const response = await apiReq(
        API_ROUTES.queuesReportClientActivity,
        "POST",
        {
          startDate,
          endDate,
        }
      );

      const responseData = await response.data;
      let flattenedArray = [];
      let responseDataAll = responseData.data;
      Object.keys(responseDataAll).map((key) => {
        if (responseDataAll[key] && responseDataAll[key]?.length > 0)
          flattenedArray = flattenedArray.concat(responseDataAll[key]);
      });
      responseDataAll?.personalCollectionForms?._id &&
        flattenedArray.unshift(responseDataAll.personalCollectionForms);

      const updatedData = flattenedArray?.map((item, index) => {
        return {
          id: index + 1,
          sr: index + 1,
          cname: item?.businessInfoId?.firstName
            ? item?.businessInfoId?.firstName +
              " " +
              item?.businessInfoId?.lastName
            : item?.truckDriverBasicInfoId?.firstName
            ? item?.truckDriverBasicInfoId?.firstName +
              " " +
              item?.truckDriverBasicInfoId?.lastName
            : item?.prepSheetId?.firstName
            ? item?.prepSheetId?.firstName + " " + item?.prepSheetId?.lastName
            : "N/A",
          bname: item?.informationType,
          fprogress: item?.formProgress,
          llogin: "N/A",
          createdat: item?.createdAt,
          updateon: item?.updatedAt,
          priority: item?.priority,
          status: item?.status,
          otherDetails: item,
        };
      });
      setValue(updatedData);
      setoriginalData(updatedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error?.message || "Error fetching data:");
    } finally {
      setTabLoader(false);
    }
  };

  useEffect(() => {
    fetchTaxFiling();
  }, [refetch]);

  const reFetchTaxFiling = () => {
    setRefetch(!refetch);
  };

  const individualColumns = [
    {
      field: "sr",
      headerName: "Sr#",
      width: 10,
      editable: false,
    },
    {
      field: "cname",
      headerName: "Client Name",
      width: 200,
      editable: false,
    },
    {
      field: "bname",
      headerName: "Business Name",
      width: 200,
      editable: false,
    },

    {
      field: "fprogress",
      headerName: "Form Progress",
      width: 150,
      editable: false,
    },
    {
      field: "llogin",
      headerName: "Last Login",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>{params.value ? params.value : "-----"}</div>
      ),
    },
    {
      field: "createdat",
      headerName: "Created Date",
      width: 200,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "updateon",
      headerName: "Modified Date",
      width: 200,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
    },
    // {
    //   // field: "comments",
    //   headerName: "Action",
    //   width: 100,
    //   editable: false,
    //   renderCell: (params) => (
    //     <div>
    //       <Button
    //         variant='text'
    //         color='primary'
    //         onClick={() => setGeneralBusinessModal(true)}
    //       >
    //         <img src={detail} alt='detail' />
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const individualRows = [
    {
      id: 1,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "heigh",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
    {
      id: 2,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "low",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
  ];

  const options = [
    { label: "Select ALL", value: "Select All" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Staff Review", value: "Staff Review" },
  ];
  const handleSelectChange = (selectedValues) => {
    // Handle select change logic
    console.log("select value");
  };

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table.xlsx");
  };
  const ExlhandleButtonClick = () => {
    // Button click logic
    generateExcel();
  };

  const reFetchListing = () => {
    setRefetch(!refetch);
  };
  const searchStaff = async (searchValue) => {
    try {
      console.log(searchValue);
      const filteredAssignData = originaldata.filter((item) => {
        return item?.cname?.toLowerCase().includes(searchValue?.toLowerCase());
        // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
      });

      setValue(filteredAssignData);
    } catch (error) {}
  };
  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          showFirstSelect={false}
          showSecondSelect={false}
          showButton={false}
          showSecondButton={false}
          showThirdButton
          clickHandler2={ExlhandleButtonClick}
          placeholder="Search by Name"
          options={options}
          btntext1="PDF"
          btntext2="Excel"
          onSelectChange={handleSelectChange}
          onChange={handleSearchByName}
          searchStaff={searchStaff}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />
        <DateComponent
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleButtonClick={reFetchListing}
          loading={loading}
        />
        <RentalPropertyWrapper>
          <CustomTable
            rows={value}
            loading={tab_loader}
            enableRowSelection={enableRowSelection}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
      <GeneralBusinessModal
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      />
      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
    </>
  );
};

export default AdminClientActivity;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
