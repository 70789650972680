import React from "react";
import styled from "styled-components";
import { Span } from "../../reusable/typography/Typography.style";

const DashboardCard = ({ icon, title, number,width, value, titleFontSize, numberFontSize,valueFontSize }) => {
  return (
    <DashboardCardContainer width={width}>
      {icon && <Icon width={40} height={40}>{icon}</Icon>}
      {number && <Number fontSize={numberFontSize}>{number}</Number>}
      {title && <Title fontSize={titleFontSize}>{title}</Title>}
      <Value fontSize={valueFontSize}>{value}</Value>
    </DashboardCardContainer>
  );
};


export default DashboardCard;

const DashboardCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  width: ${({ width }) => (width || "24%")};
  background-color: #eff3fd;
  border-radius: 10px;
  padding:10px 12px;
  /* height: 184px; */
  margin:10px;
  @media (max-width: 1200px) {
    width: ${({ mediaWidth }) => (mediaWidth || "45%")};
  }
`;

const Icon = styled.div`
  /* Define your icon styles here */
  width: ${(props) => props.width ? `${props.width}px` : 'auto'};
  height: ${(props) => props.height ? `${props.height}px` : 'auto'};
`;

const Title = styled(Span)`
  font-size: ${({ fontSize }) => (fontSize || "22px")};
  font-weight: 500;
`;

export const Number =  styled.div`
  font-size:24px;
  font-weight: 500;
`;

export const Value = styled.div`
  font-size: ${({ fontSize }) => (fontSize || "55px")};
  font-weight: 500;
  color: #00ADEF;
`;
