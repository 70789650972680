import styled from "styled-components";

export const PDFContainer = styled.div` 
width: 1050px;
height: 100%;
background-color: white;
font-family: Inter;
padding: 2%;

`
//header style
export const HeaderText = styled.div` 
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`

export const FirstCol = styled.div` 
width: 20%;
display: flex;
flex-direction: column;
padding-left: 20px;
/* align-items: center; */

`
export const VerticalLine = styled.div`
  border: ${({ border }) => (border ? border : "1px solid #00000066")};
  height:  ${({ height }) => (height ? height: "120px")};
  color: ${({ color }) => (color ? color : "grey")};
  width:1px;
  margin:${({ margin }) => (margin ? margin : "")};
`

export const HeaderContent = styled.div` 

flex-direction: column;
padding-right: 20px;


`
export const SecoundCol = styled.div`
width: 60%;
display: flex;
/* flex-direction: column; */
/* padding-left: 20px; */
align-items: center;

`
export const ThirdCol = styled.div`
width: 20%; 
display: flex;
/* flex-direction: column; */
padding-left: 20px;
align-items: center;
`

