import { Button, Dialog, DialogContent } from "@mui/material";
import detail from "assets/client/detail.png";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import AddNewBusiness from "components/modals/AddNewBusiness";
import ClientInformationModal from "components/modals/ClientInformationModal";
import UploadAndLoss from "components/modals/UploadAndLoss";
import ToggleButton from "components/reusable/button/ToggleButton";
import CustomTable from "components/reusable/customTable/CustomTable";
import StaffHeader from "components/reusable/header/StaffHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import CreatePdf from "../pdf/CreatePdf";

const StaffClient = (handleSubmit) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Personal");
  const [showPdfEditor, setShowPdfEditor] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [allOriginalData, setallOriginalData] = useState([]);
  const [OriginalData, setOriginalData] = useState([]);
  const [allbusinessData, setallBusinessData] = useState([]);
  const [generalBusinessData, setGeneralBusinessData] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState();
  const [modalData, setModalData] = useState();
  const [search, setsearch] = useState();
  const [updateStatusId, setupdateStatusId] = useState();
  const [personalCollactionID, setpersonalCollactionID] = useState();
  const [generalBusinessID, setGeneralBusinessID] = useState();
  let initialState = {
    status: null,
    tag: "",
  };
  const [status] = useState(initialState);

  const handleInfoModalClose = () => {
    setOpenInfoModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiReq(
          API_ROUTES.staffClientPersonalGetAll,
          "POST"
        );
        const responseData = await response.data;

        const updatedData = responseData?.data?.map((businessInfo, index) => {
          const signatureId = businessInfo?.signatureId?.form8879 || {};
          return {
            id: businessInfo?._id,
            srno1: index + 1,
            fname: businessInfo?.prepSheetId?.firstName,
            lname: businessInfo?.prepSheetId?.lastName,
            form: "Fill Form", // You might need to adjust this based on your logic
            tr: businessInfo?.taxRepresentative || "N/A",
            cd: businessInfo?.createdAt,
            ssname: businessInfo?.prepSheetId?.ssn,
            businessName: signatureId?.taxPayerName || "N/A", // Access businessName from signatureId
            otherdetails: businessInfo,
          };
        });

        setOriginalData(updatedData);
        setBusinessData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchAllData = async () => {
      try {
        const response = await apiReq(
          API_ROUTES.staffClientBusinessGetAll,
          "POST"
        );
        const responseData = await response.data;

        if (Array.isArray(responseData.data.clientsWorkSheet1099)) {
          const updatedData = responseData.data.clientsWorkSheet1099.map(
            (businessInfo, index) => {
              const businessName =
                businessInfo.businessInfoArr.length > 0
                  ? businessInfo.businessInfoArr[0].businessName
                  : "N/A";

              return {
                id: businessInfo._id,
                srno: index + 1,
                lname: businessInfo.prepSheetId?.lastName,
                fname: businessInfo.prepSheetId?.firstName,
                email: businessInfo.prepSheetId?.email,
                pstatus: businessInfo.paymentStatus,
                trep: businessInfo.taxRepresentative
                  ? businessInfo.taxRepresentative
                  : "N/A",
                cn: businessInfo.additionalNotes,
                cdate: businessInfo.createdAt,
                ssn: businessInfo.prepSheetId?.ssn,
                type: businessInfo.informationType,
                otherdetails: businessInfo,
                bname: businessName,
              };
            }
          );
          setallOriginalData(updatedData);
          setallBusinessData(updatedData);
        } else {
          console.error(
            "clientsWorkSheet1099 is not an array:",
            responseData.data.clientsWorkSheet1099
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const paymentStatusUpdate = async () => {
      try {
        if (updateStatusId) {
          // Create FormData
          const formData = new FormData();
          formData.append("personalCollectionId", updateStatusId);
          formData.append("paymentStatus", true);

          await apiReq(
            API_ROUTES.updateAditionalNotes,
            "POST",
            formData,
            API_BASE_URL,
            false
          );
        }
        setupdateStatusId(null);
      } catch (error) {
        console.error("update payment status", error.message);
      }
    };

    if (activeButton === "Personal") fetchData();
    else if (activeButton === "Business") fetchAllData();
    paymentStatusUpdate();
  }, [updateStatusId, activeButton]);

  useEffect(() => {
    let filteredData =
      activeButton === "Personal" ? OriginalData : allOriginalData;
    let paymentStatus = status.tag === "Paid";

    if (status.status && status.status !== "All Clients")
      filteredData = filteredData.filter((item) => item.type === status.status);

    if (status.tag)
      filteredData = filteredData.filter(
        (item) => item.pstatus === paymentStatus
      );
    if (search)
      if (activeButton === "Personal")
        filteredData = filteredData?.filter((item) =>
          item?.fname?.toLowerCase().includes(search?.toLowerCase())
        );
      else
        filteredData = filteredData?.filter((item) =>
          item?.bname?.toLowerCase().includes(search?.toLowerCase())
        );

    activeButton === "Personal"
      ? setBusinessData(filteredData)
      : setallBusinessData(filteredData);

    // eslint-disable-next-line
  }, [status.status, status.tag, search]);

  const formattedDate = (value) => {
    const date = dayjs(value);
    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  };
  const handleClosePdfEditor = () => {
    setShowPdfEditor(false);
  };
  const handleFillFormClick = () => {
    setShowPdfEditor(true);
  };
  // Remove the onChange prop from the StaffHeader component

  const handleonChange = (e) => {
    setsearch(e.target.value);
  };

  const [signatureImage, setSignatureImage] = useState();

  const handlePersonalModal = (data) => {
    setModalData(data); // Set the modal data
    setPersonalCollectionModal(true); // Open the modal
  };
  const individualColumns = [
    {
      field: "srno1",
      headerName: "Sr#.",
      width: 60,
      editable: false,
    },
    {
      field: "ssname",
      headerName: "Social Security Number",
      width: 200,
      editable: false,
    },
    {
      field: "lname",
      headerName: "Last Name",
      width: 200,
      editable: false,
    },
    {
      field: "fname",
      headerName: "First Name",
      width: 180,
      editable: false,
    },
    {
      field: "businessName",
      headerName: "Business Name",
      width: 200,
      editable: false,
    },
    // {
    //   field: "form",
    //   headerName: "8879 Form",
    //   width: 250,
    //   editable: false,
    //   renderCell: (params) => {
    //     const { eFileTaxReturnCheck } = params.row;
    //     const statusText = eFileTaxReturnCheck ? "Fill Form" : "Completed";

    //     return (
    //       <div
    //         style={{
    //           background: eFileTaxReturnCheck ? "rgba(0, 173, 239, 1)" : "rgba(89, 169, 93, 1)",
    //           borderRadius: "15px",
    //           padding: "5px 10px",
    //           color: "white",
    //           cursor: "pointer",
    //         }}
    //       >
    //         {statusText}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "form",
      headerName: "8879 Form",
      width: 180,
      editable: false,
      renderCell: (params) => {
        const { value } = params;
        if (value === "Fill Form") {
          return (
            <button
              style={{
                border: "none",
                background: "rgba(0, 173, 239, 1)",
                borderRadius: "15px",
                padding: "5px 10px",
                color: "white",
              }}
              onClick={() => {
                handleFillFormClick(params.row);
                setSignatureImage(
                  params.row.otherdetails.signatureId.signatureFile
                );
                console.log(params.row, "signarerassa");
              }}
            >
              Fill Form
            </button>
          );
        } else if (value === "Completed") {
          return (
            <div
              style={{
                border: "none",
                background: "rgba(89, 169, 93, 1)",
                borderRadius: "15px",
                padding: "5px 10px",
                color: "white",
                cursor: "pointer",
              }}
            >
              Completed
            </div>
          );
        } else {
          return null;
        }
      },
    },

    {
      field: "tr",
      headerName: "Tax Representative",
      width: 200,
      editable: false,
    },
    {
      field: "cd",
      headerName: "Created Date",
      width: 150,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      headerName: "Action",
      width: 170,
      editable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setOpenInfoModal(true);
              setModalData(params.row);
              // console.log(params.row, "yomolopo");
            }}
          >
            <img src={detail} alt="detail" />
          </Button>
        </div>
      ),
    },
  ];
  const handleGeneralBusinessModal = (data) => {
    setModalData(data); // Set the modal data
    setGeneralBusinessModal(true); // Open the modal
  };

  const businessColumns = [
    {
      field: "srno",
      headerName: "Sr#.",
      width: 70,
      editable: false,
    },
    {
      field: "id",
      headerName: "Employer ID Number (EIN)",
      width: 200,
      editable: false,
    },
    {
      field: "bname",
      headerName: "Business Name",
      width: 200,
      editable: false,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   width: 150,
    //   editable: false,
    // },
    {
      field: "trep",
      headerName: "Tax Representative",
      width: 200,
      editable: false,
    },
    {
      field: "cn",
      headerName: "Client Notes",
      width: 200,
      editable: false,
    },
    // {
    //   field: "pstatus",
    //   headerName: "Payment Status",
    //   width: 150,
    //   editable: false,
    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         // backgroundColor:
    //         //   params.row.status === "Fill Form"
    //         //     ? "rgba(0, 173, 239, 1)"
    //         //     : "rgba(89, 169, 93, 1)",
    //         color:
    //           params.row.pstatus === "Not Paid"
    //             ? "rgba(210, 47, 47, 1)"
    //             : " rgba(89, 169, 93, 1)",
    //         borderRadius: "15px",
    //         padding: "5px",
    //         cursor:'pointer'
    //       }}
    //     >
    //       {params.row.pstatus}
    //     </div>
    //   ),
    // },
    {
      field: "cdate",
      headerName: "Created Date",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>{formattedDate(params.value)}</div> // Assuming you have a formattedDate function
      ),
    },

    {
      // field: "comments",
      headerName: "Action",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setOpenInfoModal(true);
              setModalData(params.row);
            }}
          >
            <img src={detail} alt="detail" />
          </Button>
        </div>
      ),
    },
  ];

  const columns =
    activeButton === "Personal" ? individualColumns : businessColumns;
  const rows = activeButton === "Personal" ? businessData : allbusinessData;

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton={false}
          btntext="Click Me"
          clickHandler={() => {}}
          // placeholder="All Clients"
          // options={options}
          // placeholder2="Select Tags"
          onChange={handleonChange}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />

        <Button />
        <ToggleButton
          setActiveButton={setActiveButton}
          activeButton={activeButton}
        />

        <RentalPropertyWrapper>
          <CustomTable rows={rows} columns={columns} />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <AddNewBusiness
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        clickHandler={() => navigate("/add-rental")}
        setIsUploadModal={setIsUploadModal}
      />
      <UploadAndLoss
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
      />

      <Dialog
        open={showPdfEditor}
        sx={{
          overflowY: "hidden",
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "none",
            overflowY: "hidden",
            position: "static",
          },
        }}
      >
        <DialogContent
          sx={{
            overflowY: "auto",
            "& .css-pr6p8u-MuiDialogContent-root": {
              overflowY: "hidden",
              padding: "0px",
              height: "100%",
              width: "1023px",
            },
          }}
        >
          <CreatePdf
            handleClosePdfEditor={handleClosePdfEditor}
            handleSubmit={handleSubmit}
            signatureImage={signatureImage}
          />
        </DialogContent>
      </Dialog>

      <ClientInformationModal
        modalType="staffInfo"
        modalData={modalData}
        handleInfoModalClose={handleInfoModalClose}
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
      />

      {/* <ClientInformationModal modalType="clientInformation" modalData={modalData} handleInfoModalClose={handleInfoModalClose} openInfoModal={openInfoModal} setOpenInfoModal={setOpenInfoModal} />
      <PersonalCollection
       personalCollactionID={modalData}
        // modalData={modalData}
        
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      /> */}
      {/* <GeneralBusiness
        modalData={modalData}
        generalBusinessID={generalBusinessID}
        generalBusinessData={generalBusinessData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
      /> */}
      {console.log(modalData, "businessModalData")}
    </>
  );
};

export default StaffClient;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
