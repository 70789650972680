import { useRef, useState } from "react";
import {
  ReviewConfirmationBody,
  ReviewConfirmationBtnsWrapper,
  ReviewConfirmationContainer,
  ReviewConfirmationHeader,
} from "./ReviewAndConfirmation.style";
import { Modal } from "@mui/material";
import { Span } from "components/reusable/typography/Typography.style";
import CloseIcon from "@mui/icons-material/Close";
import ReviewConfirmationBox from "./ReviewConfirmationBox";
import { Button } from "components/reusable/button/Button";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import { useNavigate } from "react-router-dom";
import {
  assets,
  businessInformation,
  businessUseOfHome,
  expenses,
  vehicleInformation,
} from "data/clientData/reviewAndConfirmationModal";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import TermsCondition from "../TermsCondition";
import html2pdf from "html2pdf.js";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";
import OtherExpenses from "./OtherExpenses";

const successToast = (message = "") => toast.success(message);
const errorToast = (message = "") => toast.error(message);

const GeneralBusiness = ({
  generalBusinessModal,
  setGeneralBusinessModal,
  generalBusinessData,
  generalBusinessID,
  reviewAndSubmition,
  download = false,
  check1099Form,
  setIs1099Modal,
  closeTermModal,
  showTermsCondition,
  setShowTermsCondition,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [showTermsCondition, setShowTermsCondition] = useState(false);
  const clientId = localStorage.getItem("clientId");

  console.log("");
  const {
    businessInfoId: businessInfo,
    expensesId: expensesID,
    fixedAssetsId: fixedAssets,
    vehicleInfoId: vehicleInfo,
    businessUseOfHomeId: businessUseOfHomeID,
  } = generalBusinessData || {};
  const userName = localStorage.getItem("userName");
  const initials = localStorage.getItem("initials");
  console.log("expense", expensesID);
  const signatureData = {
    fullName: userName,
    initials: initials,
    signatureStyle: "Ariel",
    eFileTaxReturnCheck: false,
    drawCheck: false,
    uploadCheck: false,
    signatureFile: null,
  };
  // console.log({generalBusinessData});
  console.log(generalBusinessData, "fixedAssets");

  const handleSubmitReview = async () => {
    try {
      console.log(signatureData, "signatureData");
      setLoading(true);
      if (check1099Form) {
        setShowTermsCondition(true);
      } else {
        setIs1099Modal(true);
      }
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error while submit docs. please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnSaveSignature = async (signatureData) => {
    console.log("Received signature data:", signatureData);

    // Early return if signatureFile is missing
    if (!signatureData.signatureFile) {
      console.error("signatureFile is missing");
      errorToast("Signature file is required.");
      return;
    }

    try {
      // Prepare FormData for submission
      const formData = new FormData();

      formData.append("clientId", clientId);
      const generalBusinessId = [generalBusinessID];
      formData.append("generalBusinessId", JSON.stringify(generalBusinessId));
      formData.append("status", "Document Submitted");
      formData.append("signatureData", JSON.stringify(signatureData));

      await apiReq(
        API_ROUTES.SubmitAllForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );
      successToast("Form submitted successfully.");
      setTimeout(() => {
        setGeneralBusinessModal(false);
        fetchData();
      }, 1500);
    } catch (error) {
      // Handle errors during submission
      console.error("Error submitting form:", error);
      errorToast(`Error submitting form: ${error.message || "Unknown error"}.`);
    }
  };
  const componentRef = useRef();
  const handleDownload = () => {
    const element = componentRef.current;
    const opt = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based API
    html2pdf().set(opt).from(element).save();
  };
  return (
    <Modal
      open={generalBusinessModal}
      onClose={() => setGeneralBusinessModal(!generalBusinessModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ReviewConfirmationContainer>
        <ReviewConfirmationHeader>
          <Span color="#000000" fontSize="24px" fontWeight="600">
            General Business
          </Span>
          <CloseIcon
            sx={{ color: "#000000", cursor: "pointer" }}
            onClick={() => setGeneralBusinessModal(!generalBusinessModal)}
          />
        </ReviewConfirmationHeader>
        <ReviewConfirmationBody ref={componentRef}>
          <ReviewConfirmationBox
            data={businessInformation}
            values={businessInfo}
          />
          <ReviewConfirmationBox data={expenses} values={expensesID} />
         {expensesID?.otherExpense &&<OtherExpenses data={expensesID.otherExpense} />}
          {fixedAssets?.fixedAssetsArr?.length > 0 && (
            <ReviewConfirmationBox
              assets={true}
              data={assets}
              values={fixedAssets?.fixedAssetsArr || []}
            />
          )}
          {/* {fixedAssets?.length > 0 && <ReviewConfirmationBox assets={true} data={assets} values={fixedAssets?.fixedAssetsArr || []} />} */}
          <ReviewConfirmationBox
            data={vehicleInformation}
            values={vehicleInfo}
          />
          <ReviewConfirmationBox
            data={businessUseOfHome}
            values={businessUseOfHomeID}
          />
        </ReviewConfirmationBody>

        {reviewAndSubmition && (
          <ReviewConfirmationBtnsWrapper>
            <Button
              text="Edit"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              onClick={() => {
                navigate("/add-business");
                localStorage.setItem("editForm", true);
              }}
            />
            <Button
              text={
                loading ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Confirm"
              }
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#00ADEF"
              onClick={handleSubmitReview}
            />
          </ReviewConfirmationBtnsWrapper>
        )}
        <ReviewConfirmationBtnsWrapper>
          {!reviewAndSubmition && (
            <Button
              text="Ok"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              onClick={() => {
                setGeneralBusinessModal(false);
              }}
            />
          )}
          {download && (
            <TitleButton
              style={{ padding: "10px 20px 10px 20px" }}
              onClick={handleDownload}
            >
              Download
            </TitleButton>
          )}
        </ReviewConfirmationBtnsWrapper>
        {showTermsCondition && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <TermsCondition
              isModalOpen={showTermsCondition}
              closeModal={() => closeTermModal()}
              onSaveSignature={handleOnSaveSignature}
            />
          </div>
        )}
      </ReviewConfirmationContainer>
    </Modal>
  );
};

export default GeneralBusiness;
