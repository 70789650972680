import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { Input } from "components/reusable/input/Inputs";
import { ButtonIcon } from "components/reusable/button/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const ExpAccordion = ({ item, values, index, openIndex, setOpenIndex, deleteExpense, setFieldValue,Icon }) => {
  const handleAccordionClick = () => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the accordion if already open
    } else {
      setOpenIndex(index); // Open the accordion if closed
    }
  };
  
  return (
    <Accordion
      sx={{ backgroundColor: "#EBEFF9" }}
      expanded={openIndex === index}
      onChange={handleAccordionClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <AccordionTitle>
          {item.title}
          <ButtonIcon
            borderRadius="30px"
            width="auto"
            height="25px"
            fontSize="14px"
            bgColor="none"
            margin
            icon={Icon!=='RedTrash'?<CloseIcon sx={{ color: "black"}}/> : <CloseIcon sx={{ color: "black"}}/>}
            clickHandler={(e) => {
              e.stopPropagation();
              deleteExpense(index); // Corrected function name to deleteExpense
            }}
          />
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <InputWrapper>
          {/* Render fields */}
          {item.fields.map((field, fieldIndex) => (
            <Input
              id={field.id}
              key={fieldIndex}
              size={field.size}
              label={field.label}
              width={field.width}
              placeholder={field.placeholder}
              value={values[field.key]}
              onChange={e => {
                setFieldValue(`otherExpenseArr[${index}].${field.key}`, e.target.value);
              }}
            />
          ))}
          {/* Address fields */}
        </InputWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
 justify-content:space-between;
`;

export default ExpAccordion;
