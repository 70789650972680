import { Paragraph } from "../../reusable/typography/Typography.style";
import { TablePercentageContainer } from "./Dashboard.style";

const TablePercentage = ({ value }) => {
  return (
    <TablePercentageContainer>
      <Paragraph fontSize="13px" textAlign="center">
        {value}
      </Paragraph>
    </TablePercentageContainer>
  );
};

export default TablePercentage;
