import styled from "@emotion/styled";
import React from "react";

const FlexComp = ({
  direction,
  justifyContent,
  alignItems,
  gridGap,
  height,
  flexWrap,
  children,
  width,
  marginTop,
}) => {
  return (
    <FlexContainer
      width={width}
      height={height}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gridGap={gridGap}
      flexWrap={flexWrap}
      marginTop={marginTop}
    >
      {children}
    </FlexContainer>
  );
};

export default FlexComp;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || ""};
  justify-content: ${({ justifyContent }) => justifyContent || ""};
  align-items: ${({ alignItems }) => alignItems || ""};
  grid-gap: ${({ gridGap }) => gridGap || ""};
  flex-wrap: ${({ flexWrap }) => flexWrap || ""};
  height: ${({ height }) => height || ""};
  width: ${({ width }) => width || "100%"};
  margin-top: ${({ marginTop }) => marginTop || ""};
`;
