import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import detail from "assets/client/detail.png";
import edit from "assets/client/edit.png";
import AdditionalNotes from "components/modals/AdditionalNotes";
import AddNewBusiness from "components/modals/AddNewBusiness";
import ConfirmationModal from "components/modals/ConfirmationModal";
import ReviewFormPreview from "components/modals/RentalFormPreview";
import UploadAndLoss from "components/modals/UploadAndLoss";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import CustomTable from "components/reusable/customTable/CustomTable";
import TitleComponent from "components/reusable/titleComponent/TitleComponent";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YYYY");
  return formattedDate;
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const RentalPropertyList = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [tab_loader, setTabLoader] = useState(true);
  const [previewRow, setPreviewRow] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [RentalPropData, setRentalPropData] = useState([]);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [openPrevModal, setOpenPrevModal] = useState(false);
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoader(true);
        const clientId = localStorage.getItem("clientId");
        if (!clientId) {
          console.error("Client ID not found in local storage");
          return;
        }

        const response = await apiReq(API_ROUTES.rentalPropertyGetAll, "POST", {
          clientId,
        });
        const responseData = await response.data;
        setPreviewData(responseData.data);
        console.log(responseData.data, "responseData.data");
        const rentalData = responseData.data.map((item, index) => ({
          id: index + 1,
          rentaltype: item.informationType,
          type: item.type,
          status: item.status,
          additionalNotes: item.additionalNotes,
          taxYear: item?.rentalPropertyId.taxYear,
          formProgress: item?.completionPercentage,
          street: item.rentalPropertyId.address.street,
          rentalPropertyMainId: item._id,
        }));
        setRentalPropData(rentalData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setTabLoader(false);
      }
    };

    fetchData();
    localStorage.removeItem("rentalPropertyMainId");
  }, []);
  const rentalPropertyMainId = localStorage.getItem("formsId");
  const saveRentalPropertyMain = async () => {
    try {
      const formData = new FormData();
      formData.append("rentalPropertyMainId", rentalPropertyMainId);
      formData.append("additionalNotes", additionalNotes);
      formData.append("clientId", localStorage.getItem("clientId"));

      await apiReq(`${API_ROUTES.UpdateRentalPropertyMain}`, "POST", formData);
      infoToast(
        edit
          ? "Rental Property Main updated successfully."
          : "Rental Property Main data saved successfully."
      );
      setTimeout(() => navigate("/rental-property"), 1500);
    } catch (error) {
      errorToast(
        "An unexpected error occurred while saving Rental Property Main data. Please try again later."
      );
    }
  };

  const handleEditClick = (row) => {
    localStorage.setItem("rentalPropertyMainId", row.rentalPropertyMainId);
    navigate("/add-rental");
  };

  const handleViewClick = (row) => {
    setOpenPrevModal(true);
    const previewId = row.rentalPropertyMainId;
    const filterData = previewData.filter((item) => item._id === previewId);
    console.log({ filterData });
    setPreviewRow(filterData[0]);
  };

  const BGColumns = [
    {
      field: "rentaltype",
      headerName: "Property Type",
      width: 150,
      editable: false,
    },
    {
      field: "taxYear",
      headerName: "Date",
      width: 140,
      editable: false,

      renderCell: (params) => (
        <>
          <div>{formattedDate(params.row.taxYear)}</div>
        </>
      ),
    },
    {
      field: "type",
      headerName: "Form Type",
      width: 140,
      editable: false,
    },
    {
      headerName: "Form Progress",
      field: "formProgress",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <div>{params.value}%</div> // Assuming you have a formattedDate function
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Data Entery"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(244, 184, 27, 0.3)",
            color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
          }}
        >
        {params.row.formProgress < 100 ? "Incomplete" : "Verification Needed"}
        </div>
      ),
    },
    {
      field: "street",
      headerName: "Street",
      width: 160,
      editable: false,
    },
    {
      field: "additionalNotes",
      headerName: "Comments",
      width: 350,
      editable: false,
    },
    {
      // Action column
      headerName: "Action",
      width: 80,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="space-around">
          {/* {params.row.status === "Verification Needed" && ( // Check if status is Verification Needed */}
            <IconButton
              color="primary"
              onClick={() => {
                handleEditClick(params.row);
              }}
            >
              <img src={edit} alt="edit" width={30} height={25} />
            </IconButton>
          {/* )} */}
          <IconButton
            color="primary"
            onClick={() => handleViewClick(params.row)}
          >
            <img src={detail} alt="detail" width={30} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <RentalPropertyContainer>
        <TitleComponent
          text="Rental Property"
          showButton
          btntext="Add New Property"
          clickHandler={() => setIsOpen(!isOpen)}
        />
        <RentalPropertyWrapper>
          <CustomTable
            rows={RentalPropData}
            columns={BGColumns}
            loading={tab_loader}
            getRowId={(row) => row.id}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <AddNewBusiness
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uploadDocText="Upload Rental Doc"
        clickHandler={() => navigate("/add-rental")}
        setIsUploadModal={setIsUploadModal}
        setIsConfirmationModal={setIsConfirmationModal}
      />
      <UploadAndLoss
        modalHeading="Upload Rental Property Document"
        buttonText="Upload Rental Document"
        generalBusiness="rentalProperty"
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        setIsConfirmationModal={setIsConfirmationModal}
      />
      <ReviewFormPreview
        openPrevModal={openPrevModal}
        setOpenPrevModal={setOpenPrevModal}
        values={previewRow?.rentalPropertyId}
      />
      <ConfirmationModal
        ConfirmAtionData="Rental"
        buttonText="Upload Rental Document"
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        modalHeading="Mandatory Field For Rental Property"
        isConfirmationModal={isConfirmationModal}
        setIsConfirmationModal={setIsConfirmationModal}
        setIsAdditionalModal={setIsAdditionalModal}
      />
      <AdditionalNotes
        id={rentalPropertyMainId}
        isModalOpen={isAdditionalModal}
        closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
        openSecondModal={(content) => {
          setIsAdditionalModal(false);
          setAdditionalNotes(content);
          setIsValidateTaxModal(true);
        }}
        additionalNotes={additionalNotes}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to validate this Rental Form?"
        handleYesClick={() => {
          saveRentalPropertyMain(); // Call the function to save Rental Property Main data
        }} // Pass the API request function
      />
    </>
  );
};

export default RentalPropertyList;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
