import React, { useState } from "react";
import { Span } from "../../../components/reusable/typography/Typography.style";
import styled from "styled-components";
import ProfilePicUpload from "../../../components/reusable/profilePicUpload/ProfilePicUpload";
import ProfileComp from "../../../components/client/settings/Profile";
import StaffProfile from "components/staff/settings/StaffProfile";

const StaffProfiles = () => {
  const [name, setName]=useState()
  return (
    <ProfileContainer>
      <Span color="#1B1B1B" fontSize="25px" fontWeight="500" style={{ padding: "0px 15px"}}>
        {name}
      </Span>
      <StaffProfile setName={setName}/>
    </ProfileContainer>
  );
};

export default StaffProfiles;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 30px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;
