import styled from "styled-components";

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 35px;
`;

export const Line = styled.div`
  border: ${({ border }) => (border ? border : "1px solid #00000066")};
  width: 100%;
  color:${({ color }) => (color ? color : "")};
  margin-top:${({marginTop }) => (marginTop ? marginTop: "")}; ;
`;
