import React from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";

const SecDropdownComp = ({
  label,
  label2,
  width,
  value,
  handleChange,
  options,
  required,
  error,defaultValue,placeholder
}) => {
  return (
    <FormControl sx={{ width: width }} variant="outlined" focused>
      <TextField
        select
        label={label}
        value={value}
        width={ width}
        onChange={handleChange}
        error={error}
        required={required}
        defaultValue={defaultValue}
        placeholder={placeholder}
        variant="outlined"
       
        InputLabelProps={{ shrink: true }}
      
      >
        <MenuItem  value={""}>
            
          </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label} <br />
            {option.label2&&option.label2}
          </MenuItem>
          
        ))}
      </TextField>
    </FormControl>
  );
};

export default SecDropdownComp;
