import React, { useContext, useEffect, useRef, useState } from "react";
import {
  RetirementContainer,
  RetirementWrapper,
} from "./PersonalCollection.style";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import { GoInfo } from "react-icons/go";
import { Box, IconButton, Stack } from "@mui/material";
import { Button } from "components/reusable/button/Button";
import Tooltip from "@mui/material/Tooltip";
import FlexComp from "components/reusable/flex/Flex";
import { ButtonIcon } from "components/reusable/button/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { Input } from "components/layout/navbar/Navbar.style";
import ClearIcon from "@mui/icons-material/Clear";
import { SyncLoader } from "react-spinners";
import AdditionalNotes from "components/modals/AdditionalNotes";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import { useNavigate } from "react-router-dom";

const initialValues = {
  w2docs: "",
  docs1099: "",
  otherDocs: "",
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const UploadDocs = () => {
  const w2docsRef = useRef(null);
  const docs1099Ref = useRef(null);
  const otherDocsRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const [fileName, setFileName] = useState({
    w2docs: "",
    docs1099: "",
    otherDocs: "",
  });
  const clientId = localStorage.getItem("clientId");

  const personalCollectionId = localStorage.getItem("personalCollectionId");
  const { setOpen } = useContext(PageLoaderContext);
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [deletedW2Docs, setDeletedW2Docs] = useState([]);
  const [deleted1099Docs, setDeleted1099Docs] = useState([]);
  const [deletedOtherDocs, setDeletedOtherDocs] = useState([]);
  const handleUploadButtonClick = (e, type) => {
    console.log("first", e);
    e.preventDefault();
    if (type === "w2docs") w2docsRef.current.click();
    if (type === "docs1099") docs1099Ref.current.click();
    if (type === "otherDocs") otherDocsRef.current.click();
  };

  const {
    values,
    errors,
    touched,
    setValues,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: retirementSchema,
    onSubmit: async (values) => {
      try {
        if (!clientId) {
          errorToast("clientId not found!");
          return;
        }
        const formData = new FormData();
        const id = personalCollectionId.replace(/"/g, "");
        formData.append("clientId", clientId.toString());
        formData.append("personalCollectionId", id);

        if (edit) {
          formData.append("uploadDocsId", values?.uploadDocsId);

          // Append the deleted document arrays as single array entries
          // if (deletedW2Docs.length > 0) {
          formData.append("deleteW2Docs", JSON.stringify(deletedW2Docs));
          // }
          // if (deleted1099Docs.length > 0) {
          formData.append("deleteDocs1099", JSON.stringify(deleted1099Docs));
          // }
          // if (deletedOtherDocs.length > 0) {
          formData.append("deleteOtherDocs", JSON.stringify(deletedOtherDocs));
          // }
        }

        // Append remaining form data
        for (const [key, fileArray] of Object.entries(values)) {
          if (Array.isArray(fileArray)) {
            fileArray.forEach((file) => {
              formData.append(`${key}`, file); // Append each file in the array
            });
          } else {
            console.error(`Expected array for ${key}, got:`, fileArray);
          }
        }

        await apiReq(
          `${
            edit ? API_ROUTES.updateAdditionalDoc : API_ROUTES.addAdditionalDoc
          }`,
          "POST",
          formData,
          API_BASE_URL,
          false
        );

        infoToast(
          edit
            ? "Upload Docs updated successfully"
            : "Upload Docs saved successfully"
        );

        // Reset deleted document arrays after successful update
        if (edit) {
          setDeletedW2Docs([]);
          setDeleted1099Docs([]);
          setDeletedOtherDocs([]);
        }

        setTimeout(() => {
          setIsAdditionalModal(true);
        }, 1000);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchUploadDocs = async () => {
      try {
        setOpen(true);
        const id = personalCollectionId.replace(/"/g, "");
        const { data: { data = {} } = {} } = await apiReq(
          `${API_ROUTES.getAdditionalDoc}`,
          "POST",
          { clientId, personalCollectionId: id }
        );

        if (data) {
          // Update fileName state with initial values from fetched data
          setFileName({
            w2docs: data.w2Docs?.map((doc) => doc.fileName) || [],
            docs1099: data.docs1099?.map((doc) => doc.fileName) || [],
            otherDocs: data.otherDocs?.map((doc) => doc.fileName) || [],
          });

          // Update values in formik with initial values
          setValues({
            w2docs: data.w2Docs?.map((doc) => doc.doc) || [],
            docs1099: data.docs1099?.map((doc) => doc.doc) || [],
            otherDocs: data.otherDocs?.map((doc) => doc.doc) || [],
            uploadDocsId: data._id,
          });
          setEdit(true); // Set edit mode to true
        }

        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs }));
      } catch (error) {
        errorToast(
          error?.message || "An unexpected error occurred. Please refresh page."
        );
      } finally {
        setOpen(false);
      }
    };

    fetchUploadDocs();
  }, []);
  console.log(edit, "eddot");

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const id = personalCollectionId.replace(/"/g, "");

        const { data: { data = {} } = {} } = await apiReq(
          `${API_ROUTES.getPerosnalCollection + id}`,
          "POST",
          { clientId }
        );
        if (data?.additionalNotes) {
          setNotes(data?.additionalNotes || "");
          localStorage.setItem("personalCollectionId", data?._id);
        }
      } catch (error) {
        errorToast(
          error?.message || "An unexpected error occurred. Please refresh page."
        );
      }
    };
    fetchNotes();
  }, []);
  console.log(values, "valuessssssssss");
  const handleNotesSubmit = async (additionalNotes = "") => {
    try {
      const id = personalCollectionId.replace(/"/g, "");
      await apiReq(API_ROUTES.addAditionalNotes, "POST", {
        clientId,
        additionalNotes,
        personalCollectionId: id,
      });
      setIsAdditionalModal(false);
      infoToast("Addiitonal notes saved successfully");
      setTimeout(() => {
        setIsValidateTaxModal(true);
      }, 2000);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    }
  };
  const handleFormSubmit = async () => {
    try {
      // Prepare data to be uploaded
      setLoading(true);

      // Create a new FormData object
      const formData = new FormData();
      const id = personalCollectionId.replace(/"/g, "");

      formData.append("personalCollectionId", id);

      // Send the FormData with the request
      await apiReq(API_ROUTES.updatePersonalCollection, "POST", formData);

      infoToast("Info saved successfully");
      localStorage.setItem("editForm", false);
      setTimeout(() => {
        navigate("/review");
      }, 1500);

      localStorage.removeItem("personalCollectionId");
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  const handleRemoveFile = (docType, index) => {
    const removedFile = values[docType][index];

    // Remove the file from fileName state
    const updatedFileNames = fileName[docType].filter((_, i) => i !== index);
    setFileName((prevState) => ({
      ...prevState,
      [docType]: updatedFileNames,
    }));

    // Remove the file from values state (Formik)
    const updatedFiles = values[docType].filter((_, i) => i !== index);
    setFieldValue(docType, updatedFiles);

    // Add the removed file to the respective deleted document array
    if (docType === "w2docs") {
      setDeletedW2Docs((prev) => [...prev, removedFile]);
    } else if (docType === "docs1099") {
      setDeleted1099Docs((prev) => [...prev, removedFile]);
    } else if (docType === "otherDocs") {
      setDeletedOtherDocs((prev) => [...prev, removedFile]);
    }
  };
  const handleFileChange = (event, docType) => {
    const files = Array.from(event.currentTarget.files); // Convert FileList to Array

    // Check if values[docType] is an array
    if (!Array.isArray(values[docType])) {
      console.error(`${docType} is not an array`, values[docType]);
      setFieldValue(docType, files); // Initialize with the new files array if undefined or not an array
    } else {
      setFieldValue(docType, [...values[docType], ...files]); // Append new files to existing array
    }

    setFileName((prevState) => ({
      ...prevState,
      [docType]: [
        ...(prevState[docType] || []),
        ...files.map((file) => file.name),
      ], // Ensure fileName[docType] is an array
    }));
  };
  // console.log(fileName, "File changed")
  const renderFileList = (docType) => {
    if (!Array.isArray(fileName[docType])) {
      return null;
    }

    return fileName[docType].map((name, index) => (
      <Box
        key={index}
        sx={{
          fontSize: "12px",
          margin: "4px 0",
          background: "#62a0b8",
          color: "#fff",
          display: "inline-flex",
          alignItems: "center",
          padding: "0 15px",
          height: "30px",
          borderRadius: "8px",
        }}
      >
        {name}
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => handleRemoveFile(docType, index)}
          style={{ marginLeft: "8px" }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </Box>
    ));
  };
  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <RetirementContainer>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <HeadingFive fontSize="18px" fontWeight="500" color="1b1b1b">
              Upload Docs
            </HeadingFive>
            <Tooltip
              placement="top"
              title="Please upload W-2, 1099, Mortgage and other tax documents."
            >
              <IconButton
                sx={{ fontSize: "18px", color: "#000", marginTop: "4px" }}
              >
                <GoInfo />
              </IconButton>
            </Tooltip>
            {/* <p style={{ color: "#0b2558", fontSize: "14px", marginTop: "4px" }}>
              The file size should not exceed 4.5MB
            </p> */}
          </Box>
          <RetirementWrapper>
            <Box
              border="1px dashed #0000004D"
              width="100%"
              padding="20px 20px"
              borderRadius="12px"
              marginBottom="20px"
            >
              <FlexComp direction="row" gridGap="50px">
                <Span
                  color="#1B1B1B"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="start"
                >
                  <p style={{ width: "260px" }}>W-2</p>
                </Span>

                <Stack direction="row" gap={5}>
                  <>
                    <ButtonIcon
                      width="100%"
                      icon={<CloudUploadIcon />}
                      text={"Upload Required Documents"}
                      fontSize="12px"
                      clickHandler={(e) => handleUploadButtonClick(e, "w2docs")}
                    />
                  </>
                  <Input
                    type="file"
                    id="w2docs"
                    ref={w2docsRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "w2docs")}
                    multiple
                  />

                  {/* <FormHelperText error>{touched.withdrawalDoc && errors.withdrawalDoc}</FormHelperText> */}
                </Stack>
              </FlexComp>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                {renderFileList("w2docs")}
              </Box>
            </Box>
            <Box
              border="1px dashed #0000004D"
              width="100%"
              padding="20px 20px"
              borderRadius="12px"
              marginBottom="20px"
            >
              <FlexComp direction="row" gridGap="50px" marginTop="10px">
                <Span
                  color="#1B1B1B"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="start"
                >
                  <p style={{ width: "260px" }}>1099</p>
                </Span>

                <Stack direction="row" gap={5}>
                  <ButtonIcon
                    width="100%"
                    icon={<CloudUploadIcon />}
                    text={"Upload Required Documents"}
                    fontSize="12px"
                    clickHandler={(e) => handleUploadButtonClick(e, "docs1099")}
                  />

                  <Input
                    type="file"
                    id="docs1099"
                    ref={docs1099Ref}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "docs1099")}
                    multiple
                  />
                  {/* <FormHelperText error>{touched.socialSecurityBenefitDoc && errors.socialSecurityBenefitDoc}</FormHelperText> */}
                </Stack>
              </FlexComp>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                {renderFileList("docs1099")}
              </Box>
            </Box>
            <Box
              border="1px dashed #0000004D"
              width="100%"
              padding="20px 20px"
              borderRadius="12px"
            >
              <FlexComp direction="row" gridGap="50px" marginTop="10px">
                <Span
                  color="#1B1B1B"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="start"
                >
                  <p style={{ width: "260px" }}>Others</p>
                </Span>

                <Stack direction="row" gap={5}>
                  <ButtonIcon
                    width="100%"
                    icon={<CloudUploadIcon />}
                    text={"Upload Required Documents"}
                    fontSize="12px"
                    clickHandler={(e) =>
                      handleUploadButtonClick(e, "otherDocs")
                    }
                  />

                  <Input
                    type="file"
                    id="otherDocs"
                    ref={otherDocsRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "otherDocs")}
                    multiple
                  />
                  {/* <FormHelperText error>{touched.salesDoc && errors.salesDoc}</FormHelperText> */}
                </Stack>
              </FlexComp>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                {renderFileList("otherDocs")}
              </Box>
            </Box>
            <FlexComp direction="row" justifyContent="flex-end"></FlexComp>
          </RetirementWrapper>
        </RetirementContainer>
        <FlexComp direction="row" justifyContent="flex-end">
          <Button
            text={
              isSubmitting ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Submit"
              )
            }
            type="submit"
            width="15%"
            fontSize="18px"
            backgroundColor="#0B2558"
          />
        </FlexComp>
      </form>
      <AdditionalNotes
        isModalOpen={isAdditionalModal}
        additionalNotes={notes}
        handleNotesSubmit={handleNotesSubmit}
        closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
        openSecondModal={(notes) => handleNotesSubmit(notes)}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        handleYesClick={handleFormSubmit}
        paragraph="Are you sure you want to validate this Personal Collection Form?"
      />
    </>
  );
};

export default UploadDocs;
