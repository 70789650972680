import FlexComp from "../../../reusable/flex/Flex";
import {
  HeadingFive,
  Span,
} from "../../../reusable/typography/Typography.style";
import {
  GrayBox,
  GrayHeading,
  GrayHeadingTitle,
} from "./ReviewAndConfirmation.style";

const ReviewConfirmationBoxTwo = ({ data, values = {} }) => {
  // console.log(values, "valuesItemized");
  return (
    <GrayBox>
      <GrayHeading>
        <Span fontSize="18px" fontWeight="600" color="#FFFFFF">
          {data.title}
        </Span>
      </GrayHeading>

      {data.categories.map((category, catIndex) => {
        {
          /* console.log(category, "categories"); */
        }
        return (
          <div key={catIndex}>
            <ul
              style={{
                width: "100%",
              }}
            >
              <li
                style={{
                  width: "100%",
                }}
              >
                <Span
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "white",
                    background: "#0b2558",
                    borderRadius: "5px",
                    padding: "8px",
                    width: "100%",
                  }}
                >
                  {category.subtitle
                    ? category.subtitle
                        .replace(/([a-z])([A-Z])/g, "$1 $2")
                        .replace(/^./, (char) => char.toUpperCase())
                    : ""}
                </Span>
              </li>
            </ul>
            {/* Handling Day Care Section */}
            {category?.subtitle === "dayCare" ? (
              <FlexComp
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                gridGap="10px"
                width="100%"
              >
                {Array.isArray(values[category.subtitle]) &&
                values[category.subtitle].length > 0 ? (
                  values[category.subtitle].map((dayCareItem, idx) => (
                    <FlexComp
                      key={idx}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      gridGap="10px"
                      width="100%"
                    >
                      {category.colOne.map((itm, index) => (
                        <GrayHeadingTitle key={index}>
                          <HeadingFive
                            fontSize="16px"
                            fontWeight="500"
                            color="#1B1B1B"
                          >
                            {itm.title}
                          </HeadingFive>
                          <Span
                            fontSize="16px"
                            fontWeight="400"
                            color="#1B1B1B"
                          >
                            {dayCareItem?.[itm.key] || ""}
                          </Span>
                        </GrayHeadingTitle>
                      ))}
                    </FlexComp>
                  ))
                ) : (
                  <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                    No Data Available
                  </Span>
                )}

                {/* Mapping Day Care Documents */}
                {Array.isArray(values.dayCareDocs) &&
                  values.dayCareDocs.length > 0 && (
                    <FlexComp
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      gridGap="10px"
                      width="100%"
                    >
                      <HeadingFive
                        fontSize="16px"
                        fontWeight="600"
                        color="#1B1B1B"
                      >
                        Day Care Documents
                      </HeadingFive>
                      {values.dayCareDocs.map((docItem, docIdx) => (
                        <GrayHeadingTitle key={docIdx}>
                          <a
                            href={docItem.doc}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#09bab1",
                              textDecoration: "underline",
                            }}
                          >
                            {docItem.fileName}
                          </a>
                        </GrayHeadingTitle>
                      ))}
                    </FlexComp>
                  )}
              </FlexComp>
            ) : (
              <FlexComp
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                gridGap="10px"
                width="100%"
              >
                <FlexComp
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  gridGap="10px"
                  width="33%"
                >
                  {category?.colOne?.map((itm, idx) => (
                    <GrayHeadingTitle key={idx}>
                      <HeadingFive
                        fontSize="16px"
                        fontWeight="500"
                        color="#1B1B1B"
                      >
                        {itm.title}
                      </HeadingFive>
                      <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                        {data.title === "Itemized Deduction"
                          ? values[category?.subtitle]?.[itm?.key] || ""
                          : values?.[itm.key]}
                      </Span>
                    </GrayHeadingTitle>
                  ))}
                </FlexComp>

                <FlexComp
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  gridGap="10px"
                  width="33%"
                >
                  {category.colTwo.map((itm, idx) => (
                    <GrayHeadingTitle key={idx}>
                      <HeadingFive
                        fontSize="16px"
                        fontWeight="500"
                        color="#1B1B1B"
                      >
                        {itm.title}
                      </HeadingFive>
                      <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                        {data.title === "Itemized Deduction"
                          ? values[category?.subtitle]?.[itm?.key] || ""
                          : values?.[itm.key]}
                      </Span>
                    </GrayHeadingTitle>
                  ))}
                </FlexComp>

                <FlexComp
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  gridGap="10px"
                  width="33%"
                >
                  {category.colThree.map((itm, idx) => (
                    <GrayHeadingTitle key={idx}>
                      <HeadingFive
                        fontSize="16px"
                        fontWeight="500"
                        color="#1B1B1B"
                      >
                        {itm.title}
                      </HeadingFive>
                      <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                        {data.title === "Itemized Deduction"
                          ? values[category?.subtitle]?.[itm?.key] || ""
                          : values?.[itm.key]}
                      </Span>
                    </GrayHeadingTitle>
                  ))}
                </FlexComp>
              </FlexComp>
            )}
          </div>
        );
      })}
    </GrayBox>
  );
};

export default ReviewConfirmationBoxTwo;
