import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
} from "@mui/material";

const SelectWithCheckbox = ({
  label,
  id,
  width,
  menuItems,
  selectedValues,
  handleChange,
}) => {
  const { items, placeholder } = menuItems;

  const selectedArray = Array.isArray(selectedValues)
    ? selectedValues
    : [selectedValues];

  return (
    <FormControl sx={{ ml: 1, width: width || 230, outline: "none" }}>
      <Select
        border="none"
        displayEmpty
        value={selectedArray}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{placeholder}</em>;
          }
          return selected.join(", ");
        }}
        inputProps={{ "aria-label": label || "Without label" }}
        sx={{
          "&.MuiSelect-select": {
            backgroundColor: "lightblue", 
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              outline: "none", 
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "8.8px 14px",
            backgroundColor: "rgba(239, 243, 253, 1)",
            border: "none",
            outline: "none",
            borderRadius: "10px", 
            color: "rgba(174, 182, 207, 1)",
          },
          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <MenuItem sx={{ color: "rgba(174, 182, 207, 1)" }} disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={selectedArray.indexOf(item.value) > -1} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectWithCheckbox;
