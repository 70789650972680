import styled from "styled-components";
import MenuTabs from "components/reusable/tabs/MenuTabs";
import BasicInformation from "components/client/personalCollection/BasicInformation";
import Retirement from "components/client/personalCollection/Retirement";
import HealthCare from "components/client/personalCollection/HealthCare";
import Adjustments from "components/client/personalCollection/Adjustments";
import ItemizedDeduction from "components/client/personalCollection/ItemizedDeduction";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { useEffect } from "react";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import UploadDocs from "components/client/personalCollection/UploadDocs";

const tabsData = [
  {label: "Pre Sheet", key: "prepsheet"},
  {label: "Retirements", key: "retirement"},
  {label: "Health Care", key: "healthCare"},
  {label: "Adjustments", key: "adjustment"},
  {label: "Itemized Deductions", key: "itemize_deduction"},
  {label: "Upload Docs", key: "upload_docs"},
]

const tabComponents = [
  BasicInformation,
  Retirement,
  HealthCare,
  Adjustments,
  ItemizedDeduction,
  UploadDocs
];
const PersonalCollection = () => {
  const clientId = localStorage.getItem("clientId");

  useEffect(() => {
    const fetchCollection = async () => {
      const {data: {data = {}} = {}} = await apiReq(`${API_ROUTES.getPerosnalCollection}`, "POST", {clientId});
      if(data?._id) localStorage.setItem("personalCollectionId", data._id)
    };
    // fetchCollection();

    return () => {
      localStorage.removeItem("tabs");
      localStorage.removeItem("personalCollectionInfo");
      localStorage.removeItem("current_tab");
    }
  }, []);
  
  return (
    <PersonalCollectionContainer>
      <HeadingFive fontSize="24px" fontWeight="500" color="#0B2558">
        Personal Collection
      </HeadingFive>
      <PCWrapper>
        <MenuTabs tabData={tabsData} tabComponents={tabComponents} />
      </PCWrapper>
    </PersonalCollectionContainer>
  );
};

export default PersonalCollection;

const PersonalCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;

const PCWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
