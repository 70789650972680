import { useContext, useEffect, useState } from "react";
import { Input } from "components/reusable/input/Inputs";
import { InsuranceFieldsTop, InterestFieldsTop } from "data/client";
import { Button } from "components/reusable/button/Button";
import FlexComp from "components/reusable/flex/Flex";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { insuranceSchema } from "./Schema";
import { useFormik } from "formik";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { FormHelperText, Stack } from "@mui/material";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const initialValues = {
  insurance: {
    bobtailInsurance: "",
    piAndPdInsurance: "",
    truckInsurance: "",
    insuranceWorkmanCompensation: "",
    accInsurance: "",
    otherInsurance: ""
  },
  interest:{
    interestOnAdvances: "",
    interestCreditCardsBus: "",
    interestOnTruckLoans: "",
    interestWorkmanCompensation: "",
    otherInterest: ""
  }
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const Insurance = ({ setCurrentTab }) => {
  const clientId = localStorage.getItem("clientId");
  const {setOpen} = useContext(PageLoaderContext);
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const [tabsData, setTabsData] = useState({})

  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");

  const {values, errors, touched, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: insuranceSchema,
    onSubmit: async(values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast('Client Id Or Truck driver Id Not Found.');
          return;
        } else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
        const {_id: truckDriverId, truckDriverInsuranceId} = selectedDriverInfo;
        await apiReq(`${(truckDriverInsuranceId?._id || tabsData?._id) ? API_ROUTES.updateInsurance: API_ROUTES.addInsurance}`, "POST", {clientId, truckDriverMainId: truckDriverMainId || truckDriverId || "", truckDriverInsuranceInterestId: truckDriverInsuranceId?._id || tabsData?._id, ...values});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, expenses: false}));
        infoToast(truckDriverInsuranceId?._id? "Insurance info updated succesfully":"Insurance info saved succesfully");
        setTimeout(() => {
          setCurrentTab(5);
        }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const fetchInsurance = async() => {
      try {
        setOpen(true);
        const {truckDriverInsuranceId} = selectedDriverInfo;
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getInsurance, "POST", {truckDriverInsuranceInterestId: truckDriverInsuranceId._id});
        setValues(data);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setOpen(false);
      }
    }
    selectedDriverInfo?.truckDriverInsuranceId && fetchInsurance();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverInsuranceId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverInsuranceId?._id && localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, expenses: false}));
        truckDriverInsuranceId?._id && setValues(truckDriverInsuranceId);
        setTabsData(truckDriverInsuranceId)
      }
      fetchTruckDriverDetails();
    }
  },[]);
  
  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize='18px' fontWeight='600'>
            Insurance
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              flexWrap='wrap'
              gridGap='25px'
            >
              {InsuranceFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <Input
                      id={id}
                      label={label}
                      size={size}
                      placeholder={placeholder}
                      value={values.insurance[key]}
                      onChange={e => {
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(`insurance[${key}]`, val)
                      }}
                    />
                    <FormHelperText error>{touched?.insurance?.[key] && errors?.insurance?.[key]}</FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          <HeadingFive fontSize='18px' fontWeight='600'>
            Interest
          </HeadingFive>

          <BasicInfoInputWrapper>
            <FlexComp
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              flexWrap='wrap'
              gridGap='25px'
            >
              {InterestFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <Input
                      label={label}
                      id={id}
                      size={size}
                      placeholder={placeholder}
                      value={values.interest[key]}
                      onChange={e => {
                      
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(`interest[${key}]`, val)
                      }}
                    />
                    <FormHelperText error>{touched?.interest?.[key] && errors?.interest?.[key]}</FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          <Button
            text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"}
            type= "submit"
            width='15%'
            fontSize='18px'
            backgroundColor='#0B2558'
            alignSelf='flex-end'
          />
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default Insurance;
