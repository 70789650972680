import React, { useContext, useState } from "react";
import styled from "styled-components";

import Navbar2 from "./navbar/AdminNavbar";
// import Chat from "../../chatbot/Chat";
import Sidebar2 from ".//sidebar/AdminSidebar";
import { ProfileContext } from "../../../context/ProfileContext";

const AdminLayout = ({ children }) => {
  const { clickedMenuItem, setClickedMenuItem } = useContext(ProfileContext);
  const handleMenuItemClick = (menuItem) => {
    console.log("Clicked menu item:", menuItem); // Log the clicked menu item
    sessionStorage.setItem("menuItem", menuItem);
    setClickedMenuItem(menuItem); // Update the clicked menu item state
  };

  const [showChat, setShowChat] = useState(false);

  const toggleChat = () => {
    setShowChat((prev) => !prev);
  };

  return (
    <LayoutContainer>
      <LeftWrapper>
        <Sidebar2 handleMenuItemClick={handleMenuItemClick} />{" "}
        {/* Pass the function as prop */}
      </LeftWrapper>
      <RightWrapper>
        <Navbar2 header={clickedMenuItem} />{" "}
        {/* Pass the clicked menu item as prop */}
        {children}
        {/* {showChat ? (
          <Chat toggleChat={toggleChat} />
        ) : (
          <Chatbot toggleChat={toggleChat} />
        )} */}
      </RightWrapper>
    </LayoutContainer>
  );
};

export default AdminLayout;

const LayoutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  height: 100vh;
`;

const LeftWrapper = styled.div`
  width: 14%;
  display: flex;
  height: 100%;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  grid-gap: 20px;
  background: #eff3fd;
  height: 100vh;
  overflow-y: scroll;
  z-index: 1;
`;
