export const generalBusinessTab = [
  "Business Info",
  "Expenses",
  "Fixed Assets (Optional)",
  "Vehicle Info (Optional)",
  "Business Use Of Home (Optional)",
];

export const entityInfo = [
  "Sole Proprietor",
  "Single Member LLC",
  "Multi Member LLC",
  "Partnership",
  "S Corp",
  "C Corp",
];

export const businessInfoFields = [
  
  {
    label: "First Name *",
    placeHolder: "Enter owner First Name ",
    defaultValue: "",
    id: "outlined-multiline-static",
    size: "medium",
    width: "49%",
    value: "",
    name:"firstName"
  },
  {
    label: "Middle Name",
    placeHolder: "Enter owner Middle Name ",
    defaultValue: "",
    id: "outlined-multiline-static",
    size: "medium",
    width: "49%",
    value: "",
    name:"middleName"
  },
  {
    label: "Last Name *",
    placeHolder: "Enter owner Last Name ",
    defaultValue: "",
    id: "outlined-multiline-static",
    size: "medium",
    width: "49%",
    value: "",
    name:"lastName"
  },
  {
    label: "Mobile Number *",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "medium",
    width: "49%",
    value: "",
    name:"mobileNumber",
    type: "number"
  },
  {
    label: "Business Name *",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "49%",
    value: "",
    name:"businessName",
  },
  
  {
    label: "Business Description Products/Services *",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "medium",
    width: "49%",
    value: "",
    name:"businessDescription"
  },
];

export const payrollCheckbox = ["Yes (940/W3 required)", "No"];

export const contractorsCheckbox = ["Yes (1099A required)", "No"];

export const expenseFields = [
  {
    label: "Accounting",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "accounting"
  },
  {
    label: "Office Expenses",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "officeExpenses"
  },
  {
    label: "Vehicle Exp (Business)",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "vehicleExp"
  },
  {
    label: "Advertising",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "advertising"
  },
  {
    label: "Outside Contractors (1099’s)",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "outsideContractors"
  },
  {
    label: "Delivery/Freight/Shipper",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "shipper"
  },
  {
    label: "Bank Charges/Fees",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "bankCharges"
  },
  {
    label: "Postage",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "postage"
  },
  {
    label: "Dues/Subscriptions",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "subscriptions"
  },
  {
    label: "Mobile Phone",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "cellPhone"
  },
  {
    label: "Printing",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "printing"
  },
  {
    label: "Equipment Rental",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-adornment-amount",
    width: "32%",
    value: "",
    name: "equipmentRental"
  },
  {
    label: "Insurance (Bus &  Liability)",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "insurance"
  },
  {
    label: "Rent",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "rent"
  },
  {
    label: "Interest Exp",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "interestExp"
  },
  {
    label: "Internet Cost (business only)",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "internetCost"
  },
  {
    label: "Repairs & Maintenance",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "repairsAndMaintenance"
  },
  {
    label: "Commission Expenses",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "commissionExpenses"
  },
  {
    label: "Legal & Professional Fees",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "legalAndProfessionalFees"
  },
  {
    label: "Supplies",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "supplies"
  },
  {
    label: "Travel",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "travel"
  },
  {
    label: "License & Permit",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "licenseAndPermit"
  },
  {
    label: "Telephone Service (Office)",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "telephone"
  },
  {
    label: "Other",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "other"
  },
  {
    label: "Miscellaneous",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "miscellaneous"
  },
  {
    label: "Training",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "training"
  },
  {
    label: "Meals & Entertainment",
    placeHolder: "",
    defaultValue: "",
    id: "outlined-size-small",
    width: "32%",
    value: "",
    name: "mealsAndEntertainment"
  },
];

export const vehicleInfoTopFields = [
  
  {
    label: "Purchase Price",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "purchasePrice",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Loan Interest",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "loanInterest",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Advertising",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "advertising",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Lease Payments",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "leasePayments",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "License & Registration",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "licenseAndRegistration",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const vehicleInfoMidFields = [
  {
    label: "Insurance",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "insurance",
    size: "size",
    width: "48.5%",
    value: "",
  },
  {
    label: "Repairs & Tires",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "repairsAndTires",
    size: "size",
    width: "48.5%",
    value: "",
  },
  {
    label: "Gasoline, Lube, Oil",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "gasolineLubeOil",
    size: "size",
    width: "48.5%",
    value: "",
  },
  {
    label: "Miscellaneous",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "miscellaneous",
    size: "size",
    width: "48.5%",
    value: "",
  },
  {
    label: "Parking Fees, Tolls",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "parkingFees",
    size: "size",
    width: "48.5%",
    value: "",
  },
  {
    label: "Interest Exp",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "interestExp",
    size: "size",
    width: "48.5%",
    value: "",
  },
];

export const vehicleInfoBottomFields = [
  {
    label: "Personal Mileage",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "personalMileage",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Business Mileage",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "businessMileage",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Commuting Mileage",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "commutingMileage",
    size: "size",
    width: "32%",
    value: "",
  },
];


export const businessUseHomeTopFields = [
  {
    label: "Total Area of Home (Sq. Ft)",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "totalAreaOfHome",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Business Use Area (Sq. Ft)",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "businessUseArea",
    size: "size",
    width: "49%",
    value: "",
  },
];

export const businessUseHomeBottomFields = [
  {
    label: "Repairs & Maintenance",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "repairsAndMaintenance",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Mortgage Interest",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "mortgageInterest",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Utilities",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "utilities",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Real Estate Taxes",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "realEstateTaxes",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Telephone",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "telephone",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Rent",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "rent",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Security",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "security",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Insurance",
    placeHolder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    name: "insurance",
    size: "size",
    width: "49%",
    value: "",
  },
];
