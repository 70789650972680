import React, { useContext, useEffect, useState } from "react";
import FlexComp from "components/reusable/flex/Flex";
import { Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import { settingFields } from "data/clientData/setting";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import DropdownComp from "components/reusable/dropdownComp/DropdownComp";
import ProfilePicUpload from "components/reusable/profilePicUpload/ProfilePicUpload";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Autocomplete, FormHelperText, Stack, TextField } from "@mui/material";
import { SyncLoader } from "react-spinners";
import { stateOptions } from "data/clientData/businessFormation";
import { toast } from "react-toastify";
import { ProfileContext } from "context/ProfileContext";

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const ProfileComp = ({ setName }) => {
  const [profile, setprofile] = useState(null);
  const [loading, setLoading] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const { setProfileData,profileData } = useContext(ProfileContext);
  const client = profileData?.user;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: client?.firstName || "",
      middleName: client?.middleName || "",
      lastName: client?.lastName || "",
      email: client?.email || "",
      phone: client?.phone || "",
      city: client?.city || "",
      street: client?.street || "",
      zipCode: client?.zipCode || "",
      state: client?.state || "",
      profileImage: profile || client?.profileImage,
      userId: client?._id,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      middleName: Yup.string().default(""),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(/@[^.]*\./, "Email not valid")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
      zipCode: Yup.string().required("Zip code is required"),
      state: Yup.string().required("State is required"),
      profileImage: Yup.mixed(), //.required('profile image required'),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);

        // Create FormData
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }

        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.updateProfile,
          "POST",
          formData,
          API_BASE_URL,
          true
        );

        localStorage.setItem("clientData", JSON.stringify({user: data}));
        setProfileData({user:data})
        infoToast("Profile updated succesfully");
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setName(`${client?.firstName} ${client?.lastName}`);
  }, [client?.firstName, client?.lastName]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <ProfilePicUpload
          setprofile={setprofile}
          profile={client?.profileImage || ""}
        />
        <FormHelperText error>{formik.errors.profileImage}</FormHelperText>
        <FlexComp
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gridGap="30px"
        >
          {settingFields.map(
            ({ label, id, size, placeHolder, name }, index) => (
              <Stack key={index} direction="column" sx={{ width: "30%" }}>
                <Input
                  label={label}
                  name={name}
                  size={size}
                  placeHolder={placeHolder}
                  value={formik.values[name]}
                  onChange={formik.handleChange}
                />
                <FormHelperText error>{formik.touched?.[name] && formik.errors[name]}</FormHelperText>
              </Stack>
            )
          )}
        </FlexComp>

        <p style={{ padding: "12px 0px" }}></p>
        <HeadingFive
          fontSize="16px"
          fontWeight="500"
          color="black"
          style={{ padding: "5px 0px" }}
        >
          Address
        </HeadingFive>
        <FlexComp direction="row" gridGap="25px" width="100%" flexWrap="wrap">
          <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="Street"
              name="street"
              id="outlined-size-small"
              defaultValue=""
              size="size"
              placeholder="Enter"
              value={formik.values.street}
              onChange={formik.handleChange}
            />
            <FormHelperText error>{formik.touched?.street && formik.errors.street}</FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="City *"
              name="city"
              id="outlined-size-small"
              defaultValue=""
              size="size"
              placeholder="Enter"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
            <FormHelperText error>{formik.touched?.city && formik.errors.city}</FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={stateOptions}
              value={formik.values?.state}
              onChange={(_, newVal) => {
                formik.setFieldValue(`state`, newVal?.value || "");
              }}
              renderInput={(params) => (
                <TextField {...params} label="State *" />
              )}
            />
            <FormHelperText error>{formik.touched?.state && formik.errors.state}</FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <Input
              label="Zip Code *"
              name="zipCode"
              value={formik.values.zipCode}
              onChange={(e) => {
                let zipCodeValue = e.target.value
                  .replace(/\D/g, "")
                  .slice(0, 9);
                if (zipCodeValue.length > 5) {
                  zipCodeValue =
                    zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                }
                formik.setFieldValue(`zipCode`, zipCodeValue);
              }}
              id="outlined-size-small"
              defaultValue=""
              size="size"
              placeholder="Enter"
            />
            <FormHelperText error>{formik.touched?.zipCode && formik.errors.zipCode}</FormHelperText>
          </Stack>
        </FlexComp>
        <p style={{ padding: "10px 0px" }}></p>
        <FlexComp direction="row" gridGap="20px" alignItems="center">
          <Button
            text={
              loading ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Update Profile"
              )
            }
            width="15%"
            fontSize="14px"
            backgroundColor="#0B2558"
            alignSelf="end"
            padding="15px 20px"
            type="submit"
          />
          <Button
            text="Cancel"
            type="button"
            width="10%"
            fontSize="14px"
            backgroundColor="#EFF3FD"
            alignSelf="end"
            padding="15px 20px"
            color="#AEB6CF"
          />
        </FlexComp>
      </form>
    </>
  );
};

export default ProfileComp;