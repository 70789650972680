import { Modal } from "@mui/material";
import { useState, useEffect } from "react";
import {
  TermsConditionBody,
  TermsConditionContainer,
  TermsConditionHeader,
} from "./TermsCondition.style";
import { Span } from "components/reusable/typography/Typography.style";
import CloseIcon from "@mui/icons-material/Close";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { Line } from "../../client/rentalProperty/RentalProperty.style";
import {
  dummy,
  termConditionTab,
} from "data/clientData/termsConditionModal";
import MenuTabs from "components/reusable/tabs/MenuTabs";
import { Button } from "components/reusable/button/Button";
import FlexComp from "components/reusable/flex/Flex";
import SelectStyle from "./SelectStyle";
import Draw from "./Draw";
import Upload from "./Upload";
import { Input } from "components/reusable/input/Inputs";
import { SyncLoader } from "react-spinners";

const TermsCondition = ({ isModalOpen, closeModal, onSaveSignature, isLoading = false }) => {
  const initialValues = {
    signatureData: {
      fullName: "",
      initials: "",
      signatureStyle: "",
      eFileTaxReturnCheck: false,
    },
  };

  const [initial, setInitial] = useState("");
  const [initials, setInitials] = useState("");
  const [fullName, setFullName] = useState("");
  const [drawCheck, setDrawCheck] = useState(false);
  const [uploadCheck, setUploadCheck] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [signatureStyle, setSignatureStyle] = useState("");
  const [drawnSignature, setDrawnSignature] = useState(null);
  const [eFileTaxReturnCheck, setEFileTaxReturnCheck] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSaveSelectStyle = (selectedFont) => {
    setSignatureStyle(selectedFont);
  };

  const handleSaveDrawnSignature = (sigCanvas) => {
    const drawnSignatureDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setDrawnSignature(drawnSignatureDataURL);
    setDrawCheck(true);
    setUploadCheck(false);
  };

  const handleClearDrawnSignature = () => {
    setDrawnSignature("");
    setDrawCheck(false);
  }

  const handleUploadFile = (uploadedFileData) => {
    setUploadedFile(uploadedFileData);
    setUploadCheck(true);
    setDrawCheck(false);
  };

  const handleUploadedFileRemove = () => {
    setUploadedFile(null);
    setUploadCheck(false);
  };

  const handleSave = () => {
    if (!termsChecked) {
      setShowError(true);
      return;
    }
    const signatureData = {
      fullName,
      initials,
      signatureStyle: drawCheck ? "" : signatureStyle,
      eFileTaxReturnCheck,
      drawCheck,
      uploadCheck,
      signatureFile: drawCheck ? drawnSignature : uploadedFile,
    };
    onSaveSignature(signatureData);
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("userName");
    const storedInitials = localStorage.getItem("initials");
    if (storedUsername && storedInitials) {
      setFullName(storedUsername);
      setInitials(storedInitials);
    }
  }, []);

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <TermsConditionContainer>
        <TermsConditionHeader>
          <Span color="#ffffff" fontSize="24px" fontWeight="600">
            Terms and Conditions
          </Span>
          <CloseIcon
            sx={{ color: "#ffffff", cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </TermsConditionHeader>
        <TermsConditionBody>
          <Span color="#1B1B1B" fontSize="18px" fontWeight="400">
            {dummy}
          </Span>
          <TextCheckbox text="I agree with the terms and conditions." isChecked={termsChecked} onChange={(e) => { setTermsChecked(e.target.checked); setShowError(false); }} />
          {showError && <Span color="red" fontSize="14px">Please agree to the terms and conditions</Span>}
          <Line />
          <Span color="#1B1B1B" fontSize="18px" fontWeight="500">
            Adopt Your Signature
          </Span>
          <Span color="#000000" fontSize="16px" fontWeight="400">
            Confirm your name, initials, and signature.
          </Span>
          <FlexComp
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gridGap="10px"
          >
            <Input
              label="Full Name*"
              id="outlined-size-small"
              defaultValue=""
              size="size"
              width="48%"
              placeHolder=""
              value={fullName}
              valueSetter={setFullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              label="Initials*"
              id="outlined-size-small"
              defaultValue=""
              size="size"
              width="48%"
              placeHolder=""
              value={initials}
              valueSetter={setInitials}
              onChange={(e) => setInitials(e.target.value)}
            />
          </FlexComp>
          <Line />
          <MenuTabs
            initial={initials}
            fullName={fullName}
            tabData={termConditionTab}
            uploadedFile={uploadedFile}
            drawnSignature={drawnSignature}
            onUploadFile={handleUploadFile}
            onSaveSelectStyle={handleSaveSelectStyle}
            tabComponents={[SelectStyle, Draw, Upload]}
            onSaveDrawnSignature={handleSaveDrawnSignature}
            handleUploadedFileRemove={handleUploadedFileRemove}
            handleClearDrawnSignature={handleClearDrawnSignature}
          />
          <Line />
          <TextCheckbox text="Check to e-file your tax return (8879 Form)." isChecked={eFileTaxReturnCheck} onChange={(e) => setEFileTaxReturnCheck(e.target.checked)} />
          <FlexComp
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            gridGap="10px"
          >
            <Button
              text="Cancel"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#EFF3FD"
              color="#AEB6CF"
              onClick={closeModal}
            />
            <Button
              text={isLoading ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Continue"}
              type="button"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              color="#FFFFFF"
              onClick={handleSave}
            />
          </FlexComp>
        </TermsConditionBody>
      </TermsConditionContainer>
    </Modal>
  );
};

export default TermsCondition;
