import React from "react";
import {
  GrayBox,
  GrayHeading,
  GrayHeadingTitle,
} from "./ReviewAndConfirmation.style";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import FlexComp from "components/reusable/flex/Flex";
import { convertToTitleCase } from "utils/Helpers";

const OtherExpenses = ({ data }) => {
  return (
    <GrayBox>
      <GrayHeading>
        <Span fontSize="18px" fontWeight="600" color="#FFFFFF">
          Other Expenses
        </Span>
      </GrayHeading>
      <FlexComp
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        gridGap="10px"
        width="100%"
      >
        <FlexComp
          direction="row"
          alignItems="flex-start"
        //   gridGap="10px"
          width="100%"
          flexWrap="wrap"
        >
          {!!data.length &&
            data.map((itm, idx) => (
              <FlexComp
                direction="column"
                width="33%"
              >
                <GrayHeadingTitle key={idx}>
                  <HeadingFive fontSize="16px" fontWeight="500" color="#1B1B1B">
                    {itm?.name && convertToTitleCase(itm.name)}
                  </HeadingFive>
                  <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                    {itm?.amount}
                  </Span>
                </GrayHeadingTitle>
              </FlexComp>
            ))}
        </FlexComp>
      </FlexComp>
    </GrayBox>
  );
};

export default OtherExpenses;
