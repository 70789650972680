import React, { useState } from "react";
import FlexComp from "../../reusable/flex/Flex";
import { Span } from "../../reusable/typography/Typography.style";
import { SignPreview, SignPreviewBox } from "./TermsCondition.style";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const SelectStyle = ({
  initial,
  fullName,
  setSignatureStyle,
  onSaveSelectStyle,
}) => {
  const [selectedFont, setSelectedFont] = useState("");

  const handleChange = (event) => {
    const selectedStyle = event.target.value;
    setSelectedFont(selectedStyle);
    // setSignatureStyle?.(selectedStyle);
    // onSaveSelectStyle(selectedStyle);
  };

  return (
    <FlexComp
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      gridGap="20px"
    >
      <FlexComp
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gridGap="20px"
      >
        <Span color="#000000" fontSize="18px" fontWeight="500">
          Preview
        </Span>

        <FormControl>
          <Select
            value={selectedFont}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Font Style" }}
          >
            <MenuItem value="" disabled>
              Change Style
            </MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
            <MenuItem value="Courier New">Courier New</MenuItem>
            <MenuItem value="Lindsey Font">Lindsey Font</MenuItem>
            <MenuItem value="Northwell">Northwell</MenuItem>
            <MenuItem value="Bon Vivant Family">Bon Vivant Family</MenuItem>
          </Select>
        </FormControl>
      </FlexComp>
      {/* Preview section */}
      <SignPreviewBox>
        <FlexComp
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap="10px"
          width="45%"
        >
          <Span>Preview</Span>
          {/* Apply selected font style to the preview */}
          <SignPreview
            className="custom-name-preview"
            style={{ fontFamily: selectedFont }}
          >
            <Span className="custom-name-span">{fullName}</Span>
          </SignPreview>
        </FlexComp>
        <FlexComp
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap="10px"
          width="45%"
        >
          <Span>Preview</Span>
          {/* Apply selected font style to the preview */}
          <SignPreview style={{ fontFamily: selectedFont }}>
            <Span>{initial}</Span>
          </SignPreview>
        </FlexComp>
      </SignPreviewBox>
    </FlexComp>
  );
};

export default SelectStyle;
