import { IconButton, Stack } from "@mui/material";
import detail from "assets/client/detail.png";
import edit from "assets/client/edit.png";
import AdditionalNotes from "components/modals/AdditionalNotes";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import CustomTable from "components/reusable/customTable/CustomTable";
import TitleComponent from "components/reusable/titleComponent/TitleComponent";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import PersonalModal from "./PersonalModal";
// import PersonalInfoModal from "components/modals/PersonalInfoModal";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YYYY");
  return formattedDate;
};
const errorToast = (message = "") => toast.error(message);
const infoToast = (message = "") => toast.info(message);

const PersonalCollectionList = () => {
  const navigate = useNavigate();
  const [modalData, setModalData] = useState();
  const clientId = localStorage.getItem("clientId");
  const [tab_loader, setTabLoader] = useState(true);
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [, setLoading] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [additionalNotes, setadditionalnotes] = useState();
  const [notes, setNotes] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const personalCollectionId = localStorage.getItem("formsId");

  const handleNotesSubmit = async (additionalNotes = "") => {
    try {
      const id = personalCollectionId.replace(/"/g, "");
      await apiReq(API_ROUTES.addAditionalNotes, "POST", {
        clientId,
        additionalNotes,
        personalCollectionId: id,
      });
      setIsAdditionalModal(false);
      infoToast("Addiitonal notes saved successfully");
      setTimeout(() => {
        setIsValidateTaxModal(true);
      }, 2000);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    }
  };

  const handleFormSubmit = async () => {
    try {
      // Prepare data to be uploaded
      setLoading(true);

      await apiReq(API_ROUTES.updatePersonalCollection, "POST");
      infoToast("Info saved successfully");
      localStorage.setItem("editForm", false);
      setTimeout(() => {
        navigate("/personal-list");
      }, 1500);

      localStorage.removeItem("personalCollectionId");
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoader(true);
        const response = await apiReq(
          API_ROUTES.getPersonalCollectionList,
          "POST",
          { clientId }
        );
        const responseData = await response.data;
        const updatedData = responseData?.data?.map(
          (personalCollection, i) => ({
            id: personalCollection?._id,
            srNo: i + 1,
            formYear: personalCollection?.prepSheetId?.taxYear,
            date: personalCollection?.createdAt,
            status: personalCollection?.status === "Review Pending" ? "Verification Needed" : personalCollection?.status,
            formProgress: personalCollection?.completionPercentage,
            comments: personalCollection?.additionalNotes,
            otherDetails: personalCollection,
          })
        );

        setBusinessData(updatedData);
      } catch (error) {
        errorToast(
          error?.message ||
            error?.response?.message ||
            "Error fetching data. please try later."
        );
      } finally {
        setTabLoader(false);
      }
    };

    // console.log("businessData",businessData)

    fetchData();
    localStorage.removeItem("currentBusinessRow");
    // localStorage.removeItem("businessInfoId");
    localStorage.removeItem("personalCollectionId");
    localStorage.setItem("editForm", false);
    // eslint-disable-next-line
  }, []);

  const BGColumns = [
    {
      headerName: "Sr#",
      field: "srNo",
      width: 100,
      editable: false,
    },
    {
      field: "formYear",
      headerName: "Form Year",
      width: 250,
      editable: false,
      renderCell: (params) => <div>{dayjs(params.value).format("YYYY")}</div>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      headerName: "Form Progress",
      field: "formProgress",
      width: 140,
      editable: false,
      renderCell: (params) => <div>{params.value}%</div>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Data Entery"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(244, 184, 27, 0.3)",
            color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
          }}
        >
         {params.row.formProgress < 100 ? "Incomplete" : "Verification Needed"}
        </div>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
      editable: false,
    },
    {
      headerName: "Action",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="space-around">
          {params.row.status == "Verification Needed" && (
            <IconButton
              color="primary"
              onClick={() => {
                localStorage.setItem(
                  "currentPersonalRow",
                  JSON.stringify(params.row.otherDetails)
                );
                localStorage.setItem(
                  "personalCollectionId",
                  JSON.stringify(params.row.otherDetails._id)
                );
                localStorage.setItem("editForm", true);
                navigate("/personal-collection");
              }}
            >
              <img src={edit} alt="edit" width={30} height={25} />
            </IconButton>
          )}
          <IconButton
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails);
              setIsModalOpen(true);
            }}
          >
            <img src={detail} alt="detail" width={30} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];
  return (
    <>
      <BusinessListContainer>
        <TitleComponent
          text="Personal Collection"
          showButton
          btntext="Add New Personal Collection"
          clickHandler={() => navigate("/personal-collection")}
        />
        <BusinessListWrapper>
          <CustomTable
            loading={tab_loader}
            rows={businessData}
            columns={BGColumns}
          />
        </BusinessListWrapper>
      </BusinessListContainer>
      {/* <AddNewBusiness
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uploadDocText="Upload Business Doc"
        clickHandler={() => navigate("/personal-collection")}
        setIsUploadModal={setIsUploadModal}
        setIsConfirmationModal={setIsConfirmationModal}
      /> */}

      <AdditionalNotes
        isModalOpen={isAdditionalModal}
        additionalNotes={notes}
        handleNotesSubmit={handleNotesSubmit}
        closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
        openSecondModal={(notes) => handleNotesSubmit(notes)}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        handleYesClick={handleFormSubmit}
        paragraph="Are you sure you want to validate this Personal Collection Form?"
      />
      {isModalOpen && (
        <PersonalModal
          // Corrected prop name to onClose
          modalData={modalData}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default PersonalCollectionList;

const BusinessListContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
