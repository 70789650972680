// BusinessInfo component
import { useContext, useEffect, useState } from "react";
import { ItemizedDeductionContainer } from "./BusinessFormation.style";
import { Button } from "components/reusable/button/Button";
import BusinessInfoAccordian from "./BusinessInfoAccordian";
import {
  stateOptions,
  topBusinessInfoFields,
} from "data/clientData/businessFormation";
import { useFormik } from "formik";
import { Toaster } from "components/reusable/toaster";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { Input } from "components/reusable/input/Inputs";
import FlexComp from "components/reusable/flex/Flex";
import {
  CorporationInfoWrapper,
  CorporationInfoInputWrapper,
  StatusCheckboxWrapper,
} from "./BusinessFormation.style";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import {
  corporationFields,
  fillingStatus,
} from "data/clientData/businessFormation";
import { SyncLoader } from "react-spinners";
import { Autocomplete, FormHelperText, TextField } from "@mui/material";

import { nonProfitFormationSchema } from "./Schema";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { useLocation, useNavigate } from "react-router-dom";

const formStatus = [
  { label: "LLC", key: "llcCheck" },
  { label: "Business Corp. (INC)", key: "businessCorpCheck" },
  { label: "Sole Proprietor", key: "soleProprietorCheck" },
  { label: "Non-Profit", key: "nonProfitCheck" },
  { label: "DBA", key: "dbaCheck" },
];


const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const BusinessInfo = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const { pathname } = useLocation();
  const { setOpen } = useContext(PageLoaderContext);
  const [Data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const [accordions, setAccordions] = useState(
    topBusinessInfoFields.slice(0, 1)
  );
  const businessFormationId = localStorage.getItem(
    "businessFormationId"
  );
  const edit = localStorage.getItem(
    "formationEdit"
  );

  const formationId = (businessFormationId)?.replace(/"/g, '');
  const navigate = useNavigate()
  const [firstAccordionOpen, setFirstAccordionOpen] = useState(true);
  const initialValues = {
    personBusinessInfo: [
      {
        firstName: Data?.firstName || "",
        middleName: Data?.middleName || "",
        lastName: Data?.lastName || "",
        // ssn: "",
        title: "",
        mobileNumber: Data?.phone || "",
        city: Data?.city || "",
        street: Data?.street || "",
        state: Data?.state || "",
        zipCode: Data?.zipCode || "",
      },
    ],
    fillingStatus: "", // options: "LLC", "BusinessCorp", "SoleProprietor", "Non-Profit", "DBA"
    llcCheck: false,
    businessCorpCheck: false,
    soleProprietorCheck: false,
    nonProfitCheck: false,
    dbaCheck: false,
    currentCorporationName: "",
    preferedName: "",
    alternateName: "",
    city: Data?.city || "",
    street: Data?.street || "",
    state: Data?.state || "",
    zipCode: Data?.zipCode || "",
    businessDescription: "",
  };
  console.log(Data?.city, "businessFormation");

  const addAccordion = () => {
    const newAccordion = {
      ...topBusinessInfoFields[0],
      title: "Business Information (Member# " + (accordions.length + 1) + ")",
    };
    setAccordions((prev) => [...prev, newAccordion]);
  };

  const deleteAccordion = (index) => {
    if (index === 0 || index >= accordions.length) return;
    setAccordions((prev) => prev.filter((_, i) => i !== index));
  };
  const fillingStatusEnum = {
    llcCheck: "LLC",
    businessCorpCheck: "BusinessCorp",
    soleProprietorCheck: "SoleProprietor",
    nonProfitCheck: "NonProfit",
    dbaCheck: "DBA",
  };

  const {
    values,
    errors,
    touched,
    setValues,
    isSubmitting,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: nonProfitFormationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast("Client Id Not Found.");
          return;
        }
  
        // Determine the fillingStatus based on checkbox selection
        const selectedStatus = Object.keys(fillingStatusEnum).find(
          (key) => values[key]
        );
        const updatedValues = {
          ...values,
          clientId,
          fillingStatus: selectedStatus ? fillingStatusEnum[selectedStatus] : "",
        };
  
        setIsLoading(true);
        
        // API request with updated values as payload
        await apiReq(
          edit ? API_ROUTES.updateBusinessFormation + (formationId) : API_ROUTES.BusinessFormation,
          "POST",
          updatedValues
        );
        
        infoToast(edit ? "Form updated successfully" : "Form saved successfully");
        setIsLoading(false);
        navigate("/business-formation")
      } catch (error) {
        errorToast(
          error?.message || "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  console.log(values, "valuess")

  const resetCheckboxes = () => {
    for (let val of formStatus) {
      setFieldValue([val.key], false);
    }
  };
  const handleCheckboxChange = (key) => (e) => {
    resetCheckboxes();
    setFieldValue(key, e.target.checked);
    setFieldValue(
      "fillingStatus",
      e.target.checked ? fillingStatusEnum[key] : ""
    );
  };

  useEffect(()=>{
    const fetchdata = async () => {
      try {
        setOpen(true);
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getBusinessFormationById + (formationId), "POST", {clientId: clientId});
        // if(data){
        //   setValues(data)
        // }
        if (data) {
          // Update accordians based on data.personBusinessInfo length
          const newAccordions = data.personBusinessInfo.map((_, index) => ({
            ...topBusinessInfoFields[0],
            title: "Business Information (Member #" + (index + 1) + ")",
          }));
          setAccordions(newAccordions);
          setValues(data);
        }
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setOpen(false);
      }
    };
    if(formationId) fetchdata();

  },[formationId])

  const handleDelete = (index) => {
    console.log(index, "indexxxx")
    setAccordions((prev) => prev.filter((_, i) => i !== index));
    const newPersonBusinessInfo = [...values.personBusinessInfo];
    newPersonBusinessInfo.splice(index, 1);
    setValues({ ...values, personBusinessInfo: newPersonBusinessInfo });
  };
  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <ItemizedDeductionContainer>
          <CorporationInfoWrapper>
            <HeadingFive color="#000000" fontSize="18px" fontWeight="500">
              Corporation Information
            </HeadingFive>
            <StatusCheckboxWrapper>
              <Span
                color="#1B1B1B"
                fontSize="16px"
                fontWeight="500"
                textAlign="start"
              >
                Filling Status
              </Span>
              {formStatus.map((item, index) => (
                <TextCheckbox
                  text={item.label}
                  gridGap="1px"
                  key={index}
                  isChecked={values?.[item.key]} // Use dynamic field name
                  onChange={handleCheckboxChange(item.key)} // Use dynamic field name
                />
              ))}
            </StatusCheckboxWrapper>
            <FormHelperText error>
              {touched?.fillingStatus && errors?.fillingStatus}
            </FormHelperText>
          </CorporationInfoWrapper>
          <CorporationInfoInputWrapper>
            {corporationFields.map(
              ({ label, id, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{ width }}>
                  <Input
                    label={label}
                    id={id}
                    size={size}
                    placeholder={placeholder}
                    value={values?.[id]} // Use the correct id for binding to formik values
                    onChange={(e) => setFieldValue(id, e.target.value)} // Update only the specific field in formik values
                  />
                  <FormHelperText error>
                    {touched?.[id] && errors?.[id]}
                  </FormHelperText>
                </Stack>
              )
            )}
            <FlexComp direction="row" gridGap="25px" width="100%">
              <HeadingFive fontSize="16px" fontWeight="500" color="black">
                Address
              </HeadingFive>
            </FlexComp>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Street"
                  id="street"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  required
                  value={values?.street}
                  onChange={(e) => setFieldValue(`street`, e.target.value)}
                />
                <FormHelperText error>
                  {touched?.street && errors?.street}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="City"
                  id="outlined-size-small"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  required
                  value={values?.city}
                  onChange={(e) => setFieldValue(`city`, e.target.value)}
                />
                <FormHelperText error>
                  {touched?.city && errors?.city}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values.state}
                  onChange={(_, newVal) => {
                    setFieldValue("state", newVal?.value || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="State *" />
                  )}
                />
                <FormHelperText error>
                  {touched?.state && errors?.state}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Zip Code"
                  id="zipCode"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="XXXXX-XXXX"
                  required
                  value={values.zipCode.toString()} // Ensure zipCode is converted to string
                  onChange={(e) => {
                    let zipCodeValue = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue =
                        zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    setFieldValue("zipCode", zipCodeValue);
                  }}
                />
                <FormHelperText error>
                  {touched?.zipCode && errors?.zipCode}
                </FormHelperText>
              </Stack>
            </FlexComp>
            <Stack direction="column" sx={{ width: "30%" }}>
              <Input
                label="Business Description"
                id="businessDescription"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="Enter"
                required
                value={values.businessDescription}
                onChange={(e) =>
                  setFieldValue(`businessDescription`, e.target.value)
                }
              />
              <FormHelperText error>
                {touched?.businessDescription && errors?.businessDescription}
              </FormHelperText>
            </Stack>
            {/* {selectedCheckbox === "DBA" && ( */}
            <Stack direction="column" sx={{ width: "30%" }}>
              <Input
                label="Current Corporation Name"
                id="currentCorporationName"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="Enter"
                required
                value={values.currentCorporationName}
                onChange={(e) =>
                  setFieldValue(`currentCorporationName`, e.target.value)
                }
              />
              <FormHelperText error>
                {touched?.currentCorporationName &&
                  errors?.currentCorporationName}
              </FormHelperText>
            </Stack>
            {/* )} */}
          </CorporationInfoInputWrapper>
          {accordions.map((item, index) => (
            <BusinessInfoAccordian
              membersList={values.personBusinessInfo}
              item={item}
              key={index}
              index={index}
              openIndex={openIndex}
              values={values.personBusinessInfo?.[index] || {}}
              setFieldValue={setFieldValue}
              errors={errors.personBusinessInfo?.[index] || {}}
              touched={touched.personBusinessInfo?.[index] || {}}
              handleDelete={handleDelete}
              isOpen={
                openIndex === index && (index === 0 ? firstAccordionOpen : true)
              }
              onDelete={deleteAccordion}
            />
          ))}

          {values.fillingStatus != "SoleProprietor" &&  <Button
            text="+ Add Member"
            type="button"
            width="auto"
            fontSize="18px"
            backgroundColor="rgba(0, 173, 239, 1)"
            alignSelf="start"
            onClick={addAccordion}
          />}
          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Save"
            }
            type="submit"
            width="12%"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="end"
          />
        </ItemizedDeductionContainer>
      </form>

      
    </>
  );
};

export default BusinessInfo;
