import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormHelperText, Stack } from "@mui/material";
import { FeesFieldsTop } from "data/client";
import { Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import { apiReq } from "services/Requests";
import { adminstrativeFeeSchema } from "./Schema";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const initialValues = {
  associationFee: "",
  atmCharges: "",
  bankFee: "",
  dotExamFee: "",
  comcardFee: "",
  investmentFee: "",
  licensingFee: "",
  safeDepositBoxFee: "",
  administrativeFee: "",
  wireFee: "",
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const Fees = ({ setCurrentTab }) => {
  const clientId = localStorage.getItem("clientId");
  const {setOpen} = useContext(PageLoaderContext);
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const [tabsData, setTabsData] = useState({})
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");

  const {values, touched, errors, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: adminstrativeFeeSchema,
    onSubmit: async(values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast('Client Id Not Found.');
          return;
        }
        else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
        const {_id: truckDriverId, truckDriverFeeId} = selectedDriverInfo;
        await apiReq(`${(truckDriverFeeId?._id || tabsData?._id ) ? API_ROUTES.updateFee : API_ROUTES.addFee}`, "POST", {clientId, truckDriverMainId: truckDriverMainId || truckDriverId || "", truckDriverFeeId: truckDriverFeeId?._id || tabsData?._id, ...values});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, maintenance: false}));
        infoToast(truckDriverFeeId?._id ? "Fee info updated succesfully": "Fee info saved succesfully");
        setTimeout(() => {
          setCurrentTab(2);
        }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });
  console.log(selectedDriverInfo, "feessss")
  useEffect(() => {
    const fetchFee = async() => {
      try {
        setOpen(true);
        const {truckDriverFeeId} = selectedDriverInfo;
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getFee, "POST", {truckDriverFeeId: truckDriverFeeId._id});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, maintenance: false}));
        setValues(data);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      }  finally {
        setOpen(false);
      }
    }
    if(selectedDriverInfo?.truckDriverFeeId)  fetchFee();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverFeeId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverFeeId?._id && localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, maintenance: false}));
        truckDriverFeeId?._id && setValues(truckDriverFeeId);
        setTabsData(truckDriverFeeId)
      }
      fetchTruckDriverDetails();
    }
  },[]);
  // console.log(tabsData, "truck driver details")

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize='18px' fontWeight='600'>
            Administrative Fees
          </HeadingFive>
          <BasicInfoInputWrapper>
            {FeesFieldsTop.map(
              ({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{width}}>
                  <Input
                    label={label}
                    id={id}
                    size={size}
                    placeholder={placeholder}
                    value={values[key]}
                    onChange={e => {
                      let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                      setFieldValue(key, val)
                    }}
                  />
                  <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                </Stack>
              )
            )}
          </BasicInfoInputWrapper>
          <Button
            text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"}
            type="submit"
            width='15%'
            fontSize='18px'
            backgroundColor='#0B2558'
            alignSelf='flex-end'
          />
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default Fees;
