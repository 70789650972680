import styled from "styled-components";

export const ProfilePicBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  background: #eff3fd;
  border: 1px dotted #1b1b1b;
  width: 100%;
  border-radius: 18px;
  padding:13px
`;

export const ProfilePicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 10px 10px;
  grid-gap: 15px;
  cursor: pointer;
  object-fit:  cover;
  //width: 94px; 
  height: 40%;
  //margin-bottom: 8px;
`;


