import React, { useEffect, useState } from "react";
import FlexComp from "components/reusable/flex/Flex";
import { HeadingFive, Span } from "components/reusable/typography/Typography.style";
import { Input } from "components/reusable/input/Inputs";
import { partnerMultiMemberFields } from "data/client";
import { Button } from "components/reusable/button/Button";
import { IconStyledButton } from "components/reusable/button/Button.style";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MuiPhone } from "components/reusable/PhoneNumber";
import { Autocomplete, FormHelperText, Stack, TextField } from "@mui/material";
import { stateOptions } from "data/clientData/businessFormation";
import CloseIcon from '@mui/icons-material/Close';

const MultiPartnerField = ({ 
  text, 
  btnText,
  errors = {},
  setarrayData,
  required,
  multiMemberLlcArr = [],
}) => {
  const handleArrayChange = (index, name, value) => {
    const newData = [...multiMemberLlcArr];
    newData[index] = { ...newData[index], [name]: value }; 
    setarrayData(newData);
  };

  const [rows, setRows] = useState([{ index: 0 }]);
  const handleAddRow = () => {
    setRows([...rows, { index: rows.length }]);
  };

  const handleRemoveRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
    setarrayData(multiMemberLlcArr.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if(multiMemberLlcArr.length) {
      let row = multiMemberLlcArr.map((val, index) => ({index}));
      setRows(row);
    }
  }, [multiMemberLlcArr]);

  return (
    <FlexComp direction="column" gridGap="20px">
      <Span color="#1B1B1B" fontSize="18px" fontWeight="500">
        {text}
      </Span>
      {rows.map(({ index }, rowIndex) => (
        <React.Fragment key={index}>
          <FlexComp
            direction="row"
            gridGap="10px"
            alignItems="center"
          >
            {partnerMultiMemberFields.map(
              ({ label, id, size, width, placeHolder, name }, fieldIndex) => (
                <Stack key={fieldIndex} direction="column" sx={{ width }}>
                  <Input
                    id={id}
                    name={name}
                    required={required}
                    size={size}
                    label={label}
                    placeholder={placeHolder}
                    value={multiMemberLlcArr[index]?.[name]}
                    onChange={(e) => handleArrayChange(rowIndex, name, e.target.value)}
                  />
                  <FormHelperText error>{errors?.[index]?.[name]}</FormHelperText>
                </Stack>
              )
            )}
            <Stack direction="column" sx={{ width: "47%"}}>
              <MuiPhone
                value={multiMemberLlcArr?.[index]?.mobileNumber}
                handleChange={value => handleArrayChange(rowIndex, "mobileNumber", value)}
                required={required}
              />
              <FormHelperText error>{errors?.[index]?.mobileNumber}</FormHelperText>
            </Stack>
          </FlexComp>
          {/* <FlexComp direction="column" gridGap="10px" width="100%">
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Address
            </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Street *"
                  id="street"
                  size="size"
                  placeholder="Enter"
                  onChange={(e) => handleArrayChange(rowIndex, "street", e.target.value)}
                  value={multiMemberLlcArr?.[index]?.street}
                />
                <FormHelperText error>{errors?.[index]?.street}</FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="City *"
                  id="city"
                  name="city"
                  size="size"
                  placeholder="Enter"
                  onChange={(e) => handleArrayChange(rowIndex, "city", e.target.value)}
                  value={multiMemberLlcArr?.[index]?.city}
                />
                <FormHelperText error>{errors?.[index]?.city}</FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "32%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={multiMemberLlcArr?.[index]?.state}
                  onChange={(_, newVal) => handleArrayChange(rowIndex, "state", newVal?.value || "")}
                  renderInput={(params) => (
                    <TextField {...params} label="State *" />
                  )}
                />
                <FormHelperText error>{errors?.[index]?.state}</FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "33%" }}>
                <Input
                  label="Zip Code *"
                  id="zipCode"
                  size="size"
                  placeholder="XXXXX-XXXX"
                  onChange={(e) => {
                    let zipCodeValue = e.target.value.replace(/\D/g, "").slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue = zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    handleArrayChange(rowIndex, "zipCode", zipCodeValue);
                  }}
                  value={multiMemberLlcArr?.[index]?.zipCode}
                />
                <FormHelperText error>{errors?.[index]?.zipCode}</FormHelperText>
              </Stack>
            </FlexComp>
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Business Percentage *"
                id="businessPercentage"
                name="businessPercentage"
                size="size"
                placeholder="Enter"
                onChange={(e) => handleArrayChange(rowIndex, "businessPercentage", e.target.value)}
                value={multiMemberLlcArr?.[index]?.businessPercentage}
              />
              <FormHelperText error>{errors?.[index]?.businessPercentage}</FormHelperText>
            </Stack>
          </FlexComp> */}
          {rowIndex > 0 && (
            <IconStyledButton
              width='auto'
              bgColor='none'
              border='none'
              borderRadius='50%'
              text="Delete"
              onClick={() => handleRemoveRow(index)}
            >
              <CloseIcon sx={{color:'black'}}/>
            </IconStyledButton>
          )}
        </React.Fragment>
      ))}
      <Button
        text={btnText}
        width="15%"
        fontSize="14px"
        textAlign="start"
        padding="10px 20px 10px 20px"
        onClick={handleAddRow}
      />
    </FlexComp>
  );
};

export default MultiPartnerField;
