import React, { useEffect, useState } from "react";
import Pdf from "../../../assets/reusable/Pdf.png";
import Download from "../../../assets/reusable/Download.png";
import {
  TextSec,
  UploadPdfButton,
  UploadPdfRight,
  UploadPdfLeft,
} from "./Button.style";
import { Paragraph, Span } from "../typography/Typography.style";

const PdfUploadButton = ({ documents, doc }) => {
  const [fileSizes, setFileSizes] = useState({});

  useEffect(() => {
    const fetchFileSizes = async () => {
      const sizes = {};
      const promises = documents.map(async (document) => {
        try {
          const response = await fetch(document.url);
          const size = response.headers.get("content-length");
          sizes[document.url] = size;
        } catch (error) {
          console.error(`Error fetching size for ${document.url}:`, error);
        }
      });
      await Promise.all(promises);
      setFileSizes(sizes);
    };

    if (documents) {
      fetchFileSizes();
    }
  }, [documents]);

  const handleDownload = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);


      if (!response.ok) throw new Error("Download failed");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const filename = pdfUrl.split("/").pop() || "document.pdf";

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
      // Handle error appropriately
    }
  };

  const formatSize = (size) => {
    if (!size) return "Unknown size";
    const kb = size / 1024;
    if (kb < 1024) {
      return `${kb.toFixed(2)} KB`;
    } else {
      return `${(kb / 1024).toFixed(2)} MB`;
    }
  };

  return (
    <>
      {documents &&
        documents?.map((document, index) => (
          <UploadPdfButton
            key={index}
            onClick={() => handleDownload(document.url || document?.value)}
          >
            <UploadPdfLeft>
              <img src={Pdf} alt="Pdf" />
              <TextSec>
                <Span fontSize="14px">
                  {doc === "personalDoc" ? document.name : document.name}
                </Span>
              </TextSec>
            </UploadPdfLeft>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#0b2558",
                borderRadius: "6px",
                padding: "5px",
              }}
            >
              <img src={Download} alt="Download" />
            </div>
          </UploadPdfButton>
        ))}
    </>
  );
};

export default PdfUploadButton;
