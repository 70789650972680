import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { tabsClasses } from "@mui/material/Tabs"; // Importing tabsClasses

const StaffTabs = ({ tabs, centered, initialTabs, setActiveTab }) => {
  const [value, setValue] = useState(initialTabs);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "rgba(239, 243, 253, 1)",
        borderRadius: "10px",
        padding: "2px",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
          "& .Mui-selected": {
            backgroundColor: "rgba(0, 173, 239, 1)",
          },
          "& .MuiTab-root": {
            borderBottom: "none",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
          },
          "& .MuiTabs-root": {
            padding: "5px",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
    </Box>
  );
};

StaffTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  centered: PropTypes.bool,
  initialTabs: PropTypes.number,
  setActiveTab: PropTypes.func.isRequired,
};

StaffTabs.defaultProps = {
  centered: false,
  initialTabs: 0,
};

export default StaffTabs;
