import {
  AuthPicWrapper,
  SignupContainer,
} from "components/auth/signup/Signup.style";
import Auth from "assets/auth/Auth.png";
import AdminLogin from "components/auth/adminLogin/AdminLogin";

const AdminSignin = () => {
  return (
    <SignupContainer>
      <AdminLogin />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default AdminSignin;
