import { useEffect, useState } from "react";
import styled from "styled-components";
import CustomTable from "components/reusable/customTable/CustomTable";
import { Button } from "@mui/material";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import GeneralBusinessModal from "components/modals/staffModals/GernalBusinessModal";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import ClientInformationModal from "components/modals/ClientInformationModal";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const User = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [tab_loader, setTabLoader] = useState(true);
  const current_user = JSON.parse(localStorage.getItem("userData") || "{}");
  const [openInfoModal, setOpenInfoModal] = useState();
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false); // State to manage row selection
  const [search, setSearch] = useState();
  const [originalData, setOriginalData] = useState([]);
  const [modalData, setModalData] = useState();
  const handleInfoModalClose = () => {
    setOpenInfoModal(false);
  };
  const fetchData = async () => {
    try {
      setTabLoader(true);
      const response = await fetch(
        API_BASE_URL + API_ROUTES.AllUsers,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        setTabLoader(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const { user = {} } = current_user || {};
      console.log(user, "listing");
      const dataValue =
        responseData?.data.filter((val) => val.email !== user.email) || [];
      setRows(dataValue);
      setOriginalData(dataValue);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    localStorage.removeItem("truckDriverMainId");
  }, []);

  // const handleSwitchChange = async (row) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("userId", row._id);
  //     formData.append("activeStatus", row.activeStatus === 'Active' ? "Inactive" : "Active");
  //     const { data, message = '' } = await apiReq(API_ROUTES.userManagementUpdate, "POST", formData, API_BASE_URL, false);
  //     infoToast(message || "Status update successfully");
  //     setTimeout(() => {
  //       fetchData();
  //     }, 1500);
  //   } catch (error) {
  //     errorToast(error?.message || error?.response?.message || "Error update user status. please try again later.");
  //   }
  // };
  const handleAction = async (row, action) => {
    try {
      const formData = new FormData();
      formData.append("userId", row._id);

      if (action === "statusChange") {
        formData.append(
          "activeStatus",
          row.activeStatus === "Active" ? "Inactive" : "Active"
        );
      } else if (action === "Deleted") {
        formData.append("activeStatus", "Deleted");
      }

      const { data, message = "" } = await apiReq(
        API_ROUTES.userManagementUpdate,
        "POST",
        formData,
        API_BASE_URL,
        false
      );
      infoToast(message || "Action completed successfully");
      setTimeout(() => {
        fetchData();
      }, 1500);
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error performing action. Please try again later."
      );
    }
  };

  const handleSwitchChange = async (row) => {
    await handleAction(row, "statusChange");
  };

  const handleEditClick = (user) => {
    navigate("/user-management-form", { state: { user } });
  };

  // const handleViewClick = (id) => {
  //   // Implement logic to handle view action
  // };
  const handleDeleteClick = async (row) => {
    await handleAction(row, "Deleted");
  };

  const individualColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 170,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 290,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 180,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Contact",
      width: 180,
      editable: false,
    },
    {
      field: "activeStatus",
      headerName: "Active",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Switch
          checked={params.row.activeStatus === "Active"}
          onChange={() => handleSwitchChange(params.row)}
          color="primary"
        />
      ),
    },
    {
      headerName: "Action",
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="text"
            color="primary"
            size="small"
            sx={{
              background: "rgba(11, 37, 88, 1)",
              borderRadius: "20px",
              color: "white",
              minWidth: "30px",
            }}
            onClick={() => handleEditClick(params.row)}
            startIcon={
              <BorderColorIcon size="small" style={{ marginLeft: "10px" }} />
            }
          >
            {/* Edit */}
          </Button>
          <Button
            variant="text"
            color="primary"
            sx={{
              background: "rgba(0, 173, 239, 1)",
              borderRadius: "20px",
              color: "white",
              minWidth: "30px",
              padding: "2.5px",
              marginLeft: "5px",
            }}
            onClick={() => {
              setModalData(params.row);
              setOpenInfoModal(true);
            }}
            startIcon={
              <VisibilityIcon
                size="small"
                style={{ marginLeft: "10px", fontSize: "20px" }}
              />
            }
          >
            {/* View */}
          </Button>
          <Button
            variant="text"
            sx={{
              background: " rgba(208, 23, 23, 1)",
              borderRadius: "20px",
              color: "white",
              minWidth: "30px",
              padding: "2.5px",
              marginLeft: "5px",
            }}
            onClick={() => handleDeleteClick(params.row)}
            startIcon={
              <DeleteOutlineOutlinedIcon
                size="small"
                style={{ marginLeft: "10px", fontSize: "20px" }}
              />
            }
          >
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  const individualRows = [
    {
      id: 1,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "heigh",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
    {
      id: 2,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "low",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
  ];

  const options = [
    { label: "Select ALL", value: "Select All" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Staff Review", value: "Staff Review" },
  ];

  const handleSelectChange = (selectedValues) => {};

  const handleSearchChange = (selectedValues) => {
    setSearch(selectedValues.target.value);
  };

  useEffect(() => {
    let filteredData = originalData;
    if (search) {
      filteredData = filteredData.filter((item) =>
        item.firstName.toLowerCase().includes(search.toLowerCase())
      );
      setRows(filteredData);
    }
  }, [search]);

  const handleButtonClick = () => {
    // Button click logic
    console.log("button click");
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          showFirstSelect={false}
          showSecondSelect={false}
          showButton={true}
          clickHandler={() => navigate("/user-management-form")}
          showSecondButton={false}
          showThirdButton={false}
          placeholder="Search by Name"
          options={options}
          btntext="+ Add User"
          btntext2="excel"
          onSelectChange={handleSelectChange}
          onChange={handleSearchChange}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />

        <RentalPropertyWrapper>
          <CustomTable
            rows={rows}
            loading={tab_loader}
            enableRowSelection={enableRowSelection}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>

      <ClientInformationModal
        modalType="userManagement"
        modalData={modalData}
        handleInfoModalClose={handleInfoModalClose}
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
      <GeneralBusinessModal
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      />
      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
    </>
  );
};

export default User;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
