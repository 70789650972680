import React from "react";
import PdfUploadButton from "../../reusable/button/PdfUploadButton";
import { GeneralBusinessBtnWrapper } from "./UploadTaxDocs.style";
const TruckDriver = ({ data }) => {
  const transformData = (data) => {
    return data
      .filter((item) => item.truckDriverDoc) // Filter out items without truckDriverDoc
      .map((item) => ({
        name: 'truckDriverDoc', // Use 'truckDriverDoc' as the name
        url: item.truckDriverDoc, // Use the value of truckDriverDoc as the URL
      }));
  };

  const documents = transformData(data);
    return (
      <GeneralBusinessBtnWrapper>
        <PdfUploadButton documents={documents} />
      </GeneralBusinessBtnWrapper>
    );
};


export default TruckDriver;
