import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
const DropdownComp = ({
  id,
  label,
  width,
  options,
  value,
  onChange,
  required,
  name,
  error
}) => {
  const [selectedValue, setSelectedValue] = useState(value || '');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value); // Pass the synthetic event object
    }
  };
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  return (
    <FormControl sx={{ width: width }} variant="outlined">
      <TextField
        id={id}
        select
        label={label}
        value={selectedValue}
        onChange={handleChange}
        required={required}
        error={error}
        name="state"
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
export default DropdownComp;
// import React, { useState } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// const CustomSelect = ({ options, label, value,width, onChange }) => {
//   const [selectedValue, setSelectedValue] = useState(value || '');
//   const handleChange = (event) => {
//     const syntheticEvent = {
//       target: {
//         name: name,
//         value: event.target.value
//       }
//     };
//     setSelectedValue(event.target.value);
//     if (onChange) {
//       onChange(syntheticEvent); // Pass the synthetic event object
//     }
//   };
//   useEffect(() => {
//     setSelectedValue(value);
//   }, [value]);
//   return (
//     <FormControl sx={{ width: width }} variant="outlined">
//       <TextField
//         id={id}
//         select
//         label={label}
//         value={selectedValue}
//         onChange={handleChange}
//         required={required}
//         error={error}
//         name="state"
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>
//             {option}
//           </MenuItem>
//         ))}
//       </TextField>
//     </FormControl>
//   );
// };
// export default DropdownComp;
const CustomSelect = ({ options, label, value,width, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(value || '');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };
  return (
    <FormControl>
      <Select
        multiSelect={true}
        value={selectedValue}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': label || 'Select' }}
        sx={{borderRadius:'10px',height:'52px',width:width||'300px'}}
      >
        <MenuItem value="" disabled>{label || 'Select an option'}</MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export  {CustomSelect};