import {
  ReviewConfirmationBody,
  ReviewConfirmationBtnsWrapper,
  ReviewConfirmationContainer,
  ReviewConfirmationHeader,
} from "./ReviewAndConfirmation.style";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "components/reusable/button/Button";
import { Span } from "components/reusable/typography/Typography.style";
import ReviewConfirmationBox from "./ReviewConfirmationBox";
import {
  fees,
  fuelAndOil,
  insurance,
  interest,
  maintenance,
  officeExpenses,
  professionalFess,
  rentAndLease,
  roadExpenses,
  taxes,
  truckBusinessInfo,
} from "data/clientData/reviewAndConfirmationModal";
import { useNavigate } from "react-router-dom";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { useRef, useState } from "react";
import TermsCondition from "../TermsCondition";
import html2pdf from "html2pdf.js";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";

const successToast = (message = "") => toast.success(message);
const errorToast = (message = "") => toast.error(message);

const TruckDriver = ({
  truckDriverModal,
  setTruckDriverModal,
  truckdriverID,
  reviewAndSubmition,
  download = false,
  check1099Form,
  setIs1099Modal,
  closeTermModal,
  showTermsCondition,
  setShowTermsCondition,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const selectedDriverInfo = JSON.parse(Info || "{}");
  const {
    truckDriverBasicInfoId: truckDriverBasicInfo,
    truckDriverFeeId: truckDriverFee,
    truckDriverMaintenanceId: truckDriverMaintenance,
    truckDriverLaborTaxesId: truckDriverLaborTaxes,
    truckDriverInsuranceId: truckDriverInsurance,
    truckDriverRoadExpId: truckDriverRoadExp,
    truckDriverOfficeExpId: truckDriverOfficeExp,
    truckDriverRentAndLeaseId: truckDriverRentAndLease,
  } = selectedDriverInfo || {};

  const { businessAddress = {}, ...restBasicInfo } = truckDriverBasicInfo || {};
  const userName = localStorage.getItem("userName");
  const initials = localStorage.getItem("initials");

  const signatureData = {
    fullName: userName,
    initials: initials,
    signatureStyle: "Ariel",
    eFileTaxReturnCheck: false,
    drawCheck: false,
    uploadCheck: false,
    signatureFile: null,
  };
  const handleSubmitReview = async () => {
    try {
      setLoading(true);
      if (check1099Form) {
        setShowTermsCondition(true);
      } else {
        setIs1099Modal(true);
      }
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error while submit docs. please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnSaveSignature = async (signatureData) => {
    console.log("Received signature data:", signatureData);

    // Early return if signatureFile is missing
    if (!signatureData.signatureFile) {
      console.error("signatureFile is missing");
      errorToast("Signature file is required.");
      return;
    }

    try {
      // Prepare FormData for submission
      const formData = new FormData();

      formData.append("clientId", clientId);
      const truckDriverId = [truckdriverID];
      formData.append("truckDriverId", JSON.stringify(truckDriverId));
      formData.append("status", "Document Submitted");
      formData.append("signatureData", JSON.stringify(signatureData));

      await apiReq(
        API_ROUTES.SubmitAllForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );
      successToast("Form submitted successfully.");
      setTimeout(() => {
        setTruckDriverModal(false);
        fetchData();
      }, 1500);
    } catch (error) {
      // Handle errors during submission
      console.error("Error submitting form:", error);
      errorToast(`Error submitting form: ${error.message || "Unknown error"}.`);
    }
  };

  const componentRef = useRef();
  const handleDownload = () => {
    const element = componentRef.current;
    const opt = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based API
    html2pdf().set(opt).from(element).save();
  };

  return (
    <Modal
      open={truckDriverModal}
      onClose={() => setTruckDriverModal(!truckDriverModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ReviewConfirmationContainer>
        <ReviewConfirmationHeader>
          <Span color="#000000" fontSize="24px" fontWeight="600">
            Truck Driver
          </Span>
          <CloseIcon
            sx={{ color: "#000000", cursor: "pointer" }}
            onClick={() => setTruckDriverModal(!truckDriverModal)}
          />
        </ReviewConfirmationHeader>
        <ReviewConfirmationBody ref={componentRef}>
          <ReviewConfirmationBox
            data={truckBusinessInfo}
            values={restBasicInfo}
          />
          <ReviewConfirmationBox data={fees} values={truckDriverFee} />
          <ReviewConfirmationBox
            data={maintenance}
            values={truckDriverMaintenance}
          />
          <ReviewConfirmationBox
            data={taxes}
            // values={truckDriverLaborTaxes?.taxes}
            values={{
              ...truckDriverLaborTaxes?.labor,
              ...truckDriverLaborTaxes?.taxes,
            }}
          />
          <ReviewConfirmationBox
            data={insurance}
            values={{
              ...truckDriverInsurance?.insurance,
              ...truckDriverInsurance?.interest,
            }}
          />
          <ReviewConfirmationBox
            data={interest}
            values={truckDriverInsurance?.interest}
          />
          <ReviewConfirmationBox
            data={roadExpenses}
            values={truckDriverRoadExp}
          />
          <ReviewConfirmationBox
            data={professionalFess}
            values={truckDriverOfficeExp}
          />
          <ReviewConfirmationBox
            data={fuelAndOil}
            values={truckDriverOfficeExp}
          />
          <ReviewConfirmationBox
            data={officeExpenses}
            values={truckDriverOfficeExp}
          />
          <ReviewConfirmationBox
            data={rentAndLease}
            values={truckDriverRentAndLease}
          />
        </ReviewConfirmationBody>
        {console.log(reviewAndSubmition, "reviewAndSubmition")}
        {reviewAndSubmition && (
          <ReviewConfirmationBtnsWrapper>
            <Button
              text="Edit"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              onClick={() => {
                navigate("/add-driver");
              }}
            />
            <Button
              text={
                loading ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Confirm"
              }
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#00ADEF"
              onClick={handleSubmitReview}
            />
          </ReviewConfirmationBtnsWrapper>
        )}

        <ReviewConfirmationBtnsWrapper>
          {!reviewAndSubmition && (
            <Button
              text="OK"
              width="15%"
              fontSize="20px"
              padding="10px 30px 10px 30px"
              alignSelf="flex-end"
              onClick={() => setTruckDriverModal(!truckDriverModal)}
            />
          )}
          {download && (
            <TitleButton
              style={{ padding: "10px 20px 10px 20px" }}
              onClick={handleDownload}
            >
              Download
            </TitleButton>
          )}
        </ReviewConfirmationBtnsWrapper>

        {showTermsCondition && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <TermsCondition
              isModalOpen={showTermsCondition}
              closeModal={() => closeTermModal()}
              onSaveSignature={handleOnSaveSignature}
            />
          </div>
        )}
      </ReviewConfirmationContainer>
    </Modal>
  );
};

export default TruckDriver;
