import {useContext, useEffect, useMemo, useState}from "react";
import { useFormik } from "formik";
import { Input } from "components/reusable/input/Inputs";
import {
  profFieldsTop,
  OilFieldsTop,
  OfficeFieldsTop,
} from "data/client";
import { officeExpSchema } from "./Schema";
import FlexComp from "components/reusable/flex/Flex";
import { Button } from "components/reusable/button/Button";
import ExpAccordion from "components/reusable/accordion/ExpAccordian";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import { FormHelperText, Stack } from "@mui/material";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const detail = {
  name: "",
  amount: ""
};
const initialValues = {
  attorneyAndAccounting: "",
  taxPrepFee: "",
  fuel: "",
  oil: "",
  reeferFuel: "",
  officeExpense: "",
  otherExpenseArr: []
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const OfficeExp = ({ setCurrentTab }) => {
  const [expenses, setExpenses] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const clientId = localStorage.getItem("clientId");
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const [tabsData, setTabsData] = useState({})
  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const {setOpen} = useContext(PageLoaderContext);

  const newExpense = useMemo(() => ({
    title: `Additional Expense ${expenses.length + 1} `,
    fields: [
      {
        label: "Expense Name",
        placeholder: "Enter Name",
        defaultValue: "",
        key: "name",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Amount",
        placeholder: "$",
        defaultValue: "",
        key: "amount",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ] // You can initialize with any default fields if needed
  }), [expenses]);
 
  const addExpense = () => {
    setFieldValue("otherExpenseArr", [...values.otherExpenseArr, detail]);
    setExpenses([...expenses, newExpense]);
  };

  const {values, errors, touched, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: officeExpSchema,
    onSubmit: async(values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast('Client Id Or Truck driver Id Not Found.');
          return;
        } else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
        const {_id: truckDriverId, truckDriverOfficeExpId} = selectedDriverInfo;
        await apiReq(`${(truckDriverOfficeExpId?._id || tabsData?._id) ? API_ROUTES.updateOfficeExp: API_ROUTES.addOfficeExp}`, "POST", {clientId, truckDriverMainId: truckDriverMainId || truckDriverId || "", truckDriverOfficeExpId: truckDriverOfficeExpId?._id || tabsData?._id, ...values});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, utilities: false}));
        infoToast(truckDriverOfficeExpId?._id? "Office expenses updated succesfully": "Office expenses saved succesfully");
        setTimeout(() => {
          setCurrentTab(7);
        }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });

  const deleteExpense = (index) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setFieldValue("otherExpenseArr", values.otherExpenseArr.splice(index, 1));
    setExpenses(updatedExpenses);
  };

  useEffect(() => {
    const fetchFuel = async() => {
      try {
        setOpen(true);
        const {truckDriverOfficeExpId} = selectedDriverInfo;
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getOfficeExp, "POST", {truckDriverOfficeExpId: truckDriverOfficeExpId._id});
        localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, utilities: false}));
        setExpenses(Array.from(data.otherExpenseArr).fill(newExpense));
        setValues(data);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setOpen(false);
      }
    }
    selectedDriverInfo?.truckDriverOfficeExpId && fetchFuel();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverOfficeExpId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverOfficeExpId?._id && localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, utilities: false}));
        truckDriverOfficeExpId?._id && setValues(truckDriverOfficeExpId);
        truckDriverOfficeExpId?._id && setExpenses(Array.from(truckDriverOfficeExpId.otherExpenseArr).fill(newExpense));
        setTabsData(truckDriverOfficeExpId)
      }
      fetchTruckDriverDetails();
    }
  },[]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize="18px" fontWeight="500">
            Professional Fees
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {profFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <Input
                      id={id}
                      label={label}
                      size={size}
                      value={values[key]}
                      placeholder={placeholder}
                      onChange={e => {
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(key, val)
                      }}
                    />
                    <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          <HeadingFive fontSize="18px" fontWeight="500">
            Fuel and Oil
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {OilFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <Input
                      id={id}
                      size={size}
                      label={label}
                      value={values[key]}
                      placeholder={placeholder}
                      onChange={e => {
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(key, val)
                      }}
                    />
                    <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          <HeadingFive fontSize="18px" fontWeight="500">
            Office Expenses
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {OfficeFieldsTop.map(
                ({ label, id, key, size, width, placeholder }, index) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <Input
                      label={label}
                      id={id}
                      size={size}
                      value={values[key]}
                      placeholder={placeholder}
                      onChange={e => {
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(key, val)}
                      }
                    />
                    <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                </Stack>
                )
              )}
            </FlexComp>
            {expenses.map((expense, index) => (
              <FlexComp key={index} direction="column" justifyContent="flex-start" gridGap="5px">
                <ExpAccordion
                  item={expense}
                  key={index}
                  index={index}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                  setFieldValue={setFieldValue}
                  deleteExpense={deleteExpense} // Pass the deleteExpense function
                  values={values.otherExpenseArr[index]}
                />
              </FlexComp>
            ))}

            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                text="+ Add Other Expense"
                type="button"
                width="auto"
                fontSize="18px"
                backgroundColor="#00ADEF"
                onClick={addExpense}
              />
              <Button
                text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"}
                type="Submit"
                width="15%"
                fontSize="18px"
                backgroundColor="#0B2558"
              />
            </FlexComp>
          </BasicInfoInputWrapper>
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default OfficeExp;
