import { BrowserRouter, Route, Routes } from "react-router-dom";
//Authentication Module
import Signin from "./pages/signin/Signin";
import Signup from "./pages/signup/Signup";
//Client Module
import NonProfitFormation from "./components/client/nonProfitFormation/NonProfitFormation";
import BusinessFormation from "./pages/client/businessFormation/BusinesFormation";
import AddBusiness from "./pages/client/businessGeneral/AddBusiness";
import BusinessGenaralList from "./pages/client/businessGeneral/BusinessGenaralList";
import Dashboard from "./pages/client/dashboard/Dashboard";
import PersonalCollection from "./pages/client/personalCollection/PersonalCollection";
import AddRental from "./pages/client/rentalProperty/AddRental";
import RentalPropertyList from "./pages/client/rentalProperty/RentalPropertyList";
import ReviewList from "./pages/client/review/ReviewList";
import ChangePassword from "./pages/client/settings/ChangePassword";
import Profile from "./pages/client/settings/Profile";
import TaxDocs from "./pages/client/taxDocs/TaxDocs";
import TaxDocsList from "./pages/client/taxDocs/TaxDocsList";
import TaxReturnList from "./pages/client/taxDocs/TaxReturnList";
import TruckDriver from "./pages/client/truckDriver/TruckDriver";
import TruckDriverList from "./pages/client/truckDriver/TruckDriverList";
import UploadTaxDocs from "./pages/client/uploadTaxDocs/UploadTaxDocs";
import WorkSheet from "./pages/client/workSheet/WorkSheet";

import { PageLoaderProvider } from "context/PageLoaderProvider";
import "react-international-phone/style.css";
import AdminSupportComp from "./components/admin/adminSupportComp/AdminSupportComp";
import UserForm from "./components/admin/user/UserForm";
import PdfViewerModal from "./components/modals/staffModals/PdfViewerModal";
import HeadingChart from "./components/reusable/charts/HeadingChart";
import DateComponent from "./components/reusable/datecomponent/DateComponent";
import StaffChat from "./components/staff/chat/index";
import StaffMessageCompose from "./components/staff/messages/compose";
import StaffMessages from "./components/staff/messages/index";
import StaffSupportForm from "./components/staff/staffSupportComp/StaffSupportForm";
import { ProfileProvider } from "./context/ProfileProvider";
import AdminClient from "./pages/admin/adminClient/AdminClient";
import AdminDashboard from "./pages/admin/adminDashboard/AdminDashboard";
import AddNewTask from "./pages/admin/adminQueue/AddNewTask";
import AdminQueue from "./pages/admin/adminQueue/AdminQueue";
import AdminClientActivity from "./pages/admin/adminReport/AdminClientReport";
import AdminTaxFilling from "./pages/admin/adminReport/AdminTaxFilling";
import AdminTaxReturn from "./pages/admin/adminReport/AdminTaxReturn";
import ReportListing from "./pages/admin/adminReport/ReportListing";
import AdminSupportListing from "./pages/admin/adminSupport/AdminSupportListing";
import AdminSignin from "./pages/admin/login/AdminSignin";
import AdminCompose from "./pages/admin/messages/AdminCompose";
import AdminMessages from "./pages/admin/messages/AdminMessages";
import AdminChangePassword from "./pages/admin/profile/AdminChangePassword";
import AdminProfile from "./pages/admin/profile/AdminProfile";
import TaxFillingListing from "./pages/admin/taxFilling/TaxFillingListing";
import User from "./pages/admin/userManagement/User";
import ForgetPassword from "./pages/signin/ForgetPassword";
import ResetPassword from "./pages/signin/ResetPassword";
import StaffDashboard from "./pages/staff/dashboard/StaffDashboard";
import ClientSignin from "./pages/staff/login/ClientSigin";
import StaffClient from "./pages/staff/staffClient/StaffClient";
import StaffQueue from "./pages/staff/staffQueue/StaffQueue";
import ClientActivity from "./pages/staff/staffReport/ClientActivity";
import QueueReport from "./pages/staff/staffReport/QueueReport";
import TaxFilling from "./pages/staff/staffReport/TaxFilling";
import StaffChangePassword from "./pages/staff/staffsettings/StaffChangePassword";
import StaffProfiles from "./pages/staff/staffsettings/StaffProfiles";
import StaffSupport from "./pages/staff/staffSupport/StaffSupport";

import ChatComp from "components/admin/adminChat";
import StaffForgetPassword from "components/auth/clientLogin/StaffForgetPassword";
import BusinessInfo from "components/client/businessFormation/BusinessInfo";
import NonProfitFormationList from "components/client/nonProfitFormation/NonProfitFormationList";
import WorkSheetComp from "components/client/workSheet/WorkSheet";
import ScrollToTop from "components/reusable/ScrollToTop";
import AssignTaskPopup from "pages/admin/adminQueue/AssignTaskPopup";
import PersonalCollectionList from "pages/client/personalCollection/PersonalCollectionList";
import PrivateRoute from "PrivateRoute";
import { useRef } from "react";
import AdminForgetPassword from "components/auth/adminLogin/AdminForgetPassword";
import AdminResetPassword from "components/auth/adminLogin/AdminResetPassword";
import StaffResetPassword from "components/auth/clientLogin/StaffResetPassword";

function App() {
  const componentRef = useRef();

  return (
    // <CreatePdf/>
    <>
      <ProfileProvider>
        <PageLoaderProvider>
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                {/* <Route exact path="/layout" element={<StaffClient />} /> */}
                <Route
                  exact
                  path="/"
                  element={
                    <PrivateRoute>
                      <Signin />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/register"
                  element={
                    <PrivateRoute>
                      <Signup />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/forgot-password"
                  element={
                    <PrivateRoute>
                      <ForgetPassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/Change-password"
                  element={
                    <PrivateRoute>
                      <ResetPassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-change-password"
                  element={
                    <PrivateRoute>
                      <AdminResetPassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/staff-Change-password"
                  element={
                    <PrivateRoute>
                      <StaffResetPassword />
                    </PrivateRoute>
                  }
                />
                {/* <Route path="/dashboard"> 
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
            </Route> */}
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                {/* <Route
              exact
              path="/dashboard"
              element={<Layout children={<Dashboard />} />}
            /> */}
                <Route path="/personal-collection">
                  <Route
                    path="/personal-collection"
                    element={
                      <PrivateRoute>
                        <PersonalCollection />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route path="/personal-list">
                  <Route
                    path="/personal-list"
                    element={
                      <PrivateRoute>
                        <PersonalCollectionList />
                      </PrivateRoute>
                    }
                  />
                </Route>
                {/* <Route
              exact
              path="/personal-collection"
              element={<Layout children={<PersonalCollection />} />}
            /> */}
                <Route
                  exact
                  path="/add-business"
                  element={
                    <PrivateRoute>
                      <AddBusiness />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/general-business"
                  element={
                    <PrivateRoute>
                      <BusinessGenaralList />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/rental-property"
                  element={
                    <PrivateRoute>
                      <RentalPropertyList />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/add-rental"
                  element={
                    <PrivateRoute>
                      <AddRental />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/worksheet"
                  element={
                    <PrivateRoute>
                      <WorkSheet />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/add-worksheet"
                  element={
                    <PrivateRoute>
                      <WorkSheetComp />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/business-formation"
                  element={
                    <PrivateRoute>
                      <BusinessFormation />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/add-businessFormation"
                  element={
                    <PrivateRoute>
                      <BusinessInfo />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/truck-driver"
                  element={
                    <PrivateRoute>
                      <TruckDriverList />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/add-driver"
                  element={
                    <PrivateRoute>
                      <TruckDriver />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/upload-tax-docs"
                  element={
                    <PrivateRoute>
                      <UploadTaxDocs />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/tax-docs"
                  element={
                    <PrivateRoute>
                      <TaxDocs />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/client-change-password"
                  element={
                    <PrivateRoute>
                      <ChangePassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/review"
                  element={
                    <PrivateRoute>
                      <ReviewList componentRef={componentRef} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/submit-tax-docs"
                  element={
                    <PrivateRoute>
                      <TaxDocsList componentRef={componentRef} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/tax-return"
                  element={
                    <PrivateRoute>
                      <TaxReturnList />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/non-profit-formation"
                  element={
                    <PrivateRoute>
                      <NonProfitFormationList />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/add-nonProfit"
                  element={
                    <PrivateRoute>
                      <NonProfitFormation />
                    </PrivateRoute>
                  }
                />
                {/* //staff */}
                <Route exact path="/staff-signin" element={<ClientSignin />} />
                <Route
                  exact
                  path="/staff-forget"
                  element={<StaffForgetPassword />}
                />
                <Route
                  exact
                  path="/client"
                  element={
                    <PrivateRoute>
                      <StaffClient />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/queues"
                  element={
                    <PrivateRoute>
                      <StaffQueue />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/support"
                  element={
                    <PrivateRoute>
                      <StaffSupport />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/add-ticket"
                  element={
                    <PrivateRoute>
                      <StaffSupportForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/messages"
                  element={
                    <PrivateRoute>
                      <StaffMessages />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/staff-chat"
                  element={
                    <PrivateRoute>
                      <StaffChat />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/staff-change-password"
                  element={
                    <PrivateRoute>
                      <StaffChangePassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/staff-profile"
                  element={
                    <PrivateRoute>
                      <StaffProfiles />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/date"
                  element={
                    <PrivateRoute>
                      <DateComponent />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/messageCompose"
                  element={
                    <PrivateRoute>
                      <StaffMessageCompose />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/queue-report"
                  element={
                    <PrivateRoute>
                      <QueueReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/tax-filling-report"
                  element={
                    <PrivateRoute>
                      <TaxFilling />
                    </PrivateRoute>
                  }
                />
                {/* Not in staff side removed by client */}
                {/* <Route
              exact
              path="/tax-return-report"
              element={
                <PrivateRoute>
                <TaxReturn />
              </PrivateRoute>
            } 
            /> */}
                <Route
                  exact
                  path="/client-activity-report"
                  element={
                    <PrivateRoute>
                      <ClientActivity />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/client-activity"
                  element={
                    <PrivateRoute>
                      <PdfViewerModal />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/staffDashboard"
                  element={
                    <PrivateRoute>
                      <StaffDashboard />
                    </PrivateRoute>
                  }
                />

                {/* Admin module */}

                {/* Admin module */}
                <Route
                  exact
                  path="/admin-signin"
                  element={
                    <PrivateRoute>
                      <AdminSignin />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-forget"
                  element={
                    <PrivateRoute>
                      <AdminForgetPassword />
                    </PrivateRoute>
                  }
                />
                {/* <Route
              exact
            path='/admin-signin'
              element={<AdminLayout children={< AdminSignin />} />}/> */}
                <Route
                  exact
                  path="/admin-dashboard"
                  element={
                    <PrivateRoute>
                      <AdminDashboard />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/queue-report-listing"
                  element={
                    <PrivateRoute>
                      <ReportListing />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/client-report-listing"
                  element={
                    <PrivateRoute>
                      <AdminClientActivity />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/tax-report-listing"
                  element={
                    <PrivateRoute>
                      <AdminTaxFilling />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/tax-return-report-listing"
                  element={
                    <PrivateRoute>
                      <AdminTaxReturn />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/user-management"
                  element={
                    <PrivateRoute>
                      <User />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/user-management-form"
                  element={
                    <PrivateRoute>
                      <UserForm />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/chart"
                  element={
                    <PrivateRoute>
                      <HeadingChart />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/admin-client"
                  element={
                    <PrivateRoute>
                      <AdminClient />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-queues"
                  element={
                    <PrivateRoute>
                      <AdminQueue />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/tax-filing"
                  element={
                    <PrivateRoute>
                      <TaxFillingListing />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-chat"
                  element={
                    <PrivateRoute>
                      <ChatComp />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-messages"
                  element={
                    <PrivateRoute>
                      <AdminMessages />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-compose"
                  element={
                    <PrivateRoute>
                      <AdminCompose />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-profile"
                  element={
                    <PrivateRoute>
                      <AdminProfile />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/settings/admin-change-password"
                  element={
                    <PrivateRoute>
                      <AdminChangePassword />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/admin-support"
                  element={
                    <PrivateRoute>
                      <AdminSupportListing />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-support-form"
                  element={
                    <PrivateRoute>
                      <AdminSupportComp />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/task"
                  element={
                    <PrivateRoute>
                      <AssignTaskPopup />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin-add-new-task"
                  element={
                    <PrivateRoute>
                      <AddNewTask />
                    </PrivateRoute>
                  }
                />
                {/* here are some changings */}
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </PageLoaderProvider>
      </ProfileProvider>
    </>
  );
}

export default App;
