import { Snackbar, Alert  } from "@mui/material"

export const Toaster = ({open = false, position = 'top', type = 'success', variant="filled", content = '', handleClose}) => {
  return (
    <Snackbar open={open} autoHideDuration={2000} anchorOrigin={{ horizontal: 'center', vertical: "top"}} onClose={handleClose}>
        <Alert severity={type} variant={variant} sx={{ width: "100%"}} onClose={handleClose}>
            {content}
        </Alert>
    </Snackbar>
  )
}
