import React, { useEffect, useMemo, useState } from "react";
import CircularChart from "./CircularChart";
import {
  CardsContainer,
  Dot,
  DotBox,
  DotsWrapper,
  ReturnStatsContainer,
  ReturnStatsWrapper,
  TaxSelect,
} from "./Dashboard.style";
import {
  HeadingFive,
  Paragraph,
} from "../../reusable/typography/Typography.style";

const StatusSummery = ({ userData, staff }) => {
  const [showMultiValue, setShowMultiValue] = useState(false);
  const taxReturnStats  = userData?.statusSummary;
  const [singleChartValue, setSingleChartValue] = useState(null);
  const adminStaffData = useMemo(
    () => [
      { value: taxReturnStats.taxReturnCompleted || 0 },
      { value: taxReturnStats.accountantReview || 0 },
      { value: taxReturnStats.dataEntry || 0 },
      { value: taxReturnStats.dataValidation || 0 },
      { value: taxReturnStats.taxReturnRejected || 0 },
    ],
    [taxReturnStats]
  );
  const staffChartData = useMemo(
    () => [
      { value: taxReturnStats.staffReview || 0 },
      { value: taxReturnStats.dataEntry || 0 },
      { value: taxReturnStats.dataValidation || 0 },
      { value: taxReturnStats.rejected || 0 },
    ],
    [taxReturnStats]
  );
  const pieChartData=staff?staffChartData:adminStaffData
  const adminData = [
    {
      text: "Tax Return Completed",
      color: "#00ADEF",
      value: taxReturnStats.taxReturnCompleted,
    },
    {
      text: "Accountant Review",
      color: "#9BDFC4",
      value: taxReturnStats.accountantReview,
    },
    {
      text: "Data Entry",
      color: "#F99BAB",
      value: taxReturnStats.dataEntry,
    },
    {
      text: "Data Validation",
      color: "#FFB44F",
      value: taxReturnStats.dataValidation,
    },
    {
      text: "Rejected",
      color: "#9F97F7",
      value: taxReturnStats.taxReturnRejected,
    },
    ]  
    const staffData = [
      {
        text: "Staff Review",
        color: "#00ADEF",
        value: taxReturnStats.staffReview,
      },
      {
        text: "Data Entry",
        color: "#F99BAB",
        value: taxReturnStats.dataEntry,
      },
      {
        text: "Data Validation",
        color: "#FFB44F",
        value: taxReturnStats.dataValidation,
      },
      {
        text: "Rejected",
        color: "#9F97F7",
        value: taxReturnStats.rejected,
      },
      ]  

      const finalData =staff?staffData:adminData
  useEffect(() => {
    let count = 0;
    for (let i = 0; i < pieChartData.length; i++) {
      if (pieChartData[i].value > 0) {
        count++;
      }
      if (count === 2) {
        setShowMultiValue(true);
      } else {
        const maxChartData = pieChartData.reduce((acc, curr) => {
          return curr.value > acc.value ? curr : acc;
        });
        setSingleChartValue(maxChartData.value > 0 ? maxChartData.value : null);
      }
    }
  }, [pieChartData]);

  return (
    <ReturnStatsContainer>
      <ReturnStatsWrapper>
        
        <CardsContainer>
        <CircularChart
          pieChartData={pieChartData}
          showMultiValue={showMultiValue}
          singleChartValue={singleChartValue}
          staff={staff}
        />
        <DotsWrapper>
          {finalData.map((itm, idx) => (
            <DotBox key={idx} style={{width:"100%"}}>
              <Dot color={itm.color} />
              <Paragraph fontSize="11px" color="#a6a8b1" style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                {itm.text} <span style={{fontSize:13, color:"black", marginLeft:"10px"}}>{itm.value}</span>
              </Paragraph>
            </DotBox>
          ))}
        </DotsWrapper>
        
        </CardsContainer>
      </ReturnStatsWrapper>
    </ReturnStatsContainer>
  );
};

export default StatusSummery;
