import Check1099Modal from "components/modals/Check1099Modal";
import ReviewFormPreview from "components/modals/RentalFormPreview";
import GeneralBusiness from "components/modals/review/ReviewConfirmation/GeneralBusiness";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import TruckDriver from "components/modals/review/ReviewConfirmation/TruckDriver";
import TermsCondition from "components/modals/review/TermsCondition";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import CustomTable from "components/reusable/customTable/CustomTable";
import TitleComponent, {
  TitleButton,
} from "components/reusable/titleComponent/TitleComponent";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";

const formattedDate = (value) => {
  if (value) {
    const date = dayjs(value);

    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  }
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const ReviewList = ({ componentRef }) => {
  const [review, setReview] = useState([]);
  const [truckId, settruckId] = useState(null);
  const [gernalId, setGernalId] = useState(null);
  const [personId, setpersonId] = useState(null);
  const [rentalId, setrentalId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab_loader, setTabLoader] = useState(true);
  const [isTermModal, setIsTermModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [generalBusinessData, setGeneralBusinessData] = useState([]);
  const [, setTruckDriverFormData] = useState([]);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [is1099Modal, setIs1099Modal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [, setPersonalCollectionData] = useState([]);
  const [rentalPropertyData, setRentalPropertyData] = useState([]);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [openPrevModal, setOpenPrevModal] = useState(false);
  const [rentalPropertyInfo, setrentalPropertyInfo] = useState({});
  const [rentalPropertyID, setrentalPropertyID] = useState();
  const [generalBusinessID, setgeneralBusinessID] = useState();
  const [personalCollactionID, setpersonalCollactionID] = useState();
  const [showTermsCondition, setShowTermsCondition] = useState(false);
  const [check1099Form, setCheck1099Form] = useState();
  const [truckdriverID, settruckdriverID] = useState();
  const initialValues = {
    signatureData: {
      fullName: "",
      initials: "",
      signatureStyle: "",
      eFileTaxReturnCheck: false,
    },
  };

  const fetchData = async () => {
    try {
      setTabLoader(true);
      const response = await apiReq(API_ROUTES.reviewPendingForms, "POST", {
        clientId: localStorage.getItem("clientId"),
      });
      let responseData = await response.data;
      const generalBusiness = responseData.data.generalBusiness || [];
      const truckDriverForms = responseData.data.truckDriverForms || [];
      const personalCollection = responseData.data.personalCollection || [];
      const rentalProperty = responseData.data.rentalProperty || [];

      setGeneralBusinessData(generalBusiness);
      setTruckDriverFormData(truckDriverForms);
      setPersonalCollectionData(personalCollection);
      setRentalPropertyData(rentalProperty);

      // Extract _id fields from generalBusiness and truckDriverForms arrays
      let generalBusinessIds = generalBusiness.map((item) => item._id);
      let truckDriverFormsIds = truckDriverForms.map((item) => item._id);
      let rentalPropertyIds = rentalProperty.map((item) => item._id);
      let PersonalCollectionIds = personalCollection.map((item) => item._id);
      setGernalId(generalBusinessIds);
      settruckId(truckDriverFormsIds);
      setpersonId(PersonalCollectionIds);
      setrentalId(rentalPropertyIds);
      let combinedArray = [
        ...personalCollection.filter((val) => val?._id),
        ...generalBusiness.filter((val) => val?._id),
        ...truckDriverForms.filter((val) => val?._id),
        ...rentalProperty.filter((val) => val?._id),
      ];
      combinedArray = combinedArray.filter((val) => val?._id);
      let rowsWithId = combinedArray.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      setReview(rowsWithId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTabLoader(false);
    }
  };

  const fetch1099Forms = async () => {
    try {
      setTabLoader(true);
      const response = await apiReq(API_ROUTES.fethc1099Forms, "POST", {
        clientId: localStorage.getItem("clientId"),
      });
      const responseData = await response.data.data;
      setCheck1099Form(responseData.check1099);
      console.log(responseData, "responseData");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTabLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetch1099Forms();
    // eslint-disable-next-line
  }, []);

  const renderTableOrMessage = () => {
    return (
      <CustomTable loading={tab_loader} rows={review} columns={ReColumns} />
    );
  };

  const handleSaveSignature = async (signatureData) => {
    try {
      const clientId = localStorage.getItem("clientId");
      if (!clientId) {
        errorToast("clientId not found!");
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("clientId", clientId);

      // Check if gernalId is available and convert to array if needed
      if (gernalId && Array.isArray(gernalId)) {
        formData.append("generalBusinessId", JSON.stringify(gernalId));
      }

      // Check if personId is available
      if (personId) {
        formData.append("personalCollectionId", JSON.stringify(personId));
      }
      if (rentalId) {
        formData.append("rentalPropertyId", [JSON.stringify(rentalId)]);
      }

      // Check if truckId is available and convert to array if needed
      if (truckId && Array.isArray(truckId)) {
        formData.append("truckDriverId", JSON.stringify(truckId));
      }

      // Append status
      formData.append("status", "Document Submitted");

      if (signatureData) {
        const { signatureFile, ...rest } = signatureData;
        formData.append("signatureData", JSON.stringify(rest));
        formData.append("signatureFile", signatureFile);
      }

      // Create API call
      await apiReq(
        API_ROUTES.SubmitAllForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );

      // peronalCollectionId
      // generalBusinessId

      setTimeout(() => {
        setIsTermModal(false);

        infoToast("Form submitted successfully");
        fetchData();
        setGeneralBusinessModal(false);
        setPersonalCollectionModal(false);
        setTruckDriverModal(false);
        setOpenPrevModal(false);
      }, 2000);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useFormik({
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await handleSaveSignature({
          fullName: values.signatureData.fullName,
          initials: values.signatureData.initials,
          signatureStyle: values.signatureData.signatureStyle,
          eFileTaxReturnCheck: values.signatureData.eFileTaxReturnCheck,
          drawCheck: values.signatureData.drawCheck,
          uploadCheck: values.signatureData.uploadCheck,
          signatureFile: values.signatureData.signatureFile,
        });
        infoToast("Form submitted successfully");
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const handleActionClick = (row) => {
    switch (row.informationType) {
      case "General Business":
        localStorage.setItem("currentBusinessRow", JSON.stringify(row));
        setgeneralBusinessID(row._id);
        setGeneralBusinessModal(true);
        break;
      case "Personal Collection":
        localStorage.setItem("personalCollectionInfo", JSON.stringify(row));
        setpersonalCollactionID(row._id);
        setPersonalCollectionModal(true);
        break;
      case "Truck Driver":
        localStorage.setItem("selectedDriverInfo", JSON.stringify(row));
        settruckdriverID(row._id);
        setTruckDriverModal(true);

        break;
      case "Rental Property":
        setrentalPropertyInfo(row.rentalPropertyId);
        setrentalPropertyID(row._id);
        setOpenPrevModal(true);

        break;
      default:
        break;
    }
  };
  const ReColumns = [
    {
      field: "informationType",
      headerName: "Information Type",
      width: 200,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          {params.row.type === "RentalProperty"
            ? formattedDate(params.row.rentalPropertyId.createdAt)
            : formattedDate(params.row.createdAt)}
        </div>
      ),
    },
    {
      field: "dataFromFile",
      headerName: "Form Type",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: params.row.dataFromFile
              ? "rgba(244, 184, 27, 0.3)"
              : "rgba(208, 150, 0, 1)",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
          }}
        >
          {params.row.dataFromFile ? "Attached" : "Manual"}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Document Submitted"
                ? "rgba(244, 184, 27, 0.3)"
                : "rgba(244, 184, 27, 0.3)",
            color:
              params.row.status === "Document Submitted"
                ? "rgba(208, 150, 0, 1)"
                : "#D09600",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
            
          }}
        >
          {params.row.status === "Review Pending" ? "Verification Needed" : params.row.status}
        </div>
      ),
    },
    {
      field: "additionalNotes",
      headerName: "Comments",
      width: 430,
      editable: false,
    },
    {
      headerName: "Action",
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        params.row.informationType && (
          <div>
            <TitleButton
              variant="text"
              color="primary"
              onClick={() => {
                console.log({ params });
                setGeneralBusinessData(params.row);
                handleActionClick(params?.row);
              }}
              style={{ padding: "0px 20px 0px 20px" }}
            >
              Review
            </TitleButton>
          </div>
        ),
    },
  ];
  const handleConfirmDocs = () => {
    if (check1099Form) {
      setIsTermModal(true);
    } else {
      setIs1099Modal(true);
    }
  };

  const closeTermModal = () => {
    setShowTermsCondition(false);
    setTimeout(() => {
      infoToast("Form submitted successfully");
      fetchData();
    }, 2000);
  };

  return (
    <>
      <BusinessListContainer>
        <TitleComponent
          text="Verify Tax Docs"
          showButton
          btntext="Submit Confirmed Docs"
          clickHandler={() => handleConfirmDocs()}
        />
        <BusinessListWrapper>{renderTableOrMessage()}</BusinessListWrapper>
      </BusinessListContainer>

      <TermsCondition
        isLoading={loading}
        isModalOpen={isTermModal}
        closeModal={() => setIsTermModal(!isTermModal)}
        openSecondModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        onSaveSignature={handleSaveSignature}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        // generalBusinessModal={generalBusinessModal}
      />
      <Check1099Modal
        isModalOpen={is1099Modal}
        setIsTermModal={setIsTermModal}
        closeModal={() => setIs1099Modal(!is1099Modal)}
      />
      <GeneralBusiness
        setIs1099Modal={setIs1099Modal}
        generalBusinessData={generalBusinessData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        check1099Form={check1099Form}
        generalBusinessID={generalBusinessID}
        reviewAndSubmition
        closeTermModal={closeTermModal}
        showTermsCondition={showTermsCondition}
        setShowTermsCondition={setShowTermsCondition}
        fetchData={fetchData}
      />
      <PersonalCollection
        setIs1099Modal={setIs1099Modal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
        personalCollactionID={personalCollactionID}
        check1099Form={true}
        setIsTermModal={setIsTermModal}
        reviewAndSubmition
        closeTermModal={closeTermModal}
        showTermsCondition={showTermsCondition}
        setShowTermsCondition={setShowTermsCondition}
        fetchData={fetchData}
      />
      <TruckDriver
        truckDriverModal={truckDriverModal}
        setIs1099Modal={setIs1099Modal}
        setTruckDriverModal={setTruckDriverModal}
        truckdriverID={truckdriverID}
        check1099Form={check1099Form}
        reviewAndSubmition
        closeTermModal={closeTermModal}
        showTermsCondition={showTermsCondition}
        setShowTermsCondition={setShowTermsCondition}
        fetchData={fetchData}
      />
      <ReviewFormPreview
        // componentRef={com}
        is1099Modal={is1099Modal}
        setIs1099Modal={setIs1099Modal}
        openPrevModal={openPrevModal}
        setOpenPrevModal={setOpenPrevModal}
        values={rentalPropertyInfo}
        reviewAndSubmition
        rentalPropertyID={rentalPropertyID}
        check1099Form={check1099Form}
        closeTermModal={closeTermModal}
        showTermsCondition={showTermsCondition}
        setShowTermsCondition={setShowTermsCondition}
        fetchData={fetchData}
      />
    </>
  );
};

export default ReviewList;

const BusinessListContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
