import React, { useEffect, useState } from "react";

const PdfPartTwo = ({ formData, handleInputChange, handleSubmit  }) => {
  useEffect(() => {
    const inputElement = document.getElementById("input-field");

    const handleInput = (event) => {
      const inputValue = event.target.value;
      if (inputValue === "00000" || inputValue.length > 5) {
        event.target.value = inputValue.slice(0, -1);
      }
    };

    if (inputElement) {
      inputElement.addEventListener("input", handleInput);

      return () => {
        inputElement.removeEventListener("input", handleInput);
      };
    }
  }, []);

 
  const [checkboxValues, setCheckboxValues] = useState({
    taxPayerPinCheck: false,
    pinAsSignatureCheck: false,
    spouseTaxPayerPinCheck: false,
    spousePinAsSignatureCheck: false,
});

useEffect(() => {
    handleInputChange({
        target: {
            name: "taxPayerPinCheck",
            value: checkboxValues.taxPayerPinCheck,
            type: "checkbox",
            checked: checkboxValues.taxPayerPinCheck,
        },
    });
    handleInputChange({
        target: {
            name: "pinAsSignatureCheck",
            value: checkboxValues.pinAsSignatureCheck,
            type: "checkbox",
            checked: checkboxValues.pinAsSignatureCheck,
        },
    });
    handleInputChange({
        target: {
            name: "spouseTaxPayerPinCheck",
            value: checkboxValues.spouseTaxPayerPinCheck,
            type: "checkbox",
            checked: checkboxValues.spouseTaxPayerPinCheck,
        },
    });
    handleInputChange({
        target: {
            name: "spousePinAsSignatureCheck",
            value: checkboxValues.spousePinAsSignatureCheck,
            type: "checkbox",
            checked: checkboxValues.spousePinAsSignatureCheck,
        },
    });
}, [checkboxValues, handleInputChange]);



const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevState) => ({
        ...prevState,
        [name]: checked,
    }));
};

  return (
    <div>
      <div style={{ width: "auto" }}>
        <div
          style={{
            height: "23px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              height: "22px",
              width: "5%",
              background: "black",
              color: "white",
              textAlign: "center",
              fontFamily:'inter'
            }}
          >
            Part II
          </div>
          <div style={{ width: "94%", paddingLeft: "30px", fontSize: "16px"  ,fontFamily:'inter'}}>
            Taxpayer Declaration and Signature Authorization (Be sure you get
            and keep a copy of your return)
          </div>
        </div>
        <p
          style={{
            fontSize: "14px",
            textJustify: "auto",
            textAlign: "justify",
            fontFamily:'inter'
          }}
        >
          Under penalties of perjury, I declare that I have examined a copy of
          the income tax return (original or amended) I am now authorizing, and
          to the best of my knowledge and belief, it is true, correct, and
          complete. I further declare that the amounts in Part I above are the
          amounts from the income tax return (original or amended) I am now
          authorizing. I consent to allow my intermediate service provider,
          transmitter, or electronic return originator (ERO) to send my return
          to the IRS and to receive from the IRS (a) an acknowledgement of
          receipt or reason for rejection of the transmission, (b) the reason
          for any delay in processing the return or refund, and (c) the date of
          any refund. If applicable, I authorize the U.S. Treasury and its
          designated Financial Agent to initiate an ACH electronic funds
          withdrawal (direct debit) entry to the financial institution account
          indicated in the tax preparation software for payment of my federal
          taxes owed on this return and/or a payment of estimated tax, and the
          financial institution to debit the entry to this account. This
          authorization is to remain in full force and effect until I notify the
          U.S. Treasury Financial Agent to terminate the authorization. To
          revoke (cancel) a payment, I must contact the U.S. Treasury Financial
          Agent at 1-888-353-4537. Payment cancellation requests must be
          received no later than 2 business days prior to the payment
          (settlement) date. I also authorize the financial institutions
          involved in the processing of the electronic payment of taxes to
          receive confidential information necessary to answer inquiries and
          resolve issues related to the payment. I further acknowledge that the
          personal identification number (PIN) below is my signature for the
          income tax return (original or amended) I am now authorizing and, if
          applicable, my Electronic Funds Withdrawal Consent
        </p>
        <p style={{ fontSize: "14px" ,fontFamily:'inter' }}>Taxpayer’s PIN: check one box only</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="checkbox" id="taxPayerPinCheck"
                name="taxPayerPinCheck"
                checked={checkboxValues.taxPayerPinCheck}
                onChange={handleCheckboxChange} />
          <label htmlFor="vehicle1" style={{ paddingLeft: "20px" ,fontFamily:'inter' }}>
            {" "}
            I authorize{" "}
          </label>
          <input
            style={{
              width: "43%",
              padding: " 0px 20px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="authorizeField"
            name="authorizeField"
            // value={formData.form8879.authorizeField}
            onChange={handleInputChange}
          ></input>
          <p style={{fontSize:'14px' ,fontFamily:'inter'}}>to enter or generate my PIN </p>
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
              marginLeft: "10px",
            }}
            id="myPin"
            name="myPin"
            // value={formData.form8879.myPin}
            onChange={handleInputChange}
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="myPin"
            name="myPin"
            // value={formData.form8879.myPin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="myPin"
            name="myPin"
            // value={formData.form8879.myPin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="myPin"
            name="myPin"
            // value={formData.form8879.myPin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
              marginRight:'10px'
            }}
            id="myPin"
            name="myPin"
            // value={formData.form8879.myPin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <p style={{fontSize:'14px' ,fontFamily:'inter'}}>as my</p>
        </div>

        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>signature on the income tax return (original or amended) I am now authorizing.</p>

        <input type="checkbox" id="pinAsSignatureCheck"
                name="pinAsSignatureCheck"
                checked={checkboxValues.pinAsSignatureCheck}
                onChange={handleCheckboxChange}  />
          <label htmlFor="pinAsSignatureCheck" style={{ paddingLeft: "20px", fontSize:'16px',fontFamily:'inter' }}>
            {" "}
            I will enter my PIN as my signature on the income tax return (original or amended) I am now authorizing. Check this box only if you are entering your own PIN and your return is filed using the Practitioner PIN method. The ERO must complete Part III below
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>Your signature </p>
          <input
            style={{
              width: "35%",
              padding: " 0px 20px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "1px solid rgb(8, 8, 8)",
              outline: "none",
              marginRight:'20px'
            }}
            id="signature"
            name="signature"
            // value={formData.form8879.signature}
            onChange={handleInputChange}
          ></input>
          <p style={{fontSize:'14px' ,fontFamily:'inter'}}>Date </p>
          <input
            style={{
              width: "40%",
              padding: " 0px 20px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="date"
            name="date"
            // value={formData.form8879.date}
            onChange={handleInputChange}
          ></input>
         
</div>
<p style={{fontSize:'14px' ,fontFamily:'inter'}}>Spouseâ€™s PIN: check one box only</p>
<div style={{ display: "flex", alignItems: "center" }}>
          <input type="checkbox" id="spouseTaxPayerPinCheck"
                name="spouseTaxPayerPinCheck"
                checked={checkboxValues.spouseTaxPayerPinCheck}
                onChange={handleCheckboxChange} value="spouseTaxPayerPinCheck" />
          <label htmlFor="spouseTaxPayerPinCheck" style={{ paddingLeft: "20px",fontSize:'14px' ,fontFamily:'inter' }}>
            {" "}
            I authorize{" "}
          </label>
          <input
            style={{
              width: "43%",
              padding: " 0px 20px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="spouseAuthorizeField"
            name="spouseAuthorizeField"
            // value={formData.form8879.spouseAuthorizeField}
            onChange={handleInputChange}
          ></input>
          <p style={{fontSize:'14px' ,fontFamily:'inter'}}>to enter or generate my PIN </p>
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
              marginLeft: "10px",
            }}
            id="spousePin"
            name="spousePin"
            // value={formData.form8879.spousePin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="spousePin"
            name="spousePin"
            // value={formData.form8879.spousePin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="spousePin"
            name="spousePin"
            // value={formData.form8879.spousePin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            id="spousePin"
            name="spousePin"
            // value={formData.form8879.spousePin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <input
            style={{
              width: "2%",
              padding: " 0px 5px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "1px solid rgb(8, 8, 8)",
              outline: "none",
              marginRight:'10px'
            }}
            id="spousePin"
            name="spousePin"
            // value={formData.form8879.spousePin}
            onChange={handleInputChange}
            maxLength="1"
          />
          <p style={{fontSize:'14px' ,fontFamily:'inter'}}>as my</p>
        </div>

        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>signature on the income tax return (original or amended) I am now authorizing.</p>

        <input type="checkbox" id="spousePinAsSignatureCheck"
                name="spousePinAsSignatureCheck"
                checked={checkboxValues.spousePinAsSignatureCheck}
                onChange={handleCheckboxChange} value="spousePinAsSignatureCheck" />
          <label htmlFor="spousePinAsSignatureCheck" style={{ paddingLeft: "20px", fontSize:'16px',fontFamily:'inter' }}>
            {" "}
            I will enter my PIN as my signature on the income tax return (original or amended) I am now authorizing. Check this box only if you are entering your own PIN and your return is filed using the Practitioner PIN method. The ERO must complete Part III below
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>Your signature </p>
          <input
            style={{
              width: "35%",
              padding: " 0px 20px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "1px solid rgb(8, 8, 8)",
              outline: "none",
              marginRight:'20px'
            }}
            id="spouseSignature"
            name="spouseSignature"
            // value={formData.form8879.spouseSignature}
            onChange={handleInputChange}
          ></input>
          <p style={{fontSize:'14px' ,fontFamily:'inter'}}>Date </p>
          <input
            style={{
              width: "40%",
              padding: " 0px 20px",
              margin: "0px 0px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "1px solid rgb(8, 8, 8)",
              outline: "none",
            }}
            
            id="spouseDate"
            name="spouseDate"
            // value={formData.form8879.spouseDate}
            onChange={handleInputChange}
          ></input>
         
</div>
<div style={{height:'23px', width:'100%',borderTop:'1px solid black',borderBottom:'1px solid black',display:'flex',justifyContent:'center'}}>
        {/* <div style={{height:'19px', width:'8%',background:'black', color:'white',textAlign:'center'}}>Part 1</div> */}
        <div style={{paddingLeft:'10px',fontSize:'16px',fontFamily:'inter'}}>Practitioner PIN Method Returns Onlyâ€”continue below</div>
        {/* <div style={{width:'40%' ,fontSize:'14px'}}>(Enter year you are authorizing.)</div> */}
        </div>
      </div>

    </div>
  );
};

export default PdfPartTwo;
