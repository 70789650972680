import LinearProgress from "@mui/material/LinearProgress";
import TablePercentage from "components/client/dashboard/TablePercentage";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import edit from "assets/client/edit.png";
import detail from "assets/client/detail.png";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";
import { IconStyledButton } from "components/reusable/button/Button.style";
import icon1 from "assets/StaffDashboardIcons/icon1.png";
import icon2 from "assets/StaffDashboardIcons/icon2.png";
import icon3 from "assets/StaffDashboardIcons/icon3.png";
import icon4 from "assets/StaffDashboardIcons/icon4.png";

export const pieChartColors = ["#00ADEF", "#C0C0C0", "#0B2558"];

export const dashboardCard = [
  {
    title: "Tax Info Submitted",
    value: "",
  },
  {
    title: "Tax Doc Uploaded",
    value: "",
  },
  {
    title: "Confirmation Needed",
    value: "",
  },
  {
    title: "Tax Return",
    value: "",
  },
];
export const dashboardCard2 = [
  {
    icon: <img src={icon1} />,
    number: 26,
    key: 'paidClients',
    title: "Paid Clients",
    value: "",
  },
  {
    icon: <img src={icon2} />,
    number: 80,
    key: 'unPaidClients',
    title: "Unpaid Clients",
    value: "",
  },
  {
    icon: <img src={icon3} />,
    number: 54,
    key: 'taxFillingCompleted',
    title: "Tax Filling Completed",
    value: "",
  },
  {
    icon: <img src={icon4} />,
    number: 155,
    key: 'totalClients',
    title: "Total Clients",
    value: "",
  },
  {
    icon: <img src={icon4} />,
    number: 155,
    key: 'totalStaff',
    title: "Total Staff",
    value: "",
  },
];
export const dashboardTaxReturnStatus = [
  {
    text: "Review Needed",
    color: "#C0C0C0",
  },
  {
    text: "Tax Info Submitted",
    color: "#0B2558",
  },
  {
    text: "Tax Return Completed",
    color: "#00ADEF",
  },
];

export const dashboardPieChart = [{ value: 55 }, { value: 25 }, { value: 15 }];

export const dashboardTable = [
  {
    srno: "01",
    name: "Personal Collection",
    completion: <LinearProgress variant='determinate' value={45} />,
    percentage: <TablePercentage value='45%' />,
  },
  {
    srno: "02",
    name: "General Business",
    completion: <LinearProgress variant='determinate' value={29} />,
    percentage: <TablePercentage value='29%' />,
  },
  {
    srno: "03",
    name: "Rental Property",
    completion: <LinearProgress variant='determinate' value={25} />,
    percentage: <TablePercentage value='25%' />,
  },
  {
    srno: "04",
    name: "Truck Driver",
    completion: <LinearProgress variant='determinate' value={18} />,
    percentage: <TablePercentage value='18%' />,
  },
];

export const pcTabData = [
  "Pre Sheet",
  "Retirements",
  "Health Care",
  "Adjustments",
  "Itemized Deductions",
];

export const pcFieldsTop = [
  {
    label: "First Name *",
    placeholder: "Enter",
    defaultValue: "",
    id: "firstName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Middle Name ",
    placeholder: "Enter",
    defaultValue: "",
    id: "middleName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Last Name *",
    placeholder: "Enter",
    defaultValue: "",
    id: "lastName",
    size: "size",
    width: "32%",
    value: "",
  }
];

export const pcFieldsCenter = [
  {
    label: " Mobile Number *",
    placeholder: "Enter",
    defaultValue: "",
    id: "phone",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const pcFieldsBottom = [
  {
    label: "Email Address *",
    placeholder: "Enter",
    defaultValue: "",
    id: "email",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: " DRVERS LICENSE # *",
    placeholder: "dl",
    defaultValue: "",
    id: "driverLicense",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const pcFillingStatus = [
  "Single",
  "Married Filling Jointly",
  "Married Filling Separately",
  "Head of Household",
];

export const npFillingStatus = [
  "Charitable",
  "Religious",
  "Educational",
  "Scientific",
  "Literary",
  "PublicSafety",
];

export const pcAdjustment = [
  {
    label: "IRA Contributions ",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Teacher Expense ",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Student Loan Interest ",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Estimated Taxes ",
    placeholder: "Enter",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "49%",
    value: "",
  },
];

export const pcAccordion = [
  {
    title: "Medical/Dental",
    id: "medical",
    fields: [
      {
        label: "Medical Ins ",
        placeholder: "Enter",
        defaultValue: "",
        key: "medicalIns",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Health Ins (Cost) ",
        placeholder: "Enter",
        defaultValue: "",
        key: "medicalExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Prescription Cost ",
        placeholder: "Enter",
        defaultValue: "",
        key: "prescriptionCost",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Dental Ins ",
        placeholder: "Enter",
        defaultValue: "",
        key: "dentalIns",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Dental Ins (Cost) ",
        placeholder: "Enter",
        defaultValue: "",
        key: "dentalExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },

  {
    title: "Taxes & Interest",
    id: "taxesAndInterest",
    fields: [
      {
        label: "Interest (Mortgages) ",
        placeholder: "Enter",
        defaultValue: "",
        key: "interest",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Real Estate Taxes ",
        placeholder: "Enter",
        defaultValue: "",
        key: "realEstateTaxes",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Boat/Motorcycle ",
        placeholder: "Enter",
        defaultValue: "",
        key: "boatMotorcycle",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "PMI ",
        placeholder: "Enter",
        defaultValue: "",
        key: "pmi",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Auto/Truck Tax ",
        placeholder: "Enter",
        defaultValue: "",
        key: "autoTruckTax",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Yr/Make/Model",
        placeholder: "Enter",
        defaultValue: "",
        key: "model",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },
  {
    title: "Gifts to Charity",
    id: "giftsToCharity",
    fields: [
      {
        label: "Cash ",
        placeholder: "Enter",
        defaultValue: "",
        key: "cash",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Non-Cash ",
        placeholder: "Enter",
        defaultValue: "",
        key: "nonCash",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },
  {
    title: "Military Expense",
    id: "militaryExpense",
    fields: [
      {
        label: "Supplies ",
        placeholder: "Enter",
        defaultValue: "",
        key: "supplies",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Hotel Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key: "hotelExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Office Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key: "officeExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Travel Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key: "travelExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Meal & Entertainment ",
        placeholder: "Enter",
        defaultValue: "",
        key: "mealAndEntertainment",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Vehicle Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key: "vehicleExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Moving Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key: "movingExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Supplies/Equipment ",
        placeholder: "Enter",
        defaultValue: "",
        key: "equipment",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Grooming ",
        placeholder: "Enter",
        defaultValue: "",
        key: "grooming",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Uniforms/Cleaners ",
        placeholder: "Enter",
        defaultValue: "",
        key: "uniformsCleaners",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Fees ",
        placeholder: "Enter ",
        defaultValue: "",
        key: "fees",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Mileage/Commuting ",
        placeholder: "Enter",
        defaultValue: "",
        key: "milleageCommuting",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Business ",
        placeholder: "Enter",
        defaultValue: "",
        key: "business",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Personal ",
        placeholder: "Enter",
        defaultValue: "",
        key: "personal",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },
  {
    title: "Miscellaneous Deduction",
    id: "miscellaneousDeduction",
    fields: [
      {
        label: "Prior Year Tax Preparation Fees ",
        placeholder: "Enter",
        defaultValue: "",
        key:"priorYearTaxPreparationFees",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Casualty Loss ",
        placeholder: "Enter",
        defaultValue: "",
        key:"casualtyLoss",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Legal Fees ",
        placeholder: "Enter",
        defaultValue: "",
        key:"legalFees",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Alimony Paid ",
        placeholder: "Enter",
        defaultValue: "",
        key:"alimonyPaid",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Professional/Assoc Dues ",
        placeholder: "Enter",
        defaultValue: "",
        key:"AssocDues",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Energy Credit Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key:"energyCreditExpense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },

  // {
  //   title: "Note",
  //   fields: [],
  // },
];

export const daycarefields = [
  {
    title: "Daycare",
    id: "dayCare",
    fields: [
      {
        label: "Daycare Expense ",
        placeholder: "Enter",
        defaultValue: "",
        key:"expense",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Daycare Tax ID# ",
        placeholder: "Enter",
        defaultValue: "",
        key:"taxId",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Daycare Name ",
        placeholder: "Enter",
        defaultValue: "",
        key:"name",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },
];
export const pcAlternateCheckbox = ["Work", "Home", "Spouse", "Other"];

export const pcIRSCheckbox = ["Yes", "No"];

export const BGColumns = [
  
  {
    field: "srno",
    headerName: "Sr#.",
    width: 150,
    editable: false,
  },
  {
    field: "bname",
    headerName: "Business Name",
    width: 300,
    editable: false,
  },
  {
    field: "date",
    headerName: "Date",
    width: 200,
    editable: false,
  },
  {
    field: "ftype",
    headerName: "Form Type",
    width: 250,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div
        style={{
          backgroundColor:
            params.row.status === "Data Entery"
              ? "rgba(0, 173, 239, 0.16)"
              : "rgba(244, 184, 27, 0.3)",
          color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
          borderRadius: "15px",
          padding: "5px",
        }}
      >
        {params.row.status}
      </div>
    ),
  },
  {
    field: "comments",
    headerName: "Comments",
    width: 200,
    editable: false,
  },
  {
    // field: "comments",
    headerName: "Action",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div>
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            handleActionClick(params.row.id);
          }}
        >
          <img src={edit} alt='edit' />
        </Button>
        <Button
          variant='text'
          color='primary'
          onClick={() => handleActionClick(params.row.id)}
        >
          <img src={detail} alt='detail' />
        </Button>
      </div>
    ),
  },
];

const handleActionClick = (orderId) => {
  // window.location.href = `//${orderId}`;
};

export const BGRows = [
  {
    id: 1,
    srno: 1,
    bname: "Furniture",
    date: "December 08, 2023",
    comments: "Lorem ipsum",
    ftype: "Manual",
    status: "Completed",
  },
  {
    id: 2,
    srno: 1,
    bname: "Furniture",
    date: "December 08, 2023",
    comments: "Lorem ipsum",
    ftype: "Manual",
    status: "Rejected",
  },
];
export const REVIEWBGRows = [
  {
    id: 1,
    srno: 1,
    bname: "Jublie Showroom ",
    infotype: "Truck Driver Collection",
    date: "December 08, 2023",
    comments: "Lorem ipsum",
    ftype: "Manual",
    status: "Staff Review",
  },
  {
    id: 2,
    srno: 1,
    bname: "Danish Mehmood",
    infotype: "Truck Driver Collection",
    date: "December 08, 2023",
    comments: "Lorem ipsum",
    ftype: "Manual",
    status: "Staff Review",
  },
];
export const BGTaxRows = [
  {
    id: 1,
    srno: 1,
    bname: "Furniture",
    date: "December 08, 2023",
    comments: "Lorem ipsum",
    ftype: "Manual",
    status: "Submitted",
  },
  {
    id: 2,
    srno: 1,
    bname: "Furniture",
    date: "December 08, 2023",
    comments: "Lorem ipsum",
    ftype: "Manual",
    status: "Submitted",
  },
];
export const TdTabData = [
  "Basic Info",
  "Administrative Fees",
  "Maintenance",
  "Labor / Taxes",
  "Insurance / Interest",
  "Road Expences",
  "Prof. Fees / Fuel / Office Exp",
  "Rent & Lease / Utilities",
];

export const TdFieldsTop = [
  {

    label: "First name *",
    placeholder: "Enter Owner Fisrt Name",
    defaultValue: "",
    key: "firstName",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Middle name *",
    placeholder: "Enter Owner Middle Name",
    defaultValue: "",
    key: "middleName",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Last name *",
    placeholder: "Enter Owner Last Name",
    defaultValue: "",
    key: "lastName",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "EIN *",
    placeholder: "Enter",
    defaultValue: "",
    key: "socialSecurity",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const tdFieldsBottom = [
  {
    label: "Email *",
    placeholder: "Enter",
    defaultValue: "",
    key: "email",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Mobile Number *",
    placeholder: "Enter",
    defaultValue: "",
    key: "mobileNumber",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];
export const fields = [{ label: "email", placeHolder: "enter" }];

export const tdLastFields = [
  {
    label: "Business Address",
    placeholder: "Enter",
    defaultValue: "",
    key: "businessAddress",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const FeesFieldsTop = [
  {
    label: "Association Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "associationFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "ATM Charges *",
    placeholder: "$",
    defaultValue: "",
    key: "atmCharges",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Bank Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "bankFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "DOT Exam Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "dotExamFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Comcard Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "comcardFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "IRA Custodial/Investment Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "investmentFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Licensing Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "licensingFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Safe Deposit Box Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "safeDepositBoxFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Administrative Fees *",
    placeholder: "$",
    defaultValue: "",
    key: "administrativeFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Wire Fees *",
    placeholder: "$", 
    defaultValue: "",
    key: "wireFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const MFieldsTop = [
  {
    label: "Parts",
    placeholder: "$",
    defaultValue: "",
    key: "parts",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Repairs",
    placeholder: "$",
    defaultValue: "",
    key: "repairs",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Tires",
    placeholder: "$",
    defaultValue: "",
    key: "tires",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Truck Washes",
    placeholder: "$",
    defaultValue: "",
    key: "truckWashes",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Other Maintenance",
    placeholder: "$",
    defaultValue: "",
    key: "otherMaintenance",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const ExpenseFields = [
  {
    title: "Addtional Expense",
    fields: [
      {
        label: "Expense Name",
        placeholder: "Enter Name",
        defaultValue: "",
        key: "name",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Amount",
        placeholder: "$",
        defaultValue: "",
        key: "amount",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },
];

export const LaborFieldsTop = [
  {
    label: "Subcontractor *",
    placeholder: "$",
    defaultValue: "",
    key: "subContractor",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Lumpers *",
    placeholder: "$",
    defaultValue: "",
    key: "lumpers",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const TaxFieldsTop = [
  {
    label: "Ad Valorem *",
    placeholder: "$",
    defaultValue: "",
    key: "adValorem",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Fuel Tax *",
    placeholder: "$",
    defaultValue: "",
    key: "fuelTax",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Heavy Use Tax *",
    placeholder: "$",
    defaultValue: "",
    key: "heavyUseTax",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "License Tax *",
    placeholder: "$",
    defaultValue: "",
    key: "licenseTax",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Other Tax *",
    placeholder: "$",
    defaultValue: "",
    key: "otherTax",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Permits *",
    placeholder: "$",
    defaultValue: "",
    key: "permits",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Registration *",
    placeholder: "$",
    defaultValue: "",
    key: "registration",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const InsuranceFieldsTop = [
  {
    label: "Bobtail Insurance *",
    placeholder: "$",
    defaultValue: "",
    key: "bobtailInsurance",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "PI & PD Insurance *",
    placeholder: "$",
    defaultValue: "",
    key: "piAndPdInsurance",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Truck Insurance *",
    placeholder: "$",
    defaultValue: "",
    key: "truckInsurance",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Workman’s Compensation *",
    placeholder: "$",
    defaultValue: "",
    key: "insuranceWorkmanCompensation",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "ACC/CC Insurance  *",
    placeholder: "$",
    defaultValue: "",
    key: "accInsurance",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Other Insurance  *",
    placeholder: "$",
    defaultValue: "",
    key: "otherInsurance",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const InterestFieldsTop = [
  {
    label: "Interest on Advances *",
    placeholder: "$",
    defaultValue: "",
    key: "interestOnAdvances",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Interest on Credit Cards Bus  *",
    placeholder: "$",
    defaultValue: "",
    key: "interestCreditCardsBus",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Interest on Truck Loans *",
    placeholder: "$",
    defaultValue: "",
    key: "interestOnTruckLoans",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Workman’s Compensation *",
    placeholder: "$",
    defaultValue: "",
    key: "interestWorkmanCompensation",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Other Interest *",
    placeholder: "$",
    defaultValue: "",
    key: "otherInterest",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const RoadFieldsTop = [
  {
    label: "Hotels",
    placeholder: "$",
    defaultValue: "",
    key: "hotel",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Laundry",
    placeholder: "$",
    defaultValue: "",
    key: "laundry",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Parking",
    placeholder: "$",
    defaultValue: "",
    key: "parking",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Showers",
    placeholder: "$",
    defaultValue: "",
    key: "showers",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Tolls",
    placeholder: "$",
    defaultValue: "",
    key: "tolls",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Truck Supplies",
    placeholder: "$",
    defaultValue: "",
    key: "truckSupplies",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Weigh Fees/Scales",
    placeholder: "$",
    defaultValue: "",
    key: "weightFees",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Prepass",
    placeholder: "$",
    defaultValue: "",
    key: "prepass",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Meals",
    placeholder: "$",
    defaultValue: "",
    key: "meals",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Rental Car",
    placeholder: "$",
    defaultValue: "",
    key: "rentalCar",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Airfare",
    placeholder: "$",
    defaultValue: "",
    key: "airfare",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "No. of Days in the Roads",
    placeholder: "Days",
    defaultValue: "",
    key: "noOfDaysInRoads",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const profFieldsTop = [
  // {
  //   label: "Attorney and Accounting",
  //   placeholder: "$",
  //   defaultValue: "",
  //   key: "attorneyAndAccounting",
  //   id: "outlined-size-small",
  //   size: "size",
  //   width: "32%",
  //   value: "",
  // },
  {
    label: "Attorney and Accounting",
    placeholder: "$",
    defaultValue: "",
    key: "attorneyAndAccounting",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Tax Prep Fees",
    placeholder: "$",
    defaultValue: "",
    key: "taxPrepFee",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const OilFieldsTop = [
  {
    label: "Fuel",
    placeholder: "$",
    defaultValue: "",
    key: "fuel",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Oil",
    placeholder: "$",
    defaultValue: "",
    key: "oil",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Reefer Fuel",
    placeholder: "$",
    defaultValue: "",
    key: "reeferFuel",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const OfficeFieldsTop = [
  {
    label: "Office Expense",
    placeholder: "$",
    defaultValue: "",
    key: "officeExpense",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const RentFieldsTop = [
  {
    label: "Truck Lease",
    placeholder: "$",
    defaultValue: "",
    key: "truckLease",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Storage Lease",
    placeholder: "$",
    defaultValue: "",
    key: "storageLease",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Other",
    placeholder: "$",
    defaultValue: "",
    key: "other",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const UtilFieldsTop = [
  {
    label: " Mobile Number",
    placeholder: "$",
    defaultValue: "",
    key: "mobileNumber",
    id: "outlined-size-small",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const ReColumns = [
  // {
  //   field: "srno",
  //   headerName: "Sr#.",
  //   width: 150,
  //   editable: false,
  // },
  {
    field: "infotype",
    headerName: "Information Type",
    width: 200,
    editable: false,
  },
  {
    field: "bname",
    headerName: "Name",
    width: 250,
    editable: false,
  },
  {
    field: "date",
    headerName: "Date",
    width: 250,
    editable: false,
  },
  {
    field: "ftype",
    headerName: "Form Type",
    width: 250,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div
        style={{
          backgroundColor:
            params.row.status === "Staff Review"
              ? "rgba(244, 184, 27, 0.3)"
              : "rgba(244, 184, 27, 0.3)",
          color:
            params.row.status === "Staff Review"
              ? "rgba(208, 150, 0, 1)"
              : "rgba(208, 150, 0, 1)",
          borderRadius: "15px",
          padding: "5px 10px 5px 10px",
          fontSize: "12px",
        }}
      >
        {params.row.status}
      </div>
    ),
  },
  {
    field: "comments",
    headerName: "Comments",
    width: 200,
    editable: false,
  },
  {
    // field: "comments",
    headerName: "Action",
    width: 150,
    editable: false,
    renderCell: (params) => (
      <div>
        <TitleButton
          variant='text'
          color='primary'
          onClick={() => handleActionClick(params.row.id)}
          padding='0px 20px 0px 20px'
        >
          Review
          {/* <img src={edit} alt="edit" /> */}
        </TitleButton>
      </div>
    ),
  },
];

export const SUBColumns = [
  // {
  //   field: "srno",
  //   headerName: "Sr#.",
  //   width: 150,
  //   editable: false,
  // },
  {
    field: "bname",
    headerName: "Business Name",
    width: 300,
    editable: false,
  },
  {
    field: "date",
    headerName: "Date",
    width: 250,
    editable: false,
  },
  {
    field: "ftype",
    headerName: "Form Type",
    width: 300,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div
        style={{
          backgroundColor:
            params.row.status === "Submitted"
              ? "rgba(0, 173, 239, 0.16)"
              : "rgba(0, 173, 239, 0.16)",
          color:
            params.row.status === "Submitted"
              ? "rgba(0, 149, 206, 1)"
              : "rgba(0, 149, 206, 1)",
          borderRadius: "15px",
          padding: "5px 10px 5px 10px",
        }}
      >
        {params.row.status}
      </div>
    ),
  },
  {
    field: "comments",
    headerName: "Comments",
    width: 250,
    editable: false,
  },
  {
    // field: "comments",
    headerName: "Action",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div>
        <IconStyledButton
          height='40px'
          variant='text'
          color='white'
          fontSize='12px'
          onClick={() => handleActionClick(params.row.id)}
        >
          <DownloadIcon />
          Download
        </IconStyledButton>
      </div>
    ),
  },
];

export const TaxColumns = [
  {
    field: "bname",
    headerName: "Business Name",
    width: 300,
    editable: false,
  },
  {
    field: "date",
    headerName: "Date",
    width: 250,
    editable: false,
  },
  {
    field: "ftype",
    headerName: "Form Type",
    width: 250,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    editable: false,
    renderCell: (params) => (
      <div
        style={{
          backgroundColor:
            params.row.status === "Completed"
              ? "rgba(85, 153, 52, 0.16)"
              : "rgba(192, 39, 39, 0.16)",
          color:
            params.row.status === "Completed"
              ? "rgba(53, 119, 20, 1)"
              : "rgba(192, 39, 39, 1)",
          borderRadius: "15px",
          padding: "5px 10px 5px 10px",
          fontSize: "12px",
        }}
      >
        {params.row.status}
      </div>
    ),
  },
  {
    field: "comments",
    headerName: "Notes",
    width: 250,
    editable: false,
  },
  {
    // field: "comments",
    headerName: "Action",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div>
        <IconStyledButton
          height='40px'
          variant='text'
          color='white'
          fontSize='12px'
          onClick={() => handleActionClick(params.row.id)}
        >
          <DownloadIcon />
          Download
        </IconStyledButton>
      </div>
    ),
  },
];

export const BIFields = [
  {
    label: "Non-Profit Name",
    placeholder: "Enter",
    defaultValue: "",
    id: "nonProfitName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "EIN (If applicable)",
    placeholder: "Enter",
    defaultValue: "",
    id: "ein",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const PTCFields = [
  {
    label: "First Name *",
    placeholder: "Enter",
    defaultValue: "",
    id: "firstName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Middle Name",
    placeholder: "Enter",
    defaultValue: "",
    id: "middleName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Last Name *",
    placeholder: "Enter",
    defaultValue: "",
    id: "lastName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Title *",
    placeholder: "Enter",
    defaultValue: "",
    id: "title",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Mobile Number *",
    placeholder: "Enter",
    defaultValue: "",
    id: "mobileNumber",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const OBMFields = [
  {
    label: "First Name *",
    placeholder: "Enter First name",
    defaultValue: "",
    id: "firstName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Middle Name",
    placeholder: "Enter Middle Name",
    defaultValue: "",
    id: "middleName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Last Name *",
    placeholder: "Enter Last Name",
    defaultValue: "",
    id: "lastName",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Title *",
    placeholder: "Enter",
    defaultValue: "",
    id: "title",
    size: "size",
    width: "32%",
    value: "",
  },
];

// export const ExplainFields = [
//   {
//     label: "Explain",
//     placeholder: "Enter",
//     defaultValue: "",
//     id: "explain",
//     size: "size",
//     width: "100%",
//     height: "120px",
//     value: "",
//   },
// ];

export const partnerMultiMemberFields = [
  {
    label: "Name",
    placeHolder: "Enter Name",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "47%",
    value: "",
    name:"name"
  },
];

//for form 1099
export const businessInfo1 = [
  {
    title: "Business Information (Vender#1) ",
    fields: [
      {
        label: "Dental Expense",
        placeholder: "Enter",
        defaultValue: "",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Health Ins (Post Tax)",
        placeholder: "Enter",
        defaultValue: "",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Prescription Cost",
        placeholder: "Enter",
        defaultValue: "",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Medical Expense",
        placeholder: "Enter",
        defaultValue: "",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Dental Ins (Post Tax)",
        placeholder: "Enter",
        defaultValue: "",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ],
  },
];
