import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { FaFileExcel } from "react-icons/fa";
import { db } from "services/firebaseSetup";
import styled from "styled-components";
import Searchbar from "../../layout/navbar/Searchbar";
import { ButtonIcon } from "../button/IconButton";
import FlexComp from "../flex/Flex";
import SelectWithCheckbox from "../select/Select";

const StaffHeader = ({
  text,
  showButton,
  showSecondButton,
  showThirdButton,
  btntext,
  btntext1,
  btntext2,
  placeholder,
  placeholder2,
  clickHandler,
  showFirstSelect,
  showSecondSelect,
  onSelectChange,
  options,
  options2,
  icon,
  clickHandler1,
  clickHandler2,
  bgColor = "rgba(239, 243, 253, 1)",
  searchUsers,
  onChange = () => {},
  // searchStaff,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues1, setSelectedValues1] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [username, setUsername] = useState("");
  // console.log("searchStaff prop:", searchStaff);
  const handleSearchChange = async (event) => {
    onChange?.(event);
    console.log("value", event.target.value);
    // searchStaff(event?.target?.value);
    // Updated search handler

    const value = event.target.value;
    setSearchTerm(value);
    onChange?.(event);

    // Call the search function passed from parent
    if (searchUsers) {
      searchUsers(value);
    }

    if (event.target.value.trim() !== "") {
      const q = query(
        collection(db, "users"),
        where("displayName", ">=", event.target.value),
        where("displayName", "<=", event.target.value + "\uf8ff")
      );

      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          console.log("search user data", doc.data());
          // searchStaff(doc.data());
        });
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    } else {
      console.log("Value is empty, skipping query execution.");
    }
  };

  const handleSearch = async () => {
    const q = query(
      collection(db, "users"),
      where("displayName", ">=", username),
      where("displayName", "<=", username + "\uf8ff")
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log("search user data", doc.data());
        // searchStaff(doc.data());
      });
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    onSelectChange(event.target.value, "status");
  };

  const handleChange1 = (event) => {
    setSelectedValues1(event.target.value);
    onSelectChange(event.target.value, "tag");
  };

  return (
    <TitleDesignMainDiv>
      <TitleDesignDiv>
        <Searchbar
          color="00#AEB6CF"
          backgroundColor={bgColor}
          placeholder="search by Name"
          value={username}
          onKeyDown={handleKey}
          onChange={handleSearchChange}
        />

        {showFirstSelect && (
          <SelectWithCheckbox
            label="Select Multiple Options"
            id="select-multiple-options-1"
            width={230}
            menuItems={{
              placeholder: placeholder,
              items: options,
            }}
            selectedValues={selectedValues}
            handleChange={handleChange}
          />
        )}

        {showSecondSelect && (
          <SelectWithCheckbox
            label="Select Multiple Options"
            id="select-multiple-options-2"
            width={230}
            menuItems={{
              placeholder: placeholder2 || placeholder,
              items: options2,
            }}
            selectedValues={selectedValues1}
            handleChange={handleChange1}
          />
        )}
      </TitleDesignDiv>

      <FlexComp width="auto" justifyContent=" flex-end">
        {showSecondButton && (
          <ButtonIcon
            clickHandler={clickHandler1}
            text={btntext1}
            fontSize="16px"
            icon={<PictureAsPdfIcon />}
          />
        )}
        {showThirdButton && (
          <ButtonIcon
            clickHandler={clickHandler2}
            text={btntext2}
            fontSize="16px"
            icon={<FaFileExcel />}
          />
        )}
      </FlexComp>
      {showButton && (
        <TitleButton onClick={clickHandler}>{btntext}</TitleButton>
      )}
    </TitleDesignMainDiv>
  );
};

export const HeaderDesign = styled.div`
  width: auto;
  height: auto;
  border-radius: 4px;
  background: rgba(0, 173, 239, 1);
  margin-right: 10px;
`;

export const TitleDesignDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleDesignMainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TitleButton = styled.button`
  width: auto;
  height: 40px;
  background: rgba(0, 173, 239, 1);
  border: none;
  color: white;
  font-size: 14px;
  border-radius: 10px;
`;

export default StaffHeader;
