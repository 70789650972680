import { useState, useEffect } from "react";
import styled from "styled-components";
import StaffSummaryComp from "components/staff/dashboard/StaffSummary";
import StackBarChart from "components/charts/StackBarChart";
import TaskBarChart from "components/reusable/charts/TaskBarChart";
import StaffQueueStatus from "components/staff/dashboard/StaffQueueStatus";
import StatusSummery from "components/admin/dashboard/ReturnStats";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";

const AdminDashboard = () => {
  const [userData, setUserData] = useState(null);
  const fetchData=async()=>{
try{
 const response= await apiReq(API_ROUTES.userAdminDashboard, "POST")
      setUserData(response.data.data);
  
}
    catch(error)  {
      console.error("Error fetching user data:", error);
    };
  }
  useEffect(() => {
    fetchData()
  }, []);
  const totalCompleted = userData?.monthlyCompletedQueue ?
  Object.values(userData.monthlyCompletedQueue).reduce((acc, value) => acc + value, 0) :
  0;
  const totalPending = userData?.monthlyPendingQueue ?
  Object.values(userData.monthlyPendingQueue).reduce((acc, value) => acc + value, 0) :
  0;
  return (
    <>
      {userData&&<DashboardContainer>
        {/* ---TopSection--- */}
        <StaffSummaryComp userData={userData} />
        <Card>
          <CardContent>
            <ChartHeading>Status Summary</ChartHeading>
            <LineChartContainer>
              <StatusSummery userData={userData} />

            </LineChartContainer>
          </CardContent>
        </Card>
      </DashboardContainer>}
      {userData&&<DashboardContainer>
        <Card2>
          <CardContent>
            <ChartHeading>Weekly Queue</ChartHeading>
            <LineChartContainer>
              <TaskBarChart
                data={userData}
                barWidth={12} // Customize bar width
                legendNames={["Pending Task", "Completed"]}
                width={500} // Customize legend names
              />

            </LineChartContainer>
          </CardContent>
        </Card2>
        <Card2>
          <CardContent>
            <ChartHeading>Completed Tax Return</ChartHeading>
            <LineChartContainer>
              <StackBarChart data={userData} />
              <div style={{display:'flex' ,fontWeight:'400',justifyContent:'center'}}>
                <div style={{paddingRight:'30px'}} >{totalPending}</div>
                <div style={{paddingLeft:'30px'}}>{totalCompleted}</div>
              </div>
            </LineChartContainer>
          </CardContent>
        </Card2>
       

      </DashboardContainer>}
      {userData&&<DashboardContainer>
        <BottomSec>
          <StaffQueueStatus userData={userData} admin={"admin"}/>
        </BottomSec>
      </DashboardContainer>}
    </>
  );
};

export default AdminDashboard;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  width: 100%;
`;
const Card = styled.div`
  width: 608.07px;
  height: auto;
  border-radius: 20px;
  background-color: white;
`;

const Card2 = styled.div`
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: white;
`;

const CardContent = styled.div`
  padding: 20px;
  height: auto;
`;

const ChartHeading = styled.h2`
  margin-bottom: 20px;
  padding-left:10px
`;

const LineChartContainer = styled.div`
  width: 100%;
  height: calc(100% - 60px); /* Adjusted for padding and margin of heading */
`;

const BottomSec = styled.div`
  display: flex;
 
  align-items: center;
  width: 100%;
`;
