import { useState, useEffect } from "react";
import styled from "styled-components";
import CustomTable from "components/reusable/customTable/CustomTable";
import { Button } from "@mui/material";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";

const StaffSupport = () => {
  const [, setShowButton] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [originaldata, setoriginaldata] = useState([]);
  const [editId, setEditId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search,setSearch]=useState();
  const handleMenuClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setEditId(params._id)
  };

  const handleMenuItemClick = async (status) => {
    setAnchorEl(null);
    try {
      const response = await apiReq(API_ROUTES.supportTicketUpdate, "POST", { ticketId: editId, status })
      if (!response.error) {
        fetchData()
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const fetchData = async () => {
    const storedUserData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(storedUserData);
    const requestBody = {
      staffId:parsedUserData.user._id,
      page: "1",
      dataPerPage: "5",
      statusFilter: "",
      searchById: ""
    };
    try {
      const response = await apiReq(API_ROUTES.supportTicketStaffGetAll, "POST", requestBody);
      const dataValue=response.data?.data?.staffTickets.map((ticket, index) => ({ ...ticket, srNumber: (index + 1).toString().padStart(2, '0') }));
      setData(dataValue);
      setoriginaldata(dataValue);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onSelectChange = (selectedValues) => {
    if (selectedValues.includes('Data Entry')) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };
  const handleSearchByName = (selectedValues) => {
    setSearch(selectedValues.target.value);
  };
  useEffect(() => {
    let filteredData = originaldata;
    
    if (search)
      filteredData = filteredData.filter(item => item.subject.toLowerCase().includes(search.toLowerCase()));
    setData(filteredData)
  }, [search, originaldata]);
  const individualColumns = [
    {
      field: "srNumber",
      headerName: "Sr#.",
      flex: 1,
      editable: false,
    },
    {
      field: "ticketNo",
      headerName: "Ticket Number",
      flex: 1,
      editable: false,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      editable: false,
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.priority === "High" ? "rgba(215, 21, 21, 1)" : "rgba(89, 169, 93, 1)",
            borderRadius: "15px",
            padding: "5px 10px"
          }}
        >
          {params.row.priority}
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Reported Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return dayjs(params.row.updatedAt).format('MMM DD, YYYY');
      }
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return dayjs(params.row.createdAt).format('MMM DD, YYYY');
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <>
          <div
            style={{
              backgroundColor: "rgba(244, 184, 27, 0.3)",
              color: "rgba(208, 150, 0, 1)",
              borderRadius: "15px",
              minWidth: '92px',
              padding: "5px",
              cursor: "pointer", // Change cursor to pointer to indicate clickable
            }}
            onClick={(e) => handleMenuClick(e, params.row)}
          >
            {params.row.status}
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleMenuItemClick('Pending')}>Pending</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Resolved')}>Resolved</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Rejected')}>Rejected</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Processing')}>Processing</MenuItem>
          </Menu>
        </>
      ),
    },

  ];

  const options = [
    { label: "Select All", value: "Select All" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Staff Review", value: "Staff Review" },
  ];

  useEffect(() => {
    fetchData();
    return () => {
    };

  }, []);

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton={true}
          btntext="+ Add New Ticket"
          clickHandler={() => navigate("/add-ticket")}
          onSelectChange={onSelectChange}
          showFirstSelect={false}
          options={options}
          placeholder='Select Status'
          onChange={handleSearchByName}
        />
        <Line border='0.0999999999999999px solid rgba(239, 243, 253, 1)' />
        <Button />
        <h5 style={{ paddingLeft: '5px' }}>My Task</h5>
        <RentalPropertyWrapper>
          <CustomTable rows={data} columns={individualColumns} />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default StaffSupport;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
