import React from "react";
import { DonutChartContainer, GrayCircleBackground } from "./Dashboard.style";

const DonutChart = ({ pieChartData, showMultiValue, singleChartValue,staff }) => {
 
  const adminColors = [ 
     "#00ADEF",
  
     "#9BDFC4",
 
     "#F99BAB",
  
     "#FFB44F",
  
     "#9F97F7",
    ];
    const staffColors = [ 
      "#00ADEF",
   
      "#F99BAB",
  
      "#FFB44F",
   
      "#9F97F7",
     ];
    const pieChartColors=staff?staffColors:adminColors
  if (showMultiValue) {
    const total = pieChartData.reduce((acc, item) => acc + item.value, 0);
    let currentAngle = -90;
    return (
      <GrayCircleBackground>
        <DonutChartContainer>
          <svg width="100%" height="100%" viewBox="0 0 200 200">
            {pieChartData.map((item, index) => {
              const percentage = (item.value / total) * 100;
              const arc = (percentage * 360) / 100;
              const color = pieChartColors[index];
              const outerArc =
                `M ${100 + 100 * Math.cos((currentAngle * Math.PI) / 180)} ${
                  100 + 100 * Math.sin((currentAngle * Math.PI) / 180)
                } ` +
                `A 100 100 0 ${arc > 180 ? 1 : 0} 1 ${
                  100 + 100 * Math.cos(((currentAngle + arc) * Math.PI) / 180)
                } ${
                  100 + 100 * Math.sin(((currentAngle + arc) * Math.PI) / 180)
                } ` +
                `L ${
                  100 + 70 * Math.cos(((currentAngle + arc) * Math.PI) / 180)
                } ${
                  100 + 70 * Math.sin(((currentAngle + arc) * Math.PI) / 180)
                } ` +
                `A 70 70 0 ${arc > 180 ? 1 : 0} 0 ${
                  100 + 70 * Math.cos((currentAngle * Math.PI) / 180)
                } ${100 + 70 * Math.sin((currentAngle * Math.PI) / 180)} ` +
                "Z";
              currentAngle += arc;
              return <path key={index} d={outerArc} fill={color} />;
            })}
          </svg>
        </DonutChartContainer>
      </GrayCircleBackground>
    );
  } else {
    const value = singleChartValue;
    const total = 100; // Since it's a percentage calculation
    let currentAngle = -90;

    return (
      <GrayCircleBackground>
        <DonutChartContainer>
          <svg width="100%" height="100%" viewBox="0 0 200 200">
            {(() => {
              const percentage = (value / total) * 100;
              const arc = 359;
              const color = pieChartColors[0]; // Assuming single color
              const outerArc =
                `M ${100 + 100 * Math.cos((currentAngle * Math.PI) / 180)} ${
                  100 + 100 * Math.sin((currentAngle * Math.PI) / 180)
                } ` +
                `A 100 100 0 ${arc > 180 ? 1 : 0} 1 ${
                  100 + 100 * Math.cos(((currentAngle + arc) * Math.PI) / 180)
                } ${
                  100 + 100 * Math.sin(((currentAngle + arc) * Math.PI) / 180)
                } ` +
                `L ${
                  100 + 70 * Math.cos(((currentAngle + arc) * Math.PI) / 180)
                } ${
                  100 + 70 * Math.sin(((currentAngle + arc) * Math.PI) / 180)
                } ` +
                `A 70 70 0 ${arc > 180 ? 1 : 0} 0 ${
                  100 + 70 * Math.cos((currentAngle * Math.PI) / 180)
                } ${100 + 70 * Math.sin((currentAngle * Math.PI) / 180)} ` +
                "Z";
              currentAngle += arc;
              return <path key={0} d={outerArc} fill={color} />;
            })()}
          </svg>
        </DonutChartContainer>
      </GrayCircleBackground>
    );
  }
};

export default DonutChart;
