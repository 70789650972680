import { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/auth/Logo.png";
import Dashboard from "assets/sidebar/Dashboard.png";
import ActiveDashboard from "assets/sidebar/active/Dashboard.png";
import Queues from "assets/sidebar/task-square.png";
import ActiveQueues from "assets/sidebar/active/task-square.png";
import Messages from "assets/sidebar/sms.png";
import ActiveMessages from "assets/sidebar/active/device-message.png";
import Chat from "assets/sidebar/device-message.png";
import ActiveChat from "assets/sidebar/active/chat.png";
import Report from "assets/sidebar/Vector.png";
import ActiveReport from "assets/sidebar/Vector.png";
import Settings from "assets/sidebar/Settings.png";
import ActiveSetting from "assets/sidebar/active/setting.png";
import Logout from "assets/sidebar/Logout.png";
import ActiveLogout from "assets/sidebar/Logout.png";
import Dot from "assets/sidebar/Dot.png";
import ActiveDot from "assets/sidebar/active/Dot.png";
import { SidebarLogo } from "../../../layout/sidebar/Sidebar.style";
import ActiveUser from "assets/sidebar/active/Add User.png";
import User from "assets/sidebar/Add User.png";
import ActiveSupport from "assets/sidebar/active/24-support.png";
import Support from "assets/sidebar/24-support.png";


const Sidebar2 = ({ handleMenuItemClick }) => {
  const params = useLocation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  const handleMouseEnter = (menuItem) => {
    setActiveMenu(menuItem);
  };

  const handleMenuClick = (menuItem) => {
    handleMenuItemClick(menuItem); // Call handleMenuItemClick here
    if (menuItem === "Report") {
      setOpenReport(!openReport);
    } else if (menuItem === "Settings") {
      setOpenSetting(!openSetting);
    }
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  const isMenuItemActive = (menuItem) => {
    const menuItemUrls = {
      Dashboard: "/staffDashboard",
      Client: "/client",
      Queues: "/queues",
      Messages: ["/messages","/messageCompose"],
      Chat: "/staff-chat",
      Report: [
        "/report",
        "/queue",
        "/taxreturns",
        "/taxFilings",
        "/clientActivity",
      ],
      Settings: ["/staff-profile", "/staff-change-password"],
      Support: "/support",
      logout: "/",
      TaxReturn: "/tax-return-report",
      TaxFilling: "/tax-filling-report",
      ClientActivity: "/client-activity-report",
      Queue: "/queue-report",
      Profile: "/staff-profile",
      "Change Password": "/staff-change-password"
    };

    const currentUrl = params?.pathname;

    // Check if the current URL matches the menu item URL
    if (Array.isArray(menuItemUrls[menuItem])) {
      return menuItemUrls[menuItem].some((url) => url === currentUrl);
    } else {
      return menuItemUrls[menuItem] === currentUrl;
    }
  };

  const getMenuItemStyle = (isActive, subMenu = true) => ({
    background: isActive ? "#fff" : "rgb(11, 37, 88)",
    color: isActive ? "rgb(11, 37, 88)" : "#fff",
    borderTopLeftRadius: isActive ? "25px" : "0px",
    borderBottomLeftRadius: isActive ? "25px" : "0px",
    fontSize: "12px",
    paddingLeft:subMenu ? "":"16px",
    marginTop:'8px'
  });

  return (
    <Sidebar backgroundColor='#0b2558' width='100%'>
      <SidebarLogo onClick={() => navigate("/staffDashboard")}>
        <img src={Logo} alt='RemeDy' />
      </SidebarLogo>
      <Menu style={{marginTop:'10PX'}}>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Dashboard") ? ActiveDashboard : Dashboard}
              alt='Dashboard'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Dashboard"), false)}
          component={<Link to='/staffDashboard' />}
          onMouseEnter={() => handleMouseEnter("Dashboard")}
          onMouseLeave={() => handleMouseLeave("Dashboard")}
          onClick={() => handleMenuClick("Dashboard")}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Client") ? ActiveUser : User}
              alt='Client'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Client"), false)}
          component={<Link to='/client' />}
          onMouseEnter={() => handleMouseEnter("Client")}
          onMouseLeave={() => handleMouseLeave("Client")}
          onClick={() => handleMenuClick("Client")}
        >
          Client
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Queues") ? ActiveQueues : Queues}
              alt='Queues'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Queues"), false)}
          component={<Link to='/queues' />}
          onMouseEnter={() => handleMouseEnter("Queues")}
          onMouseLeave={() => handleMouseLeave("Queues")}
          onClick={() => handleMenuClick("Queues")}
        >
          Queues
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Messages") ? ActiveMessages : Messages}
              alt='Messages'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Messages"), false)}
          component={<Link to='/messages' />}
          onMouseEnter={() => handleMouseEnter("Messages")}
          onMouseLeave={() => handleMouseLeave("Messages")}
          onClick={() => handleMenuClick("Messages")}
        >
          Messages
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Chat") ? ActiveChat : Chat}
              alt='Chat'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Chat"), false)}
          component={<Link to="/staff-chat" />}
          onMouseEnter={() => handleMouseEnter("Chat")}
          onMouseLeave={() => handleMouseLeave("Chat")}
          onClick={() => handleMenuClick("Chat")}
        >
          Chat
        </MenuItem>
        <SubMenu
          label='Report'
          icon={
            <img
              src={isMenuItemActive("Report") ? ActiveReport : Report}
              alt='Report'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Report"), false)}
          onMouseEnter={() => handleMouseEnter("Report")}
          onMouseLeave={() => handleMouseLeave("Report")}
          onClick={() => handleMenuClick("Report")}
          open={openReport}
        >
          <MenuItem
            component={<Link to='/queue-report' />}
            style={getMenuItemStyle(isMenuItemActive("Queue"))}
            onMouseEnter={() => handleMouseEnter("Queue")}
            onMouseLeave={() => handleMouseLeave("Queue")}
            onClick={() => handleMenuClick("Report > Queue")}
          >
            Queue
          </MenuItem>
          {/* Not in staff side remove by client */}
          {/* <MenuItem
            component={<Link to='/tax-return-report' />}
            style={getMenuItemStyle(isMenuItemActive("TaxReturn"))}
            onMouseEnter={() => handleMouseEnter("TaxReturn")}
            onMouseLeave={() => handleMouseLeave("TaxReturn")}
            onClick={() => handleMenuClick("Report > Tax Return")}
          >
            Tax Returns
          </MenuItem> */}
          <MenuItem
            component={<Link to='/tax-filling-report' />}
            style={getMenuItemStyle(isMenuItemActive("TaxFilling"))}
            onMouseEnter={() => handleMouseEnter("TaxFilling")}
            onMouseLeave={() => handleMouseLeave("TaxFilling")}
            onClick={() => handleMenuClick("Report > Tax Filling")}
          >
            Tax Filings
          </MenuItem>
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("ClientActivity") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to='/client-activity-report' />}
            style={getMenuItemStyle(isMenuItemActive("ClientActivity"))}
            onMouseEnter={() => handleMouseEnter("ClientActivity")}
            onMouseLeave={() => handleMouseLeave("ClientActivity")}
            onClick={() => handleMenuClick("Report > Client Activity")}
          >
            Client Activity
          </MenuItem>
          {/* Add other sub-menu items here */}
        </SubMenu>
        <SubMenu
          label='Settings'
          icon={
            <img
              src={isMenuItemActive("Settings ") ? ActiveSetting : Settings}
              alt='Settings'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Settings "))}
          onMouseEnter={() => handleMouseEnter("Settings")}
          onMouseLeave={() => handleMouseLeave("Settings")}
          onClick={() => handleMenuClick("Settings")}
          open={openSetting}
        >
          <MenuItem
            component={<Link to='/staff-profile' />}
            style={getMenuItemStyle(isMenuItemActive("Profile"))}
            onMouseEnter={() => handleMouseEnter("Profile")}
            onMouseLeave={() => handleMouseLeave("Profile")}
            onClick={() => handleMenuClick("Settings > Profile")}
          >
            Profile
          </MenuItem>
          <MenuItem
            component={<Link to='/staff-change-password' />}
            style={getMenuItemStyle(isMenuItemActive("Change Password"))}
            onMouseEnter={() => handleMouseEnter("Change Password")}
            onMouseLeave={() => handleMouseLeave("Change Password")}
            onClick={() => handleMenuClick("Settings > Change Password")}
          >
            Change Password
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Support") ? ActiveSupport : Support}
              alt='Support'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Support"), false)}
          component={<Link to='/support' />}
          onMouseEnter={() => handleMouseEnter("Support")}
          onMouseLeave={() => handleMouseLeave("Support")}
          onClick={() => handleMenuClick("Support")}
        >
          Support
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("logout") ? ActiveLogout : Logout}
              alt='Logout'
            />
          }
          style={getMenuItemStyle(isMenuItemActive("logout"), false)}
          onClick={() => {
            navigate("/staff-signin");
            localStorage.clear();
            sessionStorage.removeItem("roleSelected");
          }}
          onMouseEnter={() => handleMouseEnter("logout")}
          onMouseLeave={() => handleMouseLeave("logout")}
          // onClick={() => handleMenuClick("Logout")}
        >
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default Sidebar2;
