import {
  BotPhoto,
  ChatPopContainer,
  PopupHeading,
  PopupTextSec,
} from "./Chatbox.style";
import PopupText from "assets/chat/PopupText.png";
import BotPic from "assets/chat/BotPic.png";
import { HeadingFive } from "../typography/Typography.style";
import { useLocation } from "react-router-dom";

const Chatbot = ({ toggleChat }) => {
  const handleChatIconClick = () => {
    // Call the toggleChat function to switch to the chat view
    toggleChat();
  };

  const { pathname } = useLocation();
  return (
    <ChatPopContainer onClick={handleChatIconClick}>
      {pathname === "/dashboard" && (
        <PopupTextSec>
          <img src={PopupText} alt="PopupText" />
          <PopupHeading>
            <HeadingFive fontSize="18px">Lewca is here!</HeadingFive>
            <HeadingFive fontSize="18px">How may I help you?</HeadingFive>
          </PopupHeading>
        </PopupTextSec>
      )}
      <BotPhoto>
        <img src={BotPic} alt="bot" />
      </BotPhoto>
    </ChatPopContainer>
  );
};

export default Chatbot;
