import { DatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

export const InputCheckboxWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
`;

export const CheckboxComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: ${({ width }) => width};
  top: 6px;
  right: 6px;
  border: 1px solid #00000080;
  border-radius: 6px;
`;

export const CheckboxSec = styled.div`
  display: flex;
  grid-gap: 2px;
  align-items: center;
`;

export const CustomDatePicker = styled.div`
  .custom-date-picker > div {
    background-color: transparent !important;
  }
`;

export const InputCheckboxBtnWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
`;
