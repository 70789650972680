import React, { useRef } from "react";
import Modal from "@mui/material/Modal";
import {
  ValidateTaxBtnWrapper,
  ValidateTaxContainer,
  ValidateTaxContainer1,
} from "./Modals.style";
import { Span } from "../reusable/typography/Typography.style";
import { Button } from "../reusable/button/Button";
import Warning from "../../assets/modal/Warning.png";
import { Box, Grid, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";
import dayjs from "dayjs";
import FormationBusiness from "pages/admin/adminQueue/FormationBusiness";
import FormationNonProfit from "pages/admin/adminQueue/FormationNonProfit";
import Formation1099 from "pages/admin/adminQueue/Formation1099";
import html2pdf from "html2pdf.js";

const AdminFormationPreviewModal = (props) => {
  console.log(props.modalData, "propers");
  const componentRef = useRef();

  const handleDownload = () => {
    const element = componentRef.current;
    const opt = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based API
    html2pdf().set(opt).from(element).save();
  };

  return (
    <Modal
      open={props?.isModalOpen}
      onClose={props?.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ValidateTaxContainer1>
        <Box width={"100%"} ref={componentRef}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: "600", color: "#000" }}
            >
              {props?.modalData?.informationType}
            </Typography>
            <a
              href="javascript:void(0)"
              onClick={props.closeModal}
              style={{ fontSize: "20px", color: "#000" }}
            >
              <IoClose />
            </a>
          </Box>
          {props?.modalData?.informationType == "Business Formation" && (
            <FormationBusiness sheetData={props.modalData} />
          )}
          {props?.modalData?.informationType == "Non Profit Formation" && (
            <FormationNonProfit sheetData={props.modalData} />
          )}
          {props?.modalData?.informationType == "1099 Worksheet" && (
            <Formation1099 sheetData={props.modalData} />
          )}
        </Box>
        <Box
          width={"100%"}
          textAlign={"end"}
          display={"flex"}
          justifyContent={"end"}
          gap={"16px"}
        >
          <Button
            text="Close"
            width="150px"
            fontSize="20px"
            backgroundColor="#00ADEF"
            padding="10px"
            color="#fff"
            onClick={props?.closeModal}
          />
          <Button
            text="Download"
            width="150px"
            type="submit"
            fontSize="20px"
            backgroundColor="#0B2558"
            padding="10px"
            color="#FFFFFF"
            onClick={handleDownload}

          />
        </Box>
      </ValidateTaxContainer1>
    </Modal>
  );
};

export default AdminFormationPreviewModal;
