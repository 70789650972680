import { Modal } from "@mui/material";
import { useState, useEffect } from "react";
import { SignBody, SignContainer, SignHeader } from "./review/Signature.style";
import { Span } from "../reusable/typography/Typography.style";
import CloseIcon from "@mui/icons-material/Close";
import TextCheckbox from "../reusable/textCheckbox/textCheckbox";
import { Line } from "../client/rentalProperty/RentalProperty.style";
import { termConditionTab } from "../../data/clientData/termsConditionModal";
import MenuTabs from "../reusable/tabs/MenuTabs";
import { Button } from "../reusable/button/Button";
import FlexComp from "../reusable/flex/Flex";
import SelectStyle from "../modals/review/SelectStyle";
import Draw from "../modals/review/Draw";
import Upload from "../modals/review/Upload";
import { Input } from "../reusable/input/Inputs";
import { SyncLoader } from "react-spinners";

const Signature = ({
  isModalOpen,
  closeModal,
  openSecondModal,
  onSaveSignature,
  onUploadFile,
  loading = false
}) => {
  const [signatureStyle, setSignatureStyle] = useState("");
  const [drawnSignature, setDrawnSignature] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fullName, setFullName] = useState("");
  const [initials, setInitials] = useState("");
  const [eFileTaxReturnCheck, setEFileTaxReturnCheck] = useState(false);
  const [drawCheck, setDrawCheck] = useState(false);
  const [uploadCheck, setUploadCheck] = useState(false);


  const handleSaveSelectStyle = (selectedFont) => {
    setSignatureStyle(selectedFont);
  };

  const handleSaveDrawnSignature = (sigCanvas) => {
    const drawnSignatureDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setDrawnSignature(drawnSignatureDataURL);
    setDrawCheck(true);
    setUploadCheck(false);
  };

  const handleClearDrawnSignature = () => {
    setDrawnSignature("");
    setDrawCheck(false);
  }

  const handleUploadFile = (uploadedFileData) => {
    setUploadedFile(uploadedFileData);
    setUploadCheck(true);
    setDrawCheck(false);
  };

  const handleUploadedFileRemove = () => {
    setUploadedFile(null)
    setUploadCheck(false);
  };

  const handleSave = () => {
    const signatureData = {
      fullName,
      initials,
      signatureStyle: drawCheck ? "" : signatureStyle,
      eFileTaxReturnCheck,
      drawCheck,
      uploadCheck,
      signatureFile: drawCheck ? drawnSignature : uploadedFile,
    };
    
    onSaveSignature(signatureData);
  };
  

  useEffect(() => {
    const storedUsername = localStorage.getItem("userName");
    const storedInitials = localStorage.getItem("initials");
    if (storedUsername && storedInitials) {
      setFullName(storedUsername);
      setInitials(storedInitials);
    }
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem("userName");
    const storedInitials = localStorage.getItem("initials");
    if (storedUsername && storedInitials) {
      setFullName(storedUsername);
      setInitials(storedInitials);
    }
  }, []);

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SignContainer>
        <SignHeader>
          <Span color="#ffffff" fontSize="22px" fontWeight="500">
            Adopt Your Signature
          </Span>
          <CloseIcon
            sx={{ color: "#ffffff", cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </SignHeader>
        <SignBody>
          <Span color="#000000" fontSize="16px" fontWeight="400">
            Confirm your name, initials, and signature.
          </Span>
          <FlexComp
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gridGap="10px"
          >
            <Input
              label="Full Name*"
              id="fullName"
              size="size"
              width="48%"
              placeholder=""
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              label="Initials*"
              id="initials"
              size="size"
              width="48%"
              placeholder=""
              value={initials}
              onChange={(e) => setInitials(e.target.value)}
            />
          </FlexComp>
          <Line />
          <MenuTabs
            initial={initials}
            fullName={fullName}
            tabData={termConditionTab}
            uploadedFile={uploadedFile}
            drawnSignature={drawnSignature}
            onUploadFile={handleUploadFile}
            onSaveSelectStyle={handleSaveSelectStyle}
            tabComponents={[SelectStyle, Draw, Upload]}
            onSaveDrawnSignature={handleSaveDrawnSignature}
            handleUploadedFileRemove={handleUploadedFileRemove}
            handleClearDrawnSignature={handleClearDrawnSignature}
          />
          <Line />
          {/* <TextCheckbox
            onChange={(e) => setEFileTaxReturnCheck(e.target.checked)}
            text="Check to e-file your tax return (8879 Form)."
          /> */}
          <FlexComp
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            gridGap="10px"
          >
            <Button
              text="Cancel"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#EFF3FD"
              color="#AEB6CF"
              onClick={closeModal}
            />
            <Button
              text={loading ? <SyncLoader color={"#FFFFFF"} size={8} />: "Save"}

              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              color="#FFFFFF"
              onClick={handleSave}
            />
          </FlexComp>
        </SignBody>
      </SignContainer>
    </Modal>
  );
};

export default Signature;
