import { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import CustomTable from "components/reusable/customTable/CustomTable";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";
import { Button, Divider, Typography } from "@mui/material";
import detail from "assets/client/detail.png";
import deleteIcon from "assets/client/delete-circle.svg";
import { Box } from "@mui/system";
import FlexComp from "components/reusable/flex/Flex";



const errorToast = (message = "") => toast.error(message);

const StaffMessages = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("0");
  const [selectedOption, setSelectedOption] = useState("All Sent Messages");
  const [individualRows, setIndividualRows] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [msgDetails, setMsgDetails] = useState();
  const [data, setData] = useState([]);

  const [sideBarData, setSideBarData] = useState([
    { id: "0", value: "All Sent Messages", label: "0" },
    { id: "1", value: "Staff", label: "0" },
    { id: "2", value: "Client", label: "0" },
    { id: "3", value: "Admin", label: "0" },
    { id: "4", value: "Deleted Items", label: "0" },
  ]);
  const userId = localStorage.getItem("clientId");
  const fetchMessages = async () => {
    try {
      const response = await apiReq(API_ROUTES.messageGetAll, "POST", {
        userId,
      });
      const messages = response.data.data || [];

      const rows = messages.map((message) => ({
        id: message._id,
        srno1: message.id,
        ssname: message.sendBy,
        sToName: message.sendTo,
        lname: message.subject,
        cd: new Date(message.createdAt).toLocaleDateString(),
        isDeleted: message.isDeleted,
        toEmail: message.toEmail,
        message: message.message,
      }));

      setIndividualRows(rows);
      setData(rows);

      

      // Update sidebar data with message counts
      const messageCounts = {
        "All Sent Messages": messages.filter((message) => !message.isDeleted).length,
        "Staff": messages.filter((message) => message.sendBy === "Staff" && !message.isDeleted)
          .length,
        "Client": messages.filter((message) => message.sendBy === "Client" && !message.isDeleted)
          .length,
        "Admin": messages.filter((message) => message.sendBy === "Admin" && !message.isDeleted)
          .length,
     
          "Deleted Items": messages.filter(
            (message) => message.isDeleted === true
          ).length,

      };


      setSideBarData((prevState) =>
        prevState.map((item) => ({
          ...item,
          label: messageCounts[item.value]?.toString(),
        }))
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  console.log(individualRows, "MEssages")

  useEffect(() => {
    fetchMessages();
    //eslint-disable-next-line
  }, []); // Empty dependency array to run once on mount

  const handleDelete = async (id) => {
    try {
      await apiReq(API_ROUTES.deleteMessage, "POST", {
        messageId: id,
        isDeleted: true,
      });
      toast.success("Message deleted successfully");

      setIndividualRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setData((prevData) => prevData.filter((item) => item.id !== id));
      fetchMessages();
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error deleting message. please try again later."
      );
    }
  };

  const individualColumns = [
    {
      field: "srno1",
      headerName: "",
      width: 10,
      editable: false,
    },
    {
      field: "toEmail",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "ssname",
      headerName: "From",
      width: 200,
      editable: false,
    },
    {
      field: "lname",
      headerName: "Subject",
      width: 250,
      editable: false,
    },
    {
      field: "cd",
      headerName: "Received",
      width: 200,
      editable: false,
    },
    {
      headerName: "Action",
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setMsgDetails(params.row);
              setShowDetails(true);
              console.log(params.row, "paramsRow");
            }}
          >
            <img src={detail} alt="detail" />
          </Button>
          {selectedOption !== "Deleted Items" && (
            <Button
              variant="text"
              color="primary"
              onClick={() => handleDelete(params.row.id)}
            >
              <img src={deleteIcon} alt="detail" />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const renderTable = () => {
    let tableRows = [];
    let tableHeading = "";

    switch (selectedOption) {
      case "All Sent Messages":
        tableRows = individualRows.filter((row) => !row.isDeleted);
        tableHeading = "All Sent Messages";
        break;
      case "Staff":
        tableRows = individualRows.filter((row) => row.ssname === "Staff" &&  !row.isDeleted);
        tableHeading = "Staff";
        break;
      case "Client":
        tableRows = individualRows.filter((row) => row.ssname === "Client" && !row.isDeleted);
        tableHeading = "Client";
        break;
      case "Client":
        tableRows = individualRows.filter((row) => row.ssname === "Admin" && !row.isDeleted);
        tableHeading = "Client";
        break;

      case "Deleted Items":
        tableRows = individualRows.filter((row) => row.isDeleted === true);
        tableHeading = "Deleted Items";
        break;

      // Add cases for other options as needed
      default:
        break;
    }

    return (
      <div>
        <HeadingTwo>{tableHeading}</HeadingTwo>
        <CustomTable rows={tableRows} columns={individualColumns} />
      </div>
    );
  };

  const handleSelect = (id, value) => {
    setActive(id);
    setSelectedOption(value); // Update selected option
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton
          btntext="+ Compose New"
          clickHandler={() => navigate("/messageCompose")}
          showFirstSelect={false}
          showSecondSelect={false}
          placeholder="Select Status"
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />

        <RentalPropertyWrapper>
          <Grid container spacing={2}>
            <Grid item sx={4} lg={4} >
              <SideBoxStyle>
                {sideBarData.map((item) => (
                  <SideBoxButtonStyle
                    key={item.id}
                    className={active === item.id ? "active" : ""}
                    onClick={() => {handleSelect(item.id, item.value); setShowDetails(false)}}
                  >
                    <SideBoxTitleStyle>{item.value}</SideBoxTitleStyle>
                    {item.label && <div>{item.label}</div>}
                  </SideBoxButtonStyle>
                ))}
              </SideBoxStyle>
            </Grid>
            <Grid item xs={8} lg={8}>
              {!showDetails && <>{renderTable()}</>}
              {showDetails && <>
                  <Box >
                    <FlexComp alignItems="center" gap="4px">
                    <Typography sx={{fontSize: "25px", fontWeight: "400", color: "#000"}}>
                     View Message
                     </Typography>
                     <Typography sx={{fontSize: "18px", fontWeight: "400", color: "#000"}}>
                     ({msgDetails?.ssname})
                     </Typography>
                    </FlexComp>
                    <Divider sx={{margin: "12px 0"}} />
                    <Typography sx={{fontSize: "18px", fontWeight: "400", color: "#1B1B1B"}}>
                     {msgDetails?.lname}
                     </Typography>
                    <Divider sx={{margin: "12px 0"}} />
                    <Typography sx={{fontSize: "18px", fontWeight: "400", color: "#1B1B1B"}}>
                     <div dangerouslySetInnerHTML={{ __html: msgDetails.message }} />
                     </Typography>
                  </Box>
              </>}
            </Grid>
          </Grid>
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default StaffMessages;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

const SideBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  background: #eff3fd;
  width: 100%;
  padding: 20px;
  min-height: 50vh;
`;

const SideBoxButtonStyle = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  /* Add styles for the active tab */
  &.active {
    background-color: rgba(
      0,
      173,
      239,
      1
    ); /* Adjust the background color as needed */
    color: white;
  }
`;

const SideBoxTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
const HeadingTwo = styled.h2`
  font-weight: 400;
  font-size: 25px;
  padding: 10px;
`;
