import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, Tab, Tabs, Typography } from "@mui/material";
import { Button } from "components/reusable/button/Button";
import { Span } from "components/reusable/typography/Typography.style";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { useEffect, useRef, useState } from "react";
import { AddNewBusinessContainer } from "../../../components/modals/Modals.style";
import FlexComp from "../../../components/reusable/flex/Flex";
import BusinessData from "./BusinessData";
import PersonalData from "./PersonalData";
import RentalData from "./RentalData";
import TruckData from "./TruckData";

const ClientInfoModal = ({ setOpenInfoModal, openInfoModal, modalData }) => {
  console.log("Original Modal Data:", modalData);

  const componentRef = useRef();
  const [mainTabIndex, setMainTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);
  const [processedData, setProcessedData] = useState({
    personalCollectionTaskId: [],
    generalBusinessTaskId: [],
    rentalPropertyTaskId: [],
    truckDriverTaskId: [],
  });

  // Determine if data is from Assigned or Unassigned tab based on structure
  // For unassigned, we look for specific properties
  const isAssignedData =
    modalData?.personalCollectionTaskId !== undefined ||
    modalData?.generalBusinessTaskId !== undefined ||
    modalData?.rentalPropertyTaskId !== undefined ||
    modalData?.truckDriverTaskId !== undefined;

  useEffect(() => {
    // Process data for both Assigned and Unassigned when modalData changes
    processClientData();
  }, [modalData, mainTabIndex]);

  // Process data for both Assigned and Unassigned
  const processClientData = () => {
    console.log("Processing client data:", modalData);

    let processedCollection = {
      personalCollectionTaskId: [],
      generalBusinessTaskId: [],
      rentalPropertyTaskId: [],
      truckDriverTaskId: [],
    };

    // For Assigned data (has direct task arrays)
    if (isAssignedData) {
      console.log("Processing Assigned data");
      processedCollection = {
        personalCollectionTaskId: modalData?.personalCollectionTaskId || [],
        generalBusinessTaskId: modalData?.generalBusinessTaskId || [],
        rentalPropertyTaskId: modalData?.rentalPropertyTaskId || [],
        truckDriverTaskId: modalData?.truckDriverTaskId || [],
      };
    }
    // For Unassigned data (has nested collections)
    else if (modalData) {
      console.log("Processing Unassigned data");

      // Process personal collection directly if it exists
      if (modalData.personalCollection?.length > 0) {
        console.log("Found personalCollection:", modalData.personalCollection);
        processedCollection.personalCollectionTaskId =
          modalData.personalCollection.map((item) => ({
            ...item,
            informationType: "Personal Collection",
          }));
      }

      // Process business collection data if it exists
      if (modalData.businessCollection) {
        console.log("Found businessCollection:", modalData.businessCollection);

        // Map truckDriverForms to truckDriverTaskId if it exists
        if (modalData.businessCollection.truckDriverForms?.length > 0) {
          console.log(
            "Found truckDriverForms:",
            modalData.businessCollection.truckDriverForms
          );

          processedCollection.truckDriverTaskId =
            modalData.businessCollection.truckDriverForms.map((form) => ({
              ...form,
              informationType: "Truck Driver",
            }));
        }

        // Map rentalProperties to rentalPropertyTaskId if it exists
        if (modalData.businessCollection.rentalProperties?.length > 0) {
          console.log(
            "Found rentalProperties:",
            modalData.businessCollection.rentalProperties
          );

          processedCollection.rentalPropertyTaskId =
            modalData.businessCollection.rentalProperties.map((property) => ({
              ...property,
              informationType: "Rental Property",
            }));
        }

        // Add handling for generalBusinesses if it exists
        if (modalData.businessCollection.generalBusinesses?.length > 0) {
          processedCollection.generalBusinessTaskId =
            modalData.businessCollection.generalBusinesses.map((item) => ({
              ...item,
              informationType: "General Business",
            }));
        } else if (modalData.businessCollection.generalBusiness?.length > 0) {
          // Handle alternate path for generalBusiness
          processedCollection.generalBusinessTaskId =
            modalData.businessCollection.generalBusiness.map((item) => ({
              ...item,
              informationType: "General Business",
            }));
        }
      }
    }

    console.log("Processed collection:", processedCollection);
    setProcessedData(processedCollection);
  };

  // Main Tabs Data - keep all tabs regardless of data availability
  const mainTabs = [
    { key: "personalCollectionTaskId", label: "Personal Collection" },
    { key: "generalBusinessTaskId", label: "General Business" },
    { key: "rentalPropertyTaskId", label: "Rental Property" },
    { key: "truckDriverTaskId", label: "Truck Driver" },
  ];

  // Extract tasks for the selected main tab
  const selectedMainTab = mainTabs[mainTabIndex] || mainTabs[0];
  const selectedTasks = processedData?.[selectedMainTab?.key] || [];

  // ✅ For debugging
  console.log("Selected Main Tab:", selectedMainTab);
  console.log("Selected Tasks:", selectedTasks);
  console.log("Data Source:", isAssignedData ? "Assigned" : "Unassigned");
  console.log("Processed Data:", processedData);

  const handleDownload = () => {
    const element = componentRef.current;
    const options = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Ensure html2pdf is used correctly
    html2pdf().set(options).from(element).save();
  };

  // Helper function to extract tax year from any object by trying multiple paths
  const extractTaxYear = (obj) => {
    if (!obj) return null;

    // Log the object to help with debugging
    console.log(
      "Extracting tax year from:",
      JSON.stringify(obj).substring(0, 300) + "..."
    );

    // Try different paths where tax year might be stored
    if (obj.taxYear) return obj.taxYear;
    if (obj.year) return obj.year;

    // Check nested paths based on the data structure provided
    if (obj.prepSheetId?.taxYear) return obj.prepSheetId.taxYear;
    if (obj.rentalPropertyId?.taxYear) return obj.rentalPropertyId.taxYear;
    if (obj.truckDriverBasicInfoId?.taxYear)
      return obj.truckDriverBasicInfoId.taxYear;

    // Check other potential paths
    if (obj.data?.taxYear) return obj.data.taxYear;
    if (obj.formData?.taxYear) return obj.formData.taxYear;
    if (obj.basicInfo?.taxYear) return obj.basicInfo.taxYear;

    // Try to find any property that might contain a taxYear
    const keys = Object.keys(obj);
    for (const key of keys) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Check if this nested object has taxYear
        if (obj[key].taxYear) return obj[key].taxYear;

        // Recursive check one level deeper for common patterns
        const nestedObj = obj[key];
        if (nestedObj.prepSheetId?.taxYear)
          return nestedObj.prepSheetId.taxYear;
        if (nestedObj.basicInfo?.taxYear) return nestedObj.basicInfo.taxYear;
      }
    }

    return null;
  };

  // Generate sub tabs based on content type
  const subTabs = selectedTasks.map((task) => {
    // Use the helper function to extract tax year
    const taxYear = extractTaxYear(task) || "Unknown Year";

    console.log("Extracted tax year:", taxYear);

    return {
      taxYear: taxYear,
      content: task,
    };
  });

  return (
    <Modal
      open={openInfoModal}
      onClose={() => setOpenInfoModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <AddNewBusinessContainer
        style={{
          width: "800px",
          maxHeight: "90vh",
          overflow: "auto",
          padding: "14px",
        }}
      >
        <Box sx={{ width: "100%", padding: "0 20px" }}>
          <FlexComp alignItems="center" justifyContent="space-between">
            <Span color="#000000" fontSize="20px" fontWeight="500">
              Tax Payers Information
            </Span>
            <CloseIcon
              onClick={() => setOpenInfoModal(false)}
              sx={{ cursor: "pointer" }}
            />
          </FlexComp>
        </Box>
        <Box sx={{ padding: "0px 20px" }}>
          {/* Main Tabs - show all tabs regardless of data availability */}
          <Box sx={{ marginBottom: "10px" }}>
            <Tabs
              value={mainTabIndex}
              onChange={(e, newValue) => {
                setMainTabIndex(newValue);
                setSubTabIndex(0);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {mainTabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
          </Box>

          {/* Sub Tabs */}
          {subTabs.length > 0 ? (
            <>
              <Tabs
                value={Math.min(subTabIndex, subTabs.length - 1)}
                onChange={(e, newValue) => setSubTabIndex(newValue)}
                variant="scrollable"
                scrollButtons="auto"
              >
                {subTabs.map((subTab, index) => {
                  let yearDisplay = "Unknown Year";
                  try {
                    // Try to format with dayjs if it's a valid date
                    if (subTab.taxYear && subTab.taxYear !== "Unknown Year") {
                      // Check if it's a valid date string or year number
                      const isValidDate = dayjs(subTab.taxYear).isValid();
                      const isYearString =
                        !isNaN(subTab.taxYear) ||
                        (!isNaN(parseInt(subTab.taxYear)) &&
                          String(parseInt(subTab.taxYear)).length === 4);

                      if (isValidDate) {
                        yearDisplay = dayjs(subTab.taxYear).format("YYYY");
                      } else if (isYearString) {
                        yearDisplay = String(subTab.taxYear).substring(0, 4);
                      } else {
                        yearDisplay = String(subTab.taxYear);
                      }
                    }
                  } catch (e) {
                    console.error("Date formatting error:", e);
                    yearDisplay = String(subTab.taxYear || "Unknown Year");
                  }

                  return <Tab key={index} label={yearDisplay} />;
                })}
              </Tabs>
              <Box ref={componentRef} sx={{ padding: "20px" }}>
                {subTabs.length > 0 && (
                  <Typography variant="h6">
                    {(() => {
                      const currentSubTab =
                        subTabs[Math.min(subTabIndex, subTabs.length - 1)];
                      try {
                        if (
                          currentSubTab?.taxYear &&
                          currentSubTab.taxYear !== "Unknown Year"
                        ) {
                          // Similar logic as above for consistency
                          const isValidDate = dayjs(
                            currentSubTab.taxYear
                          ).isValid();
                          const isYearString =
                            !isNaN(currentSubTab.taxYear) ||
                            (!isNaN(parseInt(currentSubTab.taxYear)) &&
                              String(parseInt(currentSubTab.taxYear)).length ===
                                4);

                          if (isValidDate) {
                            return `Details for ${dayjs(
                              currentSubTab.taxYear
                            ).format("YYYY")}`;
                          } else if (isYearString) {
                            return `Details for ${String(
                              currentSubTab.taxYear
                            ).substring(0, 4)}`;
                          } else {
                            return `Details for ${String(
                              currentSubTab.taxYear
                            )}`;
                          }
                        }
                        return "Details";
                      } catch (e) {
                        console.error("Date formatting error:", e);
                        return "Details";
                      }
                    })()}
                  </Typography>
                )}

                {/* Content Sections - Updated to handle both data structures */}
                {(() => {
                  if (subTabs.length === 0) {
                    return (
                      <Typography variant="body1" color="textSecondary">
                        No data available for this category.
                      </Typography>
                    );
                  }

                  const currentTab = selectedMainTab.key;
                  const currentContent =
                    subTabs[Math.min(subTabIndex, subTabs.length - 1)]?.content;

                  console.log("Current content to render:", currentContent);

                  // For Personal Collection
                  if (
                    currentTab === "personalCollectionTaskId" ||
                    currentContent?.informationType === "Personal Collection"
                  ) {
                    return <PersonalData clientData={currentContent} />;
                  }

                  // For General Business
                  else if (
                    currentTab === "generalBusinessTaskId" ||
                    currentContent?.informationType === "General Business"
                  ) {
                    return <BusinessData clientData={currentContent} />;
                  }

                  // For Rental Property
                  else if (
                    currentTab === "rentalPropertyTaskId" ||
                    currentContent?.informationType === "Rental Property"
                  ) {
                    return <RentalData clientData={currentContent} />;
                  }

                  // For Truck Driver
                  else if (
                    currentTab === "truckDriverTaskId" ||
                    currentContent?.informationType === "Truck Driver"
                  ) {
                    return <TruckData clientData={currentContent} />;
                  }

                  // If no match found
                  return (
                    <Typography variant="body1" color="textSecondary">
                      No data available for this selection.
                    </Typography>
                  );
                })()}
              </Box>
            </>
          ) : (
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{ padding: "20px" }}
            >
              No data available for this category.
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "15px",
            mt: 2,
          }}
        >
          <Button
            text="Download"
            width="150px"
            type="submit"
            fontSize="20px"
            backgroundColor="#0B2558"
            padding="10px"
            color="#FFFFFF"
            onClick={handleDownload}
          />

          <Button
            text="Cancel"
            width="auto"
            fontSize="20px"
            padding="10px 40px"
            backgroundColor="rgba(239, 243, 253, 1)"
            color="rgba(174, 182, 207, 1)"
            onClick={() => setOpenInfoModal(!openInfoModal)}
          />
        </Box>
      </AddNewBusinessContainer>
    </Modal>
  );
};

export default ClientInfoModal;
