import React from "react";
import { IconStyledButton } from "./Button.style";
import { borderRadius } from "@mui/system";

export const ButtonIcon = ({
  position,
  bottom,
  right,
  bgColor,
  icon,
  text,
  width,
  fontSize,
  clickHandler,
  height,
  borderRadius,
  marginLeft,
  border,padding,
  color,
  type = 'button'
}) => {
  return (
    <IconStyledButton
      position={position}
      bottom={bottom}
      right={right}
      width={width}
      fontSize={fontSize}
      bgColor={bgColor}
      onClick={clickHandler}
      height={height}
      padding={padding}
      borderRadius={borderRadius}
      marginLeft={marginLeft}
      border={border}
      color={color}
      type={type}
    >
      {icon}
      {text}
    </IconStyledButton>
  );
};
