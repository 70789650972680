import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Modal, Tab, Tabs, Typography } from "@mui/material";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import { Button } from "components/reusable/button/Button";
import { IconStyledButton } from "components/reusable/button/Button.style";
import { Span } from "components/reusable/typography/Typography.style";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { useRef, useState } from "react";
import { AddNewBusinessContainer } from "../../../components/modals/Modals.style";
import FlexComp from "../../../components/reusable/flex/Flex";
import BusinessData from "./BusinessData";
import PersonalData from "./PersonalData";
import RentalData from "./RentalData";
import TruckData from "./TruckData";

const ShowClientModal = ({
  generalBusinessModal,
  setGeneralBusinessModal,
  modalData,
  handleYesClick,
  download,
}) => {
  console.log(modalData, "modalData");
  const componentRef = useRef();

  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);

  const [mainTabIndex, setMainTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);

  // Map to get taxYear field based on the array name
  const taxYearKeyMap = {
    personalCollectionTaskId: "prepSheetId",
    generalBusinessTaskId: "businessInfoId",
    rentalPropertyTaskId: "rentalPropertyId",
    truckDriverTaskId: "truckDriverBasicInfoId",
  };

  // Main Tabs Data
  const mainTabs = [
    { key: "personalCollectionTaskId", label: "Personal Collection" },
    { key: "generalBusinessTaskId", label: "General Business" },
    { key: "rentalPropertyTaskId", label: "Rental Property" },
    { key: "truckDriverTaskId", label: "Truck Driver" },
  ];
  const handleDownload = () => {
    const element = componentRef.current;
    const opt = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based API
    html2pdf().set(opt).from(element).save();
  };

  // Extract tasks for the selected main tab
  const selectedMainTab = mainTabs[mainTabIndex];
  const selectedTasks = modalData?.[0]?.[selectedMainTab?.key] || [];

  // Generate sub tabs based on taxYear
  const subTabs = selectedTasks.map((task) => {
    const taxYearField = taxYearKeyMap[selectedMainTab.key];
    return {
      taxYear: task[taxYearField]?.taxYear || "Unknown Year",
      content: task, // Pass the entire task for rendering details
    };
  });
  //   const handleDownload = async () => {
  //     const container = document.createElement("div");
  //     container.style.margin = "10px";
  //     // container.style.fontFamily = "Arial, sans-serif"; // Add this line to set a consistent font

  //     tabs.forEach((tab) => {
  //       const content = renderTabContent(tab.label);
  //       const tempDiv = document.createElement("div");
  //       tempDiv.innerHTML = ReactDOMServer.renderToString(content);
  //       container.appendChild(tempDiv);
  //     });

  //     html2pdf()
  //       .from(container)
  //       .toPdf()
  //       .output("blob")
  //       .then((pdfBlob) => {
  //         const link = document.createElement("a");
  //         link.href = URL.createObjectURL(pdfBlob);
  //         link.download = "all_tabs.pdf";
  //         link.click();
  //       });
  //   };
  return (
    <>
      <Modal
        open={generalBusinessModal}
        onClose={() => setGeneralBusinessModal(!generalBusinessModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddNewBusinessContainer
          style={{
            width: "800px",
            maxHeight: "90vh",
            overflow: "auto",
            padding: "14px",
          }}
        >
          <Box sx={{ width: "100%", padding: "0 20px" }}>
            <FlexComp alignItems="center" justifyContent="space-between">
              <Span color="#000000" fontSize="20px" fontWeight="500">
                Tax Payers Information
              </Span>
              <CloseIcon
                onClick={() => setGeneralBusinessModal(!generalBusinessModal)}
                sx={{ cursor: "pointer" }}
              />
            </FlexComp>
          </Box>
          <Box sx={{ padding: "0px 20px" }}>
            {/* Main Tabs */}
            <Box sx={{ marginBottom: "10px" }}>
              <Tabs
                value={mainTabIndex}
                onChange={(e, newValue) => {
                  setMainTabIndex(newValue);
                  setSubTabIndex(0); // Reset sub-tab index when main tab changes
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {mainTabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} />
                ))}
              </Tabs>
            </Box>

            {/* Sub Tabs */}
            {subTabs.length > 0 && (
              <>
                <Tabs
                  value={subTabIndex}
                  onChange={(e, newValue) => setSubTabIndex(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {subTabs.map((subTab, index) => (
                    <Tab
                      key={index}
                      label={dayjs(subTab.taxYear).format("YYYY")}
                    />
                  ))}
                </Tabs>
                <Box sx={{ padding: "20px" }} ref={componentRef}>
                  {/* Render content for the selected sub-tab */}
                  <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                    {`Details for ${dayjs(subTabs[subTabIndex].taxYear).format(
                      "YYYY"
                    )}`}
                  </Typography>
                  {/* <pre>{JSON.stringify(subTabs[subTabIndex].content, null, 2)}</pre> */}

                  {subTabs[subTabIndex]?.content?.informationType ==
                    "Personal Collection" && (
                    <PersonalData clientData={subTabs[subTabIndex]?.content} />
                  )}
                  {subTabs[subTabIndex]?.content?.informationType ==
                    "General Business" && (
                    <BusinessData clientData={subTabs[subTabIndex]?.content} />
                  )}
                  {subTabs[subTabIndex]?.content?.informationType ==
                    "Rental Property" && (
                    <RentalData clientData={subTabs[subTabIndex]?.content} />
                  )}
                  {subTabs[subTabIndex]?.content?.informationType ==
                    "Truck Driver" && (
                    <TruckData clientData={subTabs[subTabIndex]?.content} />
                  )}
                </Box>
              </>
            )}

            {/* No Data Available */}
            {subTabs.length === 0 && (
              <Typography variant="h6" color="textSecondary">
                No data available for this category.
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "15px",
            }}
          >
            <IconStyledButton
              width="150px"
              variant="text"
              color="white"
              fontSize="18px"
              padding="10px"
              onClick={handleDownload}
            >
              <DownloadIcon />
              Download
            </IconStyledButton>
            {download == "true" ? (
              <>
                <Button
                  text="Download"
                  width="150px"
                  type="submit"
                  fontSize="20px"
                  backgroundColor="#0B2558"
                  padding="10px"
                  color="#FFFFFF"
                  onClick={handleDownload}
                />
              </>
            ) : (
              <>
                {modalData?.[0]?.status !== "Data Completed" && (
                  <Button
                    text="Validate"
                    width="auto"
                    fontSize="20px"
                    padding="10px 40px"
                    backgroundColor="#00ADEF"
                    onClick={() => {
                      setGeneralBusinessModal(!generalBusinessModal);
                      setIsValidateTaxModal(!isValidateTaxModal);
                    }}
                  />
                )}
              </>
            )}

            <Button // Always show Cancel button
              text="Cancel"
              width="auto"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="rgba(239, 243, 253, 1)"
              color="
            rgba(174, 182, 207, 1)"
              onClick={() => setGeneralBusinessModal(!generalBusinessModal)}
            />
          </Box>
        </AddNewBusinessContainer>
      </Modal>
      <ValidateTaxModal
        handleYesClick={handleYesClick}
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
    </>
  );
};

export default ShowClientModal;
