import Auth from "../../../assets/auth/Auth.png";
import { AuthPicWrapper, SignupContainer } from "../signup/Signup.style";

import AdminResetPass from "./AdminResetPass";

const AdminResetPassword = () => {
  return (
    <SignupContainer>
      <AdminResetPass />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default AdminResetPassword;
