import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./utils/Theme";
import { ToastContainer } from "react-toastify";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <App />
    <ToastContainer
      position="top-center"
      autoClose={3000}
      limit={1}
      rtl={false}
      draggable={false}
      pauseOnHover={false}
      hideProgressBar
      progress={null}
      theme="colored"
    />
  </ThemeProvider>
);

reportWebVitals();
