import React, { useContext, useEffect, useRef, useState } from "react";
import { HealthCareWrapper, HealthContainer } from "./PersonalCollection.style";
import FlexComp from "components/reusable/flex/Flex";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { Button } from "components/reusable/button/Button";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import { useFormik } from "formik";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { SyncLoader } from "react-spinners";
import { healthCareSchema } from "./Schema";
import { FormHelperText, Stack } from "@mui/material";
import { ButtonIcon } from "components/reusable/button/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { Input } from "components/layout/navbar/Navbar.style";
import { PageLoaderContext } from "context/PageLoaderProvider";

const initialValues = {
  healthCareGov: false,
  healthCareDoc: "",
  healthCareDocFilename: null,
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const HealthCare = ({ setCurrentTab, rowData: businessData }) => {
  const [edit, setEdit] = useState(false);
  const healthCareDocRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const clientId = localStorage.getItem("clientId");
  const [healthCare, setHealthCare] = useState("No");
  const { setOpen } = useContext(PageLoaderContext);
  const personalCollectionId = localStorage.getItem("personalCollectionId");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [healthCareId, setHealthCareId] = useState(null);

  const {
    values,
    setFieldValue,
    touched,
    errors,
    setValues,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: healthCareSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const clientId = localStorage.getItem("clientId");
        if (!clientId) {
          errorToast("Client Id Not Found.");
          return;
        }
        if (edit) delete values.clientId;
        const formData = new FormData();
        const id = personalCollectionId.replace(/"/g, "");
        formData.append("clientId", clientId.toString());
        formData.append("healthCareGov", values.healthCareGov);
        if (values.healthCareGov) {
          formData.append("healthCareDoc", values.healthCareDoc);
        }
        formData.append("personalCollectionId", id);
        // Add retirementId for update requests
        if (edit && healthCareId) {
          formData.append("healthCareId", healthCareId);
        }
        await apiReq(
          `${edit ? API_ROUTES.updateHealthCare : API_ROUTES.addHealthCare}`,
          "POST",
          formData,
          API_BASE_URL,
          false
        );
        if (!edit) {
          // localStorage.setItem("tabs", JSON.stringify({...menuTabs, adjustments: false}))
        }
        infoToast(
          edit
            ? "Health care info updated successfully"
            : "Health care info saved successfully"
        );
        setTimeout(() => {
          setCurrentTab(3);
        }, 2000);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchHealthCare = async () => {
      try {
        setOpen(true);
        const id = (businessData?._id || personalCollectionId).replace(
          /"/g,
          ""
        );
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getPerosnalCollection + id,
          "POST",
          { clientId }
        );

        if (Object.keys(data?.healthCareId || {}).length > 0) {
          setValues({
            ...data.healthCareId,
            healthCareDoc: data?.healthCareId?.healthCareDoc,
          });
          setHealthCare(data?.healthCareId?.healthCareGov ? "Yes" : "No");
          setEdit(true);
          setHealthCareId(data?.healthCareId?._id); // Store the retirementId
          // localStorage.setItem("tabs", JSON.stringify({...menuTabs, adjustments: false}))
        }
      } catch (error) {
        errorToast(
          error?.message || "An unexpected error occurred. Please refresh page."
        );
      } finally {
        setOpen(false);
      }
    };
    fetchHealthCare();
  }, [setFieldValue]);

  const handleUploadButtonClick = (e) => {
    e.preventDefault();
    healthCareDocRef.current.click();
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <HealthContainer>
          <HeadingFive>Health Care Credits</HeadingFive>
          <HealthCareWrapper>
            <FlexComp direction="column" gridGap="10px">
              <Span color="#1B1B1B" fontSize="14px" fontWeight="500">
                ACA (Obamacare) / Healthcare.gov
              </Span>
            </FlexComp>
            <FlexComp direction="column" gridGap="10px">
              <TextCheckbox
                text="Yes (1095A required)"
                isChecked={healthCare === "Yes" || false}
                onChange={(e) => {
                  setHealthCare((prev) => (prev === "Yes" ? false : "Yes"));
                  setFieldValue("healthCareGov", true);
                }}
              />
            </FlexComp>
            <FlexComp direction="column" gridGap="10px" width="25%">
              <TextCheckbox
                text="No"
                isChecked={healthCare === "No" || false}
                onChange={(e) => {
                  setHealthCare((prev) => (prev === "No" ? false : "No"));
                  setFieldValue("healthCareGov", false);
                  setFileName("");
                  setFieldValue("healthCareDoc", "");
                }}
              />
            </FlexComp>
            <FlexComp
              direction="column"
              gridGap="5px"
              justifyContent="flex-start"
              alignItems="flex-start"
              height="100px"
            >
              {console.log("values.healthCareGov:", values.healthCareGov)}
              {console.log("healthCare:", healthCare)}
              {values?.healthCareGov && healthCare === "Yes" && (
                <Stack direction="row" gap={1}>
                  {values?.healthCareDoc && (
                    <ButtonIcon
                      width="100%"
                      icon={<CloudUploadIcon />}
                      text={fileName || values?.healthCareDocFilename}
                      fontSize="12px"
                      clickHandler={handleUploadButtonClick}
                    />
                  )}
                  {!values?.healthCareDoc && (
                    <ButtonIcon
                      width="100%"
                      icon={<CloudUploadIcon />}
                      text={"Upload Required Documents"}
                      fontSize="12px"
                      clickHandler={handleUploadButtonClick}
                    />
                  )}
                  <Input
                    type="file"
                    id="healthCareDoc"
                    ref={healthCareDocRef}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setFieldValue(
                        "healthCareDoc",
                        event.currentTarget.files[0]
                      ); // Change this line
                      setFileName(event.currentTarget.files[0]?.name);
                    }}
                  />
                  <FormHelperText error>
                    {touched?.healthCareDoc && errors?.healthCareDoc}
                  </FormHelperText>
                </Stack>
              )}
            </FlexComp>
          </HealthCareWrapper>
        </HealthContainer>
        <FlexComp direction="row" justifyContent="flex-end">
          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"
            }
            type="submit"
            width="15%"
            fontSize="18px"
            backgroundColor="#0B2558"
          />
        </FlexComp>
      </form>
    </>
  );
};

export default HealthCare;
