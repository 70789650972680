import {useState,useEffect} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import './pdf.css';
import { VerticalLine } from "./Pdf.style";
import TaxForm from "./TaxForm";
import PdfPartTwo from "./PdfPartTwo";
import PdfPartThree from "./PdfPartThree";
import PdfPageTwo from "./PdfPageTwo";
import ReactDOM from 'react-dom';
import DownloadIcon from "@mui/icons-material/Download";
import { IconStyledButton } from "components/reusable/button/Button.style";
import { Button } from "@mui/material";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";


const CreatePdf = ({handleClosePdfEditor, signatureImage}) => {
    const [, setPdfPageTwoContent] = useState("");
    const clientId = localStorage.getItem("clientId");
  useEffect(() => {
    // Trigger rendering of PdfPageTwo and capture its content
    const pdfPageTwoComponent = <PdfPageTwo />;
    const container = document.createElement("div");
    ReactDOM.render(pdfPageTwoComponent, container);
    setPdfPageTwoContent(container.innerHTML);

    // Clean up: remove the rendered PdfPageTwo component
    return () => {
      ReactDOM.unmountComponentAtNode(container);
    };
  }, []);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
  
      // Add a new page for PdfPageTwo content
      pdf.addPage();
  
      // Add content from PdfPageTwo
      const pdfPageTwo = document.getElementById("PdfPageTwo");
      html2canvas(pdfPageTwo).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
        
        pdf.save("download.pdf");
      });
    });
  };
  
  const [formData, setFormData] = useState({
    clientId: "",
    form8879: {
      sid: "",
      taxPayerName: "",
      spouseName: "",
      ssn: "",
      spouseNameSsn: "",
      adjustedGrossIncome: "",
      totalTax: "",
      federalIncomeTax: "",
      amountRefund: "",
      amountYouOwe: "",
      taxPayerPinCheck: false,
      authorizeField: "",
      myPin: "",
      pinAsSignatureCheck: false,
      signature: signatureImage,
      date: "",
      spouseTaxPayerPinCheck: false,
      spouseAuthorizeField: "",
      spousePin: "",
      spousePinAsSignatureCheck: false,
      spouseSignature: "",
      spouseDate: "",
      efinPin: "",
      part3Signature: "",
      part3Date: "",
    },
  });

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     form8879: {
  //       ...prevData.form8879,
  //       [name]: type === "checkbox" ? checked : value,
  //     },
  //   }));
   
  // };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      // Check if the new value is different from the current value
      if (prevData.form8879[name] === (type === "checkbox" ? checked : value)) {
        return prevData; // Return the current state without changes
      }
  
      return {
        ...prevData,
        form8879: {
          ...prevData.form8879,
          [name]: type === "checkbox" ? checked : value,
        },
      };
    });
  };
  
        console.log("data",formData)
        const handleSubmit = async () => {
          try {
            const response = await apiReq(API_ROUTES.staffClientAdd8879Form, "POST", {
              clientId,
              form8879: formData,
            },)
            toast.success('8879 form filled successfully')
            // Handle success response
          } catch (error) {
            console.error("Error occurred:", error);
            toast.error(error.message)
            // Handle error
          }
        };
        
        

     
  return (
    <>
      <div style={{alignItems:'right'}} className="mb5">
      <IconStyledButton
          height="40px"
          variant="text"
          color="white"
          fontSize='12px' 
          onClick={printDocument}
        >
          <DownloadIcon />
          Download Pdf
        </IconStyledButton>
        {/* <button style={{width:''}} onClick={printDocument}>Print</button> */}
      </div>
      <div id="divToPrint" className="grid-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            width:'100%',
          }}
        >
          <div style={{ width:'23%'}} className="grid-item">
            <div style={{ display: "flex" ,width:'40%'}}>
              <p style={{ fontSize: "12px", paddingTop: "20px",fontFamily:'inter' }}> Form</p>
              <h1>8879</h1>
            </div>

            <p style={{ fontSize: "14px" ,fontFamily:'inter' }}>(Rev. January 2021)</p>
            <p style={{ fontSize: "14px", paddingTop: "10px"  ,fontFamily:'inter'}}>
              (Department of the Treasury )
            </p>
            <p style={{ fontSize: "14px",fontFamily:'inter' }}>(Internal Revenue Service)</p>
          </div>

          <div style={{ width:'60%',justifyContent:'center'}} className="grid-item">
            <div >
              <p style={{ fontSize: "20px", textAlign: "center",fontFamily:'inter' }}>
                IRS e-file Signature Authorization
              </p>
              <p style={{ fontSize: "14px", textAlign: "center",paddingTop:'20px',fontFamily:'inter' }}>
                {" "}
                ERO must obtain and retain completed Form 8879.{" "}
              </p>
              <p style={{ fontSize: "14px", textAlign: "center",fontFamily:'inter' }}>
                {" "}
                Go to www.irs.gov/Form8879 for the latest information.
              </p>
            </div>
          </div>
          <div style={{textAlign:'center',borderBottom:'2px solid rgba(0, 0, 0, 1)',width:'17%'}}>
            <p style={{ fontSize: "14px",padding:'50px  20px 0px 20px',fontFamily:'inter' }}>
              OMB No. 1545-0074
            </p>
          </div>
        </div>
      
        <label style={{ fontSize: '14px',fontFamily:'inter' }} htmlFor="fname">Submission Identification Number (SID)</label>
        <input className="pdfinput" type="text" id="sid"
        name="sid"
        // value={formData.form8879.sid}
        onChange={handleInputChange}></input>

        <div style={{ display: "flex" }}>
          <div style={{ width: "70%"}}>
            <label style={{ fontSize: '14px',fontFamily:'inter' }} htmlFor="lastname">Taxpayer’s name</label>
            <input className="pdfinput" type="text" id="taxPayerName"
        name="taxPayerName"
        
         onChange={handleInputChange}
        // value={formData.form8879.taxPayerName}
        >

        </input>
          </div>
          <VerticalLine height='56px' />
          <div style={{ width: "30%" }}>
            <label style={{ fontSize: '14px',fontFamily:'inter' }} htmlFor="ssn">Social security number</label>
            <input className="pdfinput" type="text" id="ssn"   name=".ssn"
        // value={formData.form8879.ssn}
        onChange={handleInputChange}></input>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "70%"}}>
            <label style={{ fontSize: '14px',fontFamily:'inter' }} htmlFor="spouseName">Spouse’s name</label>
            <input className="pdfinput" type="text"  id="spouseName"
        name="spouseName"
        // value={formData.form8879.spouseName}
        onChange={handleInputChange}></input>
          </div>
        
          <VerticalLine height='45px' />
          <div style={{ width: "30%" }}>
            <label style={{ fontSize: '14px',fontFamily:'inter' }} htmlFor="spouseNameSsn">Spouse’s social security number</label>
            <input className="pdfinput" type="text"  id="spouseNameSsn"
        name="spouseNameSsn"
        // value={formData.form8879.spouseNameSsn}
        onChange={handleInputChange}></input>
          </div>
        </div>
    
        <div style={{height:'23px', width:'100%',borderTop:'1px solid black',borderBottom:'1px solid black',display:'flex',justifyContent:'space-between'}}>
          <div style={{height:'22px', width:'8%',background:'black', color:'white',textAlign:'center',fontFamily:'inter'}}>Part I</div>
          <div style={{width:'60%',paddingLeft:'10px',fontSize:'16px',fontFamily:'inter'}}>Tax Return Information — Tax Year Ending December 31,</div>
          <div style={{width:'40%' ,fontSize:'16px',fontFamily:'inter'}}>(Enter year you are authorizing.)</div>
        </div>
        <div>
        <TaxForm formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
          <PdfPartTwo formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit}/>
          <div style={{height:'23px', width:'100%',borderTop:'1px solid black',borderBottom:'1px solid black',display:'flex'}}>
            <div style={{height:'22px', width:'5%',background:'black', color:'white',textAlign:'center',fontFamily:'inter'}}>Part III</div>
            <div style={{width:'96%',paddingLeft:'10px',fontSize:'16px',fontFamily:'inter'}}>Practitioner PIN Method Returns Only—continue below</div>
          </div>
          <PdfPartThree  formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </div>
        <div style={{paddingTop:'120px'}} id="PdfPageTwo" >
        <PdfPageTwo  />  
        </div>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            onClick={handleClosePdfEditor}
            sx={{
              margin: "0 20px 10px 10px",
              backgroundColor: "rgba(239, 243, 253, 1)",
              width: "140px",
              color: "rgba(174, 182, 207, 1)",
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              margin: "0 20px 10px 10px",
              width: "140px",
              background: "rgba(0, 173, 239, 1)",
              color: "white",
            }}
            onClick={handleSubmit}
            type="submit"
          >
            Comfirm
          </Button>
        </div>
        {/* <Button
            sx={{
              margin: "0 20px 10px 10px",
              width: "140px",
              background: "rgba(0, 173, 239, 1)",
              color: "white",
            }}
            onClick={handleSubmit}
            type="submit"
            text='submit'
          >
            Comfirm
          </Button> */}
      </div>
      
      
    
      
    </>
  );
};

export default CreatePdf;
