import React, { useEffect } from "react";

const PdfPartThree = ({ formData, handleInputChange, handleSubmit }) => {
  // useEffect(() => {
  //     const inputElement = document.getElementById("input-field");

  //     const handleInput = (event) => {
  //       const inputValue = event.target.value;
  //       if (inputValue === "00000" || inputValue.length > 5) {
  //         event.target.value = inputValue.slice(0, -1);
  //       }
  //     };

  //     if (inputElement) {
  //       inputElement.addEventListener("input", handleInput);

  //       return () => {
  //         inputElement.removeEventListener("input", handleInput);
  //       };
  //     }
  //   }, []);
  return (
    <div>
      <div style={{ display: "flex" }}>
        <p style={{ fontSize: "14px", fontFamily: "inter" }}>
        ERO’s EFIN/PIN. Enter your six-digit EFIN followed by your five-digit self-selected PIN.
        </p>
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
            marginLeft: "10px",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
        <input
          style={{
            width: "2%",
            padding: " 0px 5px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "1px solid rgb(8, 8, 8)",
            outline: "none",
            marginRight: "10px",
          }}
          id="efinPin"
          name="efinPin"
          // value={formData.form8879.efinPin}
          onChange={handleInputChange}
          maxLength="1"
        />
      </div>
      <div
        style={{ textAlign: "right", paddingRight: "11%", fontSize: " 12px" }}
      >
        <p
          style={{
            fontSize: "14px",
            fontFamily: "inter",
            paddingRight: "166px",
          }}
        >
         Don’t enter all zeros

        </p>
      </div>
      <p style={{ fontSize: "14px", fontFamily: "inter" }}>
        I certify that the above numeric entry is my PIN, which is my signature
        for the electronic individual income tax return (original or amended) I
        am now authorized to file for tax year indicated above for the
        taxpayer(s) indicated above. I confirm that I am submitting this return
        in accordance with the requirements of the Practitioner PIN method and
        Pub. 1345, Handbook for Authorized IRS e-file Providers of Individual
        Income Tax Returns
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "14px", fontFamily: "inter", marginRight: "20px" }}>Your signature </p>
        <div style={{width: "40%"}}>

        <img src={formData.form8879.signature} height={"100px"} alt="" />

        </div>
{console.log(formData, "foosdadsadsa")}
        <p style={{ fontSize: "14px", fontFamily: "inter" }}>Date </p>
        <input
          style={{
            width: "40%",
            padding: " 0px 20px",
            margin: "0px 0px",
            boxSizing: "border-box",
            border: "none",
            borderBottom: "1px solid rgb(8, 8, 8)",
            outline: "none",
          }}
          id="part3Date"
          name="part3Date"
          // value={formData.form8879.part3Date}
          onChange={handleInputChange}
        ></input>
      </div>
      <div
        style={{
          height: "50px",
          width: "100%",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "96%",
            paddingLeft: "10px",
            fontSize: "16px",
            fontFamily: "inter",
          }}
        >
         ERO Must Retain This Form — See Instructions
        
        </div>
        <div
          style={{
            width: "96%",
            paddingLeft: "10px",
            fontSize: "16px",
            fontFamily: "inter",
          }}
        >
     Don’t Submit This Form to the IRS Unless Requested To Do So
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "14px",
        }}
      >
        <p style={{ fontSize: "14px", fontFamily: "inter" }}>
          For Paperwork Reduction Act Notice, see your tax return instructions.
        </p>
        <p style={{ fontSize: "14px", fontFamily: "inter" }}>Cat. No. 32778X</p>
        <p style={{ fontSize: "14px", fontFamily: "inter" }}>
          Form 8879 (Rev. 01-2021)
        </p>
      </div>
    </div>
  );
};

export default PdfPartThree;
