import { useContext, useEffect, useState } from "react";
import FlexComp from "components/reusable/flex/Flex";
import { pcAccordion, daycarefields } from "data/client";
import { ItemizedDeductionContainer } from "./PersonalCollection.style";
import { Button } from "components/reusable/button/Button";
import AdditionalNotes from "components/modals/AdditionalNotes";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import AccordionComp from "components/reusable/accordion/Accordion";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import DayCareAccordionComp from "components/reusable/accordion/DaycareAccordian";
import { useFormik } from "formik";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { itemizeDeduction } from "./Schema";

const initialValues = {
  medical: {
    dentalExpense: 0,
    medicalIns: 0,
    prescriptionCost: 0,
    medicalExpense: 0,
    dentalIns: 0,
  },
  //Tax & Interest Object
  taxesAndInterest: {
    interest: 0,
    realEstateTaxes: 0,
    boatMotorcycle: 0,
    pmi: 0,
    autoTruckTax: 0,
    model: "",
  },
  //Gifts Charity Object
  giftsToCharity: {
    cash: 0,
    nonCash: 0,
  },
  //Military Expense Object
  militaryExpense: {
    supplies: 0,
    hotelExpense: 0,
    officeExpense: 0,
    travelExpense: 0,
    mealAndEntertainment: 0,
    vehicleExpense: 0,
    movingExpense: 0,
    equipment: 0,
    grooming: 0,
    uniformsCleaners: 0,
    fees: 0,
    milleageCommuting: 0,
    business: 0,
    personal: 0,
  },
  //Miscellaneous Deduction Object
  miscellaneousDeduction: {
    priorYearTaxPreparationFees: 0,
    casualtyLoss: 0,
    legalFees: 0,
    alimonyPaid: 0,
    AssocDues: 0,
    energyCreditExpense: 0,
  },
  //Day Care Object
  dayCare: [
    {
      expense: 0,
      taxId: "",
      name: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
    },
  ],
  dayCareDocs: [],
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const ItemizedDeduction = ({ setCurrentTab, rowData: businessData }) => {
  // const navigate = useNavigate();
  const [dayCareList, setDayCareList] = useState(initialValues.dayCare);
  const [edit, setEdit] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [openIndex1, setOpenIndex1] = useState(null);
  const clientId = localStorage.getItem("clientId");
  const { setOpen } = useContext(PageLoaderContext);
  // const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  // const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [deletedDayCareDocs, setDeletedDayCareDocs] = useState([]);
  const personalCollectionId = localStorage.getItem("personalCollectionId");
  const [fileName, setFileName] = useState([]);
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const addNewDaycare = () => {
    setFieldValue("dayCare", [
      ...values.dayCare,
      {
        expense: 0,
        taxId: "",
        name: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
      },
    ]);
  };
  const removeDaycareItem = (index) => {
    const updatedDayCareList = values.dayCare.filter((_, i) => i !== index);
    setFieldValue("dayCare", updatedDayCareList);
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: itemizeDeduction,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast("Client Id Not Found.");
          return;
        }

        const formData = new FormData();

        formData.append("clientId", clientId);
        const id = (businessData?._id || personalCollectionId).replace(/"/g, '');
        formData.append("personalCollectionId", id);
        // if(edit)formData.append("itemizedDeductionsId", values?._id);
        if (edit) {
          formData.append("itemizedDeductionsId", values?._id);
          // Append deletedDayCareDocs only for update
          formData.append(
            "deletedDayCareDocs",
            JSON.stringify(deletedDayCareDocs)
          );
        }

        Object.entries(values.medical).forEach(([key, value]) => {
          formData.append(`medical[${key}]`, value);
        });
        Object.entries(values.taxesAndInterest).forEach(([key, value]) => {
          formData.append(`taxesAndInterest[${key}]`, value);
        });
        Object.entries(values.giftsToCharity).forEach(([key, value]) => {
          formData.append(`giftsToCharity[${key}]`, value);
        });
        Object.entries(values.militaryExpense).forEach(([key, value]) => {
          formData.append(`militaryExpense[${key}]`, value);
        });
        Object.entries(values.miscellaneousDeduction).forEach(
          ([key, value]) => {
            formData.append(`miscellaneousDeduction[${key}]`, value);
          }
        );
        values.dayCare.forEach((dayCareItem, index) => {
          Object.entries(dayCareItem).forEach(([key, value]) => {
            formData.append(`dayCare[${index}][${key}]`, value);
          });

          (values?.dayCareDocs || []).forEach((file, index) => {
            formData.append(`dayCareDocs`, file.doc); // Append the actual file
          });
        });

        // Make the API request using FormData
        await apiReq(
          `${
            edit
              ? API_ROUTES.updateItemizeDeduction
              : API_ROUTES.addItemizeDeduction
          }`,
          "POST",
          formData,
          API_BASE_URL,
          false
        );
        if (!edit) {
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, upload_docs: false })
          );
        }

        setTimeout(() => {
          setCurrentTab(5);
        }, 2000);

        // Handle successful API response
        infoToast(
          edit
            ? "Itemized deduction info updated successfully"
            : "Itemized deduction info saved successfully"
        );
        // setTimeout(() => {
        //   setIsAdditionalModal(true);
        // }, 1000);
      } catch (error) {
        // Handle error
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
        console.log("is working in cathc");
      } finally {
        setSubmitting(false);
      }
    },
  });
  console.log(edit, "asassad");

  useEffect(() => {
    const fetchItemizeDeduction = async () => {
      try {
        setOpen(true);
        const id = (businessData?._id || personalCollectionId).replace(/"/g, '');
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getPerosnalCollection+ (id), "POST", {clientId});
        if (Object.keys(data?.itemizedDeductionId || {}).length > 0) {
          setValues(data?.itemizedDeductionId);
          setFileName(data?.itemizedDeductionId?.dayCareDocs);
          setEdit(true);
        }

        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, upload_docs: false })
        );
      } catch (error) {
        errorToast(
          error?.message || "An unexpected error occurred. Please refresh page."
        );
      } finally {
        setOpen(false);
      }
    };
    fetchItemizeDeduction();
  }, []);

  // useEffect(() => {
  //   const fetchNotes = async () => {
  //     try {
  //       const { data: { data = {} } = {} } = await apiReq(
  //         `${API_ROUTES.getPeronalCollection}`,
  //         "POST",
  //         { clientId }
  //       );
  //       if (data?.additionalNotes) {
  //         setNotes(data?.additionalNotes || "");
  //         localStorage.setItem("personalCollectionId", data?._id);
  //       }
  //     } catch (error) {
  //       errorToast(
  //         error?.message || "An unexpected error occurred. Please refresh page."
  //       );
  //     }
  //   };
  //   fetchNotes();
  // }, []);

  const handleAccordionOpen = (index, type) => {
    if (type === "pcAccordion") {
      setOpenIndex(index);
      setOpenIndex1(null);
    } else if (type === "daycarefields") {
      setOpenIndex(null);
      setOpenIndex1(index);
    }
  };

  // const handleNotesSubmit = async (additionalNotes = "") => {
  //   try {
  //     await apiReq(API_ROUTES.addAditionalNotes, "POST", {
  //       clientId,
  //       additionalNotes,
  //       personalCollectionId: personalCollectionId,
  //     });
  //     setIsAdditionalModal(false);
  //     infoToast("Addiitonal notes saved successfully");
  //     setTimeout(() => {
  //       setIsValidateTaxModal(true);
  //     }, 2000);
  //   } catch (error) {
  //     errorToast(
  //       error?.message ||
  //         "An unexpected error occurred. Please try again later."
  //     );
  //   }
  // };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <ItemizedDeductionContainer>
          <HeadingFive fontSize="18px" fontWeight="600" color="#1b1b1b">
            Itemized Deduction
          </HeadingFive>
          <FlexComp
            direction="column"
            justifyContent="flex-start"
            gridGap="5px"
          >
            {pcAccordion.map((item, index) => (
              <AccordionComp
                item={item}
                key={index}
                index={index}
                openIndex={openIndex}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                setOpenIndex={(index) =>
                  handleAccordionOpen(index, "pcAccordion")
                }
              />
            ))}
            {/* {daycarefields.map((item1, index1) => (
              <DayCareAccordionComp
                item1={item1}
                key={index1}
                index1={index1}
                values={values}
                errors={errors} 
                touched={touched}
                openIndex={openIndex1}
                setFieldValue={setFieldValue}
                setOpenIndex={(index) => handleAccordionOpen(index, "daycarefields")}
              />
            ))} */}
            <DayCareAccordionComp
              dayCareList={values.dayCare}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              addNewDaycare={addNewDaycare}
              removeDaycareItem={removeDaycareItem}
              setDeletedDayCareDocs={setDeletedDayCareDocs}
              fileName={fileName}
              setFileName={setFileName}
            />
          </FlexComp>

          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"
            }
            type="submit"
            width="12%"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="end"
          />
        </ItemizedDeductionContainer>
        {/* <AdditionalNotes
          isModalOpen={isAdditionalModal}
          additionalNotes={notes}
          handleNotesSubmit={handleNotesSubmit}
          closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
          openSecondModal={(notes) => handleNotesSubmit(notes)}
        />
        <ValidateTaxModal
          isModalOpen={isValidateTaxModal}
          closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
          handleYesClick={() => {
            infoToast("Submitted successfully");
          }}
          paragraph="Are you sure you want to validate this Personal Collection Form?"
        /> */}
      </form>
    </>
  );
};

export default ItemizedDeduction;
