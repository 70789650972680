import React from "react";
import TableComp from "../../reusable/tables/Table";
import { RequiredTaskContainer2 } from "./Dashboard.style";
import { HeadingFive } from "../../reusable/typography/Typography.style";
import { formatCategoryName } from "../../../utils/Helpers"; 

const RequiredTask = ({ userData }) => {
  const taskData = transformTaskData(userData && userData.requiredTasks);

  return (
    <RequiredTaskContainer2>
      <HeadingFive fontSize="20px" fontWeight="600">
       Tax Return Progress
      </HeadingFive>
      {taskData ? (
        <TableComp taskData={taskData} />
      ) : (
        <p>No tasks available</p>
      )}
    </RequiredTaskContainer2>
  );
};

const transformTaskData = (requiredTasks) => {
  if (!requiredTasks) return null;

  const transformedData = Object.keys(requiredTasks).map((key) => ({
    taskCategory: formatCategoryName(key), 
    taskCount: requiredTasks[key],
  }));
  return transformedData;
};

export default RequiredTask;
