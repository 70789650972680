import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const TruckData = ({ clientData }) => {
  console.log(clientData, "personalData");
  const {
    truckDriverBasicInfoId,
    truckDriverFeeId,
    truckDriverInsuranceId,
    truckDriverLaborTaxesId,
    truckDriverMaintenanceId,
    truckDriverOfficeExpId,
    truckDriverRentAndLeaseId,
    truckDriverRoadExpId,
  } = clientData;
  return (
    <div>
      <Box
        sx={{ background: "#EFF3FD", borderRadius: "12px", padding: "20px" }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Businesss Information
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Social Security
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.socialSecurity}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Tax year
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.taxYear}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Zip Code
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.businessAddress?.zipCode || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              City
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverBasicInfoId?.businessAddress?.city || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              State
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.businessAddress?.state || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Cell Number
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.mobileNumber}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              DOB
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.dob}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Email
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverBasicInfoId?.email}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Fees
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Association Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.associationFee}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              ATM Charges
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.atmCharges}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Bank Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.bankFee || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              DOT Exam Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverFeeId?.dotExamFee || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Comcard Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.comcardFee || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              IRA Custodial / Investment Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.investmentFee}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Licensing Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.licensingFee}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Safe Deposit Box Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.safeDepositBoxFee}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Wire Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.wireFee}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Administrative Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverFeeId?.administrativeFee}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Maintenance
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Parts
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverMaintenanceId?.parts}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Repairs
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverMaintenanceId?.repairs}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Tires
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverMaintenanceId?.tires || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Truck Washes
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverMaintenanceId?.truckWashes || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Other Maintenance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverMaintenanceId?.otherMaintenance || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Taxes
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Ad Valorem
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverLaborTaxesId?.taxes?.adValorem}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Fuel Tax
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverLaborTaxesId?.taxes?.fuelTax}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Heavy Use Tax
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverLaborTaxesId?.taxes?.heavyUseTax || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              License Tax
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverLaborTaxesId?.taxes?.licenseTax || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Permits
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverLaborTaxesId?.taxes?.permits || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Registration
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverLaborTaxesId?.taxes?.registration || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Insurance
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Bobtail Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.insurance?.bobtailInsurance}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              PI & PD Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.insurance?.piAndPdInsurance}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Truck Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.insurance?.truckInsurance || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Workman’s Compensation
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverInsuranceId?.insurance
                ?.insuranceWorkmanCompensation || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Interest on Credit Cards Bus
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.insurance?.truckInsurance || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Other Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.insurance?.otherInsurance || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Interest
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Interest on Advances
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.interest?.interestOnAdvances}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Interest on Credit Cards Bus
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.interest?.interestCreditCardsBus}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Interest on Truck Loans
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverInsuranceId?.interest?.interestOnTruckLoans || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Other Interest
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverInsuranceId?.interest?.otherInterest || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Road Expenses
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Hotels
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.hotel}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Laundry
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.laundry}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Parking
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.parking || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Showers
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {truckDriverRoadExpId?.showers || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Tolls
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.tolls || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Truck Supplies
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.truckSupplies || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Weigh Fees/Scales
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.weightFees || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Prepass
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.prepass || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Meals
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.meals || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Rental Car
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.rentalCar || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Airfare
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.airfare || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              No. of Days in the Roads
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRoadExpId?.noOfDaysInRoads || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
      Rent & Lease
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Truck Lease
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRentAndLeaseId?.truckLease}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Storage Lease

            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRentAndLeaseId?.storageLease}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Other
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {truckDriverRentAndLeaseId?.other || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TruckData;
