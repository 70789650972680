// helpers.js

export const formatCategoryName = (category) => {
    const words = category.split(/(?=[A-Z])/).map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    return words.join(" ");
  };
  
  export const convertToTitleCase = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
