import { useState, useReducer, useEffect } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Button } from "components/reusable/button/Button";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import { ChatBox } from "components/reusable/chatLayout/index";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  setDoc,
  serverTimestamp,
  onSnapshot,
  arrayUnion,
} from "firebase/firestore";
import { db } from "FirebaseSetup";
import MessageContext from "components/reusable/chatCards/MessageContext";

const chatOptions = [
  {
    title: "My Clients",
    value: "all",
  },
  {
    title: "Admin",
    value: "admin",
  },
];

const Chat = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("0");
  const [activeTab, setActiveTab] = useState("all");
  const [selectedOption, setSelectedOption] = useState("All Messages");
  const [user, setUser] = useState("");
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (currentUser && currentUser.user.fireBaseUid) {
      const getChats = () => {
        const unsub = onSnapshot(
          doc(db, "usersChats", currentUser.user.fireBaseUid),
          (doc) => {
            setChats(doc.data());
          }
        );
        return () => {
          unsub();
        };
      };

      getChats();
    }
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveTab(buttonName);
  };

  const handleFirstSelect = async (user) => {
    if (user) {
      const email = currentUser?.user?.email;
      const fullName = email.substring(0, 2);

      const combinedId =
        currentUser.user.fireBaseUid > user.uid
          ? currentUser.user.fireBaseUid + user.uid
          : user.uid + currentUser.user.fireBaseUid;

      try {
        const chatDoc = await getDoc(doc(db, "chats", combinedId));

        if (!chatDoc.exists()) {
          await setDoc(doc(db, "chats", combinedId), { messages: [] });

          await updateDoc(doc(db, "usersChats", currentUser.user.fireBaseUid), {
            [combinedId + ".userInfo"]: {
              uid: user.uid,
              displayName: user.displayName,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });

          await updateDoc(doc(db, "usersChats", user.uid), {
            [combinedId + ".userInfo"]: {
              uid: currentUser.user.fireBaseUid,
              displayName: fullName,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });
        }

        setUser(null);
        handleSelect(user);
      } catch (err) {
        console.error("Error starting chat:", err);
      }
    }
  };

  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          chatId:
            currentUser.user.fireBaseUid > action.payload.uid
              ? currentUser.user.fireBaseUid + action.payload.uid
              : action.payload.uid + currentUser.user.fireBaseUid,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  const hanldeSearchStaff = (searchTerm) => {
    if (typeof searchTerm !== "string") {
      return;
    }

    const trimmedSearchTerm = searchTerm.trim();

    if (trimmedSearchTerm === "") {
      fetchUsers();
      return;
    }

    const lowerCaseSearchTerm = trimmedSearchTerm.toLowerCase();

    const filteredUsers = users.filter((user) =>
      user.displayName.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setUsers(filteredUsers);
  };

  const handleSelect = (writerObj) => {
    dispatch({ type: "CHANGE_USER", payload: writerObj });
    getChatList();
  };

  const getChatList = () => {
    console.log("check Delete")
    onSnapshot(doc(db, "chats", state.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });
  };
  const fetchUsers = async () => {
    try {
      let querySnapshot;
      let userSnapshot;

      switch (activeTab) {
        case "all":
          querySnapshot = await getDocs(collection(db, "usersChats"));
          userSnapshot = await getDocs(collection(db, "users"));
          break;
        case "admin":
          querySnapshot = await getDocs(
            query(collection(db, "users"), where("role", "==", "Admin"))
          );
          break;
        default:
          querySnapshot = await getDocs(collection(db, "users"));
      }

      const fetchedUsers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(fetchedUsers, "fetchedUsers");

      if (activeTab === "all") {
        const fetchedUsers1 = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const staffUsers = fetchedUsers.filter((userObject) => {
          const userKeys = Object.keys(userObject);
          return userKeys.some((key) => {
            if (
              key !== "id" &&
              userObject[key]?.userInfo?.displayName === "Staff Member"
            ) {
              return true;
            }
            return false;
          });
        });

        const staffUserIds = staffUsers.map((userObject) => userObject.id);

        const filteredStaffUsers = fetchedUsers1.filter((user) =>
          staffUserIds.includes(user.id)
        );
        setUsers(filteredStaffUsers);
        console.log("nosads");
      } else {
        setUsers(fetchedUsers);
        console.log("checkYsress");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, [activeTab]);


  return (
    <>
      <RentalPropertyContainer>
        <RentalPropertyWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} lg={3}>
              <SideBoxStyle>
                <StaffHeader
                  text=""
                  btntext=""
                  bgColor="white"
                  showButton={false}
                  showFirstSelect={false}
                  clickHandler={() => {}}
                  showSecondSelect={false}
                  placeholder="Select Status"
                  serachStaff={hanldeSearchStaff}
                />
                <StyledPara />
                <ToggleButtonDiv>
                  {chatOptions.map((val, index) => (
                    <Button
                      key={index}
                      text={val.title}
                      fontSize="14px"
                      width="250px"
                      height="40px"
                      padding="0px"
                      margin="5px"
                      smHeight="auto"
                      borderRadius="10px"
                      backgroundColor={
                        activeTab === val.value ? "#00ADEF" : "white"
                      }
                      color={activeTab === val.value ? "white" : "black"}
                      onClick={() => handleButtonClick(val.value)}
                    />
                  ))}
                </ToggleButtonDiv>
                <Line border="0.0999999999999999px solid rgba(0, 0, 0, 0.4)" />
                <StyledPara />
                <div style={{ overflowY: "scroll", height: "65vh" }}>
                  <MessageContext
                    currentUser={currentUser.user.email}
                    chats={chats}
                    handleUser={handleFirstSelect}
                    users={users}
                    activeTab={activeTab}
                  />
                </div>
              </SideBoxStyle>
            </Grid>
            <Grid item xs={8} lg={9}>
              <ChatBox
                 getChatList={getChatList}
                 fetchUsers={fetchUsers}
                data={state}
                currentUser={currentUser.user.fireBaseUid}
                userName={currentUser.user.firstName}
              />
            </Grid>
          </Grid>
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default Chat;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  height: 100%;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

const SideBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  grid-gap: 5px;
  background: #eff3fd;
  width: 100%;
  padding: 15px;
  min-height: 82%;
`;

const SideBoxButtonStyle = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  height: 100px;
  margin-top: 10px;
  justify-content: space-between;
  &.active {
    background-color: rgba(0, 173, 239, 1);
    color: white;
  }
`;

const SideBoxTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
const HeadingTwo = styled.h2`
  font-weight: 400;
  font-size: 25px;
  padding: 10px;
`;

export const ToggleButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background: white;
  align-item: center;
  border: 1px solid #00adef;
  margin-bottom: 12px;
`;

export const StyledPara = styled.p`
  margintop: 12px;
`;
