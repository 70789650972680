export const dummy = `Under penalties of perjury, I declare that I have examined a copy of
the income tax return (original or amended) I am now authorizing,
and to the best of my knowledge and belief, it is true, correct, and
complete. I further declare that the amounts in Part I above are the
amounts from the income tax return (original or amended) I am now
authorizing. I consent to allow my intermediate service provider,
transmitter, or electronic return originator (ERO) to send my return
to the IRS and to receive from the IRS (a) an acknowledgement of
receipt or reason for rejection of the transmission, (b) the reason
for any delay in processing the return or refund, and (c) the date
of any refund. If applicable, I authorize the U.S. Treasury and its
designated Financial Agent to initiate an ACH electronic funds
withdrawal (direct debit) entry to the financial institution account
indicated in the tax preparation software for payment of my federal
taxes owed on this return and/or a payment of estimated tax, and the
financial institution to debit the entry to this account. This
authorization is to remain in full force and effect until I notify
the U.S. Treasury Financial Agent to terminate the authorization. To
revoke (cancel) a payment, I must contact the U.S. Treasury
Financial Agent at 1-888-353-4537. Payment cancellation requests
must be received no later than 2 business days prior to the payment
(settlement) date. I also authorize the financial institutions
involved in the processing of the electronic payment of taxes to
receive confidential information necessary to answer inquiries and
resolve issues related to the payment. I further acknowledge that
the personal identification number (PIN) below is my signature for
the income tax return (original or amended) I am now authorizing
and, if applicable, my Electronic Funds Withdrawal Consent.
Taxpayer’s PIN: check one box only I authorize ERO firm name to
enter or generate my PIN Enter five digits, but don’t enter all
zeros as my signature on the income tax return (original or amended)
I am now authorizing.`;

export const termConditionTab = ["Select Style", "Draw", "Upload"];
