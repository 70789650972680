import { useContext } from "react";
import { Modal } from "@mui/material";
import BusinessModal from "../../assets/modal/BusinessModal.png";
import {
  BusinessProfileContainer,
  BusinessProfileWrapper,
} from "./Modals.style";
import FlexComp from "../reusable/flex/Flex";
import { Span } from "../reusable/typography/Typography.style";
import { Button } from "../reusable/button/Button";
import { ProfileContext } from "../../context/ProfileContext";

const BusinessProfileModal = ({ setOpenModal, openModal, handleRoleChange }) => {
  const { setRole } = useContext(ProfileContext);

  const handleActivation = () => {
    setRole(2); // Set role to Business (assuming 2 is the Business role)
    setOpenModal(false); // Close the modal
    
  };

  const handleSkip = () => {
    setOpenModal(false); // Close the modal
    sessionStorage.setItem("roleSelected", true);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)} // Close modal when backdrop is clicked
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BusinessProfileContainer>
        <img src={BusinessModal} alt="BusinessModal" />
        <BusinessProfileWrapper>
          <FlexComp
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gridGap="10px"
          >
            <Span color="#000000" fontSize="22px" fontWeight="500">
              Active
            </Span>
            <Span color="#000000" fontSize="22px" fontWeight="700">
              Business Profile{" "}
            </Span>
          </FlexComp>
          <Span color="#000000" fontSize="22px" fontWeight="500">
            Are you filing taxes for a business?
          </Span>
          <FlexComp
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gridGap="10px"
          >
            <Button
              text="Skip"
              width="40%"
              fontSize="18px"
              padding="12px 25px 12px 25px"
              onClick={handleSkip}
              backgroundColor="#EFF3FD"
              color="#AEB6CF"
            />
            <Button
              text="Active"
              width="40%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              alignSelf="flex-end"
              onClick={handleActivation}
            />
          </FlexComp>
        </BusinessProfileWrapper>
      </BusinessProfileContainer>
    </Modal>
  );
};

export default BusinessProfileModal;
