import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

const ThemeProvider = ({ children }) => {
  const theme = {
    colors: {
      primary: "#0b2558",
      secondary: "#00adef",
    },
  };
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
