import React from "react";
import { TextCheckContainer } from "./TextCheckbox.style";
import { Checkbox } from "@mui/material";

const TextCheckbox = ({ text, gridGap, isChecked, onChange, }) => {
  return (
    <TextCheckContainer gridGap={gridGap}>
      <Checkbox checked={isChecked} onChange={onChange} />

      <p>{text}</p>
    </TextCheckContainer>
  );
};

export default TextCheckbox;
