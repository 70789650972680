import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { FormHelperText, Stack } from "@mui/material";
import { roadExpSchema } from "./Schema";
import { RoadFieldsTop } from "data/client";
import FlexComp from "components/reusable/flex/Flex";
import { Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import ExpAccordion from "components/reusable/accordion/ExpAccordian";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const detail = {
  name: "",
  amount: ""
};

const initialValues = {
  hotel: "",
  laundry: "",
  parking: "",
  showers: "",
  tolls: "",
  truckSupplies: "",
  weightFees: "",
  prepass: "",
  meals: "",
  rentalCar: "",
  airfare: "",
  noOfDaysInRoads: "",
  otherExpenseArr: [],
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const RoadExp = ({ setCurrentTab }) => {
  const [expenses, setExpenses] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const {setOpen} = useContext(PageLoaderContext);
  const clientId = localStorage.getItem("clientId");
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const [tabsData, setTabsData] = useState({})

  const selectedDriverInfo = JSON.parse(Info || "{}");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");

  const {values, errors, touched, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: roadExpSchema,
    onSubmit: async(values, {setSubmitting}) => {
      try {
        if (!clientId) {
          errorToast('Client Id Or Truck driver Id Not Found.');
          return;
        } else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
        const {_id: truckDriverId, truckDriverRoadExpId} = selectedDriverInfo;
        await apiReq(
          `${(truckDriverRoadExpId?._id || tabsData?._id )? API_ROUTES.updateRoadExp: API_ROUTES.addRoadExp}`, 
          "POST", 
          {
            clientId, 
            truckDriverMainId: truckDriverMainId || truckDriverId || "", 
            truckDriverRoadExpId: truckDriverRoadExpId?._id || tabsData?._id, 
            ...values,
            otherExpenseArr: values.otherExpenseArr.filter(val => val.name)
          });
          infoToast(truckDriverRoadExpId?._id? "Road expense updated succesfully": "Road expense saved succesfully");
          setTimeout(() => {
            setCurrentTab(6);
          }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });
  const newExpense = useMemo(() => ({
    title: `Additional Expense ${expenses.length + 1} `,
    fields: [
      {
        label: "Expense Name",
        placeholder: "Enter Name",
        defaultValue: "",
        key: "name",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
      {
        label: "Amount",
        placeholder: "$",
        defaultValue: "",
        key: "amount",
        id: "outlined-size-small",
        size: "size",
        width: "32%",
        value: "",
      },
    ], // You can initialize with any default fields if needed
  }), [expenses]);
  const addExpense = () => {
    setFieldValue("otherExpenseArr", [...values.otherExpenseArr, detail]);
    const newExpenses = [...expenses, newExpense];
  setExpenses(newExpenses);
    setOpenIndex(newExpenses.length - 1);
  };

  useEffect(() => {
    const fetchRoadExp = async() => {
      try {
        setOpen(true);
        const {truckDriverRoadExpId} = selectedDriverInfo;
        if(truckDriverRoadExpId)  {
          const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getRoadExp, "POST", {truckDriverRoadExpId: truckDriverRoadExpId._id});
          setExpenses(Array.from(data.otherExpenseArr).fill(newExpense));
          setValues(data);
        }
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setOpen(false);
      }
    }
    selectedDriverInfo?.truckDriverRoadExpId && fetchRoadExp();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverRoadExpId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverRoadExpId?._id && localStorage.setItem("tabs", JSON.stringify({ ...menuTabs, office_exp: false}));
        truckDriverRoadExpId?._id && setValues(truckDriverRoadExpId);
        truckDriverRoadExpId?._id && setExpenses(Array.from(truckDriverRoadExpId.otherExpenseArr).fill(newExpense));
        setTabsData(truckDriverRoadExpId)
      }
      fetchTruckDriverDetails();
    }
  },[]);

  const deleteExpense = (index) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  
    // Update values.otherExpenseArr array
    const updatedOtherExpenseArr = [...values.otherExpenseArr];
    updatedOtherExpenseArr.splice(index, 1);
    setFieldValue("otherExpenseArr", updatedOtherExpenseArr);
  
    // Update openIndex if needed
    if (openIndex === index) {
      setOpenIndex(null);
    } else if (openIndex > index) {
      setOpenIndex(openIndex - 1);
    }
  };
  

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize='18px' fontWeight='500'>
            Road Expences
          </HeadingFive>
          <BasicInfoInputWrapper>
            {RoadFieldsTop.map(
              ({ label, id, key, size, width, placeholder }, index) => (
                <Stack key={index} direction="column" sx={{width}}>
                  <Input
                    id={id}
                    size={size}
                    label={label}
                    value={values[key]}
                    placeholder={placeholder}
                    onChange={e => {
                      let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                      setFieldValue(key, val)
                    }}
                  />
                  <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                </Stack>
              )
            )}
          </BasicInfoInputWrapper>

          {expenses.map((expense, index) => (
            <FlexComp
              key={index}
              direction='column'
              justifyContent='flex-start'
              gridGap='5px'
            >
              <ExpAccordion
                item={expense}
                key={index}
                index={index}
                openIndex={openIndex}
                setOpenIndex={setOpenIndex}
                setFieldValue={setFieldValue}
                values={values.otherExpenseArr[index]}
                deleteExpense={deleteExpense} // Pass the deleteExpense function
                Icon='RedTrash'
              />
            </FlexComp>
          ))}
          <Button
            text='+ Add Other Expense'
            type="button"
            width='auto'
            fontSize='18px'
            backgroundColor='#00ADEF'
            onClick={addExpense}
          />
          <Button
            text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"}
            type="submit"
            width='15%'
            fontSize='18px'
            backgroundColor='#0B2558'
            alignSelf='flex-end'
          />
        </BasicInfoContainer>
      </form>
    </>
  );
};

export default RoadExp;
