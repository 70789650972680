import { useContext, useEffect, useState } from "react";
import { Input } from "components/reusable/input/Inputs";
import { RentFieldsTop, UtilFieldsTop } from "data/client";
import { Button } from "components/reusable/button/Button";
import FlexComp from "components/reusable/flex/Flex";
import { BasicInfoContainer, BasicInfoInputWrapper } from "./TruckDriver.style";
import AdditionalNotes from "components/modals/AdditionalNotes";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { utilitiesSchema } from "./Schema";
import { useFormik } from "formik";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { FormHelperText, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { MuiPhone } from "components/reusable/PhoneNumber";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";

const initialValues = {
  truckLease: "",
  storageLease: "",
  other: "",
  mobileNumber: "",
  additionalNotes: "",
};

const infoToast = (message = '') => toast.info(message);
const errorToast = (message = '') => toast.error(message);

const Rent = () => {
  const navigate = useNavigate();
  const {setOpen} = useContext(PageLoaderContext);
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const Info = localStorage.getItem("selectedDriverInfo") || "";
  const truckDriverMainId = localStorage.getItem("truckDriverMainId");
  const selectedDriverInfo = JSON.parse(Info || "{}");

  const {values, errors, touched, isSubmitting, setValues, setFieldValue, handleSubmit} = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: utilitiesSchema,
    onSubmit: async(values, {setSubmitting}) => {
      try {
        if (!clientId) {
          errorToast('Client Id Or Truck driver Id Not Found.');
          return;
        } else if (!selectedDriverInfo?._id && !truckDriverMainId) {
          errorToast('Required IDs Not Found.');
          return;
        }
          const {_id: truckDriverId, truckDriverRentAndLeaseId} = selectedDriverInfo;
          await apiReq(`${truckDriverRentAndLeaseId?._id ? API_ROUTES.updateUtilities: API_ROUTES.addUtilities}`, "POST", {clientId, truckDriverMainId: truckDriverMainId || truckDriverId || "", truckDriverUtilitiesId: truckDriverRentAndLeaseId?._id, ...values});
          infoToast(truckDriverRentAndLeaseId?._id? "Rent info updated succesfully": "Rent info saved succesfully");
          setTimeout(() => {
            setIsAdditionalModal(true)
          }, 1500);
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.')
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const fetchUtilities = async() => {
      try {
        setOpen(true);
        const {truckDriverRentAndLeaseId, additionalNotes} = selectedDriverInfo;
        const {data: {data = {}} = {}} = await apiReq(API_ROUTES.getUtilities, "POST", {truckDriverUtilitiesId: truckDriverRentAndLeaseId._id});
        truckDriverRentAndLeaseId && setValues({...data, additionalNotes});
      } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setOpen(false);
      }
    }
    selectedDriverInfo?.truckDriverRentAndLeaseId && fetchUtilities();
    if(truckDriverMainId) {
      const fetchTruckDriverDetails = async() => {
        const {data: {data: {truckDriverRentAndLeaseId = {}} = {}} = {}} = await apiReq(API_ROUTES.getTruckDriver + truckDriverMainId, "POST");
        truckDriverRentAndLeaseId?._id && setValues(truckDriverRentAndLeaseId);
      }
      fetchTruckDriverDetails();
    }
  },[]);

  const handleNotesSubmit = async(additionalNotes = '') => {
    try {
      await apiReq(API_ROUTES.truckDriverAdditionalNotes, "POST", { clientId, additionalNotes, truckDriverId: selectedDriverInfo._id || truckDriverMainId || ""}, API_BASE_URL, false);
      setIsAdditionalModal(false)
      infoToast('Addiitonal notes saved successfully');
      setTimeout(() => {
        setIsValidateTaxModal(true);
      }, 2000);
    } catch (error) {
        errorToast(error?.message || 'An unexpected error occurred. Please try again later.');
    }
  }
  
  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize='18px' fontWeight='600'>
            Rent & Lease
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              flexWrap='wrap'
              gridGap='25px'
            >
              {RentFieldsTop.map(
                (
                  { label, id,key, size, width, placeholder },
                  index
                ) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <Input
                      label={label}
                      id={id}
                      size={size}
                      value={values[key]}
                      placeholder={placeholder}
                      onChange={e => {
                        let val = e.target.value.replace(/\D/g, '').slice(0, 9);
                        setFieldValue(key, val)
                      }}
                    />
                    <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper>
          {/* <HeadingFive fontSize='18px' fontWeight='600'>
            Utilities
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              flexWrap='wrap'
              gridGap='25px'
            >
              {UtilFieldsTop.map(
                (
                  { label, id, key, size, width, placeholder },
                  index
                ) => (
                  <Stack key={index} direction="column" sx={{width}}>
                    <MuiPhone
                      value={values[key]}
                      handleChange={value =>  setFieldValue(`mobileNumber`,value)}
                   />
                    <FormHelperText error>{touched?.[key] && errors?.[key]}</FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
          </BasicInfoInputWrapper> */}
          <Button
            text={isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Submit"}
            type="submit"
            width='15%'
            fontSize='18px'
            backgroundColor='#0B2558'
            alignSelf='flex-end'
          />
        </BasicInfoContainer>
        <AdditionalNotes
          additionalNotes={values.additionalNotes}
          isModalOpen={isAdditionalModal}
          closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
          openSecondModal={(notes) => handleNotesSubmit(notes)}
        />
        <ValidateTaxModal 
          isModalOpen={isValidateTaxModal}
          closeModal={() => {
            setIsValidateTaxModal(!isValidateTaxModal)
            setTimeout(() => {
              navigate("/truck-driver")
            }, 1500)
          }}
          handleYesClick ={() => {
            infoToast('Submitted successfully');
            setTimeout(() => {
              navigate('/review');
            }, 1000);
          }}
          paragraph='Are you sure you want to validate this Tax Form?'
        />
      </form>
    </>
  );
};

export default Rent;
