import styled from "styled-components";

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const NavRightWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 25px;
  position: relative;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0px;

`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  cursor: pointer;
  
`;

export const BellBg = styled.div`
  position: relative;
  background: #fff;
  height: 48px;
  width: 50px;
  border-radius: 5px;
  cursor: pointer;
  img {
    position: absolute;
    top: 25%;
    right: 28%;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: 25%;
`;

export const Input = styled.input`
  width: 100%;
  background: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 10px 10px 40px;
  outline: none;
  color: #aeb6cf;
  font-size: 14px;
  font-weight: 400;
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
`;
