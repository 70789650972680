import { Modal } from "@mui/material";
import {
  RentalFormPreviewBody,
  RentalFormPreviewContainer,
  RentalFormPreviewHeader,
  RentalPreviewBox,
  RentalPreviewBoxHeader,
} from "./Modals.style";
import { Span } from "../reusable/typography/Typography.style";
import CloseIcon from "@mui/icons-material/Close";
import FlexComp from "components/reusable/flex/Flex";
import { Line } from "../client/rentalProperty/RentalProperty.style";
import { Button } from "components/reusable/button/Button";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { ReviewConfirmationBtnsWrapper } from "./review/ReviewConfirmation/ReviewAndConfirmation.style";
import { useReactToPrint } from "react-to-print";
import { apiReq } from "services/Requests";
import TermsCondition from "./review/TermsCondition";
import html2pdf from "html2pdf.js";
import dayjs from "dayjs";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";

const successToast = (message = "") => toast.success(message);
const errorToast = (message = "") => toast.error(message);
const ReviewFormPreview = ({
  openPrevModal,
  setOpenPrevModal,
  values = {},
  reviewAndSubmition,
  rentalPropertyID,
  setIs1099Modal,
  download = false,
  check1099Form,
  closeTermModal,
  showTermsCondition,
  setShowTermsCondition,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [showTermsCondition, setShowTermsCondition] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const userName = localStorage.getItem("userName");
  const initials = localStorage.getItem("initials");

  const signatureData = {
    fullName: userName,
    initials: initials,
    signatureStyle: "Ariel",
    eFileTaxReturnCheck: false,
    drawCheck: false,
    uploadCheck: false,
    signatureFile: null,
  };
  // console.log(values, "values");
  const handleSubmitReview = async () => {
    try {
      setLoading(true);

      if (check1099Form) {
        setShowTermsCondition(true);
      } else {
        setIs1099Modal(true);
      }
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error while submit docs. please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnSaveSignature = async (signatureData) => {
    console.log("Received signature data:", signatureData);

    // Early return if signatureFile is missing
    if (!signatureData.signatureFile) {
      console.error("signatureFile is missing");
      errorToast("Signature file is required.");
      return;
    }

    try {
      // Prepare FormData for submission
      const formData = new FormData();

      formData.append("clientId", clientId);
      const formId = [rentalPropertyID];
      formData.append("rentalPropertyId", JSON.stringify(formId));
      formData.append("status", "Document Submitted");
      formData.append("signatureData", JSON.stringify(signatureData));

      await apiReq(
        API_ROUTES.SubmitAllForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );
      successToast("Form submitted successfully.");
      setTimeout(() => {
        setOpenPrevModal(false);
        fetchData();
      }, 1500);
    } catch (error) {
      // Handle errors during submission
      console.error("Error submitting form:", error);
      errorToast(`Error submitting form: ${error.message || "Unknown error"}.`);
    }
  };

  const componentRef = useRef();
  const handleDownload = () => {
    const element = componentRef.current;
    const opt = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based API
    html2pdf().set(opt).from(element).save();
  };

  return (
    <Modal
      open={openPrevModal}
      onClose={() => setOpenPrevModal(!setOpenPrevModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <RentalFormPreviewContainer>
        <RentalFormPreviewHeader>
          <Span color="#000000" fontSize="24px" fontWeight="600">
            Rental Property
          </Span>
          <CloseIcon
            sx={{ color: "#000000", cursor: "pointer" }}
            onClick={() => setOpenPrevModal(!openPrevModal)}
          />
        </RentalFormPreviewHeader>
        <RentalFormPreviewBody ref={componentRef}>
          <RentalPreviewBox>
            <FlexComp
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              gridGap="10px"
              width="100%"
            >
              <RentalPreviewBoxHeader>
                <Span color="#FFFFFF" fontSize="18px" fontWeight="500">
                  Property Address
                </Span>
              </RentalPreviewBoxHeader>
            </FlexComp>
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Street
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.address?.street}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  City
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.address?.city}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  State
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.address?.state}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  zipCode
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.address?.zipCode}
                </Span>
              </FlexComp>
            </FlexComp>
            <Line />

            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Date Placed in Service
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.taxYear
                    ? dayjs(values?.taxYear).format("YYYY-MM-DD")
                    : "N/A"}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Rent Charge
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {/* {values?.address?.rentCharge} */}
                  {values?.rentCharge
                    ? dayjs(values?.rentCharge).format("YYYY-MM-DD")
                    : "N/A"}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Purchase Price
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.address?.purchasePrice}
                </Span>
              </FlexComp>
            </FlexComp>
            <Line />
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Fair Market Value
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="400">
                  {values?.address?.fairMarketValue}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Fair Rental Days
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="400">
                  {values?.address?.fairRentalDays}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                gridGap="10px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Property Type (Sch E)
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="400">
                  {values?.address?.propertyType}
                </Span>
              </FlexComp>
            </FlexComp>
          </RentalPreviewBox>
          <RentalPreviewBox>
            <FlexComp
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              gridGap="10px"
            >
              {console.log(values, "vallll")}
              <Span color="#000000" fontSize="20px" fontWeight="500">
                Month: {values?.monthYear?.month}
              </Span>
              <RentalPreviewBoxHeader>
                <Span color="#FFFFFF" fontSize="18px" fontWeight="500">
                  Income
                </Span>
              </RentalPreviewBoxHeader>
            </FlexComp>
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Rental Income
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.income?.rentalIncome}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Deposit
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.income?.deposit}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Late Fees
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.income?.lateFees}
                </Span>
              </FlexComp>
            </FlexComp>
            <FlexComp
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              gridGap="10px"
            >
              <RentalPreviewBoxHeader>
                <Span color="#FFFFFF" fontSize="18px" fontWeight="500">
                  Expenses
                </Span>
              </RentalPreviewBoxHeader>
            </FlexComp>
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Management Fees
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.managementFees}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Advertising for Tenants
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.advertisingForTenants}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Association Dues (HOA)
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.associationDues}
                </Span>
              </FlexComp>
            </FlexComp>
            <Line />
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Cleaning & Maintenace
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.cleaningAndMaintenance}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Repairs & Maintenance
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.repairsAndMaintenance}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Insurance
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.insurance}
                </Span>
              </FlexComp>
            </FlexComp>
            <Line />
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Property Tax
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.propertyTax}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Utilities
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.utilities}
                </Span>
              </FlexComp>
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Water Charges
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.waterCharges}
                </Span>
              </FlexComp>
            </FlexComp>
            <Line />
            <FlexComp
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="20px"
            >
              <FlexComp
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="5px"
              >
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  Misc. Fees
                </Span>
                <Span color="#000000" fontSize="16px" fontWeight="500">
                  {values?.expenses?.miscFees}
                </Span>
              </FlexComp>
            </FlexComp>
          </RentalPreviewBox>
        </RentalFormPreviewBody>

        {reviewAndSubmition && (
          <ReviewConfirmationBtnsWrapper>
            <Button
              text="Edit"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              onClick={() => {
                localStorage.setItem("rentalPropertyMainId", rentalPropertyID);
                navigate("/add-rental");
              }}
            />
            <Button
              text={
                loading ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Confirm"
              }
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#00ADEF"
              onClick={handleSubmitReview}
            />
          </ReviewConfirmationBtnsWrapper>
        )}

        <ReviewConfirmationBtnsWrapper style={{ padding: "15px 15px" }}>
          {!reviewAndSubmition && (
            <Button
              text="OK"
              width="15%"
              fontSize="20px"
              padding="10px 30px 10px 30px"
              alignSelf="flex-end"
              onClick={() => setOpenPrevModal(!openPrevModal)}
            />
          )}
          {download && (
            <TitleButton
              style={{ padding: "10px 20px 10px 20px" }}
              onClick={handleDownload}
            >
              Download
            </TitleButton>
          )}
        </ReviewConfirmationBtnsWrapper>

        {showTermsCondition && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <TermsCondition
              isModalOpen={showTermsCondition}
              closeModal={() => closeTermModal()}
              onSaveSignature={handleOnSaveSignature}
            />
          </div>
        )}
      </RentalFormPreviewContainer>
    </Modal>
  );
};

export default ReviewFormPreview;
