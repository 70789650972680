import { useContext, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Input } from "components/reusable/input/Inputs";
import { BIFields, PTCFields, OBMFields, npFillingStatus } from "data/client";
import { Button } from "components/reusable/button/Button";
import FlexComp from "components/reusable/flex/Flex";
import {
  BasicInfoContainer,
  BasicInfoInputWrapper,
  FillingStatusCheckBoxes,
  FillingStatusWrapper,
} from "./NonProfit.style";
import { FormHelperText, Stack } from "@mui/material";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import Signature from "../../modals/Signature";
import { stateOptions } from "data/clientData/businessFormation";
import NonProfitAccordion from "components/reusable/accordion/NomProfitAccordion";
import { useFormik } from "formik";
import { nonProfitFormationSchema } from "./Schema";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { useEffect } from "react";
import { MuiPhone } from "components/reusable/PhoneNumber";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { useNavigate } from "react-router-dom";

const officersObject = {
  firstName: "",
  lastName: "",
  middleName: "",
  title: "",
  city: "",
  street: "",
  state: "",
  zipCode: "",
};

const fillOptions = [
  { label: "Charitable", key: "charitableCheck" },
  { label: "Religious", key: "religiousCheck" },
  { label: "Educational", key: "educationalCheck" },
  { label: "Scientific", key: "scientificCheck" },
  { label: "Literary", key: "literaryCheck" },
  { label: "PublicSafety", key: "testingForPublicSafetyCheck" },
];

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const NonProfitFormation = ({ setCurrentTab }) => {
  const [isLoading, setLoading] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const [isTermModal, setIsTermModal] = useState(false);
  const { setOpen } = useContext(PageLoaderContext);
  const [Data, setData] = useState();
  const businessFormationId = localStorage.getItem("nonProfitId");
  const edit = localStorage.getItem("nonProfitEdit");
  const navigate = useNavigate();
  const nonProfitFormationId = businessFormationId?.replace(/"/g, "");
  const [organizationPurpose, setOrganizationPurpose] = useState(
    npFillingStatus.reduce((acc, item) => {
      acc[item.toLowerCase()] = false;
      return acc;
    }, {})
  );
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const initialValues = {
    basicInfo: {
      nonProfitName: "",
      ein: "",
      city: Data?.city || "",
      street: Data?.street || "",
      state: Data?.state || "",
      zipCode: Data?.zipCode || "",
    },
    personOfContact: {
      firstName: Data?.firstName || "",
      lastName: Data?.lastName || "",
      middleName: Data?.middleName || "",
      ssn: "",
      title: "",
      mobileNumber: Data?.phone || "",
      city: Data?.city || "",
      street: Data?.street || "",
      state: Data?.state || "",
      zipCode: Data?.zipCode || "",
    },
    officersBoardTrustees: [officersObject],
    explaination: "",
    // organizationPurpose: "",
    charitableCheck: false,
    religiousCheck: false,
    educationalCheck: false,
    scientificCheck: false,
    literaryCheck: false,
    testingForPublicSafetyCheck: false,
    // signatureData: {
    //   fullName: "",
    //   initials: "",
    //   signatureStyle: "",
    //   eFileTaxReturnCheck: false,
    // },
  };

  const handleCheckboxChange = (itemName, isChecked) => {
    const updatedOrganizationPurpose = { ...organizationPurpose };
    updatedOrganizationPurpose[itemName.toLowerCase()] = isChecked;
    setOrganizationPurpose(updatedOrganizationPurpose);
  };

  const [nonProfit, setnonProfit] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const addExpense = () => {
    const newExpense = {
      title: `Add Member # ${nonProfit.length + 1} `,
      fields: [
        {
          label: "First Name *",
          placeholder: "Enter First Name",
          defaultValue: "",
          id: "firstName",
          size: "size",
          width: "32%",
          value: "",
        },
        {
          label: "Middle Name",
          placeholder: "Enter Middle Name",
          defaultValue: "",
          id: "middleName",
          size: "size",
          width: "32%",
          value: "",
        },
        {
          label: "Last Name *",
          placeholder: "Enter Last Name",
          defaultValue: "",
          id: "lastName",
          size: "size",
          width: "32%",
          value: "",
        },
        {
          label: "Title *",
          placeholder: "Enter Title",
          defaultValue: "",
          id: "title",
          size: "size",
          width: "32%",
          value: "",
        },
      ],
    };
    setFieldValue(`officersBoardTrustees`, [
      ...values.officersBoardTrustees,
      officersObject,
    ]);
    setnonProfit([...nonProfit, newExpense]);
  };

  const deleteExpense = (index) => {
    const updatedExpenses = [...nonProfit];
    updatedExpenses.splice(index - 1, 1);
    setFieldValue(
      "officersBoardTrustees",
      values.officersBoardTrustees.splice(index, 1)
    );
    setnonProfit(updatedExpenses);
  };

  const handleSaveSignature = async () => {
    try {
      if (!clientId) {
        errorToast("Client ID not found!");
        return;
      }

      setLoading(true);

      // Construct base payload
      const basePayload = {
        basicInfo: JSON.parse(JSON.stringify(values.basicInfo)),
        personOfContact: JSON.parse(JSON.stringify(values.personOfContact)),
        officersBoardTrustees: JSON.parse(
          JSON.stringify(values.officersBoardTrustees)
        ),
        explaination: values.explaination,
        charitableCheck: values.charitableCheck,
        religiousCheck: values.religiousCheck,
        educationalCheck: values.educationalCheck,
        scientificCheck: values.scientificCheck,
        literaryCheck: values.literaryCheck,
        testingForPublicSafetyCheck: values.testingForPublicSafetyCheck,
      };

      // Add conditional fields
      if (edit) {
        basePayload.nonProfitFormationId = nonProfitFormationId;
      } else {
        basePayload.clientId = clientId.toString();
      }

      if (selectedOption) {
        basePayload.organizationPurpose = selectedOption;
      }

      // Create FormData
      const formData = new FormData();

      // Add each key-value pair to FormData
      Object.keys(basePayload).forEach((key) => {
        // If the value is an object, stringify it
        const value =
          typeof basePayload[key] === "object"
            ? JSON.stringify(basePayload[key])
            : basePayload[key];
        formData.append(key, value);
      });

      // Make API call
      const response = await apiReq(
        edit ? API_ROUTES.updateNonProfit : API_ROUTES.nonProfitFormation,
        "POST",
        formData, // Send formData instead of JSON
        API_BASE_URL,
        false // Set to false since we're using FormData
      );

      if (
        response?.data?.status === 200 ||
        response?.status === 200 ||
        response?.data?.status === 201 ||
        response?.status === 201
      ) {
        infoToast(
          edit ? "Form updated successfully" : "Form saved successfully"
        );
        navigate("/non-profit-formation");
      } else {
        throw new Error(response?.data?.message || "API call failed");
      }
    } catch (error) {
      setIsTermModal(false);
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: nonProfitFormationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // setIsTermModal(!isTermModal);
        handleSaveSignature();
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const nonFormation = async () => {
      try {
        setOpen(true);
        const response = await apiReq(`${API_ROUTES.getNonFormation}`, "POST", {
          nonProfitFormationId,
        });
        const { data } = response.data || {};

        const updatedData = {
          ...data,
        };
        if (updatedData.organizationPurpose) {
          const organizationPurposeFromData = updatedData.organizationPurpose;

          setOrganizationPurpose(organizationPurposeFromData);

          // Delete organizationPurpose from updatedData
          delete updatedData.organizationPurpose;
        }
        // Remove the original key
        if (!data) {
          // Handle empty or null response
          setValues(initialValues);
        } else {
          setValues(updatedData);
        }
      } catch (error) {
        console.error("API Error:", error);
        // alert("An unexpected error occurred. Please try again later.");
      } finally {
        setOpen(false);
      }
    };
    nonFormation();
  }, [setValues]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <BasicInfoContainer>
          <HeadingFive fontSize="24px" fontWeight="500">
            Non-Profit Formation
          </HeadingFive>
          <BasicInfoInputWrapper>
            <HeadingFive fontSize="17px" fontWeight="600" color="#1B1B1B">
              Basic Information
            </HeadingFive>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
            >
              {BIFields.map(
                (
                  { label, id, defaultValue, size, width, placeholder },
                  index
                ) => (
                  <Stack key={index} direction="column" sx={{ width }}>
                    {id === "ein" ? (
                      <Input
                        label="EIN (if applicable)"
                        id="ssn"
                        size="size"
                        placeholder="XX-XXXXXXX"
                        onChange={(e) => {
                          const formattedSSN = e.target.value
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d{7})/, "$1-$2");
                          if (formattedSSN.length <= 10)
                            setFieldValue(`basicInfo[${id}]`, formattedSSN);
                        }}
                        value={values.basicInfo[id]}
                      />
                    ) : (
                      <Input
                        key={index}
                        label={label}
                        id={id}
                        defaultValue={defaultValue}
                        size={size}
                        width="100%"
                        placeholder={placeholder}
                        required
                        onChange={(e) => {
                          setFieldValue(`basicInfo[${id}]`, e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.basicInfo[id]}
                      />
                    )}
                    <FormHelperText error>
                      {touched?.basicInfo?.[id] && errors?.basicInfo?.[id]}
                    </FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Address
            </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Street *"
                  id="street"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={(e) => {
                    setFieldValue(`basicInfo.street`, e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.basicInfo.street}
                  error={touched.street && errors.street}
                />
                <FormHelperText error>
                  {touched?.basicInfo?.street && errors?.basicInfo?.street}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="City *"
                  id="city"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={(e) => {
                    setFieldValue(`basicInfo.city`, e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.basicInfo.city}
                  error={touched.city && errors.city}
                />
                <FormHelperText error>
                  {touched?.basicInfo?.city && errors?.basicInfo?.city}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values?.basicInfo?.state}
                  onChange={(_, newVal) => {
                    setFieldValue("basicInfo.state", newVal?.value || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="State *" />
                  )}
                />
                <FormHelperText error>
                  {touched?.basicInfo?.state && errors?.basicInfo?.state}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Zip Code"
                  id="zipCode"
                  defaultValue=""
                  size="size"
                  placeholder="XXXXX-XXXX"
                  required
                  onChange={(e) => {
                    let zipCodeValue = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue =
                        zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    setFieldValue(`basicInfo.zipCode`, zipCodeValue);
                  }}
                  onBlur={handleBlur}
                  value={values.basicInfo.zipCode}
                />
                <FormHelperText error>
                  {touched?.basicInfo?.zipCode && errors?.basicInfo?.zipCode}
                </FormHelperText>
              </Stack>
            </FlexComp>
          </BasicInfoInputWrapper>
          <HeadingFive fontSize="17px" fontWeight="600" color="#1B1B1B">
            Person of Contact
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
              width="100%"
            >
              {PTCFields.map(
                (
                  { label, id, defaultValue, size, width, placeholder },
                  index
                ) => (
                  <Stack key={index} direction="column" sx={{ width }}>
                    {id === "mobileNumber" ? (
                      <MuiPhone
                        value={values.personOfContact?.[id]}
                        handleChange={(value) =>
                          setFieldValue(`personOfContact[${id}]`, value)
                        }
                      />
                    ) : (
                      <Input
                        label={label}
                        id={id}
                        defaultValue={defaultValue}
                        size={size}
                        width="100%"
                        placeholder={placeholder}
                        onChange={(e) => {
                          setFieldValue(
                            `personOfContact[${id}]`,
                            e.target.value
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.personOfContact[id]}
                      />
                    )}
                    <FormHelperText error>
                      {touched?.personOfContact?.[id] &&
                        errors?.personOfContact?.[id]}
                    </FormHelperText>
                  </Stack>
                )
              )}
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="EIN *"
                  id="ssn"
                  size="size"
                  width="100%"
                  placeholder="XX-XXXXXXX"
                  onChange={(e) => {
                    const formattedSSN = e.target.value
                      .replace(/\D/g, "")
                      .replace(/(\d{2})(\d{7})/, "$1-$2");
                    if (formattedSSN.length <= 11)
                      setFieldValue("personOfContact.ssn", formattedSSN);
                  }}
                  onBlur={handleBlur}
                  value={values.personOfContact.ssn}
                />
                <FormHelperText error>
                  {touched?.personOfContact?.ssn &&
                    errors?.personOfContact?.ssn}
                </FormHelperText>
              </Stack>
            </FlexComp>
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Address
            </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Street"
                  id="street"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  required
                  onChange={(e) => {
                    setFieldValue(`personOfContact.street`, e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.personOfContact.street}
                  error={touched.street && errors.street}
                />
                <FormHelperText error>
                  {touched?.personOfContact?.street &&
                    errors?.personOfContact?.street}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="City"
                  id="city"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={(e) => {
                    setFieldValue(`personOfContact.city`, e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.personOfContact.city}
                  error={touched.city && errors.city}
                />
                <FormHelperText error>
                  {touched?.personOfContact?.city &&
                    errors?.personOfContact?.city}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values.personOfContact?.state}
                  onChange={(_, newVal) => {
                    setFieldValue(`personOfContact.state`, newVal?.value || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="State *" />
                  )}
                />
                <FormHelperText error>
                  {touched?.personOfContact?.state &&
                    errors?.personOfContact?.state}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Zip Code"
                  id=" zipCode"
                  defaultValue=""
                  size="size"
                  width="100%"
                  placeholder="XXXXX-XXXX"
                  onChange={(e) => {
                    let zipCodeValue = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue =
                        zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    setFieldValue("personOfContact.zipCode", zipCodeValue);
                  }}
                  onBlur={handleBlur}
                  value={values.personOfContact.zipCode}
                  error={touched.zipCode && errors.zipCode}
                />
                <FormHelperText error>
                  {touched?.personOfContact?.zipCode &&
                    errors?.personOfContact?.zipCode}
                </FormHelperText>
              </Stack>
            </FlexComp>
          </BasicInfoInputWrapper>
          <HeadingFive fontSize="17px" fontWeight="600" color="#1B1B1B">
            Officers, Board Members, Trustees
          </HeadingFive>
          <BasicInfoInputWrapper>
            <FlexComp
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gridGap="25px"
              width="100%"
            >
              {OBMFields.map(
                ({ label, id, size, width, placeholder }, fieldIndex) => (
                  <Stack key={fieldIndex} direction="column" sx={{ width }}>
                    <Input
                      key={fieldIndex}
                      label={label}
                      size={size}
                      width="100%"
                      placeholder={placeholder}
                      id={`officersBoardTrustees[0].${id}`} // Update ID to use correct structure with array index
                      onChange={(e) => {
                        const updatedOfficers = [
                          ...values.officersBoardTrustees,
                        ];
                        updatedOfficers[0][id] = e.target.value;
                        setFieldValue(`officersBoardTrustees`, updatedOfficers); // Update field value setter
                      }}
                      onBlur={handleBlur}
                      value={values.officersBoardTrustees[0][id]} // Access value using correct structure with array index
                    />
                    <FormHelperText error>
                      {touched?.officersBoardTrustees?.[0]?.[id] &&
                        errors?.officersBoardTrustees?.[0]?.[id]}
                    </FormHelperText>
                  </Stack>
                )
              )}
            </FlexComp>
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Address
            </HeadingFive>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Street *"
                  id={`officersBoardTrustees[0].street`}
                  defaultValue={values.officersBoardTrustees[0].street}
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={(e) => {
                    const updatedOfficers = [...values.officersBoardTrustees];
                    updatedOfficers[0].street = e.target.value;
                    setFieldValue("officersBoardTrustees", updatedOfficers);
                  }}
                  onBlur={handleBlur}
                  value={values.officersBoardTrustees[0].street}
                />
                <FormHelperText error>
                  {touched.officersBoardTrustees &&
                    touched.officersBoardTrustees[0] &&
                    errors.officersBoardTrustees &&
                    errors.officersBoardTrustees[0] &&
                    touched.officersBoardTrustees[0].street &&
                    errors.officersBoardTrustees[0].street}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="City *"
                  id={`officersBoardTrustees[${0}].city`}
                  defaultValue={values.officersBoardTrustees[0].city}
                  size="size"
                  width="100%"
                  placeholder="Enter"
                  onChange={(e) => {
                    const updatedOfficers = [...values.officersBoardTrustees];
                    updatedOfficers[0].city = e.target.value;
                    setFieldValue("officersBoardTrustees", updatedOfficers);
                  }}
                  onBlur={handleBlur}
                  value={values.officersBoardTrustees[0].city}
                />
                <FormHelperText error>
                  {touched.officersBoardTrustees &&
                    touched.officersBoardTrustees[0] &&
                    errors.officersBoardTrustees &&
                    errors.officersBoardTrustees[0] &&
                    touched.officersBoardTrustees[0].city &&
                    errors.officersBoardTrustees[0].city}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={stateOptions}
                  value={values.officersBoardTrustees[0].state}
                  onChange={(_, newVal) => {
                    const updatedOfficers = [...values.officersBoardTrustees];
                    updatedOfficers[0].state = newVal?.value || "";
                    setFieldValue("officersBoardTrustees", updatedOfficers);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="State" />
                  )}
                />
                <FormHelperText error>
                  {touched.officersBoardTrustees &&
                    touched.officersBoardTrustees[0] &&
                    errors.officersBoardTrustees &&
                    errors.officersBoardTrustees[0] &&
                    touched.officersBoardTrustees[0].state &&
                    errors.officersBoardTrustees[0].state}
                </FormHelperText>
              </Stack>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Input
                  label="Zip Code *"
                  id={`officersBoardTrustees[${0}].zipCode`}
                  size="size"
                  width="100%"
                  placeholder="XXXXX-XXXX"
                  onChange={(e) => {
                    let zipCodeValue = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 9);
                    if (zipCodeValue.length > 5) {
                      zipCodeValue =
                        zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                    }
                    const updatedOfficers = [...values.officersBoardTrustees];
                    updatedOfficers[0].zipCode = zipCodeValue;
                    setFieldValue("officersBoardTrustees", updatedOfficers);
                  }}
                  onBlur={handleBlur}
                  value={values.officersBoardTrustees[0].zipCode}
                />
                <FormHelperText error>
                  {touched.officersBoardTrustees &&
                    touched.officersBoardTrustees[0] &&
                    errors.officersBoardTrustees &&
                    errors.officersBoardTrustees[0] &&
                    touched.officersBoardTrustees[0].zipCode &&
                    errors.officersBoardTrustees?.[0].zipCode}
                </FormHelperText>
              </Stack>
            </FlexComp>
          </BasicInfoInputWrapper>

          {nonProfit.slice(0).map((expense, index) => (
            <FlexComp
              key={index}
              direction="column"
              justifyContent="flex-start"
              gridGap="5px"
            >
              <NonProfitAccordion
                item={expense}
                itemIndex={index + 1}
                openIndex={openIndex}
                setOpenIndex={setOpenIndex}
                deleteExpense={deleteExpense}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                values={values.officersBoardTrustees[index + 1]}
                errors={errors?.officersBoardTrustees?.[index + 1]}
                touched={touched?.officersBoardTrustees?.[index]}
              />
            </FlexComp>
          ))}

          <Button
            text="+ Add Member"
            width="auto"
            fontSize="14px"
            color="#fff"
            backgroundColor="#00ADEF"
            onClick={addExpense}
            // alignSelf="flex-end"
          />
          <h5>
            Briefly describe the organizations mission or most significant
            activities
          </h5>
          <FlexComp
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            gridGap="25px"
          >
            <Stack direction="column" sx={{ width: "100%" }}>
              <Input
                label="Explanation"
                id=" explaination"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="Enter"
                onChange={(e) => {
                  setFieldValue(`explaination`, e.target.value);
                }}
                onBlur={handleBlur}
                value={values.explaination}
              />
              <FormHelperText error>
                {touched?.explaination && errors?.explaination}
              </FormHelperText>
            </Stack>
          </FlexComp>
          <FillingStatusWrapper>
            <Span color="#1B1B1B" fontSize="16px" fontWeight="500">
              Organization Purpose (Check all that apply){" "}
            </Span>
            <FillingStatusCheckBoxes>
              {fillOptions.map((item, index) => (
                <TextCheckbox
                  text={item.label}
                  gridGap="1px"
                  key={index}
                  isChecked={values[item.key]}
                  onChange={(e) => {
                    setFieldValue(item.key, e.target.checked);
                    handleCheckboxChange(item.label, e.target.checked);
                  }}
                />
              ))}
            </FillingStatusCheckBoxes>
          </FillingStatusWrapper>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              text={
                isSubmitting ? (
                  <SyncLoader color={"#FFFFFF"} size={8} />
                ) : (
                  "Save"
                )
              }
              width="15%"
              fontSize="18px"
              margin="0px 10px 0px 0px"
              backgroundColor="rgba(0, 173, 239, 1)"
              alignSelf="flex-end"
            />
          </Box>
        </BasicInfoContainer>
      </form>
      <Signature
        loading={isLoading}
        isModalOpen={isTermModal}
        onSaveSignature={handleSaveSignature}
        closeModal={() => setIsTermModal(!isTermModal)}
        openSecondModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
      />
    </>
  );
};

export default NonProfitFormation;
