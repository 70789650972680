import { useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Input } from "../input/Inputs";
import styled from "styled-components";
import FlexComp from "components/reusable/flex/Flex";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { stateOptions } from "data/clientData/businessFormation";
import { RxCross1 } from "react-icons/rx";
import { LuPlus } from "react-icons/lu";
import { ButtonIcon } from "components/reusable/button/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Button } from "../button/Button";
import { IconStyledButton } from "../button/Button.style";
import { IoIosCloudUpload } from "react-icons/io";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;
const DayCareAccordionComp = ({
  item1,
  values,
  index1,
  errors,
  touched,
  openIndex,
  setOpenIndex,
  setFieldValue,
  addNewDaycare,
  removeDaycareItem,
  dayCareList,
  setDeletedDayCareDocs,
  fileName,
  setFileName,
}) => {
  // Store uploaded file names

  const dayCareDocs = useRef(null);
  const isOpen = index1 === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index1);
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.currentTarget.files);

    setFieldValue("dayCareDocs", [
      ...(values?.dayCareDocs || []), // Fallback to empty array if undefined
      ...files.map((file) => ({ doc: file, fileName: file.name })),
    ]);

    setFileName((prevState) => [
      ...prevState,
      ...files.map((file) => ({ doc: file, fileName: file.name })),
    ]);
  };

  const handleUploadButtonClick = (e) => {
    e.preventDefault();
    dayCareDocs.current.click(); // Trigger file input click
  };

  const handleRemoveFile = (index) => {
    // Remove the selected file from the display state
    const removedFile = fileName[index];
    const updatedFileNames = fileName.filter((_, i) => i !== index);
    setFileName(updatedFileNames);

    // Update Formik's state for dayCareDocs to remove the file
    setFieldValue(
      "dayCareDocs",
      values?.dayCareDocs?.filter((_, i) => i !== index)
    );

    // Update the deletedDayCareDocs array in ItemizedDeduction
    setDeletedDayCareDocs((prev) => [
      ...prev,
      removedFile.doc, // Push only the document (URL or file reference)
    ]);
  };
  return (
    <Accordion sx={{ backgroundColor: "#EBEFF9" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="daycare-content"
        id="daycare-header"
      >
        Daycare Information
      </AccordionSummary>
      <AccordionDetails>
        {dayCareList.map((dayCareItem, index) => (
          <>
            <Box display={"flex"} alignItems={"center"} marginBottom={"20px"}>
              <Box fontSize={"16px"} fontWeight={"700"}>
                Daycare # {index + 1}
              </Box>
              {index !== 0 && (
                <IconStyledButton
                  onClick={() => removeDaycareItem(index)}
                  bgColor="red"
                  fontSize={"16px"}
                  width={"auto"}
                  type="button"
                >
                  <RxCross1 />
                </IconStyledButton>
              )}
            </Box>
            <InputWrapper key={index}>
              {/* Daycare Expense */}
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Daycare Expense"
                  id={`daycare-expense-${index}`}
                  value={dayCareItem.expense} // Set value from Formik
                  onChange={(e) =>
                    setFieldValue(`dayCare[${index}].expense`, e.target.value)
                  } // Update Formik state
                  placeholder="Enter"
                />
                <FormHelperText error={!!errors?.dayCare?.[index]?.expense}>
                  {errors?.dayCare?.[index]?.expense}
                </FormHelperText>
              </Stack>

              {/* Daycare Tax ID */}
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Daycare Tax ID"
                  id={`daycare-taxid-${index}`}
                  value={dayCareItem.taxId} // Set value from Formik
                  onChange={(e) =>
                    setFieldValue(`dayCare[${index}].taxId`, e.target.value)
                  } // Update Formik state
                  placeholder="Enter"
                />
                <FormHelperText error={!!errors?.dayCare?.[index]?.taxId}>
                  {errors?.dayCare?.[index]?.taxId}
                </FormHelperText>
              </Stack>

              {/* Daycare Name */}
              <Stack direction="column" sx={{ width: "32%" }}>
                <Input
                  label="Daycare Name"
                  id={`daycare-name-${index}`}
                  value={dayCareItem.name} // Set value from Formik
                  onChange={(e) =>
                    setFieldValue(`dayCare[${index}].name`, e.target.value)
                  } // Update Formik state
                  placeholder="Enter"
                />
                <FormHelperText error={!!errors?.dayCare?.[index]?.name}>
                  {errors?.dayCare?.[index]?.name}
                </FormHelperText>
              </Stack>

              {/* Address Fields */}
              <HeadingFive fontSize="16px" fontWeight="500" color="black">
                Address
              </HeadingFive>

              <FlexComp direction="row" gridGap="25px" width="100%">
                {/* Street */}
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="Street"
                    id={`daycare-street-${index}`}
                    value={dayCareItem.street} // Set value from Formik
                    onChange={(e) =>
                      setFieldValue(`dayCare[${index}].street`, e.target.value)
                    } // Update Formik state
                    placeholder="Enter"
                  />
                  <FormHelperText error={!!errors?.dayCare?.[index]?.street}>
                    {errors?.dayCare?.[index]?.street}
                  </FormHelperText>
                </Stack>

                {/* City */}
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="City"
                    id={`daycare-city-${index}`}
                    value={dayCareItem.city} // Set value from Formik
                    onChange={(e) =>
                      setFieldValue(`dayCare[${index}].city`, e.target.value)
                    } // Update Formik state
                    placeholder="Enter"
                  />
                  <FormHelperText error={!!errors?.dayCare?.[index]?.city}>
                    {errors?.dayCare?.[index]?.city}
                  </FormHelperText>
                </Stack>

                {/* State */}
                <Stack direction="column" sx={{ width: "32%" }}>
                  {/* <Input
                    label="State"
                    id={`daycare-state-${index}`}
                    value={dayCareItem.state} // Set value from Formik
                    onChange={(e) =>
                      setFieldValue(`dayCare[${index}].state`, e.target.value)
                    } // Update Formik state
                    placeholder="Enter"
                  /> */}
                  <Autocomplete
                    disablePortal
                    sx={{ width: "100%" }}
                    id="combo-box-demo"
                    options={stateOptions}
                    value={dayCareItem.state}
                    onChange={(_, newVal) => {
                      setFieldValue(
                        `dayCare[${index}].state`,
                        newVal?.value || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" />
                    )}
                  />
                  <FormHelperText error={!!errors?.dayCare?.[index]?.state}>
                    {errors?.dayCare?.[index]?.state}
                  </FormHelperText>
                </Stack>

                {/* Zip Code */}
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="Zip Code"
                    id={`daycare-zipcode-${index}`}
                    value={dayCareItem.zipCode} // Set value from Formik
                    onChange={(e) =>
                      setFieldValue(`dayCare[${index}].zipCode`, e.target.value)
                    } // Update Formik state
                    placeholder="Enter"
                  />
                  <FormHelperText error={!!errors?.dayCare?.[index]?.zipCode}>
                    {errors?.dayCare?.[index]?.zipCode}
                  </FormHelperText>
                </Stack>
              </FlexComp>

              {/* Button to remove daycare */}
            </InputWrapper>
          </>
        ))}
        <Box display={"flex"} alignItems={"center"} marginTop={"12px"}>
          <IconStyledButton
            fontSize="14px"
            onClick={addNewDaycare}
            width={"auto"}
            type="button"
          >
            <LuPlus />
            Add New Daycare
          </IconStyledButton>

          <Stack direction="row" gap={5}>
            <ButtonIcon
              width="100%"
              icon={<CloudUploadIcon />}
              text={"Upload Daycare Documents"}
              fontSize="12px"
              clickHandler={(e) => handleUploadButtonClick(e, "dayCareDocs")}
            />

            <input
              type="file"
              id="daycare-docs" // Single file input for all documents
              ref={dayCareDocs}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, "dayCareDocs")}
              multiple // Allows multiple file upload
            />
          </Stack>
        </Box>
        {fileName.length > 0 && (
          <Box mt={2}>
            <HeadingFive fontSize="16px" fontWeight="500" color="black">
              Uploaded Daycare Documents:
            </HeadingFive>
            <Stack
              display="flex"
              justifyContent="start"
              flexWrap="wrap"
              flexDirection="row"
              alignItems="center"
              gap="15px"
            >
              {fileName.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    fontSize: "12px",
                    margin: "4px 0",
                    background: "#62a0b8",
                    color: "#fff",
                    display: "inline-flex",
                    alignItems: "center",
                    padding: "0 15px",
                    height: "30px",
                    borderRadius: "8px",
                  }}
                >
                  {file.fileName}
                  <IconButton
                    aria-label="delete"
                    size="small"
                    style={{ marginLeft: "8px" }}
                    onClick={() => handleRemoveFile(index)}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Stack>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DayCareAccordionComp;
