import { useState, useEffect } from "react";
import CustomTable from "components/reusable/customTable/CustomTable";
import styled from "styled-components";
import TitleComponent from "components/reusable/titleComponent/TitleComponent";
import { IconStyledButton } from "components/reusable/button/Button.style";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";

const errorToast = (message = '') => toast.error(message);


const TaxReturnList = () => {
  const [review, setReview] = useState([]);
  const [tab_loader, setTabLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoader(true);
        const response = await apiReq(API_ROUTES.taxReturnGetAll, "POST",{
          clientId: localStorage.getItem("clientId"),
        } )

        const responseData = await response.data;
        const personalCollection = responseData.data.personalCollection;
        const generalBusiness = responseData.data.generalBusiness;
        const truckDriverForms = responseData.data.truckDriverForms;
        const rentalProperty = responseData.data.rentalProperty;

        let combinedArray = [
          personalCollection,
          ...generalBusiness,
          ...truckDriverForms,
          ...rentalProperty,
        ];
        combinedArray = combinedArray.filter(val => val?._id);
        const rowsWithId = combinedArray.map((row, index) => ({
          ...row,
          id: index + 1, // Generate a unique id for each row
        }));

        setReview(rowsWithId);
       
      } catch (error) {
        errorToast(error?.message || error?.response?.message || "Error fetching data. please try again later");
      } finally {
        setTabLoader(false);
      }
    };

    fetchData();
  }, []);
  const renderTableOrMessage = () => {
    return <CustomTable loading={tab_loader} rows={review} columns={TaxColumns} />;
  };
  
  const formattedDate = (value) => {
    const date = dayjs(value);
    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  };

  const handleDownload = async (rowData) => {
    try {
      const doc = new jsPDF();
  
      // Define columns for the PDF table
      const columns = [
        { title: "Information Type", dataKey: "informationType" },
        { title: "Date", dataKey: "createdAt" },
        { title: "Form Type", dataKey: "dataFromFile" },
        { title: "Status", dataKey: "status" },
        { title: "Comments", dataKey: "additionalNotes" },
      ];
  
      // Define rows data for the PDF table
      const rows = [rowData];
  
      // Create the PDF table
      doc.autoTable({
        head: [columns.map((col) => col.title)],
        body: rows.map((row) => columns.map((col) => row[col.dataKey])),
      });
  
      // Generate a unique name for the PDF file
      const fileName = `TaxDocument_${rowData.id}.pdf`;
  
      // Save the PDF file with the unique name
      doc.save(fileName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  
  
  

  const TaxColumns = [
    {
      field: "informationType",
      headerName: "Information Type",
      width: 220,
      editable: false,
    },
    // {
    //   field: "bname",
    //   headerName: "Name",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "createdAt",
      headerName: "Date",
      width: 280,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.row.createdAt)}</div>,
    },
    
    {
      field: "dataFromFile",
      headerName: "Form Type",
      width: 280,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Submitted"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(0, 173, 239, 0.16)",
            color:
              params.row.status === "Submitted"
                ? "rgba(0, 149, 206, 1)"
                : "rgba(0, 149, 206, 1)",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    {
      field: "additionalNotes",
      headerName: "Comments",
      width: 280,
      editable: false,
    },
    // {
    //   // field: "comments",
    //   headerName: "Action",
    //   width: 200,
    //   sortable: false,
    //   editable: false,
    //   renderCell: (params) => (
    //     <div>
    //       <IconStyledButton
    //         height="40px"
    //         variant="text"
    //         color="white"
    //         fontSize="12px"
    //         onClick={() => handleDownload(params.row)}
    //       >
    //         <DownloadIcon />
    //         Download
    //       </IconStyledButton>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
    <BusinessListContainer>
      <TitleComponent text="Tax Return" />
      <BusinessListWrapper>
        {renderTableOrMessage()}
      </BusinessListWrapper>
    </BusinessListContainer>
  </>
  );
};

export default TaxReturnList;

const BusinessListContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
