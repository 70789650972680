import { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/auth/Logo.png";
import Dashboard from "assets/sidebar/Dashboard.png";
import ActiveDashboard from "assets/sidebar/active/Dashboard.png";
import Queues from "assets/sidebar/task-square.png";
import ActiveQueues from "assets/sidebar/active/task-square.png";
import Messages from "assets/sidebar/sms.png";
import ActiveMessages from "assets/sidebar/active/device-message.png";
import Chat from "assets/sidebar/device-message.png";
import ActiveChat from "assets/sidebar/active/chat.png";
import Report from "assets/sidebar/Vector.png";
import ActiveReport from "assets/sidebar/Vector.png";
import Settings from "assets/sidebar/Settings.png";
import ActiveSetting from "assets/sidebar/active/setting.png";
import Logout from "assets/sidebar/Logout.png";
import ActiveLogout from "assets/sidebar/Logout.png";
import Dot from "assets/sidebar/Dot.png";
import ActiveDot from "assets/sidebar/active/Dot.png";
import { SidebarLogo } from "../../../layout/sidebar/Sidebar.style";
import ActiveUser from "assets/sidebar/active/Add User.png";
import User from "assets/sidebar/Add User.png";
import ActiveSupport from "assets/sidebar/active/24-support.png";
import Support from "assets/sidebar/24-support.png";

const AdminSidebar = ({ handleMenuItemClick }) => {
  const params = useLocation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  const handleMouseEnter = (menuItem) => {
    setActiveMenu(menuItem);
  };

  const handleMenuClick = (menuItem) => {
    handleMenuItemClick(menuItem);
    if (menuItem === "Report") {
      setOpenReport(!openReport);
    } else if (menuItem === "Settings") {
      setOpenSetting(!openSetting);
    }
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  const isMenuItemActive = (menuItem) => {
    const menuItemUrls = {
      Dashboard: "/admin-dashboard",

      Messages: ["/admin-messages", "admin-compose"],
      dashboard: "/dashboard",
      Client: "/admin-client",
      Queues: "/admin-queues",
      TaxFilling: "/tax-filing",
      Chat: "/admin-chat",
      Reports: [
        "/report",
        "/queue-report-listing",
        "/tax-return-report-listing",
        "/tax-report-listing",
        "/client-report-listing",
      ], // Consider all report-related URLs
      Setting: ["/admin-profile", "/settings/admin-change-password"], // Consider all settings-related URLs
      Support: "/admin-support",
      logout: "/", // Adjust this according to your logout URL
      //TODO: remove this later
      Queue: "/queue-report-listing",
      TaxReturns: "/tax-return-report-listing",
      TaxFillings: "/tax-report-listing",
      ClientActivity: "/client-report-listing",
      User: "/user-management",
      Profile: "/admin-profile",
      ChangePassword: "/settings/admin-change-password",
    };

    const currentUrl = params?.pathname;

    // Check if the current URL matches the menu item URL
    if (Array.isArray(menuItemUrls[menuItem])) {
      return menuItemUrls[menuItem].some((url) => url === currentUrl);
    } else {
      return menuItemUrls[menuItem] === currentUrl;
    }
  };

  const getMenuItemStyle = (isActive, subMenu = true) => ({
    background: isActive ? "#fff" : "rgb(11, 37, 88)",
    color: isActive ? "rgb(11, 37, 88)" : "#fff",
    borderTopLeftRadius: isActive ? "25px" : "0px",
    borderBottomLeftRadius: isActive ? "25px" : "0px",
    fontSize: "12px",
    paddingLeft: subMenu ? "" : "16px",
    // paddingLeft: "16px",
  });

  return (
    <Sidebar backgroundColor="#0b2558" width="100%">
      <SidebarLogo onClick={() => navigate("/dashboard")}>
        <img src={Logo} alt="RemeDy" />
      </SidebarLogo>
      <Menu>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Dashboard") ? ActiveDashboard : Dashboard}
              alt="Dashboard"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Dashboard"), false)}
          component={<Link to="/admin-dashboard" />}
          onMouseEnter={() => handleMouseEnter("Dashboard")}
          onMouseLeave={() => handleMouseLeave("Dashboard")}
          onClick={() => handleMenuClick("Dashboard")}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Client") ? ActiveUser : User}
              alt="Client"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Client"), false)}
          component={<Link to="/admin-client" />}
          onMouseEnter={() => handleMouseEnter("Client")}
          onMouseLeave={() => handleMouseLeave("Client")}
          onClick={() => handleMenuClick("Client")}
        >
          Client
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Queues") ? ActiveQueues : Queues}
              alt="Queues"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Queues"), false)}
          component={<Link to="/admin-queues" />}
          onMouseEnter={() => handleMouseEnter("Queues")}
          onMouseLeave={() => handleMouseLeave("Queues")}
          onClick={() => handleMenuClick("Queues")}
        >
          Queues
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Messages") ? ActiveMessages : Messages}
              alt="Messages"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Messages"), false)}
          component={<Link to="/admin-messages" />}
          onMouseEnter={() => handleMouseEnter("Messages")}
          onMouseLeave={() => handleMouseLeave("Messages")}
          onClick={() => handleMenuClick("Messages")}
        >
          Messages
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Chat") ? ActiveChat : Chat}
              alt="Chat"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Chat"), false)}
          component={<Link to="/admin-chat" />}
          onMouseEnter={() => handleMouseEnter("Chat")}
          onMouseLeave={() => handleMouseLeave("Chat")}
          onClick={() => handleMenuClick("Chat")}
        >
          Chat
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Tax Filling") ? ActiveReport : Report}
              alt="Dot"
            />
          }
          component={<Link to="/tax-filing" />}
          style={getMenuItemStyle(isMenuItemActive("TaxFilling"))}
          onMouseEnter={() => handleMouseEnter("TaxFilling")}
          onMouseLeave={() => handleMouseLeave("TaxFilling")}
          onClick={() => handleMenuClick("Tax Fillings")}
        >
          Tax Filings
        </MenuItem>
        <SubMenu
          label="Report"
          icon={
            <img
              src={isMenuItemActive("Report") ? ActiveReport : Report}
              alt="Report"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Report"))}
          onMouseEnter={() => handleMouseEnter("Report")}
          onMouseLeave={() => handleMouseLeave("Report")}
          onClick={() => handleMenuClick("Report")}
          open={openReport}
        >
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("Queue") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to="/queue-report-listing" />}
            style={getMenuItemStyle(isMenuItemActive("Queue"))}
            onMouseEnter={() => handleMouseEnter("Queue")}
            onMouseLeave={() => handleMouseLeave("Queue")}
            onClick={() => handleMenuClick("Report > Queue")}
          >
            Queue
          </MenuItem>
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("TaxReturns") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to="/tax-return-report-listing" />}
            style={getMenuItemStyle(isMenuItemActive("TaxReturns"))}
            onMouseEnter={() => handleMouseEnter("TaxReturns")}
            onMouseLeave={() => handleMouseLeave("TaxReturns")}
            onClick={() => handleMenuClick("Report > Staff Activity")}
          >
            Staff Activity
          </MenuItem>
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("TaxFillings") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to="/tax-report-listing" />}
            style={getMenuItemStyle(isMenuItemActive("TaxFillings"))}
            onMouseEnter={() => handleMouseEnter("TaxFillings")}
            onMouseLeave={() => handleMouseLeave("TaxFillings")}
            onClick={() => handleMenuClick("Report > Tax Filling")}
          >
            Tax Filings
          </MenuItem>
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("ClientActivity") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to="/client-report-listing" />}
            style={getMenuItemStyle(isMenuItemActive("ClientActivity"))}
            onMouseEnter={() => handleMouseEnter("ClientActivity")}
            onMouseLeave={() => handleMouseLeave("ClientActivity")}
            onClick={() => handleMenuClick("Report > Client Activity")}
          >
            Client Activity
          </MenuItem>
          {/* Add other sub-menu items here */}
        </SubMenu>
        <SubMenu
          label="Settings"
          icon={
            <img
              src={isMenuItemActive("Settings") ? ActiveSetting : Settings}
              alt="Settings"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Settings"))}
          onMouseEnter={() => handleMouseEnter("Settings")}
          onMouseLeave={() => handleMouseLeave("Settings")}
          onClick={() => handleMenuClick("Settings")}
          open={openSetting}
        >
          <MenuItem
            // icon={
            //   <img src={isMenuItemActive("User") ? ActiveDot : Dot} alt='Dot' />
            // }
            component={<Link to="/user-management" />}
            style={getMenuItemStyle(isMenuItemActive("User"))}
            onMouseEnter={() => handleMouseEnter("User")}
            onMouseLeave={() => handleMouseLeave("User")}
            onClick={() => handleMenuClick("Settings > User")}
          >
            User Management
          </MenuItem>
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("Profile") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to="/admin-profile" />}
            style={getMenuItemStyle(isMenuItemActive("Profile"))}
            onMouseEnter={() => handleMouseEnter("Profile")}
            onMouseLeave={() => handleMouseLeave("Profile")}
            onClick={() => handleMenuClick("Settings > Profile")}
          >
            Profile
          </MenuItem>
          <MenuItem
            // icon={
            //   <img
            //     src={isMenuItemActive("ChangePassword") ? ActiveDot : Dot}
            //     alt='Dot'
            //   />
            // }
            component={<Link to="/settings/admin-change-password" />}
            style={getMenuItemStyle(isMenuItemActive("ChangePassword"))}
            onMouseEnter={() => handleMouseEnter("ChangePassword")}
            onMouseLeave={() => handleMouseLeave("ChangePassword")}
            onClick={() => handleMenuClick("Settings > Change Password")}
          >
            Change Password
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("Support") ? ActiveSupport : Support}
              alt="Support"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("Support"))}
          component={<Link to="/admin-support" />}
          onMouseEnter={() => handleMouseEnter("Support")}
          onMouseLeave={() => handleMouseLeave("Support")}
          onClick={() => handleMenuClick("Support")}
        >
          Support
        </MenuItem>
        <MenuItem
          icon={
            <img
              src={isMenuItemActive("logout") ? ActiveLogout : Logout}
              alt="Logout"
            />
          }
          style={getMenuItemStyle(isMenuItemActive("logout"))}
          onClick={() => {
            navigate("/admin-signin");
            localStorage.clear();
            sessionStorage.removeItem("roleSelected");
          }}
          onMouseEnter={() => handleMouseEnter("logout")}
          onMouseLeave={() => handleMouseLeave("logout")}
          // onClick={() => handleMenuClick("Logout")}
        >
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default AdminSidebar;
