import {
  Autocomplete,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import AdditionalNotes from "components/modals/AdditionalNotes";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import { Button } from "components/reusable/button/Button";
import DropdownComp from "components/reusable/dropdownComp/DropdownComp";
import FlexComp from "components/reusable/flex/Flex";
import {
  DateInput,
  Input,
  InputWithAdornment,
} from "components/reusable/input/Inputs";
import { Span } from "components/reusable/typography/Typography.style";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { stateOptions } from "data/clientData/businessFormation";
import {
  expenseFields,
  incomeFields,
  topBusinessInfoFields,
} from "data/clientData/rentalProperty";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { FieldsWrapper, Line } from "./RentalProperty.style";
import { RentalSchema } from "./RentalSchema";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const initialValues = {
  taxYear: "",
  address: {
    city: "",
    street: "",
    state: "",
    zipCode: "",
    datePlacedService: "",
    rentCharge: "",
    purchasePrice: "",
    fairMarketValue: "",
    fairRentalDays: "",
    propertyType: "",
  },
  income: {
    rentalIncome: "",
    deposit: "",
    lateFees: "",
  },
  expenses: {
    managementFees: "",
    advertisingForTenants: "",
    associationDues: "",
    cleaningAndMaintenance: "",
    repairsAndMaintenance: "",
    insurance: "",
    propertyTax: "",
    utilities: "",
    waterCharges: "",
    miscFees: "",
  },
};

const BusinessInformation = ({ previewData }) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const { setOpen } = useContext(PageLoaderContext);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const rentalPropertyMainId = localStorage.getItem("rentalPropertyMainId");

  const saveRentalPropertyMain = async () => {
    try {
      const formData = new FormData();
      formData.append("rentalPropertyMainId", rentalPropertyMainId);
      formData.append("additionalNotes", additionalNotes);
      formData.append("clientId", localStorage.getItem("clientId"));
      formData.append("dataFromFile", values.dataFromFile);
      formData.append("rentalDoc", values.rentalDoc);
      await apiReq(`${API_ROUTES.UpdateRentalPropertyMain}`, "POST", formData);
      infoToast(
        edit
          ? "Rental Property Main updated successfully."
          : "Rental Property Main data saved successfully."
      );
      setTimeout(() => navigate("/review"), 1500);
    } catch (error) {
      errorToast(
        "An unexpected error occurred while saving Rental Property Main data. Please try again later."
      );
    }
  };
  const {
    values,
    errors,
    setValues,
    touched,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: RentalSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast("clientId not found!");
          return;
        }
        const payload = {
          ...(edit && { rentalPropertyId: values?._id }),
          address: values.address,
          monthYear: values.monthYear,
          income: values.income,
          expenses: values.expenses,
          taxYear: values.taxYear,
        };
        const { data: { data: { rentalPropertyMainId = "" } = {} } = {} } =
          await apiReq(
            edit ? API_ROUTES.updateRentalProperty : API_ROUTES.rentalProperty,
            "POST",
            { clientId, ...payload }
          );
        !edit &&
          localStorage.setItem("rentalPropertyMainId", rentalPropertyMainId);
        infoToast(
          edit
            ? "Rental Property updated successfully"
            : "Rental Property saved successfully"
        );
        setTimeout(() => {
          setIsAdditionalModal(true);
        }, 1000);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(
          `${API_ROUTES.getRentalProperty}/${rentalPropertyMainId}`,
          "POST"
        );
        const additionalNotes = data.additionalNotes;
        const {
          rentalPropertyId: {
            address,
            monthYear,
            income,
            expenses,
            _id,
            taxYear,
          },
        } = data;
        setValues({ address, monthYear, income, expenses, _id ,taxYear});
        setAdditionalNotes(additionalNotes);
        setEdit(true);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };

    rentalPropertyMainId && fetchData();
  }, []);

  const handleMonthChange = (event) => {
    let {
      target: { value },
    } = event;

    if (value.includes("All")) {
      value = [
        "All",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sept",
        "October",
        "November",
        "December",
      ];
    }

    setFieldValue("monthYear.month", value);
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <FieldsWrapper>
          <Span color="#000000" fontSize="18px" fontWeight="500">
            Business Information
          </Span>
          <Stack direction="column" sx={{ width: "32%" }}>
            <DateInput
              label="Tax year"
              id="tax-year"
              name="taxYear"
              openTo="year"
              restrictToLast10Years="true"
              defaultToTenthYear="true"
              disableFuture="true"
              width="100%"
              placeholder="YYYYY"
              views={["year"]}
              value={dayjs(values.taxYear).format("YYYY") || ""}
              onChange={(date) => setFieldValue(`taxYear`, date)}
            />
            <FormHelperText error>
              {touched?.taxYear && errors?.taxYear}
            </FormHelperText>
          </Stack>
          <Typography>Address</Typography>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Street *"
                id="street"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="Enter"
                onChange={(e) => {
                  setFieldValue(`address.street`, e.target.value);
                }}
                onBlur={handleBlur}
                value={values?.address?.street}
              />
              <FormHelperText error>
                {touched?.address?.street && errors?.address?.street}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="City *"
                id="city"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="Enter"
                onChange={(e) => {
                  setFieldValue(`address.city`, e.target.value);
                }}
                onBlur={handleBlur}
                value={values?.address?.city}
              />
              <FormHelperText error>
                {touched?.address?.city && errors?.address?.city}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={stateOptions}
                value={values?.address?.state}
                onChange={(_, newVal) => {
                  setFieldValue(`address.state`, newVal?.value || "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="State *" />
                )}
              />
              <FormHelperText error>
                {touched?.address?.state && errors?.address?.state}
              </FormHelperText>
            </Stack>

            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Zip Code *"
                id="zipCode"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="XXXXX-XXXX"
                onChange={(e) => {
                  let zipCodeValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 9);
                  if (zipCodeValue.length > 5) {
                    zipCodeValue =
                      zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                  }
                  setFieldValue(`address.zipCode`, zipCodeValue);
                }}
                onBlur={handleBlur}
                value={values?.address?.zipCode}
              />
              <FormHelperText error>
                {touched?.address?.zipCode && errors?.address?.zipCode}
              </FormHelperText>
            </Stack>
          </FlexComp>
          <FlexComp
            direction="row"
            alignItems="center"
            gridGap="20px"
            flexWrap="wrap"
          >
            <Stack direction="column" sx={{ width: "32%" }}>
              <DateInput
                label="Date in Service *"
                width="100%"
                onChange={(date) =>
                  setFieldValue(`address.datePlacedService`, date)
                }
                value={dayjs(values?.address?.datePlacedService)}
              />
              <FormHelperText error>
                {touched?.address?.datePlacedService &&
                  errors?.address?.datePlacedService}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <DateInput
                label="DATE OUT OF SERVICE *"
                width="100%"
                onChange={(date) => setFieldValue(`address.rentCharge`, date)}
                value={dayjs(values?.address?.rentCharge)}
              />
              {/* <DateInput
                label="DATE OUT OF SERVICE *"
                width="100%"
                id="rentCharge"
                type="date"
                placeHolder=""
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                  setFieldValue(`address.rentCharge`, val);
                }}
                onBlur={handleBlur}
                value={values?.address?.rentCharge}
              /> */}
              <FormHelperText error>
                {touched?.address?.rentCharge && errors?.address?.rentCharge}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <InputWithAdornment
                label="Purchase Price *"
                defaultValue=""
                width="100%"
                id="purchasePrice"
                placeHolder=""
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                  setFieldValue(`address.purchasePrice`, val);
                }}
                onBlur={handleBlur}
                value={values?.address?.purchasePrice}
              />
              <FormHelperText error>
                {touched?.address?.purchasePrice &&
                  errors?.address?.purchasePrice}
              </FormHelperText>
            </Stack>

            {topBusinessInfoFields.map(
              ({ label, id, size, width, placeHolder }, index) => (
                <>
                  <Stack key={index} direction="column" sx={{ width }}>
                    <Input
                      id={id}
                      key={index}
                      size={size}
                      width="100%"
                      label={label}
                      placeHolder={placeHolder}
                      onChange={(e) => {
                        const val = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 9);
                        setFieldValue(`address[${id}]`, val);
                      }}
                      onBlur={handleBlur}
                      value={values?.address?.[id]}
                    />
                    <FormHelperText error>
                      {touched?.address?.[id] && errors?.address?.[id]}
                    </FormHelperText>
                  </Stack>
                </>
              )
            )}
            <Stack direction="column" sx={{ width: "30%" }}>
              <DropdownComp
                id="state"
                label="Property Type (Sch E) *"
                width="100%"
                options={[
                  {
                    label: "Single Family Residence",
                    value: "Single Family Residence",
                  },
                  {
                    label: " Multi-Family Residence",
                    value: " Multi-Family Residence",
                  },
                  {
                    label: " Vacation/Short-Term Rental",
                    value: " Vacation/Short-Term Rental",
                  },
                  { label: "Commercial", value: "Commercial" },
                ]}
                value={values?.address?.propertyType}
                onChange={(value) =>
                  setFieldValue("address.propertyType", value)
                }
              />
              <FormHelperText error>
                {touched?.address?.propertyType &&
                  errors?.address?.propertyType}
              </FormHelperText>
            </Stack>
          </FlexComp>
        </FieldsWrapper>
        <Line />
        {/* <FieldsWrapper>
        <Span color="#000000" fontSize="18px" fontWeight="500">
          Select Month & Year
        </Span>
        <FlexComp
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gridGap="30px"
          flexWrap="wrap"
        >
          <Stack direction="column" sx={{ width: "32%" }}>
            <MonthSelect
              label="Select Month *"
              id="month"
              size="size"
              width="100%"
              menuItems={rentalMonths}
              handleChange={handleMonthChange}
              onBlur={handleBlur}
              value={values?.monthYear?.month}
            />
            <FormHelperText error>{touched?.monthYear?.month &&  errors?.monthYear?.month}</FormHelperText>
          </Stack>
          <Stack direction="column" sx={{ width: "32%" }}>
            <DateInput
              label="Year *"
              width="100%"
              openTo="year"
              views={["year"]}
              disableFuture="true"
              placeholder="YYYY"
              onChange={(date) => {
                setFieldValue("monthYear.year", date)
              }}
              value={(values?.monthYear?.year)}
            />
            <FormHelperText error>{touched?.monthYear?.year && errors?.monthYear?.year}</FormHelperText>
          </Stack>
        </FlexComp>
      </FieldsWrapper> */}
        <FieldsWrapper>
          <Span color="#000000" fontSize="18px" fontWeight="500">
            Income
          </Span>
          <FlexComp
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gridGap="25px"
            flexWrap="wrap"
          >
            {incomeFields.map(({ label, id, width, placeHolder }, index) => (
              <>
                <Stack key={index} direction="column" sx={{ width }}>
                  <InputWithAdornment
                    label={label}
                    width="100%"
                    id={id}
                    placeHolder={placeHolder}
                    key={index}
                    onChange={(e) => {
                      const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                      setFieldValue(`income[${id}]`, val);
                    }}
                    onBlur={handleBlur}
                    value={values?.income?.[id]}
                  />
                  <FormHelperText error>
                    {touched?.income?.[id] && errors?.income?.[id]}
                  </FormHelperText>
                </Stack>
              </>
            ))}
          </FlexComp>
        </FieldsWrapper>
        <FieldsWrapper>
          <Span color="#000000" fontSize="18px" fontWeight="500">
            Expenses
          </Span>
          <FlexComp
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gridGap="25px"
            flexWrap="wrap"
          >
            {expenseFields.map(({ label, id, width, placeHolder }, index) => (
              <>
                <Stack key={index} direction="column" sx={{ width }}>
                  <InputWithAdornment
                    label={label}
                    width="100%"
                    id={id}
                    placeHolder={placeHolder}
                    key={index}
                    onChange={(e) => {
                      const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                      setFieldValue(`expenses[${id}]`, val);
                    }}
                    onBlur={handleBlur}
                    value={values?.expenses?.[id]}
                  />
                  <FormHelperText error>
                    {touched?.expenses?.[id] && errors?.expenses?.[id]}
                  </FormHelperText>
                </Stack>
              </>
            ))}
          </FlexComp>
        </FieldsWrapper>
        <FlexComp
          direction="row"
          justifyContent="flex-end"
          gridGap="30px"
          flexWrap="wrap"
        >
          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Save"
            }
            type="submit"
            width="10%"
            fontSize="20px"
            padding="10px 30px"
            alignSelf="flex-end"
            backgroundColor="#0B2558"
          />
        </FlexComp>
        <AdditionalNotes
          id={rentalPropertyMainId}
          isModalOpen={isAdditionalModal}
          closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
          openSecondModal={(content) => {
            setIsAdditionalModal(false);
            setAdditionalNotes(content);
            setIsValidateTaxModal(true);
          }}
          additionalNotes={additionalNotes}
        />
        <ValidateTaxModal
          isModalOpen={isValidateTaxModal}
          closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
          paragraph="Are you sure you want to validate this Rental Form?"
          handleYesClick={() => {
            saveRentalPropertyMain(); // Call the function to save Rental Property Main data
          }} // Pass the API request function
        />
      </form>
    </>
  );
};

export default BusinessInformation;
