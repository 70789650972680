import { useEffect, useState } from "react";
import styled from "styled-components";
import MenuTabs from "components/reusable/tabs/MenuTabs";
import BusinessInfo from "components/client/businessCollection/BusinessInfo";
import Expense from "components/client/businessCollection/Expense";
import FixedAssets from "components/client/businessCollection/FixedAssets";
import VehicleInfo from "components/client/businessCollection/VehicleInfo";
import BusinessUseHome from "components/client/businessCollection/BusinessUseHome";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { useLocation } from "react-router-dom";

const tabComponents = [
  BusinessInfo,
  Expense,
  FixedAssets,
  VehicleInfo,
  BusinessUseHome,
];

const tabsData = [
  {label: "Business Info", key: "business_info"},
  {label: "Expenses", key: "expenses"},
  {label: "Fixed Assets (Optional)", key: "fixed_assets"},
  {label: "Vehicle Info (Optional)", key: "vehicle_info"},
  {label: "Business Use Of Home (Optional)", key: "business_use"},
];

const AddBusiness = () => {
  const [editTab, setEditTab] = useState(false)
  const rowData = localStorage.getItem("currentBusinessRow") || "";
  const selectedRow = JSON.parse(rowData || "{}");
  const history = useLocation()
  console.log(history, "history")

  useEffect(() => {
    return () => {
      localStorage.removeItem("current_tab");
      localStorage.removeItem('currentBusinessRow');
  };
  }, [])
  
  return (
    <AddBusinessContainer>
      <HeadingFive fontSize="18px" fontWeight="500">
        General Business
      </HeadingFive>
      <AddBusinessWrapper>
        <MenuTabs editTab={editTab} setEditTab={setEditTab} tabData={tabsData} tabComponents={tabComponents} rowData={selectedRow} />
      </AddBusinessWrapper>
    </AddBusinessContainer>
  );
};

export default AddBusiness;

const AddBusinessContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;

const AddBusinessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
