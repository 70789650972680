import { Button } from "@mui/material";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import GeneralBusinessModal from "components/modals/staffModals/GernalBusinessModal";
import CustomTable from "components/reusable/customTable/CustomTable";
import DateComponent from "components/reusable/datecomponent/DateComponent";
import StaffHeader from "components/reusable/header/StaffHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as XLSX from "xlsx";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YY");
  return formattedDate;
};
const ReportListing = () => {
  const [tab_loader, setTabLoader] = useState(true);
  const [showButton, setShowButton] = useState(false); // State to manage button visibility
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false); // State to manage row selection
  const [originaldata, setoriginalData] = useState();
  const [loading, setLoading] = useState(false);
  const onSelectChange = (selectedValues) => {
    if (selectedValues.includes("Data Validation")) {
      setShowButton(true);
      setEnableRowSelection(true);
    } else {
      setShowButton(false);
      setEnableRowSelection(false);
    }
  };
  const [searchName, setSearchName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [value, setValue] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (startDate && !endDate) {
        toast.error("Please Select End date");
        return;
      } else if (!startDate && endDate) {
        toast.error("Please Select Start date");
        return;
      }

      const isStartDateAfterEndDate = startDate > endDate;
      if (isStartDateAfterEndDate) {
        toast.error("End date cannot be before the start date");
        return;
      }
      try {
        setLoading(true);
        const { data: { data = {} } = {} } = await apiReq(
          `${API_ROUTES.getQueueReports}`,
          "POST",
          {
            startDate,
            endDate,
          }
        );
        const dataValue = data?.map((itm, idx) => ({
          id: itm._id,
          srNo: (idx + 1).toString().padStart(2, "0"),
          clientName: itm.clientId.userName,
          businessName: itm?.generalBusinessTaskId
            ? itm?.generalBusinessTaskId?.informationType
            : itm?.personalCollectionTaskId
            ? itm?.personalCollectionTaskId?.informationType
            : itm?.truckDriverTaskId
            ? itm?.truckDriverTaskId?.informationType
            : "",
          startDate: dayjs(itm.startDateTime).format("MMM DD, YYYY"),
          endDate: dayjs(itm.endDateTime).format("MMM DD, YYYY"),
          updatedBy: `${itm.assignBy?.firstName || ""} ${
            itm.assignBy?.lastName || ""
          }`,
          modifiedDate: dayjs(itm.endDateTime).format("MMM DD, YYYY"),
          priority: itm.priority,
          status: itm.status,
        }));
        console.log(dataValue, "dataValue");

        setValue(dataValue);
        setoriginalData(dataValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(
          error?.message || "Error fetching data. please try again later"
        );
      } finally {
        setTabLoader(false);
      }
    };

    fetchData();
  }, [refetch]);
  const reFetchTaxFiling = () => {
    setRefetch(!refetch);
  };

  const handleSearchByName = (selectedValues) => {
    console.log("The ", selectedValues);
    setSearchName(selectedValues.target.value);
    const filteredData = originaldata.filter((item) =>
      item.clientName.toLowerCase().includes(selectedValues.target.value)
    );
    if (!selectedValues.target.value) {
      setRefetch(!refetch);
    }
    setValue(filteredData);
  };

  const individualColumns = [
    // {
    //   field: "srNo",
    //   headerName: "Sr#",
    //   width: 150,
    //   editable: false,
    // },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 200,
      editable: false,
    },
    {
      field: "businessName",
      headerName: "Business Name",
      width: 220,
      editable: false,
      renderCell: (params) => (
        <div>{params.value ? params.value : "-----"}</div>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            color:
              params.row.priority === "high"
                ? "#D23F3F"
                : "rgba(89, 169, 93, 1)",
            borderRadius: "15px",
            padding: "5px",
          }}
        >
          {params.row.priority}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div
        // style={{
        //   backgroundColor:
        //     params.row.form === "Staff Review"
        //       ? "rgba(244, 184, 27, 0.3)"
        //       : "rgba(244, 184, 27, 0.3)",
        //   color:
        //     params.row.form === "Staff Review"
        //       ? "rgba(208, 150, 0, 1)"
        //       : "rgba(208, 150, 0, 1)",
        //   borderRadius: "15px",
        //   padding: "5px",
        // }}
        >
          {params.row.status}
        </div>
      ),
    },
    // {
    //   // field: "comments",
    //   headerName: "Action",
    //   width: 100,
    //   editable: false,
    //   renderCell: (params) => (
    //     <div>
    //       <Button
    //         variant='text'
    //         color='primary'
    //         onClick={() => setGeneralBusinessModal(true)}
    //       >
    //         <img src={detail} alt='detail' />
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const individualRows = [
    {
      id: 1,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "heigh",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
    {
      id: 2,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "low",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
  ];

  const options = [
    { label: "Select ALL", value: "Select All" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Staff Review", value: "Staff Review" },
  ];
  const handleSelectChange = (selectedValues) => {
    // Handle select change logic
    console.log("select value");
  };

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table.xlsx");
  };
  const ExlhandleButtonClick = () => {
    // Button click logic
    generateExcel();
  };
  const searchStaff = async (searchValue) => {
    try {
      console.log(searchValue);
      const filteredAssignData = originaldata.filter((item) => {
        return item?.cname?.toLowerCase().includes(searchValue?.toLowerCase());
        // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
      });

      setValue(filteredAssignData);
    } catch (error) {}
  };
  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          showFirstSelect={false}
          showSecondSelect={false}
          showButton={false}
          showSecondButton={false}
          showThirdButton
          clickHandler2={ExlhandleButtonClick}
          placeholder="Search by Name"
          options={options}
          btntext1="PDF"
          btntext2="Excel"
          onSelectChange={handleSelectChange}
          onChange={handleSearchByName}
          // searchStaff={searchStaff}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />
        <DateComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleButtonClick={reFetchTaxFiling}
          loading={loading}
        />
        <RentalPropertyWrapper>
          <CustomTable
            rows={value}
            loading={tab_loader}
            getRowId={(row) => row.data._id}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
      <GeneralBusinessModal
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      />
      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
    </>
  );
};

export default ReportListing;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  width: 100%;
  padding: 20px;
  //min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
