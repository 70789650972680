import React, { useState, useEffect } from "react";
import { Avatar, Box, InputAdornment, Stack } from "@mui/material";
import styled from "styled-components";
import User from "assets/navbar/User.png";
import { SlOptionsVertical } from "react-icons/sl";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import TextField from "@mui/material/TextField";
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { IoSendOutline } from "react-icons/io5";
import { GoMention } from "react-icons/go";
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "services/firebaseSetup";
import { storage } from "../../../FirebaseSetup";
import { v4 as uuid } from "uuid";
import chat from "../../../assets/auth/chat.png";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  arrayUnion,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

const MessageBox = ({
  icon,
  name = "",
  direction = "row",
  show_icon = false,
  text = "",
  backgroundColor = "#E6EBF6",
}) => (
  <Box sx={{ display: "flex", gap: "10px", flexDirection: direction }}>
    <Avatar></Avatar>
    <Box
      className="message-box"
      sx={{
        backgroundColor,
        padding: "12px 15px",
        borderRadius: "8px",
        minWidth: "50%",
        maxHeight: "80px",
        lineHeight: "22px",
      }}
    >
      <h4>{name}</h4>
      <p>{text}</p>
    </Box>
  </Box>
);
export const ChatBox = ({
  data,
  currentUser,
  userName,
  onChange,
  value = "",
  handleClick,
  getChatList,
  fetchUsers,
}) => {
  // console.log("curent",currentUser)
  const [messages, setMessages] = useState([]);
  const name = data.user.displayName;
  const name2 = userName;
  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
  }, [data.chatId]);

  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleFileChange = (e) => {
    setImg(e.target.files[0]);
  };

  const handleSend = async () => {
    if (img) {
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, img);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress monitoring
        },
        (error) => {
          // Handle error
          console.error(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await sendMessage(downloadURL);
        }
      );
    } else {
      await sendMessage();
    }
    setText("");
    setImg(null);
  };

  const sendMessage = async (imgUrl = null) => {
    const messageData = {
      id: uuid(),
      text,
      senderId: currentUser,
      date: Timestamp.now(),
      ...(imgUrl && { img: imgUrl }),
    };

    const chatDocRef = doc(db, "chats", data.chatId);
    const userChatDocRef = doc(db, "usersChats", currentUser);
    const receiverChatDocRef = doc(db, "usersChats", data.user.uid);

    const chatDoc = await getDoc(chatDocRef);
    const userChatDoc = await getDoc(userChatDocRef);
    const receiverChatDoc = await getDoc(receiverChatDocRef);

    if (!chatDoc.exists()) {
      await setDoc(chatDocRef, { messages: [messageData] });
    } else {
      await updateDoc(chatDocRef, {
        messages: arrayUnion(messageData),
      });
    }

    const updateUserChat = async (docSnapshot, docRef, chatId, message) => {
      if (!docSnapshot.exists()) {
        await setDoc(docRef, {
          [chatId]: {
            lastMessage: { text: message.text },
            date: serverTimestamp(),
          },
        });
      } else {
        await updateDoc(docRef, {
          [`${chatId}.lastMessage`]: {
            text: message.text,
          },
          [`${chatId}.date`]: serverTimestamp(),
        });
      }
    };

    await updateUserChat(userChatDoc, userChatDocRef, data.chatId, messageData);
    await updateUserChat(
      receiverChatDoc,
      receiverChatDocRef,
      data.chatId,
      messageData
    );
  };
  const deletChat = async () => {
    const chatDocRef = doc(db, "chats", data.chatId);
    const userChatDocRef = doc(db, "usersChats", currentUser);
    const receiverChatDocRef = doc(db, "usersChats", data.user.uid);
    const chatDoc = await getDoc(chatDocRef);
    if (chatDoc.exists()) {
      await deleteDoc(chatDocRef);
      await deleteDoc(userChatDocRef);
      await deleteDoc(receiverChatDocRef);
    }
  };

  return (
    <>
      {data.chatId === null || data.chatId === "null" ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "120px",
            }}
          >
            <img className="sm-250" src={chat} alt="" />
          </div>
          <p
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              fontSize: "25px",
              marginTop: "20px",
            }}
          >
            No conversations yet
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              padding: "0px 10px 0px 10px",
              textAlign: "center",
            }}
          >
            This Client has yet to contact an operator for help. <br /> All
            conversations between the customer and the operator will be listed
            here.
          </p>
        </>
      ) : (
        <StyledContainer>
          <>
            <StyledHeader>
              <Stack alignItems="center">
                <StyledLeftHeader>
                  <Stack direction="row" gap={2}>
                    {/* <img src={data?.user.profileUrl || User} alt="User" /> */}
                    <Stack direction="column">
                      <div>{data?.user.displayName || "User Name"}</div>
                      <div style={{ color: "rgba(134, 148, 151, 1)" }}>
                        {/* {data?.description || "Other Detail "} */}
                        {/* <span style={{ color: 'rgba(0, 173, 239, 1)' }}>Mention</span> */}
                      </div>
                      This Client has yet to contact an operator for help. All
                      conversations between the customer and the operator will
                      be listed here.
                    </Stack>
                  </Stack>
                </StyledLeftHeader>
              </Stack>
              {/* <button onClick={deletChat}>
              <SlOptionsVertical />
              </button> */}
            </StyledHeader>
            <Line border="0.0999999999999999px solid rgba(0, 0, 0, 0.4)" />
          </>
          <Stack direction="column" sx={{ height: "82%", overflowY: "scroll" }}>
            {messages.map((obj, index) =>
              obj.senderId === currentUser ? (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    margin: "0px 10px 0px 10px",
                  }}
                  mt={2}
                  key={index}
                >
                  <MessageBox
                    name={name2}
                    text={obj.text}
                    show_icon={index === obj.id}
                    direction={index === obj.id ? "row-reverse" : "row"}
                  />
                </Stack>
              ) : (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    margin: "0px 10px 0px 10px",
                  }}
                  mt={2}
                  key={index}
                >
                  <MessageBox
                    name={name}
                    text={obj.text}
                    show_icon={index === obj.id}
                    direction={index === obj.id ? "row-reverse" : "row"}
                  />
                </Stack>
              )
            )}
          </Stack>
          <Stack>
            <TextField
              fullWidth
              value={text}
              variant="outlined"
              onChange={handleTextChange}
              placeholder="Start typing..."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSend();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaRegFaceSmileBeam />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <GoMention />
                      <IoSendOutline color="#00ADEF" onClick={handleSend} />
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </StyledContainer>
      )}
    </>
  );
};
export const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 25px 30px;
  border-radius: 10px;
  height: 100%;
  background-color: rgba(239, 243, 253, 0.3);
`;
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;
export const StyledLeftHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
