import { useContext, useEffect, useRef, useState } from "react";
import {
  BusinessCollectionContainer,
  BusinessInfoTextWrapper,
  ContractWrapper,
  EntityInfoWrapper,
  PayRollWrapper,
} from "./BusinessCollection.style";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { businessInfoFields } from "data/clientData/generalBusiness";
import { DateInput, Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import MultiPartnerField from "../multiPartnerShip/MultiPartnerField";
import FlexComp from "components/reusable/flex/Flex";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SyncLoader } from "react-spinners";
import { stateOptions } from "data/clientData/businessFormation";
import { Autocomplete, FormHelperText, Stack, TextField } from "@mui/material";
import { MuiPhone } from "components/reusable/PhoneNumber";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ButtonIcon } from "components/reusable/button/IconButton";
import { Input as FileInput } from "components/layout/navbar/Navbar.style";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";
import dayjs from "dayjs";

const fillStatus = [
  { label: "Sole Proprietor", key: "soleProprietorCheck" },
  { label: "Single Member LLC", key: "singleMemberLlcCheck" },
  { label: "Multi Member LLC", key: "multiMemberLlcCheck" },
  { label: "Partnership", key: "partnershipCheck" },
  { label: "S Corp", key: "sCorpCheck" },
  { label: "C Corp", key: "cCorpCheck" },
];

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const validationSchema = Yup.object().shape({
  entityInfo: Yup.string().required("entityInfo Check Required"),
  businessStartDate: Yup.date().required("Date required"),
  state: Yup.string().required("State required"),
  street: Yup.string().required("Street required"),
  city: Yup.string().required("City required"),
  zipCode: Yup.string().required("Zip Code required"),
  incorporationState: Yup.string().required("Incorporation State required"),
  firstName: Yup.string().required("First Name required"),
  // middleName: Yup.string().required("Middle Name required"),
  lastName: Yup.string().required("Last Name required"),
  mobileNumber: Yup.string().required("Mobile Number required"),
  businessName: Yup.string().required("Business Name required"),
  ssn: Yup.string().required("EIN required"),
  businessDescription: Yup.string().required("Business Description required"),
  payroll: Yup.boolean(),
  contract: Yup.boolean(),
  payRollDoc: Yup.mixed().when("payroll", {
    is: true,
    then: (schema) => schema.required("Attachment required"),
  }),
  contractDoc: Yup.mixed().when("contract", {
    is: true,
    then: (schema) => schema.required("Attachment required"),
  }),
});
const BusinessInfo = ({
  check = false,
  setCheck,
  setCurrentTab,
  rowData: businessData,
}) => {
  const payrollDocRef = useRef(null);
  const contractDocRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [Data, setData] = useState();
  const [entityInformation] = useState([]);
  const [payroll, setPayroll] = useState(false);
  const [, setLoading] = useState(false);
  const { setOpen } = useContext(PageLoaderContext);
  const [contract, setContract] = useState(false);
  const [arrayData, setarrayData] = useState([]);
  const clientId = localStorage.getItem("clientId");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const generalMainId = localStorage.getItem("generalBusinessMainId");
  const [fileName, setFileName] = useState({
    payRollDoc: "",
    contractDoc: "",
  });

  const initialValues = {
    entityInfo: Data?.entityInfo || "", // SoleProprietor, SingleMemberLlc, MultiMemberLlc, Partnership, SCorp, CCorp
    taxYear: dayjs( Data?.taxYear).format("YYYY") || false,
    soleProprietorCheck: Data?.soleProprietorCheck || false,
    singleMemberLlcCheck: Data?.singleMemberLlcCheck || false,
    multiMemberLlcCheck: Data?.multiMemberLlcCheck || false,
    partnershipCheck: Data?.partnershipCheck || false,
    sCorpCheck: Data?.sCorpCheck || false,
    cCorpCheck: Data?.cCorpCheck || false,
    businessStartDate: Data?.businessStartDate || "",
    state: Data?.state || "",
    street: Data?.street || "",
    city: Data?.city || "",
    zipCode: Data?.zipCode || "",
    incorporationState: Data?.incorporationState || "",
    firstName: Data?.firstName || "",
    middleName: Data?.middleName || "",
    lastName: Data?.lastName || "",
    mobileNumber: Data?.mobileNumber || "",
    businessName: Data?.businessName || "",
    ssn: Data?.ssn || "",
    businessDescription: Data?.businessDescription || "",
    payroll: Data?.payroll || false,
    contract: Data?.contract || false,
    payRollDoc: Data?.payRollDoc || "",
    contractDoc: Data?.contractDoc || "",
    payRollDoc: Data?.payRollDoc || "",
    contractDoc: Data?.contractDoc || "",
    payRollDocFileName: Data?.payRollDocFileName || "",
    contractDocFileName: Data?.contractDocFileName || "",

    patnersArr: Data
      ? Data.patnersArr.map((asset) => ({
          name: asset.name,
          mobileNumber: asset.mobileNumber,
          street: asset.street,
          state: asset.state,
          city: asset.city,
          zipCode: asset.zipCode,
          businessPercentage: asset.businessPercentage,
        }))
      : [],
    multiMemberLlcArr: Data
      ? Data.multiMemberLlcArr.map((asset) => ({
          name: asset.name,
          mobileNumber: asset.mobileNumber,
          street: asset.street,
          state: asset.state,
          city: asset.city,
          zipCode: asset.zipCode,
          businessPercentage: asset.businessPercentage,
        }))
      : [],
    sCorpMembersArr: Data
      ? Data.sCorpMembersArr.map((asset) => ({
          name: asset.name,
          mobileNumber: asset.mobileNumber,
          street: asset.street,
          state: asset.state,
          city: asset.city,
          zipCode: asset.zipCode,
          businessPercentage: asset.businessPercentage,
        }))
      : [],
    cCorpShareholdersArr: Data
      ? Data.cCorpShareholdersArr.map((asset) => ({
          name: asset.name,
          mobileNumber: asset.mobileNumber,
          street: asset.street,
          state: asset.state,
          city: asset.city,
          zipCode: asset.zipCode,
          businessPercentage: asset.businessPercentage,
        }))
      : [],
  };

  console.log(Data, "dataaaaa");

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (entityInformation === "Partnership") {
        values.patnersArr = arrayData;
      } else if (entityInformation === "Multi Member LLC") {
        values.multiMemberLlcArr = arrayData;
      } else if (entityInformation === "S Corp") {
        values.sCorpMembersArr = arrayData;
      } else if (entityInformation === "C Corp") {
        values.cCorpShareholdersArr = arrayData;
      }

      try {
        setLoading(true);
        // Prepare data to be uploaded
        const formData = new FormData();
        formData.append("clientId", clientId.toString());
        for (const [key, value] of Object.entries(values)) {
          if (
            key === "patnersArr" ||
            key === "multiMemberLlcArr" ||
            key === "sCorpMembersArr" ||
            key === "cCorpShareholdersArr"
          ) {
            formData.append(key, JSON.stringify(value));
          } else formData.append(key, value);
        }
        console.log("valuesss", values);
        const { data: { data = {} } = {} } = await apiReq(
          !edit ? API_ROUTES.addBusinessInfo : API_ROUTES.updateBusinessInfo,
          "POST",
          formData,
          API_BASE_URL,
          false
        );
        console.log(data, "generalBusinessMaindID");
        if (!edit)
          localStorage.setItem(
            "generalBusinessMainId",
            data.generalBusinessMainId
          );
        localStorage.setItem("businessInfoId", data?.newInfo?._id);

        if (!edit)
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, expenses: false })
          );

        infoToast(
          edit
            ? "Business info updated successfully."
            : "Business info saved successfully."
        );
        setTimeout(() => {
          setCurrentTab(1);
        }, 1500);
      } catch (error) {
        errorToast(
          error?.message ||
            "Error while updating business info. please try later."
        );
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchdata = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getGeneralBusinessByID +
            (businessData?._id || generalMainId),
          "POST",
          { clientId }
        );

        if (data) {
          setPayroll(
            data?.businessInfoId?.payroll ? "Yes (940/W3 required)" : "No"
          );
          setContract(
            data?.businessInfoId?.contract ? "Yes (1099A required)" : "No"
          );
          const ssn = data?.businessInfoId.ssn
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d{7})/, "$1-$2");
          setData({
            ...data?.businessInfoId,
            ssn,
            entityInfo: data?.businessInfoId?.entityInfo?.[0] || "",
            multiMemberLlcArr: data?.businessInfoId?.multiMemberLlcArr,
            cCorpShareholdersArr: data?.businessInfoId?.cCorpShareholdersArr,
            sCorpMembersArr: data?.businessInfoId?.sCorpMembersArr,
            patnersArr: data?.businessInfoId?.patnersArr,
          });
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, expenses: false })
          );
          setEdit(true);
        }
      } catch (error) {
        errorToast(
          error?.message ||
            "Error while fetching business info. please try later."
        );
      } finally {
        setOpen(false);
      }
    };
    if (businessData?._id || generalMainId) fetchdata();
    else {
      !check && setCheck(true);
      localStorage.setItem(
        "tabs",
        JSON.stringify({
          expenses: true,
          fixed_assets: true,
          vehicle_info: true,
          business_use: true,
        })
      );
      const fetchCollection = async () => {
        setOpen(true);
        try {
          const { data: { data = {} } = {} } = await apiReq(
            `${API_ROUTES.getPerosnalCollection}`,
            "POST",
            { clientId }
          );
          if (data?.prepSheetId?._id) {
            const prepSheet = data.prepSheetId;
            setData({
              ...initialValues,
              firstName: prepSheet.firstName,
              lastName: prepSheet.lastName,
              middleName: prepSheet.middleName,
              mobileNumber: prepSheet.mobileNumber,
            });
          }
        } catch (error) {
        } finally {
          setOpen(false);
        }
      };
      fetchCollection();
    }
  }, [businessData?._id]);

  const resetCheckboxes = () => {
    for (let val of fillStatus) {
      formik.setFieldValue([val.key], false);
    }
  };

  const handleUploadButtonClick = (e, type) => {
    e.preventDefault();
    type === "payRollDoc" && payrollDocRef.current.click();
    type === "contractDoc" && contractDocRef.current.click();
  };

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <BusinessCollectionContainer>
          <HeadingFive color="#000" fontSize="18px" fontWeight="500">
            Business Information
          </HeadingFive>
          <EntityInfoWrapper>
            <Span color="#000000" fontSize="16px" fontWeight="500">
              Entity Info
            </Span>
            {fillStatus.map((item, index) => (
              <TextCheckbox
                text={item.label}
                gridGap="1px"
                key={index}
                isChecked={formik.values[item.key]}
                onChange={(e) => {
                  resetCheckboxes();
                  formik.setFieldValue([item.key], e.target.checked, false);
                  formik.setFieldValue("entityInfo", item.label, false);
                }}
              />
            ))}
            <FormHelperText error>
              {formik.touched?.entityInfo && formik.errors?.entityInfo}
            </FormHelperText>
          </EntityInfoWrapper>
          <Stack direction="column" sx={{ width: "40%" }}>
            <DateInput
              label="Tax Year *"
              width="100%"
              openTo="year"
              restrictToLast10Years="true"
              defaultToTenthYear="true"
              disableFuture="true"
              views={["year"]}
              placeholder="YYYY"
              onChange={(date) => formik.setFieldValue("taxYear", date)}
              value={formik.values?.taxYear}
            />
            <FormHelperText error>
              {formik.touched?.taxYear?.year && formik.errors?.taxYear?.year}
            </FormHelperText>
          </Stack>
          <BusinessInfoTextWrapper>
            {businessInfoFields.map(
              ({ label, id, size, width, placeHolder, name, type }, index) => (
                <Stack key={index} direction="column" sx={{ width }}>
                  {name === "mobileNumber" ? (
                    <MuiPhone
                      value={formik.values[name]}
                      handleChange={(value) =>
                        formik.setFieldValue(`mobileNumber`, value)
                      }
                    />
                  ) : (
                    <Input
                      label={label}
                      id={id}
                      type={type}
                      size={size}
                      name={name}
                      placeholder={placeHolder}
                      value={formik.values[name]}
                      onChange={formik.handleChange}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  )}
                  <FormHelperText error>
                    {formik.touched?.[name] && formik.errors[name]}
                  </FormHelperText>
                </Stack>
              )
            )}
            <Stack direction="column" sx={{ width: "49%" }}>
              <Input
                label="EIN *"
                id="ssn"
                size="size"
                width="100%"
                placeholder="XX-XXXXXXX"
                onChange={(e) => {
                  const formattedSSN = e.target.value
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{7})/, "$1-$2");
                  if (e.target.value.length <= 10) {
                    formik.setFieldValue("ssn", formattedSSN);
                  }
                }}
                value={formik.values.ssn
                  .replace(/\D/g, "")
                  .replace(/(\d{2})(\d{7})/, "$1-$2")}
              />
              <FormHelperText error>
                {formik.touched?.ssn && formik.errors.ssn}
              </FormHelperText>
            </Stack>
          </BusinessInfoTextWrapper>
          <HeadingFive fontSize="16px" fontWeight="500" color="black">
            Address
          </HeadingFive>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="Street *"
                id="street"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="Enter"
                onChange={formik.handleChange}
                value={formik.values.street}
              />
              <FormHelperText error>
                {formik.touched?.street && formik.errors.street}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <Input
                label="City *"
                id="city"
                name="city"
                size="size"
                placeholder="Enter"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
              <FormHelperText error>
                {formik.touched?.city && formik.errors.city}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "32%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={stateOptions}
                value={formik.values.state}
                onChange={(_, newVal) => {
                  formik.setFieldValue("state", newVal?.value || "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="State *" />
                )}
              />
              <FormHelperText error>
                {formik.touched?.state && formik.errors.state}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "33%" }}>
              <Input
                label="Zip Code *"
                id="zipCode"
                defaultValue=""
                size="size"
                width="100%"
                placeholder="XXXXX-XXXX"
                onChange={(e) => {
                  let zipCodeValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 9);
                  if (zipCodeValue.length > 5) {
                    zipCodeValue =
                      zipCodeValue.slice(0, 5) + "-" + zipCodeValue.slice(5);
                  }
                  formik.setFieldValue(`zipCode`, zipCodeValue);
                }}
                value={formik.values.zipCode}
              />
              <FormHelperText error>
                {formik.touched?.zipCode && formik.errors?.zipCode}
              </FormHelperText>
            </Stack>
          </FlexComp>
          <FlexComp direction="row" gridGap="25px" width="100%">
            <Stack direction="column" width="49%">
              <DateInput
                label="Business Start Date *"
                width="100%"
                id="businessStartDate"
                onChange={(date) =>
                  formik.setFieldValue("businessStartDate", date)
                }
                value={formik.values?.businessStartDate}
              />
              <FormHelperText error>
                {formik.touched?.businessStartDate &&
                  formik.errors?.businessStartDate}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={stateOptions}
                value={formik.values.incorporationState}
                onChange={(_, newVal) => {
                  formik.setFieldValue(
                    "incorporationState",
                    newVal?.value || ""
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Incorporation State *" />
                )}
              />
              {/* <Input
                label="Incorporation State *"
                id="incorporationState"
                size="size"
                placeholder="Enter"
                onChange={formik.handleChange}
                value={formik.values.incorporationState}
              /> */}
              <FormHelperText error>
                {formik.touched?.incorporationState &&
                  formik.errors?.incorporationState}
              </FormHelperText>
            </Stack>
          </FlexComp>

          {formik.values.partnershipCheck && (
            <MultiPartnerField
              text={"Partners"}
              btnText="+ Add Member"
              multiMemberLlcArr={formik.values.patnersArr}
              setarrayData={(updatedArray) => {
                formik.setFieldValue(`patnersArr`, updatedArray);
              }}
            />
          )}

          {formik.values.multiMemberLlcCheck && (
            <MultiPartnerField
              text={"Multi Member LLC"}
              btnText="+ Add Member"
              multiMemberLlcArr={formik.values.multiMemberLlcArr}
              setarrayData={(updatedArray) => {
                formik.setFieldValue(`multiMemberLlcArr`, updatedArray);
              }}
            />
          )}

          {formik.values.cCorpCheck && (
            <MultiPartnerField
              text={"C Corp Shareholders"}
              btnText="+ Add Member"
              multiMemberLlcArr={formik.values.cCorpShareholdersArr}
              setarrayData={(updatedArray) => {
                formik.setFieldValue(`cCorpShareholdersArr`, updatedArray);
              }}
            />
          )}

          {formik.values.sCorpCheck && (
            <MultiPartnerField
              text={"S Corp Members"}
              btnText="+ Add Member"
              multiMemberLlcArr={formik.values.sCorpMembersArr}
              setarrayData={(updatedArray) => {
                formik.setFieldValue(`sCorpMembersArr`, updatedArray);
              }}
            />
          )}

          <PayRollWrapper>
            <Span color="#000000" fontSize="16px" fontWeight="500">
              Payroll
            </Span>
            <TextCheckbox
              name="payroll"
              text="Yes (940/W3 required)"
              gridGap="1px"
              isChecked={payroll === "Yes (940/W3 required)" || false} // Check if the value matches the current item
              onChange={() => {
                setPayroll((prev) =>
                  prev === "Yes (940/W3 required)"
                    ? false
                    : "Yes (940/W3 required)"
                );
                formik.setFieldValue("payroll", true);
              }} // Set the field value accordingly
            />
            <TextCheckbox
              name="payroll"
              text="No"
              gridGap="1px"
              isChecked={payroll === "No" || false} // Check if the value matches the current item
              onChange={() => {
                setPayroll((prev) => (prev === "No" ? false : "No"));
                formik.setFieldValue("payroll", false);
                setFileName({ ...fileName, payRollDoc: "" });
              }} // Set the field value accordingly
            />

            {formik.values?.payroll && payroll === "Yes (940/W3 required)" && (
              <Stack direction="row" gap={5}>
                {formik?.values?.payRollDoc && (
                  <ButtonIcon
                    width="100%"
                    icon={<CloudUploadIcon />}
                    text={
                      fileName.payRollDoc || formik.values.payRollDocFileName
                    }
                    fontSize="12px"
                    clickHandler={(e) =>
                      handleUploadButtonClick(e, "payRollDoc")
                    }
                  />
                )}
                {!formik?.values?.payRollDoc && (
                  <ButtonIcon
                    width="100%"
                    icon={<CloudUploadIcon />}
                    text={"Upload Required Documents"}
                    fontSize="12px"
                    clickHandler={(e) =>
                      handleUploadButtonClick(e, "payRollDoc")
                    }
                  />
                )}
                <FileInput
                  type="file"
                  id="payRollDoc"
                  ref={payrollDocRef}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "payRollDoc",
                      event.currentTarget.files[0]
                    );
                    setFileName({
                      ...fileName,
                      payRollDoc: event.currentTarget.files[0]?.name,
                    });
                  }}
                />
                <FormHelperText error>
                  {formik.touched?.payRollDoc && formik.errors?.payRollDoc}
                </FormHelperText>
              </Stack>
            )}
          </PayRollWrapper>

          <ContractWrapper>
            <Span color="#000000" fontSize="16px" fontWeight="500">
              Contract
            </Span>
            <TextCheckbox
              name="contract"
              text="Yes (1099A required)"
              gridGap="10px"
              isChecked={contract === "Yes (1099A required)" || false} // Check if the value matches the current item
              onChange={() => {
                setContract((prev) =>
                  prev === "Yes (1099A required)"
                    ? false
                    : "Yes (1099A required)"
                );
                formik.setFieldValue("contract", true);
              }} // Set the field value accordingly
            />
            <TextCheckbox
              name="contract"
              text="No"
              gridGap="10px"
              isChecked={contract === "No" || false} // Check if the value matches the current item
              onChange={() => {
                setContract((prev) => (prev === "No" ? false : "No"));
                setFileName({ ...fileName, contractDoc: "" });
                formik.setFieldValue("contract", false);
              }} // Set the field value accordingly
            />
            {formik.values?.contract && contract === "Yes (1099A required)" && (
              <Stack direction="row" gap={5}>
                {formik?.values?.contractDoc && (
                  <ButtonIcon
                    width="100%"
                    icon={<CloudUploadIcon />}
                    text={
                      fileName.contractDoc ||
                      formik?.values?.contractDocFileName
                    }
                    fontSize="12px"
                    clickHandler={(e) =>
                      handleUploadButtonClick(e, "contractDoc")
                    }
                  />
                )}
                {!formik?.values?.contractDoc && (
                  <ButtonIcon
                    width="100%"
                    icon={<CloudUploadIcon />}
                    text={"Upload Required Documents"}
                    fontSize="12px"
                    clickHandler={(e) =>
                      handleUploadButtonClick(e, "contractDoc")
                    }
                  />
                )}
                <FileInput
                  type="file"
                  id="contractDoc"
                  ref={contractDocRef}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "contractDoc",
                      event.currentTarget.files[0]
                    );
                    setFileName({
                      ...fileName,
                      contractDoc: event.currentTarget.files[0]?.name,
                    });
                  }}
                />
                <FormHelperText error>
                  {formik.touched?.contractDoc && formik.errors?.contractDoc}
                </FormHelperText>
              </Stack>
            )}
          </ContractWrapper>
          <Button
            text={
              formik.isSubmitting ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Next"
              )
            }
            type="submit"
            width="10%"
            fontSize="20px"
            padding="10px 40px"
            alignSelf="flex-end"
            backgroundColor="#0B2558"
          />
        </BusinessCollectionContainer>
      </form>
    </>
  );
};
export default BusinessInfo;
