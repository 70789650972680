import React from "react";
import PdfUploadButton from "../../reusable/button/PdfUploadButton";
import { GeneralBusinessBtnWrapper } from "./UploadTaxDocs.style";

const PersonalDocs = ({ data }) => {
  const extractDocLinks = (data) => {
    let result = [];
    const visited = new Set(); // Track visited objects to prevent circular references
  
    const traverse = (obj) => {
      if (!obj || typeof obj !== "object" || visited.has(obj)) {
        return;
      }
  
      visited.add(obj);
  
      // Handle arrays
      if (Array.isArray(obj)) {
        for (let item of obj) {
          traverse(item);
        }
        return;
      }
  
      for (let key in obj) {
        // Check for document URLs and filenames
        if (
          (key.includes("FileName") ||
            key.includes("Filename") ||
            key.includes("fileName")) &&
          obj[key]
        ) {
          // Extract file name and corresponding URL
          const baseKey = key.replace(/FileName|Filename|fileName/i, "");
          const fileUrl = obj[baseKey] || "";
  
          if (
            fileUrl &&
            typeof fileUrl === "string" &&
            fileUrl.startsWith("http")
          ) {
            result.push({ name: obj[key], value: fileUrl });
          }
        }
        // Special handling for document arrays like w2Docs, docs1099, otherDocs
        else if (
          [
            "w2Docs",
            "docs1099",
            "otherDocs",
            "dayCareDocs",
            "dependent",
            "spouse"
          ].includes(key) &&
          Array.isArray(obj[key])
        ) {
          for (let doc of obj[key]) {
            if (doc.doc && doc.fileName) {
              result.push({ name: doc.fileName, value: doc.doc });
            }
            // For other nested objects in arrays
            traverse(doc);
          }
        }
        // Continue traversing nested objects
        else if (typeof obj[key] === "object" && obj[key] !== null) {
          traverse(obj[key]);
        }
      }
    };
  
    traverse(data);
    return result;
  };
  // console.log(data, "dataaaa");

  const documents = extractDocLinks(data);


  console.log(documents, "documentsasasasaa");

  return (
    <GeneralBusinessBtnWrapper>
      <PdfUploadButton doc="personalDoc" documents={documents} />
    </GeneralBusinessBtnWrapper>
  );
};

export default PersonalDocs;
