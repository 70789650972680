import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FlexComp from "../../../components/reusable/flex/Flex";
import PartOneFields from "./PartOneFields";

const TaxForm = ({ formData, handleInputChange, handleSubmit }) => {
  
 

  return (
    <form onSubmit={handleSubmit}>
      <p style={{fontSize:"14px" ,fontFamily:'inter'}}>Enter whole dollars only on lines 1 through 5.</p>
      <p style={{fontSize:"14px" ,fontFamily:'inter'}}>
        Note: Form 1040-SS filers use line 4 only. Leave lines 1, 2, 3, and 5
        blank.
      </p>
      <div style={{display:'flex'}}>
     <FlexComp direction='column'gridGap='15px' width='auto'>
      <div style={{ display: "flex" }}>
        <p style={{fontSize:'14px'  ,fontFamily:'inter' }}>
          1 Adjusted gross income. . . . . . . . . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .. . . . . . . . .      
        </p>
       
      </div>
      <div style={{ display: "flex" }}>
        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>
          2 Total tax. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . .  . . . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
        </p>
        
      </div>
      <div style={{ display: "flex",justifyContent:'space-between' }}>
        <p style={{fontSize:'14px'  ,fontFamily:'inter'}}>
          3 Federal income tax withheld from Form(s) W-2 and Form(s) 1099  . . . . . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
        </p>
        
      </div>
      <div style={{ display: "flex" }}>
        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>
          4 Amount you want refunded to you. . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .  . . . . . . . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . 
        </p>
       
      </div>

      <div style={{ display: "flex" }}>
        <p style={{fontSize:'14px' ,fontFamily:'inter'}}>
          5 Amount you owe   . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .   . . . . . . . . . . . . . . . . . . . . . . .. . . . . . . . . . . . . . . . . . . . . . . . . . . . 
        </p>
       
      </div>
     
      </FlexComp>
      {/* <PartOneFields/> */}
      <div>
        
        <div >
        <div style={{ display: "flex" }}>
          <div
            style={{
              textAlign: "center",
              width: "40px",
              height: "32px",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              fontFamily:'inter'
            }}
          >
            1
          </div>
          
          <input className="pdfinput" type="text" id="adjustedGrossIncome"
        name="adjustedGrossIncome"
        // value={formData.form8879.adjustedGrossIncome}
        onChange={handleInputChange} />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              textAlign: "center",
              width: "40px",
              height: "32px",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              fontFamily:'inter'
            }}
          >
            2
          </div>
          
          <input className="pdfinput" type="text" id="totalTax"
        name="totalTax"
        // value={formData.form8879.totalTax}
        onChange={handleInputChange}/>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              textAlign: "center",
              width: "40px",
              height: "32px",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              fontFamily:'inter'
            }}
          >
            3
          </div>
          
          <input className="pdfinput" type="text"  id="federalIncomeTax"
        name="federalIncomeTax"
        // value={formData.form8879.federalIncomeTax}
        onChange={handleInputChange} />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              textAlign: "center",
              width: "40px",
              height: "32px",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              fontFamily:'inter'
            }}
          >
            4
          </div>
          
          <input className="pdfinput" type="text" id="amountRefund"
        name="amountRefund"
        // value={formData.form8879.amountRefund}
        onChange={handleInputChange} />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              textAlign: "center",
              width: "40px",
              height: "32px",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              fontFamily:'inter'
            }}
          >
            5
          </div>
          
          <input className="pdfinput" type="text" id="amountYouOwe"
        name="amountYouOwe"
        // value={formData.form8879.amountYouOwe}
        onChange={handleInputChange} />
        </div>
      </div>

    </div>
      </div>
    </form>
  );
};

export default TaxForm;
