import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const BusinessData = ({ clientData }) => {
  console.log(clientData, "personalData");

  const {
    businessInfoId,
    businessUseOfHomeId,
    expensesId,
    fixedAssetsId,
    vehicleInfoId,
  } = clientData;

  return (
    <div>
      <Box
        sx={{ background: "#EFF3FD", borderRadius: "12px", padding: "20px" }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Businesss Information
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Entity Info
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.entityInfo?.[0]}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Individual Name{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.firstName} {businessInfoId?.lastName}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Phone#{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.mobileNumber}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Business Name{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {businessInfoId?.businessName}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Fed ID/EIN and/or SSN{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.ssn}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Business Address
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.street} {businessInfoId?.city}{" "}
              {businessInfoId?.state}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Business Description Products/Services
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.businessDescription}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Business Start Date
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.businessStartDate}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Incorporate State
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.incorporationState}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Payroll
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.payroll ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Contractors
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessInfoId?.contract ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Expenses
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Accounting
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.accounting || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Office Expenses{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.officeExpenses || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Vehicle Expense{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.vehicleExp || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Advertising{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {expensesId?.advertising || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Outside Contractors{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.outsideContractors || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Delivery
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.shipper || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Bank Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.bankCharges || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Postage
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.postage || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Dues/Subscriptions
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.subscriptions || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Cell Phone
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.cellPhone || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Printing
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.printing || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Equipment Rental
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.equipmentRental || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Insurance (Bus & Liability)
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.insurance || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Rent
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.rent || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Interest Exp
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.interestExp || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Internet Cost (business only)
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.internetCost || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Repairs & Maintenance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.repairsAndMaintenance || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Commission Expenses
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.commissionExpenses || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Legal & Professional Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.legalAndProfessionalFees || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Supplies
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.supplies || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Travel
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.travel || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              License & Permit
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.licenseAndPermit || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Telephone (Cell & Land)
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.telephone || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Other
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.other || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Miscellaneous
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.miscellaneous || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Training
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.training || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Meals & Entertainment
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {expensesId?.mealsAndEntertainment || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
     {fixedAssetsId?.fixedAssetsArr?.length > 0 &&  <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Assets
        </Box>

        {fixedAssetsId?.fixedAssetsArr?.map((item, i) => {
          return (
            <>
              <div>
                <Typography>Fixed Assets # {i + 1}</Typography>
                <Grid container spacing={2}>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Asset Name
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item?.firstName || "N/A" }
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Enter Price
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item?.enterPrice}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography fontSize={"16px"} fontWeight={"600"}>
                      Date
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"400"}>
                      {" "}
                      {item?.date}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </>
          );
        })}
      </Box>}
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Business Use of Home
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Total Area of Home (Sq. Ft)
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.totalArea || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Business Use Area (Sq. Ft)
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.businessArea || "N/A"} 
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Association Dues{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.associationDues || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Repairs & Maintenance{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {businessUseOfHomeId?.repairsAndMaintenance || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Mortgage Interest{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.mortageInterest || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Utilities
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.utilities || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Real Estate Taxes
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.realEstateTaxes || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Telephone
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.telephone || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Rent
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.rent || "N/A"}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Security
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.security || "N/A" }
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Insurance
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {businessUseOfHomeId?.insurance || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BusinessData;
