import { useContext, useEffect, useState } from "react";
import SwitchComp from "components/reusable/switch/Switch";
import Bell from "assets/navbar/Bell.png";
import User from "assets/navbar/User.png";
import {
  BellBg,
  NavRightWrapper,
  SwitchWrapper,
  UserInfoWrapper,
} from "./Navbar.style";
import { ProfileContext } from "context/ProfileContext";
import { Paragraph, Span } from "components/reusable/typography/Typography.style";
import { useNavigate } from "react-router-dom";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import BusinessProfileModal from "components/modals/BusinessProfileModal";

const NavRight = ({ setIsOpenModal }) => {
  
  let { role, setRole, profileData } = useContext(ProfileContext);
  profileData = profileData?.user;
  const [submitting, setSubmitting] = useState(false); 
  const [openModal, setOpenModal] = useState(false);
  const profile = profileData?.profileImage;
  const navigate = useNavigate();

 
    // const hasSeenModal = localStorage.getItem("hasSeenBusinessProfileModal");
    // if (!hasSeenModal) {
    //   setOpenModal(true);
    //   localStorage.setItem("hasSeenBusinessProfileModal", "true");
    // }
    // console.log(role, "role")

  const handleRoleChange = async (newRole) => {
    if (role !== newRole) { 
      setSubmitting(true);
      try {
        await apiReq(API_ROUTES.switchProfile, "POST", {
          userId: localStorage.getItem('clientId'), 
          profile: newRole === 1 ? 'Personal' : 'Business',
        });
        setRole(newRole); 
      } catch (error) {
        console.error('Error switching profile:', error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    const isFirstLogin = localStorage.getItem('isFirstLogin');
    
    if (isFirstLogin === 'true') {
      setOpenModal(true);
      localStorage.setItem('isFirstLogin', 'false'); // Update flag to false after showing modal
    }
  }, []);

  return (
    <NavRightWrapper>
      {/* <SwitchWrapper>
        <SwitchComp
          value={role}
          valueSetter={handleRoleChange} 
          disabled={submitting} 
        />
        <Paragraph fontSize="16px">
          {role === 1 ? "Personal" : "Business"}
        </Paragraph>
      </SwitchWrapper> */}
      <BellBg onClick={() => setIsOpenModal(true)}>
        <img src={Bell} alt="Bell" />
      </BellBg>
      <UserInfoWrapper onClick={() => navigate("/profile")}>
        <img src={profile ? profile : User} alt="User" width={45} height={45} style={{ borderRadius: "10px"}} />
        <Span fontSize="16px" fontWeight="500">
          {profileData?.firstName + " " + profileData?.lastName}
        </Span>
      </UserInfoWrapper>
      {/* <BusinessProfileModal handleRoleChange={handleRoleChange} setOpenModal={setOpenModal} openModal={openModal} /> */}
    </NavRightWrapper>
  );
};

export default NavRight;
