import { useContext, useEffect, useState } from "react";
import { businessInfoFields } from "../../../data/clientData/workSheet";
import { ItemizedDeductionContainer } from "./WorkSheet.style";
import { Button } from "../../reusable/button/Button";
import WorksheetAccordion from "./WorksheetAccordian"; // Import the new component
import { useFormik } from "formik";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import { validationSchema } from "./WorkSchema";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import { FormHelperText, Stack } from "@mui/material";
import { DateInput } from "components/reusable/input/Inputs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const basicInfo = {
  name: "",
  mobileNumber: "",
};

const setvalue = {
  entityInfo: "", // SoleProprietor, SingleMemberLlc, MultiMemberLlc, Partnership, SCorp, CCorp
  soleProprietorCheck: false,
  singleMemberLlcCheck: false,
  multiMemberLlcCheck: false,
  partnershipCheck: false,
  sCorpCheck: false,
  cCorpCheck: false,
  firstName: "",
  lastName: "",
  middleName: "",

  mobileNumber: "",
  businessName: "",
  ssn: "",
  city: "",
  street: "",
  state: "",
  zipCode: "",
  taxYear: "",
  amountPaid: "",
  multiMemberLlcArr: [basicInfo],
  patnersArr: [basicInfo],
  sCorpMembersArr: [basicInfo],
  cCorpShareholdersArr: [basicInfo],
};



const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const WorkSheetComp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setOpen } = useContext(PageLoaderContext);
  const [accordions, setAccordions] = useState(businessInfoFields.slice(0, 1));
  const navigate = useNavigate()
  const [Data, setData] = useState();
  const [openIndex, setOpenIndex] = useState(0);
  const clientId = localStorage.getItem("clientId");
  const addAccordion = () => {
    const newAccordion = {
      ...businessInfoFields[0],
      title: "Business Information (Vendor# " + (accordions.length + 1) + ")",
    };
    setFieldValue(`businessInfoArr`, [...values.businessInfoArr, setvalue]);
    setAccordions((prev) => [...prev, newAccordion]);
  };
  const businessFormationId = localStorage.getItem(
    "worksheetId"
  );
  const edit = localStorage.getItem(
    "worksheetEdit"
  );
  const worksheetId = (businessFormationId)?.replace(/"/g, '');

  const basicInfo = {
    name: "",
    mobileNumber: "",
  };
  console.log(Data, "basicInfo");

  const setvalue = {
    entityInfo: "", // SoleProprietor, SingleMemberLlc, MultiMemberLlc, Partnership, SCorp, CCorp
    soleProprietorCheck: false,
    singleMemberLlcCheck: false,
    multiMemberLlcCheck: false,
    partnershipCheck: false,
    sCorpCheck: false,
    cCorpCheck: false,
    firstName: Data?.firstName || "",
    lastName: Data?.lastName || "",
    middleName: Data?.middleName || "",

    mobileNumber: Data?.phone || "",
    businessName: "",
    ssn: "",
    city: Data?.city || "",
    street: Data?.street || "",
    state: Data?.state || "",
    zipCode: Data?.zipCode || "",
    amountPaid: "",
    multiMemberLlcArr: [basicInfo],
    patnersArr: [basicInfo],
    sCorpMembersArr: [basicInfo],
    cCorpShareholdersArr: [basicInfo],
  };

  const initialValues = {
    taxYear: "2023",
    businessInfoArr: [setvalue],
    additionalNotes: "",
  };

  const addMountAccordian = () => {
    const newAccordion = {
      ...businessInfoFields[0],
      title: "Business Information (Vendor# " + (accordions.length + 1) + ")",
    };
    setAccordions((prev) => [...prev, newAccordion]);
  };

  const deleteAccordion = (index) => {
    if (index === 0) return;
    const updatedBusinessInfoArr = [...values.businessInfoArr];
    updatedBusinessInfoArr.splice(index, 1);
    setFieldValue("businessInfoArr", updatedBusinessInfoArr);
    setAccordions((prev) => prev.filter((_, i) => i !== index));
  };
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [firstAccordionOpen, setFirstAccordionOpen] = useState(true);

  const toggleAccordion = (index) => {
    if (index === 0) {
      // Toggle the first accordion
      setFirstAccordionOpen((prevState) => !prevState);
    } else {
      setFirstAccordionOpen(false);
    }
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleApiCall = async () => {
    try {
      if (!clientId) {
        toast.error("Client ID not found.");
        return;
      }
      const payload = edit
      ? { worksheetId, ...values }
      : { clientId, ...values };
      setIsLoading(true);
      await apiReq(
        `${edit ? API_ROUTES.updateWorksheet : API_ROUTES.addworkSheet}`,
        "POST",
        payload
      );
      toast.info(
        edit ? "Form updated successfully." : "Form saved successfully."
      );
      navigate("/worksheet")
    } catch (error) {
      // console.log(error,"error")
      toast.error(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    validateForm,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false); 
    },
  });
  console.log(values.businessInfoArr[0].multiMemberLlcCheck, "worksheet");

  useEffect(() => {
    const fetchItemizeDeduction = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(API_ROUTES.getworksheet, "POST", {clientId: clientId, worksheetId});

        if (data) {
          setValues(data);
          for (let index = 0; index < data?.businessInfoArr.length; index++) {
            if (index !== 0) addMountAccordian();
          }
        }
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };
    if(worksheetId) fetchItemizeDeduction();
    
    //ITS DONE
  }, [worksheetId]);
  const handleSave = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setIsValidateTaxModal(true); // Open the modal
    } else {
      toast.error("Please fix the validation errors.");
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <ItemizedDeductionContainer style={{background: "#fff", height: "calc(100vh - 130px)", overflowY: "auto", padding: "20px"}}>
        <Stack direction="column" sx={{ width: "29%" }}>
              <DateInput
                label="Tax year *"
                id="tax-year"
                name="taxYear"
                openTo="year"
                width="100%"
                placeholder="YYYYY"
                disableFuture="true"
                views={["year"]}
                value={values?.taxYear ? dayjs(values?.taxYear).format("YYYY") : "2023"}
                onChange={(date) =>
                  setFieldValue(`taxYear`, date)
                }
              />
              <FormHelperText error>
                {touched?.taxYear && errors?.taxYear}
              </FormHelperText>
            </Stack>
          {accordions.map((item, index) => (
            <WorksheetAccordion
              item={item}
              // Data={Data}
              key={index}
              index={index}
              openIndex={openIndex}
              setFieldValue={setFieldValue}
              values={values.businessInfoArr[index]}
              touched={touched.businessInfoArr?.[index]}
              isOpen={
                openIndex === index && (index === 0 ? firstAccordionOpen : true)
              }
              errors={errors.businessInfoArr?.[index]}
              toggleAccordion={() => toggleAccordion(index)}
              onDelete={deleteAccordion}
            />
          ))}
          <Button
            text="+ Add Vendor"
            width="auto"
            type="button"
            fontSize="18px"
            marginTop="5px"
            backgroundColor="rgba(0, 173, 239, 1)"
            alignSelf="start"
            onClick={addAccordion}
          />
          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Save"
            }
            width="12%"
            type="button"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="end"
            onClick={handleSave}
          />
        </ItemizedDeductionContainer>
      </form>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        handleYesClick={handleApiCall}
        paragraph="Are you sure you want to submit this form?"
      />
    </>
  );
};

export default WorkSheetComp;
