import * as Yup from 'yup';
const fillingStatusEnum = {
  llcCheck: "LLC",
  businessCorpCheck: "BusinessCorp",
  soleProprietorCheck: "SoleProprietor",
  nonProfitCheck: "NonProfit",
  dbaCheck: "DBA",
};
const nonProfitFormationSchema = Yup.object().shape({
  personBusinessInfo: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First Name required'),
      // middleName: Yup.string().required('Middle Name required'),
      lastName: Yup.string().required('Last Name required'),
      // ssn: Yup.string().required('EIN required'),
      title: Yup.string().required('Title required'),
      mobileNumber: Yup.string().required('Mobile Number required'),
      city: Yup.string().required('City required'),
      street: Yup.string().required('Street required'),
      state: Yup.string().required('State required'),
      zipCode: Yup.string().required('Zip Code required'),
    })
  ).required('At least one person business info required'),
  fillingStatus: Yup.string().oneOf(Object.values(fillingStatusEnum), 'Invalid filling status').required('Filling status is required'),
  llcCheck: Yup.boolean().required('LLC Check required'),
  businessCorpCheck: Yup.boolean(),
  soleProprietorCheck: Yup.boolean(),
  nonProfitCheck: Yup.boolean(),
  dbaCheck: Yup.boolean(),
  currentCorporationName: Yup.string().required('Current Corporation Name required'),
  preferedName: Yup.string().required('Preferred Name required'),
  city: Yup.string().required('City required'),
  street: Yup.string().required('Street required'),
  state: Yup.string().required('State required'),
  zipCode: Yup.string().required('Zip Code required'),
  businessDescription: Yup.string().required('Business Description required'),
});

export { nonProfitFormationSchema };
