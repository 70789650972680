import { useState } from "react";
import { Tab, Tabs } from "@mui/material";

const MenuTabs = ({ 
  tabData, 
  initial, 
  fullName,
  rowData, 
  onUploadFile,
  tabComponents, 
  uploadedFile = {},
  onSaveSelectStyle,
  drawnSignature = '', 
  onSaveDrawnSignature,
  handleUploadedFileRemove,
  handleClearDrawnSignature, 
}) => {
  const [check, setCheck] = useState(false);
  const curr_tab = localStorage.getItem("current_tab");
  const [currentTab, setCurrentTab] = useState(+curr_tab || 0);
  const tab = JSON.parse(localStorage.getItem("tabs") || "{}");
console.log(currentTab, "current tab")
  const handleChange = (_, tabValue) => {
    setCurrentTab(tabValue);
    localStorage.setItem("current_tab", tabValue);
    
 
  };
  const CustomTabPanel = ({ value, index }) => {
    const Component = tabComponents[index];
    return (
      value === index && (
        <div
          role="tabpanel"
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          <Component
            check={check}
            rowData={rowData}
            initial={initial}
            fullName={fullName}
            setCheck={setCheck}
            uploadedFile={uploadedFile}
            onUploadFile={onUploadFile}
            setCurrentTab={setCurrentTab}
            drawnSignature={drawnSignature}
            onSaveSelectStyle={onSaveSelectStyle}
            onSaveDrawnSignature={onSaveDrawnSignature}
            handleUploadedFileRemove={handleUploadedFileRemove}
            handleClearDrawnSignature={handleClearDrawnSignature}
          />
        </div>
      )
    );
  };

  return (
    <>
      <Tabs variant="scrollable" scrollButtons="false" value={currentTab} onChange={handleChange} >
        {tabData.map((value, index) => (
          <Tab label={value?.label || value} key={index} disabled={tab?.[value?.key]} />
        ))}
      </Tabs>
      {tabData.map((label, index) => (
        <CustomTabPanel key={index} index={index} value={currentTab} />
      ))}
    </>
  );
};

export default MenuTabs;
