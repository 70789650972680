import 'react-international-phone/style.css';

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['us'].includes(iso2);
  });

export const MuiPhone = ({ label = "Mobile number", value, handleChange, ...restProps }) => {
  const { inputValue, handlePhoneValueChange, inputRef, country } =
    usePhoneInput({
      defaultCountry: 'us',
      value,
      countries,
      onChange: (data) => {
        handleChange(data.phone);
      },
    });

  return (
    <TextField
      variant="outlined"
      label={label}
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ marginRight: '2px', marginLeft: '-8px' }}>
            <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }}/>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};