import styled from "styled-components";

export const BusinessInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const BusinessInfoInputWrapper = styled.div`
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 25px;
`;

export const CorporationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const CorporationInfoInputWrapper = styled.div`
  width: 100%;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 25px;
`;

export const StatusCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
export const ItemizedDeductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  grid-gap: 5px;
  width: 100%;
  min-height: auto;
  padding: 15px;
`;
