import styled from "styled-components";

export const WorkSheetBusinessInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
`;

export const EntityInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
`;
export const ItemizedDeductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  width: 100%;
 padding:10px
    justify-content: space-between;
 ::-webkit-scrollbar {
    width: 0px !important;
  }
`;
