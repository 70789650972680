import Auth from "../../../assets/auth/Auth.png";
import { AuthPicWrapper, SignupContainer } from "../signup/Signup.style";
import StaffResetPass from "./StaffResetPass";

const StaffResetPassword = () => {
  return (
    <SignupContainer>
      <StaffResetPass />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default StaffResetPassword;
