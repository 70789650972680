import React, { useContext, useEffect, useState } from "react";
import FlexComp from "../../../components/reusable/flex/Flex";
import { Input } from "../../../components/reusable/input/Inputs";
import { Button } from "../../../components/reusable/button/Button";
import { settingFields } from "../../../data/clientData/setting";
import { HeadingFive } from "../../reusable/typography/Typography.style";
import DropdownComp from "../../reusable/dropdownComp/DropdownComp";
import axios from "axios";
import ProfilePicUpload from "../../../components/reusable/profilePicUpload/ProfilePicUpload";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toaster } from "components/reusable/toaster";
import { FormHelperText, Stack } from "@mui/material";
import { ProfileContext } from "context/ProfileContext";

const StaffProfile = ({ setName }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(localStorage.getItem("clientId"));
  const data = JSON.parse(localStorage.getItem("userData") || "{}");
  const user=data?.user
  const [profile, setprofile] = useState(null);
  const { setadminstaffProfileData } = useContext(ProfileContext);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phone: user?.phone ||"",
      address: user?.address ||"",
      profileImage: profile || user?.profileImage,
      userId: user?._id,
      role: user?.role ||"",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      address: Yup.string().required("State is required"),
      profileImage: Yup.mixed(),
      userId: Yup.string().required("User ID is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);
        console.log("working");

        // Create FormData
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }

        const response = await apiReq(
          API_ROUTES.updateAdminProfile,
          "POST",
          formData,
          API_BASE_URL,
          true
        );
        const updatedUserData = { ...JSON.parse(localStorage.getItem("userData")), user: response?.data?.data };
    localStorage.setItem("userData", JSON.stringify(updatedUserData));
    setadminstaffProfileData(updatedUserData);
    setSuccess("Profile updated succesfully");
      } catch (error) {
        setSuccess("");
        setError(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (!clientId) {
      console.error("Client ID not found in local storage");
    }
  }, [clientId]);
  useEffect(() => {
    setName(`${user?.firstName} ${user?.lastName}`);
  }, [user?.firstName, user?.lastName]);

  return (
    <>
    {error ||
        (success && (
          <Toaster
            open={!!error || !!success}
            content={error || success}
            type={error ? "error" : "success"}
            handleClose={() => {
              setError("");
              setSuccess("");
            }}
          />
        ))}
    <form noValidate onSubmit={formik.handleSubmit}>
      <ProfilePicUpload setprofile={setprofile} profile={user?.profileImage || ""}/>
      <FormHelperText error>{formik.errors.profileImage}</FormHelperText>
      <FlexComp
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gridGap="15px"
        marginTop="40px"
      >
        {settingFields.map(
          (
            { label, id, defaultValue, size, width, placeHolder, name },
            index
          ) => (
            <Stack direction="column" sx={{ width: "30%" }}>
              <Input
                key={index}
                label={label}
                name={name}
                defaultValue={defaultValue}
                size={size}
                // width={width}
                placeHolder={placeHolder}
                value={formik.values[name]}
                onChange={formik.handleChange}
                required
              />
              <FormHelperText error>{formik.errors[name]}</FormHelperText>
            </Stack>
          )
        )}
      </FlexComp>
      <FlexComp direction="row" gridGap="20px" alignItems="center" marginTop="10px">
        <Button
          text={loading ? "Updating..." : "Update Profile"}
          onClick={formik.handleSubmit}
          disabled={loading}
          width="auto"
          fontSize="18px"
          backgroundColor="#0B2558"
          alignSelf="end"
          padding="15px 20px"
          type="submit"
        />
        <Button
          text="Cancel"
          type="button"
          width="auto"
          fontSize="18px"
          backgroundColor="#EFF3FD"
          alignSelf="end"
          padding="15px 20px"
          color="#AEB6CF"
        />
      </FlexComp>
      </form>
    </>
  );
};

export default StaffProfile;
