import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Button } from "../reusable/button/Button";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import {
  BulletList,
  BulletListItem,
  EmailVerContainer,
  EmailVerContainer1,
  ModalHeading,
  ModalText,
  Resend,
} from "./Modals.style";
import { HeadingFive } from "../reusable/typography/Typography.style";


const VerifiedModal = ({open,onClose}) => {
    const navigate = useNavigate();
//   const [open, setOpen] = useState(true);
//   const handleClose = () => setOpen(false);
  const [otp, setOtp] = useState("000000");
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <EmailVerContainer1>
        <ModalHeading>
          <HeadingFive fontSize="36px">Email Verified!</HeadingFive>
          <CloseIcon
            sx={{ color: "#000", fontSize: "40px", cursor: "pointer" }}
            onClick={onClose}
          />
        </ModalHeading>
        <ModalText>
          <p style={{fontSize:'14px'}}>You have successfully verified the account.</p>
         
        </ModalText>
      
        <Button
          text="Go to Login"
          width="100%"
          fontSize="18px"
          onClick={() => navigate("/")}
        />
      
      </EmailVerContainer1>
    </Modal>
  );
};

export default VerifiedModal;
