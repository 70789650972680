import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Button } from "../button/Button";
import styled from "styled-components";
import SecDropdownComp from "../dropdownComp/SecDropdown";
import { SyncLoader } from "react-spinners";
export default function DateComponent({
  setStartDate,
  setEndDate,
  handleButtonClick,
  loading
}) {
  const options = [
    { value: "height", label: "High" },
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
  ];
  const [selectedOption, setSelectedOption] = React.useState(null);
  const handleDateChange = (date, type) => {
    if (type === "startDate") {
      setStartDate(date.format("MM-DD-YYYY"));
    } else if (type === "endDate") {
      setEndDate(date.format("MM-DD-YYYY"));
    }
  };
  return (
    <DatePickerMainDiv>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={[
            "DatePicker",
            "MobileDatePicker",
            "DesktopDatePicker",
            "StaticDatePicker",
          ]}
        >
          <DemoItem label="Start Date">
            <DesktopDatePicker
              variant="standard"
              sx={{
                "& fieldset": { border: "none" },
                "& .css-11a8txn-MuiStack-root>:not(style)~:not(style)": {
                  marginTop: "0px",
                },
              }}
              defaultValue={dayjs()}
              onChange={(date) => handleDateChange(date, "startDate")}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={[
            "DatePicker",
            "MobileDatePicker",
            "DesktopDatePicker",
            "StaticDatePicker",
          ]}
        >
          <DemoItem label="End Date">
            <DesktopDatePicker
              variant="standard"
              sx={{
                "& fieldset": { border: "none" },
                "& .css-11a8txn-MuiStack-root>:not(style)~:not(style)": {
                  marginTop: "0px",
                },
              }}
              defaultValue={dayjs()}
              onChange={(date) => handleDateChange(date, "endDate")}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
      {/* <SecDropdownComp
        label='Select Form Type'
        id='location-select'
        placeholder='select'
        defaultValue='Role'
        focused
        size='small'
        width='20%'
        options={options}
        selectedMonth={selectedOption}
        handleChange={() => {}}
      />
      <SecDropdownComp
        label='Select Status'
        id='location-select'
        placeholder='select'
        defaultValue='Role'
        focused
        size='small'
        width='20%'
        options={options}
        selectedMonth={selectedOption}
        handleChange={() => {}}
      /> */}
      <Button
        text={!loading?"Go":<SyncLoader color={"#FFFFFF"} size={8} />}
        width={!loading?"50px":"100px"}
        disabled={loading}
        padding="11px"
        marginTop="36px"
        onClick={handleButtonClick}
      />
    </DatePickerMainDiv>
  );
}
export const DatePickerMainDiv = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  width: 100%;
`;
