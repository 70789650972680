import * as Yup from "yup";
import { array, boolean, date, mixed, number, object, string } from "yup";

export const prepSheetSchema = object().shape({
  firstName: string()
    .min(3, "First name must be at least 3 characters")
    .max(30, "First name must be maximum 50 characters")
    .required("First name is required"),
  // middleName: string()
  //     .min(3, "Middle name must be at least 3 characters")
  //     .max(20, "Middle name must be maximum 50 characters")
  //     .required("Middle name is required"),
  lastName: string()
    .min(3, "Last name must be at least 3 characters")
    .max(30, "Last name must be maximum 50 characters")
    .required("Last name is required"),
  dob: date()
    .required("Date of birth required")
    .max(new Date(), "Furture date not valid"),
  taxYear: Yup.string()
    .required('Tax Year is required'),
  ssn: string().required("SSN required"),
  phone: string().required("Mobile number required"),
  city: string().required("City name required"),
  street: string().required("Street number required"),
  state: string().required("State required"),
  zipCode: string().required("Zip code required"),
  alternateWorkAddressCheck: boolean(),
  alternateHomeAddressCheck: boolean(),
  alternateSpouseAddressCheck: boolean(),
  alternateOtherAddressCheck: boolean(),
  statusSingle: boolean(),
  statusMarriedFillingJointly: boolean(),
  statusMarriedFillingSeparately: boolean(),
  statusMarriedFillingHeadOfHousehold: boolean(),
  // freeLookRequestCheck: boolean(),
  // freeLookRequestedNoCheck: boolean(),
  // alternatePhone: string().required("Mobile number required"),
  // alternateAddress: string().matches(
  //   /^\d+$/,
  //   "Alternate address must only contain numbers"
  // ),
  license_state: string().required("Driver license state required"),
  email: string()
    .email()
    .matches(/@[^.]*\./, "Email not valid")
    .required("Email required"),
  driverLicense: string().required("Driver license required"),
  occupation: string().required("occupation required"),
  isIrsIpPinYesCheck: boolean(),
  isIrsIpPinNoCheck: boolean(),
  IRSPin: Yup.string().when('isIrsIpPinYesCheck', {
    is: true,
    then: () => Yup.string().required('IRS IP PIN is required when Yes is selected'),
    otherwise: () => Yup.string(),
  }),
  // birthCertificateDoc: mixed().required("Attachment required"),
  dlDoc: mixed().required("Attachment required"),
  // spouse: object().shape({
  //     firstName: string().default(""),
  //     // middleName: string().default(""),
  //     lastName: string().default(""),
  //     dob: date(),
  //     ssn: string().default(""),
  // })
  additionalNotes: Yup.string().nullable(),
});

export const retirementSchema = object().shape({
  tspWithdrawal: boolean().required("TSP withdrawal type required"),
  receiveSocialSecurityBenefits: boolean().default(false),
  cryptoSales: boolean().default(false),
  // withdrawalDoc: mixed().when("tspWithdrawal", {
  //   is: true,
  //   then: (schema) => schema.required("Attachment required"),
  // }),
  // socialSecurityBenefitDoc: mixed().when("receiveSocialSecurityBenefits", {
  //   is: true,
  //   then: (schema) => schema.required("Attachment required"),
  // }),
  // salesDoc: mixed().when("cryptoSales", {
  //   is: true,
  //   then: (schema) => schema.required("Attachment required"),
  // }),
});

export const healthCareSchema = object().shape({
  healthCareGov: boolean(),
  healthCareDoc: mixed().when("healthCareGov", {
    is: true,
    then: (schema) => schema.required("Attachment required"),
  }),
});

export const adjustmentSchema = object().shape({
  iraContributions: string().matches(
    /^\d+$/,
    "Ira Contributions must only contain numbers"
  ),
  teacherExpense: string().matches(
    /^\d+$/,
    "Teacher Expense  must only contain numbers"
  ),
  studentLoanInterest: string().matches(
    /^\d+$/,
    "Student Loan Interest must only contain numbers"
  ),
  estimatedTaxes: string().matches(
    /^\d+$/,
    "Estimated Taxes must only contain numbers"
  ),
  educationExpenseCheck: boolean(),
  // studentLoanDoc: mixed().when("educationExpenseCheck", {
  //     is: true,
  //     then: (schema) => schema.required("Attachment required")
  // }),

  allmoneyPaid: string().matches(
    /^\d+$/,
    "Almonry Paid must only contain numbers"
  ),
});

export const itemizeDeduction = Yup.object().shape({
  medical: Yup.object().shape({
    dentalExpense: Yup.string().matches(
      /^\d+$/,
      "Dental Expense must only contain numbers"
    ),
    prescriptionCost: Yup.string().matches(
      /^\d+$/,
      "Prescription Cost must only contain numbers"
    ),
    medicalExpense: Yup.string().matches(
      /^\d+$/,
      "Medical Expense must only contain numbers"
    ),
  }),
  taxesAndInterest: Yup.object().shape({
    interest: Yup.string().matches(
      /^\d+$/,
      "Interest must only contain numbers"
    ),
    realEstateTaxes: Yup.string().matches(
      /^\d+$/,
      "Real Estate Taxes must only contain numbers"
    ),
    boatMotorcycle: Yup.string().matches(
      /^\d+$/,
      "Boat MotorCycle must only contain numbers"
    ),
    pmi: Yup.string().matches(/^\d+$/, "PMI must only contain numbers"),
    autoTruckTax: Yup.string().matches(
      /^\d+$/,
      "Auto Truck Tax must only contain numbers"
    ),
  }),
  giftsToCharity: Yup.object().shape({
    cash: Yup.string().matches(/^\d+$/, "Cash must only contain numbers"),
    nonCash: Yup.string().matches(
      /^\d+$/,
      "Non Cash must only contain numbers"
    ),
  }),
  militaryExpense: Yup.object().shape({
    supplies: Yup.string().matches(
      /^\d+$/,
      "Supplies must only contain numbers"
    ),
    hotelExpense: Yup.string().matches(
      /^\d+$/,
      "Hotel Expense must only contain numbers"
    ),
    officeExpense: Yup.string().matches(
      /^\d+$/,
      "Office Expense must only contain numbers"
    ),
    travelExpense: Yup.string().matches(
      /^\d+$/,
      "Travel Expense must only contain numbers"
    ),
    mealAndEntertainment: Yup.string().matches(
      /^\d+$/,
      "Meal and Entertainment must only contain numbers"
    ),
    vehicleExpense: Yup.string().matches(
      /^\d+$/,
      "Vehicle Expense must only contain numbers"
    ),
    movingExpense: Yup.string().matches(
      /^\d+$/,
      "Moving Expense must only contain numbers"
    ),
    equipment: Yup.string().matches(
      /^\d+$/,
      "Equipment must only contain numbers"
    ),
    grooming: Yup.string().matches(
      /^\d+$/,
      "Grooming must only contain numbers"
    ),
    uniformsCleaners: Yup.string().matches(
      /^\d+$/,
      "Uniform Cleaners must only contain numbers"
    ),
    fees: Yup.string().matches(/^\d+$/, "Fees must only contain numbers"),
    milleageCommuting: Yup.string().matches(
      /^\d+$/,
      "Mileage Commuting must only contain numbers"
    ),
    business: Yup.string().matches(
      /^\d+$/,
      "Business must only contain numbers"
    ),
    personal: Yup.string().matches(
      /^\d+$/,
      "Personal must only contain numbers"
    ),
  }),
  miscellaneousDeduction: Yup.object().shape({
    priorYearTaxPreparationFees: Yup.string().matches(
      /^\d+$/,
      "Prior Year Tax Preparation Fees must only contain numbers"
    ),
    casualtyLoss: Yup.string().matches(
      /^\d+$/,
      "Casualty Loss must only contain numbers"
    ),
    legalFees: Yup.string().matches(
      /^\d+$/,
      "Legal Fees must only contain numbers"
    ),
    alimonyPaid: Yup.string().matches(
      /^\d+$/,
      "Alimony Paid must only contain numbers"
    ),
    AssocDues: Yup.string().matches(
      /^\d+$/,
      "Assoc Dues must only contain numbers"
    ),
    energyCreditExpense: Yup.string().matches(
      /^\d+$/,
      "Energy Credit Expense must only contain numbers"
    ),
  }),
  dayCare: Yup.array().of(
    Yup.object().shape({
      expense: Yup.string().matches(
        /^\d+$/,
        "Expense must only contain numbers"
      ),
      name: Yup.string(),
      taxId: Yup.string().when("expense", {
        is: (expense) => parseInt(expense) > 0,
        then: (schema) => schema.required("Tax# required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      city: Yup.string().when("expense", {
        is: (expense) => parseInt(expense) > 0,
        then: (schema) => schema.required("City required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      street: Yup.string().when("expense", {
        is: (expense) => parseInt(expense) > 0,
        then: (schema) => schema.required("Street required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      state: Yup.string().when("expense", {
        is: (expense) => parseInt(expense) > 0,
        then: (schema) => schema.required("State required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      zipCode: Yup.string().when("expense", {
        is: (expense) => parseInt(expense) > 0,
        then: (schema) => schema.required("Zip Code required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
  ),
});
