import { useState } from "react";
import { useFormik } from "formik";
import Logo from "assets/auth/Logo.png";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import {
  AlreadyAcc,
  FieldWrapper,
  FormLogo,
  FormSec,
  FormHtmlWrapper,
  StyledInput,
} from "./Signup.style";
import { Button } from "components/reusable/button/Button";
import { useNavigate } from "react-router-dom";
import EmailVerModal from "components/modals/EmailVerModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import VerifiedModal from "components/modals/VerifiedModal";
import { SyncLoader } from "react-spinners";
import { auth, storage, db } from "services/firebaseSetup";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";

const errorToast = (message = "") => toast.error(message);

const handleFirstLogin = () => {
  const isFirstLogin = localStorage.getItem("isFirstLogin");

  if (!isFirstLogin) {
    localStorage.setItem("isFirstLogin", "true");
  }
};

const SignupComp = ({ isLoading }) => {
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    // validationSchema: addCampaignSchema,
    onSubmit: async (values) => {
      try {
        if (values.password !== values.confirmPassword) {
          alert("Passwords do not match");
          return;
        }
        if (
          values.password === "" ||
          values.email === "" ||
          values.firstName === "" ||
          values.lastName === "" ||
          values.confirmPassword === "" ||
          values.phone === ""
        ) {
          alert("Please fill all the fields");
          return;
        }
        setSpinner(true);

        const res = await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
        const storageRef = ref(
          storage,
          `${values.firstName}_${values.lastName}`
        );
        const uploadTask = uploadBytesResumable(storageRef);
        uploadTask.on(
          "state_changed",
          (error) => {
            // Handle error
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (downloadURL) => {
                await updateProfile(res.user, {
                  displayName: `${values.firstName} ${values.lastName}`,
                });
              }
            );
          }
        );
        await setDoc(doc(db, "users", res.user.uid), {
          uid: res.user.uid,
          displayName: `${values.firstName} ${values.lastName}`,
          email: values.email,
          role: "",
        });
        const payload = {
          firstName: values.firstName,
          lastName: values.lastName,
          userName: `${values.firstName} ${values.lastName}`,
          email: values.email,
          phone: values.phone,
          password: values.password,
          fireBaseUid: res.user.uid,
        };
        await setDoc(doc(db, "usersChats", res.user.uid), {});
        const response = await apiReq(
          API_ROUTES.clientRegister,
          "POST",
          payload
        );

        if (response.status === 200) {
          const data = response.data;
          console.log(data);

          if (data.status === "200") {
            const token = data.data.token;
            alert("Registration successful");
            localStorage.setItem("token", token);
            setShowEmailVerificationModal(true);
          }
        } else if (response.status === 409) {
          errorToast(
            "User already exists. Please use a different email or username."
          );
        } else {
          console.error("Registration failed:", response.statusText);
        }

        handleFirstLogin();
      } catch (error) {
        errorToast("Error during registration. Email Already Exist.");
      } finally {
        setSpinner(false);
      }
    },
  });

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const navigate = useNavigate();

  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const handleOTPVerification = async (otp) => {
    try {
      const response = await apiReq(API_ROUTES.accountOTPVerify, "POST", {
        email: values.email,
        otp: otp,
      });

      if (response.status === 200) {
        setShowEmailVerificationModal(false);
        setShowVerificationModal(true);
        resetForm();
      } else {
        errorToast("OTP verification failed");
      }
    } catch (error) {
      errorToast("Error during OTP verification. Please try again later.");
    }
  };

  const onClose = () => {
    setShowEmailVerificationModal(false);
  };

  const onClose1 = () => {
    setShowVerificationModal(false);
  };

  return (
    <>
      <EmailVerModal
        open={showEmailVerificationModal}
        onVerify={handleOTPVerification}
        email={values.email}
        onClose={onClose}
      />
      <VerifiedModal open={showVerificationModal} onClose={onClose1} />
      <FormHtmlWrapper onSubmit={handleSubmit}>
        <FormSec gridGap="20px">
          <FormLogo>
            <img src={Logo} alt="Logo" />
          </FormLogo>
          <h5>Sign up</h5>
          <FieldWrapper>
            <p>First Name</p>
            <StyledInput
              className="emailComp"
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First name"
              value={values.firstName}
              onChange={(e) => setFieldValue("firstName", e.target.value)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <p>Last Name</p>
            <StyledInput
              className="emailComp"
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last name"
              value={values.lastName}
              onChange={(e) => setFieldValue("lastName", e.target.value)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <p>Email</p>
            <StyledInput
              className="emailComp"
              type="email"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <p>Phone Number</p>
            <PhoneInput
              className="emailComp"
              country={"us"}
              name="phone"
              value={values.phone}
              onChange={(phone) => setFieldValue("phone", phone)}
              style={{
                position: "absolute",
                top: "10px",
                width: "100%",
                background: "transparent",
              }}
            />
            <Line
              border="0.0005px solid rgba(194, 194, 194, 1)"
              marginTop="10px"
              color="white"
            />
          </FieldWrapper>
          <FieldWrapper>
            <p>Password</p>
            <StyledInput
              className="emailComp"
              type={showPass ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
            />
            <h5 onClick={() => setShowPass(!showPass)}>
              {showPass ? "HIDE" : "SHOW"}
            </h5>
          </FieldWrapper>
          <FieldWrapper>
            <p>Re-Type Password</p>
            <StyledInput
              className="emailComp"
              type={showConfirmPass ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm Password"
              value={values.confirmPassword}
              onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
            />
            <h5 onClick={() => setShowConfirmPass(!showConfirmPass)}>
              {showConfirmPass ? "HIDE" : "SHOW"}
            </h5>
          </FieldWrapper>
          <Button
            text={
              spinner ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Sign up"
            }
            width="100%"
            fontSize="18px"
            margin="10px 0px"
            disabled={spinner}
            type="submit"
          />
          <AlreadyAcc>
            <p>Have an account? </p>
            <h5 onClick={() => navigate("/")}> Log in</h5>
          </AlreadyAcc>
        </FormSec>
      </FormHtmlWrapper>
    </>
  );
};

export default SignupComp;
