import styled from "styled-components";

export const UploadLossContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: white;
  outline: none;
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  justify-content: space-between;
  border-radius: 10px;
`;

export const UploadLossHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  padding: 10px 15px;
  border-radius: 10px 10px 0px 0px;
`;

export const UploadLossBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  padding: 20px 15px;
`;

export const GrayBox = styled.div`
  width: 100%;
  background: #eff3fd82;
  border: 1px solid #0b2558;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
`;

export const UploadLossButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  width: 100%;
`;
