import React from 'react'
import { Line } from '../../../components/client/rentalProperty/RentalProperty.style'
import a from '../../../assets/client/a.png'
const PdfPageTwo = () => {
  return (
    <div style={{padding:'20px'}}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
            <p style={{fontSize:'14px',fontFamily:'inter'}}> Form 8879 (Rev. 01-2021)</p>
            <p style={{fontSize:'14px',fontFamily:'inter'}}>Page 2</p>
        </div>
        <Line/>

       <div style={{width:'100%' ,display:'flex',flexDirection:'row'}}>
<div style={{width:'33.3%'}}>
<p style={{fontSize:'22px' ,fontFamily:'inter'}}>General Instructions</p>
<p style={{fontSize:'14px' ,fontFamily:'inter'}}>Section references are to the Internal Revenue Code unless otherwise noted.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}><span style={{fontWeight:'bold'}}>Future developments. </span>For the latest information about developments related to Form 8879 and its instructions, such as legislation enacted after they were published, go to www.irs.gov/Form8879. </p>
<p style={{fontSize:'14px',fontFamily:'inter'}}><span style={{fontWeight:'bold'}}>What's New.</span> Form 8879 is used to authorize the electronic filing (e-file) of original and amended returns. Use this Form 8879 (Rev. January 2021) to authorize e-file of your Form 1040, 1040-SR, 1040-NR, 1040-SS, or 1040-X, for tax years beginning with 2019.</p>
<p style={{fontSize:'16px',fontFamily:'inter'}}>Purpose of Form</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>Form 8879 is the declaration document and signature authorization for an e-filed return filed by an electronic return originator (ERO). Complete Form 8879 when the Practitioner PIN method is used or when the taxpayer authorizes the ERO to enter or generate the taxpayer’s personal identification number (PIN) on his or her e-filed individual income tax return</p>
<div style={{display:'flex',textAlign:'center',fontFamily:'inter'}}>
 <div style={{width:'81px',height:'81px',background:'black',color:"white"}}> <img src={a}/><p style={{fontSize:'14px',fontFamily:'inter'}}>Caution</p></div>
 <div  style={{paddingTop:'20px'}}>

<p style={{fontSize:'14px',fontFamily:'inter'}}>Don’t send this form to the IRS.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>The ERO must retain Form 8879. </p>
</div>
</div>

<p style={{fontSize:'16px',fontFamily:'inter'}}>When and How To Complete</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>Use this chart to determine when and how to complete Form 8879. </p>
<div style={{fontSize:'14px',width:'100%'}}>
    <div style={{display:'flex'}}>
        <div style={{fontSize:'14px', borderTop:'1px  solid black',  borderRight:'1px  solid black',width:'162px',fontFamily:'inter'}}>IF the ERO is . . .</div>
        <div style={{fontSize:'14px', borderTop:'1px  solid black',paddingLeft:'40px',fontFamily:'inter'}}>IF the ERO is . . .</div>
   
    </div>
    <div style={{display:'flex'}}>
        <div style={{fontSize:'14px', borderTop:'1px  solid black', borderRight:'1px  solid black',width:'182px',fontFamily:'inter'}}>Not using the Practitioner  PIN method and the  taxpayer enters his or her own PIN</div>
        <div style={{fontSize:'14px', borderTop:'1px  solid black',paddingLeft:'5px',fontFamily:'inter'}}>Don’t complete  Form 8879.</div>
   
    </div>
    <div style={{display:'flex'}}>
        <div style={{fontSize:'14px', borderTop:'1px  solid black', borderRight:'1px  solid black',width:'232px',fontFamily:'inter'}}>Not using the Practitioner  PIN method and is  authorized to enter or generate the taxpayer’s  PIN</div>
        <div style={{fontSize:'14px', borderTop:'1px  solid black',paddingLeft:'5px',fontFamily:'inter'}}>Complete Form 8879, Parts I and II</div>
   
    </div>
    <div style={{display:'flex'}}>
        <div style={{fontSize:'14px', borderTop:'1px  solid black', borderRight:'1px  solid black',width:'252px',fontFamily:'inter'}}>Using the Practitioner PIN method and is authorized  to enter or generate the taxpayer’s PIN</div>
        <div style={{fontSize:'14px', borderTop:'1px  solid black',paddingLeft:'5px',fontFamily:'inter'}}>Complete Form 8879, Parts I, II, and III</div>
   
    </div>
    <div style={{display:'flex'}}>
        <div style={{fontSize:'14px', borderTop:'1px  solid black', borderRight:'1px  solid black',width:'252px',fontFamily:'inter'}}>Using the Practitioner PIN method and the taxpayer enters his or her own PIN</div>
        <div style={{fontSize:'14px', borderTop:'1px  solid black',paddingLeft:'5px',fontFamily:'inter'}}>Complete Form 8879, Parts I, II, and II</div>
   
    </div>
</div>



<p style={{fontSize:'14px',fontFamily:'inter'}}>ERO Responsibilities</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>The ERO must:</p>

<p style={{fontSize:'14px',fontFamily:'inter'}}>1 Enter the name(s) and social security number(s) of the taxpayer(s) at the top of the form.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>2. Complete Part I using the amounts (zeros may be entered when appropriate) from the taxpayer’s tax return. Form 1040-SS filers leave lines 1 through 3 and line 5 blank</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>3. Enter or generate, if authorized by the taxpayer, the taxpayer’s PIN and enter it in the boxes provided in Part II.</p>
</div>
       
<div style={{width:'33.3%',paddingLeft:'10px'}}>

<p style={{fontSize:'14px',fontFamily:'inter'}}>4. Enter on the authorization line in Part II the ERO firm name (not the name of the individual preparing the return) if the ERO is authorized to enter the taxpayer’s PIN.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>5. Provide the taxpayer(s) Form 8879 by hand delivery, U.S. mail, private delivery service, email, Internet website, or fax</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>6. Enter the 20-digit Submission Identification Number (SID) assigned to the tax return, or associate Form 9325, Acknowledgement and General Information for Taxpayers Who File Returns Electronically, with Form 8879 after filing. If Form 9325 is used to provide the SID, it isn’t required to be physically attached to Form 8879. However, it must be kept in accordance with published retention requirements for Form 8879. See Pub. 4164, Modernized e-File (MeF) Guide for Software Developers and Transmitters, for more details.</p>

<div style={{display:'flex',textAlign:'center'}}>
 <div style={{width:'81px',height:'81px',background:'black',color:"white"}}> <img src={a}/><p style={{fontSize:'14px',fontFamily:'inter'}}>Caution</p></div>
 <div  style={{paddingTop:'5px'}}>

<p style={{fontSize:'14px',fontFamily:'inter'}}>You must receive the completed and signed Form 8879 from the taxpayer before the electronic return is transmitted (or released for transmission).For additional information, see Pub. 1345. </p>

</div>
</div>
<p style={{fontSize:'16px',fontFamily:'inter'}}>Taxpayer Responsibilities</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>Taxpayers must:</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}> 1. Verify the accuracy of the prepared income tax return, including direct deposit information.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>2. Check the appropriate box in Part II to authorize the ERO to enter or generate your PIN or to do it yourself.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>3. Indicate or verify your PIN when authorizing the ERO to enter or generate it (the PIN must be five digits other than all zeros</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>4. Sign and date Form 8879. Taxpayers must sign Form 8879 by handwritten signature, or electronic signature if supported by computer software</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>5. Return the completed Form 8879 to the ERO by hand delivery, U.S. mail, private delivery service, email, Internet website, or fax.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>Your return won’t be transmitted to the IRS until the ERO receives your signed Form 8879</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}><span  style={{fontWeight:'bold'}}>Refund information.</span> You can check on the status of your refund if it has been at least 72 hours since the IRS acknowledged receipt of your e-filed return. But if you filed Form 8379 with your return, allow 11 weeks. To check the status of your refund, do one of the following.</p>

<p style={{fontSize:'14px',fontFamily:'inter'}}>• Go to www.irs.gov/Refunds.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Call 1-800-829-4477 for automated refund information and follow the recorded instructions</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Call 1-800-829-1954</p>
</div>

<div style={{width:'33.3%',paddingLeft:'10px'}}>

<p style={{fontSize:'16px',fontFamily:'inter'}}>Important Notes for EROs</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Don’t send Form 8879 to the IRS unless requested to do so. Retain the completed Form 8879 for 3 years from the return due date or IRS received date, whichever is later. Form 8879 may be retained electronically in accordance with the recordkeeping guidelines in Rev. Proc. 97-22, which is on page 9 of Internal Revenue Bulletin 1997-13 at www.irs.gov/pub/irs-irbs/irb97-13.pdf.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Confirm the identity of the taxpayer(s)</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Complete Part III only if you are filing the return using the Practitioner PIN method. You aren’t required to enter the taxpayer’s date of birth, prior year adjusted gross income, or PIN in the Authentication Record of the electronically filed return</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• If you aren’t using the Practitioner PIN method, enter the taxpayer(s) date of birth and either the adjusted gross income or the PIN, or both, from the taxpayer’s prior year originally filed return in the Authentication Record of the taxpayer’s electronically filed return. Don’t use an amount from an amended return or a math error correction made by the IRS</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Enter the taxpayer’s PIN(s) on the input screen only if the taxpayer has authorized you to do so. If married filing jointly, it is acceptable for one spouse to authorize you to enter his or her PIN, and for the other spouse to enter his or her own PIN. It isn’t acceptable for a taxpayer to select or enter the PIN of an absent spouse</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Taxpayers must use a PIN to sign their       e-filed individual income tax return transmitted by an ERO</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Provide the taxpayer with a copy of the signed Form 8879 for his or her records upon request.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Provide the taxpayer with a corrected copy of Form 8879 if changes are made to the return (for example, based on taxpayer review)</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• EROs can sign the form using a rubber stamp, mechanical device (such as a signature pen), or computer software program. See Notice 2007-79, 2007-42 I.R.B. 809, available at www.irs.gov/irb/2007-42_IRB/ar10.html, for more information.</p>
<p style={{fontSize:'14px',fontFamily:'inter'}}>• Go to www.irs.gov/Efile for the latest information. </p>

</div>



</div>
    </div>
  )
}

export default PdfPageTwo