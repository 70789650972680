import { Grid, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  GrayBox,
  UploadLossBody,
  UploadLossContainer,
  UploadLossHeader,
} from "./UploadAndLoss.style";
import CloseIcon from "@mui/icons-material/Close";
import { Span } from "../reusable/typography/Typography.style";
import { Box, Stack, borderRight } from "@mui/system";
import FlexComp from "components/reusable/flex/Flex";
import {
  ContractWrapper,
  PayRollWrapper,
} from "components/client/businessCollection/BusinessCollection.style";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { ButtonIcon } from "components/reusable/button/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Input as FileInput } from "components/layout/navbar/Navbar.style";
import { Button } from "components/reusable/button/Button";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";

// truck driver arrays
const basicInfo = [
  {
    section: "Basic Info",
    fields: ["First name", "Middle name", "Last name", "EIN", "Tax year"],
  },
  {
    section: "Address",
    fields: [
      "Street",
      "City",
      "State",
      "Zip Code",
      "Date of Birth",
      "Email",
      "Mobile number",
      "Filing Status",
    ],
  },
  {
    section: "Business Address",
    fields: ["Street", "City", "State", "Zip Code"],
  },
];

const administrativeFees = [
  "Association Fees",
  "ATM Charges",
  "Bank Fees",
  "DOT Exam Fees",
  "Comcard Fees",
  "IRA Custodial/Investment Fees",
  "Licensing Fees",
  "Safe Deposit Box Fees",
  "Administrative Fees",
  "Wire Fees",
];

const admistrateCol1 = administrativeFees.slice(0, 4);
const admistrateCol2 = administrativeFees.slice(4, 7);
const admistrateCol3 = administrativeFees.slice(7, 10);

const columns = [admistrateCol1, admistrateCol2, admistrateCol3];

const Maintenance = [
  "Parts",
  "Repairs",
  "Tires",
  "Truck Washes",
  "Other Maintenance",
];
const maintenanceCol1 = Maintenance.slice(0, 2);
const maintenanceCol2 = Maintenance.slice(2, 4);
const maintenanceCol3 = Maintenance.slice(4, 5);

const MaintenanceColumns = [maintenanceCol1, maintenanceCol2, maintenanceCol3];

const LaborsAndTaxes = [
  "Subcontractor",
  "Lumpers",
  "Ad Valorem",
  "Fuel Tax",
  "Heavy Use Tax",
  "License Tax",
  "Other Tax",
  "Permits",
  "Registration",
];
const LaborCol1 = LaborsAndTaxes.slice(0, 3);
const LaborCol2 = LaborsAndTaxes.slice(3, 6);
const LaborCol3 = LaborsAndTaxes.slice(6, 9);

const LabourColumns = [LaborCol1, LaborCol2, LaborCol3];

const InsuranceAndInterest = [
  "Bobtail Insurance",
  "PI & PD Insurance",
  "Truck Insurance",
  "Workman’s Compensation",
  "ACC/CC Insurance",
  "Other Insurance",
  "Interest on Advances",
  "Interest on Credit Cards Bus",
  "Interest on Truck Loans",
  "Other Interest",
];
const insuranceCol1 = InsuranceAndInterest.slice(0, 3);
const insuranceCol2 = InsuranceAndInterest.slice(3, 6);
const insuranceCol3 = InsuranceAndInterest.slice(6, 9);

const insuranceColumns = [insuranceCol1, insuranceCol2, insuranceCol3];

const RoadExpences = [
  "Hotels",
  "Laundry",
  "Parking",
  "Showers",
  "Tolls",
  "Truck Supplies",
  "Weigh Fees/Scales",
  "Prepass",
  "Meals",
  "Rental Car",
  "Airfare",
  "No. of Days in the Roads",
];
const roadExpenseCol1 = RoadExpences.slice(0, 4);
const roadExpenseCol2 = RoadExpences.slice(4, 8);
const roadExpenseCol3 = RoadExpences.slice(8, 12);

const roadExpensesColumns = [roadExpenseCol1, roadExpenseCol2, roadExpenseCol3];
const FuelInfo = [
  {
    section: "Professional Fees",
    fields: ["Attorney and Accounting", "Tax Prep Fees"],
  },
  {
    section: "Fuel and Oil",
    fields: ["Fuel", "Oil", "Reefer Fuel"],
  },
  {
    section: "Office Expenses",
    fields: ["Office Expense"],
  },
];

const rentLeaseInfo = ["Truck Lease", "Storage Lease", "Other"];

// rental property arrays
const rentalPropertyInfo = [
  {
    section: "Address",
    fields: [
      "Street",
      "City",
      "State",
      "Zip Code",
      "Date in Service",
      "Fair Market Value",
      "Rent Charge",
      "Purchase Price",
      "Fair Rental Days",
      "Property Type (Sch E)",
    ],
  },
  {
    section: "Select Month & Year",
    fields: ["Select Month", "Year"],
  },
  {
    section: "Income ($0)",
    fields: ["Rental Income", "Deposit"],
  },
  {
    section: "Expenses ($0)",
    fields: [
      "Management Fees",
      "Advertising For Tenants",
      "Cleaning & Maintenance",
      "Repairs & Maintenance",
      "Property Tax",
      "Utilities",
      "Misc. Fees",
      "Late Fees",
      "Association Dues (HOA)",
      "Insurance",
      "Water Charges",
    ],
  },
];

// genral Business array
const BusinessBasicInfo = [
  "Entity Info",
  "First Name",
  "Middle Name",
  "Last Name",
  "Mobile number",
  "Business Name",
  "Business Description Products/Services",
  "EIN",
  "Street",
  "City",
  "State",
  "Zip Code",
  "Business Start Date",
  "Incorporation State",
];

const businessBasicCol1 = BusinessBasicInfo.slice(0, 4);
const businessBasicCol2 = BusinessBasicInfo.slice(4, 8);
const businessBasicCol3 = BusinessBasicInfo.slice(8, 12);

const businessBasicColumns = [
  businessBasicCol1,
  businessBasicCol2,
  businessBasicCol3,
];

const errorToast = (message = "") => toast.error(message);
const ConfirmationModal = ({
  modalHeading,
  isConfirmationModal,
  setIsConfirmationModal,
  ConfirmAtionData,
  setIsUploadModal,
  payroll,
  setPayroll,
  contract,
  setContract,
  fileName,
  setFileName,
  fileData,
  setFileData,
  buttonText = "Upload Education Document",
  isUploadModal,
  setIsAdditionalModal
}) => {
  const payrollDocRef = useRef(null);
  const contractDocRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const { pathname } = useLocation();
  const [clientId] = useState(localStorage.getItem("clientId"));
  const [isChecked, setIsChecked] = useState(false);

  const handleUploadButtonClick = (e, type) => {
    e.preventDefault();
    type === "payRollDoc" && payrollDocRef.current.click();
    type === "contractDoc" && contractDocRef.current.click();
  };

  const handleFileUpload = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/upload-endpoint", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  console.log(fileName, "fileName");

  const handleGrayBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const Submit = async () => {
    try {
      if (!file) {
        errorToast("Please uplaod File.");
        return;
      } else if (file && file.type !== "text/csv") {
        errorToast("Please uplaod valid CSV File.");
        return;
      }

      // Prepare FormData
      const formData = new FormData();
      // Append clientId if available
      if (clientId) {
        formData.append("clientId", clientId);
      }
      const URL =
        pathname === "/general-business"
          ? API_ROUTES.uploadGeneralBusiness
          : pathname === "/rental-property"
          ? API_ROUTES.uploadRentalPropertyDoc
          : API_ROUTES.uploadTruckDriverDoc;
      formData.append("generalBusinessDoc", file);
      if (ConfirmAtionData == "General") {
        formData.append("payroll", payroll);
        formData.append("contract", contract);
        formData.append("payRollDoc", fileData?.payRollDoc);
        formData.append("contractDoc", fileData?.contractDoc);
      }
      const res = await apiReq(URL, "POST", formData, API_BASE_URL, false);
      console.log(res, "ressssss");
      if (pathname === "/general-business") {
        localStorage.setItem("formsId", res?.data?.data[0]?.generalBusiness?._id);
      } else if (pathname === "/rental-property") {
        localStorage.setItem("formsId", res?.data?.data?.rentalPropertyMain?._id);
      } else {
        localStorage.setItem("formsId", res?.data?.data?.truckDriver?._id);
      }

      setIsAdditionalModal(true)
    } catch (error) {
      errorToast(
        error?.message || error?.response?.message || "Error while parsing CSV"
      );
    } finally {
      setFile(null);
      setIsConfirmationModal(false);
    }
  };
  console.log(fileData, "FIleData");
  return (
    <>
      <Modal
        open={isConfirmationModal}
        onClose={() => {
          setIsConfirmationModal(!isConfirmationModal);
          setFileData({
            payRollDoc: null,
            contractDoc: null,
          });
          setPayroll(false);
          setFileName({
            payRollDoc: "",
            contractDoc: "",
          });
          setContract(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UploadLossContainer style={{ width: "800px" }}>
          <UploadLossHeader>
            <Span color="#FFFFFF" fontSize="16px" fontWeight="500">
              {modalHeading}
            </Span>
            <CloseIcon
              sx={{ color: "#FFFFFF", cursor: "pointer" }}
              onClick={() => {
                setIsConfirmationModal(!isConfirmationModal);
                setFileData({
                  payRollDoc: null,
                  contractDoc: null,
                });
                setPayroll(false);
                setFileName({
                  payRollDoc: "",
                  contractDoc: "",
                });
                setContract(false);
              }}
            />
          </UploadLossHeader>
          <UploadLossBody style={{ height: "80vh", overflowY: "auto" }}>
            {/* data for truck driver  */}
            {ConfirmAtionData == "Truck" && (
              <div>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Basic Info Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {basicInfo.map((item, i) => {
                      return (
                        <>
                          <div
                            style={{
                              borderRight:
                                i === basicInfo.length - 1
                                  ? "1px solid transparent"
                                  : "1px solid #8694974d",
                              width: "100%",
                            }}
                          >
                            <Span color="#000" fontSize="16px" fontWeight="500">
                              {item.section}
                            </Span>
                            {item.fields.map((item) => {
                              return (
                                <>
                                  <FlexComp
                                    alignItems="center"
                                    gridGap="10px"
                                    marginTop="8px"
                                  >
                                    <Box
                                      sx={{
                                        width: "11px",
                                        height: "4px",
                                        background: "#0B2558",
                                      }}
                                    ></Box>
                                    <Span
                                      color="#000"
                                      fontSize="16px"
                                      fontWeight="400"
                                    >
                                      {item}{" "}
                                    </Span>
                                  </FlexComp>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </FlexComp>
                </Box>

                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Administrative Fees Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {columns.map((column, colIndex) => (
                      <div
                        key={colIndex}
                        style={{
                          borderRight:
                            colIndex === columns.length - 1
                              ? "1px solid transparent"
                              : "1px solid #8694974d",
                          width: "100%",
                        }}
                      >
                        {column.map((item, itemIndex) => (
                          <FlexComp
                            key={itemIndex}
                            alignItems="center"
                            style={{ marginBottom: "10px" }}
                          >
                            <Box
                              sx={{
                                width: "11px",
                                height: "4px",
                                background: "#0B2558",
                                marginRight: "10px",
                              }}
                            ></Box>
                            <Span color="#000" fontSize="16px" fontWeight="400">
                              {item}
                            </Span>
                          </FlexComp>
                        ))}
                      </div>
                    ))}
                  </FlexComp>
                </Box>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Maintenance Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {MaintenanceColumns.map((column, colIndex) => (
                      <div
                        key={colIndex}
                        style={{
                          borderRight:
                            colIndex === columns.length - 1
                              ? "1px solid transparent"
                              : "1px solid #8694974d",
                          width: "100%",
                        }}
                      >
                        {column.map((item, itemIndex) => (
                          <FlexComp
                            key={itemIndex}
                            alignItems="center"
                            style={{ marginBottom: "10px" }}
                          >
                            <Box
                              sx={{
                                width: "11px",
                                height: "4px",
                                background: "#0B2558",
                                marginRight: "10px",
                              }}
                            ></Box>
                            <Span color="#000" fontSize="16px" fontWeight="400">
                              {item}
                            </Span>
                          </FlexComp>
                        ))}
                      </div>
                    ))}
                  </FlexComp>
                </Box>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Labors and Taxes Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {LabourColumns.map((column, colIndex) => (
                      <div
                        key={colIndex}
                        style={{
                          borderRight:
                            colIndex === columns.length - 1
                              ? "1px solid transparent"
                              : "1px solid #8694974d",
                          width: "100%",
                        }}
                      >
                        {column.map((item, itemIndex) => (
                          <FlexComp
                            key={itemIndex}
                            alignItems="center"
                            style={{ marginBottom: "10px" }}
                          >
                            <Box
                              sx={{
                                width: "11px",
                                height: "4px",
                                background: "#0B2558",
                                marginRight: "10px",
                              }}
                            ></Box>
                            <Span color="#000" fontSize="16px" fontWeight="400">
                              {item}
                            </Span>
                          </FlexComp>
                        ))}
                      </div>
                    ))}
                  </FlexComp>
                </Box>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Insurance and Interests Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {insuranceColumns.map((column, colIndex) => (
                      <div
                        key={colIndex}
                        style={{
                          borderRight:
                            colIndex === columns.length - 1
                              ? "1px solid transparent"
                              : "1px solid #8694974d",
                          width: "100%",
                        }}
                      >
                        {column.map((item, itemIndex) => (
                          <FlexComp
                            key={itemIndex}
                            alignItems="center"
                            style={{ marginBottom: "10px" }}
                          >
                            <Box
                              sx={{
                                width: "11px",
                                height: "4px",
                                background: "#0B2558",
                                marginRight: "10px",
                              }}
                            ></Box>
                            <Span color="#000" fontSize="16px" fontWeight="400">
                              {item}
                            </Span>
                          </FlexComp>
                        ))}
                      </div>
                    ))}
                  </FlexComp>
                </Box>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Road Expenses Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {roadExpensesColumns.map((column, colIndex) => (
                      <div
                        key={colIndex}
                        style={{
                          borderRight:
                            colIndex === columns.length - 1
                              ? "1px solid transparent"
                              : "1px solid #8694974d",
                          width: "100%",
                        }}
                      >
                        {column.map((item, itemIndex) => (
                          <FlexComp
                            key={itemIndex}
                            alignItems="center"
                            style={{ marginBottom: "10px" }}
                          >
                            <Box
                              sx={{
                                width: "11px",
                                height: "4px",
                                background: "#0B2558",
                                marginRight: "10px",
                              }}
                            ></Box>
                            <Span color="#000" fontSize="16px" fontWeight="400">
                              {item}
                            </Span>
                          </FlexComp>
                        ))}
                      </div>
                    ))}
                  </FlexComp>
                </Box>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Professional Fees, Fuel and OfficeExpense Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {FuelInfo.map((item, i) => {
                      return (
                        <>
                          <div
                            style={{
                              borderRight:
                                i === basicInfo.length - 1
                                  ? "1px solid transparent"
                                  : "1px solid #8694974d",
                              width: "100%",
                            }}
                          >
                            <Span color="#000" fontSize="16px" fontWeight="500">
                              {item.section}
                            </Span>
                            {item.fields.map((item) => {
                              return (
                                <>
                                  <FlexComp
                                    alignItems="center"
                                    gridGap="10px"
                                    marginTop="8px"
                                  >
                                    <Box
                                      sx={{
                                        width: "11px",
                                        height: "4px",
                                        background: "#0B2558",
                                      }}
                                    ></Box>
                                    <Span
                                      color="#000"
                                      fontSize="16px"
                                      fontWeight="400"
                                    >
                                      {item}{" "}
                                    </Span>
                                  </FlexComp>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </FlexComp>
                </Box>

                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Rent and lease Mandatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="20px"
                  >
                    {rentLeaseInfo.map((item, index) => (
                      <FlexComp
                        key={index}
                        alignItems="center"
                        style={{
                          marginBottom: "10px",
                          borderRight: "1px solid #8694974d",
                        }}
                      >
                        <Box
                          sx={{
                            width: "11px",
                            height: "4px",
                            background: "#0B2558",
                            marginRight: "10px",
                          }}
                        ></Box>
                        <Span color="#000" fontSize="16px" fontWeight="400">
                          {item}
                        </Span>
                      </FlexComp>
                    ))}
                  </FlexComp>
                </Box>
              </div>
            )}
            {/* data for rental property  */}
            {ConfirmAtionData == "Rental" && (
              <div>
                <Span
                  color="#000"
                  fontSize="22px"
                  fontWeight="500"
                  style={{ margin: "15px 0", display: "block" }}
                >
                  Rental Property Mandoatory Fields
                </Span>
                <Box
                  sx={{
                    border: "1px solid #8694974d",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <FlexComp
                    direction="row"
                    justifyContent="space-between"
                    gridGap="40px 20px "
                    flexWrap="wrap"
                  >
                    {rentalPropertyInfo.map((item, i) => {
                      return (
                        <>
                          <div
                            style={{
                              borderRight:
                                i === 0 || i === 2
                                  ? "1px solid #8694974d"
                                  : "1px solid transparent",
                              width: "48%",
                            }}
                          >
                            <Span color="#000" fontSize="16px" fontWeight="500">
                              {item.section}
                            </Span>
                            {item.fields.map((item) => {
                              return (
                                <>
                                  <FlexComp
                                    alignItems="center"
                                    gridGap="10px"
                                    marginTop="8px"
                                  >
                                    <Box
                                      sx={{
                                        width: "11px",
                                        height: "4px",
                                        background: "#0B2558",
                                      }}
                                    ></Box>
                                    <Span
                                      color="#000"
                                      fontSize="16px"
                                      fontWeight="400"
                                    >
                                      {item}{" "}
                                    </Span>
                                  </FlexComp>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </FlexComp>
                </Box>
              </div>
            )}
            {/* data for genral Business  */}
            {ConfirmAtionData == "General" && (
              <>
                <div style={{ width: "100%" }}>
                  <Span
                    color="#000"
                    fontSize="22px"
                    fontWeight="500"
                    style={{ margin: "15px 0", display: "block" }}
                  >
                    Baisc Info Mandoatory Fields
                  </Span>
                  <Box
                    sx={{
                      border: "1px solid #8694974d",
                      borderRadius: "10px",
                      padding: "20px",
                      width: "100%",
                    }}
                  >
                    <FlexComp
                      direction="row"
                      justifyContent="space-between"
                      gridGap="20px"
                    >
                      {businessBasicColumns.map((column, colIndex) => (
                        <div
                          key={colIndex}
                          style={{
                            borderRight:
                              colIndex === columns.length - 1
                                ? "1px solid transparent"
                                : "1px solid #8694974d",
                            width: "100%",
                          }}
                        >
                          {column.map((item, itemIndex) => (
                            <FlexComp
                              key={itemIndex}
                              alignItems="center"
                              style={{ marginBottom: "10px" }}
                            >
                              <Box
                                sx={{
                                  width: "11px",
                                  height: "4px",
                                  background: "#0B2558",
                                  marginRight: "10px",
                                }}
                              ></Box>
                              <Span
                                color="#000"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                {item}
                              </Span>
                            </FlexComp>
                          ))}
                        </div>
                      ))}
                    </FlexComp>
                  </Box>
                  <Span
                    color="#000"
                    fontSize="22px"
                    fontWeight="500"
                    style={{ margin: "15px 0", display: "block" }}
                  >
                    Expenses Mandoatory Fields
                  </Span>
                  <Box
                    sx={{
                      border: "1px solid #8694974d",
                      borderRadius: "10px",
                      padding: "20px",
                      width: "100%",
                    }}
                  >
                    <FlexComp
                      direction="row"
                      justifyContent="space-between"
                      gridGap="20px"
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <FlexComp
                          alignItems="center"
                          style={{ marginBottom: "10px" }}
                        >
                          <Box
                            sx={{
                              width: "11px",
                              height: "4px",
                              background: "#0B2558",
                              marginRight: "10px",
                            }}
                          ></Box>
                          <Span color="#000" fontSize="16px" fontWeight="400">
                            Gross Reciepts For sale
                          </Span>
                        </FlexComp>
                      </div>
                    </FlexComp>
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <PayRollWrapper>
                    <Span color="#000000" fontSize="16px" fontWeight="500">
                      Payroll
                    </Span>
                    <TextCheckbox
                      name="payroll"
                      text="Yes"
                      gridGap="1px"
                      isChecked={payroll === true}
                      onChange={() => {
                        setPayroll((prev) => !prev);
                      }}
                    />
                    <TextCheckbox
                      name="payroll"
                      text="No"
                      gridGap="1px"
                      isChecked={payroll === false}
                      onChange={() => {
                        setPayroll((prev) => !prev);
                        setFileName({ ...fileName, payRollDoc: "" });
                      }}
                    />

                    {payroll === true && (
                      <Stack direction="row" gap={5}>
                        <ButtonIcon
                          width="100%"
                          icon={<CloudUploadIcon />}
                          fontSize="12px"
                          text={
                            fileName.payRollDoc || "Upload Required Documents"
                          }
                          clickHandler={(e) =>
                            handleUploadButtonClick(e, "payRollDoc")
                          }
                        />
                        <FileInput
                          type="file"
                          id="payRollDoc"
                          style={{ display: "none" }}
                          ref={payrollDocRef}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFileName({
                              ...fileName,
                              payRollDoc: file?.name,
                            });
                            setFileData({ ...fileData, payRollDoc: file });
                            handleFileUpload(file, "payRollDoc");
                          }}
                        />
                      </Stack>
                    )}
                  </PayRollWrapper>

                  <ContractWrapper>
                    <Span color="#000000" fontSize="16px" fontWeight="500">
                      Contract
                    </Span>
                    <TextCheckbox
                      name="contract"
                      text="Yes"
                      gridGap="10px"
                      isChecked={contract === true}
                      onChange={() => {
                        setContract((prev) => !prev);
                      }}
                    />
                    <TextCheckbox
                      name="contract"
                      text="No"
                      gridGap="10px"
                      isChecked={contract === false}
                      onChange={() => {
                        setContract((prev) => !prev);
                        setFileName({ ...fileName, contractDoc: "" });
                      }}
                    />

                    {contract === true && (
                      <Stack direction="row" gap={5}>
                        <ButtonIcon
                          width="100%"
                          icon={<CloudUploadIcon />}
                          text={
                            fileName.contractDoc || "Upload Required Documents"
                          }
                          fontSize="12px"
                          clickHandler={(e) =>
                            handleUploadButtonClick(e, "contractDoc")
                          }
                        />
                        <FileInput
                          type="file"
                          id="contractDoc"
                          style={{ display: "none" }}
                          ref={contractDocRef}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFileName({
                              ...fileName,
                              contractDoc: file?.name,
                            });
                            setFileData({ ...fileData, contractDoc: file });
                            handleFileUpload(file, "contractDoc");
                          }}
                        />
                      </Stack>
                    )}
                  </ContractWrapper>
                </div>
              </>
            )}

            <GrayBox onClick={handleGrayBoxClick}>
              <Span fontSize="16px" fontWeight="400">
                You can choose file from folder or you can drag & drop file.
              </Span>
              <ButtonIcon
                width="50%"
                icon={<CloudUploadIcon />}
                text={!file ? buttonText : file?.name}
                fontSize="14px"
              />
              <input
                type="file"
                accept=".xls,.csv"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />

              <Span fontSize="16px" fontWeight="400">
                Format: only CSV supported.
              </Span>
            </GrayBox>
            <div style={{ width: "100%" }}>
              <TextCheckbox
                text="Please make sure that you have filled all the mandatory fields"
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
              />
              {isChecked && (
                <FlexComp alignItems="center" gridGap="20px">
                  <Button
                    text="Submit"
                    width="20%"
                    fontSize="20px"
                    padding="10px 40px"
                    backgroundColor="#00ADEF"
                    onClick={Submit}
                  />
                </FlexComp>
              )}
            </div>
          </UploadLossBody>
        </UploadLossContainer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
