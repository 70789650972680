import React from "react";
import PdfUploadButton from "components/reusable/button/PdfUploadButton";
import { GeneralBusinessBtnWrapper } from "./UploadTaxDocs.style";

const GeneralBusiness = ({ data }) => {
  const transformData = (data) => {
    return data
      .filter((item) => item.businessInfoId)
      .flatMap((item) => {
        const docs = [];
        if (item.businessInfoId.payRollDoc) {
          docs.push({
            name: item.businessInfoId.payRollDocFileName || "payRollDoc",
            url: item.businessInfoId.payRollDoc,
          });
        }
        if (item.businessInfoId.contractDoc) {
          docs.push({
            name: item.businessInfoId.contractDocFileName || "contractDoc",
            url: item.businessInfoId.contractDoc,
          });
        }
        return docs;
      });
  };

  const documents = transformData(data);
  console.log(documents, "documents");

  return (
    <GeneralBusinessBtnWrapper>
      <PdfUploadButton doc="businessDoc" documents={documents} />
    </GeneralBusinessBtnWrapper>
  );
};

export default GeneralBusiness;
