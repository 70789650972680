import styled from "styled-components";
import ChangePassword from "components/staff/settings/ChangePassword";

const StaffChangePassword = () => {
  return (
    <ChangePasswordContainer>
      <ChangePassword />
    </ChangePasswordContainer>
  );
};

export default StaffChangePassword;

export const ChangePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 40px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;
