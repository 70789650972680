import { useState, useEffect, useRef } from "react";
import CustomTable from "components/reusable/customTable/CustomTable";
import styled from "styled-components";
import TitleComponent from "components/reusable/titleComponent/TitleComponent";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { IconStyledButton } from "components/reusable/button/Button.style";
import jsPDF from "jspdf";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { useReactToPrint } from "react-to-print";
import GeneralBusiness from "components/modals/review/ReviewConfirmation/GeneralBusiness";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import ReviewFormPreview from "components/modals/RentalFormPreview";
import TruckDriver from "components/modals/review/ReviewConfirmation/TruckDriver";

const formattedDate = (value) => {
  if (value) {
    const date = dayjs(value);
    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  }
};

const TaxDocsList = () => {
  const [review, setReview] = useState([]);
  const [tab_loader, setTabLoader] = useState(true);
  const [generalBusinessData, setGeneralBusinessData] = useState();
  const [modalData, setModalData] = useState();
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [generalBusinessID, setgeneralBusinessID] = useState();
  const [rentalPropertyID, setrentalPropertyID] = useState();
  const [personalCollactionID, setpersonalCollactionID] = useState();
  const [openPrevModal, setOpenPrevModal] = useState(false);
  const [rentalPropertyInfo, setrentalPropertyInfo] = useState({});
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [truckdriverID, settruckdriverID] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoader(true);
        const response = await apiReq(API_ROUTES.downloadDoc, "POST", {
          clientId: localStorage.getItem("clientId"),
        });
        const responseData = await response.data;
        const personalCollection = responseData.data.personalCollection;
        const generalBusiness = responseData.data.generalBusiness;
        const truckDriverForms = responseData.data.truckDriverForms;
        const rentalProperties = responseData.data.rentalProperties;
        console.log(rentalProperties, "rentalProperties");
        setGeneralBusinessData(responseData.data?.generalBusiness);

        let combinedArray = [
          personalCollection,
          ...generalBusiness,
          ...truckDriverForms,
          ...rentalProperties,
        ]?.filter((val) => val?._id); // Ensure there's an _id property
        const rowsWithId = combinedArray.map((row, index) => ({
          ...row,
          id: index + 1, // Assign a unique ID starting from 1
        }));

        setReview(rowsWithId);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setTabLoader(false);
      }
    };

    fetchData();
  }, []);
  const handleActionClick = (row) => {
    switch (row.informationType) {
      case "General Business":
        localStorage.setItem("currentBusinessRow", JSON.stringify(row));
        setgeneralBusinessID(row._id);
        setGeneralBusinessModal(true);
        setModalData(row);
        break;
      case "Personal Collection":
        localStorage.setItem("personalCollectionInfo", JSON.stringify(row));
        setpersonalCollactionID(row._id);
        setPersonalCollectionModal(true);
        setModalData(row);
        break;
      case "Truck Driver":
        localStorage.setItem("selectedDriverInfo", JSON.stringify(row));
        settruckdriverID(row._id);
        setTruckDriverModal(true);
        setModalData(row);

        break;
      case "Rental Property":
        setrentalPropertyInfo(row.rentalPropertyId);
        setrentalPropertyID(row._id);
        setOpenPrevModal(true);
        setModalData(row);

        break;
      default:
        break;
    }
  };
  const renderTableOrMessage = () => {
    const validRows = review.filter((row) => row && row.id);
    return (
      <CustomTable loading={tab_loader} rows={validRows} columns={SUBColumns} />
    );
  };

  const SUBColumns = [
    {
      field: "informationType",
      headerName: "Information Type",
      width: 200,
      editable: false,
    },

    {
      field: " createdAt",
      headerName: "Date",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          {params.row.type === "RentalProperty"
            ? formattedDate(params.row.rentalPropertyId.createdAt)
            : formattedDate(params.row.createdAt)}
        </div>
      ),
    },
    {
      field: "formProgress",
      headerName: "Form Progress",
      width: 200,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Tax Return Submitted"
                ? "rgba(0, 173, 239, 0.16)"
                : "",
            color:
              params.row.status === "Tax Return Submitted"
                ? "rgba(0, 149, 206, 1)"
                : "",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    {
      field: "additionalNotes",
      headerName: "Comments",
      width: 370,
      editable: false,
    },
    {
      headerName: "Action",
      width: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <div>
          <IconStyledButton
            height="40px"
            variant="text"
            color="white"
            fontSize="12px"
            onClick={() => handleActionClick(params.row)}
          >
            <DownloadIcon />
            Download
          </IconStyledButton>
        </div>
      ),
    },
  ];

  const handleDownload = async (rowData) => {
    try {
      const doc = new jsPDF();

      const columns = [
        { title: "Information Type", dataKey: "informationType" },
        { title: "Date", dataKey: "createdAt" },
        { title: "Form Type", dataKey: "dataFromFile" },
        { title: "Status", dataKey: "status" },
        { title: "Comments", dataKey: "additionalNotes" },
      ];

      const rows = [rowData];
      doc.autoTable({
        head: [columns.map((col) => col.title)],
        body: rows.map((row) => columns.map((col) => row[col.dataKey])),
      });

      const fileName = `TaxDocument_${rowData.id}.pdf`;
      doc.save(fileName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <>
      <BusinessListContainer>
        <TitleComponent text="Downloaded tax docs" />
        <BusinessListWrapper>{renderTableOrMessage()}</BusinessListWrapper>
      </BusinessListContainer>
      {console.log(generalBusinessData, "generalBusinessData")}
      <GeneralBusiness
        download={true}
        modalData={generalBusinessData}
        generalBusinessData={modalData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        generalBusinessID={generalBusinessID}
      />
      <PersonalCollection
        download={true}
        personalCollectionData={modalData}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
        personalCollactionID={personalCollactionID}
      />
      <ReviewFormPreview
        download={true}
        // componentRef={com}
        rentalPropertiesData={modalData}
        openPrevModal={openPrevModal}
        setOpenPrevModal={setOpenPrevModal}
        values={rentalPropertyInfo}
        rentalPropertyID={rentalPropertyID}
      />
      <TruckDriver
        download={true}
        truckDriverModalData={modalData}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
        truckdriverID={truckdriverID}
      />
    </>
  );
};

export default TaxDocsList;

const BusinessListContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
