import {
  GrayBox,
  GrayHeading,
  GrayHeadingTitle,
} from "./ReviewAndConfirmation.style";
import {
  HeadingFive,
  Span,
} from "components/reusable/typography/Typography.style";
import FlexComp from "components/reusable/flex/Flex";

const ReviewConfirmationBox = ({ data, values = {} }) => {
  // Helper function to check if string is a valid URL
  const isValidUrl = (string) => {
    try {
      return string.startsWith("http://") || string.startsWith("https://");
    } catch {
      return false;
    }
  };

  // Helper function to check if string is a timestamp
  const isTimestamp = (dateString) => {
    if (!dateString || typeof dateString !== "string") return false;
    return dateString.includes("T") || dateString.includes(":");
  };

  // Helper function to format timestamp as MM-DD-YYYY
  const formatTimestamp = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;

    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Helper function to render value based on type
  const renderValue = (value) => {
    if (!value || (Array.isArray(value) && value.length === 0)) return "N/A";

    // Case 1: If value is an array of objects with "doc" links, render multiple links
    if (Array.isArray(value) && typeof value[0] === "object" && value[0]?.doc) {
      return (
        <div className="flex flex-col space-y-1">
          {value.map((docObj) => (
            <a
              key={docObj._id}
              href={docObj.doc}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 underline"
            >
              {docObj.fileName || "Download File"}
            </a>
          ))}
        </div>
      );
    }

    // Case 2: If value is a single valid URL, return a clickable download link
    if (typeof value === "string" && isValidUrl(value)) {
      return (
        <a
          href={value}
          download
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Download
        </a>
      );
    }

    // Case 3: If value is a timestamp, format it
    if (typeof value === "string" && isTimestamp(value)) {
      return formatTimestamp(value);
    }

    // Case 4: If value is a normal string, return as is
    return value.toString();
  };

  // Helper function to get the value based on whether it's an asset or not
  const getValue = (itm, idx) => {
    if (data?.title === "Assets") {
      return values[idx]?.[itm?.key] || "";
    }
    // Handle otherExpenseArr fields
    if (itm.key === "name" || itm.key === "amount") {
      return values.otherExpenseArr?.[0]?.[itm.key] || "";
    }
    return values?.[itm?.key] || "";
  };

  // Helper function to render a section (e.g., spouse or dependents)
  const renderSection = (section, sectionData) => {
    if (!section || !sectionData) return null;

    return (
      <div>
        <GrayHeading>
          <Span fontSize="18px" fontWeight="600" color="#FFFFFF">
            {section.title}
          </Span>
        </GrayHeading>
        <FlexComp
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          gridGap="10px"
          width="100%"
        >
          {section.fields.map((field, idx) => (
            <FlexComp
              key={idx}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              gridGap="10px"
              width="33%"
            >
              <GrayHeadingTitle>
                <HeadingFive fontSize="16px" fontWeight="500" color="#1B1B1B">
                  {field.title}
                </HeadingFive>
                <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                  {renderValue(sectionData[field.key.split(".")[1]])}
                </Span>
              </GrayHeadingTitle>
            </FlexComp>
          ))}
        </FlexComp>
      </div>
    );
  };

  // Helper function to render dependents section
  const renderDependents = (dependents) => {
    if (!dependents || !values[dependents.key]) return null;

    return (
      <div>
        <GrayHeading>
          <Span fontSize="18px" fontWeight="600" color="#FFFFFF">
            {dependents.title}
          </Span>
        </GrayHeading>
        {values[dependents.key].map((dependent, idx) => (
          <FlexComp
            key={idx}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            gridGap="10px"
            width="100%"
          >
            {dependents.fields.map((field, idx) => (
              <FlexComp
                key={idx}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gridGap="10px"
                width="33%"
              >
                <GrayHeadingTitle>
                  <HeadingFive fontSize="16px" fontWeight="500" color="#1B1B1B">
                    {field.title}
                  </HeadingFive>
                  <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                    {renderValue(dependent[field.key])}
                  </Span>
                </GrayHeadingTitle>
              </FlexComp>
            ))}
          </FlexComp>
        ))}
      </div>
    );
  };

  return (
    <GrayBox>
      <GrayHeading>
        <Span fontSize="18px" fontWeight="600" color="#FFFFFF">
          {data?.title}
        </Span>
      </GrayHeading>
      <FlexComp
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        gridGap="10px"
        width="100%"
      >
        <FlexComp
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap="10px"
          width="33%"
        >
          {data.colOne &&
            data.colOne.map((itm, idx) => (
              <GrayHeadingTitle key={idx}>
                <HeadingFive fontSize="16px" fontWeight="500" color="#1B1B1B">
                  {itm?.title}
                </HeadingFive>
                <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                  {renderValue(getValue(itm, idx))}
                </Span>
              </GrayHeadingTitle>
            ))}
        </FlexComp>

        <FlexComp
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap="10px"
          width="33%"
        >
          {data.colTwo &&
            data.colTwo.map((itm, idx) => (
              <GrayHeadingTitle key={idx}>
                <HeadingFive fontSize="16px" fontWeight="500" color="#1B1B1B">
                  {itm?.title}
                </HeadingFive>
                <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                  {renderValue(getValue(itm, idx))}
                </Span>
              </GrayHeadingTitle>
            ))}
        </FlexComp>

        <FlexComp
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap="10px"
          width="33%"
        >
          {data.colThree &&
            data.colThree.map((itm, idx) => (
              <GrayHeadingTitle key={idx}>
                <HeadingFive fontSize="16px" fontWeight="500" color="#1B1B1B">
                  {itm?.title}
                </HeadingFive>
                <Span fontSize="16px" fontWeight="400" color="#1B1B1B">
                  {renderValue(getValue(itm, idx))}
                </Span>
              </GrayHeadingTitle>
            ))}
        </FlexComp>
      </FlexComp>

      {/* Render Spouse Section */}
      {data.spouse && renderSection(data.spouse, values.spouse)}

      {/* Render Dependents Section */}
      {data.dependents && renderDependents(data.dependents)}
    </GrayBox>
  );
};

export default ReviewConfirmationBox;
