import styled from "styled-components";
import BusinessInformation from "components/client/rentalProperty/BusinessInformation";
import { HeadingFive } from "components/reusable/typography/Typography.style";

const AddRental = () => {
  return (
    <AddRentalContainer>
      <HeadingFive fontSize="18px" fontWeight="500">
        Rental Property
      </HeadingFive>
      <BusinessInformation />
    </AddRentalContainer>
  );
};

export default AddRental;

const AddRentalContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;
