import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import { Button } from "components/reusable/button/Button";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";
import { Span } from "components/reusable/typography/Typography.style";
import {
  adjustments,
  healthCare,
  ItemizedDeduction,
  personalCollection,
  retirements,
  uploadDoc,
} from "data/clientData/reviewAndConfirmationModal";
import html2pdf from "html2pdf.js";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import TermsCondition from "../TermsCondition";
import {
  ReviewConfirmationBody,
  ReviewConfirmationBtnsWrapper,
  ReviewConfirmationContainer,
  ReviewConfirmationHeader,
} from "./ReviewAndConfirmation.style";
import ReviewConfirmationBox from "./ReviewConfirmationBox";
import ReviewConfirmationBoxTwo from "./ReviewConfirmationBoxTwo";

const successToast = (message = "") => toast.success(message);
const errorToast = (message = "") => toast.error(message);

const PersonalCollection = ({
  personalCollectionModal,
  setPersonalCollectionModal,
  personalCollactionID,
  reviewAndSubmition,
  download = false,
  check1099Form,
  setIs1099Modal,
  closeTermModal,
  showTermsCondition,
  setShowTermsCondition,
  setIsTermModal,
  fetchData,
}) => {
  // console.log(personalCollactionID, "personalCollectionId");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [showTermsCondition, setShowTermsCondition] = useState(false);
  const clientId = localStorage.getItem("clientId");
  const info = localStorage.getItem("personalCollectionInfo");
  const personalCollectionInfo = JSON.parse(info || "{}");
  const userName = localStorage.getItem("userName");
  const initials = localStorage.getItem("initials");

  const signatureData = {
    fullName: userName,
    initials: initials,
    signatureStyle: "Ariel",
    eFileTaxReturnCheck: false,
    drawCheck: false,
    uploadCheck: false,
    signatureFile: null,
  };
  const handleSubmitReview = async () => {
    try {
      console.log(check1099Form, "check1099Form >>>");
      setLoading(true);
      if (check1099Form) {
        if(setIsTermModal){
          setIsTermModal(true);
        } else{
          setShowTermsCondition(true);
        }
      } else {
        setIs1099Modal(true);
      }
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error while submit docs. please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  const handleOnSaveSignature = async (signatureData) => {
    console.log("Received signature data:", signatureData);

    // Early return if signatureFile is missing
    if (!signatureData.signatureFile) {
      console.error("signatureFile is missing");
      errorToast("Signature file is required.");
      return;
    }

    try {
      // Prepare FormData for submission
      const formData = new FormData();
      formData.append("clientId", clientId);
      formData.append(
        "personalCollectionId",
        JSON.stringify(personalCollactionID)
      );
      formData.append("status", "Document Submitted");
      formData.append("signatureData", JSON.stringify(signatureData));

      // // Log FormData entries for debugging
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // Submit the form data to the server
      await apiReq(
        API_ROUTES.SubmitAllForms,
        "POST",
        formData,
        API_BASE_URL,
        false
      );
      // Handle successful submission
      successToast("Form submitted successfully.");
      setTimeout(() => {
        setPersonalCollectionModal(false); // Close the modal after submission
        fetchData();
      }, 1500);
    } catch (error) {
      // Handle errors during submission
      console.error("Error submitting form:", error);
      errorToast(`Error submitting form: ${error.message || "Unknown error"}.`);
    }
  };
  const componentRef = useRef();
  const handleDownload = () => {
    const element = componentRef.current;
    const opt = {
      margin: 1,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    // New Promise-based API
    html2pdf().set(opt).from(element).save();
  };

  return (
    <Modal
      open={personalCollectionModal}
      onClose={() => setPersonalCollectionModal(!personalCollectionModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ReviewConfirmationContainer>
        <ReviewConfirmationHeader>
          <Span color="#000000" fontSize="24px" fontWeight="600">
            Personal Collection
          </Span>
          <CloseIcon
            sx={{ color: "#000000", cursor: "pointer" }}
            onClick={() => setPersonalCollectionModal(!personalCollectionModal)}
          />
        </ReviewConfirmationHeader>
        <ReviewConfirmationBody ref={componentRef}>
          <ReviewConfirmationBox
            data={personalCollection}
            // values={personalCollectionInfo?.prepSheetId}
            values={{
              ...personalCollectionInfo?.prepSheetId,
              ...personalCollectionInfo?.dependent,
              ...personalCollectionInfo?.spouse,
            }}
          />
          <ReviewConfirmationBox
            data={retirements}
            values={personalCollectionInfo?.retirementId}
          />
          <ReviewConfirmationBox
            data={healthCare}
            values={personalCollectionInfo?.healthCareId}
          />
          <ReviewConfirmationBox
            data={adjustments}
            values={personalCollectionInfo?.adjustmentsId}
          />
          <ReviewConfirmationBoxTwo
            data={ItemizedDeduction}
            values={personalCollectionInfo?.itemizedDeductionId}
          />
          <ReviewConfirmationBox
            data={uploadDoc}
            values={personalCollectionInfo?.uploadDocs}
          />
          {/* {console.log(personalCollectionInfo?.adjustmentsId, "personalCollectionInfo?.itemizedDeductionId")} */}
        </ReviewConfirmationBody>
        {/* {console.log(
          personalCollectionInfo?.itemizedDeductionId,
          "itemizedDeduction"
        )} */}
        {reviewAndSubmition && (
          <ReviewConfirmationBtnsWrapper>
            <Button
              text="Edit"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#0B2558"
              onClick={() => {
                localStorage.setItem(
                  "personalCollectionId",
                  JSON.stringify(personalCollactionID)
                );
                localStorage.setItem("editForm", true);
                navigate("/personal-collection");
              }}
            />
            <Button
              text={
                loading ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Confirm"
              }
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="#00ADEF"
              onClick={handleSubmitReview}
            />
          </ReviewConfirmationBtnsWrapper>
        )}
        <ReviewConfirmationBtnsWrapper>
          {!reviewAndSubmition && (
            <Button
              text="OK"
              width="15%"
              fontSize="20px"
              padding="10px 30px 10px 30px"
              alignSelf="flex-end"
              onClick={() =>
                setPersonalCollectionModal(!personalCollectionModal)
              }
            />
          )}

          {download && (
            <TitleButton
              style={{ padding: "10px 20px 10px 20px" }}
              onClick={handleDownload}
            >
              Download
            </TitleButton>
          )}
        </ReviewConfirmationBtnsWrapper>

        {showTermsCondition && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <TermsCondition
              isModalOpen={showTermsCondition}
              closeModal={() => closeTermModal()}
              onSaveSignature={handleOnSaveSignature}
            />
          </div>
        )}
      </ReviewConfirmationContainer>
    </Modal>
  );
};

export default PersonalCollection;
