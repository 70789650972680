import styled from "styled-components";

export const RequiredTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
`;

export const RequiredTaskContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  margin-right: 10px;
  `;

export const DonutChartContainer = styled.div`
  width: 185px;
  margin: auto;
  top: 12px;
  right: 18px;
  position: absolute;
`;

export const GrayCircleBackground = styled.div`
  position: relative;
  width: 220px;
  background-color: #eef0fa;
  border-radius: 68%;
  height: 210px;
  margin: auto;
  @media (max-width: 1240px) {
    width: 160px;
    height: 160px;
  };
`;

export const ReturnStatsContainer = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 20px;
  width: 40%;
`;

export const ReturnStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 35px;
`;

export const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 3px;
  width: 100%;
`;

export const Dot = styled.div`
  border-radius: 50%;
  background: red;
  width: 10px;
  background: ${({ color }) => color};
  height: 10px;
`;

export const TaxSelect = styled.div`
  display: flex;
  grid-gap: 5px;
  align-items: center;
  justify-content: space-between;
  select {
    border: none;
  }
`;

export const DotBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  flex-wrap: wrap;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 45px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
 
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleSec = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

export const TablePercentageContainer = styled.div`
  border: 1px solid #00adef;
  display: flex;
  justify-content: center;
  background: #f0f9ff;
  align-items: center;
  color: #00adef;
  width: 30%;
  border-radius: 5px;
  padding: 2px 4px;
  @media (max-width: 1200px) {
    width: 70%;
  }
`;
