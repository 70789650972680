import styled from "styled-components";
import FlexComp from "components/reusable/flex/Flex";
import { Input } from "components/reusable/input/Inputs";
import SecDropdownComp from "components/reusable/dropdownComp/SecDropdown";
import { Button } from "components/reusable/button/Button";
import { useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";

const initialValues = {
  subject: '',
  priority: '',
  description: ''
};

const validationSchema = Yup.object({
  subject: Yup.string().required('Subject is required'),
  priority: Yup.string().required('Priority is required'),
  description: Yup.string().required('Description is required')
});
const errorToast = (message = '') => toast.error(message);

const options = [
  { value: "High", label: "High" },
  { value: "Low", label: "Low" },
];

const AdminSupportComp = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    const storedUserData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(storedUserData);
    if (storedUserData) {
      const obj = {
        ...values,
        staffId: parsedUserData.user._id
      }
      try {
        await apiReq(API_ROUTES.supportTicketAdd, "POST", obj)
        resetForm();
      } catch (error) {
        errorToast(error?.message || "Error while save support ticket. please try again later.");
      }
    };
  }

  return (
    <>
      <RentalPropertyContainer>
        <h2 style={{ paddingLeft: "5px" }}>Add New Ticket</h2>
        <RentalPropertyWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <FlexComp direction='column' gridGap='25px' width='100%'>
                  <Input
                    label='Subject'
                    id='outlined-size-small'
                    defaultValue=''
                    size='size'
                    width='32%'
                    placeholder='Enter'
                    name='subject'
                    onChange={(e) => formikProps.setFieldValue("subject", e.target.value)}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage name="subject" component="div" />

                  <SecDropdownComp
                    label='Priority'
                    id='location-select'
                    placeholder='select'
                    size='small'
                    width='32%'
                    options={options}
                    name='priority'
                    value={formikProps.values.priority}
                    handleChange={(e) => formikProps.setFieldValue("priority", e.target.value)}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage name="priority" component="div" />
                  <Input
                    label='Description'
                    id='outlined-size-small'
                    defaultValue=''
                    size='size'
                    width='92%'
                    height={50}
                    placeholder='You can write your issue here...'
                    name='description'
                    value={formikProps.values.description}
                    onChange={(e) => formikProps.setFieldValue("description", e.target.value)}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage name="description" component="div" />
                </FlexComp>
                <FlexComp direction='row' gridGap='25px' width='100%' marginTop="20px">
                  <Button
                    type='submit'
                    text='Send'
                    backgroundColor='rgba(11, 37, 88, 1)'
                    color='white'
                    width='112px'
                  />
                  <Button
                    text='Cancel'
                    backgroundColor='rgba(239, 243, 253, 1)'
                    color='rgba(174, 182, 207, 1)'
                    width='112px'
                    onClick={() => {
                      navigate("/admin-support");
                    }}
                  />
                </FlexComp>
              </form>
            )}
          </Formik>
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default AdminSupportComp;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
