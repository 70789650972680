import PdfUploadButton from "../../components/reusable/button/PdfUploadButton";

export const businessInformation = {
  title: "Business Information",
  colOne: [
    {
      title: "Tax Year",
      key: "taxYear",
      value: "1234",
    },
    {
      title: "Entity Info",
      key: "entityInfo",
      value: "Sole Proprietor",
    },
    {
      title: "Business Name",
      key: "businessName",
      value: "Furniture",
    },
    {
      title: "Business Description Products/Services",
      key: "businessDescription",
      value: "abc......",
    },
  ],

  colTwo: [
    {
      title: "Individual Name",
      key: "firstName",
      value: "Danish Mehmood",
    },
    {
      title: "Fed ID/EIN and/or SSN",
      key: "ssn",
      value: "123456",
    },
    {
      title: "Business Start Date",
      key: "businessStartDate",
      value: "2023-12-15",
    },
    {
      title: "Contractors",
      key: "contractDoc",
      value: "",
    },
  ],

  colThree: [
    {
      title: "Phone#",
      key: "mobileNumber",
      value: "+92 322 651 6323",
    },
    {
      title: "Business Address",
      key: "city",
      value: "xyz",
    },
    {
      title: "Incorporate State",
      key: "incorporationState",
      value: "xyz",
    },
    {
      title: "Payroll",
      key: "payRollDoc",
    },
  ],
};

export const expenses = {
  title: "Expenses",
  colOne: [
    {
      title: "Income",
      key: "grossReceipts",
      value: "$100",
    },
    {
      title: "Accounting",
      key: "accounting",
      value: "$100",
    },

    {
      title: "Advertising",
      key: "advertising",
      value: "$100",
    },
    {
      title: "Bank Charges/Fees",
      key: "bankCharges",
      value: "$100",
    },
    {
      title: "Cell Phone",
      key: "cellPhone",
      value: "$100",
    },
    {
      title: "Insurance (Bus & Liability)",
      key: "insurance",
      value: "$100",
    },
    {
      title: "Internet Cost (business only)",
      key: "internetCost",
      value: "$100",
    },
    {
      title: "Legal & Professional Fees",
      key: "legalAndProfessionalFees",
      value: "$100",
    },
    {
      title: "License & Permit",
      key: "licenseAndPermit",
      value: "$100",
    },
    {
      title: "Miscellaneous",
      key: "miscellaneous",
      value: "$100",
    },
  ],

  colTwo: [
    {
      title: "Office Expenses",
      key: "officeExpenses",
      value: "$100",
    },
    {
      title: "Outside Contractors (1099’s)",
      key: "outsideContractors",
      value: "$100",
    },
    {
      title: "Postage",
      key: "postage",
      value: "$100",
    },
    {
      title: "Printing",
      key: "printing",
      value: "$100",
    },
    {
      title: "Rent",
      key: "rent",
      value: "$100",
    },
    {
      title: "Repairs & Maintenance",
      key: "repairsAndMaintenance",
      value: "$100",
    },
    {
      title: "Supplies",
      key: "supplies",
      value: "$100",
    },
    {
      title: "Telephone (Cell & Land)",
      key: "telephone",
      value: "$100",
    },
    {
      title: "Training",
      key: "training",
      value: "$100",
    },
  ],

  colThree: [
    {
      title: "Vehicle Exp (Business)",
      key: "vehicleExp",
      value: "$100",
    },
    {
      title: "Delivery/Freight/Shipper",
      key: "shipper",
      value: "$100",
    },
    {
      title: "Dues/Subscriptions",
      key: "subscriptions",
      value: "$100",
    },
    {
      title: "Equipment Rental",
      key: "equipmentRental",
      value: "$100",
    },
    {
      title: "Interest Exp",
      key: "interestExp",
      value: "$100",
    },
    {
      title: "Commission Expenses",
      key: "commissionExpenses",
      value: "$100",
    },
    {
      title: "Travel",
      key: "travel",
      value: "$100",
    },
    {
      title: "Other",
      key: "other",
      value: "$100",
    },
    {
      title: "Meals & Entertainment",
      key: "mealsAndEntertainment",
      value: "$100",
    },
  ],
};

export const assets = {
  title: "Assets",
  colOne: [
    {
      title: "Asset Name",
      key: "assetName",
      value: "Jewelry",
    },
  ],
  colTwo: [
    {
      title: "Enter Price",
      key: "price",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Date",
      key: "date",
      value: "2023-12-15",
    },
  ],
};

export const vehicleInformation = {
  title: "Vehicle Information",
  colOne: [
    {
      title: "Vehicle Year Make Model",
      key: "vehicleYearMakeModel",
      value: "2020",
    },
    {
      title: "Advertising",
      key: "advertising",
      value: "$100",
    },
    {
      title: "Vehicle is used by a more than 5% own",
      key: "vehicleUsedCheck",
      value: "Yes",
    },
    {
      title: "Vehicle available for off duty personal use",
      key: "vehicleAvailableForPersonalUserCheck",
      value: "Yes",
    },
    {
      title: "Any other vehicle available for personal user",
      key: "vehicleAvailableForOffDutyCheck",
      value: "Yes",
    },
    {
      title: "Personal Mileage",
      key: "personalMileage",
      value: "abc",
    },
  ],
  colTwo: [
    {
      title: "Purchase Price",
      key: "purchasePrice",
      value: "$100",
    },
    {
      title: "Lease Payments",
      key: "leasePayments",
      value: "$100",
    },
    {
      title: "Insurance",
      key: "insurance",
      value: "$100",
    },
    {
      title: "Gasoline, Lube, Oil",
      key: "gasolineLubeOil",
      value: "Yes",
    },
    {
      title: "Parking Fees, Tolls",
      key: "parkingFees",
      value: "$100",
    },
    {
      title: "Business Mileage",
      key: "businessMileage",
      value: "abc",
    },
  ],
  colThree: [
    {
      title: "Loan Interest",
      key: "loanInterest",
      value: "$100",
    },
    {
      title: "License & Registration",
      key: "licenseAndRegistration",
      value: "$100",
    },
    {
      title: "Repairs & Tires",
      key: "repairsAndTires",
      value: "$100",
    },
    {
      title: "Miscellaneous",
      key: "miscellaneous",
      value: "Yes",
    },
    {
      title: "Interest Exp",
      key: "interestExp",
      value: "$100",
    },
    {
      title: "Commuting Mileage",
      key: "commutingMileage",
      value: "abc",
    },
  ],
};

export const businessUseOfHome = {
  title: "Business Use of Home",
  colOne: [
    {
      title: "Total Area of Home (Sq. Ft)",
      key: "totalAreaOfHome",
      value: "1600 Ft.",
    },
    {
      title: "Repairs & Maintenance",
      key: "repairsAndMaintenance",
      value: "$100",
    },
    {
      title: "Real Estate Taxes",
      key: "realEstateTaxes",
      value: "$100",
    },
    {
      title: "Security",
      key: "security",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Business Use Area (Sq. Ft)",
      key: "businessUseArea",
      value: "1500 Ft.",
    },
    {
      title: "Mortgage Interest",
      key: "mortgageInterest",
      value: "$100",
    },
    {
      title: "Telephone",
      key: "telephone",
      value: "$100",
    },
    {
      title: "Insurance",
      key: "insurance",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Association Dues",
      key: "associationDues",
      value: "$100",
    },
    {
      title: "Utilities",
      key: "utilities",
      value: "$100",
    },
    {
      title: "Rent",
      key: "rent",
      value: "$100",
    },
  ],
};

export const uploaddocs = {
  title: "Uploaded Documents",
  documents: [
    { name: "Document 1", url: "https://example.com/document1.pdf" },
    { name: "Document 2", url: "https://example.com/document2.pdf" },
    { name: "Document 3", url: "https://example.com/document3.pdf" },
    // Add more document objects as needed
  ],
};

const documents = [
  { name: "Business_doc.pdf", url: "https://example.com/document1.pdf" },
  { name: "Personal_docs.pdf", url: "https://example.com/document2.pdf" },
  { name: "Personal_docs.pdf", url: "https://example.com/document2.pdf" },
];

// Map through the documents array and create PdfUploadButton components for each document
uploaddocs.colOne = documents.map((document, index) => (
  <PdfUploadButton key={index} text={document.name} documents={[document]} />
));

uploaddocs.colTwo = documents.map((document, index) => (
  <PdfUploadButton key={index} text={document.name} documents={[document]} />
));

uploaddocs.colThree = documents.map((document, index) => (
  <PdfUploadButton key={index} text={document.name} documents={[document]} />
));

export const personalCollection = {
  title: "Personal Information",
  colOne: [
    {
      title: "First Name",
      key: "firstName",
      value: "Sole Proprietor",
    },
    {
      title: "Tax Year",
      key: "taxYear",
      value: "1234",
    },
    {
      title: "Street",
      key: "street",
      value: "Furniture",
    },
    {
      title: "Zip Code",
      key: "zipCode",
      value: "xyz",
    },
    {
      title: "Alternate # (Work)",
      key: "alternateAddress",
      value: "0330000000",
    },
  ],
  colTwo: [
    {
      title: "Middle Name",
      key: "middleName",
      value: "Danish Mehmood",
    },
    {
      title: "Date of Birth",
      key: "dob",
      value: "1995-12-12",
    },
    {
      title: "City",
      key: "city",
      value: "abc......",
    },
    {
      title: "Mobile Number",
      key: "phone",
      value: "03300000000",
    },
    {
      title: "SSN",
      key: "ssn",
      value: "Furniture",
    },
  ],
  colThree: [
    {
      title: "Last Name",
      key: "lastName",
      value: "030000000",
    },
    {
      title: "Email Address",
      key: "email",
      value: "abc@gmail.com",
    },
    {
      title: "State",
      key: "state",
      value: "abc",
    },
    {
      title: "Occupation",
      key: "occupation",
      value: "abc",
    },
    {
      title: "DL Document",
      key: "dlDoc",
    },
  ],
  // Add new spouse section
  spouse: {
    title: "Spouse Information",
    fields: [
      {
        title: "First Name",
        key: "spouse.firstName",
      },
      {
        title: "Middle Name",
        key: "spouse.middleName",
      },
      {
        title: "Last Name",
        key: "spouse.lastName",
      },
      {
        title: "SSN",
        key: "spouse.ssn",
      },
      {
        title: "Date of Birth",
        key: "spouse.dob",
      },
      {
        title: "DL Document",
        key: "spouse.spouseDlDoc",
      },
    ],
  },
  // Add new dependent section
  dependents: {
    title: "Dependent Information",
    isArray: true,
    key: "dependent",
    fields: [
      {
        title: "First Name",
        key: "firstName",
      },
      {
        title: "Middle Name",
        key: "middleName",
      },
      {
        title: "Last Name",
        key: "lastName",
      },
      {
        title: "SSN",
        key: "ssn",
      },
      {
        title: "Date of Birth",
        key: "dob",
      },
      {
        title: "Relation",
        key: "relation",
      },
      {
        title: "Birth Certificate",
        key: "dependentBirthCertificateDoc",
      },
    ],
  },
};

export const healthCare = {
  title: "Health Care",
  colOne: [
    // {
    //   title: "Total Amount of 3rd Stimulus ($1400)",
    //   value: "$100",
    // },
    {
      title: "ACA (Obamacare) / Healthcare.gov",
      key: "healthCareGov",
      value: "Yes",
    },
    {
      title: "Healthcare Document", // Add this field
      key: "healthCareDoc",
    },
  ],
  colTwo: [
    // {
    //   title: "Total Child Tax Credit (6419)",
    //   value: "$100",
    // },
    // {
    //   title: "401K/TSP Withdrawal",
    //   value: "No",
    // },
  ],
  colThree: [],
};
export const retirements = {
  title: "Retirements",
  colOne: [
    {
      title: "Withdrawal Doc",
      key: "withdrawalDoc",
    },
    {
      title: "Sales Doc",
      key: "salesDoc",
    },
  ],
  colTwo: [
    {
      title: "Social Security Benefit Doc",
      key: "socialSecurityBenefitDoc",
    },
  ],
  colThree: [],
};

export const uploadDoc = {
  title: "Uploaded Docs",
  colOne: [
    {
      title: "Docs 1099",
      key: "docs1099",
    },
    {
      title: "Other Doc",
      key: "otherDocs",
    },
  ],
  colTwo: [
    {
      title: "w2 Docs Doc",
      key: "w2Docs",
    },
  ],
  colThree: [],
};

export const adjustments = {
  title: "Adjustments",
  colOne: [
    {
      title: "IRA Contributions",
      key: "iraContributions",
      value: "$100",
    },
    {
      title: "Estimated Taxes",
      key: "estimatedTaxes",
      value: "Yes",
    },
  ],
  colTwo: [
    {
      title: "Teacher Expenses",
      key: "teacherExpense",
      value: "$100",
    },
    {
      title: "    (1098T Required)",
      key: "studentLoanDoc",
    },
    // {
    //   title: "Education Expense (1098T Required)",
    //   key: "educationExpense",
    //   value: "No",
    // },
  ],
  colThree: [
    {
      title: "Student Loan Interest",
      key: "studentLoanInterest",
      value: "$100",
    },
    {
      title: "Alimony Paid ",
      key: "alimoneyPaid",
      value: "No",
    },
  ],
};

export const truckBusinessInfo = {
  title: "Business Information",
  colOne: [
    {
      title: "First Name",
      key: "firstName",
      value: "abc",
    },
    {
      title: "Zip Code",
      key: "zipCode",
      value: "abc......",
    },
    {
      title: "Street",
      key: "street",
      value: "abc......",
    },
    {
      title: "Cell Number",
      key: "mobileNumber",
      value: "03300000000",
    },
  ],
  colTwo: [
    {
      title: "Middle Name",
      key: "middleName",
      value: "abc",
    },
    {
      title: "Social Security",
      key: "socialSecurity",
      value: "abc",
    },
    {
      title: "City",
      key: "city",
      value: "abc",
    },
    {
      title: "DOB",
      key: "dob",
      value: "2000-12-20",
    },
  ],
  colThree: [
    {
      title: "Last Name",
      key: "lastName",
      value: "abc",
    },
    {
      title: "Tax year",
      key: "taxYear",
      value: "2023",
    },
    {
      title: "State",
      key: "state",
      value: "xyz",
    },
    {
      title: "Email",
      key: "email",
      value: "xyz",
    },
    // {
    //   title: "Business Address",
    //   key: "",
    //   value: "abc",
    // },
  ],
};

export const fees = {
  title: "Administrative Fees",
  colOne: [
    {
      title: "Association Fees",
      key: "associationFee",
      value: "$100",
    },
    {
      title: "DOT Exam Fees",
      key: "dotExamFee",
      value: "$100",
    },
    {
      title: "Licensing Fees",
      key: "licensingFee",
      value: "$100",
    },
    {
      title: "Wire Fees",
      key: "wireFee",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "ATM Charges",
      key: "atmCharges",
      value: "$100",
    },
    {
      title: "Comcard Fees",
      key: "comcardFee",
      value: "$100",
    },
    {
      title: "Safe Deposit Box Fees",
      key: "safeDepositBoxFee",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Bank Fees",
      key: "bankFee",
      value: "$100",
    },
    {
      title: "IRA Custodial / Investment Fees",
      key: "investmentFee",
      value: "$100",
    },
    {
      title: "Administrative Fees",
      key: "administrativeFee",
      value: "$100",
    },
  ],
};

export const maintenance = {
  title: "Maintenance",
  colOne: [
    {
      title: "Parts",
      key: "parts",
      value: "$100",
    },
    {
      title: "Truck Washes",
      key: "truckWashes",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Repairs",
      key: "repairs",
      value: "$100",
    },
    {
      title: "Other Maintenance",
      key: "otherMaintenance",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Tires",
      key: "tires",
      value: "$100",
    },
  ],
};

export const labor = {
  title: "Labor",
  colOne: [
    {
      title: "Subcontractor",
      key: "",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Lumpers",
      key: "",
      value: "$100",
    },
  ],
  colThree: [],
};

export const taxes = {
  title: "Labor/Taxes",
  colOne: [
    {
      title: "Sub Contractor",
      key: "subContractor",
      value: "$100",
    },

    {
      title: "Ad Valorem",
      key: "adValorem",
      value: "$100",
    },
    {
      title: "License Tax",
      key: "licenseTax",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Lumpers",
      key: "lumpers",
      value: "$100",
    },
    {
      title: "Fuel Tax",
      key: "fuelTax",
      value: "$100",
    },
    {
      title: "Other Tax",
      key: "otherTax",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Heavy Use Tax",
      key: "heavyUseTax",
      value: "$100",
    },
    {
      title: "Permits",
      key: "permits",
      value: "$100",
    },
    {
      title: "Registration",
      key: "registration",
      value: "$100",
    },
  ],
};

export const insurance = {
  title: "Insurance",
  colOne: [
    {
      title: "Bobtail Insurance",
      key: "bobtailInsurance",
      value: "$100",
    },
    {
      title: "Workman’s Compensation",
      key: "insuranceWorkmanCompensation",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "PI & PD Insurance",
      key: "piAndPdInsurance",
      value: "$100",
    },
    {
      title: "Acc Insurance",
      key: "accInsurance",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Truck Insurance",
      key: "truckInsurance",
      value: "$100",
    },
    {
      title: "Other Insurance",
      key: "otherInsurance",
      value: "$100",
    },
  ],
};

export const interest = {
  title: "Interest",
  colOne: [
    {
      title: "Interest on Advances",
      key: "interestOnAdvances",
      value: "$100",
    },
    {
      title: "Other Interest",
      key: "otherInterest",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Interest on Credit Cards Bus",
      key: "interestCreditCardsBus",
      value: "$100",
    },
    {
      title: "Workman’s Compensation",
      key: "interestWorkmanCompensation",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Interest on Truck Loans",
      key: "interestOnTruckLoans",
      value: "$100",
    },
    // {
    //   title: "Other Interest",
    //   key: "otherInterest",
    //   value: "$100",
    // },
  ],
};

export const roadExpenses = {
  title: "Road Expenses",
  colOne: [
    {
      title: "Hotels",
      key: "hotel",
      value: "$100",
    },
    {
      title: "Showers",
      key: "showers",
      value: "$100",
    },
    {
      title: "Weigh Fees/Scales",
      key: "weightFees",
      value: "$100",
    },
    {
      title: "Rental Car",
      key: "rentalCar",
      value: "$100",
    },
    {
      title: "Name",
      key: "name",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Laundry",
      key: "laundry",
      value: "$100",
    },
    {
      title: "Tolls",
      key: "tolls",
      value: "$100",
    },
    {
      title: "Prepass",
      key: "prepass",
      value: "$100",
    },
    {
      title: "Airfare",
      key: "airfare",
      value: "$100",
    },
    {
      title: "Amount",
      key: "amount",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Parking",
      key: "parking",
      value: "$100",
    },
    {
      title: "Truck Supplies",
      key: "truckSupplies",
      value: "$100",
    },
    {
      title: "Meals",
      key: "meals",
      value: "$100",
    },
    {
      title: "No. of Days in the Roads",
      key: "noOfDaysInRoads",
      value: "20",
    },
  ],
};

export const professionalFess = {
  title: "Professional Fees",
  colOne: [
    {
      title: "Attorney and Accounting",
      key: "attorneyAndAccounting",
      value: "$100",
    },
  ],
  // colTwo: [
  //   {
  //     title: "Professional Fees",
  //     key: "officeExpense",
  //     value: "$100",
  //   },
  // ],
  colTwo: [
    {
      title: "Tax Prep Fees",
      key: "taxPrepFee",
      value: "$100",
    },
  ],
};

export const fuelAndOil = {
  title: "Fuel and Oil",
  colOne: [
    {
      title: "Fuel",
      key: "fuel",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Oil",
      key: "oil",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Reefer Fuel",
      key: "reeferFuel",
      value: "$100",
    },
  ],
};

export const officeExpenses = {
  title: "Office Expenses",
  colOne: [
    {
      title: "Office Expense",
      key: "officeExpense",
      value: "$100",
    },
  ],
  colTwo: [],
  colThree: [],
};

export const rentAndLease = {
  title: "Rent & Lease",
  colOne: [
    {
      title: "Truck Lease",
      key: "truckLease",
      value: "$100",
    },
  ],
  colTwo: [
    {
      title: "Storage Lease",
      key: "storageLease",
      value: "$100",
    },
  ],
  colThree: [
    {
      title: "Other",
      key: "other",
      value: "$100",
    },
  ],
};

export const utilities = {
  title: "Utilities",
  colOne: [
    {
      title: " Mobile Number",
      key: "mobileNumber",
      value: "$100",
    },
  ],
  colTwo: [],
  colThree: [],
};

export const ItemizedDeduction = {
  title: "Itemized Deduction",
  categories: [
    {
      subtitle: "medical",
      colOne: [
        {
          title: "Dental Exp",
          key: "dentalExpense",
          value: "abc",
        },
        {
          title: "Medical Exp",
          key: "medicalExpense",
          value: "$100",
        },
      ],
      colTwo: [
        {
          title: "Health Ins (Post Tax)",
          key: "medicalIns",
          value: "abc",
        },
        {
          title: "Dental Ins (Post Tax)",
          key: "dentalIns",
          value: "$100",
        },
      ],
      colThree: [
        {
          title: "Prescription Cost",
          key: "prescriptionCost",
          value: "$100",
        },
      ],
    },
    {
      subtitle: "taxesAndInterest",
      colOne: [
        {
          title: "Interest (Mortgages)",
          key: "interest",
          value: "$100",
        },
        {
          title: "PMI",
          key: "pmi",
          value: "$100",
        },
      ],
      colTwo: [
        {
          title: "Real Estate Taxes",
          key: "realEstateTaxes",
          value: "$100",
        },
        {
          title: "Auto/Truck Tax",
          key: "autoTruckTax",
          value: "Yes",
        },
      ],
      colThree: [
        {
          title: "Boat/Motorcycle",
          key: "boatMotorcycle",
          value: "$100",
        },
        {
          title: "Yr/Make/Model",
          key: "model",
          value: "$100",
        },
      ],
    },
    {
      subtitle: "giftsToCharity",
      colOne: [
        {
          title: "Cash",
          key: "cash",
          value: "$100",
        },
      ],
      colTwo: [
        {
          title: "Non Cash",
          key: "nonCash",
          value: "$100",
        },
      ],
      colThree: [],
    },
    {
      subtitle: "militaryExpense",
      colOne: [
        {
          title: "Supplies",
          key: "supplies",
          value: "$100",
        },
        {
          title: "Travel Expenses",
          key: "travelExpense",
          value: "$100",
        },
        {
          title: "Moving Expenses",
          key: "movingExpense",
          value: "$100",
        },
        {
          title: "Uniforms/Cleaners",
          key: "uniformsCleaners",
          value: "$100",
        },
        {
          title: "Business",
          key: "business",
          value: "$100",
        },
      ],
      colTwo: [
        {
          title: "Hotel Expenses",
          key: "hotelExpense",
          value: "$100",
        },
        {
          title: "Meals & Entertainment",
          key: "mealAndEntertainment",
          value: "$100",
        },
        {
          title: "Supplies/Equipment",
          key: "equipment",
          value: "$100",
        },
        {
          title: "Fees",
          key: "fees",
          value: "$100",
        },
        {
          title: "Personal",
          key: "personal",
          value: "$100",
        },
      ],
      colThree: [
        {
          title: "Office Expenses",
          key: "officeExpense",
          value: "$100",
        },
        {
          title: "Vehicle Expenses",
          key: "vehicleExpense",
          value: "$100",
        },
        {
          title: "Grooming",
          key: "grooming",
          value: "$100",
        },
        {
          title: "Mileage/Commuting",
          key: "milleageCommuting",
          value: "$100",
        },
      ],
    },
    {
      subtitle: "miscellaneousDeduction",
      colOne: [
        {
          title: "Prior Yr Tax Prep Fees",
          key: "priorYearTaxPreparationFees",
          value: "$100",
        },
        {
          title: "Professional/Assoc Dues",
          key: "AssocDues",
          value: "$100",
        },
      ],
      colTwo: [
        {
          title: "Casualty Loss",
          key: "casualtyLoss",
          value: "$100",
        },
        {
          title: "Alimony Paid",
          key: "alimonyPaid",
          value: "$100",
        },
      ],
      colThree: [
        {
          title: "Legal Fees",
          key: "legalFees",
          value: "$100",
        },
        {
          title: "Energy Credit Exp",
          key: "energyCreditExpense",
          value: "$100",
        },
      ],
    },
    {
      subtitle: "dayCare",
      colOne: [
        {
          title: "Daycare Expenses",
          key: "expense",
          value: "$100",
        },
        {
          title: "Daycare Address",
          key: "city",
          value: "$100",
        },
        {
          title: "Daycare Tax ID#",
          key: "taxId",
          value: "$100",
        },
        {
          title: "Daycare Name",
          key: "name",
          value: "$100",
        },
      ],
    },
  ],
};
