// export const API_BASE_URL = "https://60f4-2400-adc5-434-d000-d897-fee6-edcc-f680.ngrok-free.app";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.remedytaxes.com";

export const API_ROUTES = {
	// client urls
	clientDashboard: "/client/dashboard",
	staffClientAdd8879Form: "/staff-client-module/add/8879-form",
	clientLogin: "/auth/client/login",
	generalBusinessGetAll: "/client/business-collection/general-business/getAll",
	rentalPropertyGetAll:
		"/client/business-collection/rental-property-main/getAll",
	reviewPendingForms: "/client/review-submission/get-pending-review-forms",
	fethc1099Forms: "/client/review-submission/check-1099-worksheet-confirmation",
	taxReturnGetAll: "/client/tax-return/getAll",
	truckDriverGetAll: "/client/business-collection/truck-driver/getAll",
	clientRegister: "/auth/client/register",
	accountOTPVerify: "/auth/account/otp/verify",
	loginOTPVerify: "/auth/user/verify/login",
	switchProfile: "/client/switch-profile",
	accountOTPEmail: "/auth/account/otp/email",
	uploadTaxDocs: "/client/uploaded-tax-docs/getAll",
	addPreSheet: "/client/personal-collection/pre-sheet/add",
	updatePass: "/client/setting/update-password",
	downloadDoc: "/client/review-submission/get-submit-forms",
	nonProfitFormation: "/client/formation-services/non-profit-formation/add",
	updateNonProfit: "/client/formation-services/non-profit-formation/update",
	getNonFormation: "/client/formation-services/non-profit-formation/get",
	rentalProperty: "/client/business-collection/rental-property/add",
	rentalPropertyMain: "/client/business-collection/rental-property-main/add",
	updateRentalProperty: "/client/business-collection/rental-property/update",
	uploadRentalPropertyDoc: "/client/business-collection/rental-property/upload",
	UpdateRentalPropertyMain:
		"/client/business-collection/rental-property-main/update",
	getRentalProperty: "/client/business-collection/rental-property-main/get",
	getPersonalCollectionList: "/client/personal-collection/getAll",
	getBusinessFormationList:
		"/client/formation-services/business-formation/getAll/listing",
	getWorkSheetList: "/client/formation-services/1099-worksheet/listing",
	getNonProfitList: "/client/formation-services/non-profit-formation/listing",
	updatePersonalCollection: "/client/personal-collection/update",
	unlockAssignedClients: "/queues/client/re-assign/for-review",
	unlockNotassignedClients:
		"/queues/client/re-assign/un-assigned-forms/for-review",
	getPrepSheet: "/client/personal-collection/pre-sheet/get",
	updatePrepSheet: "/client/personal-collection/pre-sheet/update/",
	deleteDepentInfo: "/client/personal-collection/pre-sheet/delete/dependent",
	addHealthCare: "/client/personal-collection/health-care/add",
	getHealthCare: "/client/personal-collection/health-care/get",
	updateHealthCare: "/client/personal-collection/health-care/update",
	addRetirement: "/client/personal-collection/retirement/add",
	updateRetirement: "/client/personal-collection/retirement/update",
	getRetirement: "/client/personal-collection/retirement/get",
	addAdjustment: "/client/personal-collection/adjustment/add",
	getAdjustment: "/client/personal-collection/adjustment/get",
	updateAdjustment: "/client/personal-collection/adjustment/update",
	addItemizeDeduction: "/client/personal-collection/itemized-deductions/add",
	getItemizeDeduction: "/client/personal-collection/itemized-deductions/get",
	updateItemizeDeduction:
		"/client/personal-collection/itemized-deductions/update",
	addAdditionalDoc: "/client/personal-collection/upload-docs/add",
	updateAdditionalDoc: "/client/personal-collection/upload-docs/update",
	getAdditionalDoc: "/client/personal-collection/upload-docs/get",
	addAditionalNotes: "/client/personal-collection/update",
	getAditionalNotes: "/client/personal-collection/get",
	updateAditionalNotes: "/client/personal-collection/update",
	addBusinessInfo: "/client/business-collection/businessInfo/add",
	updateBusinessInfo: "/client/business-collection/businessInfo/update",
	getBusinessInfo: "/client/business-collection/businessInfo/get",
	addExpense: "/client/business-collection/expenses/add",
	updateExpense: "/client/business-collection/expenses/update",
	addFixedAssets: "/client/business-collection/fixed-assets/add",
	updateFixedAssets: "/client/business-collection/fixed-assets/update",
	addVehicleInfo: "/client/business-collection/vehicle-info/add",
	updateVehicleInfo: "/client/business-collection/vehicle-info/update",
	addBusinessUseHome: "/client/business-collection/business-use-home/add",
	updateBusinessUseHome: "/client/business-collection/business-use-home/update",
	addGeneralBusiness: "/client/business-collection/general-business/add",
	uploadGeneralBusiness: "/client/business-collection/upload",
	updateGeneralBusiness: "/client/business-collection/general-business/update",
	getGeneralBusinessByID: "/client/business-collection/general-business/get/",
	getBusinessFormationById:
		"/client/formation-services/business-formation/get/",

	SubmitAllForms: "/client/review-submission/submitAll",
	SubmitBusinessFormationForms:
		"/client/formation-services/business-formation/submit-forms",
	SubmitWorksheetForms:
		"/client/formation-services/1099-worksheet/submit-forms",
	SubmitNonProfitForms:
		"/client/formation-services/non-profit-formation/submit-forms",
	BusinessFormation: "/client/formation-services/business-formation/add",
	updateBusinessFormation:
		"/client/formation-services/business-formation/update/",
	getBusinessFormation: "/client/formation-services/business-formation/get",

	addworkSheet: "/client/formation-services/1099-worksheet/add",
	updateWorksheet: "/client/formation-services/1099-worksheet/update",
	getworksheet: "/client/formation-services/1099-worksheet/get",

	// Truck Driver APIs Endpoints
	addBasicInfo: "/client/business-collection/truck-driver/basic-info/add",
	getBasicInfo: "/client/business-collection/truck-driver/basic-info/get",
	updateBasicInfo: "/client/business-collection/truck-driver/basic-info/update",
	uploadTruckDriverDoc: "/client/business-collection/truck-driver/upload",

	addFee: "/client/business-collection/truck-driver/fee/add",
	getFee: "/client/business-collection/truck-driver/fee/get",
	updateFee: "/client/business-collection/truck-driver/fee/update",

	addMaintenance: "/client/business-collection/truck-driver/maintenance/add",
	getMaintenance: "/client/business-collection/truck-driver/maintenance/get",
	updateMaintenance:
		"/client/business-collection/truck-driver/maintenance/update",

	addLaborTax: "/client/business-collection/truck-driver/labor-taxes/add",
	getLaborTax: "/client/business-collection/truck-driver/labor-taxes/get",
	updateLaborTax: "/client/business-collection/truck-driver/labor-taxes/update",

	addInsurance:
		"/client/business-collection/truck-driver/insurance-interest/add",
	getInsurance:
		"/client/business-collection/truck-driver/insurance-interest/get",
	updateInsurance:
		"/client/business-collection/truck-driver/insurance-interest/update",

	addRoadExp: "/client/business-collection/truck-driver/road-exp/add",
	getRoadExp: "/client/business-collection/truck-driver/road-exp/get",
	updateRoadExp: "/client/business-collection/truck-driver/road-exp/update",

	addOfficeExp: "/client/business-collection/truck-driver/office-exp/add",
	getOfficeExp: "/client/business-collection/truck-driver/office-exp/get",
	updateOfficeExp: "/client/business-collection/truck-driver/office-exp/update",

	addUtilities:
		"/client/business-collection/truck-driver/rent-lease-utilities/add",
	getUtilities:
		"/client/business-collection/truck-driver/rent-lease-utilities/get",
	updateUtilities:
		"/client/business-collection/truck-driver/rent-lease-utilities/update",
	getTruckDriver: "/client/business-collection/truck-driver/get/",
	updateProfile: "/client/update-profile",

	truckDriverAdditionalNotes: "/client/business-collection/truck-driver/update",

	// Admin APIS URLS

	updateAdminPassword: "/user/setting/password-update/admin-staff",
	updateAdminProfile: "/user/profile-update/admin-staff",
	registerAdminSide: "/auth/user/register",

	getPeronalCollection: "/client/personal-collection/get",
	getIndividualTaxFiling: "/queues-report/tax-filling/individual",
	getBusinessTaxFiling: "/queues-report/tax-filling/business",
	getAdminAllTaxFiling: "/queues-report/tax-filling/all",
	getQueueReports: "/queues-report/queues/all",
	addNewTask: "/queues/add",
	assignTask: "/queues/assign-client-and-its-forms-to-staff",

	getPerosnalCollection: "/client/personal-collection/get/",
	getAllClients: "/admin-client-module/all/clients",
	// clientIndividuals: "/admin-client-module/individuals/getAll",
	// clientBusinessAll: "/admin-client-module/business/getAll",
	AdminQueuesAllForms: "/queues/get-all-clients-and-their-all-forms",
	AdminQueuesUnassignForms:
		"/queues/get-all-clients-and-their-unassigned-forms",
	AdminQueuesFormationForms: "/queues/client/formation-requests",

	AdminQueuesAssignByMe: "/queues/get-all-clients-and-their-assigned-forms",
	UpdateFormState: "/queues/client/update-forms-state",
	queuesClients: "/queues/clients",
	clientReviewSubmitionForms: "/client/review-submission/get-submit-forms",
	AllUsers: "/user/all",
	queuesReportTaxFilling: "/queues-report/tax-filling/all",
	queuesReportQueues: "/queues-report/queues/all",
	queuesReportClientActivity: "/queues-report/client-activity",
	queuesReportStaffActivity: "/queues-report/staff-activity",
	userManagementUpdate: "/user/profile-update/admin-staff",
	supportTicketAdd: "/support/ticket/add",
	userLogin: "/auth/user/login",
	resetEmail: "/auth/reset-password/email",
	resetPasswordOTP: "/auth/reset-password/otp/verify",
	updateUserPassword: "/auth/update/password",
	userAdminDashboard: "/user/admin/dashboard",
	supportTicketUpdate: "/support/ticket/update",
	supportTicketAdminGetAll: "/support/ticket/admin/getAll",
	deleteMessage: "/message/delete",
	searchEmail: "/message/getEmail",
	taxFilling: "/queues-report/queues/get-all-client-and-their-forms",
	updateStatus: "/queues-report/queues/update-status-of-queue",
	assignToData: "/queues/get-staff-by-id",
	// Staff APIS URLS

	staffQueues: "/queues/staff/get-all",
	staffDashboard: "/user/staff/dashboard",
	queuesreportstaff: "/queues-report/staff/specific",
	taxfillingreportstaff: "/queues-report/staff/specific/tax-filling",
	queuesStatusUpdate: "/queues/status/update",
	staffClientPersonalGetAll: "/staff-client-module/personal/getAll",
	staffClientBusinessGetAll: "/staff-client-module/business/getAll",
	messageGetAll: "/message/getAll",
	messageAdd: "/message/add",
	supportTicketStaffGetAll: "/support/ticket/staff/getAll",
	getAllocatedTaskQueueStaff:
		"/queues/staff/get-assigned-client-and-its-forms-to-staff",

	addChatRequest: "/chat/addChatRequest",
	getChatRequests: "/chat/getChatRequests",
	getChatStaffs: "/chat/getAllStaff",
	chatWithLuca: "/luca/chatwithluca",
};
