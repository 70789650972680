import styled from "styled-components";

export const ChatPopContainer = styled.div`
  position: fixed;
  bottom: -10px;
  right: -20px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const PopupTextSec = styled.div`
  position: relative;
  right: -25px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const PopupHeading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 5px;
  width: 70%;
`;

export const BotPhoto = styled.div`
  cursor: pointer;
  img {
    width: 50%;
    height: auto;
    transition: 0.3s ease;
  }
  &:hover {
    img {
      width: 80%;
      overflow: hidden
    }
  }
`;
