import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  width: 100%;
  grid-gap: ${({ gridGap }) => gridGap};
  padding: 20px 0px;

`;
