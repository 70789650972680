import { Box, Grid, Typography } from '@mui/material';
import React from 'react'

const FormationBusiness = ({sheetData}) => {
  return (
    <>
       <Box
          sx={{
            background: "#EFF3FD99",
            padding: "20px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#0B2558",
              height: "40px",
              padding: "0 20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            Business Information
          </Box>

          <Grid container spacing={2} borderBottom={"1px solid #00000033"} padding={"10px 20px"}>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Filling Status
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.fillingStatus}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Preferred Name
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.preferedName}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Alternative Name
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.alternateName}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} borderBottom={"1px solid #00000033"} padding={"10px 20px"}>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Street
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.street}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  City
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.city}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  State
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.state}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} borderBottom={"1px solid #00000033"} padding={"10px 20px"}>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Zip Code
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.zipCode}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Business Description
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.businessDescription}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Current Corporation Name
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {sheetData?.currentCorporationName}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        {sheetData?.personBusinessInfo.map((item, i) => {
          return (
            <>
              <Box
                sx={{
                  background: "#EFF3FD99",
                  padding: "20px",
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    background: "#0B2558",
                    height: "40px",
                    padding: "0 20px",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  Owner {i == 0 ? "" : i + 1} Information  
                </Box>

                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        First Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.firstName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Middle Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.middleName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Last Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.lastName}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Title
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.title}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Mobile Number
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.mobileNumber}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.street}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        City
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.city}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.street}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.zipCode}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
    </>
  )
}

export default FormationBusiness