import { NavbarContainer } from "../../../layout/navbar/Navbar.style";
import Notification from "../../../modals/Notification";
import NavRight2 from "../../../layout/navbar/NavRight2";

const AdminNavbar = ({ header }) => {
  // Ensure that Navbar2 receives header prop
  return (
    <NavbarContainer>
      {/* <Searchbar /> */}
      <h3>{header}</h3> {/* Display the header prop */}
      <NavRight2 is_admin setIsOpenModal={null} />
      <Notification />
    </NavbarContainer>
  );
};

export default AdminNavbar;
