import { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import CustomTable from "components/reusable/customTable/CustomTable";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import { toast } from "react-toastify";
import detail from "assets/client/detail.png";
import deleteIcon from "assets/client/delete-circle.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { Box, Button, Divider, Typography } from "@mui/material";
import FlexComp from "components/reusable/flex/Flex";

const errorToast = (message = "") => toast.error(message);

const AdminMessages = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("0");
  const [tab_loader, setTabLoader] = useState(true);
  const [selectedOption, setSelectedOption] = useState("All Sent Messages");
  const [showDetails, setShowDetails] = useState(false);
  const [individualRows, setIndividualRows] = useState([]);
  const [msgDetails, setMsgDetails] = useState();
  const [sideBarData, setSideBarData] = useState([
    { id: "0", value: "All Sent Messages", label: "0" },
    { id: "1", value: "Staff", label: "0" },
    { id: "2", value: "Client", label: "0" },
    { id: "3", value: "Admin", label: "0" },
    { id: "4", value: "Deleted Items", label: "0" },
  ]);
  const userId = localStorage.getItem("clientId");
  const [search, setSearch] = useState();
  const [data, setData] = useState([]);
  const fetchMessages = async () => {
    try {
      setTabLoader(true);
      const response = await apiReq(API_ROUTES.messageGetAll, "POST", {
        userId,
      });
      const messages = response.data.data || [];

      const rows = messages.map((message) => ({
        id: message._id,
        srno1: message.id,
        ssname: message.sendBy,
        sToName: message.sendTo,
        lname: message.subject,
        cd: new Date(message.createdAt).toLocaleDateString(),
        isDeleted: message.isDeleted,
        toEmail: message.toEmail,
        message: message.message,
      }));

      setIndividualRows(rows);
      setData(rows);

      {
        console.log(messages, "messagesssss");
      }

      // Update sidebar data with message counts
      const messageCounts = {
        "All Sent Messages": messages.filter((message) => !message.isDeleted).length,
        "Staff": messages.filter(
          (message) => message?.sendTo === "Staff" && !message.isDeleted
        ).length,
        "Client": messages.filter(
          (message) => message?.sendTo === "Client" && !message.isDeleted
        ).length,
        "Admin": messages.filter(
          (message) => message?.sendBy === "Admin" && !message.isDeleted
        ).length,

        "Deleted Items": messages.filter(
          (message) => message.isDeleted === true
        ).length,
      };

      setSideBarData((prevState) =>
        prevState.map((item) => ({
          ...item,
          label: messageCounts[item.value].toString(),
        }))
      );
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line
  }, []); // Empty dependency array to run once on mount
  const handleSearch = (selectedValues) => {
    setSearch(selectedValues.target.value);
  };

  const handleDelete = async (id) => {
    try {
      await apiReq(API_ROUTES.deleteMessage, "POST", {
        messageId: id,
        isDeleted: true,
      });
      toast.success("Message deleted successfully");

      setIndividualRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setData((prevData) => prevData.filter((item) => item.id !== id));
      fetchMessages();
    } catch (error) {
      errorToast(
        error?.message ||
          error?.response?.message ||
          "Error deleting message. please try again later."
      );
    }
  };

  useEffect(() => {
    let filteredData = data;
    filteredData = filteredData?.filter((item) =>
      item.ssname.toLowerCase().includes(search.toLowerCase())
    );
    setIndividualRows(filteredData);

    // eslint-disable-next-line
  }, [search]);
  const individualColumns = [
    {
      field: "ssname",
      headerName: "From",
      width: 100,
      editable: false,
    },
    {
      field: "toEmail",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "lname",
      headerName: "Subject",
      width: 250,
      editable: false,
    },
    {
      field: "cd",
      headerName: "Received",
      width: 180,
      editable: false,
    },
    {
      headerName: "Action",
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setMsgDetails(params.row);
              setShowDetails(true);
              console.log(params.row, "paramsRow");
            }}
          >
            <img src={detail} alt="detail" />
          </Button>
          {selectedOption !== "Deleted Items" && (
            <Button
              variant="text"
              color="primary"
              onClick={() => handleDelete(params.row.id)}
            >
              <img src={deleteIcon} alt="detail" />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const renderTable = () => {
    let tableRows = [];
    let tableHeading = "";

    switch (selectedOption) {
      case "All Sent Messages":
        tableRows = individualRows.filter((row) => !row.isDeleted);
        tableHeading = "All Sent Messages";
        break;
      case "Staff":
        // tableRows = individualRows;
        tableRows = individualRows.filter(
          (row) => row.sToName === "Staff" && !row.isDeleted
        );
        tableHeading = "Staff";
        break;
      case "Client":
        // tableRows = individualRows;
        tableRows = individualRows.filter(
          (row) => row.sToName === "Client" && !row.isDeleted
        );
        tableHeading = "Client";
        break;
      case "Admin":
        // tableRows = individualRows;
        tableRows = individualRows.filter(
          (row) => row.ssname === "Admin" && !row.isDeleted
        );
        tableHeading = "Admin";
        break;
      case "Deleted Items":
        tableRows = individualRows.filter((row) => row.isDeleted === true);
        tableHeading = "Deleted Items";
        break;

      // Add cases for other options as needed
      default:
        break;
    }
    console.log(individualRows, "Individual Rows");

    return (
      <div>
        <HeadingTwo>{tableHeading}</HeadingTwo>
        <CustomTable
          rows={tableRows}
          loading={tab_loader}
          columns={individualColumns}
        />
      </div>
    );
  };

  const handleSelect = (id, value) => {
    setActive(id);
    setSelectedOption(value); // Update selected option
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton
          btntext="+ Compose New"
          clickHandler={() => navigate("/admin-compose")}
          showFirstSelect={false}
          showSecondSelect={false}
          placeholder="Select Status"
          onChange={handleSearch}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />

        <RentalPropertyWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} lg={3}>
              <SideBoxStyle>
                {sideBarData.map((item) => (
                  <SideBoxButtonStyle
                    key={item.id}
                    className={active === item.id ? "active" : ""}
                    onClick={() => {handleSelect(item.id, item.value); setShowDetails(false)}}
                  >
                    <SideBoxTitleStyle>{item.value}</SideBoxTitleStyle>
                    {item.label && <div>{item.label}</div>}
                  </SideBoxButtonStyle>
                ))}
              </SideBoxStyle>
            </Grid>
            <Grid item xs={8} lg={8}>
              {!showDetails && <>{renderTable()}</>}
              {showDetails && <>
                  <Box >
                    <FlexComp alignItems="center" gap="4px">
                    <Typography sx={{fontSize: "25px", fontWeight: "400", color: "#000"}}>
                     View Message
                     </Typography>
                     <Typography sx={{fontSize: "18px", fontWeight: "400", color: "#000"}}>
                     ({msgDetails?.ssname})
                     </Typography>
                    </FlexComp>
                    <Divider sx={{margin: "12px 0"}} />
                    <Typography sx={{fontSize: "18px", fontWeight: "400", color: "#1B1B1B"}}>
                     {msgDetails?.lname}
                     </Typography>
                    <Divider sx={{margin: "12px 0"}} />
                    <Typography sx={{fontSize: "18px", fontWeight: "400", color: "#1B1B1B"}}>
                     <div dangerouslySetInnerHTML={{ __html: msgDetails.message }} />
                     </Typography>
                  </Box>
              </>}
            </Grid>
          </Grid>
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default AdminMessages;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  //min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

const SideBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  background: #eff3fd;
  width: 100%;
  padding: 20px;
  min-height: 50vh;
`;

const SideBoxButtonStyle = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  /* Add styles for the active tab */
  &.active {
    background-color: rgba(
      0,
      173,
      239,
      1
    ); /* Adjust the background color as needed */
    color: white;
  }
`;

const SideBoxTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
const HeadingTwo = styled.h2`
  font-weight: 400;
  font-size: 25px;
  padding: 10px;
`;
