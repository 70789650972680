import { createContext, useState } from 'react'

export const PageLoaderContext = createContext();

export const PageLoaderProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => setOpen(prev => !prev);

  return (
    <PageLoaderContext.Provider value={{open, setOpen, handleToggle}}>
        {children}
    </PageLoaderContext.Provider>
  )
}
