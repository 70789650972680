import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomTable from "../../../components/reusable/customTable/CustomTable";
import { Button } from "@mui/material";
import StaffHeader from "../../../components/reusable/header/StaffHeader";
import { Line } from "../../../components/client/rentalProperty/RentalProperty.style";
import ValidateTaxModal from "../../../components/modals/ValidateTaxModal";
import PersonalCollection from "../../../components/modals/review/ReviewConfirmation/PersonalCollection";
import GeneralBusinessModal from "../../../components/modals/staffModals/GernalBusinessModal";
import DateComponent from "../../../components/reusable/datecomponent/DateComponent";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import axios from "axios";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
const TaxFilling = () => {

  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false); // State to manage row selection
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tableData, setTableData] = useState([]);
  const user = JSON.parse(localStorage.getItem('userData'));
  const staffId =user?.user?._id
  console.log(staffId, 'staff')
  const [date, setDate] = useState({ startDate: "", endDate: "", staffId: staffId});
  const [search, setsearch] = useState();
  const [originaldata, setoriginaldata] = useState([]);
  const [loading,setLoading] = useState(false);
  const fetchData = async () => {
    try {
      
      if(startDate&&!endDate){
        toast.error("Please Select End date")
        return
      }
      else if(!startDate&&endDate){
        toast.error("Please Select Start date")
        return
      }
      
      const isStartDateAfterEndDate = startDate > endDate;
      if (isStartDateAfterEndDate) {
        toast.error("End date cannot be before the start date")
        return
      } 
      setLoading(true);
        const response = await apiReq(API_ROUTES.taxfillingreportstaff, "POST", date)

        const responseData = await response?.data;


        const updatedData = responseData?.data?.map((item, index) => {
          return {
            id: index + 1,
            sr: index + 1,
            cname: item?.clientId?.firstName + " " + item?.clientId?.lastName,
            bname: item?.formType,
            assignto: item?.assignTo?.firstName + " " + item?.assignTo?.lastName,
            updateby: "_",
            createdat: item?.createdAt,
            updateon: item?.updatedAt,
            priority: item?.priority,
            status: item?.status,
            otherDetails: item

          }
        });
        setTableData(updatedData)
        setoriginaldata(updatedData)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (!startDate && !endDate) {
      setDate({startDate: "", endDate: "", staffId: staffId });
    } else {
    setDate({ startDate: startDate, endDate: endDate, staffId: staffId })
    }
  }, [startDate, endDate])
  const formattedDate = (value) => {
    const date = dayjs(value);
    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  };
  useEffect(() => {
    let filteredData = originaldata;
    if (search)
      filteredData = filteredData.filter(item => item.cname.toLowerCase().includes(search.toLowerCase()));

    setTableData(filteredData);
  }, [search]);


  const individualColumns = [
    {
      field: "sr",
      headerName: "Sr#",
      width: 100,
      editable: false,
    },
    {
      field: "cname",
      headerName: "Client Name",
      width: 200,
      editable: false,
    },
    {
      field: "bname",
      headerName: "Business Name",
      width: 200,
      editable: false,
    },

    {
      field: "assignto",
      headerName: "Assigned To",
      width: 200,
      editable: false,
    },
    {
      field: "updateby",
      headerName: "Updated By",
      width: 200,
      editable: false,
    },
    {
      field: "createdat",
      headerName: "Created Date",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>{formattedDate(params.value)}</div>
      )
    },
    {
      field: "updateon",
      headerName: "Modified Date",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>{formattedDate(params.value)}</div>
      )
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
    },
    // {
    //   // field: "comments",
    //   headerName: "Action",
    //   width: 100,
    //   editable: false,
    //   renderCell: (params) => (
    //     <div>
    //       <Button
    //         variant='text'
    //         color='primary'
    //         onClick={() => setGeneralBusinessModal(true)}
    //       >
    //         <img src={detail} alt='detail' />
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const individualRows = [
    {
      id: 1,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "heigh",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
    {
      id: 2,
      srno1: 1,
      ssname: "****1234",
      ftype: "abc",
      lname: "Mehmood",
      fname: "Danish",
      form: "Pending",
      tr: "Jonathan",
      enddate: "16/02/2024",
      cd: "16/02/2024",
      form1: "low",
      assignby: "Jonathan",
      updateby: "Jonathan",
      updateon: "monday",
    },
  ];

  const options = [
    { label: "Select ALL", value: "Select All" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Staff Review", value: "Staff Review" },
  ];
  const handleSelectChange = (selectedValues) => {
    // Handle select change logic
    console.log("select value")
  }




  const handleButtonClick = () => {
    // Button click logic
    if (tableData?.length > 0) {
      generatePDF();
    } else {
      // Handle the case when tableData is not available yet
      console.log("Table data is not available yet.");
    }
  };

  const ExlhandleButtonClick = () => {
    // Button click logic
    generateExcel();
  };
  const generatePDF = () => {
    const doc = new jsPDF();
    const tableRows = tableData.map(row => Object.values(row));
    const tableColumns = individualColumns.map(column => column.headerName);
    doc.autoTable({
      head: [tableColumns],
      body: tableRows
    });
    doc.save('table.pdf');
  };
  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table.xlsx");
  };
  const handleonChange = (e) => {
    setsearch(e.target.value);
  }

  const searchStaff = async (searchValue) => {
    try {
      console.log(searchValue);
      const filteredAssignData = originaldata.filter((item) => {
        return (
          item?.cname?.toLowerCase().includes(searchValue?.toLowerCase()) 
          // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      });
    
      setTableData(filteredAssignData);
     // Adjust page size if needed
    } catch (error) {
     
    }
  };


  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          showFirstSelect={false}
          showSecondSelect={false}
          showButton={false}
          showSecondButton={false}
          showThirdButton={true}
          clickHandler1={handleButtonClick}
          clickHandler2={ExlhandleButtonClick}
          placeholder="Search by Status"
          placeholder2="Search by Form Type"
          options={options}
          btntext1="PDF"
          btntext2="Excel"
          onSelectChange={handleSelectChange}
          onChange={handleonChange}
          searchStaff={searchStaff}
        />
        <Line border='0.0999999999999999px solid rgba(239, 243, 253, 1)' />
        <Button />
        <DateComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleButtonClick={fetchData} 
          loading={loading}
        />
        <RentalPropertyWrapper>
          <CustomTable
            rows={tableData}
            enableRowSelection={enableRowSelection}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph='Are you sure you want to submit for review?'
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
      <GeneralBusinessModal
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      />
      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
    </>
  );
};

export default TaxFilling;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
