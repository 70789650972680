import React from "react";
import Modal from "@mui/material/Modal";
import {
  ValidateTaxBtnWrapper,
  ValidateTaxContainer,
  ValidateTaxContainer1,
} from "./Modals.style";
import { Span } from "../reusable/typography/Typography.style";
import { Button } from "../reusable/button/Button";
import Warning from "../../assets/modal/Warning.png";
import { Box, Grid, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";

const FormationPreviewModal = (props) => {
  console.log(props.modalData, "propers");
  return (
    <Modal
      open={props?.isModalOpen}
      onClose={props?.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ValidateTaxContainer1>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
          <Typography sx={{fontSize: "20px", fontWeight: "600", color: "#000"}}>
             Business Formation
          </Typography>
          <a href="javascript:void(0)" onClick={props.closeModal} style={{fontSize: "20px", color: "#000"}}>
          <IoClose />

          </a>
        </Box>
        <Box
          sx={{
            background: "#EFF3FD99",
            padding: "20px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#0B2558",
              height: "40px",
              padding: "0 20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            Business Information
          </Box>

          <Grid container spacing={2} borderBottom={"1px solid #00000033"} padding={"10px 20px"}>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Filling Status
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.fillingStatus}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Preferred Name
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.preferedName}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Alternative Name
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.alternateName}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} borderBottom={"1px solid #00000033"} padding={"10px 20px"}>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Street
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.street}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  City
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.city}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  State
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.state}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} borderBottom={"1px solid #00000033"} padding={"10px 20px"}>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Zip Code
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.zipCode}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Business Description
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.businessDescription}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#1B1B1B" }}
                >
                  Current Corporation Name
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#1B1B1B" }}
                >
                  {props?.modalData?.currentCorporationName}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        {props?.modalData?.personBusinessInfo.map((item, i) => {
          return (
            <>
              <Box
                sx={{
                  background: "#EFF3FD99",
                  padding: "20px",
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    background: "#0B2558",
                    height: "40px",
                    padding: "0 20px",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  Owner {i == 0 ? "" : i + 1} Information  
                </Box>

                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        First Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.firstName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Middle Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.middleName}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Last Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.lastName}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Title
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.title}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Mobile Number
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.mobileNumber}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.street}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        City
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.city}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.street}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >{item?.zipCode}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
        <Box width={"100%"} textAlign={"end"}>
   
          <Button
            text="Close"
            width="150px"
            fontSize="20px"
            backgroundColor="#00ADEF"
            padding="10px"
            color="#AEB6CF"
            onClick={props?.closeModal}
          />
          {/* <Button
            text="Yes"
            width="150px"
            type="submit"
            fontSize="20px"
            backgroundColor="#0B2558"
            padding="10px"
            color="#FFFFFF"
            onClick={() => {
              props?.closeModal();
            }}
            // onClick={() => {
            // props?.closeModal();
            // props?.setGeneralBusinessModal &&
            //   props?.setGeneralBusinessModal(!props?.generalBusinessModal);
            // }}
          /> */}
          </Box>
      </ValidateTaxContainer1>
    </Modal>
  );
};

export default FormationPreviewModal;
