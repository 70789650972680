import { useState, useEffect } from "react";
import styled from "styled-components";
import SummaryComp from "components/client/dashboard/Summary";
import RequiredTask from "components/client/dashboard/RequiredTask";
import ReturnStats from "components/client/dashboard/ReturnStats";
import BusinessProfileModal from "components/modals/BusinessProfileModal";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [userData, setUserData] = useState(null);
  const fetchData =async()=>{
  const clientId = localStorage.getItem("clientId");
  try{
  if (clientId) {
   const response= await apiReq(API_ROUTES.clientDashboard, "POST", { clientId })
   

      
          setUserData(response.data.data);
    }
  }
      catch(error) {
        console.error("Error fetching user data:", error);
      };
    }
  useEffect(() => {
    fetchData()
  }, []);

  return (
    <>
      <DashboardContainer>
        {/* ---TopSection--- */}
        {userData && <SummaryComp userData={userData} />}
        {/* ---BottomSection--- */}
        <BottomSec>
          {userData && <RequiredTask userData={userData} />}
          {userData && <ReturnStats userData={userData} />}
        </BottomSec>
        {isModalOpen && (
          <BusinessProfileModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            // switchProfile={switchProfile}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default Dashboard;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  width: 100%;
`;

const BottomSec = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
