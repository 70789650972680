import styled from "styled-components";

export const Paragraph = styled.p`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const Span = styled.span`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
  cursor: ${props => props.cursor || "inherit"};
  padding-bottom:${(props) => props.paddingBottom || ""};
  @media screen and (max-width: 1800px) {
    font-size: ${(props) => props.eighteenFontSize || ""};
  }
  @media screen and (max-width: 1700px) {
    font-size: ${(props) => props.seventeenFontSize || ""};
  }
  @media screen and (max-width: 1600px) {
    font-size: ${(props) => props.sixteenFontSize || ""};
  }
  @media screen and (max-width: 1400px) {
    font-size: ${(props) => props.fourteenFontSize || ""};
  }
  @media screen and (max-width: 1300px) {
    font-size: ${(props) => props.thirteenFontSize || ""};
  }
`;

export const HeadingOne = styled.h1`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const HeadingTwo = styled.h2`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const HeadingThree = styled.h3`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const HeadingFour = styled.h4`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const HeadingFive = styled.h5`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "24px"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  text-align: ${(props) => props.textAlign || "left"};
  padding-bottom:${(props) => props.paddingBottom || ""};
  padding-top:${(props) => props.paddingTop || ""};
`;
