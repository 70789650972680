import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const RentalData = ({ clientData }) => {
  console.log(clientData, "personalData");
  const { rentalPropertyId } = clientData;
  return (
    <div>
      <Box
        sx={{ background: "#EFF3FD", borderRadius: "12px", padding: "20px" }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Property Address
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Street
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.street}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              City{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.city}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              State{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.state}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Zip Code{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {rentalPropertyId?.address?.zipCode}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Date Place Service{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.datePlacedService}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Fair Market Value
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.fairMarketValue}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Fair Rental Days
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.fairRentalDays}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Property Type
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.propertyType}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Purchase Price
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.purchasePrice}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Rent Charge
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.address?.rentCharge}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Income
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Deposit
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.income?.deposit}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Late Fees{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.income?.lateFees}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              Rental Income{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.income?.rentalIncome}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#EFF3FD",
          borderRadius: "12px",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: "#0B2558",
            fontSize: "18px",
            fontWeight: "600",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          Expenses
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Management Fees
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.managementFees}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Advertising for Tenants
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.advertisingForTenants}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Association Dues (HOA)

            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.associationDues}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Cleaning & Maintenace
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.cleaningAndMaintenance}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Repairs & Maintenance

            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.repairsAndMaintenance}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Insurance

            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.insurance}
            </Typography>
          </Grid>
    
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Property Tax

            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.propertyTax}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Utilities

            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.utilities}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Water Charges


            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.waterCharges}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
            Misc. Fees


            </Typography>
            <Typography fontSize={"16px"} fontWeight={"400"}>
              {" "}
              {rentalPropertyId?.expenses?.miscFees}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RentalData;
