import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormHelperText, Stack } from "@mui/material";
import { Button } from "components/reusable/button/Button";
import { ButtonIcon } from "components/reusable/button/IconButton";
import FlexComp from "components/reusable/flex/Flex";
import {
  InputCheckboxBtn,
  InputWithAdornment,
} from "components/reusable/input/Inputs";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { PageLoaderContext } from "context/PageLoaderProvider";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { AdjustmentContainer } from "./PersonalCollection.style";
import { adjustmentSchema } from "./Schema";

const initialValues = {
  iraContributions: "",
  teacherExpense: "",
  studentLoanInterest: "",
  estimatedTaxes: "",
  studentLoanDoc: "",
  educationExpenseCheck: false,
  educationExpense: "",
  alimoneyPaid: "",
  studentLoanDocFilename: null,
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const Adjustments = ({ setCurrentTab, rowData: businessData }) => {
  const [edit, setEdit] = useState(false);
  const [check, setCheck] = useState("No");
  const [dlDoc, setDlDoc] = useState(null);
  const clientId = localStorage.getItem("clientId");
  const { setOpen } = useContext(PageLoaderContext);
  const personalCollectionId = localStorage.getItem("personalCollectionId");
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const [adjustmentsId, setAdjustmentId] = useState(null);

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    isSubmitting,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: adjustmentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!clientId) {
          errorToast("Client Id Not Found.");
          return;
        }

        // New validation for mandatory document upload
        if (values.educationExpenseCheck && !values.studentLoanDoc) {
          errorToast("1098 Document is required when selecting 'Yes'.");
          setSubmitting(false);
          return;
        }

        if (edit) delete values.clientId;

        const formData = new FormData();
        formData.append("clientId", clientId.toString());

        for (const [key, value] of Object.entries(values)) {
          if (key !== "studentLoanDocFilename") {
            formData.append(key, value);
          }
        }

        const id = (businessData?._id || personalCollectionId).replace(
          /"/g,
          ""
        );
        formData.append("personalCollectionId", id);
        if (edit && adjustmentsId) {
          formData.append("adjustmentsId", adjustmentsId);
        }
        await apiReq(
          `${edit ? API_ROUTES.updateAdjustment : API_ROUTES.addAdjustment}`,
          "POST",
          formData,
          API_BASE_URL,
          false
        );

        if (!edit) {
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, itemize_deduction: false })
          );
        }
        infoToast(
          edit
            ? "Adjustment info updated successfully"
            : "Adjustment info saved successfully"
        );
        setTimeout(() => {
          setCurrentTab(4);
        }, 2000);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchAdjustment = async () => {
      try {
        setOpen(true);
        const id = (businessData?._id || personalCollectionId).replace(
          /"/g,
          ""
        );
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getPerosnalCollection + id,
          "POST",
          { clientId }
        );
        if (Object.keys(data?.adjustmentsId || {})?.length > 0) {
          setValues({ ...data?.adjustmentsId });
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...menuTabs, itemize_deduction: false })
          );
          setCheck(data?.adjustmentsId?.educationExpenseCheck ? "Yes" : "No");
          setEdit(true);
          setAdjustmentId(data?.adjustmentsId?._id); // Store the retirementId
        }
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setOpen(false);
      }
    };
    fetchAdjustment();
  }, []);

  const fileChange = (file) => {
    setFieldValue("studentLoanDoc", file);
    setFieldValue("studentLoanDocFilename", file.name); // Set new filename
    setDlDoc(file);
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <AdjustmentContainer>
          <HeadingFive fontSize="18px" fontWeight="600" color="#1b1b1b">
            Adjustments
          </HeadingFive>
          <FlexComp
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            flexWrap="wrap"
            gridGap="20px"
          >
            <Stack sx={{ width: "49%" }}>
              <InputWithAdornment
                label="IRA Contributions "
                name="iraContributions"
                placeholder="Enter"
                defaultValue=""
                id="outlined-size-small"
                size="size"
                width="100%"
                onblur={handleBlur}
                value={values.iraContributions}
                onChange={(e) => {
                  let val = e.target.value.slice(0, 9);
                  setFieldValue("iraContributions", val);
                }}
              />
              <FormHelperText error>{errors.iraContributions}</FormHelperText>
            </Stack>
            <Stack sx={{ width: "49%" }}>
              <InputWithAdornment
                label="Teacher Expenses"
                placeholder="Enter"
                defaultValue=""
                id="outlined-size-small"
                size="size"
                width="100%"
                value={values.teacherExpense}
                onChange={(e) => {
                  let val = e.target.value.slice(0, 9);
                  setFieldValue("teacherExpense", val);
                }}
              />
              <FormHelperText error>{errors.teacherExpense}</FormHelperText>
            </Stack>
            <Stack sx={{ width: "49%" }}>
              <InputWithAdornment
                label="Student Loan Interest Paid"
                placeholder="Enter"
                defaultValue=""
                id="outlined-size-small"
                size="size"
                width="100%"
                value={values.studentLoanInterest}
                onChange={(e) => {
                  let val = e.target.value.slice(0, 9);
                  setFieldValue("studentLoanInterest", val);
                }}
              />
              <FormHelperText error>
                {errors.studentLoanInterest}
              </FormHelperText>
            </Stack>
            <Stack sx={{ width: "49%" }}>
              <InputWithAdornment
                label="Estimated Taxes "
                placeholder="Enter"
                defaultValue=""
                id="outlined-size-small"
                size="size"
                width="100%"
                value={values.estimatedTaxes}
                onChange={(e) => {
                  let val = e.target.value.slice(0, 9);
                  setFieldValue("estimatedTaxes", val);
                }}
              />
              <FormHelperText error>{errors.estimatedTaxes}</FormHelperText>
            </Stack>
            <Stack
              sx={{ width: "49%", position: "relative" }}
              direction="column"
            >
              <p>
                Do you have the 1098 Document to be Upload (Education Expense)
              </p>
              <InputCheckboxBtn
                remove={"none"}
                label="Education Expense (1098T Required) *"
                id="outlined-size-small"
                defaultValue="$1000"
                size="medium"
                width="100%"
                startAdornment="true"
                placeHolder="You can type here..."
                value={values.educationExpense}
                val={check}
                onFileSelect={fileChange}
                // onChange={(e) => {
                //   let val = e.target.value.slice(0, 9);
                //   setFieldValue("educationExpense", val);
                // }}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setCheck(isChecked ? "Yes" : "No");
                  setFieldValue("educationExpenseCheck", isChecked);

                  // Clear the file input if "No" is selected
                  if (!isChecked) {
                    setFieldValue("studentLoanDoc", "");
                    setFieldValue("studentLoanDocFilename", null);
                  }
                }}
                setCheckBox={setCheck}
                setFieldValue={setFieldValue}
                leftPo="0px"
                rightPo="auto"
                bottomPo="-51px"
                button={
                  <>
                    <div>
                      <input
                        type="file"
                        id="dlIssueByState"
                        onChange={(event) => {
                          const selectedFile = event.currentTarget.files[0];
                          if (selectedFile) {
                            fileChange(selectedFile); // Call the fileChange function with the new file
                          }
                        }}
                        style={{ display: "none" }}
                      />
                      <label htmlFor="dlIssueByState">
                        {values?.studentLoanDocFilename && (
                          <ButtonIcon
                            type="button"
                            width="100%"
                            icon={<CloudUploadIcon />}
                            text={values?.studentLoanDocFilename}
                            fontSize="13px"
                          />
                        )}
                        {!values?.studentLoanDocFilename && (
                          <ButtonIcon
                            type="button"
                            width="100%"
                            icon={<CloudUploadIcon />}
                            text="Upload 1098 Document"
                            fontSize="13px"
                          />
                        )}
                      </label>
                    </div>
                  </>
                }
              />
              <FormHelperText error>
                {errors?.educationExpense || errors?.studentLoanDoc}
              </FormHelperText>
            </Stack>
            <InputWithAdornment
              label="Alimony paid"
              id="outlined-size-small"
              defaultValue=""
              size="size"
              width="49%"
              placeholder="Enter"
              value={values.alimoneyPaid}
              onChange={(e) => {
                let val = e.target.value.replace(/\D/g, "").slice(0, 9);
                setFieldValue("alimoneyPaid", val);
              }}
            />
          </FlexComp>
          <Button
            text={
              isSubmitting ? <SyncLoader color={"#FFFFFF"} size={8} /> : "Next"
            }
            type="submit"
            width="15%"
            fontSize="18px"
            backgroundColor="#0B2558"
            alignSelf="end"
          />
        </AdjustmentContainer>
      </form>
    </>
  );
};

export default Adjustments;
