import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StackBarChart = ({data}) => {
  
  const months = Object?.keys(data?.monthlyCompletedQueue? data?.monthlyCompletedQueue:''); // Get the days of the week
  const chartData = months?.map(month => ({
    name: month.slice(0, 3),
    Completed: data?.monthlyCompletedQueue[month],
    Pending: data?.monthlyPendingQueue[month],
  }));
  return (
    <div>
      <style>
        {`
          .recharts-cartesian-grid {
            display: none;
          }
          .recharts-cartesian-axis-line {
            display: none;
          }
          .recharts-text {
            font-size: 10px;
          }
          .recharts-legend-item-text {
            font-size: 10px;
          }
          .recharts-legend-icon {
            width: 12px !important;
            height: 12px !important;
          }
        `}
      </style>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis  hide={true}/>
          <Tooltip />
          <Legend />
          <Bar dataKey="Pending" stackId="a" fill="rgba(11, 37, 88, 1)" barSize={12} radius={[2, 2, 2, 2]} />
          <Bar dataKey="Completed" stackId="a" fill="rgba(0, 173, 239, 1)" barSize={12} radius={[2, 2, 2, 2]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackBarChart;
