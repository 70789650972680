import React from "react";
import { GeneralBusinessBtnWrapper } from "./UploadTaxDocs.style";
import PdfUploadButton from "../../reusable/button/PdfUploadButton";
const RentalProperty =({ data }) => {
 

  const transformData = (data) => {
    return data
      .filter((item) => item.rentalDoc) // Filter out items without rentalDoc
      .map((item) => ({
        name: 'rentalDoc', // Use 'rentalDoc' as the name
        url: item.rentalDoc, // Use the value of rentalDoc as the URL
      }));
  };

  const documents = transformData(data);

    return (
      <GeneralBusinessBtnWrapper>
        <PdfUploadButton documents={documents} />
      </GeneralBusinessBtnWrapper>
    );
};


export default RentalProperty;
