import { useState } from "react";
import FlexComp from "../../reusable/flex/Flex";
import { Span } from "../../reusable/typography/Typography.style";
import { ButtonIcon } from "../../reusable/button/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SignPreviewBox } from "./Signature.style";
import { Button } from "components/reusable/button/Button";

const Upload = ({ onUploadFile, uploadedFile = {}, handleUploadedFileRemove }) => {

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      onUploadFile(file)
    }
  };

  const handleButtonClick = () => {
    // Trigger file input click programmatically
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  return (
    <FlexComp
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      gridGap="20px"
    >
      <FlexComp
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gridGap="20px"
      >
        <Span color="#000000" fontSize="18px" fontWeight="500">
          Preview
        </Span>
        {/* Clear button */}
        <Button
          text="Clear"
          width="12%"
          fontSize="18px"
          backgroundColor="#00ADEF"
          padding="5px 15px"
          borderRadius="5px"
          onClick={handleUploadedFileRemove}
        />
        {/* File input */}
        <input
          type="file"
          id="fileInput"
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        {/* Upload button */}
       
      </FlexComp>
      {/* Show file preview in SignPreviewBox */}
      <SignPreviewBox>
        {uploadedFile ? (
          uploadedFile.type.includes("image") ? (
            <img
              src={URL.createObjectURL(uploadedFile)}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            <Span color="#000000" fontSize="16px">
              {uploadedFile.name}
            </Span>
          )
        ) : (
          <Span color="#AEB6CF">No file selected</Span>
        )}
      </SignPreviewBox>
      <label htmlFor="fileInput">
          <ButtonIcon
            bgColor="#00ADEF"
            icon={<CloudUploadIcon />}
            text="Upload Document"
            fontSize="14px"
            clickHandler={handleButtonClick}
          />
        </label>
      <Span>
        By selecting Adopt and Sign, I agree that the signature and initials
        will be the electronic representation of my signature and initials for
        all purposes when I (or my agent) use them on documents, including
        legally binding contracts - just the same as a pen-and-paper signature
        or initial.
      </Span>
    </FlexComp>
  );
};

export default Upload;
