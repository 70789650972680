import { useState, useEffect } from "react";
import styled from "styled-components";
import TitleComponent from "components/reusable/titleComponent/TitleComponent";
import CustomTable from "components/reusable/customTable/CustomTable";
import AddNewBusiness from "components/modals/AddNewBusiness";
import { useNavigate } from "react-router-dom";
import UploadAndLoss from "components/modals/UploadAndLoss";
import edit from "assets/client/edit.png";
import detail from "assets/client/detail.png";
import { IconButton } from "@mui/material";
import TruckDriver from "components/modals/review/ReviewConfirmation/TruckDriver";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import ConfirmationModal from "components/modals/ConfirmationModal";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import AdditionalNotes from "components/modals/AdditionalNotes";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YYYY");
  return formattedDate;
};

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const TruckDriverList = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [tab_loader, setTabLoader] = useState(true);
  const clientId = localStorage.getItem("clientId");
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [TruckDriverData, setTruckDriverData] = useState([]);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [additionalNotes, setadditionalnotes] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoader(true);
        const response = await apiReq(API_ROUTES.truckDriverGetAll, "POST", {
          clientId,
        });

        const responseData = await response.data;

        const truckData = responseData.data.map((item, index) => ({
          id: item._id,
          comments: item.additionalNotes,
          formType: item.informationType,
          status: item.status,
          formProgress: item?.completionPercentage,
          date: item.createdAt,
          otherDetails: item,
        }));

        setTruckDriverData(truckData);
      } catch (error) {
        errorToast(
          error?.message ||
            error?.response?.message ||
            "Error fetching data. please try again later."
        );
      } finally {
        setTabLoader(false);
      }
    };
    fetchData();
    localStorage.removeItem("truckDriverMainId");
    // eslint-disable-next-line
  }, []);
  const truckDriverId = localStorage.getItem("formsId");

  const handleNotesSubmit = async (additionalNotes = "") => {
    try {
      await apiReq(
        API_ROUTES.truckDriverAdditionalNotes,
        "POST",
        { clientId, additionalNotes, truckDriverId },
        API_BASE_URL,
        false
      );
      setIsAdditionalModal(false);
      infoToast("Addiitonal notes saved successfully");
      setTimeout(() => {
        setIsValidateTaxModal(true);
      }, 2000);
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    }
  };

  const BGColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 180,
      editable: false,
      renderCell: (params) => (
        <div>{formattedDate(params.value)}</div> // Assuming you have a formattedDate function
      ),
    },
    {
      field: "formType",
      headerName: "Form Type",
      width: 200,
      editable: false,
    },
    {
      headerName: "Form Progress",
      field: "formProgress",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <div>{params.value}%</div> // Assuming you have a formattedDate function
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Data Entery"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(244, 184, 27, 0.3)",
            color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
            borderRadius: "15px",
            padding: "5px 10px 5px 10px",
            fontSize: "12px",
          }}
        >
          {params.row.formProgress < 100 ? "Incomplete" : "Verification Needed"}
        </div>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 460,
      editable: false,
    },
    {
      headerName: "Action",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="space-around">
          {/* {params.row.status === "Verification Needed" && ( */}
            <IconButton
              color="primary"
              onClick={() => {
                localStorage.setItem(
                  "selectedDriverInfo",
                  JSON.stringify(params.row.otherDetails)
                );
                navigate("/add-driver");
              }}
            >
              <img src={edit} alt="edit" width={30} height={25} />
            </IconButton>
          {/* )} */}
          <IconButton
            color="primary"
            onClick={() => {
              localStorage.setItem(
                "selectedDriverInfo",
                JSON.stringify(params.row.otherDetails)
              );
              setTruckDriverModal(!truckDriverModal);
            }}
          >
            <img src={detail} alt="detail" width={30} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <TruckDriverContainer>
        <TitleComponent
          showButton
          text="Truck Driver"
          btntext="Add New Sheet"
          clickHandler={() => setIsOpen(!isOpen)}
        />
        <TruckDriverWrapper>
          <CustomTable
            loading={tab_loader}
            rows={TruckDriverData}
            columns={BGColumns}
          />
        </TruckDriverWrapper>
      </TruckDriverContainer>
      <AddNewBusiness
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uploadDocText="Upload Driver Doc"
        setIsConfirmationModal={setIsConfirmationModal}
        clickHandler={() => {
          localStorage.removeItem("selectedDriverInfo");
          navigate("/add-driver");
        }}
        setIsUploadModal={setIsUploadModal}
      />
      <UploadAndLoss
        modalHeading="Upload Truck Driver Document"
        buttonText="Upload Driver Document"
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        generalBusiness="truckDriver"
        setIsConfirmationModal={setIsConfirmationModal}
      />
      <TruckDriver
        modalHeading="Upload Truck Driver Document"
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
      <ConfirmationModal
        buttonText="Upload Driver Document"
        isUploadModal={isUploadModal}
        ConfirmAtionData="Truck"
        setIsUploadModal={setIsUploadModal}
        modalHeading="Mandatory Field For Truck Driver"
        isConfirmationModal={isConfirmationModal}
        setIsConfirmationModal={setIsConfirmationModal}
        setIsAdditionalModal={setIsAdditionalModal}
      />
      <AdditionalNotes
        additionalNotes={additionalNotes}
        setadditionalnotes={setadditionalnotes}
        isModalOpen={isAdditionalModal}
        closeModal={() => setIsAdditionalModal(!isAdditionalModal)}
        openSecondModal={(notes) => handleNotesSubmit(notes)}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => {
          setIsValidateTaxModal(!isValidateTaxModal);
          setTimeout(() => {
            navigate("/truck-driver");
          }, 1500);
        }}
        handleYesClick={() => {
          infoToast("Submitted successfully");
          setTimeout(() => {
            navigate("/truck-driver");
          }, 1000);
        }}
        paragraph="Are you sure you want to validate this Tax Form?"
      />
    </>
  );
};

export default TruckDriverList;

const TruckDriverContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const TruckDriverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
