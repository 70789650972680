import { ChangePasswordWrapper } from "./Setting.style";
import { Span } from "components/reusable/typography/Typography.style";
import FlexComp from "components/reusable/flex/Flex";
import { Button } from "components/reusable/button/Button";
import { PasswordInput } from "components/reusable/input/Inputs";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, "Password must contain at least one capital letter")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        let userId = JSON.parse(localStorage.getItem("userData"));
        userId = userId?.user?._id;

        if (!userId) {
          throw new Error("User ID not found in localStorage");
        }

        const response = await apiReq(API_ROUTES.updateAdminPassword, "POST", {
          userId: userId,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });

        // if (!response.ok) {
        //   throw new Error(`Request failed with status ${response.message}`);
        // }

        toast.success("Password updated successfully");
        // const data = await response.json();
      } catch (error) {
        console.error("Error updating password:", error.message);
        console.log("Error updating password:", error.message);
        toast.error(error.message);
        setFieldError("submitError", error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {formik.errors.submitError && <p>Error: {formik.errors.submitError}</p>}
        <ChangePasswordWrapper gridGap="8px">
          <Span color="#1B1B1B" fontSize="20px" fontWeight="500">
            Enter Old Password
          </Span>
          <PasswordInput
            label="Old Password"
            placeHolder="*******"
            id="oldPassword"
            type="password"
            size="size"
            style={{ width: "32%" }}
            {...formik.getFieldProps("oldPassword")}
          />
          {formik.touched.oldPassword && formik.errors.oldPassword && (
            <div style={{ color: "#d93025", marginTop: "0px" }}>
              {formik.errors.oldPassword}
            </div>
          )}
        </ChangePasswordWrapper>
        <ChangePasswordWrapper gridGap="20px">
          <Span color="#1B1B1B" fontSize="20px" fontWeight="500">
            Enter New Password
          </Span>
          <PasswordInput
            label="New Password"
            placeHolder="*******"
            id="newPassword"
            type="password"
            size="size"
            style={{ width: "32%" }}
            {...formik.getFieldProps("newPassword")}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div style={{ color: "#d93025", marginTop: "0px" }}>
              {formik.errors.newPassword}
            </div>
          )}
          <PasswordInput
            label="Confirm Password"
            placeHolder="*******"
            id="confirmPassword"
            type="password"
            size="size"
            style={{ width: "32%" }}
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div style={{ color: "#d93025", marginTop: "0px" }}>
              {formik.errors.confirmPassword}
            </div>
          )}
        </ChangePasswordWrapper>
        <FlexComp
          direction="row"
          gridGap="20px"
          alignItems="center"
          marginTop="20px"
        >
          <Button
            text={
              formik.isSubmitting ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Update Password"
              )
            }
            type="submit"
            width="auto"
            fontSize="16px"
            backgroundColor="#0B2558"
            alignSelf="end"
            padding="15px 20px"
            disabled={formik.isSubmitting}
          />
          <Button
            text="Cancel"
            type="button"
            width="auto"
            fontSize="16px"
            backgroundColor="#EFF3FD"
            alignSelf="end"
            padding="15px 20px"
            color="#AEB6CF"
          />
        </FlexComp>
      </form>
    </>
  );
};

export default ChangePassword;
