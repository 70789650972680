import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { ValidateTaxBtnWrapper, ValidateTaxContainer } from "./Modals.style";
import { Span } from "../reusable/typography/Typography.style";
import { Button } from "../reusable/button/Button";
import Warning from "../../assets/modal/Warning.png";
import { Typography } from "@mui/material";
import TextCheckbox from "components/reusable/textCheckbox/textCheckbox";
import { useNavigate } from "react-router-dom";

const Check1099Modal = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate()
  return (
    <Modal
      open={props?.isModalOpen}
      onClose={props?.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ValidateTaxContainer>
        <div>
          <img src={Warning} alt="Warning" />
        </div>
        <Span color="#000000" fontSize="20px" fontWeight="500" textAlign="center" >
          Are you sure you want to submit without completing the 1099 Worksheet?
        </Span>
        <TextCheckbox
          text="Please be advise no changes will be made after submission. Contact Staff for additional changes."
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
        />

        <ValidateTaxBtnWrapper>
          <Button
            text="No"
            width="150px"
            fontSize="20px"
            backgroundColor="#0B2558"
            padding="10px"
            color="#AEB6CF"
            onClick={() => {props?.closeModal(); navigate("/worksheet") }}
          />
          <button
          style={{ backgroundColor: !isChecked ? "#BCBABA": "#00ADEF", width: "150px", fontSize:"20px", padding:"10px", color: "#FFFFFF", border: "none", borderRadius: "10px" }}
          
            
            type="button"
            disabled={!isChecked}
            onClick={() => {
              props?.closeModal();
              props?.setIsTermModal(true)
            }}>
              Yes
            </button>
        </ValidateTaxBtnWrapper>
      </ValidateTaxContainer>
    </Modal>
  );
};

export default Check1099Modal;
