import styled from "styled-components";
import { Input } from "@mui/material";
import { Form } from "formik";

export const SignupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const FormWrapper = styled(Form)`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const FormHtmlWrapper = styled.form`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  justify-content: center;
  @media (max-height: 820px) {
    justify-content: initial;
    padding-top: 30px;
  }
`;

export const FormSec = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: flex-start;

  justify-content: space-between;
  grid-gap: ${({ gridGap }) => (gridGap ? gridGap : "10px")};
  h5 {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
`;

export const FormLogo = styled.div`
  width: 50%;
  margin-bottom: 10px;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const AuthPicWrapper = styled.div`
  width: 65%;
  position: relative;
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0b25584d; /* Your desired color shade */
    mix-blend-mode: multiply;
    z-index: 2;
  }
`;

export const StyledInput = styled(Input)`
  && {
    input {
      color: #fff !important; /* Ensures typed text is white */
      border-color: #fff;
      background-color: transparent;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: #ffffff !important;
      caret-color: #ffffff !important;
      transition: background-color 5000s ease-in-out 0s; /* Prevents background color change */
    }
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  grid-gap: 15px;
  width: 100%;
  p {
    color: #fff;
    font-size: 16px;
  }
  h5 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 18px;
    cursor: pointer;
  }
`;

export const AlreadyAcc = styled.div`
  display: flex;
  grid-gap: 5px;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 18px;
  margin: auto;
  h5 {
    font-size: 18px;
    cursor: pointer;
  }
`;
