import { array, boolean, object, string } from "yup";

export const basicInfoSchema = object().shape({
  firstName: string()
    .min(3, "First name must be at least 3 characters")
    .max(30, "First name must be maximum 50 characters")
    .required("First name required"),
  // middleName: string()
  //   .min(3, "Middle name must be at least 3 characters")
  //   .max(20, "Middle name must be maximum 50 characters")
  //   .required("First name required"),
  lastName: string()
    .min(3, "Last name must be at least 3 characters")
    .max(30, "Last name must be maximum 50 characters")
    .required("Last name required"),
  socialSecurity: string().required("EIN required"),
  taxYear: string().required("Tax year required"),
  street: string().required("Street number required"),
  city: string().required("City name required"),
  state: string().required("State required"),
  zipCode: string().required("Zip code required"),
  dob: string().required("Date of borth required"),
  email: string().email().required("Email required"),
  mobileNumber: string().required("Mobile number required"),
  SingleCheck: boolean().default(false),
  MarriedFillingJointlyCheck: boolean().default(false),
  MarriedFillingSeparatelyCheck: boolean().default(false),
  headOfHouseholdCheck: boolean().default(false),
  // businessAddress: object().shape({
  //   street: string().required("Street number required"),
  //   city: string().required("City name required"),
  //   state: string().required("State required"),
  //   zipCode: string().required("Zip code required"),
  // }),
});

export const adminstrativeFeeSchema = object().shape({
  associationFee: string().required("Association fee required"),
  atmCharges: string().required("ATM charges required"),
  bankFee: string().required("Bank fee required"),
  dotExamFee: string().required("Exam fee required"),
  comcardFee: string().required("Card fee required"),
  investmentFee: string().required("Investment fee required"),
  licensingFee: string().required("License fee required"),
  safeDepositBoxFee: string().required("Safe deposite fee required"),
  administrativeFee: string().required("administrative fee required"),
  wireFee: string().required("Wire fee required"),
});

export const maintenanceSchema = object().shape({
  parts: string().required("Parts field required"),
  repairs: string().required("Repair field required"),
  tires: string().required("Tires field required"),
  truckWashes: string().required("Truck wash field required"),
  otherMaintenance: string().required("maintenance field required"),
  otherExpenseArr: array().of(
    object().shape({
      name: string().default(""),
      amount: string().default(""),
    })
  ),
});

export const laboxTaxSchema = object().shape({
  labor: object().shape({
    subContractor: string().required("Contractor required"),
    lumpers: string().required("Lumoers required"),
  }),
  taxes: object().shape({
    adValorem: string().required("Valorem required"),
    fuelTax: string().required("Fue tax required"),
    heavyUseTax: string().required("Tax required"),
    licenseTax: string().required("License tax required"),
    otherTax: string().required("Other tax required"),
    permits: string().required("Permits required"),
    registration: string().required("Registration required"),
  }),
});

export const insuranceSchema = object().shape({
  insurance: object().shape({
    bobtailInsurance: string().required(),
    piAndPdInsurance: string().required(),
    truckInsurance: string().required(),
    insuranceWorkmanCompensation: string().required(),
    accInsurance: string().required(),
    otherInsurance: string().required(),
  }),
  interest: object().shape({
    interestOnAdvances: string().required(),
    interestCreditCardsBus: string().required(),
    interestOnTruckLoans: string().required(),
    interestWorkmanCompensation: string().required(),
    otherInterest: string().required(),
  }),
});

export const roadExpSchema = object().shape({
  hotel: string().required(),
  laundry: string().required(),
  parking: string().required(),
  showers: string().required(),
  tolls: string().required(),
  truckSupplies: string().required(),
  weightFees: string().required(),
  prepass: string().required(),
  meals: string().required(),
  rentalCar: string().required(),
  airfare: string().required(),
  noOfDaysInRoads: string().required(),
  otherExpenseArr: array().of(
    object().shape({
      name: string().default(""),
      amount: string().default(""),
    })
  ),
});

export const officeExpSchema = object().shape({
  attorneyAndAccounting: string().required(),
  taxPrepFee: string().required(),
  fuel: string().required(),
  oil: string().required(),
  reeferFuel: string().required(),
  officeExpense: string().required(),
  otherExpenseArr: array().of(
    object().shape({
      name: string().default(""),
      amount: string().default(""),
    })
  ),
});

export const utilitiesSchema = object().shape({
  truckLease: string().required(),
  storageLease: string().required(),
  other: string().required(),
  // mobileNumber: string().required(),
});
