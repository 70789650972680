import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "components/admin/adminLayout/AdminLayout";
import Layout2 from "components/staff/layout/Layout2";
import Layout from "components/layout/Layout";

const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const Authentication = localStorage.getItem("auth");
  const user = JSON.parse(localStorage.getItem("userData") || "{}");
  const userExists = Boolean(Object.entries(user).length);
  const userRole = user?.user?.role;
  const adminRoutes = useMemo(
    () => [
      "/admin-dashboard",
      "/admin-client",
      "/user-management",
      "/admin-queues",
      "/tax-filing",
      "/queue-report-listing",
      "/client-report-listing",
      "/tax-report-listing",
      "/tax-return-report-listing",
      "/user-management-form",
      "/chart",
      "/admin-chat",
      "/admin-messages",
      "/admin-compose",
      "/admin-profile",
      "/admin-change-password",
      "/settings/admin-change-password",
      "/admin-support",
      "/admin-support-form",
      "/task",
      "/admin-add-new-task",
    ],
    []
  );
  const staffRoutes = useMemo(
    () => [
      "/staffDashboard",
      "/client",
      "/queues",
      "/support",
      "/add-ticket",
      "/messages",
      "/staff-chat",
      "/staff-change-password",
      "/staff-profile",
      "/date",
      "/messageCompose",
      "/queue-report",
      "/tax-filling-report",
      "/client-activity-report",
      "/client-activity",
    ],
    []
  );
  const clientRoutes = useMemo(
    () => [
      "/dashboard",
      "/personal-collection",
      "/personal-list",
      "/add-business",
      "/general-business",
      "/rental-property",
      "/add-rental",
      "/worksheet",
      "/add-worksheet",
      "/business-formation",
      "/add-businessFormation",
      "/truck-driver",
      "/add-driver",
      "/upload-tax-docs",
      "/tax-docs",
      "/profile",
      "/client-change-password",
      "/review",
      "/submit-tax-docs",
      "/tax-return",
      "/non-profit-formation",
      "/add-nonProfit",
    ],
    []
  );
  const loginSignupRoutes = useMemo(
    () => ["/", "/register", "/admin-signin", "/staff-signin", "/forgot-password", "/Change-password", "/admin-change-password", "/staff-Change-password","/admin-forget"],
    []
  );
  useEffect(() => {
    if (!Authentication) {
      if (!loginSignupRoutes.includes(pathname)) {
        navigate("/");
      }
    } else {
      // Redirect Admin to Admin Dashboard
      if (userRole === "Admin") {
        if (!adminRoutes.includes(pathname)) {
          navigate(adminRoutes[0]);
        }
      }
      // Redirect Staff to Staff Dashboard
      if (userRole === "Staff") {
        if (!staffRoutes.includes(pathname)) {
          navigate(staffRoutes[0]);
        }
      }
      // Redirect Client to Client Dashboard
      if (!userRole) {
        if (adminRoutes.includes(pathname) || staffRoutes.includes(pathname)) {
          navigate(clientRoutes[0]);
        }
      }
      // Restrict all users from accessing login/signup pages
      if (loginSignupRoutes.includes(pathname)) {
        navigate(
          userRole === "Admin"
            ? adminRoutes[0]
            : userRole === "Staff"
            ? staffRoutes[0]
            : clientRoutes[0]
        );
      }
    }
    setIsLoading(false);
  }, [
    Authentication,
    navigate,
    pathname,
    userRole,
    adminRoutes,
    staffRoutes,
    clientRoutes,
    loginSignupRoutes,
  ]);

  console.log(userExists, "__user");

  if (isLoading) return null;

  // Get the appropriate layout based on the user role
  const getLayout = () => {
    let layout = Layout;
    if (userRole === "Admin") layout = AdminLayout;
    if (userRole === "Staff") layout = Layout2;
    return layout;
  };
  const AppLayout = getLayout();
  return <> {userExists ? <AppLayout>{children}</AppLayout> : children} </>;
};

export default PrivateRoute;
