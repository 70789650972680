import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import { Button } from "components/reusable/button/Button";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import SimpleSelect from "components/reusable/select/SimpleSelect";
import SunEditor from "suneditor-react";
import { SyncLoader } from "react-spinners";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const options = [
  { value: "Staff", label: "Staff" },
  { value: "Client", label: "Client" },
];
const initialValues = {
  sendTo: "",
  toEmail: "",
  subject: "",
  message: "",
};

const validationSchema = Yup.object({
  sendTo: Yup.string().required("sendTo is required"),
  toEmail: Yup.string()
    .email("Invalid email format")
    .required("toEmail is required"),
  subject: Yup.string().required("subject is required"),
  message: Yup.string().required("message is required"),
});
const infoToast = (message = "") => toast.info(message);

const StaffMessageCompose = () => {
  const navigate = useNavigate();
  const [sideBarData, setSideBarData] = useState([
    { id: "0", value: "All Sent Messages", label: "0" },
    { id: "1", value: "Staff", label: "0" },
    { id: "2", value: "Client", label: "0" },
    { id: "3", value: "Admin", label: "0" },
    { id: "4", value: "Deleted Items", label: "0" },
  ]);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [emailError, setEmailError] = useState("");

  const userId = localStorage.getItem("clientId");

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await apiReq(API_ROUTES.messageGetAll, "POST", {
          userId,
        });
        const messages = response.data.data || [];

        let messageCounts = {
          "All Sent Messages": messages.filter((message) => !message.isDeleted)
            .length,
          Staff: messages.filter((message) => message.sendTo === "Staff")
            .length,
          Client: messages.filter((message) => message.sendTo === "Client")
            .length,
          Admin: messages.filter(
            (message) => message.sendBy === "Admin" && !message.isDeleted
          ).length,
          "Deleted Items": messages.filter(
            (message) => message.isDeleted === true
          ).length,
        };

        messages.forEach((message) => {
          if (message.sendTo === "Staff" && message.sendBy === "Staff") {
            messageCounts["Staff"]++;
          } else if (
            message.sendTo === "Client" &&
            message.sendBy === "Client"
          ) {
            messageCounts["Client"]++;
          }
        });

        // Update labels in sideBarData using functional update
        setSideBarData((prevState) =>
          prevState.map((item) => ({
            ...item,
            label: (messageCounts[item.value] || 0).toString(), // Use default value if messageCounts[item.value] is undefined
          }))
        );
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [userId]); // Include userId in dependency array

  const [, setLoading] = useState(false);
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      await apiReq(API_ROUTES.messageAdd, "POST", {
        userId: localStorage.getItem("clientId"),
        sendBy: "Staff",
        sendTo: values.sendTo,
        toEmail: values.toEmail,
        subject: values.subject,
        message: values.message,
      });
      infoToast("Message Sent Successfully:");
      setLoading(false);
      resetForm();
    } catch (error) {
      console.error("Error submitting message:", error);
    }
  };

  const fetchEmailSuggestions = async (type, value) => {
    try {
      const response = await apiReq(API_ROUTES.searchEmail, "POST", {
        type,
        value,
      });
      if (response.data?.data.length > 0) {
        const emailData = response.data.data.map(
          (data) => `${data.email} - ${data.firstName} ${data.lastName}`
        );
        setEmailSuggestions(emailData);
        setEmailError("");
      } else {
        setEmailSuggestions([]);
        setEmailError("Email not found in database");
      }
    } catch (error) {
      console.error("Error fetching email suggestions:", error);
    }
  };

  console.log(emailSuggestions, "emailSuggestions");

  const debouncedFetchEmailSuggestions = debounce((type, value) => {
    fetchEmailSuggestions(type, value);
  }, 300);

  return (
    <RentalPropertyContainer>
      <StaffHeader
        text="Example with Select Fields"
        showButton={false}
        btntext="+ Compose New"
        clickHandler={() => navigate("/add-ticket")}
        showFirstSelect={false}
        showSecondSelect={false}
        placeholder="Select Status"
      />

      <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />

      <RentalPropertyWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4} lg={3}>
                  <SideBoxStyle>
                    {sideBarData.map((item) => (
                      <SideBoxButtonStyle
                        key={item.id}
                        onClick={() => navigate("/messages")}
                      >
                        <SideBoxTitleStyle>{item.value}</SideBoxTitleStyle>
                        <div>{item.label}</div>
                      </SideBoxButtonStyle>
                    ))}
                  </SideBoxStyle>
                </Grid>
                <Grid item xs={8} lg={8}>
                  {/* Form fields */}
                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography sx={{ marginBottom: "10px" }}>
                      Compose Message
                    </Typography>
                    <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
                  </Box>
                  <SimpleSelect
                    label="Select Person Type"
                    id="select-person-type"
                    width={230}
                    menuItems={{
                      placeholder: "",
                      items: options,
                    }}
                    name="sendTo"
                    selectedValues={formikProps.values.sendTo}
                    handleChange={(e) => {
                      formikProps.setFieldValue("sendTo", e.target.value);
                    }}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage
                    name="sendTo"
                    component="div"
                    className="error-message"
                  />

                  <Autocomplete
                    freeSolo
                    options={emailSuggestions}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To (Email)"
                        name="toEmail"
                        id="toEmail"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={formikProps.values.toEmail}
                        onChange={(e) => {
                          formikProps.setFieldValue("toEmail", e.target.value);
                          debouncedFetchEmailSuggestions(
                            formikProps.values.sendTo,
                            e.target.value
                          );
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      // Extract only the email part
                      const emailOnly = value ? value.split(" - ")[0] : "";
                      formikProps.setFieldValue("toEmail", emailOnly);
                    }}
                  />
                  {console.log(formikProps.values.toEmail, "formikProps")}
                  <ErrorMessage
                    name="toEmail"
                    component="div"
                    className="error-message"
                  />

                  <Input
                    placeholder="Subject"
                    sx={{ width: "100%" }}
                    name="subject"
                    value={formikProps.values.subject}
                    onChange={(e) =>
                      formikProps.setFieldValue("subject", e.target.value)
                    }
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="error-message"
                  />

                  <SunEditor
                    placeholder="Type Message"
                    height="300px"
                    name="message"
                    value={formikProps.values.message}
                    onChange={(value) => {
                      const newValue = value === "<p><br></p>" ? "" : value;
                      formikProps.setFieldValue("message", newValue);
                      if (!newValue) {
                        formikProps.setFieldTouched("message", true);
                      }
                    }}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage name="message" component="div" />

                  <Button
                    text={
                      formikProps.isSubmitting ? (
                        <SyncLoader color={"#FFFFFF"} size={8} />
                      ) : (
                        "Send"
                      )
                    }
                    borderRadius="5px"
                    height="45px"
                    width="100px"
                    color="white"
                    backgroundColor="rgba(11, 37, 88, 1)"
                    type="submit"
                    margin="10px"
                  />
                  <Button
                    borderRadius="5px"
                    height="45px"
                    width="100px"
                    color="rgba(174, 182, 207, 1)"
                    backgroundColor="rgba(239, 243, 253, 1)"
                    text="Cancel"
                    margin="10px"
                    onClick={() => navigate("/messages")}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </RentalPropertyWrapper>
    </RentalPropertyContainer>
  );
};

export default StaffMessageCompose;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

const SideBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  background: #eff3fd;
  width: 100%;
  padding: 20px;
  min-height: 50vh;
`;

const SideBoxButtonStyle = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const SideBoxTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
