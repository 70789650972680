import styled from "styled-components";

export const TermsConditionContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  outline: none;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  justify-content: space-between;
  border-radius: 10px;
  height: 90vh;
`;

export const TermsConditionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0b2558;
  padding: 10px 25px;
  border-radius: 10px 10px 0px 0px;
`;

export const TermsConditionBody = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  padding: 10px 15px;
  overflow-y: scroll;
`;

//Select Style Component
export const SignPreviewBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff3fd;
  width: 100%;
  padding: 20px 5px;
  border-radius: 10px;
  grid-gap: 15px;
  height: 220px;
`;

export const SignPreview = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 100px;
  span {
    font-family: ${({ fontFamily }) => fontFamily || ""};
    font-size: 55px;
    font-weight: 400;
  }
  &.custom-name-preview .custom-name-span {
    font-size: 32px;
    font-weight: 300;
  }
  @media (max-width: 1150px) {
    span {
      font-size: 30px;
    }
  }
`;
