import styled from "styled-components";

export const ReviewConfirmationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  outline: none;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  justify-content: space-between;
  border-radius: 10px;
  height: 90vh;
  overflow-y: scroll;
  padding: 30px;
`;

export const ReviewConfirmationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
`;

export const ReviewConfirmationBody = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const ReviewConfirmationBtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

export const GrayBox = styled.div`
  background: #eff3fd;
  border-radius: 10px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

export const GrayHeading = styled.div`
  background: #0b2558;
  border-radius: 5px;
  padding: 10px;
`;

export const GrayHeadingTitle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  padding: 2px 5px;
`;
