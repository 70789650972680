import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { Modal } from "@mui/material";
import { IconStyledButton } from "components/reusable/button/Button.style";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  ItemizedDeduction,
  adjustments,
  assets,
  businessInformation,
  businessUseOfHome,
  expenses,
  fees,
  fuelAndOil,
  healthCare,
  insurance,
  interest,
  maintenance,
  officeExpenses,
  personalCollection,
  professionalFess,
  rentAndLease,
  roadExpenses,
  taxes,
  truckBusinessInfo,
  utilities,
  vehicleInformation,
} from "../../../data/clientData/reviewAndConfirmationModal";
import { Button } from "../../reusable/button/Button";
import PdfUploadButton from "../../reusable/button/PdfUploadButton";
import FlexComp from "../../reusable/flex/Flex";
import StaffTabs from "../../reusable/tabs/StaffTabs";
import { Span } from "../../reusable/typography/Typography.style";
import { RentalPreviewBox } from "../Modals.style";
import {
  ReviewConfirmationBody,
  ReviewConfirmationBtnsWrapper,
  ReviewConfirmationContainer,
  ReviewConfirmationHeader,
} from "../review/ReviewConfirmation/ReviewAndConfirmation.style";
import ReviewConfirmationBox from "../review/ReviewConfirmation/ReviewConfirmationBox";
import ValidateTaxModal from "../ValidateTaxModal";

const GeneralBusinessModal = ({
  generalBusinessModal,
  setGeneralBusinessModal,
  setPersonalCollectionModal,
  personalCollectionModal,
  modalData,
  handleYesClick,
}) => {
  const [activeTab, setActiveTab] = useState(0); // State to keep track of active tab index
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [showNextButton, setShowNextButton] = useState(true);
  console.log(modalData, "dsds");
  const componentRef = useRef();
  // const handleDownload = () => {
  //   const element = componentRef.current;
  //   const opt = {
  //     margin: 1,
  //     filename: "form.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //   };

  //   // New Promise-based API
  //   html2pdf().set(opt).from(element).save();
  // };

  useEffect(() => {
    const generateTabsAndData = () => {
      if (!Array.isArray(modalData)) return;

      const tabLabels = [];
      const dataMap = {};

      modalData.forEach((item, index) => {
        const { formType, ...data } = item;
        const count =
          tabLabels.filter((tab) => tab.startsWith(formType)).length + 1;
        const label = `${formType} ${count}`;
        tabLabels.push(label);
        dataMap[label] = data;
      });

      setTabs(tabLabels.map((label) => ({ label })));
      setTabData(dataMap);
      setShowNextButton(tabLabels.length > 1); // Show Next button if there are multiple tabs
    };

    generateTabsAndData();
  }, [modalData]);

  // console.log(modalData, "staffQueues");

  const handleTabChange = (index) => {
    setActiveTab(index);
    setShowNextButton(index < tabs.length - 1);
  };

  const handleNextClick = () => {
    const nextTab = activeTab + 1;
    setActiveTab(nextTab);
    setShowNextButton(nextTab < tabs.length - 1);
  };

  const generateDocumentButtons = (documents) => {
    const documentButtons = [];

    // Ensure documents is an array and filter out null/undefined values
    const documentsToProcess = Array.isArray(documents)
      ? documents.filter((doc) => doc !== null && doc !== undefined)
      : documents
      ? [documents]
      : [];

    documentsToProcess.forEach((doc) => {
      // Check if doc is an object before processing
      if (typeof doc === "object" && doc !== null) {
        for (const [key, value] of Object.entries(doc)) {
          if (value && typeof value === "string" && value.startsWith("http")) {
            documentButtons.push(
              <PdfUploadButton
                key={key}
                text={doc[`${key}FileName`] || "Document"}
                documents={[
                  { url: value, name: doc[`${key}FileName`] || "Document" },
                ]}
              />
            );
          }
        }
      }
    });

    return documentButtons;
  };

  const renderDocumentSection = (documentButtons) => {
    if (documentButtons.length === 0) return null;

    return (
      <div
        style={{
          background: " rgba(239, 243, 253, 1)",
          height: " auto",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <h2
          style={{
            backgroundColor: "rgba(11, 37, 88, 1)",
            color: "white",
            fontSize: "18px",
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "10px",
          }}
        >
          Uploaded Documents
        </h2>
        <div style={{ display: "flex" }}>
          {documentButtons.map((button) => button)}
        </div>
      </div>
    );
  };

  const renderTabContent = (label) => {
    if (!label) return <div>No data available</div>;

    const data = tabData[label] || {};
    const {
      personalCollectionTaskId,
      generalBusinessTaskId,
      truckDriverTaskId,
      rentalPropertyTaskId,
    } = data;

    let documentButtons = [];

    if (generalBusinessTaskId) {
      const {
        businessInfoId: businessInfo,
        expensesId: expensesID,
        fixedAssetsId: fixedAssets,
        vehicleInfoId: vehicleInfo,
        businessUseOfHomeId: businessUseOfHomeID,
      } = generalBusinessTaskId;

      documentButtons = generateDocumentButtons(businessInfo);
    } else if (personalCollectionTaskId) {
      documentButtons = generateDocumentButtons(
        personalCollectionTaskId.prepSheetId,
        personalCollectionTaskId.adjustmentsId,
        personalCollectionTaskId.healthCareId,
        personalCollectionTaskId.retirementId
      );
    }

    switch (true) {
      case label.startsWith("General Business"):
        return (
          <>
            <ReviewConfirmationBox
              title="Business Information"
              data={businessInformation}
              values={generalBusinessTaskId.businessInfoId}
            />
            <ReviewConfirmationBox
              title="Expenses"
              data={expenses}
              values={generalBusinessTaskId?.expensesID}
            />
            {generalBusinessTaskId?.fixedAssets?.fixedAssetsArr &&
              generalBusinessTaskId?.fixedAssets.fixedAssetsArr.length > 0 && (
                <ReviewConfirmationBox
                  title="Assets"
                  data={assets}
                  values={generalBusinessTaskId?.fixedAssets.fixedAssetsArr}
                />
              )}
            <ReviewConfirmationBox
              title="Vehicle Information"
              data={vehicleInformation}
              values={generalBusinessTaskId?.vehicleInfo}
            />
            <ReviewConfirmationBox
              title="Business Use of Home"
              data={businessUseOfHome}
              values={generalBusinessTaskId?.businessUseOfHomeID}
            />
            {renderDocumentSection(documentButtons)}
          </>
        );
      case label.startsWith("Personal Collection"):
        return (
          <>
            <ReviewConfirmationBox
              title="Prep Sheet"
              data={personalCollection}
              values={personalCollectionTaskId.prepSheetId}
            />
            <ReviewConfirmationBox
              title="Health Care"
              data={healthCare}
              values={personalCollectionTaskId.healthCareId}
            />
            <ReviewConfirmationBox
              title="Adjustments"
              data={adjustments}
              values={personalCollectionTaskId.adjustmentsId}
            />
            <ReviewConfirmationBox
              title="Retirement"
              data={ItemizedDeduction} // You might want to replace this with appropriate data
              values={personalCollectionTaskId.retirementId}
            />
            {renderDocumentSection(
              generateDocumentButtons([
                personalCollectionTaskId.prepSheetId,
                personalCollectionTaskId.healthCareId,
                personalCollectionTaskId.adjustmentsId,
                personalCollectionTaskId.retirementId,
              ])
            )}
          </>
        );
      case label.startsWith("Truck Driver"):
        return (
          <>
            <ReviewConfirmationBox
              title="Truck Business Information"
              data={truckBusinessInfo}
              values={truckDriverTaskId.truckDriverBasicInfoId}
            />
            <ReviewConfirmationBox
              title="Fees"
              data={fees}
              values={truckDriverTaskId.truckDriverFeeId}
            />
            <ReviewConfirmationBox
              title="Fuel and Oil"
              data={fuelAndOil}
              values={truckDriverTaskId.truckDriverOfficeExpId}
            />
            <ReviewConfirmationBox
              title="Insurance"
              data={insurance}
              values={truckDriverTaskId.truckDriverInsuranceId?.insurance}
            />
            <ReviewConfirmationBox
              title="Interest"
              data={interest}
              values={truckDriverTaskId.truckDriverInsuranceId?.interest}
            />
            <ReviewConfirmationBox
              title="Maintenance"
              data={maintenance}
              values={truckDriverTaskId.truckDriverMaintenanceId}
            />
            <ReviewConfirmationBox
              title="Office Expenses"
              data={officeExpenses}
              values={truckDriverTaskId.truckDriverOfficeExpId}
            />
            <ReviewConfirmationBox
              title="Professional Fees"
              data={professionalFess}
              values={truckDriverTaskId.truckDriverOfficeExpId}
            />
            <ReviewConfirmationBox
              title="Rent and Lease"
              data={rentAndLease}
              values={truckDriverTaskId.truckDriverRentAndLeaseId}
            />
            <ReviewConfirmationBox
              title="Road Expenses"
              data={roadExpenses}
              values={truckDriverTaskId.truckDriverRoadExpId}
            />
            <ReviewConfirmationBox
              title="Taxes"
              data={taxes}
              values={truckDriverTaskId.truckDriverLaborTaxesId?.taxes}
            />
            <ReviewConfirmationBox
              title="Utilities"
              data={utilities}
              values={truckDriverTaskId.truckDriverBasicInfoId}
            />
          </>
        );
      case label.startsWith("Rental Property"):
        return (
          <>
            <RentalPreviewBox>
              <div
                style={{
                  background: "#0b2558",
                  width: "100%",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
              >
                <Span color="#FFFFFF" fontSize="18px" fontWeight="500">
                  Property Address
                </Span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Street
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.address?.street}
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    City
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.address?.city}
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    State
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.address?.state}
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    zipCode
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.address?.zipCode}
                  </Span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Date Placed in Service
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {dayjs(
                      rentalPropertyTaskId?.rentalPropertyId?.address
                        ?.datePlacedService
                    ).format("MM-DD-YYYY")}
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Rent Charge
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.address
                        ?.rentCharge
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Purchase Price
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.address
                        ?.purchasePrice
                    }
                  </Span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Fair Market Value
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.address
                        ?.fairMarketValue
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Fair Rental Days
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.address
                        ?.fairRentalDays
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Property Type (Sch E)
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.address
                        ?.propertyType
                    }
                  </Span>
                </div>
              </div>
            </RentalPreviewBox>
            <RentalPreviewBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <Span color="#000000" fontSize="20px" fontWeight="500">
                  Month:{" "}
                  {rentalPropertyTaskId?.rentalPropertyId?.monthYear?.month}
                </Span>

                <div
                  style={{
                    background: "#0b2558",
                    width: "100%",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                >
                  <Span color="#FFFFFF" fontSize="18px" fontWeight="500">
                    Income
                  </Span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Rental Income
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.income
                        ?.rentalIncome
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Deposit
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.income?.deposit}
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Late Fees
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.income?.lateFees}
                  </Span>
                </div>
              </div>
              <FlexComp
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                gridGap="10px"
              >
                <div
                  style={{
                    background: "#0b2558",
                    width: "100%",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                >
                  <Span color="#FFFFFF" fontSize="18px" fontWeight="500">
                    Expenses
                  </Span>
                </div>
              </FlexComp>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Management Fees
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.managementFees
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Advertising for Tenants
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.advertisingForTenants
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Association Dues (HOA)
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.associationDues
                    }
                  </Span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Cleaning & Maintenace
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.cleaningAndMaintenance
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Repairs & Maintenance
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.repairsAndMaintenance
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                  s
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Insurance
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.insurance
                    }
                  </Span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Property Tax
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.propertyTax
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Utilities
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.utilities
                    }
                  </Span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Water Charges
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {
                      rentalPropertyTaskId?.rentalPropertyId?.expenses
                        ?.waterCharges
                    }
                  </Span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    Misc. Fees
                  </Span>
                  <Span color="#000000" fontSize="16px" fontWeight="500">
                    {rentalPropertyTaskId?.rentalPropertyId?.expenses?.miscFees}
                  </Span>
                </div>
              </div>
              {renderDocumentSection(documentButtons)}
            </RentalPreviewBox>
          </>
        );
      default:
        return <div>No data available</div>;
    }
  };
  const handleDownload = async () => {
    const container = document.createElement("div");
    container.style.margin = "10px";
    // container.style.fontFamily = "Arial, sans-serif"; // Add this line to set a consistent font

    tabs.forEach((tab) => {
      const content = renderTabContent(tab.label);
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = ReactDOMServer.renderToString(content);
      container.appendChild(tempDiv);
    });

    html2pdf()
      .from(container)
      .toPdf()
      .output("blob")
      .then((pdfBlob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "all_tabs.pdf";
        link.click();
      });
  };

  return (
    <>
      <Modal
        open={generalBusinessModal}
        onClose={() => setGeneralBusinessModal(!generalBusinessModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ReviewConfirmationContainer>
          <ReviewConfirmationHeader>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Span color="#000000" fontSize="24px" fontWeight="600">
                Tax Payers Information
              </Span>
              {/* <ButtonIcon
                text="chat"
                fontSize="12px"
                padding="5px 15px"
                icon={<ChatBubbleOutlineOutlinedIcon size="small" />}
              /> */}
            </FlexComp>
            <CloseIcon
              sx={{ color: "#000000", cursor: "pointer" }}
              onClick={() => setGeneralBusinessModal(!generalBusinessModal)}
            />
          </ReviewConfirmationHeader>
          <StaffTabs
            tabs={tabs}
            initial={activeTab}
            setActiveTab={handleTabChange}
          />
          <ReviewConfirmationBody ref={componentRef}>
            {renderTabContent(tabs[activeTab]?.label)}
          </ReviewConfirmationBody>
          <ReviewConfirmationBtnsWrapper>
            <div>
              <IconStyledButton
                width="100%"
                variant="text"
                color="white"
                fontSize="12px"
                padding="10px 40px"
                onClick={handleDownload}
              >
                <DownloadIcon />
                Download
              </IconStyledButton>
            </div>
            {modalData?.length === 1 || activeTab === tabs?.length - 1 ? (
              <Button
                text="Validate"
                width="20%"
                fontSize="20px"
                padding="10px 40px"
                backgroundColor="#00ADEF"
                onClick={() => {
                  setGeneralBusinessModal(!generalBusinessModal);
                  setIsValidateTaxModal(!isValidateTaxModal);
                }}
              />
            ) : (
              <Button
                text="Next"
                width="20%"
                fontSize="20px"
                padding="10px 40px"
                backgroundColor="#00ADEF"
                onClick={handleNextClick}
              />
            )}
            <Button // Always show Cancel button
              text="Cancel"
              width="20%"
              fontSize="20px"
              padding="10px 40px"
              backgroundColor="rgba(239, 243, 253, 1)"
              color="
            rgba(174, 182, 207, 1)"
              onClick={() => setGeneralBusinessModal(!generalBusinessModal)}
            />
          </ReviewConfirmationBtnsWrapper>
        </ReviewConfirmationContainer>
      </Modal>
      <ValidateTaxModal
        handleYesClick={handleYesClick}
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
    </>
  );
};

export default GeneralBusinessModal;
