import styled from "styled-components";

export const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  background-color: #fff;
  padding: 15px;
`;

export const BasicInfoInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
`;

export const FreeLookWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

export const TdButtonWrappers = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  width: 25%;
`;

export const FillingStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap
`;

export const FillingStatusCheckBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
  width:auto;
  flex-wrap: wrap;
`;

export const RetirementContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
`;

export const RetirementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  span {
    padding: 10px 0px;
  }
`;

export const HealthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

export const AdjustmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  width: 100%;
`;

export const ItemizedDeductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  width: 100%;
`;

export const ButtonPosition = styled.div`
  align-self: ${({ alignSelf }) => alignSelf};
  width: ${({ width }) => width};
`;
