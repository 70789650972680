import styled from "styled-components";

export const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  min-height: 60vh;
`;

export const BasicInfoInputWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  grid-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
`;

export const FreeLookWrapper = styled.div`
  display: flex;
  align-items: start;
  grid-gap: 10px;
`;

export const PCButtonWrappers = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  width: 30%;
`;

export const FillingStatusWrapper = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FillingStatusCheckBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
`;

export const RetirementContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  min-height: 60vh;
`;

export const RetirementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  span {
    padding: 10px 0px;
  }
  @media (max-width: 1150px){
    width: 100%;
    
  }
`;

export const HealthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  min-height: 60vh;
  @media (max-width: 1150px){
  width: 100%;

  }
`;

export const HealthCareWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  //width: 65%;
  span {
    padding: 10px 0px;
  }
`;

export const AdjustmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  width: 100%;
  min-height: 65vh;
`;

export const ItemizedDeductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  width: 100%;
  min-height: 60vh;
`;

export const ButtonPosition = styled.div`
  align-self: ${({ alignSelf }) => alignSelf};
  width: ${({ width }) => width};
`;
