export const topBusinessInfoFields = [
  {
    label: "Fair Market Value *",
    placeHolder: "Enter",
    defaultValue: "",
    id: "fairMarketValue",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Fair Rental Days *",
    placeHolder: "Enter",
    defaultValue: "",
    id: "fairRentalDays",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const incomeFields = [
  {
    label: "Rental Income",
    placeholder: "Enter",
    defaultValue: "",
    id: "rentalIncome",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Deposit",
    placeholder: "Enter",
    defaultValue: "",
    id: "deposit",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Late Fees",
    placeholder: "Enter",
    defaultValue: "",
    id: "lateFees",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const expenseFields = [
  {
    label: "Management Fees *",
    placeholder: "Enter",
    defaultValue: "",
    id: "managementFees",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Advertising For Tenants *",
    placeholder: "Enter",
    defaultValue: "",
    id: "advertisingForTenants",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Association Dues (HOA) *",
    placeholder: "Enter",
    defaultValue: "",
    id: "associationDues",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Cleaning & Maintenance *",
    placeholder: "Enter",
    defaultValue: "",
    id: "cleaningAndMaintenance",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Repairs & Maintenance *",
    placeholder: "Enter",
    defaultValue: "",
    id: "repairsAndMaintenance",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Insurance *",
    placeholder: "Enter",
    defaultValue: "",
    id: "insurance",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Property Tax *",
    placeholder: "Enter",
    defaultValue: "",
    id: "propertyTax",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Utilities *",
    placeholder: "Enter",
    defaultValue: "",
    id: "utilities",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Water Charges *",
    placeholder: "Enter",
    defaultValue: "",
    id: "waterCharges",
    size: "size",
    width: "32%",
    value: "",
  },
  {
    label: "Misc. Fees *",
    placeholder: "Enter",
    defaultValue: "",
    id: "miscFees",
    size: "size",
    width: "32%",
    value: "",
  },
];

export const rentalMonths = [
  // {
  //   check: false,
  //   text: "All",
  //   value: 0,
  // },
  {
    check: false,
    text: "January",
    value: 1,
  },
  {
    check: false,
    text: "February",
    value: 2,
  },
  {
    check: false,
    text: "March",
    value: 3,
  },
  {
    check: false,
    text: "April",
    value: 4,
  },
  {
    check: false,
    text: "May",
    value: 5,
  },
  {
    check: false,
    text: "June",
    value: 6,
  },
  {
    check: false,
    text: "July",
    value: 7,
  },
  {
    check: false,
    text: "August",
    value: 8,
  },
  {
    check: false,
    text: "Sept",
    value: 9,
  },
  {
    check: false,
    text: "October",
    value: 10,
  },
  {
    check: false,
    text: "November",
    value: 11,
  },
  {
    check: false,
    text: "December",
    value: 12,
  },
];
