import React, { useEffect, useState } from "react";
import {
  ChatbotContainer,
  ChatbotMessages,
  Message,
  ChatbotInputForm,
  ChatButton,
  ChatbotHeader,
} from "./chat.style.js";
import arrow from "../../assets/client/arrow.png";
import { Avatar, Box, Stack, TextareaAutosize } from "@mui/material";
import emoji from "../../assets/client/emoji.png";
import file from "../../assets/client/file.png";
import bot from "../../assets/client/bot.png";
import line from "../../assets/client/line.png";
import icon from "../../assets/client/icon.png";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  getDoc,
  setDoc,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../FirebaseSetup.js";
import FlexComp from "components/reusable/flex/Flex.jsx";
import { Button } from "components/reusable/button/Button.jsx";
import { API_ROUTES } from "utils/apiRoutes.constant.js";
import { apiReq } from "services/Requests.js";
import { v4 as uuid } from "uuid";
import { MutatingDots, ThreeDots } from "react-loader-spinner";

const Chat = ({ toggleChat }) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("userData"));
  const [queryBox, setQueryBox] = useState(false);
  const [, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const clientUId = JSON.parse(localStorage.getItem("clientData"));
  const [isBot, setIsBot] = useState(true);
  const [chats, setChats] = useState({});
  const [staffUid, setStaffUid] = useState("");
  const [isBotMessage, setIsBotMessage] = useState(true);
  const [chatId, setChatId] = useState("");
  const [chatWithAi, setChatWithAi] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = () => {
    if (input.trim() !== "") {
      const newMessages = [...messages, { text: input, isUser: true }];
      setMessages(newMessages);
      setInput("");

      // Auto-generate response with buttons
      setTimeout(async () => {
        setIsLoading(true);
        try {
          const lucaMessage = await apiReq(API_ROUTES.chatWithLuca, "POST", {
            userId: localStorage.getItem("clientId"),
            input: input,
          });
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: lucaMessage.data.response, isUser: false },
          ]);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
        }

        // setMessages((prevMessages) => [
        //   ...prevMessages,
        //   {
        //     text: "Do you want to chat with AI or Real Person?",
        //     isUser: false,
        //     options: true,
        //   },
        // ]);
      }, 500);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const combinedId =
    currentUser?.user?.fireBaseUid > staffUid
      ? currentUser?.user?.fireBaseUid + staffUid
      : staffUid + currentUser?.user?.fireBaseUid;
  const handleSend = async () => {
    if (!combinedId) {
      console.error("No chatId found");
      return;
    }

    const messageData = {
      id: uuid(),
      text: input,
      senderId: currentUser?.user?.fireBaseUid,
      date: Timestamp.now(),
    };

    try {
      const chatDocRef = doc(db, "chats", combinedId);
      const userChatDocRef = doc(
        db,
        "usersChats",
        currentUser?.user?.fireBaseUid
      );
      const receiverChatDocRef = doc(db, "usersChats", staffUid);

      const chatDoc = await getDoc(chatDocRef);
      const userChatDoc = await getDoc(userChatDocRef);
      const receiverChatDoc = await getDoc(receiverChatDocRef);

      if (!chatDoc.exists()) {
        await setDoc(chatDocRef, { messages: [messageData] });
      } else {
        await updateDoc(chatDocRef, {
          messages: arrayUnion(messageData),
        });
      }

      const updateUserChat = async (
        docSnapshot,
        docRef,
        combinedId,
        message
      ) => {
        if (!docSnapshot.exists()) {
          await setDoc(docRef, {
            [combinedId]: {
              lastMessage: { text: message.text },
              date: serverTimestamp(),
            },
          });
        } else {
          await updateDoc(docRef, {
            [`${combinedId}.lastMessage`]: {
              text: message.text,
            },
            [`${combinedId}.date`]: serverTimestamp(),
          });
        }
      };

      await updateUserChat(
        userChatDoc,
        userChatDocRef,
        combinedId,
        messageData
      );
      await updateUserChat(
        receiverChatDoc,
        receiverChatDocRef,
        combinedId,
        messageData
      );

      setInput("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleSendQuery = async () => {
    try {
      setLoading(true);
      await apiReq(API_ROUTES.addChatRequest, "POST", {
        clientId: localStorage.getItem("clientId"),
        clientFireBaseUid: clientUId?.user?.fireBaseUid,
        chatStatus: "requested",
        chatBody: query,
      });
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: query, isUser: true },
        { text: "We will assist you soon", isUser: false },
      ]);

      setQuery(""); // Clear the textarea after sending
      setQueryBox(false);
      setLoading(false);
    } catch (error) {
      console.error("Error submitting message:", error);
    }
  };

  const handleOptionClick = (option) => {
    setQueryBox(option === "Real Person");
    // if (option === "AI") {
    //   setMessages((prevMessages) => [
    //     ...prevMessages,
    //     { text: `We will assist you soon`, isUser: false },
    //   ]);
    // }
  };

  const getChats = () => {
    const unsub = onSnapshot(
      doc(db, "usersChats", currentUser.user.fireBaseUid),
      (doc) => {
        const chatData = doc.data();

        // Check if chatData is valid
        if (chatData) {
          setChats(chatData);

          const staffUsers = Object.keys(chatData)
            .filter((key) => chatData[key]?.userInfo?.uid)
            .map((key) => chatData[key]?.userInfo);
          setStaffUid(staffUsers[0]?.uid || "");
          // setChatId(Object.keys(chatData)[1] || ""); // Set the chatId to the first chat's ID
        } else {
          // Handle the case where chatData is null or undefined
          setChats({});
          setStaffUid("");
        }
      }
    );
    return () => {
      unsub();
    };
  };

  useEffect(() => {
    if (currentUser && currentUser.user.fireBaseUid) {
      getChats();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const userChatDocRef = doc(
          db,
          "usersChats",
          currentUser?.user?.fireBaseUid
        );
        const userChatDoc = await getDoc(userChatDocRef);

        if (userChatDoc.exists()) {
          const userChats = userChatDoc.data();

          if (combinedId) {
            // Set to false if chats exist
            const chatDocRef = doc(db, "chats", combinedId); // Assuming fetching the first chat
            onSnapshot(chatDocRef, (doc) => {
              if (doc.exists()) {
                setMessages(doc.data().messages);
                if (doc.data().messages.length > 0) {
                  setIsBotMessage(false);
                  setChatWithAi(false);
                }
              }
            });
          } else {
            setIsBotMessage(true); // No chats found, set to true
            setChatWithAi(true);
          }
        } else {
          setIsBotMessage(true); // Document does not exist, set to true
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
        setIsBotMessage(true); // On error, set to true
      }
    };

    if (currentUser?.user?.fireBaseUid) {
      fetchChats();
    }
  }, [currentUser]);
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        margin: "15% 0% 0% 40%",
        zIndex: 99,
      }}
    >
      <ChatbotHeader>
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <p
            style={{
              padding: "30px 0px 20px 104px",
              color: "white",
              textAlign: "center",
            }}
          >
            Lewca
          </p>
          <ChatButton
            marginLeft="50px"
            backgroundColor="rgba(11, 37, 88, 1)"
            onClick={toggleChat}
          >
            <img src={line} alt="toggle chat" />
          </ChatButton>
          <ChatButton
            backgroundColor="rgba(11, 37, 88, 1)"
            onClick={toggleChat}
          >
            <img src={icon} alt="toggle chat" />
          </ChatButton>
        </Stack>
        <ChatbotContainer>
          <ChatbotMessages>
            {messages?.map((message, index) => (
              <>
                <Stack
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection:
                      message.isUser ||
                      message?.senderId == currentUser?.user?.fireBaseUid
                        ? "row-reverse"
                        : "row",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Avatar
                    src={
                      message.isUser || message?.senderId
                        ? currentUser?.avatar
                        : bot
                    }
                    style={{
                      margin: message.isUser ? "0 0 0 10px" : "0 10px 0 0",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                  <Message isUser={message.isUser}>
                    {/* <div dangerouslySetInnerHTML={{ __html: message.text }} /> */}
                    <pre style={{whiteSpace: "break-spaces", margin: "0px"}}>
                      {message.text}
                    </pre>
                  </Message>

                  {message.options && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: message.isUser ? "0" : "10px",
                        marginRight: message.isUser ? "10px" : "0",
                      }}
                    >
                      <button
                        onClick={() => {
                          setChatWithAi(true);
                          setQueryBox(true);
                        }}
                      >
                        AI
                      </button>
                    </div>
                  )}
                </Stack>
                {!message.isUser && chatWithAi && index === messages.length - 1 && (
                  <Box sx={{ textAlign: "end" }}>
                    <button
                      style={{
                        background: "#00adef",
                        border: "none",
                        color: "white",
                        borderRadius: "6px",
                        marginBottom: "5px",
                      }}
                      onClick={() => {
                        setQueryBox(true);
                        setChatWithAi(false);
                      }}
                    >
                      Switch to a Real Person
                    </button>
                  </Box>
                )}
              </>
            ))}
            {isLoading && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Avatar
                  src={bot}
                  style={{
                    margin: "0 10px 0 0",
                    width: "50px",
                    height: "50px",
                  }}
                />
                <ThreeDots
                  visible={true}
                  height="50"
                  width="50"
                  color="#00adef"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Stack>
            )}

            {queryBox && (
              <Box
                sx={{
                  border: "1px solid rgba(112, 112, 112, 1)",
                  borderRadius: "12px",
                  padding: "8px",
                }}
              >
                <TextareaAutosize
                  placeholder="Enter Your Query"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  style={{
                    background: "rgb(245, 245, 245)",
                    padding: "10px",
                    width: "100%",
                    border: "none",
                    borderRadius: "12px",
                    height: "80px",
                    resize: "none",
                    outline: "none",
                  }}
                />
                <FlexComp justifyContent="end" gridGap="10px">
                  <Button
                    text="Cancel"
                    width="auto"
                    padding="4px 15px"
                    backgroundColor="grey"
                    onClick={() => setQueryBox(false)}
                  ></Button>
                  <Button
                    text="Send"
                    width="auto"
                    padding="4px 15px"
                    onClick={handleSendQuery}
                  ></Button>
                </FlexComp>
              </Box>
            )}
          </ChatbotMessages>
          <div className="">
            {!isBotMessage && (
              <ChatbotInputForm>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "rgba(245, 245, 245, 1)",
                    borderRadius: "40px",
                  }}
                >
                  <input
                    type="text"
                    value={input}
                    placeholder="Reply to chat..."
                    style={{
                      width: "250px",
                      height: "50px",
                      background: "rgba(245, 245, 245, 1)",
                      border: "none",
                      borderRadius: "40px",
                      paddingLeft: "10px",
                      outline: "rgb(245, 245, 245)",
                    }}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <button style={{ background: "none", border: "none" }}>
                    <img src={file} alt="attach file" />
                  </button>
                  <span style={{ marginRight: "5px" }}>
                    <img src={emoji} alt="emoji" />
                  </span>
                </div>
                <ChatButton type="submit" onClick={() => handleSend()}>
                  <img src={arrow} alt="Send Message" />
                </ChatButton>
              </ChatbotInputForm>
            )}
            {isBotMessage && (
              <ChatbotInputForm>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "rgba(245, 245, 245, 1)",
                    borderRadius: "40px",
                  }}
                >
                  <input
                    type="text"
                    value={input}
                    placeholder="Reply to chat..."
                    style={{
                      width: "250px",
                      height: "50px",
                      background: "rgba(245, 245, 245, 1)",
                      border: "none",
                      borderRadius: "40px",
                      paddingLeft: "10px",
                      outline: "rgb(245, 245, 245)",
                    }}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    
                  />
                  <button style={{ background: "none", border: "none" }}>
                    <img src={file} alt="attach file" />
                  </button>
                  <span style={{ marginRight: "5px" }}>
                    <img src={emoji} alt="emoji" />
                  </span>
                </div>
                <ChatButton type="submit" onClick={handleSendMessage}>
                  <img src={arrow} alt="Send Message" />
                </ChatButton>
              </ChatbotInputForm>
            )}
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                color: "rgba(68, 93, 119, 1)",
              }}
            >
              Powered by{" "}
              <span style={{ color: "rgba(0, 51, 142, 1)" }}>
                Remedy Taxation
              </span>
            </p>
          </div>
        </ChatbotContainer>
      </ChatbotHeader>
    </Stack>
  );
};

export default Chat;
