import { useState } from "react";
import { ProfilePicBox, ProfilePicWrapper } from "./ProfilePic.style";
import { Span } from "../typography/Typography.style";
import PhotoAdd from "../../../assets/reusable/PhotoAdd.png";
import { Button } from "../button/Button";
import { Stack } from "@mui/material";

const ProfilePicUpload = ({setprofile, profile}) => {
  const [previewImage, setPreviewImage] = useState(profile);
  const [imageUploaded, setImageUploaded] = useState(false); // New state variable

  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setprofile(file)
    // Display preview of the uploaded image
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setImageUploaded(true); // Set imageUploaded to true when an image is uploaded
      };

      reader.readAsDataURL(file);
    }
  };

  // Function to handle removing the profile picture
  const handleRemoveProfilePic = () => {
    setPreviewImage(null);
    setprofile(null);
    setImageUploaded(false); // Reset imageUploaded when removing the profile picture
  };

  return (
    <ProfilePicWrapper>
      <Span color="#1B1B1B" fontSize="16px" fontWeight="500" style={{ width: "max-content"}}>
        Your Profile Picture
      </Span>
      <ProfilePicBox style={{ width: "150px", height: "132px" }}>
        <label htmlFor="file-upload">
          <div style={{ width: "100%", overflow: "hidden" }}>
            <img
              src={previewImage  || PhotoAdd}
              alt="Uploaded"
              style={
                !previewImage
                  ? { width: "56px", height: "56px", marginLeft: "13px" }
                  : {
                      width: "120px",
                      height: "99px",
                      objectFit: "cover",
                    }
              }
            />
          </div>
          {!previewImage && (
            <Span color="#1B1B1B" fontSize="12px" fontWeight="500">
              Upload photo
            </Span>
          )}
        </label>
        {/* Hidden file input */}
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
      </ProfilePicBox>
      {/* {imageUploaded && ( // Conditionally render the button when imageUploaded is true
      <Stack direction="row" justifyContent="center" alignItems="center" width="15%">
        <Button
          text="Remove"
          // width="10%"
          fontSize="14px"
          type="button"
          color="#AEB6CF"
          backgroundColor="#EFF3FD"
          onClick={handleRemoveProfilePic}
        />
      </Stack>
      )} */}
    </ProfilePicWrapper>
  );
};

export default ProfilePicUpload;
