import { useState } from "react";
import { Span } from "components/reusable/typography/Typography.style";
import styled from "styled-components";
import ProfileComp from "components/client/settings/Profile";

const Profile = () => {
  const [name, setName]=useState()
  return (
    <ProfileContainer>
      <Span color="#1B1B1B" fontSize="25px" fontWeight="500" style={{ padding: "0px 15px"}}>
        {name}
      </Span>
      <ProfileComp setName={setName}/>
    </ProfileContainer>
  );
};

export default Profile;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 30px;
  background: #fff;
  width: 100%;
  padding: 20px;
  height: calc(100vh -  100px);
  padding-bottom: 60px;
  min-height: 85vh;
  overflow-y: auto;
`;
