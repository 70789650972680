import React from "react";
import Search from "../../../assets/navbar/search.png";
import { Input, SearchIcon, SearchWrapper} from "./Navbar.style";

const Searchbar = ({ onKeyDown,onChange, searchTerm,backgroundColor,border ,color, placeholder="Search" }) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <SearchWrapper
      onClick={handleClick}
      style={{ width: "320px", border:border, borderRadius: "5px" }}
    >
      <SearchIcon src={Search} alt="Search" />
      <Input
        placeholder={placeholder}
        onChange={onChange}
        value={searchTerm}
        onKeyDown={onKeyDown}
        style={{ width: "100%",  outline: "none" ,backgroundColor:backgroundColor,border:border,color:color}}
      />
    </SearchWrapper>
  );
};

export default Searchbar;
