import { array, boolean, object, string } from "yup";

export const nonProfitFormationSchema = object().shape({
  basicInfo: object().shape({
    nonProfitName: string()
      .min(1, "Non-Profit Name must be at least 1 character")
      .max(50, "Non-Profit Name must be maximum 50 characters")
      .required("Non-Profit Name is required"),
    ein: string(),
    city: string()
      .min(1, "City must be at least 3 character")
      .max(50, "City must be maximum 50 characters")
      .required("City required"),
    street: string()
      .min(1, "Street must be at least3 character")
      .max(50, "Street must be maximum 50 characters")
      .required("Street required"),
    state: string()
      .required("State required"),
    zipCode: string()
      .required("Zip Code required"),
  }),
  // personOfContact: object().shape({
  //   firstName: string()
  //     .min(1, "First Name must be at least 3 character")
  //     .max(50, "First Name must be maximum 50 characters")
  //     .required("First Name required"),
  //   lastName: string()
  //     .min(1, "Last Name must be at least 3 character")
  //     .max(50, "Last Name must be maximum 50 characters")
  //     .required("Last Name required"),
  //   // middleName: string()
  //   //   .min(1, "Middle Name must be at least 3 character")
  //   //   .max(50, "Middle Name must be maximum 50 characters")
  //   //   .required("Middle Name required"),
  //   ssn: string()
  //     .required("EIN required"),
  //   title: string()
  //     .min(1, "Title must be at least 3 character")
  //     .max(50, "Title must be maximum 50 characters")
  //     .required("Title required"),
  //   mobileNumber: string()
  //     .required("Mobile Number required"),
  //   city: string()
  //     .min(1, "City must be at least 3 character")
  //     .max(50, "City must be maximum 50 characters")
  //     .required("City required"),
  //   street: string()
  //     .min(1, "Street must be at least 3 character")
  //     .max(50, "Street must be maximum 50 characters")
  //     .required("Street required"),
  //   state: string()
  //     .required("State required"),
  //   zipCode: string()
  //     .required("Zip Code required"),
  // }),
  // officersBoardTrustees: array().of(
  //   object().shape({
  //     firstName: string()
  //       .min(1, "First Name must be at least 3 character")
  //       .max(50, "First Name must be maximum 50 characters")
  //       .required("First Name required"),
  //     lastName: string()
  //       .min(1, "Last Name must be at least 3 character")
  //       .max(50, "Last Name must be maximum 50 characters")
  //       .required("Last Name required"),
  //     // middleName: string()
  //     //   .min(1, "Middle Name must be at least 3 character")
  //     //   .max(50, "Middle Name must be maximum 50 characters")
  //     //   .required("Middle Name required"),
  //     title: string()
  //       .min(1, "Title must be at least 3 character")
  //       .max(50, "Title must be maximum 50 characters")
  //       .required("Title required"),
  //     city: string()
  //       .min(1, "City must be at least 3 character")
  //       .max(50, "City must be maximum 50 characters")
  //       .required("City required"),
  //     street: string()
  //       .min(1, "Street must be at least 3 character")
  //       .max(50, "Street must be maximum 50 characters")
  //       .required("Street required"),
  //     state: string().required("State required"),
  //     zipCode: string()
  //       .required("Zip Code required"),
  //   })
  // ),
  
  // explaination: string()
  //   .min(1, "Explanation must be at least 3 character")
  //   .max(500, "Explanation must be maximum 500 characters")
  //   .required("Explanation required"),
  // organizationPurpose: string().required("Organization Purpose is required"),
  // charitableCheck: boolean(),
  // religiousCheck: boolean(),
  // educationalCheck: boolean(),
  // scientificCheck: boolean(),
  // literaryCheck: boolean(),
  // testingForPublicSafetyCheck: boolean()
});
