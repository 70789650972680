import React from "react";
import { StyledButton } from "./Button.style";
import { Dot } from "react-loading-dot"; 
export const Button = ({
  text,
  height,
  smHeight,
  width,
  fontSize,
  backgroundColor,
  alignSelf,
  padding,
  color,
  borderRadius,
  onClick,
  position,
  margin,
  border,
  marginTop,
  isLoading, 
  type = 'button',
}) => {
  return (
    <StyledButton
      width={width}
      height={height}
      smHeight={smHeight}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      alignSelf={alignSelf}
      padding={padding}
      color={color}
      borderRadius={borderRadius}
      position={position}
      onClick={onClick}
      margin={margin}
      border={border}
      marginTop={marginTop}
      disabled={isLoading}
      type={type}
    >
      {isLoading ? <Dot />: null}
      {text}
    </StyledButton>
  );
};
