import { useEffect, useState } from "react";
import styled from "styled-components";
import CustomTable from "components/reusable/customTable/CustomTable";
import detail from "assets/client/detail.png";
import { Button } from "@mui/material";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import dayjs from "dayjs";
import { apiReq } from "services/Requests";
import { API_BASE_URL, API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";
import ClientInformationModal from "components/modals/ClientInformationModal";

const errorToast = (message = "") => toast.error(message);

const AdminClient = () => {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [tableLoader, setTableLoader] = useState(true);
  const [clientData, setClientData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [modalData, setModalData] = useState();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({
    status: null,
    tag: "",
  });

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        setTableLoader(true);
        const response = await apiReq(
          API_ROUTES.getAllClients,
          "POST",
          {},
          API_BASE_URL,
          true
        );

        const responseData = await response.data;
        const updatedData = responseData?.data?.map((clientInfo, index) => ({
          id: index + 1,
          srno1: index + 1,
          fname: clientInfo?.firstName,
          lname: clientInfo?.lastName,
          email: clientInfo?.email,
          ssn: clientInfo?.presheetDetails?.ssn || "----",
          phone: clientInfo?.phone,
          createdDate: clientInfo?.createdAt,
          // type: clientInfo?.type || "----",
          otherdetails: clientInfo,
        }));
        setOriginalData(updatedData);
        setClientData(updatedData);
      } catch (error) {
        console.error("API Error:", error);
        errorToast(
          error?.message ||
            error?.response?.message ||
            "Error fetching data. Please try again later."
        );
      } finally {
        setTableLoader(false);
      }
    };

    fetchClientData();
  }, []);

  useEffect(() => {
    let filteredData = originalData;

    if (status.status && status.status !== "All Clients") {
      filteredData = filteredData.filter((item) => item.type === status.status);
    }

    if (search) {
      filteredData = filteredData.filter(
        (item) =>
          item.fname.toLowerCase().includes(search.toLowerCase()) ||
          item.lname.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    setClientData(filteredData);
  }, [status.status, search, originalData]);

  const formattedDate = (value) => {
    return dayjs(value).format("MM-DD-YYYY");
  };

  const handleInfoModalClose = () => {
    setOpenInfoModal(false);
  };

  const columns = [
    { field: "srno1", headerName: "Sr#.", width: 60, editable: false },
    {
      field: "fname",
      headerName: "First Name",
      width: 150,
      editable: false,
    },
    {
      field: "lname",
      headerName: "Last Name",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },
    {
      field: "ssn",
      headerName: "SSN",
      width: 150,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      editable: false,
    },
    // {
    //   field: "type",
    //   headerName: "Client Type",
    //   width: 150,
    //   editable: false,
    // },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 150,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setModalData(params.row?.otherdetails);
              setOpenInfoModal(true);
            }}
          >
            <img src={detail} alt="detail" />
          </Button>
        </div>
      ),
    },
  ];

  const options = [
    { label: "All Clients", value: "All Clients" },
    { label: "General Business", value: "General Business" },
    { label: "Rental Property", value: "Rental Property" },
    { label: "Truck Driver", value: "Truck Driver" },
    { label: "1099 Worksheet", value: "1099 Worksheet" },
    { label: "Business Formation", value: "Business Formation" },
    { label: "Non-Profit Formation", value: "Non-Profit Formation" },
    { label: "Personal Collection", value: "Personal Collection" },
  ];

  const onSelectChange = (selectedValues, key) => {
    setStatus({ ...status, [key]: selectedValues });
  };

  const handleOnChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Client Management"
          showButton={false}
          placeholder="Select Status"
          options={options}
          onSelectChange={onSelectChange}
          onChange={handleOnChange}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />

        <RentalPropertyWrapper>
          <CustomTable
            rows={clientData}
            loading={tableLoader}
            columns={columns}
            pinnedLeftColumns={["fname"]}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>

      <ClientInformationModal
        modalType="clientInformation"
        modalData={modalData}
        handleInfoModalClose={handleInfoModalClose}
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
      />
    </>
  );
};

export default AdminClient;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
