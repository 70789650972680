import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Stack } from "@mui/system";
import { ButtonIcon } from "components/reusable/button/IconButton";
import {
  DateInput,
  Input,
  InputWithButton,
} from "components/reusable/input/Inputs";
import { Fragment } from "react";
import styled from "styled-components";

const SopuseAccordion = ({
  item,
  index,
  openIndex,
  setOpenIndex,
  deleteExpense,
  setFieldValue,
  values,
  fieldToSet = "spouse",
}) => {
  const isOpen = index === openIndex;
  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  console.log("values.spouse:", values.spouse);
  console.log(
    "values.spouse.spouseDlDocFileName:",
    values?.spouse?.spouseDlDocFileName
  );

  console.log(values, "values")

  console.log("index", item);
  return (
    <Accordion
      sx={{ backgroundColor: "#EBEFF9" }}
      expanded={isOpen}
      onChange={toggleAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <AccordionTitle>
          {item.title}
          {index + 1}
          {/* kk */}
          <ButtonIcon
            type="button"
            borderRadius="30px"
            width="auto"
            height="25px"
            fontSize="14px"
            bgColor="none"
            margin
            icon={<CloseIcon sx={{ color: "black" }} />}
            clickHandler={(e) => {
              e.stopPropagation();
              deleteExpense(index);
            }}
          />
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <InputWrapper>
          {item?.fields?.map(
            ({ label, id, size, width, placeholder }, fieldIndex) => (
              <Fragment key={fieldIndex}>
                {id === "ssn" && (
                  <Input
                    label="SSN"
                    id="ssn"
                    size="size"
                    placeholder="XXX-XX-XXXX"
                    onChange={(e) => {
                      const formattedSSN = e.target.value
                        .replace(/\D/g, "")
                        .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
                      if (formattedSSN.length <= 11)
                        fieldToSet === "spouse"
                          ? setFieldValue(`spouse[${id}]`, formattedSSN)
                          : setFieldValue(
                              `dependent[${index}].${id}`,
                              formattedSSN
                            );
                    }}
                    value={values[id]}
                  />
                )}
                {id !== "dob" &&
                id !== "ssn" &&
                id !== "spouseDlDoc" &&
                id !== "dependentBirthCertificateDoc" ? (
                  <Input
                    label={label}
                    id={id}
                    size={size}
                    value={values[id]}
                    width={width}
                    placeholder={placeholder}
                    onChange={(e) => {
                      if (fieldToSet === "spouse")
                        setFieldValue(`spouse[${id}]`, e.target.value);
                      else
                        setFieldValue(
                          `dependent[${index}].${id}`,
                          e.target.value
                        );
                    }}
                  />
                ) : id === "dob" ? (
                  <DateInput
                    label={label}
                    width={width}
                    value={values?.[id]}
                    onChange={(date) => {
                      if (fieldToSet === "spouse")
                        setFieldValue(`spouse[${id}]`, date);
                      else setFieldValue(`dependent[${index}].${id}`, date);
                    }}
                  />
                ) : (
                  (id === "spouseDlDoc" ||
                    id === "dependentBirthCertificateDoc") && (
                    <Stack direction="column" sx={{ width: "40%" }}>
                      <InputWithButton
                        label={label}
                        id={id}
                        defaultValue=""
                        size="small"
                        placeholder=""
                        disabled
                        onFileSelect={(file) => {
                          if (fieldToSet === "spouse" && id === "spouseDlDoc") {
                            setFieldValue(`spouse.spouseDlDoc`, file);
                          } else if (
                            fieldToSet === "dependent" &&
                            id === "dependentBirthCertificateDoc"
                          ) {
                            setFieldValue(
                              `dependent[${index}].dependentBirthCertificateDoc`,
                              file
                            );
                          }
                        }}
                        value={
                          id === "spouseDlDoc"
                            ? values?.spouseDlDoc?.name ? values?.spouseDlDoc?.name : values?.spouseDlDocFileName ||
                              ""
                            : values?.dependentBirthCertificateDoc?.name ? values?.dependentBirthCertificateDoc?.name : values?.dependentBirthCertificateDocFileName
                        }
                        button={
                          <div>
                            <input
                              type="file"
                              id={id}
                              onChange={(event) => {
                                const file = event.currentTarget.files[0];
                                const fileName = file.name;
                                if (
                                  fieldToSet === "spouse" &&
                                  id === "spouseDlDoc"
                                ) {
                                  setFieldValue(`spouse.spouseDlDoc`, file);
                                  setFieldValue(
                                    `spouseDlDoc.spouceDlFilename`,
                                    fileName
                                  );
                                } else if (
                                  fieldToSet === "dependent" &&
                                  id === "dependentBirthCertificateDoc"
                                ) {
                                  setFieldValue(
                                    `dependent[${index}].dependentBirthCertificateDoc`,
                                    file
                                  );
                                  setFieldValue(
                                    `dependent[${index}].dependentBirthCertificateDocFileName`,
                                    "name"
                                  );
                                }
                              }}
                              style={{ display: "none" }}
                            />
                            <label htmlFor={id}>
                              <ButtonIcon
                                icon={<CloudUploadIcon />}
                                text={`Upload ${label}`}
                                fontSize="14px"
                              />
                            </label>
                          </div>
                        }
                      />
                      {console.log(values?.[index]?.dependentBirthCertificateDoc?.name, "checkDependetDoc")}
                    </Stack>
                  )
                )}
              </Fragment>
            )
          )}
        </InputWrapper>
        
      </AccordionDetails>
    </Accordion>
  );
};  

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export default SopuseAccordion;
