import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {
  AdditionalNotesContainer,
  AdditionalNotesHeader,
  AdditionalNotesBody,
} from "../../../components/modals/Modals.style";
import { Button } from "components/reusable/button/Button";
import { Span } from "components/reusable/typography/Typography.style";
import SecDropdownComp from "../../../components/reusable/dropdownComp/SecDropdown";
import FlexComp from "components/reusable/flex/Flex";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { apiReq } from "services/Requests";
import { useFormik } from "formik";
import { SyncLoader } from "react-spinners";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { DateInput, Input } from "components/reusable/input/Inputs";
import { toast } from "react-toastify";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const AssignTaskPopup = ({
  assignPopUp,
  setAssignPopUp,
  clientData,
  fetchUnassignedData,
  fetchAssignedData,
  activeButton,
}) => {
  const navigate = useNavigate();
  const adminId = localStorage.getItem("clientId");
  const [assignto, setAssignto] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [priority, setPriority] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [assignChange, setAssignChange] = useState(clientData.assignTo || "");

  const assigntotask = Array.isArray(assignto)
    ? assignto.map((client) => ({
        label: client.name,
        value: client.id,
      }))
    : [];
  const handleAssignChange = (event) => {
    setAssignChange(event.target.value);
    // formik.setFieldValue("assignTo", event.target.value);
    // formik.setFieldValue("staffId", event.target.value);
    // const selectedValue = event.target.value;
    // formik.setFieldValue("assignTo", selectedValue);
    // formik.setFieldValue("staffId", selectedValue);
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
    formik.setFieldValue("priority", event.target.value);
  };

  const AssignTaskTo = async () => {
    try {
      const response = await apiReq(API_ROUTES.AllUsers, "POST");
      const responseData = await response.data;

      const updatedData = Array.isArray(responseData?.data)
        ? responseData.data.map((item) => ({
            id: item?._id,
            name: item?.firstName + " " + item?.lastName,
            email: item?.email,
            role: item?.role,
          }))
        : [];
      setAssignto(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log('clientData in AssignTaskPopup >>>',)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientId: clientData?.clientId,
      formType: getFormTypes(clientData),
      personalCollectionTaskId: getTaskIds(clientData, 'personalCollection'),
      generalBusinessTaskId: getTaskIds(clientData, 'generalBusiness'),
      rentalPropertyTaskId: getTaskIds(clientData, 'rentalProperty'),
      truckDriverTaskId: getTaskIds(clientData, 'truckDriver'),
      assignBy: adminId,
      assignTo: assignChange ? assignChange : clientData?.staffInfo?.data?._id,
      assignToType: "Staff",
      endDateTime: "",
      priority: priority,
      startDateTime: "",
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setLoading(true);
        console.log("Form Values:", values); // Log form values
        const response = await apiReq(API_ROUTES.assignTask, "POST", values);
        console.log("API Response:", response); // Log API response
        setSuccess("New task added successfully");
        setError("");
        resetForm();
        infoToast("Task assigned successfully");
        
        // Conditionally call the appropriate fetch function based on activeButton
        if (activeButton === "Assigned Tasks") {
          fetchAssignedData();
        } else if (activeButton === "Unassigned Tasks") {
          fetchUnassignedData();
        }
      } catch (error) {
        console.error("API Error:", error);
        setSuccess("");
        setError(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setLoading(false);
        setSubmitting(false);
        setAssignPopUp(false);
      }
    },
  });

  useEffect(() => {
    AssignTaskTo();
  }, []);

  return (
    <Modal
      open={assignPopUp}
      onClose={() => setAssignPopUp(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <AdditionalNotesContainer>
        <AdditionalNotesHeader>
          <Span color="#FFFFFF" fontSize="16px" fontWeight="500">
            Assign Task To Staff
          </Span>
          <CloseIcon
            onClick={() => setAssignPopUp(false)}
            sx={{ color: "#FFFFFF", cursor: "pointer" }}
          />
        </AdditionalNotesHeader>
        <AdditionalNotesBody>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <RentalPropertyContainer>
              <h2 style={{ paddingLeft: "5px" }}>Assign Task To Staff</h2>
              <RentalPropertyWrapper>
                <Typography>
                Client Task Quantity:{" "}
                  {getFormCount(clientData)}
                </Typography>

                {/* <FlexComp direction="row" gridGap="25px" width="100%">
                  {clientData.staffInfo ? (
                    <Typography>
                      Assigned To: {clientData.staffInfo.data.firstName}{" "}
                      {clientData.staffInfo.data.lastName}
                    </Typography>
                  ) : (
                    <Stack direction="column" width={"32%"}>
                      <SecDropdownComp
                        label="Assign Task To *"
                        id="location-select"
                        placeholder="select"
                        size="small"
                        width="100%"
                        options={assigntotask}
                        value={formik.values.assignTo}
                        // options={assigntotask}
                        selectedMonth={selectedOption}
                        handleChange={handleAssignChange}
                      />
                    </Stack>
                  )}
                
                </FlexComp> */}
                <FlexComp direction="row" gridGap="25px" width="100%">
                  <Stack direction="column" width={"32%"}>
                    <SecDropdownComp
                      label="Assign Task To *"
                      id="location-select"
                      placeholder="select"
                      size="small"
                      width="100%"
                      options={assigntotask}
                      defaultValue={clientData?.staffInfo?.data?._id}
                      // options={assigntotask}
                      selectedMonth={selectedOption}
                      handleChange={(e) => handleAssignChange(e)}
                    />
                    {/* {console.log(clientData?.staffInfo?.data?.firstName, "clientData.staffInfo.data._id")} */}
                  </Stack>
                  <Stack direction="column" width={"32%"}>
                    <SecDropdownComp
                      label="Priority *"
                      id="location-select"
                      placeholder="select"
                      size="small"
                      width="100%"
                      options={[
                        { label: "high", value: "high" },
                        { label: "low", value: "low" },
                      ]}
                      defaultValue={clientData?.priority}
                      selectedMonth={selectedOption}
                      handleChange={handlePriorityChange}
                    />
                  </Stack>
                </FlexComp>
                <FlexComp direction="row" gridGap="25px" width="100%">
                  <Stack direction="column" width={"32%"}>
                    <DateInput
                      label="Start Date & Time *"
                      width="100%"
                      required
                      name="startDateTime"
                      value={clientData?.startDateTime}
                      onChange={(value) =>
                        formik.setFieldValue("startDateTime", value)
                      }
                    />
                  </Stack>
                  <Stack direction="column" width={"32%"}>
                    <DateInput
                      label="End Date & Time *"
                      width="100%"
                      required
                      name="endDateTime"
                      value={clientData?.endDateTime}
                      onChange={(value) =>
                        formik.setFieldValue("endDateTime", value)
                      }
                    />
                  </Stack>
                </FlexComp>
                <Stack direction="column" width={"92%"}>
                  <Input
                    label="Note "
                    id="outlined-size-small"
                    defaultValue=""
                    size="size"
                    width="100%"
                    height={50}
                    name="note"
                    placeholder="You can write your note here..."
                    onChange={formik.handleChange}
                    value={formik.values.note}
                  />
                </Stack>
                <FlexComp direction="row" gridGap="25px" width="100%">
                  <Button
                    type="submit"
                    text={
                      formik.isSubmitting ? (
                        <SyncLoader color={"#FFFFFF"} size={8} />
                      ) : (
                        "Send"
                      )
                    }
                    disabled={loading || formik.isSubmitting}
                    backgroundColor="rgba(11, 37, 88, 1)"
                    color="white"
                    width="112px"
                  />
                  <Button
                    text="Cancel"
                    onClick={() => navigate("/admin-queues")}
                    backgroundColor="rgba(239, 243, 253, 1)"
                    color="rgba(174, 182, 207, 1)"
                    width="112px"
                  />
                </FlexComp>
              </RentalPropertyWrapper>
            </RentalPropertyContainer>
          </form>
        </AdditionalNotesBody>
      </AdditionalNotesContainer>
    </Modal>
  );
};

export default AssignTaskPopup;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

// Helper functions to clean up the formik initialValues
function getFormTypes(clientData) {
  const formType = [];
  
  // Check for personal collection forms
  if (hasFormData(clientData, 'personalCollection')) {
    formType.push("Personal Collection");
  }
  
  // Check for general business forms
  if (hasFormData(clientData, 'generalBusiness')) {
    formType.push("General Business");
  }
  
  // Check for truck driver forms
  if (hasFormData(clientData, 'truckDriver')) {
    formType.push("Truck Driver");
  }
  
  // Check for rental property forms
  if (hasFormData(clientData, 'rentalProperty')) {
    formType.push("Rental Property");
  }
  
  return formType;
}

// Helper function to check if a form type has data
function hasFormData(clientData, formType) {
  if (!clientData) return false;
  
  switch(formType) {
    case 'personalCollection':
      return clientData?.personalCollectionTaskId?.length > 0 || 
             clientData?.personalCollection?.length > 0;
    
    case 'generalBusiness':
      return clientData?.generalBusinessTaskId?.length > 0 || 
             (clientData?.businessCollection?.generalBusiness &&
              clientData.businessCollection.generalBusiness.length > 0);
    
    case 'truckDriver':
      return clientData?.truckDriverTaskId?.length > 0 || 
             (clientData?.businessCollection?.truckDriverForms &&
              clientData.businessCollection.truckDriverForms.length > 0);
    
    case 'rentalProperty':
      return clientData?.rentalPropertyTaskId?.length > 0 || 
             (clientData?.businessCollection?.rentalProperties &&
              clientData.businessCollection.rentalProperties.length > 0);
    
    default:
      return false;
  }
}

// Helper function to get task IDs based on form type
function getTaskIds(clientData, formType) {
  if (!clientData) return [];
  
  switch(formType) {
    case 'personalCollection':
      return clientData?.personalCollectionTaskId || 
             (clientData?.personalCollection?.map(item => item._id) || []);
    
    case 'generalBusiness':
      return clientData?.generalBusinessTaskId || 
             (clientData?.businessCollection?.generalBusiness?.map(item => item._id) || []);
    
    case 'rentalProperty':
      return clientData?.rentalPropertyTaskId || 
             (clientData?.businessCollection?.rentalProperties?.map(item => item._id) || []);
    
    case 'truckDriver':
      return clientData?.truckDriverTaskId || 
             (clientData?.businessCollection?.truckDriverForms?.map(item => item._id) || []);
    
    default:
      return [];
  }
}

// Helper function to count the total number of forms
function getFormCount(clientData) {
  if (!clientData) return 0;
  
  // Initialize count
  let count = 0;
  
  // Count personal collection forms
  count += getTaskIds(clientData, 'personalCollection').length;
  
  // Count general business forms
  count += getTaskIds(clientData, 'generalBusiness').length;
  
  // Count rental property forms
  count += getTaskIds(clientData, 'rentalProperty').length;
  
  // Count truck driver forms
  count += getTaskIds(clientData, 'truckDriver').length;
  
  // Count non-profit formation forms
  count += clientData?.nonProfitFormationTaskId?.length || 0;
  
  // Count business formation forms
  count += clientData?.businessFormationTaskId?.length || 0;
  
  return count;
}
