export const entityInfo = [
  "Sole Proprietor",
  "Single Member LLC",
  "Multi Member LLC",
  "Partnership",
  "S Corp",
  "C Corp",
];

export const businessInfoFields = [
  {
    title: "Business Information (Vender# 1)",
    fields: [
  {
    label: "Individual First Name *",
    placeHolder: "Enter Individual First Name",
    defaultValue: "",
    id: "firstName",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Individual Middle Name",
    placeHolder: "Enter Individual Middle Name",
    defaultValue: "",
    id: "middleName",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Individual Last Name *",
    placeHolder: "Enter Individual Last Name",
    defaultValue: "",
    id: "lastName",
    size: "size",
    width: "49%",
    value: "",
  },
  {
    label: "Business Name *",
    placeHolder: "Enter",
    defaultValue: "",
    id: "businessName",
    size: "size",
    width: "49%",
    value: "",
  },
],
},
]
export const resourceFields = [
  {
    label: "Name",
    placeHolder: "Enter Name",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
  {
    label: "SSN",
    placeHolder: "Enter SSN",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
  {
    label: "Address",
    placeHolder: "Enter Address",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
];

export const partnerFields = [
  {
    label: "Name",
    placeHolder: "Enter Name",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
  {
    label: "SSN",
    placeHolder: "Enter SSN",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
  {
    label: "Address",
    placeHolder: "Enter Address",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
  {
    label: "Phone No",
    placeHolder: "Enter Phone no",
    defaultValue: "",
    id: "outlined-size-small",
    size: "size",
    width: "24%",
    value: "",
  },
];
