import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Span } from "components/reusable/typography/Typography.style";
import FlexComp from "components/reusable/flex/Flex";
import { Button } from "components/reusable/button/Button";
import { PasswordInput } from "components/reusable/input/Inputs";
import { ChangePasswordWrapper } from "components/staff/settings/Setting.style";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { SyncLoader } from "react-spinners";
import { Toaster } from "components/reusable/toaster";

const ChangePasswordComp = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const userId = localStorage.getItem("clientId");
        if (!userId) {
          throw new Error("User ID not found in localStorage");
        }

        await apiReq(API_ROUTES.updatePass, "POST", {
          userId: userId,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });

        setError("");
        setSuccess("Password updated successfully");
      } catch (error) {
        setSuccess("");
        const errorMessage =
          error?.message ||
          "An unexpected error occurred. Please try again later.";
        setError(errorMessage);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {/* Conditionally render the Toaster component based on success or error */}
      {(error || success) && (
        <Toaster
          open={!!error || !!success}
          content={error || success}
          type={error ? "error" : "success"}
          handleClose={() => {
            setError("");
            setSuccess("");
          }}
        />
      )}

      <form noValidate onSubmit={formik.handleSubmit}>
        {formik.errors.submitError && <p>Error: {formik.errors.submitError}</p>}
        <ChangePasswordWrapper gridGap="30px">
          <Span color="#1B1B1B" fontSize="20px" fontWeight="500">
            Enter Old Password
          </Span>
          <PasswordInput
            label="Old Password *"
            placeholder="*******"
            id="oldPassword"
            type="password"
            size="size"
            style={{ width: "32%" }}
            {...formik.getFieldProps("oldPassword")}
          />
          {formik.touched.oldPassword && formik.errors.oldPassword && (
            <div style={{ color: "red" }}>{formik.errors.oldPassword}</div>
          )}
        </ChangePasswordWrapper>

        <ChangePasswordWrapper gridGap="30px">
          <Span color="#1B1B1B" fontSize="20px" fontWeight="500">
            Enter New Password
          </Span>
          <PasswordInput
            label="New Password *"
            placeholder="*******"
            id="newPassword"
            type="password"
            size="size"
            style={{ width: "32%" }}
            {...formik.getFieldProps("newPassword")}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div style={{ color: "red" }}>{formik.errors.newPassword}</div>
          )}
          <PasswordInput
            label="Confirm Password *"
            placeholder="*******"
            id="confirmPassword"
            type="password"
            size="size"
            style={{ width: "32%" }}
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div style={{ color: "red" }}>{formik.errors.confirmPassword}</div>
          )}
        </ChangePasswordWrapper>

        <FlexComp direction="row" gridGap="20px" alignItems="center">
          <Button
            type="submit"
            text={
              formik.isSubmitting ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Update"
              )
            }
            width="40%"
            fontSize="14px"
            backgroundColor="#0B2558"
            alignSelf="end"
            padding="15px 20px"
            disabled={formik.isSubmitting}
          />
          <Button
            type="button"
            text="Cancel"
            width="40%"
            fontSize="14px"
            backgroundColor="#EFF3FD"
            alignSelf="end"
            padding="15px 20px"
            color="#AEB6CF"
          />
        </FlexComp>
      </form>
    </>
  );
};

export default ChangePasswordComp;
