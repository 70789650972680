import { useEffect, useState } from "react";
import styled from "styled-components";
import StaffSummaryComp from "../../../components/staff/dashboard/StaffSummary";
// import StackBarChart from "../../../components/charts/StackBarChart";
// import HeadingChart from "../../../components/reusable/charts/HeadingChart";
import TaskBarChart from "../../../components/reusable/charts/TaskBarChart";
// import MonthlyChart from "../../../components/reusable/charts/MonthlyChart";
// import { MyTaskData, TaskData } from "../../../data/staffData/ChartData"
import StaffQueueStatus from "../../../components/staff/dashboard/StaffQueueStatus";
import StatusSummery from "components/admin/dashboard/ReturnStats";
// import axios from "axios";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";

const StaffDashboard = () => {
  const [userData, setUserData] = useState(null);
  let getuserData = localStorage.getItem('userData');
  getuserData = JSON.parse(getuserData);
  const fetchAllData = async () => {
    try {
      const response = await apiReq(API_ROUTES.staffDashboard, "POST", { staffId: getuserData?.user?._id })

      const responseData = await response.data;
      setUserData(responseData)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const totalCompleted = userData?.data?.monthlyCompletedQueue ?
  Object.values(userData.data.monthlyCompletedQueue).reduce((acc, value) => acc + value, 0) :
  0;

const totalPending = userData?.data?.monthlyPendingQueue ?
  Object.values(userData.data.monthlyPendingQueue).reduce((acc, value) => acc + value, 0) :
  0;
  
  return (
    <Container>
      {userData && <DashboardContainer>
        {/* ---TopSection--- */}
        <StaffSummaryComp showExportButton={false} staff={"staff"} userData={userData.data} />
        <Card>
          <CardContent>
            <ChartHeading>Status Summary</ChartHeading>
            <LineChartContainer>
              {userData && <StatusSummery userData={userData.data} staff={"staff"} />}

            </LineChartContainer>
          </CardContent>
        </Card>
      </DashboardContainer>}
      {userData && <DashboardContainer>
        <Card2>
          <CardContent>
            <ChartHeading>My Weekly Task Report</ChartHeading>
            <LineChartContainer>
              <TaskBarChart
                data={userData.data}
                barWidth={12} // Customize bar width
                legendNames={["Pending Task", "Completed"]}
                width={500} // Customize legend names
              />

            </LineChartContainer>
          </CardContent>
        </Card2>
        <Card2>
          <CardContent>
            <ChartHeading>Monthly Task Report</ChartHeading>
            <LineChartContainer>
              <TaskBarChart
                data={userData.data}
                barWidth={20} // Customize bar width
                legendNames={["Pending Task", "Completed"]}
                width={450} // Customize legend names
                staffMonthlyreport={"staffMonthlyreport"}
              />
              <div style={{ display: 'flex', fontWeight: '400', justifyContent: 'center' }}><div style={{ paddingRight: '30px' }} >{totalPending}</div><div style={{ paddingLeft: '30px' }}>{totalCompleted}</div></div>
            </LineChartContainer>
          </CardContent>
        </Card2>

      </DashboardContainer>}
      {userData && <DashboardContainer>
        <BottomSec>
          <StaffQueueStatus userData={userData?.data} admin={"staff"} />
        </BottomSec>
      </DashboardContainer>}
    </Container>
  );
};

export default StaffDashboard;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  width: 100%;
`;
const Container = styled.div`
   display: flex;
  flex-direction: column; 
  grid-gap: 20px; 
  width: 100%;
`;
const Card = styled.div`
  width: 608.07px;
  height: auto;
  border-radius: 20px;
  background-color: white;
`;

const Card2 = styled.div`
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: white;
`;

const CardContent = styled.div`
  padding: 20px;
  height: auto;
`;

const ChartHeading = styled.h2`
  margin-bottom: 20px;
  padding-left:10px
`;

const LineChartContainer = styled.div`
  width: 100%;
  height: calc(100% - 60px); /* Adjusted for padding and margin of heading */
`;

const BottomSec = styled.div`
  display: flex;
 
  align-items: center;
  width: 100%;
`;
