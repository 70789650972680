import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { Button } from "../reusable/button/Button";
import { HeadingFive } from "../reusable/typography/Typography.style";
import {
  BulletList,
  BulletListItem,
  EmailVerContainer,
  ModalHeading,
  ModalText,
  Resend,
} from "./Modals.style";

const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const EmailVerModal = ({ open, onClose, onVerify, email }) => {
  const [otp, setOtp] = useState("000000");
  const [timer, setTimer] = useState(300); // 5 minutes timer in seconds
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    let interval;
    if (open && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [open, timer]);

  const handleVerify = () => {
    onVerify(otp);
  };

  const handleResend = async () => {
    try {
      setIsResending(true);
      const response = await apiReq(API_ROUTES.accountOTPEmail, "POST", {
        email,
        role: "Client",
      });
      if (response.status === 200) {
        infoToast("New OTP has been sent to your email.");
        setIsResending(false);
        setTimer(300); // Reset timer to 5 minutes
      } else {
        errorToast("Failed to resend OTP. Please try again later.");
        setIsResending(false);
      }
    } catch (error) {
      errorToast(
        "An error occurred while resending OTP. Please try again later."
      );
      setIsResending(false);
    }
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <EmailVerContainer>
        <ModalHeading>
          <HeadingFive fontSize="36px">Email Verification</HeadingFive>
          <CloseIcon
            sx={{ color: "#000", fontSize: "40px", cursor: "pointer" }}
            onClick={onClose}
          />
        </ModalHeading>
        <ModalText>
          <p style={{ fontSize: "16px" }}>
            A 6-digit code has been sent to{" "}
            <span style={{ color: "rgba(0, 173, 239, 1)" }}>{email}</span>
          </p>
        </ModalText>
        <OTPInput
          containerStyle={{
            width: "100%",
          }}
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "20%",
            fontSize: "50px",
            borderRadius: "10px",
            border: "1px solid #00000033",
            margin: "10px",
            padding: "10px",
            color: "#40485C80",
          }}
        />
        <Button
          text="Verify and Continue"
          width="100%"
          fontSize="18px"
          onClick={handleVerify}
        />
        <BulletList>
          <BulletListItem>
            Time remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </BulletListItem>
          <BulletListItem>
            {isResending ? (
              "Resending..."
            ) : (
              <p>
                Didn't receive the code?{" "}
                <Resend
                  style={{ cursor: "pointer", textDecorationLine: "underline" }}
                  onClick={handleResend}
                >
                  Resend
                </Resend>
              </p>
            )}
          </BulletListItem>
        </BulletList>
      </EmailVerContainer>
    </Modal>
  );
};

export default EmailVerModal;
