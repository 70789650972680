import { useState, useRef, useEffect } from "react";
import FlexComp from "../../reusable/flex/Flex";
import { Span } from "../../reusable/typography/Typography.style";
import SignaturePad from "react-signature-canvas";
import { Button } from "../../reusable/button/Button"; // Update import path

const Draw = ({ onSaveDrawnSignature, drawnSignature, handleClearDrawnSignature }) => {
  const sigCanvas = useRef(null);

  const clearCanvas = () => {
    sigCanvas.current.clear();
    handleClearDrawnSignature?.();
  };

  const saveSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      return;
    }
    onSaveDrawnSignature(sigCanvas);
  };

  return (
    <FlexComp
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      gridGap="20px"
    >
      <FlexComp alignItems="center" justifyContent="space-between">
        <Button
          text="Clear"
          type="button"
          width="12%"
          fontSize="18px"
          backgroundColor="#00ADEF"
          padding="5px 15px"
          borderRadius="5px"
          onClick={clearCanvas}
        />
        {drawnSignature ? <img style={{ border: "2px solid #1b1b1b", borderRadius: "10px"}} src={drawnSignature} width={300} height={100} at="" />: null}
      </FlexComp>
      <SignaturePad
        ref={sigCanvas}
        canvasProps={{
          className: "signatureCanvas",
        }}
      />
      <Button
        text="Save Signature"
        width="20%"
        type="button"
        fontSize="18px"
        backgroundColor="#00ADEF"
        padding="5px 15px"
        borderRadius="5px"
        onClick={saveSignature}
      />
      <Span>
        By selecting Adopt and Sign, I agree that the signature and initials
        will be the electronic representation of my signature and initials for
        all purposes when I (or my agent) use them on documents, including
        legally binding contracts - just the same as a pen-and-paper signature
        or initial.
      </Span>
    </FlexComp>
  );
};

export default Draw;
