import Auth from "../../../assets/auth/Auth.png";
import { AuthPicWrapper, SignupContainer } from "../signup/Signup.style";
import AdminForgetPass from "./AdminForgetPass";

const AdminForgetPassword = () => {
  return (
    <SignupContainer>
      <AdminForgetPass />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default AdminForgetPassword;
