import { useState,useEffect } from "react";
import styled from "styled-components";
import CustomTable from "components/reusable/customTable/CustomTable";
import { Button } from "@mui/material";
import StaffHeader from "components/reusable/header/StaffHeader";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";

const errorToast = (message = '') => toast.error(message);

const AdminSupportListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tab_loader, setTabLoader] = useState(true);
  const [originaldata, setoriginalData] = useState([])
  const [, setSearchName] = useState("");
  const [selectedVal, setSelectedValues] = useState()
  const handleMenuClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setEditId(params._id)
  };
  const handleSearchByName = (selectedValues) => {
    let filteredData = originaldata
    setSearchName(selectedValues?.target?.value);
if (originaldata){
  if (selectedVal && selectedVal !== "Select All") {
    filteredData = filteredData.filter((item) =>  
    item.status === selectedVal)};
     if(selectedValues?.target?.value){
      filteredData = originaldata?.filter((item) =>
      item.subject.toLowerCase().includes(selectedValues.target.value.toLowerCase())
    )
     };
    setData(filteredData);
  }};
  useEffect(()=>{
    handleSearchByName()
    // eslint-disable-next-line
  },[selectedVal])

  const handleMenuItemClick = async (status) => {
    setAnchorEl(null);
    try {
      await apiReq(API_ROUTES.supportTicketUpdate, "POST", { ticketId: editId, status })
      fetchData()
      
    } catch (error) {
      errorToast(error?.message || 'Error updating status. please try again later.');
    }
  };

  const fetchData = async () => {
    const requestBody = {
      page: "1",
      dataPerPage: "5",
      statusFilter: "",
      searchById: ""
    };
    try {
      setTabLoader(true);
      const response = await apiReq(API_ROUTES.supportTicketAdminGetAll, "POST", requestBody)

      const dataValue = response.data?.data?.staffTickets.map((ticket, index) => ({
         ...ticket, srNumber: (index + 1).toString().padStart(2, '0') }));
         setData(dataValue)
         setoriginalData(dataValue)
    } catch (error) {
      errorToast(error?.message || 'Error updating status. please try again later.');
    } finally {
      setTabLoader(false);
    }
  };

  const onSelectChange = (selectedValues) => {
    // Check if the selected value is 'Data Entry'
    setSelectedValues(selectedValues)
  };

  const individualColumns = [
    {
      field: "srNumber",
      headerName: "Sr#.",
      flex: 1,
      editable: false,
    },
    {
      field: "ticketNo",
      headerName: "Ticket Number",
      flex: 1,
      editable: false,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      editable: false,
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.priority === "High" ? "rgba(215, 21, 21, 1)" : "rgba(89, 169, 93, 1)",
            borderRadius: "15px",
            padding: "5px 10px"
          }}
        >
          {params.row.priority}
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Reported Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return dayjs(params.row.updatedAt).format('MMM DD, YYYY');
      }
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return dayjs(params.row.createdAt).format('MMM DD, YYYY');
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <>
          <div
            style={{
              backgroundColor: "rgba(244, 184, 27, 0.3)",
              color: "rgba(208, 150, 0, 1)",
              borderRadius: "15px",
              minWidth: '92px',
              padding: "5px",
              cursor: "pointer", // Change cursor to pointer to indicate clickable
            }}
            onClick={(e) => handleMenuClick(e, params.row)}
          >
            {params.row.status}
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleMenuItemClick('Pending')}>Pending</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Resolved')}>Resolved</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Rejected')}>Rejected</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Processing')}>Processing</MenuItem>
          </Menu>
        </>
      ),
    },

  ];

  const options = [
    { label: "Pending", value: "Pending" },
    { label: "Resolved", value: "Resolved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Processing", value: "Processing" },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton={false} // Pass the button visibility state
          btntext="+ Add New Ticket"
          clickHandler={() => navigate("/admin-support-form")}
          onSelectChange={onSelectChange} // Pass the onSelectChange function
          showFirstSelect={true}
          options={options}
          placeholder='Select Status'
          onChange={handleSearchByName}
        />
        <Line border='0.0999999999999999px solid rgba(239, 243, 253, 1)' />
        <Button />
        {/* //support */}
        {/* //support */}
        {/* <ToggleButton setActiveButton={setActiveButton} activeButton={activeButton} /> */}
        <h5 style={{ paddingLeft: '5px' }}>My Task</h5>
        <RentalPropertyWrapper>
          {/* <CustomTable rows={individualRows} columns={individualColumns} /> */}
           <CustomTable rows={data} loading={tab_loader} columns={individualColumns} />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
    </>
  );
};

export default AdminSupportListing;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
