import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import AddNewBusiness from "components/modals/AddNewBusiness";
import UploadAndLoss from "components/modals/UploadAndLoss";
import { IconStyledButton } from "components/reusable/button/Button.style";
import CustomTable from "components/reusable/customTable/CustomTable";
import StaffHeader from "components/reusable/header/StaffHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as XLSX from "xlsx";

import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import ShowClientModal from "pages/staff/staffQueue/ShowClientModal";
const formattedDate = (value) => {
  const date = dayjs(value);
  return date.format("MM-DD-YYYY");
};

const errorToast = (message = "") => toast.error(message);
const getStatusStyles = (status) => {
  let styles = {
    backgroundColor: "",
    color: "black",
    borderRadius: "10px",
    padding: "5px",
    textAlign: "center",
    // display: 'inline-block', // Ensure the div does not take full wid
    Width: "100%",
  };

  switch (status) {
    case "Data Completed":
      styles.backgroundColor = "#ede0bf";
      styles.color = "#D09600";
      break;
    case "Tax Return Filled":
      styles.backgroundColor = "#eff0ca";
      styles.color = "#888A01";
      break;
    case "Tax Return Rejected":
      styles.backgroundColor = "#ebc0c0";
      styles.color = "#D01717";
      break;
    case "Tax Return Completed":
      styles.backgroundColor = "#d9f5dc";
      styles.color = "#22C132";
      break;
    default:
      styles.backgroundColor = "white";
      styles.color = "black";
      break;
  }

  return styles;
};

const TaxFillingListing = () => {
  const [tab_loader, setTabLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [individualData, setIndividualData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState("Select All");
  const [searchName, setSearchName] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [data, setData] = useState([]);
  const formattedDate = (value) => {
    const date = dayjs(value);
    const formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  };
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [modalData, setModalData] = useState();

  const fetchData = async () => {
    try {
      setTabLoader(true);
      const adminId = localStorage.getItem("clientId");
      const response = await apiReq(API_ROUTES.taxFilling, "POST", {
        adminId: adminId,
      });

      const responseData = await response.data;
      setResponseData(responseData);
      setData(responseData);
      const updatedData = responseData?.data?.map((item, index) => {
        return {
          id: index + 1,
          srno1: index + 1,
          cname: item?.clientId?.firstName + " " + item?.clientId?.lastName,
          totalForms: item?.tasks?.length,
          cd: item?.startDateTime,
          enddate: item?.endDateTime,
          assignby: item?.assignBy?.firstName + " " + item?.assignBy?.lastName,
          updateby: item?.prepSheetId?.ssn,
          updateon: item?.createdAt,
          priority: item?.priority,
          status: item?.status,
          otherDetails: item?.tasks,
        };
      });

      setIndividualData(updatedData);

      setOriginalData(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      errorToast("An unexpected error occurred. Please try again later.");
    } finally {
      setTabLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [statusFilter, searchName]);

  const handleSearchByName = (e) => {
    setSearchName(e.target.value);
  };
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenuItemClick = async (status, option) => {
    setAnchorEl(null);

    try {
      const tasksData = rowData?.otherDetails || [];
      const queueId = tasksData.map((item, index) => {
        return item._id;
      });

      if (queueId.length === 0) {
        errorToast("No tasks found to update status.");
        return;
      }
      const requestData = {
        queueId,
        status: option,
      };
      const updateResponse = await apiReq(
        API_ROUTES.queuesStatusUpdate,
        "POST",
        requestData
      );

      console.log("updateResponse", updateResponse);
      toast.success("Status updated successfully");
      fetchData();
    } catch (error) {
      errorToast(error.message || "Failed to update status. Please try again.");
    }
  };

  const handleClick = (event, id, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedStatus(id);
    setRowData(row);
  };
  console.log(anchorEl, "anchorEl");

  // const handleClose = () => {
  //   setAnchorEl(null);
  //   setSelectedStatus("");
  // };
  const flattenData = (data, parent = "", res = {}) => {
    for (let key in data) {
      let propName = parent ? `${parent}.${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null) {
        if (Array.isArray(data[key])) {
          // Flatten each item in the array
          data[key].forEach((item, index) => {
            flattenData(item, `${propName}[${index}]`, res);
          });
        } else {
          // Recursively flatten object
          flattenData(data[key], propName, res);
        }
      } else {
        res[propName] = data[key];
      }
    }
    return res;
  };

  // Function to export data to Excel
  const exportToExcel = (data, filename = "ExportedData") => {
    const flattenedData = data.map((item) => flattenData(item));
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };
  const handleExport = () => {
    console.log("excel data", responseData);
    exportToExcel(responseData.data, "DownloadedData");
  };

  const flattenObject = (obj, parent = "", res = {}) => {
    for (let key in obj) {
      let propName = parent ? parent + "." + key : key;
      if (typeof obj[key] == "object" && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const individualColumns = [
    { field: "srno1", headerName: "Sr#", width: 10 },
    { field: "cname", headerName: "Client Name", width: 150 },
    { field: "totalForms", headerName: "Total Forms", width: 100 },
    {
      field: "cd",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "enddate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    // { field: "assignby", headerName: "Assigned By", width: 150 },
    {
      field: "updateby",
      headerName: "Updated By",
      width: 150,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "updateon",
      headerName: "Updated On",
      width: 150,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    { field: "priority", headerName: "Priority", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={getStatusStyles(params.row.status)}
            onClick={(event) => handleClick(event, params.row.id, params.row)}
          >
            {params.row.status}
            {/* <img src={menuIcon} alt='' /> */}
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              "& .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper ": {
                boxShadow: "0px 0px 0px 0.1px rgba(0, 0, 0, 0.3);",
                borderRadius: "10px",
              },
            }}
          >
            {[
              "Data Completed",
              "Tax Return Filled",
              "Tax Return Rejected",
              "Tax Return Completed",
            ].map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(params.value, option)}
              >
                {console.log(params.value, "checkOArasaa")}
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
    },
    {
      headerName: "Action",
      width: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <div>
          <IconStyledButton
            height="40px"
            variant="text"
            color="white"
            fontSize="12px"
            // onClick={handleExport}
            onClick={() => {
              setModalData(params.row.otherDetails);
              setGeneralBusinessModal(true);
            }}
          >
            <DownloadIcon />
            Download
          </IconStyledButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let filteredData = originalData;
    if (statusFilter && statusFilter !== "Select All") {
      filteredData = filteredData.filter(
        (item) => item.status === statusFilter
      );
    }
    if (searchName) {
      filteredData = filteredData.filter((item) =>
        item.cname.toLowerCase().includes(searchName.toLowerCase())
      );
    }
    setIndividualData(filteredData);
  }, [statusFilter, searchName, originalData]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const searchStaff = async (searchValue) => {
    try {
      console.log(searchValue);
      const filteredAssignData = originalData.filter((item) => {
        return item?.cname?.toLowerCase().includes(searchValue?.toLowerCase());
        // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
      });

      setIndividualData(filteredAssignData);
      // Adjust page size if needed
    } catch (error) {}
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          text="Example with Select Fields"
          showButton={false}
          btntext="Click Me"
          showFirstSelect={true}
          placeholder="Select Status"
          options={[
            { label: "Select ALL", value: "Select All" },
            { label: "Accountant Review", value: "Accountant Review" },
            { label: "Tax Return Rejected", value: "Tax Return Rejected" },
            { label: "Tax Return Completed", value: "Tax Return Completed" },
          ]}
          onChange={handleSearchByName}
          onSelectChange={setStatusFilter}
          searchStaff={searchStaff}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />
        <RentalPropertyWrapper>
          <CustomTable
            loading={tab_loader}
            rows={individualData}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <AddNewBusiness
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        clickHandler={() => navigate("/add-rental")}
        setIsUploadModal={setIsUploadModal}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#FAFAFA",
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem
        // onClick={goTOProfile}
        >
          <ListItemIcon></ListItemIcon>
          <ListItemText
            sx={{
              color: "black",
            }}
          >
            Deactivate
          </ListItemText>
        </MenuItem>
        <div style={{ border: "1px soild grey" }}></div>
        <MenuItem
        //  onClick={goTOLogout}
        >
          <ListItemIcon>{/* <img src={lock} alt="vector"  /> */}</ListItemIcon>
          <ListItemText sx={{ color: "black" }}>
            Duplicate this rule
          </ListItemText>
        </MenuItem>
      </Menu>
      <UploadAndLoss
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
      />
      <ShowClientModal
        modalData={modalData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        // handleYesClick={handleYesClick}
        download="true"
      />
    </>
  );
};

export default TaxFillingListing;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
