import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend,ResponsiveContainer } from 'recharts';

const HeadingChart = () => {
  const data = [
    { name: "Page A", "My Task": 4000, "Completed Task": 4000, "New Task": 7000 },
    { name: "Page B", "My Task": 7000, "Completed Task": 4000, "New Task": 6010 },
    { name: "Page C", "My Task": 4000, "Completed Task": 7800, "New Task": 2290 },
    { name: "Page D", "My Task": 4000, "Completed Task": 6008, "New Task": 2000 },
    { name: "Page E", "My Task": 4000, "Completed Task": 5800, "New Task": 7081 },
    { name: "Page F", "My Task": 2390, "Completed Task": 3800, "New Task": 2500 },
    { name: "Page G", "My Task": 3490, "Completed Task": 4300, "New Task": 4000 }
  ];

  return (
    <div>
      <style>
        {`
          .recharts-cartesian-axis-line {
            display: none;
          }
          .recharts-text {
            font-size: 12px;
          }
          .recharts-legend-item-text {
            font-size: 12px;
          }
          .recharts-legend-icon {
            width: 12px !important;
            height: 12px !important;
          }
        `}
      </style>
      <ResponsiveContainer height={250} width="100%">
      <LineChart width={500} height={250} data={data}
                 margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend iconType="square" className="legend" />
        <Line type="monotone" dataKey="My Task" stroke="rgba(9, 74, 190, 1)" fill="rgba(9, 74, 190, 1)" strokeWidth={2} />
        <Line type="monotone" dataKey="Completed Task" stroke="rgba(11, 37, 88, 1)" fill="rgba(11, 37, 88, 1)" strokeWidth={2} />
        <Line type="monotone" dataKey="New Task" stroke="rgba(0, 173, 239, 1)" fill="rgba(0, 173, 239, 1)" strokeWidth={2} />
     </LineChart>
     </ResponsiveContainer>
    </div>
  );
};

export default HeadingChart;
