import {
  AuthPicWrapper,
  SignupContainer,
} from "components/auth/signup/Signup.style";
import Auth from "assets/auth/Auth.png";
import ClientLogin from "components/auth/clientLogin/ClientLogin";

const ClientSignin = () => {
  return (
    <SignupContainer>
      <ClientLogin />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default ClientSignin;
