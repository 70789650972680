import { useState, useEffect } from "react";
import CustomTable from "components/reusable/customTable/CustomTable";
import styled from "styled-components";
import TitleComponent from "components/reusable/titleComponent/TitleComponent";
import AddNewBusiness from "components/modals/AddNewBusiness";
import { useNavigate } from "react-router";
import UploadAndLoss from "components/modals/UploadAndLoss";
import edit from "assets/client/edit.png";
import detail from "assets/client/detail.png";
import { IconButton, Stack } from "@mui/material";
import GeneralBusiness from "components/modals/review/ReviewConfirmation/GeneralBusiness";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { FaLess } from "react-icons/fa6";
import ConfirmationModal from "components/modals/ConfirmationModal";
import AdditionalNotes from "components/modals/AdditionalNotes";
import ValidateTaxModal from "components/modals/ValidateTaxModal";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YYYY");
  return formattedDate;
};
const errorToast = (message = "") => toast.error(message);
const infoToast = (message = "") => toast.info(message);

const BusinessGenaralList = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const clientId = localStorage.getItem("clientId");
  const [tab_loader, setTabLoader] = useState(true);
  const [isAdditionalModal, setIsAdditionalModal] = useState(false);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [, setLoading] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [additionalNotes, setadditionalnotes] = useState();
  const [payroll, setPayroll] = useState(false);
  const [contract, setContract] = useState(false);
  const [fileName, setFileName] = useState({
    payRollDoc: "",
    contractDoc: "",
  });
  const [fileData, setFileData] = useState({
    payRollDoc: null,
    contractDoc: null,
  });

  const generalBusinessId = localStorage.getItem("formsId");

  const handleFormSubmit = async () => {
    try {
      // Prepare data to be uploaded
      setLoading(true);
      const uploadData = { additionalNotes, generalBusinessId };

      await apiReq(API_ROUTES.updateGeneralBusiness, "POST", uploadData);
      infoToast("Info saved successfully");
      localStorage.setItem("editForm", false);
      setTimeout(() => {
        navigate("/general-business");
      }, 1500);

      // localStorage.removeItem('generalBusinessMainId');
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoader(true);
        const response = await apiReq(
          API_ROUTES.generalBusinessGetAll,
          "POST",
          { clientId }
        );
        const responseData = await response.data;
        const updatedData = responseData?.data?.map((businessInfo) => ({
          id: businessInfo?._id,
          businessName: businessInfo?.businessInfoId?.businessName,
          ownerName:
            businessInfo?.businessInfoId?.firstName +
            " " +
            businessInfo?.businessInfoId?.lastName,
          businessStartDate: businessInfo?.businessInfoId?.businessStartDate,
          ftype: businessInfo?.type,
          formProgress: businessInfo?.completionPercentage,
          status: businessInfo?.status,
          comments: businessInfo?.additionalNotes,
          fillingYear: businessInfo?.createdAt,
          otherDetails: businessInfo,
        }));

        setBusinessData(updatedData);
      } catch (error) {
        errorToast(
          error?.message ||
            error?.response?.message ||
            "Error fetching data. please try later."
        );
      } finally {
        setTabLoader(false);
      }
    };

    console.log("businessData", businessData);

    fetchData();
    localStorage.removeItem("currentBusinessRow");
    localStorage.removeItem("businessInfoId");
    localStorage.removeItem("generalBusinessMainId");
    localStorage.setItem("editForm", false);
    // eslint-disable-next-line
  }, []);

  const BGColumns = [
    {
      field: "businessName",
      headerName: "Business Name",
      width: 140,
      editable: false,
    },
    { field: "ownerName", headerName: "Owner Name", width: 200 },
    {
      field: "businessStartDate",
      headerName: "Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div>{formattedDate(params.value)}</div> // Assuming you have a formattedDate function
      ),
    },
    {
      field: "ftype",
      headerName: "Form Type",
      width: 200,
      editable: false,
    },
    {
      headerName: "Form Progress",
      field: "formProgress",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <div>{params.value}%</div> // Assuming you have a formattedDate function
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "Data Entery"
                ? "rgba(0, 173, 239, 0.16)"
                : "rgba(244, 184, 27, 0.3)",
            color: params.row.status === "Data Entery" ? "#0095CE" : "#D09600",
            borderRadius: "15px",
            padding: "5px 1 0px 5px 10px",
            fontSize: "12px",
          }}
        >
          {params.row.formProgress < 100 ? "Incomplete" : "Verification Needed"}
        </div>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 280,
      editable: false,
    },
    {
      headerName: "Action",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="space-around">
          {params.row.status == "Verification Needed" && (
            <IconButton
              color="primary"
              onClick={() => {
                localStorage.setItem(
                  "currentBusinessRow",
                  JSON.stringify(params.row.otherDetails)
                );
                localStorage.setItem(
                  "generalBusinessID",
                  JSON.stringify(params.row.otherDetails._id)
                );
                localStorage.setItem("editForm", true);
                navigate("/add-business");
              }}
            >
              <img src={edit} alt="edit" width={30} height={25} />
            </IconButton>
          )}
          <IconButton
            color="primary"
            onClick={() => {
              setModalData(params.row.otherDetails);
              setGeneralBusinessModal(!generalBusinessModal);
            }}
          >
            <img src={detail} alt="detail" width={30} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];
  return (
    <>
      <BusinessListContainer>
        <TitleComponent
          text="General Business"
          showButton
          btntext="Add New Business"
          clickHandler={() => setIsOpen(!isOpen)}
        />
        <BusinessListWrapper>
          <CustomTable
            loading={tab_loader}
            rows={businessData}
            columns={BGColumns}
          />
        </BusinessListWrapper>
      </BusinessListContainer>
      <AddNewBusiness
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uploadDocText="Upload Business Doc"
        clickHandler={() => navigate("/add-business")}
        setIsUploadModal={setIsUploadModal}
        setIsConfirmationModal={setIsConfirmationModal}
      />
      <UploadAndLoss
        modalHeading="Upload Business Document"
        buttonText="Upload Business Document"
        generalBusiness="generaBusiness"
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        setIsConfirmationModal={setIsConfirmationModal}
        payroll={payroll}
        contract={contract}
        fileName={fileName}
        fileData={fileData}
      />
      <GeneralBusiness
        modalData={modalData}
        generalBusinessData={modalData}
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      />
      <ConfirmationModal
        buttonText="Upload Business Document"
        isUploadModal={isUploadModal}
        payroll={payroll}
        fileData={fileData}
        setFileData={setFileData}
        setPayroll={setPayroll}
        contract={contract}
        setContract={setContract}
        fileName={fileName}
        setFileName={setFileName}
        setIsUploadModal={setIsUploadModal}
        ConfirmAtionData="General"
        modalHeading="Mandatory Field For General Business"
        isConfirmationModal={isConfirmationModal}
        setIsConfirmationModal={setIsConfirmationModal}
        setIsAdditionalModal={setIsAdditionalModal}
      />
      <AdditionalNotes
        isModalOpen={isAdditionalModal}
        closeModal={() => setIsAdditionalModal(false)}
        openSecondModal={() => setIsValidateTaxModal(true)}
        additionalNotes={additionalNotes}
        setadditionalnotes={setadditionalnotes}
      />
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(false)}
        paragraph="Are you sure you want to validate this Business Info Form?"
        handleYesClick={handleFormSubmit}
      />
    </>
  );
};

export default BusinessGenaralList;

const BusinessListContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const BusinessListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
