import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import { Button } from "components/reusable/button/Button";
import StaffHeader from "components/reusable/header/StaffHeader";
import SimpleSelect from "components/reusable/select/SimpleSelect";
import { ErrorMessage, Formik } from "formik";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as Yup from "yup";

const options = [
  { value: "Staff", label: "Staff" },
  { value: "Client", label: "Client" },
];

const initialValues = {
  sendTo: "",
  toEmail: "",
  subject: "",
  message: "",
};

const validationSchema = Yup.object({
  sendTo: Yup.string().required("is required"),
  toEmail: Yup.string().email("Invalid email format").required("is required"),
  subject: Yup.string().required("subject is required"),
  message: Yup.string().required("message is required"),
});

const AdminCompose = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sideBarData, setSideBarData] = useState([
    { id: "0", value: "All Sent Messages", label: "0" },
    { id: "1", value: "Staff", label: "0" },
    { id: "2", value: "Client", label: "0" },
    { id: "3", value: "Admin", label: "0" },
    { id: "4", value: "Deleted Items", label: "0" },
  ]);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const userId = localStorage.getItem("clientId");

  const fetchMessages = async () => {
    try {
      const response = await apiReq(API_ROUTES.messageGetAll, "POST", {
        userId,
      });
      const messages = response.data.data || [];
      let messageCounts = {
        "All Sent Messages": messages.filter((message) => !message.isDeleted)
          .length,
        Staff: messages.filter(
          (message) => message?.sendBy === "Staff" && !message.isDeleted
        ).length,
        Client: messages.filter(
          (message) => message?.sendTo === "Client" && !message.isDeleted
        ).length,
        Admin: messages.filter(
          (message) => message?.sendBy === "Admin" && !message.isDeleted
        ).length,
        "Deleted Items": messages.filter(
          (message) => message.isDeleted === true
        ).length,
      };
      messages.forEach((message) => {
        if (message.sendTo === "Staff" && message.sendBy === "Admin") {
          messageCounts["Staff"]++;
        } else if (message.sendTo === "Client" && message.sendBy === "Admin") {
          messageCounts["Client"]++;
        }
      });
      setSideBarData((prevState) =>
        prevState.map((item) => ({
          ...item,
          label: (messageCounts[item.value] || 0).toString(),
        }))
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [userId]);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      setLoading(true);
      await apiReq(API_ROUTES.messageAdd, "POST", {
        userId: localStorage.getItem("clientId"),
        sendBy: "Admin",
        sendTo: values.sendTo,
        toEmail: values.toEmail,
        subject: values.subject,
        message: values.message,
      });
      toast.success("Message sent successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoading(false);
      resetForm();
      // Reset SunEditor contents
      if (window.sunEditor) {
        window.sunEditor.setContents("");
      }

      setSubmitting(false);
    } catch (error) {
      console.error("Error submitting message:", error);
      // Show error toast
      toast.error("Failed to send message. Please try again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchEmailSuggestions = async (type, value) => {
    try {
      const response = await apiReq(API_ROUTES.searchEmail, "POST", {
        type,
        value,
      });
      if (response.data?.data.length > 0) {
        const emailData = response.data.data.map(
          (data) => `${data.email} - ${data.firstName} ${data.lastName}`
        );
        setEmailSuggestions(emailData);
        setEmailError("");
      } else {
        setEmailSuggestions([]);
        setEmailError("Email not found in database");
      }
    } catch (error) {
      console.error("Error fetching email suggestions:", error);
    }
  };
  console.log(emailSuggestions, "emailS");

  const debouncedFetchEmailSuggestions = debounce((type, value) => {
    fetchEmailSuggestions(type, value);
  }, 300);

  return (
    <RentalPropertyContainer>
      <ToastContainer />
      <StaffHeader
        text="Example with Select Fields"
        showButton={false}
        btntext="+ Compose New"
        clickHandler={() => navigate("/add-ticket")}
        showFirstSelect={false}
        showSecondSelect={false}
        placeholder="Select Status"
      />
      <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
      <RentalPropertyWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4} lg={3}>
                  <SideBoxStyle>
                    {sideBarData.map((item) => (
                      <SideBoxButtonStyle
                        key={item.id}
                        onClick={() => navigate("/admin-messages")}
                      >
                        <SideBoxTitleStyle>{item.value}</SideBoxTitleStyle>
                        <div>{item.label}</div>
                      </SideBoxButtonStyle>
                    ))}
                  </SideBoxStyle>
                </Grid>

                <Grid item xs={8} lg={8}>
                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography sx={{ marginBottom: "10px" }}>
                      Compose Message
                    </Typography>
                    <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
                  </Box>
                  <SimpleSelect
                    label="Select Person Type"
                    id="select-person-type"
                    width={230}
                    menuItems={{ placeholder: "", items: options }}
                    name="sendTo"
                    selectedValues={formikProps.values.sendTo}
                    handleChange={(e) => {
                      formikProps.setFieldValue("sendTo", e.target.value);
                    }}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage
                    name="sendTo"
                    component="div"
                    className="error-message"
                  />

                  <Autocomplete
                    freeSolo
                    options={emailSuggestions}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To (Email)"
                        name="toEmail"
                        id="toEmail"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={formikProps?.values?.toEmail}
                        onChange={(e) => {
                          formikProps.setFieldValue("toEmail", e.target.value);
                          debouncedFetchEmailSuggestions(
                            formikProps.values.sendTo,
                            e.target.value
                          );
                        }}
                        // onBlur={formikProps.handleBlur}
                      />
                    )}
                    onChange={(event, value) => {
                      // Extract only the email part
                      const emailOnly = value ? value.split(" - ")[0] : "";
                      formikProps.setFieldValue("toEmail", emailOnly);
                    }}
                  />
                  <ErrorMessage
                    name="toEmail"
                    component="div"
                    className="error-message"
                  />
                  {emailError && (
                    <div className="error-message">{emailError}</div>
                  )}
                  <Input
                    placeholder="Subject"
                    sx={{ width: "100%" }}
                    name="subject"
                    value={formikProps.values.subject}
                    onChange={(e) =>
                      formikProps.setFieldValue("subject", e.target.value)
                    }
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="error-message"
                  />
                  <SunEditor
                    placeholder="Type Message"
                    height="300px"
                    name="message"
                    setContents={formikProps.values.message}
                    setDefaultStyle="font-family: Arial; font-size: 14px;"
                    getSunEditorInstance={(editor) => {
                      // Store editor instance for reset functionality
                      window.sunEditor = editor;
                    }}
                    onChange={(content) => {
                      const newValue = content === "<p><br></p>" ? "" : content;
                      formikProps.setFieldValue("message", newValue);
                      if (!newValue) {
                        formikProps.setFieldTouched("message", true);
                      }
                    }}
                    onBlur={() => formikProps.setFieldTouched("message", true)}
                  />
                  <Button
                    height="45px"
                    width="100px"
                    color="white"
                    backgroundColor="rgba(11, 37, 88, 1)"
                    type="submit"
                    text={
                      loading ? (
                        <SyncLoader color={"#FFFFFF"} size={8} />
                      ) : (
                        "Send"
                      )
                    }
                    margin="10px"
                  />
                  <Button
                    borderRadius="5px"
                    height="45px"
                    width="100px"
                    color="rgba(174, 182, 207, 1)"
                    backgroundColor="rgba(239, 243, 253, 1)"
                    text="Cancel"
                    margin="10px"
                    onClick={() => navigate("/admin-messages")}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </RentalPropertyWrapper>
    </RentalPropertyContainer>
  );
};

export default AdminCompose;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;

const SideBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  background: #eff3fd;
  width: 100%;
  padding: 20px;
  min-height: 50vh;
`;

const SideBoxButtonStyle = styled.div`
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const SideBoxTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
