import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { HeadingFive, Span } from "../../reusable/typography/Typography.style";
import TextCheckbox from "../../reusable/textCheckbox/textCheckbox";
import { DateInput, Input } from "../../reusable/input/Inputs";
import { ButtonIcon } from "../../reusable/button/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import FlexComp from "../../reusable/flex/Flex";
import MultiPartnerField from "../multiPartnerShip/MultiPartnerField";
import { stateOptions } from "data/clientData/businessFormation";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import { MuiPhone } from "components/reusable/PhoneNumber";

const fillStatus = [
  {
    label: "Sole Proprietor",
    key: "soleProprietorCheck",
    name: "SoleProprietor",
  },
  {
    label: "Single Member LLC",
    key: "singleMemberLlcCheck",
    name: "SingleMemberLlc",
  },
  {
    label: "Multi Member LLC",
    key: "multiMemberLlcCheck",
    name: "MultiMemberLlc",
  },
  { label: "Partnership", key: "partnershipCheck", name: "Partnership" },
  { label: "S Corp", key: "sCorpCheck", name: "SCorp" },
  { label: "C Corp", key: "cCorpCheck", name: "CCorp" },
];

const WorksheetAccordion = ({
  item,
  index,
  isOpen,
  values,
  errors,
  touched,
  onDelete,
  setFieldValue,
  toggleAccordion,
  selectedEntityInfo,
}) => {
  const [entityInformation, setEntityInformation] = useState(null);
  const deleteAccordion = () => {
    onDelete(index);
  };

  const resetCheckboxes = () => {
    for (let val of fillStatus) {
      setFieldValue(`businessInfoArr[${index}].${val.key}`, false);
    }
  };
  useEffect(() => {
    if (selectedEntityInfo) {
      const { type } = selectedEntityInfo;
      setEntityInformation(type || null);
    }
  }, [selectedEntityInfo]);

  return (
    <div>
      <Accordion
        sx={{ backgroundColor: "#EBEFF9" }}
        expanded={isOpen}
        onChange={toggleAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <AccordionTitle>
            {item.title}
            {index !== 0 && (
              <ButtonIcon
                borderRadius="30px"
                width="auto"
                height="25px"
                fontSize="14px"
                bgColor="none"
                icon={<CloseIcon sx={{ color: "black" }} />}
                clickHandler={(e) => {
                  e.stopPropagation();
                  deleteAccordion();
                }}
              />
            )}
          </AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <InputWrapper>
            {/* Display entityInfo */}
            <EntityInfoWrapper>
              <Span color="#000000" fontSize="16px" fontWeight="500">
                Entity Info
              </Span>
              {item?.fields.length &&
                fillStatus.map((item, index1) => (
                  <TextCheckbox
                    text={item.label}
                    name={item.name}
                    id={item.name}
                    gridGap="1px"
                    key={index1}
                    isChecked={values?.[item.key]}
                    onChange={(e) => {
                      resetCheckboxes();
                      setFieldValue(
                        `businessInfoArr[${index}].${item.key}`,
                        e.target.checked
                      );
                      setFieldValue(
                        `businessInfoArr[${index}].entityInfo`,
                        item.name
                      );
                    }}
                  />
                ))}
            </EntityInfoWrapper>

            {/* Display fields */}
            {item?.fields?.map((field, idx) => (
              <Stack key={idx} direction="column" sx={{ width: field.width }}>
                <Input
                  label={field.label}
                  id={field.id}
                  value={values?.[field.id]} // Set default value from Formik values
                  size={field.size}
                  placeholder={field.placeholder}
                  onChange={(e) => {
                    setFieldValue(
                      `businessInfoArr[${index}].${field.id}`,
                      e.target.value
                    );
                  }} // Update Formik values on change
                />
                <FormHelperText error>
                  {touched?.[field.id] && errors?.[field.id]}
                </FormHelperText>
              </Stack>
            ))}
            <Stack direction="column" sx={{ width: "49%" }}>
              <MuiPhone
                value={values?.mobileNumber}
                handleChange={(value) =>
                  setFieldValue(`businessInfoArr[${index}].mobileNumber`, value)
                }
              />
              <FormHelperText error>
                {touched?.mobileNumber && errors?.mobileNumber}
              </FormHelperText>
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Input
                label="EIN *"
                id="ssn"
                size="size"
                placeholder="XX-XXXXXXX"
                onChange={(e) => {
                  const formattedSSN = e.target.value
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{7})/, "$1-$2");
                  if (formattedSSN.length <= 10)
                    setFieldValue(
                      `businessInfoArr[${index}].ssn`,
                      formattedSSN
                    );
                }}
                value={values?.ssn}
              />
              <FormHelperText error>
                {touched?.ssn && errors?.ssn}
              </FormHelperText>
            </Stack>
            <FlexComp direction="column" gridGap="25px" width="100%">
              <HeadingFive fontSize="16px" fontWeight="500" color="black">
                Address
              </HeadingFive>
              <FlexComp direction="row" gridGap="25px" width="100%">
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="Street *"
                    id="street"
                    defaultValue=""
                    size="size"
                    placeholder="Enter"
                    value={values?.street}
                    onChange={(e) =>
                      setFieldValue(
                        `businessInfoArr[${index}].street`,
                        e.target.value
                      )
                    }
                  />
                  <FormHelperText error>
                    {touched?.street && errors?.street}
                  </FormHelperText>
                </Stack>
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="City *"
                    id="city"
                    defaultValue=""
                    size="size"
                    placeholder="Enter"
                    value={values?.city}
                    onChange={(e) =>
                      setFieldValue(
                        `businessInfoArr[${index}].city`,
                        e.target.value
                      )
                    }
                  />
                  <FormHelperText error>
                    {touched?.city && errors?.city}
                  </FormHelperText>
                </Stack>
                <Stack direction="column" sx={{ width: "32%" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={stateOptions}
                    value={values?.state}
                    onChange={(_, newVal) => {
                      setFieldValue(
                        `businessInfoArr[${index}].state`,
                        newVal?.value || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State *" />
                    )}
                  />
                  <FormHelperText error>
                    {touched?.state && errors?.state}
                  </FormHelperText>
                </Stack>

                <Stack direction="column" sx={{ width: "32%" }}>
                  <Input
                    label="Zip Code *"
                    id="zipCode"
                    defaultValue=""
                    size="size"
                    placeholder="Enter"
                    value={values?.zipCode}
                    onChange={(e) => {
                      let zipCodeValue = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 9);
                      if (zipCodeValue.length > 5) {
                        zipCodeValue =
                          zipCodeValue.slice(0, 5) +
                          "-" +
                          zipCodeValue.slice(5);
                      }
                      setFieldValue(
                        `businessInfoArr[${index}].zipCode`,
                        zipCodeValue
                      );
                    }}
                  />
                  <FormHelperText error>
                    {touched?.zipCode && errors?.zipCode}
                  </FormHelperText>
                </Stack>
              </FlexComp>
            </FlexComp>

            <Stack direction="column" sx={{ width: "29%" }}>
              <Input
                label="All money Paid *"
                id="amount-paid"
                name="amountPaid"
                value={values?.amountPaid || ""}
                onChange={(e) => {
                  let val = e.target.value.replace(/\D/g, "").slice(0, 9);
                  setFieldValue(`businessInfoArr[${index}].amountPaid`, val);
                }}
                placeholder="$"
              />
              <FormHelperText error>
                {touched?.amountPaid && errors?.amountPaid}
              </FormHelperText>
            </Stack>

            {/* Tax Year */}
           
            {values?.partnershipCheck && (
              <MultiPartnerField
                text="Partners"
                btnText="+ Add Member"
                errors={errors?.patnersArr}
                multiMemberLlcArr={values?.patnersArr}
                setarrayData={(updatedArray) => {
                  setFieldValue(
                    `businessInfoArr[${index}].patnersArr`,
                    updatedArray
                  );
                }}
              />
            )}
            {values?.multiMemberLlcCheck && (
              <MultiPartnerField
                text="Multi Member LLC"
                btnText="+ Add Member"
                name="multiMemberLlcArr"
                id="multiMemberLlcArr"
                required
                errors={errors?.multiMemberLlcArr}
                multiMemberLlcArr={values?.multiMemberLlcArr}
                setarrayData={(updatedArray) => {
                  setFieldValue(
                    `businessInfoArr[${index}].multiMemberLlcArr`,
                    updatedArray
                  );
                }}
              />
            )}

            {values?.sCorpCheck && (
              <MultiPartnerField
                text="S Corp Members"
                btnText="+ Add Member"
                required
                errors={errors?.sCorpMembersArr}
                multiMemberLlcArr={values?.sCorpMembersArr}
                setarrayData={(updatedArray) => {
                  setFieldValue(
                    `businessInfoArr[${index}].sCorpMembersArr`,
                    updatedArray
                  );
                }}
              />
            )}
            {values?.cCorpCheck && (
              <MultiPartnerField
                text="C Corp Shareholders"
                btnText="+ Add Member"
                errors={errors?.cCorpShareholdersArr}
                multiMemberLlcArr={values?.cCorpShareholdersArr}
                setarrayData={(updatedArray) => {
                  setFieldValue(
                    `businessInfoArr[${index}].cCorpShareholdersArr`,
                    updatedArray
                  );
                }}
              />
            )}
          </InputWrapper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const InputWrapper = styled.div`
  display: flex;
  grid-gap: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const EntityInfoWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  grid-gap: 25px;
  flex-wrap: wrap;
`;

const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export default WorksheetAccordion;
