import Logo from "assets/auth/Logo.png";
import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { Button } from "../../reusable/button/Button";
import {
  FieldWrapper,
  FormLogo,
  FormSec,
  FormWrapper,
  StyledInput
} from "../signup/Signup.style";
import { ForgetPasswordWrapper, LoginMessageWrapper } from "./Signin.style";

const ResetPass = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPass, setShowPass] = useState(false);
  const [spinner] = useState(false); // Add spinner state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetPassword = async (values) => {
    try {
      if (!formData.password || !formData.confirmPassword) {
        alert("Password fields cannot be empty");
        return;
      }
      const resetEmail = localStorage.getItem("resetEmail");
      const response = await apiReq(API_ROUTES.updateUserPassword, "POST", {
        email: resetEmail,
        password: formData.password,
      });
      console.log("Response:", response);
      if (response.status === 200) {
        alert("password reset successfully");
        navigate("/");
      } else {
        console.error("Password reset request failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during password reset:", error.message);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      onSubmit={handleResetPassword}
    >
      <FormWrapper>
        <FormSec gridGap="30px">
          <FormLogo>
            <img src={Logo} alt="Logo" />
          </FormLogo>
          <LoginMessageWrapper>
            <h5>Change Password</h5>
            <p>Enter new Password</p>
          </LoginMessageWrapper>

          <ForgetPasswordWrapper>
            <FieldWrapper>
              <p>Password</p>
              <StyledInput
                type={showPass ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <h5 onClick={() => setShowPass(!showPass)}>
                {showPass ? "HIDE" : "SHOW"}
              </h5>
              <ErrorMessage name="username" component="div" />
            </FieldWrapper>
            <FieldWrapper>
              <p>Confirm Password</p>
              <StyledInput
                type={showPass ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <h5 onClick={() => setShowPass(!showPass)}>
                {showPass ? "HIDE" : "SHOW"}
              </h5>
              <ErrorMessage name="username" component="div" />
            </FieldWrapper>
          </ForgetPasswordWrapper>
          <Button
            text={
              spinner ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Reset Password"
              )
            } // Change button text based on loading state
            width="100%"
            fontSize="18px"
            disabled={spinner}
            onClick={() => {
              handleResetPassword();
            }}
          />
          {/* <AlreadyAcc>
            <p>Don't have a account? </p>{" "}
            <h5 onClick={() => navigate("/")}> Login</h5>
          </AlreadyAcc> */}
        </FormSec>
      </FormWrapper>
    </Formik>
  );
};

export default ResetPass;
