import WorkSheetComp from "components/client/workSheet/WorkSheet";
import styled from "styled-components";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import WorkSheetList from "components/client/workSheet/WorkSheetList";
import { Box } from "@mui/material";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const WorkSheet = () => {
  const navigate = useNavigate()
  const [isTermModal, setIsTermModal] = useState(false);
  return (
    <WorkSheetContainer>
       <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
      <HeadingFive fontSize="20px" fontWeight="500">
        1099 Worksheet (Vender Information)
      </HeadingFive>
      <Box sx={{display: "flex", alignItems: "center", gap: "20px"}}>
      <TitleButton onClick={() => navigate("/add-worksheet")}>Add New Worksheet</TitleButton>
      <TitleButton onClick={() => setIsTermModal(true)} >Submit All Docs</TitleButton>

      </Box>
      </Box>
      <WorkSheetList isTermModal={isTermModal} setIsTermModal={setIsTermModal} />
    </WorkSheetContainer>
  );
};

export default WorkSheet;

export const WorkSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;
