import React from "react";
import {
  AuthPicWrapper,
  SignupContainer,
} from "../../components/auth/signup/Signup.style";
import Auth from "../../assets/auth/Auth.png";
import SigninComp from "../../components/auth/signin/SigninComp";
import ForgetPass from "../../components/auth/signin/ForgetPass";
import ResetPass from "../../components/auth/signin/ResetPass";

const ResetPassword = () => {
  return (
    <SignupContainer>
      <ResetPass />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default ResetPassword;
