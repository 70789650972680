import { useContext, useEffect, useState } from "react";
import {
  FixedAssetContainer,
  FixedAssetInputWrapper,
} from "./BusinessCollection.style";
import {
  DateInput,
  Input,
  InputWithAdornment,
} from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { IconStyledButton } from "components/reusable/button/Button.style";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFormik } from "formik";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoaderContext } from "context/PageLoaderProvider";
import CloseIcon from "@mui/icons-material/Close";
const infoToast = (message = "") => toast.info(message);
const errorToast = (message = "") => toast.error(message);

const FixedAssets = ({ setCurrentTab, rowData }) => {
  const [Data, setData] = useState();
  const clientId = localStorage.getItem("clientId");
  const { setOpen } = useContext(PageLoaderContext);
  const [assets, setAssets] = useState([{ id: Date.now() }]);
  const menuTabs = JSON.parse(localStorage.getItem("tabs") || "{}");
  const generalBusinessMainId = localStorage.getItem("generalBusinessMainId");
  const editForm = localStorage.getItem("editForm");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fixedAssetsArr: Data
        ? Data.fixedAssetsArr.map((asset) => ({
            assetName: asset.assetName,
            price: asset.price,
            date: asset.date,
          }))
        : [],
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Prepare data to be uploaded
        // const uploadData = { ...values, clientId, generalBusinessMainId };
        let uploadData;
        if (editForm === "true" && Data?._id) {
          uploadData = {
            ...values,
            clientId,
            fixedAssetsId: Data?._id,
          };
        } else {
          uploadData = {
            ...values,
            clientId,
            generalBusinessMainId: generalBusinessMainId
              ? generalBusinessMainId
              : rowData?._id,
          };
        }
        const { data: { data = {} } = {} } = await apiReq(
          !Data?._id ? API_ROUTES.addFixedAssets : API_ROUTES.updateFixedAssets,
          "POST",
          uploadData
        );
        localStorage.setItem("fixedAssetsId", data?._id);
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, vehicle_info: false })
        );
        infoToast(
          Data?._id
            ? "Fixed assets info updated succesfully"
            : "Fixed assets info saved succesfully"
        );
        setTimeout(() => {
          setCurrentTab(3);
        }, 1500);
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  console.log(rowData?._id, generalBusinessMainId, "adsdasdasdasdasasd");

  const handleAddAsset = () => {
    const newAsset = { assetName: "", price: "", date: "" };
    setAssets([...assets, newAsset]);
    formik.setFieldValue("fixedAssetsArr", [
      ...formik.values.fixedAssetsArr,
      newAsset,
    ]);
  };
  const handleDateChange = (index, date) => {
    const updatedAssets = [...formik.values.fixedAssetsArr];
    updatedAssets[index].date = date;
    formik.setFieldValue(`fixedAssetsArr[${index}].date`, date);
  };

  const handleRemoveAsset = (index) => {
    const updatedAssets = assets.filter((_, i) => i !== index);
    setAssets(updatedAssets);
    const updatedFixedAssetsArr = [...formik.values.fixedAssetsArr];
    updatedFixedAssetsArr.splice(index, 1);
    formik.setFieldValue("fixedAssetsArr", updatedFixedAssetsArr);
    // formik.setFieldValue("fixedAssetsArr", updatedAssets.map(asset => ({
    //   assetName: asset.assetName,
    //   price: asset.price,
    //   date: asset.date
    // })));
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        setOpen(true);
        const { data: { data = {} } = {} } = await apiReq(
          API_ROUTES.getGeneralBusinessByID +
            (rowData?._id || generalBusinessMainId),
          "POST",
          { clientId: clientId }
        );
        setData(data?.fixedAssetsId);
        localStorage.setItem(
          "tabs",
          JSON.stringify({ ...menuTabs, vehicle_info: false })
        );
      } catch (error) {
        errorToast(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
        // Handle error
      } finally {
        setOpen(false);
      }
    };

    if (rowData?._id || generalBusinessMainId) fetchdata();
  }, [rowData?._id]);
  console.log(Data, "Data");

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <FixedAssetContainer>
          <HeadingFive color="#000" fontSize="18px" fontWeight="500">
            Fixed Assets
          </HeadingFive>
          {formik.values.fixedAssetsArr.map((asset, index) => (
            <FixedAssetInputWrapper key={index}>
              <Input
                label="Asset Name"
                id={`asset-name-${index}`}
                value={asset.assetName}
                onChange={formik.handleChange}
                name={`fixedAssetsArr[${index}].assetName`}
                size="size"
                width="31%"
                placeholder="i.e furniture, jewelry... "
              />
              <InputWithAdornment
                label="Enter Price"
                value={asset.price}
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, "").slice(0, 9);
                  formik.setFieldValue(`fixedAssetsArr[${index}].price`, val);
                }}
                name={`fixedAssetsArr[${index}].price`}
                size="size"
                width="31%"
                id={`asset-price-${index}`}
              />
              <DateInput
                label="Date"
                width="100%"
                value={asset.date}
                onChange={(date) => handleDateChange(index, date)} // Pass index and date to handleDateChange
                name={`fixedAssetsArr[${index}].date`}
              />
              {index > 0 && (
                <IconStyledButton
                  width="auto"
                  type="button"
                  bgColor="none"
                  border="none"
                  borderRadius="50%"
                  onClick={() => handleRemoveAsset(index)}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </IconStyledButton>
              )}
            </FixedAssetInputWrapper>
          ))}
          <Button
            type="button" // Set type to button
            text="+ Add Asset"
            width="auto"
            fontSize="18px"
            padding="12px 25px 12px 25px"
            onClick={handleAddAsset}
          />
          <Button
            type="submit"
            text={
              formik.isSubmitting ? (
                <SyncLoader color={"#FFFFFF"} size={8} />
              ) : (
                "Next"
              )
            }
            disabled={formik.isSubmitting}
            width="10%"
            fontSize="20px"
            padding="10px 40px"
            backgroundColor="#0B2558"
            alignSelf="flex-end"
          />
        </FixedAssetContainer>
      </form>
    </>
  );
};

export default FixedAssets;
