import { Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ButtonIcon } from "components/reusable/button/IconButton";
import { Span } from "components/reusable/typography/Typography.style";
import {
  AddBusinessBtnWrapper,
  AddBusinessCloseIcon,
  AddNewBusinessContainer,
} from "./Modals.style";

const AddNewBusiness = ({
  isOpen,
  setIsOpen,
  clickHandler,
  uploadDocText = 'Upload Profit & Loss',
  setIsUploadModal,
  setIsConfirmationModal,

}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <AddNewBusinessContainer>
        <AddBusinessCloseIcon>
          <CloseIcon
            onClick={() => setIsOpen(!isOpen)}
            sx={{ cursor: "pointer" }}
          />
        </AddBusinessCloseIcon>
        <Span color="#000000" fontSize="20px" fontWeight="500">
          Add New Business
        </Span>
        <AddBusinessBtnWrapper>
          <ButtonIcon
            bgColor="#00ADEF"
            icon={<CloudUploadIcon />}
            text={uploadDocText}
            fontSize="14px"
            padding="10px 15px !important"
            clickHandler={() => {
              setIsUploadModal(true);
              // setIsConfirmationModal(true)
              setIsOpen(false);
         
            }}
          />
          <ButtonIcon
            bgColor="#0B2558"
            icon={<AddIcon />}
            text="Add Data Manually"
            padding="10px 15px !important"
            fontSize="14px"
            clickHandler={clickHandler}
          />
        </AddBusinessBtnWrapper>
      </AddNewBusinessContainer>
    </Modal>
  );
};

export default AddNewBusiness;
