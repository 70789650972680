import { useState } from "react";
import { ProfileContext } from "./ProfileContext";
import { useMemo } from "react";

export const ProfileProvider = ({ children }) => {
  const u_role = localStorage.getItem("user_role");
  const user_role = JSON.parse(+u_role || 1);
  const [role, setRole] = useState(user_role);
  const [profileData, setProfileData] = useState(JSON.parse(localStorage.getItem("clientData")))
  const [adminstaffprofileData, setadminstaffProfileData] = useState(JSON.parse(localStorage.getItem("userData")))
  const [clickedMenuItem, setClickedMenuItem] = useState(
    sessionStorage.getItem("menuItem") || "Client"
  ); // State to store the clicked menu item

  useMemo(() => {
    localStorage.setItem("user_role", role);
  }, [role]);

  return (
    <ProfileContext.Provider
      value={{ role, setRole, clickedMenuItem, setClickedMenuItem,profileData,setProfileData,adminstaffprofileData,setadminstaffProfileData }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
