import React, { useState } from "react";
import { Modal, Tabs, Tab, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlexComp from "../../../components/reusable/flex/Flex";
import {
  AddNewBusinessContainer,
  AddBusinessCloseIcon,
  AdditionalButtonWrapper,
} from "../../../components/modals/Modals.style";
import { Span } from "components/reusable/typography/Typography.style";
import warningIcon from "../../../assets/reusable/warning-icon.png";
import { Input } from "components/reusable/input/Inputs";
import { Button } from "components/reusable/button/Button";
import { apiReq } from "services/Requests";
import { API_ROUTES } from "utils/apiRoutes.constant";
import { toast } from "react-toastify";


const errorToast = (message = "") => toast.error(message);
const infoToast = (message = "") => toast.success(message);
const WarningModal = ({ setOpenInfoModal, openInfoModal, modalData, activeButton, fetchUnassignedData }) => {
    console.log(modalData, "modalData")
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState(""); 
  const adminId = localStorage.getItem("clientId");

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const unAssignedpayload = {
    formType: (() => {
        const formType = [];

        // Check personalCollection array
        if (modalData?.personalCollection?.length > 0) {
          formType.push("Personal Collection");
        }

        // Check generalBusiness array in businessCollection
        if (
          modalData?.businessCollection?.generalBusiness &&
          modalData.businessCollection?.generalBusiness?.length > 0
        ) {
          formType.push("General Business");
        }

        // Check truckDriverForms array in businessCollection
        if (
          modalData?.businessCollection?.truckDriverForms &&
          modalData.businessCollection?.truckDriverForms?.length > 0
        ) {
          formType.push("Truck Driver");
        }

        // Check rentalProperties array in businessCollection
        if (
          modalData?.businessCollection?.rentalProperties &&
          modalData.businessCollection?.rentalProperties?.length > 0
        ) {
          formType.push("Rental Property");
        }

        return formType;
      })(),
      personalCollectionTaskId:
        modalData?.personalCollection?.map((item) => item._id) || [],
      generalBusinessTaskId:
        modalData?.businessCollection?.generalBusiness?.map(
          (item) => item._id
        ) || [],
      rentalPropertyTaskId:
        modalData?.businessCollection?.rentalProperties?.map(
          (item) => item._id
        ) || [],
      truckDriverTaskId:
        modalData?.businessCollection?.truckDriverForms?.map(
          (item) => item._id
        ) || [],
        notes,
        clientId: modalData?.clientId,
        assignById: adminId
  }
  const assignedPayload = {
    queueId: modalData?._id,
    notes,
    assignById: adminId
  }
console.log(activeButton, "checkBitt")
  // Main Tabs Data
  const handleUnlockClients = async () => {
    try {
      // Prepare data to be uploaded
      setLoading(true);
      const payload = activeButton === "Assigned Tasks" ? assignedPayload : unAssignedpayload;

      await  apiReq(activeButton == "Assigned Tasks" ? API_ROUTES.unlockAssignedClients : API_ROUTES.unlockNotassignedClients, "POST", payload)
      infoToast("Queues realocate to client for re-check");   
      setNotes("")
      setOpenInfoModal(false)
      fetchUnassignedData()
    } catch (error) {
      errorToast(
        error?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={openInfoModal}
      onClose={() => {setOpenInfoModal(false); setNotes("")}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <AddNewBusinessContainer
        style={{
          width: "500px",
          maxHeight: "90vh",
          overflow: "auto",
          padding: "14px",
        }}
      >
        <Box sx={{ width: "100%", padding: "0 20px" }}>
          <FlexComp alignItems="center" justifyContent="end">
            {/* <Span color="#000000" fontSize="20px" fontWeight="500">
              Tax Payers Information
            </Span> */}
            <CloseIcon
              onClick={() => setOpenInfoModal(false)}
              sx={{ cursor: "pointer" }}
            />
          </FlexComp>
        </Box>
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "12px",
            }}
          >
            <img src={warningIcon} alt="" />
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500", 
              marginBottom: "15px",
            }}
          >
            Are you sure you want to reassign all forms to the client?
          </Typography>
          <Input
            label="Notes"
            // defaultValue={notes} // Set default value to notes
            size="medium"
            multiline
            width="100%"
            placeholder="You can type here..."
            minRows="3"
            mb={"20px"}
            value={notes}
            onChange={handleNotesChange} 
          />

          <AdditionalButtonWrapper>
            <Button
              text="No"
              width="25%"
              fontSize="18px"
              backgroundColor="#EFF3FD"
              color="#AEB6CF"
              padding="10px 16px"
              borderRadius="10px"
              onClick={() => {setOpenInfoModal(false); setNotes("")}}
            />
            <Button
              text="Yes"
              width="25%"
              fontSize="18px"
              backgroundColor="#0B2558"
              color="#FFFFFF"
              padding="10px 16px"
              borderRadius="10px"
              onClick={handleUnlockClients}
            />
          </AdditionalButtonWrapper>
        </div>
      </AddNewBusinessContainer>
    </Modal>
  );
};

export default WarningModal;
