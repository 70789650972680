import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  taxYear: Yup.string().required("TaxYear required"),
  businessInfoArr: Yup.array().of(
    Yup.object().shape({
      entityInfo: Yup.string().required('Entity Info is required'),
      soleProprietorCheck: Yup.boolean(),
      singleMemberLlcCheck: Yup.boolean(),
      multiMemberLlcCheck: Yup.boolean(),
      partnershipCheck: Yup.boolean(),
      sCorpCheck: Yup.boolean(),
      cCorpCheck: Yup.boolean(),
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      // middleName: Yup.string().required('Middle Name is required'),
      mobileNumber: Yup.string().required('Mobile Number is required'),
      businessName: Yup.string().required('Business Name is required'),
      ssn: Yup.string().required('EIN is required'),
      city: Yup.string().required('City is required'),
      street: Yup.string().required('Street is required'),
      state: Yup.string().required('State is required'),
      zipCode: Yup.string().required('Zip Code is required'),
      amountPaid: Yup.number().required('Alimony is required'),
     
      multiMemberLlcArr: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          mobileNumber: Yup.string(),
        })
      ).when('multiMemberLlcCheck', {
        is: true,
        then: schema => schema,
        otherwise: schema => schema.notRequired(),
      }),
      patnersArr: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          mobileNumber: Yup.string(),
        })
      ).when('partnershipCheck', {
        is: true,
        then: schema => schema,
        otherwise: schema => schema.notRequired(),
      }),
      sCorpMembersArr: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          mobileNumber: Yup.string(),
        })
      ).when('sCorpCheck', {
        is: true,
        then: schema => schema,
        otherwise: schema => schema.notRequired(),
      }),
      cCorpShareholdersArr: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          mobileNumber: Yup.string(),
        })
      ).when('cCorpCheck', {
        is: true,
        then: schema => schema,
        otherwise: schema => schema.notRequired(),
      }),
    })
  ),
  additionalNotes: Yup.string(), // Add validation rule for additionalNotes if needed
});
