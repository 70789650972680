import { Box } from "@mui/material";
import BusinessFormationList from "components/client/businessFormation/BusinessFormationList";
import { TitleButton } from "components/reusable/titleComponent/TitleComponent";
import { HeadingFive } from "components/reusable/typography/Typography.style";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BusinessFormation = () => {
  const navigate = useNavigate();
  const [isTermModal, setIsTermModal] = useState(false);
  const [hasEditableItems, setHasEditableItems] = useState(false);

  return (
    <>
      <BusinessFormationContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <HeadingFive fontSize="24px" fontWeight="500">
            Business Formation
          </HeadingFive>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <TitleButton onClick={() => navigate("/add-businessFormation")}>
              Add New Business
            </TitleButton>
            <TitleButton
              onClick={() => setIsTermModal(true)}
              disabled={!hasEditableItems}
              sx={{
                opacity: hasEditableItems ? 1 : 0.5,
                cursor: hasEditableItems ? "pointer" : "not-allowed",
              }}
            >
              Submit All Docs
            </TitleButton>
          </Box>
        </Box>
        {/* <BusinessInfo /> */}
        <BusinessFormationList
          isTermModal={isTermModal}
          setIsTermModal={setIsTermModal}
          setHasEditableItems={setHasEditableItems}
        />
      </BusinessFormationContainer>
    </>
  );
};

export default BusinessFormation;

export const BusinessFormationContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
`;
