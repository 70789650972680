import { useEffect, useState } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import FlexComp from "components/reusable/flex/Flex";
import SecDropdownComp from "components/reusable/dropdownComp/SecDropdown";
import { Button } from "components/reusable/button/Button";
import { DateInput, Input } from "components/reusable/input/Inputs";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import { useFormik } from "formik";
import { Toaster } from "components/reusable/toaster";
import { apiReq } from "services/Requests";
import { SyncLoader } from "react-spinners";
import { FormHelperText, Stack } from "@mui/material";
import * as Yup from "yup";
import { API_ROUTES } from "utils/apiRoutes.constant";
import axios from "axios";

const AddNewTask = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [taskChange, settaskChange] = useState("");
  const [assignChange, setassignChange] = useState("");
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [clients, setclients] = useState();
  const [allsubmitedForms, setallsubmitedForms] = useState();
  const [assignto, setassignto] = useState();
  const [priority, setpriority] = useState();
  const [generalBusinessTaskId, setgeneralBusinessTaskId] = useState();
  const [truckDriverTaskId, settruckDriverTaskId] = useState();
  const [personalCollectionTaskId, setpersonalCollectionTaskId] = useState();
  const userData = localStorage.getItem("userData");
  const jsonUserData = JSON.parse(userData);
  const [adminId, setadminId] = useState(jsonUserData?.user?._id);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleTaskChange = (event) => {
    settaskChange(event.target.value);
  };
  const handleAssignChange = (event) => {
    setassignChange(event.target.value);
  };
  const handlepriorityChange = (event) => {
    setpriority(event.target.value);
  };
  const options =
    clients?.map((client) => ({
      label: client.username,
      label2: client.email,
      value: client.id,
    })) || [];
  const allformsoptions =
    allsubmitedForms?.map((client) => ({
      label: client.informationType,
      label2: client.busines_name,
      value: client,
    })) || [];
  const assigntotask =
    assignto?.map((client) => ({
      label: client.name,
      value: client,
    })) || [];
  const fetchData = async () => {
    try {
      const response = await apiReq(API_ROUTES.queuesClients, "POST");

      const responseData = await response.data;

      const updatedData = responseData?.data?.map((item, index) => {
        return {
          id: item?._id,
          username: item?.userName,
          email: item?.email,
        };
      });
      setclients(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getAllforms = async () => {
    if (!selectedValue) {
      return;
    }
    try {
      const response = await apiReq(
        API_ROUTES.clientReviewSubmitionForms,
        "POST",
        { clientId: selectedValue }
      );

      const responseData = await response.data;
      console.log("Th e", responseData);

      let flattenedArray = [];
      let responseDataAll = responseData.data;
      Object.keys(responseDataAll).map((key) => {
        if (responseDataAll[key] && responseDataAll[key]?.length > 0)
          flattenedArray = flattenedArray.concat(responseDataAll[key]);
      });
      responseDataAll?.personalCollection?._id &&
        flattenedArray.push(responseDataAll.personalCollection);
      const updatedData = flattenedArray.map((item, index) => {
        return {
          id: item?._id,
          informationType: item?.informationType,
          busines_name: item?.businessInfoId?.firstName
            ? item?.businessInfoId?.firstName +
              " " +
              item?.businessInfoId?.lastName
            : item?.truckDriverBasicInfoId?.firstName
            ? item?.truckDriverBasicInfoId?.firstName +
              " " +
              item?.truckDriverBasicInfoId?.lastName
            : item?.prepSheetId?.firstName
            ? item?.prepSheetId?.firstName + " " + item?.prepSheetId?.lastName
            : "",
          // email: item?.email,
        };
      });
      setallsubmitedForms(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const AssignTAaskTo = async () => {
    try {
      const response = await apiReq(API_ROUTES.AllUsers, "POST");

      const responseData = await response.data;

      const updatedData = responseData?.data?.map((item, index) => {
        return {
          id: item?._id,
          name: item?.firstName + " " + item?.lastName,
          email: item?.email,
          role: item?.role,
        };
      });
      setassignto(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    AssignTAaskTo();
  }, []);
  useEffect(() => {
    getAllforms();
  }, [selectedValue]);
  useEffect(() => {
    if (taskChange?.informationType == "General Business") {
      setgeneralBusinessTaskId(taskChange.id);
    }
    if (taskChange?.informationType == "Truck Driver") {
      settruckDriverTaskId(taskChange.id);
    }
    if (taskChange?.informationType == "Personal Collection") {
      setpersonalCollectionTaskId(taskChange.id);
    }
  }, [taskChange]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientId: selectedValue,
      formType: taskChange.informationType,
      generalBusinessTaskId: generalBusinessTaskId,
      truckDriverTaskId: truckDriverTaskId,
      personalCollectionTaskId: personalCollectionTaskId,
      assignBy: adminId,
      assignTo: assignChange?.id,
      assignToType: assignChange?.role,
      priority: priority,
      startDateTime: "",
      endDateTime: "",
      note: "",
    },

    validationSchema: Yup.object().shape({
      clientId: Yup.string().required("Select a client"),
      formType: Yup.string().required("Select a task"),
      assignTo: Yup.string().required("Assign a task to"),
      priority: Yup.string().required("Select a priority"),
      startDateTime: Yup.date().required("Select a start date and time"),
      endDateTime: Yup.date()
        .required("Select an end date and time")
        .min(Yup.ref("startDateTime"), "End date must be after start date"),
      note: Yup.string().required("Enter a note"),
    }),

    onSubmit: async (values, formik) => {
      try {
        setLoading(true);
        // Prepare data to be uploaded

        const response = await apiReq(API_ROUTES.addNewTask, "POST", values);
        setSuccess("New task added succesfully");
        setError("");
        formik.resetForm();
        setSelectedValue("");
        setpriority("");
        setassignChange("");
        settaskChange("");
      } catch (error) {
        setSuccess("");
        setError(
          error?.message ||
            "An unexpected error occurred. Please try again later."
        );
      } finally {
        setLoading(false);
        formik.setSubmitting(false);
      }
    },
  });
  console.log("formkiValue", formik?.values);
  return (
    <>
      {
        <Toaster
          open={!!error || !!success}
          content={error || success}
          type={error ? "error" : "success"}
          handleClose={() => {
            setError("");
            setSuccess("");
          }}
        />
      }
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <RentalPropertyContainer>
          <h2 style={{ paddingLeft: "5px" }}>Add New Task</h2>
          <RentalPropertyWrapper>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" width={"32%"}>
                <SecDropdownComp
                  label="Select Client *"
                  id="location-select"
                  placeholder="select"
                  size="small"
                  width="100%"
                  defaultValue=""
                  options={options}
                  selectedMonth={selectedOption}
                  handleChange={handleChange}
                />
                <FormHelperText error>{formik.errors?.clientId}</FormHelperText>
              </Stack>
              <Stack direction="column" width={"32%"}>
                <SecDropdownComp
                  label="Select Task *"
                  id="location-select"
                  placeholder="select"
                  size="small"
                  width="100%"
                  options={allformsoptions}
                  selectedMonth={selectedOption}
                  handleChange={handleTaskChange}
                />
                <FormHelperText error>{formik.errors?.formType}</FormHelperText>
              </Stack>
            </FlexComp>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" width={"32%"}>
                <SecDropdownComp
                  label="Assign Task To *"
                  id="location-select"
                  placeholder="select"
                  size="small"
                  width="100%"
                  options={assigntotask}
                  selectedMonth={selectedOption}
                  handleChange={handleAssignChange}
                />
                <FormHelperText error>{formik.errors?.assignTo}</FormHelperText>
              </Stack>
              <Stack direction="column" width={"32%"}>
                <SecDropdownComp
                  label="Priority *"
                  id="location-select"
                  placeholder="select"
                  size="small"
                  width="100%"
                  options={[
                    { label: "high", value: "high" },
                    { label: "low", value: "low" },
                  ]}
                  selectedMonth={selectedOption}
                  handleChange={handlepriorityChange}
                />
                <FormHelperText error>{formik.errors?.priority}</FormHelperText>
              </Stack>
            </FlexComp>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Stack direction="column" width={"32%"}>
                <DateInput
                  label="Start Date & Time *"
                  width="100%"
                  required
                  name="startDateTime"
                  value={formik.values.startDateTime}
                  onChange={(value) =>
                    formik.setFieldValue("startDateTime", value)
                  }
                />
                <FormHelperText error>
                  {formik.errors?.startDateTime}
                </FormHelperText>
              </Stack>
              <Stack direction="column" width={"32%"}>
                <DateInput
                  label="End Date & Time *"
                  width="100%"
                  required
                  name="endDateTime"
                  value={formik.values.endDateTime}
                  onChange={(value) =>
                    formik.setFieldValue("endDateTime", value)
                  }
                />
                <FormHelperText error>
                  {formik.errors?.endDateTime}
                </FormHelperText>
              </Stack>
            </FlexComp>
            <Stack direction="column" width={"92%"}>
              <Input
                label="Note "
                id="outlined-size-small"
                defaultValue=""
                size="size"
                width="100%"
                height={50}
                name="note"
                placeholder="You can write your note here..."
                onChange={formik.handleChange}
                value={formik.values.note}
              />
              <FormHelperText error>{formik.errors?.note}</FormHelperText>
            </Stack>
            <FlexComp direction="row" gridGap="25px" width="100%">
              <Button
                type="submit"
                text={
                  formik.isSubmitting ? (
                    <SyncLoader color={"#FFFFFF"} size={8} />
                  ) : (
                    "Send"
                  )
                }
                disabled={loading || formik.isSubmitting}
                backgroundColor="rgba(11, 37, 88, 1)"
                color="white"
                width="112px"
              />
              <Button
                text="Cancel"
                onClick={() => navigate("/admin-queues")}
                backgroundColor="rgba(239, 243, 253, 1)"
                color="rgba(174, 182, 207, 1)"
                width="112px"
              />
            </FlexComp>
          </RentalPropertyWrapper>
        </RentalPropertyContainer>
      </form>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to assign task to Danish?"
        // setGeneralBusinessModal={setGeneralBusinessModal}
        // generalBusinessModal={generalBusinessModal}
      />
    </>
  );
};

export default AddNewTask;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
