import React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/system";

const SwitchCompStyled = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#0B2558",
    "&.Mui-checked": {
      color: "#0B2558", // Set the desired color when the switch is checked
      "& + .MuiSwitch-track": {
        backgroundColor: "#0B2558", // Set the desired background color when the switch is checked
      },
    },
  },
}));

const SwitchComp = ({ value, valueSetter }) => {
  return (
    <SwitchCompStyled
      checked={value === 2 ? true : false}
      onChange={() => valueSetter(value === 2 ? 1 : 2)}
    />
  );
};
export default SwitchComp;
