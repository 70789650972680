import React, { useEffect, useMemo, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import {
  Dot,
  DotBox,
  DotsWrapper,
  ReturnStatsContainer,
  ReturnStatsWrapper,
  TaxSelect,
} from "./Dashboard.style";
import {
  HeadingFive,
  Paragraph,
} from "../../reusable/typography/Typography.style";
import { dashboardTaxReturnStatus } from "../../../data/client";

const ReturnStats = ({ userData }) => {
  const [showMultiValue, setShowMultiValue] = useState(false);
  const { taxReturnStats } = userData;
  const [singleChartValue, setSingleChartValue] = useState(null);

  // Pie chart data
  const pieChartData = useMemo(
    () => [
      { name: "Tax Return Completed", value: taxReturnStats.taxReturnCompleted || 0 },
      { name: "Review Needed", value: taxReturnStats.reviewNeeded || 0 },
      { name: "Tax Info Submitted", value: taxReturnStats.taxInfoSubmitted || 0 },
    ],
    [taxReturnStats]
  );

  // Color scheme for the pie chart
  const COLORS = ["#00ADEF", "#B0B0B0", "#003366"];  // Light Blue, Grey, Dark Blue

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < pieChartData?.length; i++) {
      if (pieChartData[i].value > 0) {
        count++;
      }
      if (count === 2) {
        setShowMultiValue(true);
      } else {
        const maxChartData = pieChartData.reduce((acc, curr) => {
          return curr.value > acc.value ? curr : acc;
        });
        setSingleChartValue(maxChartData.value > 0 ? maxChartData.value : null);
      }
    }
  }, [pieChartData]);

  return (
    <ReturnStatsContainer>
      <ReturnStatsWrapper>
        <TaxSelect>
          <HeadingFive fontSize="20px">
            Tax Preps Status
            <span style={{ fontSize: "12px" }}>(This Year)</span>
          </HeadingFive>
        </TaxSelect>

        <div style={{ width: "100%", height: "300px" }}>
          <PieChart width={300} height={300}>
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"  // Center X position
              cy="50%"  // Center Y position
              innerRadius={70}  // Inner radius for the donut effect
              outerRadius={90}  // Outer radius
              paddingAngle={5}  // Add padding angle between slices
              stroke="none"  // Remove lines (strokes)
            >
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </ReturnStatsWrapper>
    </ReturnStatsContainer>
  );
};

export default ReturnStats;
