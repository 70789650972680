import { Button } from "@mui/material";
import { Line } from "components/client/rentalProperty/RentalProperty.style";
import ValidateTaxModal from "components/modals/ValidateTaxModal";
import PersonalCollection from "components/modals/review/ReviewConfirmation/PersonalCollection";
import GeneralBusinessModal from "components/modals/staffModals/GernalBusinessModal";
import CustomTable from "components/reusable/customTable/CustomTable";
import DateComponent from "components/reusable/datecomponent/DateComponent";
import StaffHeader from "components/reusable/header/StaffHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiReq } from "services/Requests";
import styled from "styled-components";
import { API_ROUTES } from "utils/apiRoutes.constant";
import * as XLSX from "xlsx";

const formattedDate = (value) => {
  const date = dayjs(value);
  const formattedDate = date.format("MM-DD-YYYY");
  return formattedDate;
};

const AdminTaxFilling = () => {
  const [tab_loader, setTabLoader] = useState(true);
  const [isValidateTaxModal, setIsValidateTaxModal] = useState(false);
  const [generalBusinessModal, setGeneralBusinessModal] = useState(false);
  const [personalCollectionModal, setPersonalCollectionModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false); // State to manage row selection
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [value, setValue] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [originaldata, setoriginalData] = useState();
  const [loading, setLoading] = useState(false);
  const handleSearchByName = (selectedValues) => {
    setSearchName(selectedValues.target.value);

    const filteredData = originaldata.filter((item) =>
      item.clientName.toLowerCase().includes(selectedValues.target.value)
    );
    if (!selectedValues.target.value) {
      setRefetch(!refetch);
    }
    setValue(filteredData);
  };

  const fetchTaxFiling = async () => {
    if (startDate && !endDate) {
      toast.error("Please Select End date");
      return;
    } else if (!startDate && endDate) {
      toast.error("Please Select Start date");
      return;
    }

    const isStartDateAfterEndDate = startDate > endDate;
    if (isStartDateAfterEndDate) {
      toast.error("End date cannot be before the start date");
      return;
    }
    try {
      setLoading(true);
      setTabLoader(true);
      const { data: { data = {} } = {} } = await apiReq(
        `${API_ROUTES.getAdminAllTaxFiling}`,
        "POST",
        {
          startDate,
          endDate,
        }
      );
      const dataValue = data?.map((itm, idx) => ({
        id: itm._id,
        srNo: (idx + 1).toString().padStart(2, "0"),
        clientName: itm.clientId.userName || "",
        businessName:
          itm?.generalBusinessTaskId?.businessInfoId?.businessName || "",
        assignedTo: itm.assignTo.firstName + " " + itm.assignTo.lastName || "",
        updatedBy: itm.updatedAt,
        createdDate: itm.createdAt,
        modifiedDate: itm.updatedAt,
        status: itm.status,
      }));

      setValue(dataValue);
      setoriginalData(dataValue);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message || "Error fetching data. please try again later."
      );
    } finally {
      setTabLoader(false);
    }
  };

  useEffect(() => {
    fetchTaxFiling();
  }, [refetch]);

  const reFetchTaxFiling = () => {
    setRefetch(!refetch);
  };

  const individualColumns = [
    {
      field: "srNo",
      headerName: "Sr#.",
      width: 30,
      editable: false,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 190,
      editable: false,
    },
    {
      field: "businessName",
      headerName: "Business Name",
      width: 180,
      editable: false,
      renderCell: (params) => (
        <div>{params.value ? params.value : "-----"}</div>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 180,
      editable: false,
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 180,
      editable: false,
      renderCell: (params) => <div>{formattedDate(params.value)}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      editable: false,
      renderCell: (params) => (
        <div
        // style={{
        //   backgroundColor:
        //     params.row.form === "Staff Review"
        //       ? "rgba(244, 184, 27, 0.3)"
        //       : "rgba(244, 184, 27, 0.3)",
        //   color:
        //     params.row.form === "Staff Review"
        //       ? "rgba(208, 150, 0, 1)"
        //       : "rgba(208, 150, 0, 1)",
        //   borderRadius: "15px",
        //   padding: "5px",
        // }}
        >
          {params.row.status}
        </div>
      ),
    },
  ];

  const options = [
    { label: "Select ALL", value: "Select All" },
    { label: "Data Validation", value: "Data Validation" },
    { label: "Data Entry", value: "Data Entry" },
    { label: "Staff Review", value: "Staff Review" },
  ];
  const handleSelectChange = (selectedValues) => {
    // Handle select change logic
    console.log("select value");
  };

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table.xlsx");
  };
  const ExlhandleButtonClick = () => {
    // Button click logic
    generateExcel();
  };

  const searchStaff = async (searchValue) => {
    try {
      console.log(searchValue);
      const filteredAssignData = originaldata.filter((item) => {
        return item?.cname?.toLowerCase().includes(searchValue?.toLowerCase());
        // item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        // item?.priority?.toLowerCase().includes(searchValue?.toLowerCase())
      });

      setValue(filteredAssignData);
    } catch (error) {}
  };

  return (
    <>
      <RentalPropertyContainer>
        <StaffHeader
          showFirstSelect={false}
          showSecondSelect={false}
          showButton={false}
          showSecondButton={false}
          showThirdButton={true}
          clickHandler2={ExlhandleButtonClick}
          placeholder="Search by Name"
          options={options}
          btntext1="PDF"
          btntext2="Excel"
          onSelectChange={handleSelectChange}
          onChange={handleSearchByName}
          searchStaff={searchStaff}
        />
        <Line border="0.0999999999999999px solid rgba(239, 243, 253, 1)" />
        <Button />
        <DateComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleButtonClick={reFetchTaxFiling}
          loading={loading}
        />
        <RentalPropertyWrapper>
          <CustomTable
            rows={value}
            loading={tab_loader}
            enableRowSelection={enableRowSelection}
            columns={individualColumns}
          />
        </RentalPropertyWrapper>
      </RentalPropertyContainer>
      <ValidateTaxModal
        isModalOpen={isValidateTaxModal}
        closeModal={() => setIsValidateTaxModal(!isValidateTaxModal)}
        paragraph="Are you sure you want to submit for review?"
        setGeneralBusinessModal={setGeneralBusinessModal}
        generalBusinessModal={generalBusinessModal}
      />
      <GeneralBusinessModal
        generalBusinessModal={generalBusinessModal}
        setGeneralBusinessModal={setGeneralBusinessModal}
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
      />
      <PersonalCollection
        personalCollectionModal={personalCollectionModal}
        setPersonalCollectionModal={setPersonalCollectionModal}
        truckDriverModal={truckDriverModal}
        setTruckDriverModal={setTruckDriverModal}
      />
    </>
  );
};

export default AdminTaxFilling;

const RentalPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: #fff;
  width: 100%;
  padding: 20px;
  min-height: 85vh;
`;

const RentalPropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
`;
