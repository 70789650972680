import Auth from "../../assets/auth/Auth.png";
import ForgetPass from "../../components/auth/signin/ForgetPass";
import {
  AuthPicWrapper,
  SignupContainer,
} from "../../components/auth/signup/Signup.style";

const ForgetPassword = () => {
  return (
    <SignupContainer>
      <ForgetPass />
      <AuthPicWrapper>
        <img src={Auth} alt="Auth" />
      </AuthPicWrapper>
    </SignupContainer>
  );
};

export default ForgetPassword;
