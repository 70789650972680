import { Box, Grid, Typography } from '@mui/material';
import React from 'react'

const FormationNonProfit = ({sheetData}) => {
  return (
    <>
       <Box
          sx={{
            background: "#EFF3FD99",
            padding: "20px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#0B2558",
              height: "40px",
              padding: "0 20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            Basic Information
          </Box>

          <Grid
            container
            spacing={2}
            borderBottom={"1px solid #00000033"}
            padding={"10px 20px"}
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Non-Profit Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.basicInfo.nonProfitName}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  EIN
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.basicInfo.ein}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            borderBottom={"1px solid #00000033"}
            padding={"10px 20px"}
          >
            <Grid item xs={3}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Street
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.basicInfo.street}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  City
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.basicInfo.city}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  State
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.basicInfo.state}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Zip Code
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.basicInfo.zipCode}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            background: "#EFF3FD99",
            padding: "20px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#0B2558",
              height: "40px",
              padding: "0 20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            Person Of Contact
          </Box>

          <Grid
            container
            spacing={2}
            borderBottom={"1px solid #00000033"}
            padding={"10px 20px"}
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  First Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.firstName}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Middle Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.middleName}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Last Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.lastName}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            borderBottom={"1px solid #00000033"}
            padding={"10px 20px"}
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Title
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.title}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Mobile Number
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.mobileNumber}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  EIN
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.ssn}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            borderBottom={"1px solid #00000033"}
            padding={"10px 20px"}
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Street
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.street}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  City
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.city}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  State
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.state}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            borderBottom={"1px solid #00000033"}
            padding={"10px 20px"}
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  Zip Code
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.personOfContact.zipCode}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        {sheetData?.officersBoardTrustees?.map((item, i) => {
          return (
            <>
              <Box
                sx={{
                  background: "#EFF3FD99",
                  padding: "20px",
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    background: "#0B2558",
                    height: "40px",
                    padding: "0 20px",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  Trustee Member {i == 0 ? "" : i + 1}
                </Box>

                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        First Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.firstName}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Middle Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.middleName}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Last Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.lastName}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Title
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.entityInfo}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Mobile Number
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.businessName}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.ssn}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        City
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.street}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.city}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  borderBottom={"1px solid #00000033"}
                  padding={"10px 20px"}
                >
                  <Grid item xs={4}>
                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#1B1B1B",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1B1B1B",
                        }}
                      >
                        {item?.zipCode}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}

        <Box
          sx={{
            background: "#EFF3FD99",
            padding: "20px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#0B2558",
              height: "40px",
              padding: "0 20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#fff",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
          Explanation
          </Box>

          <Grid
            container
            spacing={2}
            padding={"10px 20px"}
          >
            <Grid item xs={12}>
              <div>
              
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#1B1B1B",
                  }}
                >
                  {sheetData?.explaination}
                </Typography>
              </div>
            </Grid>
        
          </Grid>
      
        </Box>
    </>
  )
}

export default FormationNonProfit